import React, { useContext, useEffect, useState } from "react";
import PageName from "../Utils/PageName";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { businessObjContext } from "../../context/businessObjContext";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import { businessNameContext } from "../../context/businessContext";
import FilterIcon from "../../../src/Images/FilterIcon.png";
import "./UpdateRequestcardstyle.css";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";

const CustomizedPlans = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData } = useContext(userContext);
  const [isLoading, setIsLoading] = useState(false);
  const tableExtensions = { export: false, print: false };
  const [isSubmit, setIsSubmit] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  // 12/01/2024
  const user_type = userData?.user_type;
  const [dataArrCustom, setDataArrCustom] = useState([]);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [editedData, setEditedData] = useState({});
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [planId, setPlanId] = useState("");
  // const [price, setPrice] = useState("");
  const [expiryDays, setExpiryDays] = useState(0);
  const [userEmail, setUserEmail] = useState("");
  const [isEmailFound, setIsEmailFound] = useState(true);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const handleFilterOpen = () => {
    setShowFilter(!showFilter);
  };

  const customStyles = {
    dataTableExtensions: {
      backgroundColor: "lightgray",
      padding: "10px",
    },
    headRow: {
      style: {
        backgroundColor: "#e9e9e9",
        borderRadius: "2px 2px 0 0",
        paddingLeft: "25px",
        border: "none",
        textTransform: "capitalize",
        position: "sticky",
        left: 0,
        top: 0,
        zIndex: 1,
        boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "52px",
      //   width: "135px", // Adjust the width as needed
      // overflow: "visible", 
      },
    },
    rows: {
      style: {
        padding: "6px 0px 6px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "48px !important",
      },
    },
    BusinessColumn: {
      position: "sticky",
      left: "0",
      zIndex: "1",
      backgroundColor: "#fff",
      overflow: "hidden",
      whiteSpace: "nowrap",
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const updatedEditedData = {
        ...editedData,
        userEmail,
        expiryDays,
      };
  
      let data = JSON.stringify(updatedEditedData);
      // let data = JSON.stringify(editedData);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}api/v1/plan/editPlan?planId=${planId}`,
        headers: {
          _token: userData?.token,
          "Content-Type": "application/json",
        },
        // withCredentials: true,
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          toast.success("plan Updated succesfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          fetchPlanData();
          setTimeout(() => {
            location.reload();
          }, 3000);
        })
        .catch((error) => {
          console.log(error?.response?.data?.message?.message);
          toast.error(
            error?.response?.data?.message?.message || "Failed to Update.",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
        });
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const fetchData = async (id) => {
    try {
      setPlanId(id);
      axios
        .get(`${baseUrl}api/v1/plan/fetchCustomPlanById`, {
          params: { planId: id },
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((response) => {
          setData1(response.data);

          setEditedData(response?.data?.result?.plan);
          setUserEmail(response?.data?.result?.userEmail || '');
          setExpiryDays(response?.data?.result?.days || 0);
        })
        .catch((error) => {
          console.error("Error:", error?.response?.data?.message);
        });
    } catch (error) {
      console.error("API Error:", error?.response?.data?.message);
    }
  };

  const handleBlurEmail = () => {
    if (userEmail === '') {
      setEmailErrorMessage('Email is required!');
      return;
    }

    axios
      .get(`${baseUrl}api/v1/user/checkEmailAvailability?email=${userEmail}`)
      .then((response) => {
        const { isEmailUsed } = response.data;
        setIsEmailFound(!isEmailUsed);
        if (isEmailUsed) {
          setEmailErrorMessage(''); 
        } else {
          setIsEmailFound(false)
          setEmailErrorMessage('Email not found');
        }
      })
      .catch((err) => {
        console.error(err);
        setEmailErrorMessage('Error checking email availability');
      });
  };

  const handleTabSelect = (index) => {
    setSelectedTabIndex(index);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData({ ...editedData, [name]: value });
    setIsFormChanged(true);
  };

  const handleDelete = async (planId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: `You won't be able to revert this!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      try {
        const response = await axios.delete(
          `${baseUrl}api/v1/plan/deletePlan${planId}`,
          {
            headers: {
              _token: userData?.token,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.success) {
          toast.success("Plan deleted successfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toast.error("Failed to delete the plan.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
      } catch (error) {
        console.error("Error deleting the plan:", error.message);
      }
    }
  };

  const fetchPlanData = () => {
    let apiEnd = "";
    if (user_type === "super_admin") {
      apiEnd = "/fatchPlans";
    } else {
      apiEnd = "/fatchPlansUsers";
    }
    try {
      axios
        .get(`${baseUrl}api/v1/plan${apiEnd}`, {
          headers: {
            _token: userData?.token,
          },
        })
        .then((response) => {

          const filteredDataCustom = response.data?.result
          // console.log(filteredDataCustom, "filteredDataCustom")

          setDataArrCustom(filteredDataCustom);
          let arr = filteredDataCustom;
          arr = arr.sort(
            (x, y) => new Date(y?.created_at) - new Date(x?.created_at)
          );
          setData(arr)
          // console.log(arr, "arr12")
        })
        .catch((error) => {
          console.error("Error:", error?.response?.data?.message);
        });
    } catch (error) {
      console.error("API Error:", error?.response?.data?.message);
    }
  };
  useEffect(() => {
    fetchPlanData();
  }, []);




  const columns = [
    {
      name: "Plan Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Country",
      selector: "country",
      sortable: true,
    },
    {
      name: "Price",
      selector: "price",
      sortable: true,
    },
    {
      name: "Duration",
      selector: "duration",
      sortable: true,
    },
    {
      name: "Business",
      selector: "business",
      sortable: true,
    },
    {
      name: "Users",
      selector: "max_users",
      sortable: true,
    },
    {
      name: "Themes",
      selector: "themes",
      sortable: true,
    },
    {
      name: "QrCode",
      selector: "enable_qr_code",
      sortable: true,
    },
    {
      name: "Storage",
      selector: "storage_limit",
      sortable: true,
    },
    {
      name: "PVC",
      selector: "no_of_pvc_card",
      sortable: true,
    },
    {
      name: "Metal",
      selector: "no_of_metal_card",
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      cell: (d) => [
        <>
          <i
          style={{padding: "0 7px"}}
            key={`trash-${d.id}`}
            className="fas fa-trash-alt"
            onClick={() => handleDelete(d?.id)}
          ></i>{" "}
          &nbsp;&nbsp; &nbsp;{" "}
          <i
            key={d?.id}
            data-bs-toggle="modal"
            data-bs-target="#planselectedit"
            onClick={() => { fetchData(d?.id); }}
            className="first fas fa-pen"
          ></i>


        </>,
      ],
    },
  ];
  const tableData = {
    columns,
    data,
  };

 const paginationOptions = {
    rowsPerPageText: t("Rows_per_page"),
    rangeSeparatorText: t("Of"),
    selectAllRowsItem: true,
    selectAllRowsItemText: t("All"),
  };
  



  return (
    <>
      <PageName PageName="CustomizedPlans" />
      <p className="addrolep">
        <span className="icon-btn addrolemain_role" >
          Add New Plan <span className="addrole_role">+</span>
        </span>
        <a className="MyClickModel" data-bs-toggle="modal" data-bs-target="#planselectedit"></a>
      </p>
      
      <article>
        <div className="row d-none">
          <div className="col-md-12">
            <div className="tile">
              <div className="tile-body">
                <form className="row">
                  <div className="mb-2 col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search....."
                    />
                  </div>
                  <div className="mb-2 col-md-3">
                    <select className="form-select">
                      <option value="">Select an option</option>
                      <option value="option1">xxx </option>
                      <option value="option2">xxxx</option>
                    </select>
                  </div>
                  <div className="mb-2 col-md-4 align-self-end">
                    <button className="btn btn-primary me-3" type="button">
                      <i className="bi bi-check-circle-fill ms-2 "></i>Search
                    </button>
                    <button className="btn btn-danger" type="button">
                      <i className="bi bi-check-circle-fill me-2"></i>Reset
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="main">
          {isLoading ? (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "55%",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CircularLoader size={40} />
            </div>
          ) :
            (
              <>
                <div className="filter-button-wrapper requestcardfilterbuttonWrapper">
                  <span class="FilterIconTextWrapper" onClick={handleFilterOpen}>
                    {" "}
                    {/* <i class="fa fa-filter" aria-hidden="true"></i> */}
                    <div className="FilterIcon">
                      {" "}
                      <img loading="lazy" src={FilterIcon} width={28} />
                      <div className="filtertext">Filters</div>
                    </div>
                  </span>
                  {/* <button
                    className="btn btn-primary requestcardfilterbuttonstyle"
                    onClick={handleFilterOpen}
                    id="FilterIconText"
                  >
                    Filter
                  </button> */}
                </div>
                <div className="scrollable-columns">
                  <div className="sticky-header-container">
                    <DataTableExtensions
                      {...tableData}
                      {...tableExtensions}
                      customStyles={customStyles}
                      filterPlaceholder="Search CustomizedPlans"
                    >
                      <DataTable
                        columns={columns}
                        data={data}
                        noHeader
                        defaultSortField="id"
                        sortIcon={<SortIcon />}
                        defaultSortAsc={true}
                        pagination
                        highlightOnHover
                        dense
                         paginationComponentOptions={{
                        ...paginationOptions,
                      }}
                        customStyles={customStyles}
                        noDataComponent={<div style={{padding:"24px"}}>{t("No_records_found")}</div>}
                      />
                    </DataTableExtensions>
                  </div>
                </div>
              </>
            )
          }

        </div>

        <div
          className="modal fade"
          id="planselectedit"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg dialog_wrapper_plan_superadmin">
            <div className="modal-content ">
              <div className="modal-header">
                <h5 className="modal-title L508" id="exampleModalLabel">
                  Edit Plan
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSave}>
                  {editedData?.is_enterprise == true && (
                    <div className="row g-3 mb-3">
                      <div className="col">
                        <div className="d-flex gap-2">
                          <input
                            disabled
                            checked={editedData?.is_enterprise}
                            // onChange={handleChangeIsEnterprise}
                            type="checkbox"
                          />
                          <div>Enterprise Plan</div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row g-3 mb-3">
                    <div className="col">
                      <input
                        type="text"
                        // className="form-control"
                        className={`form-control ${!isEmailFound ? 'input-error' : ''}`}
                        placeholder="Enter user Email*"
                        aria-label="email"
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                        onBlur={handleBlurEmail}
                        disabled
                      />
                      {!isEmailFound && <div className="error-message">{emailErrorMessage}</div>}
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Valid for Days*"
                        aria-label="expirydays"
                        value={expiryDays}
                        disabled
                        onChange={(e) => setExpiryDays(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row g-3 mb-3">
                    <div className="col">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Plan Name*"
                        aria-label="Name"
                        name="name"
                        value={editedData.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Plan Price*"
                        aria-label="Price"
                        name="price"
                        value={editedData.price}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="row g-3  mb-3">
                    <div className="col">
                      <select
                        className="form-select"
                        disabled={editedData?.is_enterprise}
                        name="duration"
                        value={editedData.duration}
                        onChange={handleInputChange}
                      >
                        <option value="" disabled>
                          Per Month
                        </option>
                        <option value="Per Month">Per Month</option>
                        <option value="Per Year">Per Year</option>
                      </select>
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="User Create Limit*"
                        aria-label="Max User"
                        name="max_users"
                        value={editedData.max_users}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="row g-3  mb-3">
                    <div className="col">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Business Create Limit*"
                        aria-label="Max Business"
                        name="business"
                        value={editedData.business}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Storage limit*"
                        aria-label="Storage limit"
                        value={editedData.storage_limit}
                        name="storage_limit"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="row g-3  mb-3">
                    <div className="col">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Max Theme Create Limit*"
                        aria-label="Max Theme"
                        name="themes"
                        value={editedData?.themes}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="row g-3  mb-3">
                    <div className="col d-none">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={editedData.enable_custdomain === "on"}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setEditedData({
                              ...editedData,
                              enable_custdomain: isChecked ? "on" : "off",
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckDefault"
                        >
                          Enable Domain2
                        </label>
                      </div>
                    </div>

                    <div className="col d-none">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={editedData.enable_custsubdomain === "on"}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setEditedData({
                              ...editedData,
                              enable_custsubdomain: isChecked ? "on" : "off",
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Enable Sub Domain
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3  mb-3">
                    <div className="col d-none">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={editedData.enable_branding === "on"}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setEditedData({
                              ...editedData,
                              enable_branding: isChecked ? "on" : "off",
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Enable Branding
                        </label>
                      </div>
                    </div>
                    <div className="col d-none">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={editedData.pwa_business === "on"}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setEditedData({
                              ...editedData,
                              pwa_business: isChecked ? "on" : "off",
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Progressive Web App (PWA3)
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3  mb-3">
                    <div className="col">
                      <div className="form-check form-switch Admin_editplan_inputcard_wrapper">
                        <input
                          className="form-check-input input_admineditplan_form"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={editedData.enable_qr_code === "on"}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setEditedData({
                              ...editedData,
                              enable_qr_code: isChecked ? "on" : "off",
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Enable QR Code
                        </label>
                      </div>
                    </div>
                    <div className="col d-none">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={editedData.enable_chatgpt === "on"}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setEditedData({
                              ...editedData,
                              enable_chatgpt: isChecked ? "on" : "off",
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Enable Chatgpt
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="box" id="AdminEditPlan_physicalcard_box">
                    <h3>Physical card</h3>
                    <div className="row g-3  mb-3">
                      <div className="col Admin_editplan_form">
                        <div className="form-check form-switch Admin_editplan_inputcard_wrapper">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                            checked={editedData.enable_physical_pvc === "on"}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setEditedData({
                                ...editedData,
                                enable_physical_pvc: isChecked ? "on" : "off",
                              });
                            }}
                          />
                          <label
                            className="form-check-label"
                            for="flexSwitchCheckDefault"
                          >
                            Enable PVC Card
                          </label>
                        </div>
                      </div>
                      <div className="col">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="0"
                          aria-label="No of card"
                          value={editedData.no_of_pvc_card}
                          name="no_of_pvc_card"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="row g-3  mb-3">
                      <div className="col Admin_editplan_form">
                        <div className="form-check form-switch Admin_editplan_inputcard_wrapper">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                            checked={editedData.enable_physical_metal === "on"}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setEditedData({
                                ...editedData,
                                enable_physical_metal: isChecked ? "on" : "off",
                              });
                            }}
                          />
                          <label
                            className="form-check-label"
                            for="flexSwitchCheckDefault"
                          >
                            Enable Metal Card
                          </label>
                        </div>
                      </div>
                      <div className="col">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="0"
                          aria-label="No of card"
                          value={editedData?.no_of_metal_card}
                          name="no_of_metal_card"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row g-3  mt-3">
                    <div className="col">
                      <textarea
                        className="form-control"
                        placeholder="Enter Plan Description*"
                        id="floatingTextarea2"
                        rows="5"
                        value={editedData?.description}
                        name="description"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="adminplan_buttonwrapper">
                    <button
                      type="button"
                      className="btn btn-secondary icon-btn adminplan_closebutton"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>

                    <button
                      type="submit"
                      className="btn btn-primary icon-btn adminplan_deletbutton"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      style={{ margin: "0 10px" }}
                      disabled={!isFormChanged}
                    >
                      Update
                    </button>
                  </div>
                </form>
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>

      </article>

    </>
  )
};
export default CustomizedPlans;
