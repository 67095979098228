import React, { useContext, useState, useRef } from "react";
// import "./TemplatComman.css";
import { userContext } from "../../../../context/userContext";
import { environmentVariables } from "../../../../config/env.config";
import { useEffect } from "react";
// import "./Template11.css";
import "./Theme9.css";
import axios from "axios";
import { useTranslation } from "react-i18next";
import logoImage from "../../../../Images/logo9.png";
// import "./Template11.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import theme6strip from "../../../../Images/theme6strip.png";
import TestimonialCarousel from "./TestimonialCarousel";
import GalleryCarousel from "./GalleryCarousel";
import { getServicesArray } from "../../../../utils/utility";
// import "./Theme1.css";

// import "./Template10.css";
const Theme9 = (props) => {
  const { t } = useTranslation();
  const { userData } = useContext(userContext);
  const [selectedDate, setSelectedDate] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [appointmentData, setAppointmentData] = useState([]);
  const touchStartX = useRef(0);
  const calendarInputRef = useRef(null);
  const handleChangeAppointmentDate = (dateVal) => {
    setSelectedDate(dateVal);

    const dateObject = new Date(dateVal);

    const dayIndex = dateObject.getDay();

    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    if (props?.appointmentArray && props?.appointmentArray?.length != 0) {
      setAppointmentData(
        props?.appointmentArray[`${daysOfWeek[dayIndex].toLowerCase()}`]
      );
    } else {
      // const parsedContent = JSON.parse(
      //   props?.businessObj?.appointmentObj?.content
      // );
      // setAppointmentData(
      //   parsedContent[`${daysOfWeek[dayIndex].toLowerCase()}`]
      // );
      try {
        const parsedContent = JSON.parse(
          JSON.parse(props?.businessObj?.appointmentObj?.content)
        );
        setAppointmentData(
          parsedContent[`${daysOfWeek[dayIndex].toLowerCase()}`]
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    const touchEndX = e.touches[0].clientX;
    const deltaX = touchEndX - touchStartX.current;

    if (Math.abs(deltaX) > 50) {
      // You can adjust this threshold to control how much swipe is needed to trigger the slide
      setCurrentIndex((prev) =>
        deltaX > 0
          ? Math.max(prev - 1, 0)
          : Math.min(prev + 1, testimonials.length - 1)
      );
    }
  };

  const handleTouchEnd = () => {
    touchStartX.current = 0;
  };
  // console.log(props.orderOfBlocks, "appointment inside iframe");

  useEffect(() => {
    getBlockOrder();
  }, [props?.businessObj, props.businessId, props.orderOfBlocks]);
  const getBrandNameForSocial = (brand) => {
    // console.log(brand);
    if (brand == "gmail") {
      return "google";
    } else return `${brand}`;
  };
  const getBlockOrder = async () => {
    // const handleGetBlock = async () => {
    if (props.orderOfBlocks) {
      let orders = {};
      props.orderOfBlocks.map((val) => {
        if (val.content == "Description") {
          orders["description"] = val?.position;
        } else if (val.content == "Contact Info") {
          orders["contact_info"] = val?.position;
        } else if (val.content == "Appointment") {
          orders["appointment"] = val?.position;
        } else if (val.content == "Service") {
          orders["service"] = val?.position;
        } else if (val.content == "Gallery") {
          orders["gallery"] = val.position;
        } else if (val.content == "Contact Form") {
          orders["contact_form"] = val.position;
        } else if (val.content == "Testimonials") {
          orders["testimonials"] = val.position;
        } else if (val.content == "Social") {
          orders["social"] = val.position;
        } else if (val.content == "Business Hour") {
          orders["business_hour"] = val.position;
        } else if (val.content == "Google Review") {
          orders["google_review"] = val.position;
        }
      });
      props.setOrder(orders);
    } else {
      try {
        let id = props?.businessObj?.id || props.businessId;
        if (props?.businessObj?.id) {
          let { data: get } = await axios.get(
            `${environmentVariables?.apiUrl}api/v1/business/FetchBlock?business_id=${id}`,
            {
              headers: {
                _token: userData?.token,
              },
              // withCredentials: true,
            }
          );
          // console.log(get?.data?.order, "order data");
          props.setOrder(get?.data?.order);
        }

        // console.log(get.data.order,"Getetgwef")
      } catch (err) {
        console.log(err, "error in template");
      }
    }
    // };
  };
  const getJSXElement = (elemName) => {
    // console.log(props?.appointmentArray, "elemNameelemName");
    if (elemName == "appointment") {
      return (
        props?.onState?.appointment && (
          // <article class="contact sec">
          //   <h3 class="sectitle">Appoinment</h3>
          //   <select class="form-select" aria-label="Default select example">
          //     <option selected>Select time</option>
          //     {props?.appointmentArray?.map((val) => (
          //       <>
          //         <option value="1">
          //           {props.changeTimeFormat(val?.startDate)} -{" "}
          //           {props.changeTimeFormat(val?.endDate)}{" "}
          //         </option>
          //       </>
          //     ))}
          //   </select>
          // </article>
          <article className="makeappitmneets sec" >
            <div className="appointment" style={{background: props?.dynamicBackgroundColor ? `${props?.dynamicBackgroundColor}80` : "", border: props?.dynamicBackgroundColor ? `1px solid ${props?.dynamicBackgroundColor}` : "" }} >
              <h3 class="sectitle" style={{color: props?.dynamicTextDescriptionColor || "" }}>{props?.appointmentsHeading || t("Make_appointment")}</h3>
              <div className="contact-form">
                <div class="col-12 mb-3" style={{position:'relative'}}>
                  {/* <input
                    type="date"
                    class="form-control clear_string"
                    placeholder="Date"
                    name="currentDate"
                    value={selectedDate}
                    onChange={(e) => handleChangeAppointmentDate(e.target.value)}
                  /> */}
                  <DatePicker
                    selected={selectedDate}
                    ref={calendarInputRef}
                    minDate={new Date()}
                    placeholderText={t("Date")}
                    
                    onChange={(date) => handleChangeAppointmentDate(date)}
                    className="form-control"
                    popperPlacement="bottom-end"
                  />
                  {/* <div className="calanderIcon firefox-hide" onClick={openCalendar}><img src={environmentVariables?.s3UrlDarkCalenderIconDays} alt="Calendar Icon" /></div> */}
                  <div
                    className="calanderIcon firefox-hide"
                    style={{position:'absolute', top:'12px', right:'8px'}}
                    onClick={() => calendarInputRef.current.setOpen(true)}
                  >
                    <img src={environmentVariables?.s3UrlDarkCalenderIconDays} alt="Calendar Icon" />
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <select
                    class="form-select"
                  
                    aria-label="Default select example"
                    // onChange={(e) => setSelectedTime(e.target.value)}
                  >
                    <option value={`${t("Select_time")}`}>{t("Select_time")}</option>
                    {appointmentData?.map((val) => (
                      <option
                        key={val?.startDate}
                        value={`${val?.startDate} - ${val?.endDate}`}
                      >
                        {`${val?.startDate} - ${val?.endDate}`}
                      </option>
                    ))}
                  </select>
                </div>
                <div class="col-12 mb-3">
                  <button className="btn btnmakeappitmnets" style={{background: props?.dynamicCtaColor,color:props?.dynamicCtaTextColor}}>
                    {t("Make_an_appointment")}
                  </button>
                </div>
              </div>
            </div>
          </article>
        )
      );
    } else if (elemName == "business_hour") {
      return (
        props?.onState?.businessHours && (
          <article className="businessHours_sec" style={{backgroundColor: props?.dynamicMainBackgroundColor || ""}}>
            <h3 class="sectitle"  style={{color: props?.dynamicTextHeadingColor || "" }}>{props?.businessHourHeading || t("Working_hours")}</h3>

            <ul class="timelist" style={{border: props?.dynamicTextHeadingColor ? `1px solid ${props?.dynamicTextHeadingColor}` : ""}}>
              {props?.businessHourObject?.isMonday &&
                props?.businessHourObject?.modayStartDate &&
                props?.businessHourObject?.modayEndDate && (
                  <li>
                    <div className="day" style={{color: props?.dynamicTextHeadingColor ? `${props?.dynamicTextHeadingColor}B3`  : "" }}>{t("Monday")}</div>
                    <div className="time"  style={{color: props?.dynamicTextHeadingColor ? `${props?.dynamicTextHeadingColor}B3`  : "" }}>
                      {" "}
                      <span >
                        {props.changeTimeFormat(
                          props?.businessHourObject?.modayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span >
                        {props.changeTimeFormat(
                          props?.businessHourObject?.modayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              {props?.businessHourObject?.isTuesday &&
                props?.businessHourObject?.tuesdayStartDate &&
                props?.businessHourObject?.tuesdayEndDate && (
                  <li>
                    <div className="day" style={{color: props?.dynamicTextHeadingColor ? `${props?.dynamicTextHeadingColor}B3`  : "" }}>{t("Tuesday")}</div>
                    <div className="time"  style={{color: props?.dynamicTextHeadingColor ? `${props?.dynamicTextHeadingColor}B3`  : "" }}>
                      {" "}
                      <span >
                        {props.changeTimeFormat(
                          props?.businessHourObject?.tuesdayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span >
                        {props.changeTimeFormat(
                          props?.businessHourObject?.tuesdayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              {props?.businessHourObject?.isWednesDay &&
                props?.businessHourObject?.wednesdayStartDate &&
                props?.businessHourObject?.wednesdayEndDate && (
                  <li>
                    <div className="day" style={{color: props?.dynamicTextHeadingColor ? `${props?.dynamicTextHeadingColor}B3`  : "" }}>{t("Wednesday")}</div>
                    <div className="time"  style={{color: props?.dynamicTextHeadingColor ? `${props?.dynamicTextHeadingColor}B3`  : "" }}>
                      {" "}
                      <span >
                        {props.changeTimeFormat(
                          props?.businessHourObject?.wednesdayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span >
                        {props.changeTimeFormat(
                          props?.businessHourObject?.wednesdayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              {props?.businessHourObject?.isthrusdayDay &&
                props?.businessHourObject?.thrusdayStartDate &&
                props?.businessHourObject?.thrusdayEndDate && (
                  <li>
                    <div className="day" style={{color: props?.dynamicTextHeadingColor ? `${props?.dynamicTextHeadingColor}B3`  : "" }}>{t("Thursday")}</div>
                    <div className="time"  style={{color: props?.dynamicTextHeadingColor ? `${props?.dynamicTextHeadingColor}B3`  : "" }}>
                      {" "}
                      <span >
                        {props.changeTimeFormat(
                          props?.businessHourObject?.thrusdayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span >
                        {props.changeTimeFormat(
                          props?.businessHourObject?.thrusdayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              {props?.businessHourObject?.isfridayDay &&
                props?.businessHourObject?.fridayStartDate &&
                props?.businessHourObject?.fridayEndDate && (
                  <li>
                    <div className="day" style={{color: props?.dynamicTextHeadingColor ? `${props?.dynamicTextHeadingColor}B3`  : "" }}>{t("Friday")}</div>
                    <div className="time" style={{color: props?.dynamicTextHeadingColor ? `${props?.dynamicTextHeadingColor}B3`  : "" }}>
                      {" "}
                      <span >
                        {props.changeTimeFormat(
                          props?.businessHourObject?.fridayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span >
                        {props.changeTimeFormat(
                          props?.businessHourObject?.fridayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              {props?.businessHourObject?.issaturdayDay &&
                props?.businessHourObject?.SaturdayStartDate &&
                props?.businessHourObject?.SaturdayEndDate && (
                  <li>
                    <div className="day" style={{color: props?.dynamicTextHeadingColor ? `${props?.dynamicTextHeadingColor}B3`  : "" }}>{t("Saturday")}</div>
                    <div className="time"  style={{color: props?.dynamicTextHeadingColor ? `${props?.dynamicTextHeadingColor}B3`  : "" }}>
                      {" "}
                      <span >
                        {props.changeTimeFormat(
                          props?.businessHourObject?.SaturdayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span >
                        {props.changeTimeFormat(
                          props?.businessHourObject?.SaturdayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              {props?.businessHourObject?.issundayDay &&
                props?.businessHourObject?.SundayStartDate &&
                props?.businessHourObject?.SundayEndDate && (
                  <li>
                    <div className="day" style={{color: props?.dynamicTextHeadingColor ? `${props?.dynamicTextHeadingColor}B3`  : "" }}>{t("Sunday")}</div>
                    <div className="time"  style={{color: props?.dynamicTextHeadingColor ? `${props?.dynamicTextHeadingColor}B3`  : "" }}>
                      {" "}
                      <span>
                        {props.changeTimeFormat(
                          props?.businessHourObject?.SundayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span>
                        {props.changeTimeFormat(
                          props?.businessHourObject?.SundayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
            </ul>
          </article>
        )
      );
    } else if (elemName == "contact_info") {
      return (
        props?.onState?.contactInfo && (
          <article class="contact sec" >
            <div className="contact-inner" style={{background: props?.dynamicBackgroundColor ? `${props?.dynamicBackgroundColor}80` : "", border: props?.dynamicBackgroundColor ? `1px solid ${props?.dynamicBackgroundColor}` : "" }}>
              <h3 class="sectitle" style={{color: props?.dynamicTextDescriptionColor || "" }}>{props?.contactHeading ||t("Contact")} </h3>
              <ul class="conlink">
                <li style={{backgroundColor: props?.dynamicBackgroundColor ? `${props?.dynamicBackgroundColor}80` : "" }}>
                  <a className="infomain">
                    {/* <span className="icon-bg" style={{background:props?.dynamicMainBackgroundColor || ''}}> */}
                      <i class="fa-solid fa-phone"  style={{ padding: "7px", color: props?.dynamicTextHeadingColor || "#dc3545", background:props?.dynamicMainBackgroundColor || '#fff',borderRadius:'50%'}}></i>
                    {/* </span> */}
                    <div className="content">
                      <span className="label"style={{color: `${props?.dynamicTextDescriptionColor}` || "" }}>{(t("Mobile"))}</span>
                      <span className="value"  style={{color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}B3` : "" }}>
                        {props?.contactInfo?.contactInfoPhone}
                      </span>
                      {props?.contactInfo?.optionalContactInfoPhone?.split(
                        "-"
                      )?.[1]?.length > 0 && (
                        <span  style={{color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}B3` : "" }}>
                          {" "}
                          {props?.contactInfo?.optionalContactInfoPhone}{" "}
                        </span>
                      )}
                    </div>
                  </a>
                </li>

                <li style={{backgroundColor: props?.dynamicBackgroundColor ? `${props?.dynamicBackgroundColor}80` : "" }}>
                  <a className="infomain">
                    {/* <span className="icon-bg" style={{background:props?.dynamicMainBackgroundColor || ''}}> */}
                      <i class="fa-solid fa-envelope"  style={{ padding: "7px", color: props?.dynamicTextHeadingColor || "#dc3545", background:props?.dynamicMainBackgroundColor || '#fff',borderRadius:'50%'}}></i>
                    {/* </span> */}
                    <div className="content">
                      <span className="label" style={{color: `${props?.dynamicTextDescriptionColor}` || "" }}>{t("Email")}</span>
                      <span className="value" style={{color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}B3` : "" }}>
                        {props?.contactInfo?.contactInfoEmail}
                      </span>
                    </div>
                  </a>
                </li>
                {/* <li>
                <a href="">
                <i class="fa-solid fa-envelope"></i>
                  <span>
                   
                    {props?.contactInfo?.contactInfoWebUrl ||
                      `www.Shreyassinghal.com`}
                  </span>
                </a>
              </li> */}
                {props?.contactInfo?.contactInfoWebUrl && (
                  <li style={{backgroundColor: props?.dynamicBackgroundColor ? `${props?.dynamicBackgroundColor}80` : "" }}>
                    <a className="infomain">
                      {/* <span className="icon-bg" style={{background:props?.dynamicMainBackgroundColor || ''}}> */}
                        <i class="fa-solid fa-link" style={{ padding: "7px", color: props?.dynamicTextHeadingColor || "#dc3545", background:props?.dynamicMainBackgroundColor || '#fff',borderRadius:'50%'}}></i>
                      {/* </span> */}
                      <div className="content">
                        <span className="label"style={{color: `${props?.dynamicTextDescriptionColor}` || "" }}>{t("Web_url")}</span>
                        {/* <span className="value">{props?.contactInfo?.contactInfoEmail}</span> */}
                        <span
                          style={{ marginLeft: "5px", wordBreak: "break-all", color: props?.dynamicTextDescriptionColor ?  `${props?.dynamicTextDescriptionColor}B3` : "" }}
                        >
                          {props?.contactInfo?.contactInfoWebUrl}
                        </span>
                      </div>
                    </a>
                  </li>
                )}
                {props?.contactInfo?.contactInfoAddress && (
                  <li style={{backgroundColor: props?.dynamicBackgroundColor ? `${props?.dynamicBackgroundColor}80` : "" }}>
                    <a className="infomain">
                      {/* <span className="icon-bg" style={{background:props?.dynamicMainBackgroundColor || ''}}> */}
                        <i class="fa-solid fa-map " style={{ padding: "7px", color: props?.dynamicTextHeadingColor || "#dc3545", background:props?.dynamicMainBackgroundColor || '#fff',borderRadius:'50%'}}></i>
                      {/* </span> */}
                      <div className="content">
                        <span className="label"style={{color: `${props?.dynamicTextDescriptionColor}` || "" }}>{t("Address")}</span>
                        <span className="value" style={{color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}B3` : "" }}>
                          {props?.contactInfo?.contactInfoAddress}
                        </span>
                      </div>
                    </a>
                    {props?.contactInfo?.contactInfoOptAddress && (
                      <a className="infomain" style={{ paddingLeft: "50px" }}>
                        <div className="content">
                          <span className="label" style={{color: props?.dynamicTextDescriptionColor || "" }}>{t("Alternative_address")}</span>
                          <span className="value" style={{color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}B3` : "" }}>
                            {props?.contactInfo?.contactInfoOptAddress}
                          </span>
                        </div>
                      </a>
                    )}
                  </li>
                )}
              </ul>
            </div>
          </article>
        )
      );
    } else if (elemName == "custom_html") {
      return <></>;
    } else if (elemName == "google_review") {
      return (
        props?.onState?.googlereview == true && (
          <article class="abouttemplate sec"  style={{backgroundColor: props?.dynamicMainBackgroundColor || "" }}>
            <h3 class="sectitle" style={{color: props?.dynamicTextHeadingColor || "" }}>{props?.reviewHeading || t("Google_review")}</h3>
            <ul class="conlink">
              <li style={{ background: "none" }}>
                <p
                  style={{
                    wordBreak: "break-all",
                    maxHeight: "77px",
                    overflow: "hidden",
                    fontSize: "16px",
                    color: props?.dynamicTextHeadingColor ? `${props?.dynamicTextHeadingColor}B3` : "",
                  }}
                >
                  {props?.googlereview !== undefined && props?.googlereview}
                </p>
              </li>
            </ul>
          </article>
        )
      );
    } else if (elemName == "contact_form") {
      return (
        props?.onState?.contactForm && (
          <article className="Conatctform_sec"  >
            <div className="contactform-sec-inner" style={{background: props?.dynamicBackgroundColor ? `${props?.dynamicBackgroundColor}80` : "", border: props?.dynamicBackgroundColor ? `1px solid ${props?.dynamicBackgroundColor}` : "" }}>
              <h3 class="Contact_Form_sectitle" style={{color: props?.dynamicTextDescriptionColor || "" }}>{props?.contactformHeading || t("Contact")} </h3>
              <div class="contact-form">
                <form>
                  <div class="col-12 mb-3">
                    <input
                      type="text"
                      class="form-control clear_string"
                      placeholder={t("Full_name")}
                      name="last_name"
                       style={{border:`1px solid ${props?.dynamicTextDescriptionColor}`}}
                      value=""
                      required=""
                    />
                  </div>
                  <div class="col-12 mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder={t("Email")}
                      name="email"
                       style={{border:`1px solid ${props?.dynamicTextDescriptionColor}`}}
                      value=""
                      required=""
                    />
                  </div>
                  <div class="col-12 mb-3">
                    <input
                      type="text"
                      class="form-control clear_string"
                      maxlength="10"
                      minlength="10"
                      placeholder={t("Mobile_number")}
                      name="mobile_number"
                       style={{border:`1px solid ${props?.dynamicTextDescriptionColor}`}}
                      value=""
                      required=""
                    />
                  </div>
                  <div class="col-12 mb-3">
                    <textarea
                      class="form-control clear_string"
                      rows="3"
                      placeholder={`${t("Message")}*`}
                      required=""
                      name="message"
                       style={{border:`1px solid ${props?.dynamicTextDescriptionColor}`}}
                    ></textarea>
                  </div>

                  <div class="col-12 mb-3">
                    <button type="button" class="btn btn-submitform" style={{background: props?.dynamicCtaColor ,color:props?.dynamicCtaTextColor}}>
                      {t("Submit")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </article>
        )
      );
    } else if (elemName == "description") {
      return (
        <article className="abouttemplate sec" style={{backgroundColor: props?.dynamicMainBackgroundColor || "" }}>
          <h3 class="sectitle" style={{color: props?.dynamicTextHeadingColor || "" }}>{props.descriptionHeading ? props.descriptionHeading : t("About_us")} </h3>
          <p class="aboutus_paragraph" style={{color: props?.dynamicTextHeadingColor? `${props?.dynamicTextHeadingColor}B3` : "" }}
           dangerouslySetInnerHTML={{
            __html: (props?.description).replace(/(?:\r\n|\r|\n)/g, '<br>'),
          }}></p>
        </article>
      );
    } else if (elemName == "gallery") {
      return (
        props?.onState?.gallery && (
          <article class="gallery sec sec-common"  >
            <div className="gallery-inner" style={{background: props?.dynamicBackgroundColor ? `${props?.dynamicBackgroundColor}80` : "", border: props?.dynamicBackgroundColor ? `1px solid ${props?.dynamicBackgroundColor}` : "" }}>
              <h3 class="sectitle" style={{color: props?.dynamicTextDescriptionColor || "" }}>{props?.galleryHeading || t("Portfolio")} </h3>
              {props?.galleryData && (
                <>
                  <GalleryCarousel slides={props?.galleryData} colordata={props} />
                </>
              )}
              {/* {props.existingGalleryImages ? (
              <GalleryCarousel slides={props?.existingGalleryImages} />
            ) : (
              props?.gallaryObject?.gallaryArray && (
                <GalleryCarousel slides={props?.gallaryObject?.gallaryArray} />
              )
            )} */}
              {/* {props.galleryView && (
              <GalleryCarousel slides={props?.galleryView} staticImage={true} />
            )} */}
              {/* {props?.gallaryObject?.gallary_video && (
              <video className="mx-auto d-block img-fluid" controls>
                <source
                  src={
                    props?.gallaryObject?.gallary_video &&
                    `${environmentVariables?.apiUrl}uploads/${props?.gallaryObject?.gallary_video}`
                  }
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            )} */}
            </div>
          </article>
        )
      );
    } else if (elemName == "more") {
      return <></>;
    } else if (elemName == "service") {
      return (
        props?.onState?.services && (
          <article className="servicesName sec" class="Service_name" >
            <div className="services" style={{background: props?.dynamicBackgroundColor ? `${props?.dynamicBackgroundColor}80` : "", border: props?.dynamicBackgroundColor ? `1px solid ${props?.dynamicBackgroundColor}` : "" }}>
              <h3 class="sectitle" style={{color: props?.dynamicTextDescriptionColor || "" }}>{props?.serviceHeading || t("Services")} </h3>
              <div
                className="mainservicebox"
                style={{
                  height: "260px",
                  overflow: "scroll",
                  marginLeft: "0",
                  marginRight: "0",
                  
                }}
              >
                {props?.servicesArray && (
                  <ul className="servesbox">
                    <h3
                      style={{
                        fontFamily: "jost",
                        fontSize: "20px",
                        fontWeight: "600",
                        textAlign: "center",
                        marginBottom: "10px",
                        color: props?.dynamicTextDescriptionColor || "white"
                      }}
                    >
                      {t("Men")}
                    </h3>

                    {getServicesArray(props?.servicesArray).map(
                      (val) =>
                        val?.name != null &&
                        (val?.description === "male" ||
                          val?.description === "all" || val?.description === "undefined") && (
                          <li key={val?.name}  style={{ border: props?.dynamicCtaColor ? `1px solid ${props?.dynamicCtaColor}` : "" ,background: props?.dynamicTextHeadingColor ? `${props?.dynamicTextHeadingColor}40` : "",  color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}B3` : "" }}> {val?.name}</li>
                        )
                    )}
                  </ul>
                )}
                {props?.servicesArray && (
                  <ul className="servesbox">
                    <h3
                      style={{
                        fontFamily: "jost",
                        fontSize: "20px",
                        fontWeight: "600",
                        textAlign: "center",
                        marginBottom: "10px",
                        color: props?.dynamicTextDescriptionColor || "white",
                      }}
                    >
                      {t("Women")}
                    </h3>

                    {getServicesArray(props?.servicesArray).map(
                      (val) =>
                        val?.name != null &&
                        (val?.description === "female" ||
                          val?.description === "all") && (
                          <li key={val?.name}  style={{ border:props?.dynamicCtaColor ? `1px solid ${props?.dynamicCtaColor}` : "" , color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}B3` : "" }}> {val?.name}</li>
                        )
                    )}
                  </ul>
                )}
              </div>
            </div>
          </article>
        )
      );
    } else if (elemName == "social") {
      return (
        props?.onState?.socials && (
          <article className="social-links-sec sec" style={{backgroundColor: props?.dynamicMainBackgroundColor || "" }}>
            <h3 class="sectitle" style={{color: props?.dynamicTextHeadingColor || "" }}>{props?.socialHeading || t("Social_media")}</h3>
            <ul class="socil-link">
              {/* {props?.socialMediaData?.map((val) => (
                <li class="social_media_li">
                  <a href={val?.link}>
                    <i
                      class={`fa-brands fa-${getBrandNameForSocial(
                        val?.nameOfSocial.toLowerCase()
                      )}`}
                    ></i>
                  </a>
                </li>
              ))} */}
              {props?.socialMediaData?.map(
                (val) =>
                  val.link &&
                  getBrandNameForSocial(val.nameOfSocial.toLowerCase()) && (
                    <li className="social_media_li" key={val.link} style={{background: props?.dynamicTextHeadingColor ? `${props?.dynamicTextHeadingColor}40` : "" }}>
                      <a>
                        {/* <i
                          className={`fa-brands fa-${getBrandNameForSocial(
                            val.nameOfSocial.toLowerCase()
                          )}`}
                        ></i> */}
                        <i
                          className={`fa-${
                            val?.nameOfSocial.toLowerCase() === "star"
                              ? "solid"
                              : "brands"
                          } fa-${getBrandNameForSocial(
                            val?.nameOfSocial.toLowerCase()
                          )}`}
                          style={{color: props?.dynamicTextHeadingColor }}
                        ></i>
                      </a>
                    </li>
                  )
              )}
            </ul>
          </article>
        )
      );
    } else if (elemName == "testimonials") {
      return (
        props?.onState?.testimonials && (
          <article class="testimoniyalbox sec" >
            <div className="testimonial-inner" style={{background: props?.dynamicBackgroundColor ? `${props?.dynamicBackgroundColor}80` : "", border: props?.dynamicBackgroundColor ? `1px solid ${props?.dynamicBackgroundColor}` : "" }}>
              <h3 class="sectitle testimonial" style={{color: props?.dynamicTextDescriptionColor || "" }}>{props?.testimonialHeading || t("Testimonial")} </h3>

              <div>
                <TestimonialCarousel
                  testimonials={props?.testimonialArray}
                  currentElem={currentIndex}
                  handleTouchStart={handleTouchStart}
                  handleTouchMove={handleTouchMove}
                  handleTouchEnd={handleTouchEnd}
                  colordata={props}
                />
              </div>
              <div className="carousel-container">
                <button
                  onClick={() =>
                    currentIndex == 0
                      ? setCurrentIndex(props.testimonialArray.length - 1)
                      : setCurrentIndex((prev) => prev - 1)
                  }
                  className="carousel-btn colorcta colordescription"
                  style={{background: props?.dynamicCtaColor || "",borderColor: props?.dynamicCtaColor || "",color: props?.dynamicCtaTextColor||'' }}
                >
                  <i class="fa fa-angle-left"></i>
                </button>
                <button 
                  onClick={() => {
                    currentIndex == props.testimonialArray.length - 1
                      ? setCurrentIndex(0)
                      : setCurrentIndex((prev) => prev + 1);
                  }}
                  className="carousel-btn colorcta colordescription"
                  style={{background: props?.dynamicCtaColor || "",borderColor: props?.dynamicCtaColor || "",color: props?.dynamicCtaTextColor||'' }}
                >
                  <i class="fa fa-angle-right"></i>
                </button>
              </div>
            </div>
          </article>
        )
      );
    }else if (elemName == "alternateUrls") {
          return (
           props?.onState?.alternateurl && (
            <>
            <article className="servicesName sec" style={{background:props?.dynamicMainBackgroundColor || '', marginLeft:'10px', marginRight:'10px'}}>
              <h3 className="sectitle service" style={{color:props?.dynamicTextHeadingColor || ""}}>{props?.alternateUrlsHeading || t("Useful_links")}</h3>
              <div className="mainservicebox">
                {props?.alternateUrlArray && (
                  <ul className="servesbox">
                    {getServicesArray(props?.alternateUrlArray).map((val) => {
                      const url = val?.description?.startsWith("http")
                        ? val?.description
                        : `https://${val?.description}`;
                      return (
                        val?.name &&
                        val?.description && (
                          <li key={val?.name} style={{background: props?.dynamicTextHeadingColor ? `${props?.dynamicTextHeadingColor}40` : ""}}>
                            <a href={url} target="_blank" style={{color: props?.dynamicTextHeadingColor ? `${props?.dynamicTextHeadingColor}B3` :  "#fff"}} rel="noopener noreferrer">
                              {val?.name}
                            </a>
                          </li>
                        )
                      );
                    })}
                  </ul>
                )}
              </div>
            </article>
            </>
           )
          )
        }
  };

  const getBlocksOrder = () => {
    let BlocksArray = [];
    for (const key in props.order) {
      BlocksArray[props.order[key]] = getJSXElement(key);
    }
    return BlocksArray;
  };

  return (
    <div>
      <div class="main-temaplt" id="temp9">
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ width: "420px",background: props?.dynamicMainBackgroundColor }} className="col-12 wtemplate">
              {/* <div class="circle"></div>
              <div class="circle2"></div> */}
              {/* <article className="imgsechere sec">
                <img
                  src={
                    props?.bannerImageUrl
                      ? props.getImage(props.bannerImageUrl)
                      : null
                  }
                  className="mx-auto d-block img-fluid coverimghere "
                />
                <img
                  src={
                    props?.logoImageUrl
                      ? props.getImage(props?.logoImageUrl)
                      : null
                  }
                  className="mx-auto d-block img-fluid logo-profile"
                />
              </article>

              <article className="personalinfo sec">
                <h3 className="username">{props.title || "Name"}</h3>
                <p className="titleuser">
                  {props.designation || "Designation"}
                </p>
                <p className="companeyname">
                  {props.subTitle || "Company Name"}
                </p>
              </article> */}
              <div className="top-section">
                <article className="imgsechere sec">
                  <img
                    src={
                      props?.bannerImageUrl
                        ? props.getImage(props.bannerImageUrl)
                        : environmentVariables?.s3BannerUrlTheme9
                    }
                    className="mx-auto d-block img-fluid coverimghere "
                  />
                </article>
                <article class="personalinfo sec">
                  <div class="innerinfo">
                    <h3 className="username" style={{color: props?.dynamicTextHeadingColor || ""}}>{props.subTitle || "Name"}</h3>
                    <p className="titleuser" style={{color: props?.dynamicTextHeadingColor || ""}}>
                      {props.designation || "Designation"}
                    </p>
                    {props?.onState && props?.onState?.googlereview && (
                      <div className="reviewratingcontainer">
                        <p className="reviewrating" style={{color: props?.dynamicTextHeadingColor || ""}}>
                          {/* <StarRating value={props?.rating} totalStars={5} starSize="16px" themeNo={9} /> */}
                          <img
                            src={environmentVariables?.s3Star1}
                            style={{ width: "20px", paddingRight: "5px" }}
                          />
                          <span className="ratingspan" style={{color: props?.dynamicTextHeadingColor || ""}}> {props.rating}</span> (
                          {props.review} {t("Reviews")})
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="logo-parenta">
                    <img
                      src={
                        props?.logoImageUrl
                          ? props.getImage(props?.logoImageUrl)
                          : environmentVariables?.s3UrllogoUrlTheme9
                      }
                      className="mx-auto d-block img-fluid logo-profile"
                    />
                  </div>
                </article>
              </div>
              {/* <article class="personalinfo sec">
                <img
                  src={
                    props?.logoImageUrl
                      ? props.getImage(props?.logoImageUrl)
                      : logoImage
                  }
                  className="mx-auto d-block img-fluid logo-profile"
                />

                <div class="innerinfo">
                  <h3 className="username">{props.subTitle || "Name"}</h3>
                  <p className="titleuser">
                    {props.designation || "Designation"}
                  </p> */}
              {/* <p className="companeyname">{rs.sub_title || "Company Name"}</p> */}
              {/* <!-- <p class='companeyname'>Company</p> --> */}
              {/* </div>
              </article> */}
              {getBlocksOrder().map((val) => (val == undefined ? <></> : val))}

              {/* <div class="fixedd">
                <article class="fixedfooter">
                  <div class="footerleft">
                    <div
                      class="qrcodeshaere"
                      data-bs-toggle="modal"
                      data-bs-target="#appotiments"
                    >
                      <i class="fa-solid fa-qrcode"></i>
                    </div>
                  </div>
                  <div class="addtocinatct">+Add To Contact</div>
                </article>
              </div> */}
              <div
                class="modal fade"
                id="appotiments"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Modal title
                      </h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <article class="personalinfo modal-info ">
                        <canvas
                          height="160"
                          width="160"
                          style={{ height: "128px", width: "128px" }}
                        ></canvas>
                        <h3 class="username">Subhan</h3>
                        <p class="titleuser">Software Developer</p>
                        <p class="companeyname">Xenophobe Tech</p>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        //   ref={createUserRef}
        id="appotimnets"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                {t("Make_appointment")}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form className="row">
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control clear_string"
                    placeholder="Full Name"
                    //   value={appointmentName}
                    //   onChange={(e) =>
                    //     setAppointmentName(e.target.value)
                    //   }
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    eror
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control clear_string"
                    placeholder="Email"
                    //   value={appointmentEmail}
                    //   onChange={(e) =>
                    //     setAppointmentEmail(e.target.value)
                    //   }
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",
                      bottom: "70px",
                    }}
                  >
                    'errormsg'
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control clear_string"
                    placeholder="Mobile No."
                    //   value={appointmentContactNumber}
                    //   onChange={(e) =>
                    //     setAppointmentContactNumber(e.target.value)
                    //   }
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    'errormsgni'
                  </div>
                </div>
                <div class="mb-2 mt-4 col-md-12">
                  <button
                    //   onClick={handleSubmits}
                    class="btn btn-primary me-3"
                    type="button"
                  >
                    <i class="bi bi-check-circle-fill ms-2 "></i>
                    Submit
                  </button>
                </div>
              </form>
            </div>
            {/* <ToastContainer /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theme9;
