import React, { useRef, useState } from "react";
import bannerImage from "../../../../Images/theme-28-banner.png";
import topright from "../../../../Images/theme18-top-right.png";
import logoImage from "../../../../Images/theme28-logo.png";
// import testimonialImage from "../../../../Images/avatar.png";
// import FacebookIcon from "../../../../Images/theme18-social1.png";
// import InstagramIcon from "../../../../Images/theme-18-social2.png";
// import TweeterIcon from "../../../../Images/theme-18-social3.png";
// import LinedinIcon from "../../../../Images/theme18-social4.png";
// import WhatsappIcon from "../../../../Images/theme-18-social5.png";
// import CalanderIconn from "../../../../Images/theme18-calendarIcondays.png";

import "./Theme28.css";
import GalleryCarousel from "./GalleryCarousel";
import TestimonialCarousel from "./TestimonialCarousel";
const Theme28Template = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const calendarInputRef = useRef(null);
  const testimonialArray = [
    {
      name: "Rachel McDermott",
      description:
        "I have been under their care for several years now to every appointment.",
      formCount: "0",
      image: "null",
    },
    {
      name: "Ernest Reinger",
      description:
        "I have been under their care for several years now to every appointment.",
      formCount: "1",
      image: "null",
    },
    {
      name: " Reinger",
      description:
        "I have been under their care for several years now to every appointment.",
      formCount: "2",
      image: "null",
    },
  ];
  const galleryArray =[
    {
        "id": 754,
        "business_id": 2617,
        "image": "1728992262241abc.png",
        "created_by": 202,
        "created_at": "2024-10-15T11:37:42.000Z",
        "updated_at": "2024-10-15T11:37:42.000Z"
    },
    {
        "id": 755,
        "business_id": 2617,
        "image": "1728992266106abcd2.png",
        "created_by": 202,
        "created_at": "2024-10-15T11:37:46.000Z",
        "updated_at": "2024-10-15T11:37:46.000Z"
    },
    {
        "id": 756,
        "business_id": 2617,
        "image": "1728992269950Group1321314860.png",
        "created_by": 202,
        "created_at": "2024-10-15T11:37:49.000Z",
        "updated_at": "2024-10-15T11:37:49.000Z"
    },
    {
        "id": 757,
        "business_id": 2617,
        "image": "1728992273261ww.png",
        "created_by": 202,
        "created_at": "2024-10-15T11:37:53.000Z",
        "updated_at": "2024-10-15T11:37:53.000Z"
    },
    {
        "id": 758,
        "business_id": 2617,
        "image": "1728992277107www.png",
        "created_by": 202,
        "created_at": "2024-10-15T11:37:57.000Z",
        "updated_at": "2024-10-15T11:37:57.000Z"
    },
    {
        "id": 759,
        "business_id": 2617,
        "image": "1728992281679rrrr.png",
        "created_by": 202,
        "created_at": "2024-10-15T11:38:01.000Z",
        "updated_at": "2024-10-15T11:38:01.000Z"
    }
]

  const openCalendar = () => {

    if (calendarInputRef.current) {
      calendarInputRef.current.showPicker();

    }
  };
  return (
    <div>
      <div class="main-temaplt" id="temp28">
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ width: "420px" }} className="col-12 wtemplate">

              <article className="imgsechere sec">
                {/* <div className="imgsechere-top">
                  <div className="imgsechere-top-left">  9:41  </div>
                  <div className="imgsechere-top-right"> <img loading="lazy" src={topright} alt="top right" />  </div>
                </div> */}
                <div className="imgsechere-main-banner">
                <img
                  src={bannerImage}
                  className="mx-auto d-block img-fluid coverimghere "
                  style={{ width: "100%" }}
                />
                </div>                
              </article>


              <div >
                  <img
                    src={logoImage}
                    className="mx-auto d-block img-fluid logo-profile"
                  />
                </div>


              <section className="mainsection ">


              <article className="personalinfo">
                <h3 className="username">Yannic kalléno</h3>
                <p className="titleuser">Deep Cleaning</p>
              </article>


              <article className="social-links-sec sec">
                <h3 class="sectitle">Social Media</h3>
                <ul class="socil-link socil-link-inner">
                  <li>
                    <a>
                      <i class={`fa-brands fa-facebook`}></i>
              
                    </a>
                  </li>
                  <li>
                    <a>
                      <i class={`fa-brands fa-instagram`}></i>
                     
                    </a>
                  </li>

                  <li>
                    <a>
                      <i class={`fa-brands fa-youtube`}></i>
                 
                    </a>
                  </li>
                  <li>
                    <a>
                      <i class={`fa-brands fa-twitter`}></i>
                      {/* <img loading="lazy" src={WhatsappIcon} /> */}
                    </a>
                  </li>
                  <li>
                    <a>
                      <i class={`fa-brands fa-linkedin`}></i>
                      {/* <img loading="lazy" src={LinedinIcon} /> */}
                    </a>
                  </li>
                </ul>
              </article>





              <article class="contact sec">
                <h3 class="sectitle">Contact Info</h3>
                <ul class="conlink">
                  <li>
                    <a href="">
                      <i class="fa-solid fa-phone"></i>
                      <span>{`9876543210`}</span>
                    </a>
                  </li>

                  <li>
                    <a href="">
                      <i class="fa-solid fa-envelope"></i>
                      <span>{`Yannickalléno@gmail.com`}</span>
                    </a>
                  </li>

                  <li>
                    <a href="">
                      <i class="fa-solid  fa-location-dot"></i>
                      <span >{`1901 Thornridge Cir. Shiloh, Hawaii 81063`}</span>
                    </a>
                  </li>
                </ul>
              </article>



              <article class="gallery ">
                <h3 class="sectitle">Gallery </h3>
              <div className="gallerysec"> <GalleryCarousel slides={galleryArray} />
              </div>

              </article>






              <article className="conatctform sec">
                <h3 class="sectitle">Contact Us</h3>
                <div class="contact-form">
                  <form>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control clear_string"
                        placeholder="Full Name"
                        name="last_name"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="abc@gmail.com"
                        name="email"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control clear_string"
                        maxlength="10"
                        minlength="10"
                        placeholder="Mobile Number"
                        name="mobile_number"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <textarea
                        class="form-control clear_string"
                        rows="3"
                        placeholder="Your message goes here..."
                        required=""
                        name="message"
                      ></textarea>
                    </div>

                    <div class="col-12 mb-3">
                      <button type="submit" class="btn btn-submitform">
                        Messege
                      </button>
                    </div>
                  </form>
                </div>
              </article>










              <article className="abouttemplate sec">
                <h3 class="sectitle">About us</h3>
                <p className="para">
                  {` Our formulations provide a very safe and exceptionally-effective cleaning that not only removes dirt, but also eradicates microbes on surfaces, thus freshening the atmosphere throughout your home.`}
                </p>
              </article>
              

             

              {/* <article className="servicesName sec">
                <h3 class="sectitle">Services </h3>
                <div className="mainservicebox">
                  <ul className="servesbox">
                    <li>Cardiology </li>
                    <li>Pediatric </li>
                    <li>Orthopedics </li>
                    <li> Neurology</li>
                    <li>Pediatric </li>
                    <li> Orthopedics</li>
                  </ul>
                </div>
              </article> */}

           

              {/* <article className="businessHours sec">
                <h3 class="sectitle">Working Hours</h3>

                <ul class="timelist">
                  <li>
                    <div className="day">Monday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Tuesday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Wednesday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Thursday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Friday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Saturday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                </ul>
              </article> */}



              {/* <article className="makeappitmneets sec">
                <h3 class="sectitle">Make Appointment</h3>
                <div className="contact-form">
                  <div class="col-12 mb-3" style={{ position: "relative" }}>
                    <input
                      ref={calendarInputRef}
                      type="date"
                      class="form-control clear_string"
                      placeholder="Date"
                      name="currentDate"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                    />
                    <div className="calanderIcon" onClick={openCalendar}><img loading="lazy" src={CalanderIconn} alt="Calendar Icon" /></div>
                  </div>
                  <div class="col-12 mb-3">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                    onChange={(e) => setSelectedTime(e.target.value)}
                    >
                      <option value="Select time">Select time</option>
                      <option>{`2:00 PM to 5:00 PM`}</option>
                    </select>
                  </div>
                  <div class="col-12 mb-3">
                    <button
                      className="btn btnmakeappitmnets"
                      data-bs-toggle="modal"
                      data-bs-target="#appotimnets"
                    >
                      Make An Appointment
                    </button>
                  </div>
                </div>
              </article> */}


           
          
          
            
              {/* <article className="makeappitmneets sec">
                <h3 class="sectitle">Make Appointment</h3>
                <div className="contact-form">
                  <div class="col-12 mb-3">
                    <input
                      type="date"
                      class="form-control clear_string"
                      placeholder="Date"
                      name="currentDate"
                      // value={selectedDate}
                      // onChange={(e) => setSelectedDate(e.target.value)}
                    />
                  </div>
                  <div class="col-12 mb-3">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      // onChange={(e) => setSelectedTime(e.target.value)}
                    >
                      <option value="Select time">Select time</option>
                      <option>{`2:00 PM - 5:00 PM`}</option>
                    </select>
                  </div>
                  <div class="col-12 mb-3">
                    <button
                      className="btn btnmakeappitmnets"
                      data-bs-toggle="modal"
                      data-bs-target="#appotimnets"
                    >
                      Make An Appointment
                    </button>
                  </div>
                </div>
              </article> */}

              
           
              {/* <article class="testimoniyalbox sec">
                <h3 class="sectitle testimonial">Testimonial </h3>

                <div className="testimonials-wrapper">
                <div className="carousel-container">
                    <button
                      onClick={() =>
                        currentIndex == 0
                          ? setCurrentIndex(testimonialArray.length - 1)
                          : setCurrentIndex((prev) => prev - 1)
                      }
                      className="carousel-btn"
                    >
                      <i class="fa fa-angle-left"></i>
                    </button>
                    <button
                      onClick={() => {
                        currentIndex == testimonialArray.length - 1
                          ? setCurrentIndex(0)
                          : setCurrentIndex((prev) => prev + 1);
                      }}
                      className="carousel-btn"
                    >
                      <i class="fa fa-angle-right"></i>
                    </button>
                  </div>
                  <TestimonialCarousel
                    testimonials={testimonialArray}
                    currentElem={currentIndex}
                  />
                  
                </div>
              </article> */}





              </section>
        
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theme28Template;