import React, { useEffect, useState } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'owl.carousel';


const ThemeSelect = () => {
  useEffect(() => {
    $('.owl-carousel').owlCarousel({
        items: 6,
        loop: false,
        autoplay: false,
        dots:false,
        nav:true,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
    });
  }, []); 



  const [selectedDiv, setSelectedDiv] = useState(null);

  const handleDivClick = (divId) => {
    setSelectedDiv(divId);
  };



  return (

    <div className='sec-gap'>
     <h3 className='templatheading'>Page Template <span>(Click on the template you like)</span> </h3>

    <div className="owl-carousel owl-theme">


    {Array.from({ length: 10 }, (_, index) => (
        <div
          key={index}
          className={`div ${selectedDiv === index ? 'selected' : ''}`}
          onClick={() => handleDivClick(index)}
        >
       <div className="item">
        <div className='themeboxselect'>
        <img loading="lazy" src='https://www.qrcodechimp.com/images/digitalCard/dbcv2/digital-business-cards-template-sales.webp' className='mx-auto d-block img-fluid'/>
        </div>
      </div>
    

        </div>
      ))}


      
     
    
    </div>


    
  </div>
  );
};

export default ThemeSelect;
