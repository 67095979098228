import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import OneWayTabComp from './OneWayTabComp';
import Banner from "./../../../../Images/WelcomeScreenIcon.png";
import RoundTTabCom from './RoundTTabCom';
import { LocalTabCom } from './LocalTabCom';
import AirportCabCom from './AirportCabCom';

const CabsTab = ({colorData}) => {
    const { t } = useTranslation();
    const [activeTab , setActiveTab] = useState("oneway");

const rendersTab = () =>{
    switch(activeTab){
        case "oneway":
            return <div><OneWayTabComp colorData={colorData} /></div>
        case "roundtrip":
            return <div><RoundTTabCom colorData={colorData} /></div>
        case "local":
            return <div><LocalTabCom colorData={colorData} /></div>
        case "airport":
            return <div><AirportCabCom colorData={colorData} /></div>
    }
}

const getTabClassName = (tabName) => {
    return `tab ${activeTab === tabName ? 'activejs' : 'inactivejs'}`;
};

const tabStyles = (tabName) => {
  if(activeTab === tabName && colorData?.dynamicBackgroundColor){
    return {
      backgroundColor: `${colorData?.dynamicBackgroundColor}60` || "#1d1d1d",
      color: colorData?.dynamicTextDescriptionColor || "#fff",
      borderBottom: `1px solid ${colorData?.dynamicTextDescriptionColor || "#EDAE10"}`
    }
  }else if(activeTab === tabName && !colorData?.dynamicBackgroundColor){
    return {
      backgroundColor: "#1d1d1d",
      color: "#fff",
      borderBottom: `1px solid #EDAE10`
    }
  }else if(activeTab !== tabName && colorData?.dynamicBackgroundColor){
    return {
      backgroundColor: colorData?.dynamicMainBackgroundColor || "#000",
      color: colorData?.dynamicTextHeadingColor || "#fff",
      borderBottom: `1px solid ${colorData?.dynamicTextHeadingColor}`
    }
  }else{
    return {
      backgroundColor: "#000",
      color: "#fff",
      borderBottom: `1px solid #1d1d1d`
    }
  }
}

return (
  <div className='w-hundred'>
      <div className='flex justify-center items-center py-2 ' style={{backgroundColor: colorData?.dynamicMainBackgroundColor || "#000"}}>
        <div 
        // className='font-Poppins text-white font-medium cursor-pointer' 
          className={getTabClassName('oneway')} onClick={()=>setActiveTab("oneway")} style={tabStyles('oneway')}> {t("One_way")} </div>
        <div  
        // className='font-Poppins text-white font-medium cursor-pointer' 
        className={getTabClassName('roundtrip')}
        //  style={tabStyles('roundtrip')}
          onClick={()=>setActiveTab("roundtrip")} style={tabStyles('roundtrip')}> {t("Round_trip")} </div>
        <div  
        // className='font-Poppins text-white font-medium cursor-pointer' 
        className={getTabClassName('local')}
        onClick={()=>setActiveTab("local")} style={tabStyles('local')}>  {t("Local")} </div>
        <div 
        //  className='font-Poppins text-white font-medium cursor-pointer'
        className={getTabClassName('airport')}
        onClick={()=>setActiveTab("airport")} style={tabStyles('airport')}>  {t("Airport")} </div>
    </div>
    <div className='py-4 px-2'>{rendersTab()}</div>
  </div>
  )
}

export default CabsTab;