import React from 'react'
import { useTranslation } from 'react-i18next';
import { AboutText, AboutTextWrapper, ConfirmOrder, ConfirmOrderWrapper, ContactIconWrapper, ContactTabIcon, ContactTabValue, CustomerDetailText, CustomerDetailsWrapper, InputTextField, InputTextFieldWrapper, OpeningHoursContainer, OrderCardWrapperContainer, OrderCardWrapperContainerCI, OrderDetail, OrderStatusKeyValueWrapper, OrderStatusValue, OrderStatuskey, OrderSuccessImage, OrderSuccessImageWrapper, OrderreviewKeyValueWrapper, OrderreviewValue, OrdersuccessIdNumber, OrdersuccessfullyPlaced, OrdersuccessfullyPlacedWrapper, PlaceOrder, PlaceOrderWrapper, TabContactInfoContainer, TabContactPhoneWrapper, TabContactText, TabContactWrapper, TabCustomerDetailsWrapper, TabOrderReviewScreen, TabPlaceOrderSuccess, TabPlaceOrderSuccessWrapper, TaborderDetailWrapper, ThankYouTextWrapper, ThankyouText } from './Theme14ExtStyleComponent'



export const TabContactInfo = (props) => {
  const { t } = useTranslation();
  let colordata = props?.colordata;
  let rs = props.rs;
  let ff = rs?.contactInfo?.content;
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }
  console.log(ff,"uu")

  return (
    <OrderCardWrapperContainerCI>
      <OpeningHoursContainer style={{backgroundColor: colordata?.dynamicBackgroundColor || ""}}>
        <AboutTextWrapper>
          {/* <AboutText > Contact Info </AboutText> */}

          <ul class="conlink">
          {ff?.contactInfoPhone && (
          <TabContactPhoneWrapper >
            <li>
              <a href={ff?.contactInfoPhone ? `tel:${ff?.contactInfoPhone}` : ""}>
              <ContactIconWrapper style={{background: colordata?.dynamicMainBackgroundColor || ""}}>   <i class="fa-solid fa-phone" style={{color: colordata?.dynamicTextHeadingColor || ""}}></i> </ContactIconWrapper>
              <ContactTabValue > <span style={{color: `${colordata?.dynamicTextDescriptionColor}90` || ""}}>{ff?.contactInfoPhone}</span> </ContactTabValue> 
              {ff?.optionalContactInfoPhone.length > 5 &&
              <ContactTabValue > <span style={{color: `${colordata?.dynamicTextDescriptionColor}90` || ""}}>{ff?.optionalContactInfoPhone}</span> </ContactTabValue> }
              </a>
            </li>
            </TabContactPhoneWrapper>
          )}
          
             {ff?.contactInfoEmail && !ff.contactInfoEmail.includes('@yopmail') && (
            <TabContactPhoneWrapper>
              <li>
                <a
                  href={ff?.contactInfoEmail ? `mailto:${ff?.contactInfoEmail}` : ""}
                >
                  <ContactIconWrapper style={{background: colordata?.dynamicMainBackgroundColor || ""}}><i className="fa-solid fa-envelope" style={{color: colordata?.dynamicTextHeadingColor || ""}}></i></ContactIconWrapper>
                  <ContactTabValue><span style={{color: `${colordata?.dynamicTextDescriptionColor}90` || ""}}>{ff?.contactInfoEmail}</span></ContactTabValue>
                </a>
              </li>
            </TabContactPhoneWrapper>
          )}

            {ff?.contactInfoWebUrl && (
            <TabContactPhoneWrapper >
            <li>
                      <a href="">
                      
                      <ContactTabValue  style={{color: `${colordata?.dynamicTextDescriptionColor}90` || ""}}> 
                      {/* <a href={`http://${ff?.contactInfoWebUrl?.replace(/^(https?:\/\/)?(www\.)?/, '')}`} target="_blank"> <span>{ff?.contactInfoWebUrl} </span></a> */}
                      <a
              href={(ff?.contactInfoWebUrl) ? `http://${ff?.contactInfoWebUrl.replace(/^(https?:\/\/)?(www\.)?/, '')}` : '#'}
              onClick={(e) => {
                if (!ff?.contactInfoWebUrl) {
                  e.preventDefault(); 
                }
              }}
              target={ff?.contactInfoWebUrl ? '_blank' : undefined}
              className="contactinfolink content"
            >  <ContactIconWrapper style={{background: colordata?.dynamicMainBackgroundColor || ""}}> <i class="fa fa-globe" style={{color: colordata?.dynamicTextHeadingColor || ""}}></i></ContactIconWrapper>
              <span style={{color: `${colordata?.dynamicTextDescriptionColor}90` || ""}}>{ff?.contactInfoWebUrl}</span>
            </a>
                         </ContactTabValue> 
                      </a>
                    </li>
                    </TabContactPhoneWrapper>
                    )}
            {ff?.contactInfoAddress && (
                    <TabContactPhoneWrapper >        
            <li>
              <a>
              <ContactIconWrapper style={{background: colordata?.dynamicMainBackgroundColor || ""}}>  <i class="fa-solid fa-location-dot" style={{color: colordata?.dynamicTextHeadingColor || ""}}></i></ContactIconWrapper>
              <ContactTabValue >    <span style={{color: `${colordata?.dynamicTextDescriptionColor}90` || ""}}>{ff?.contactInfoAddress}</span></ContactTabValue> 
              </a>
            </li>
            </TabContactPhoneWrapper>
            )}
             {ff?.contactInfoOptAddress && (
                    <TabContactPhoneWrapper >        
            <li>
              <a>
                 <p style={{color:colordata?.dynamicTextDescriptionColor || "gray", fontSize:"13px", fontWeight: 600}}>{t("Alternative_address")}</p>
              <ContactTabValue>    <span style={{color: `${colordata?.dynamicTextDescriptionColor}90` || ""}}>{ff?.contactInfoOptAddress}</span></ContactTabValue> 
              </a>
            </li>
            </TabContactPhoneWrapper>
            )}
          </ul>
        </AboutTextWrapper>
      </OpeningHoursContainer>
    </OrderCardWrapperContainerCI>
  )
}
