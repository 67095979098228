import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { toast } from "react-toastify";
import { environmentVariables } from "../../config/env.config";
import 'bootstrap/dist/css/bootstrap.min.css';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-data-table-component-extensions/dist/index.css";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import "./UpdateRequestcardstyle.css";
import { Modal } from "react-bootstrap";
import { getcoutryfromlocation, isValidEmail, isValidName, isValidPassword, isValidPhoneNumber } from "../../utils/utility";
import { Formik, Form, Field } from "formik";
import parsePhoneNumberFromString from "libphonenumber-js";
import { CountryDropdown } from 'react-country-region-selector';
import { useTranslation } from "react-i18next";

function CreateUser(props) {
  const { t } = useTranslation();
  const { userData } = useContext(userContext);
  const [show, setShow] = useState(false);
  const [errorMsgName, setErrorMsgName] = useState(null);
  const [errorMsgEmail, setErrorMsgEmail] = useState(null);
  const [errorMsgPassword, setErrorMsgPassword] = useState(null);
  const [errorMsgRole, setErrorMsgRole] = useState(null);
  const [roleData, setRoleData] = useState(null);
  const [typeData, setTypeData] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [teamLeads, setTeamLeads] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [userCreateData, setUserCreateData] = useState({
    name: "",
    email: "",
    password: "",
    roleId: 0,
    agent_code: "",
  });

  const [countryCode, setCountryCode] = useState("in");
  
  const fetchCountryfromlocation = async () => {
    try {
      const countryData = await getcoutryfromlocation();
      console.log("countryData-==>",countryData);
      setCountryCode(countryData.countryCode.toLowerCase());
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
  };

  useEffect(() => {
    fetchCountryfromlocation()
  }, []);

  const handleClose = () => {
  if (props.onClose) {
      props.onClose();  
    }
  setShow(false);
  }

  const se_role = process.env.REACT_APP_SE_ROLE || "Sales Representative";
  const isValidContact = (contact, country) => {
    const phoneNumber = parsePhoneNumberFromString(contact, country?.iso2?.toUpperCase());
    return phoneNumber?.isValid() || false;
  };

  useEffect(()=>{
    setShow(props.showModal);
    setUserCreateData(props.userCreateData);
    setRoleData(props.roleData);
    setTypeData(props.typeData);
  },[props])

  const handleCountryChange = async (val, setFieldValue) => {
    setFieldValue('country', val);
    await getTeamLeaders(val);
  };

  const getTeamLeaders = async (val) => {
    try {
      const response = await axios.get(`${environmentVariables?.apiUrl}api/v1/user/getTeamLeaders?country=${val}`);
      setTeamLeads(response?.data?.teamLeaders);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (values) => {
    const {
      name,
      email,
      password,
      roleId,
      contact,
      type,
      company_name,
      company_employee,
      department,
      country,
      staff_type,
      under_tl,
      agent_code
    } = values;
    console.log("Submitted Data:", userCreateData);
    let errorMsg = null;

    if (!isValidName(name)) {
      errorMsg = `${t("Name_can_only_contain_alphabets_hyphens_and_spaces")}`;
    } else if (!isValidEmail(email)) {
      errorMsg = `${t("Invalid_email_format")}`;
    } else if (props.isStaff && roleId === 0) {
      errorMsg = `${t("Role_mandatory")}`;
    } else if (!isValidContact(contact)) {
      errorMsg = `${t("Invalid_number")}`;
    } else if (props.isBusinessOwner) {
      if (!type) {
        errorMsg = `${t("Type_mandatory")}`;
      } else if (type === "company" && (!company_name || !company_employee || !department)) {
        errorMsg = `${t("All_corporate_field_mandatory")}`;
      }
      if (!agent_code && userData?.user_type !== "Sales Executive") {
        errorMsg = `${t("Agent_code_mandatory")}`;
      }
    }
    
    if (errorMsg) {
      toast.error(errorMsg, { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
      return;
    }

    let data = { name, email, roleId, contact, type, password, agent_code };

    // Apply conditions based on user type
    if (props.isBusinessOwner) {
      if (userData?.user_type === "Sales Executive") {
        data.agent_code = userData?.agent_code;
      } else {
        data.agent_code = agent_code;
      }
      if (data.type === "company") {
        data.company_name = company_name;
        data.company_employee = company_employee;
        data.department = department;
      } else if (data.type === "individual") {
        delete data.company_name;
        data.company_employee = 0;
        delete data.department;
      }
    } else {
      userData?.user_type == "Team Leader" ? data.country = userData?.country : data.country = country;
      delete data.type;
      delete data.company_name;
      delete data.company_employee;
      delete data.department;
      delete data.agent_code;
      if(data.roleId === se_role){
        data.staff_type = staff_type === "Team Leader" ? "Team Leader" : "Sales Executive";
        if(staff_type != "Team Leader"){
          if(userData?.user_type == "Team Leader"){
            data.under_tl = userData?.id.toString() || null;
          }else{
            data.under_tl = under_tl.toString() || null;
          }
        }
      }
    }
    data.agent_id = userData?.id.toString();
    setIsSubmit(true);

    let url = `${environmentVariables?.apiUrl}api/v1/user/createUserby`;
    if(!props.isStaff && !props.isBusinessOwner){
      url = `${environmentVariables?.apiUrl}api/v1/user/createUser`;
    }

    const config = {
      method: "post",
      url,
      headers: {
        _token: userData?.token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
      // data: JSON.stringify(userCreateData),
    };

    axios
      .request(config)
      .then(() => {
        handleClose();
        props.handleCreateUser(true);
        setIsSubmit(false);
        toast.success(
          props.isBusinessOwner ? `${t("Business_owner_created")}` : props.isStaff ? `${t("Staff_created")}` : `${t("User_created")}`,
          { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 }
        );
      })
      .catch((error) => {
        setIsSubmit(false);
        toast.error(error?.response?.data?.message || error?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> 
            <h5 className="modal-title">
              {props.isBusinessOwner ? `${t("Create_new_business_owner")}` : props.isStaff ? `${t("Create_new_staff")}` : `${t("Create_new_user")}`}
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Formik
            initialValues={{
              name: "",
              email: "",
              password: "",
              roleId: "",
              contact: "",
              type: "",
              company_name: "",
              company_employee: "",
              department: "",
              country: "",
              staff_type: "",
              under_tl: "",
              agent_code: ""
            }}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ values, handleChange, setFieldValue }) => (
              <Form className="row">
                {/* Name Field */}
                <div className="mb-2 col-md-12">
                  <Field
                    name="name"
                    className="form-control"
                    type="text"
                    placeholder={t("Name")}
                    value={values.name}
                    onChange={handleChange}
                  />
                </div>

                {/* Email Field */}
                <div className="mb-2 col-md-12">
                  <Field
                    name="email"
                    className="form-control"
                    type="text"
                    placeholder={t("Email")}
                    value={values.email}
                    onChange={handleChange}
                  />
                </div>
{/* Password */}
{/* {(props.isBusinessOwner || props.isStaff) && ( */}
<div className="mb-2 col-md-12" style={{ position: "relative" }}>
  <input
      type={showPassword ? "text" : "password"}
      className="form-control"
      placeholder={t("Enter_your_password")}
      name="password"
      key="password"
      value={values.password}
      onChange={handleChange}
    />
    <span
      onClick={togglePasswordVisibility}
      style={{
        position: "absolute",
        right: "20px",
        top: "50%",
        transform: "translateY(-50%)",
        cursor: "pointer",
      }}
    >
      {showPassword ? (
        <i className="fas fa-eye Eeye"></i>
      ) : (
        <i className="fas fa-eye-slash Eeye"></i>
      )}
    </span>
    <div
      style={{
        fontSize: "14px",
        color: "red",
        bottom: "70px",
      }}
    >
      {errorMsgPassword}
    </div>
  </div>
{/* )} */}
                {/* Country Dropdown */}
                {!props.isBusinessOwner && userData?.user_type != "Team Leader" &&(
                  <div className="mb-2 col-md-12">
                    <CountryDropdown
                      value={values?.country}
                      onChange={(val) => handleCountryChange(val, setFieldValue)}
                      className="form-control form-select"
                      valueType="short"
                    />
                  </div>
                )}

                {/* Phone Number for Business Owner */}
                <div className="mb-2 col-md-12">
                  <PhoneInput
                    name="contact"
                    country={countryCode}
                    countryCodeEditable={false}
                    enableSearch={true}
                    value={values?.contact}
                    onChange={(phone, country, e, formattedValue) => {
                      handleChange({ target: { name: "contact", value: formattedValue } });
                    }}
                    isValid={(value, country) => isValidContact(value, country)}
                    inputProps={{
                      className: "form-control",
                      placeholder: t("Mobile_number_required"),
                      required: true,
                    }}
                    style={{
                      marginBottom:"6px"
                    }}
                  />
                </div>
                {/* Role Dropdown */}
                  <>
                    <div className="mb-2 col-md-12">
                      <Field
                        as="select"  // Ensures Field renders as a select element
                        name="roleId"
                        className="form-control form-select"
                        value={values.roleId}
                        onChange={handleChange}
                      >
                        <option value="">{props.isStaff ? `${t("Staff_role")}` : `${t("User_role")}`}</option> 
                        {roleData && (
                          props.isBusinessOwner || props.isStaff ? 
                          roleData.map((val, inde) => (
                            <option key={inde} value={val?.name}>
                              {val?.name}
                            </option>
                          ))
                          :
                          roleData.map((val, inde) => (
                            <option key={inde} value={val?.role_id}>
                              {val?.role_name}
                            </option>
                          ))
                        )}
                      </Field>
                    </div>
                    {values.roleId === se_role && (
                      <>
                      <div className="mb-2 col-md-12">
                        <Field
                          as="select"  // Ensures Field renders as a select element
                          name="staff_type"
                          className="form-control form-select"
                          value={values.staff_type}
                          onChange={handleChange}
                        >
                          <option value="Sales Executive">{t("Sales_executive")}</option>
                          {userData?.user_type != "Team Leader" && <option value="Team Leader">{t("Team_leader")}</option>}
                        </Field>
                      </div>
                      {userData?.user_type != "Team Leader" &&  values.staff_type != "Team Leader" && (
                        <div className="mb-2 col-md-12">
                          <Field
                            as="select"  // Ensures Field renders as a select element
                            name="under_tl"
                            className="form-control form-select"
                            value={values.under_tl}
                            onChange={handleChange}
                          >
                            <option key="none" value="none">{t("Under_team_leader")}</option>
                            {teamLeads?.map((val, index) => (
                              <option key={index} value={val.id}>
                                {val.name}
                              </option>
                            ))}
                          </Field>
                        </div>
                      )}
                      </>
                    )}
                  </>

                {/* Type and Additional Fields for Business Owner */}
                {props.isBusinessOwner && (
                  <>
                    <div className="mb-2 col-md-12">
                      <Field
                        as="select"
                        name="type"
                        className="form-select"
                        value={values.type}
                        onChange={handleChange}
                      >
                        <option value={""}>{t("Register_type")}</option>
                        {[
                          { value: "individual", label: "Individual" },
                          { value: "company", label: "Corporate" }
                        ].map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Field>
                    </div>

                    {values.type === "company" && (
                      <>
                        <div className="mb-2 col-md-12">
                          <Field
                            name="company_name"
                            className="form-control"
                            type="text"
                            placeholder={t("Company_name")}
                            value={values.company_name}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="mb-2 col-md-12">
                          <Field
                            name="company_employee"
                            className="form-control"
                            type="number"
                            placeholder={t("Number_of_employees")}
                            value={values.company_employee}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="mb-2 col-md-12">
                          <Field
                            name="department"
                            className="form-control"
                            type="text"
                            placeholder={t("Department")}
                            value={values.department}
                            onChange={handleChange}
                          />
                        </div>
                      </>
                    )}

                    {userData?.user_type !== "Sales Executive" && <div className="mb-2 col-md-12">
                      <Field
                        name="agent_code"
                        className="form-control"
                        type="text"
                        placeholder={t("Agent_code")}
                        value={values.agent_code}
                        onChange={(e)=>{
                          e.target.value = e.target.value.toUpperCase();
                          handleChange(e);
                        }}
                        maxLength={6}
                      />
                    </div>}
                  </>
                )}

              <div class="mb-2 flex-center col-md-12">
                  <button
                  type="submit"
                  disabled={isSubmit}
                  class="btn btn-primary me-3 save-cta"
                >
                  {isSubmit ? <CircularLoader size={20} /> :` ${t("Save")}`}
                </button>
              </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateUser;
