import React, { useContext,useState, useEffect, useRef } from "react";
import { environmentVariables } from "../../../config/env.config";
import testimonialdefault from "../../../Images/testimonialdefault.png";
import avatarImage from "../../../Images/minimarketproducticon.png";
import "./../Updatedcss/BusinessChangeBlockUpdatedStyle.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { currencyContext } from "../../../context/currencyContext.js";
import axios from "axios";
import Swal from "sweetalert2"; 
import { toast } from "react-toastify";
import { businessObjContext } from "../../../context/businessObjContext.js";
import { userContext } from "../../../context/userContext.js";
import { useTranslation } from "react-i18next";

const FormikErrorStyle = styled.div`
font-size:11px;
color: #ff3333;
text-align: justify;
    padding-bottom: 10px;
`;


const CabproductForm = ({
  formCount,
  restaurantData,
  setIsAddTestimonialDisable,
  setRestaurantData,
  handleFetchminiproductById,
  handleSubmitWithoutTabChange,
  index,
  removeForm,
  servicesArray
}) => {
  const fileInputRef = useRef();
  const selectElementRef = useRef();
  const openFileInput = () => {
    fileInputRef.current.click();
  };
  const { businessObjData } = useContext(businessObjContext);

  const baseUrl = environmentVariables?.apiUrl;
  const [image, setImage] = useState(null);
  const [imgGalleryArrayPreview,setImgGalleryArrayPreview]=useState([]);
  const [imgGalleryArray,setImgGalleryArray]=useState([]);
const [productId,setProductId]=useState(null);
  const [addBtnDisable, setAddBtnDisable] = useState(false);
  const { currencyObjData } = useContext(currencyContext);
  const { userData, setUserData } = useContext(userContext);
  const { t } = useTranslation();

  const [data, setData] = useState({
    formCount: formCount,
    image: null,
    name: "",
    price: "",
    description:"",
    category: "enquiry",
    imageView: null,
    isImageUpload: true,
    quantity: "",
    service:"",
    withDriver: false,
    withDriverPrice: "",
    selfDrive: false,
    selfDrivePrice: "",
    fuelType: { cng: false, el: false, petrol: false, diesel: false },
    gearType: "",
  });

  const handleGalleryImages=(event)=>{
const files=Array.from(event.target.files);
let allGalleryImage = [...imgGalleryArray,...files];
if(allGalleryImage?.length){
  const filePreviews=files.map((file)=>URL.createObjectURL(file))
  const allImagePreviews=[...imgGalleryArrayPreview,...filePreviews]
  setImgGalleryArrayPreview(allImagePreviews);
  setImgGalleryArray(allGalleryImage)
}else{
  // toast.error(t("max_gallery_error"))
}
// console.log(imgGalleryArray,'------------------------------------>>>>>>>>>>>>>>>>>>..')
  }
  const handleEditProduct=()=>{
    setData({ ...data, isImageUpload: true });
    setImage(restaurantData[index]?.image);
    setAddBtnDisable(false);
    setProductId(restaurantData[index]?.id)
  }
  const getImage = (data) => {
    if (data["imageView"]) {
      return data["imageView"];
    } else {
      if (data["image"]) {
        if (data["image"] != "null") {
          return `${environmentVariables?.s3UrlBackendUrl}${data["image"]}`;
        } else {
          return testimonialdefault;
        }
      } else {
        return avatarImage;
      }
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      // .matches(/^[a-zA-Z0-9 ,.?\/()&@$'_\-\\"]*$/, "Only ‘,.?-_\\/@$&’ characters are allowed")
      .matches(/^[\u0600-\u06FFa-zA-Z0-9 ,.?\/()&@$'_\-\\"]*$/, `${t("Only")} ‘,.?-_\\/@$&’ ${t("characters_are_allowed")}`)
      .required(t("Name_required"))
      .min(2, t("Minimum_2_characters_required"))
      .max(45, t("Maximum_45_characters")),
    description: Yup.string()
    // .matches(/^[a-zA-Z0-9 ,.?\/()&@$'_\-\\"]*$/, "Only ‘,.?-_\\/@$&’ characters are allowed"),
    .matches(/^[\u0600-\u06FFa-zA-Z0-9 ,.’?\/()&@$'_\-\\"\n]*$/, `${t("Following_special_characters_are_allowed")}`),
    category: Yup.string(),
    price: Yup.number()
    .typeError(t("Seat_must_number"))
    .positive(t("Seat_must_greater_zero"))  // Ensures it's positive
    .required(t("Seat_is_required")),
    withDriverPrice: Yup.number()
    .when("withDriver", {
      is: true,
      then: (schema) => schema.required("Price is required when 'With Driver' is selected").positive("Price must be greater than zero"),
    }),
        selfDrivePrice: Yup.number()
          .when("selfDrive", {
            is: true,
            then: (schema) => schema.required("Price is required when 'Self Drive' is selected").positive("Price must be greater than zero"),
          }),
        gearType: Yup.string().required("Gear Type is required"),
        fuelType: Yup.object().test(
          "fuelTypeRequired",
          "At least one fuel type must be selected",
          (value) =>   Object.values(value).some((v) => v === true)
        ),
  });

  const formik = useFormik({
    initialValues: {
      name: data.name || "",
      price: data.price || "",
      category: data.category || "enquiry",
      quantity: data.quantity || "",
      service: data.service || "",
      description: data.description || "",
      withDriver: data.withDriver || false,
      withDriverPrice: data.withDriverPrice || "",
      selfDrive: data.selfDrive || false,
      selfDrivePrice: data.selfDrivePrice || "",
      fuelType: data.fuelType || { cng: false, el: false, petrol: false, diesel: false },
      gearType: data.gearType || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true, 
    onSubmit: (values) => {
      // handleAddService(values);
    },
  });

  const handleAddService = (values) => {
    // console.log(values,'values after add')
    // console.log("Formik Values:", formik.values);
    // setAddBtnDisable(true);
    // setIsAddTestimonialDisable(false);
    const newRestaurantData = restaurantData.map((val, index) => {
      if (val.formCount == formCount) {
        val.name = values?.name;
        val.price = values?.price;
        val.category = values?.category;
        val.quantity = values?.quantity;
        val.image = image;
        val.imageView = data?.imageView;
        val.isImageUpload = data?.isImageUpload;
        val.service = values?.service;
        val.description = values?.description;
        val.withDriver = values?.withDriver;
        val.withDriverPrice = values?.withDriverPrice;
        val.selfDrive = values?.selfDrive;
        val.selfDrivePrice = values?.selfDrivePrice;
        val.fuelType = values?.fuelType;
        val.gearType = values?.gearType;
        return val;
      }
      return val;
    });
    setRestaurantData(newRestaurantData);

let formData = new FormData();
if(!!image){
  if(typeof image!="string"){
    for (let i = 0; i < image.length; i++) {
      formData.append("productImage", image[i], image[i].name);
    }
  }else {
    formData.append("productImage",image);
  }
}

let gallery=imgGalleryArray;
let count=0;
for (let i = 0; i < gallery.length; i++) {
  if(typeof gallery[i]=='string'){
    formData.append(`existingGallery[${count}]`,gallery[i]);
    count++;
  }else{
    formData.append(`productGallery`, gallery[i], gallery[i].name);
  }
}

// data.append('productGallery', fs.createReadStream('/C:/Users/user/Downloads/Telegram Desktop/67.png'));
// data.append('productGallery', fs.createReadStream('/C:/Users/user/Downloads/Telegram Desktop/1213 (2).png'));
formData.append('product_name', values?.name);
if(productId){
  formData.append("id", productId);
}
formData.append('product_seator', values?.price||"");
// formData.append('productQuantity',values?.quantity||1);
formData.append('product_description', values?.description);
formData.append('product_category', 'enquiry');
formData.append('business_id', businessObjData?.id);
// formData.append('productService', values?.service);

formData.append('with_driver', values?.withDriver ? '1' : '0');
formData.append('self_driver', values?.selfDrive ? '1' : '0');
formData.append('with_driver_price', values?.withDriverPrice || '0');
formData.append('self_driver_price', values?.selfDrivePrice || '0');
const fuelTypes = [];
if (values?.fuelType?.cng) fuelTypes?.push('cng');
if (values?.fuelType?.el) fuelTypes?.push('el');
if (values?.fuelType?.petrol) fuelTypes?.push('petrol');
if (values?.fuelType?.diesel) fuelTypes?.push('diesel');
formData.append('fuel_type', fuelTypes?.join(', '));

formData.append('gear_type', values?.gearType);


let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: `${environmentVariables?.apiUrl}api/v1/business/addCabproduct`,
  data : formData,
  headers: {
    _token: userData?.token,  // Add the token here
  }
};

axios.request(config)
.then((response) => {
  toast.success(t("Product_added_successfully"), {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
  setAddBtnDisable(true);
  // handleSubmitWithoutTabChange('productadd')
  let currentForm = restaurantData[index];
  // {console.log(currentForm,"currentFormcurrentFormcurrentForm")}
    setData({
     
      name: currentForm?.product_name||currentForm?.name,
      price: currentForm?.product_price||currentForm?.price,
      category: currentForm?.product_category||currentForm?.category,
      // quantity: currentForm?.product_quantity||currentForm?.quantity,
      image: currentForm?.image,
      gallery:currentForm?.image,
      imageView: currentForm?.imageView,
      formCount: index,
      isImageUpload: currentForm?.isImageUpload,
      // service: currentForm?.product_service||currentForm?.service,
      description: currentForm?.description||currentForm?.product_description,
      withDriver: currentForm?.with_driver ||  currentForm?.withDriver || false,
      withDriverPrice: currentForm?.withDriverPrice || currentForm?.with_driver_price ||"",
      selfDrive: currentForm?.selfDrive || currentForm?.self_driver || false,
      selfDrivePrice: currentForm?.selfDrivePrice || currentForm?.self_driver_price ||"",
      fuelType: currentForm?.fuel_type ||  currentForm?.fuelType || "",
      gearType: currentForm?.gear_type || currentForm?.gearType,
    });
    setIsAddTestimonialDisable(false);
    handleFetchminiproductById(businessObjData?.id)
    setTimeout(() => {
    }, 4000);
})
.catch((error) => {
  toast.error(error?.response?.data?.message||error?.message)
  console.log("API error====>>>", error);
});

  };

  const handleChangeImage = (e) => {
    setImage(e.target.files);
    if (e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setData({ ...data, imageView: reader.result });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  useEffect(() => {
    let currentForm = restaurantData[index];
    // console.log(restaurantData,'restaurantData==>')
    setData({
      name: currentForm?.product_name||currentForm?.name,
      price: currentForm?.product_seator||currentForm?.price,
      category: currentForm?.product_category||currentForm?.category,
      // quantity: currentForm?.product_quantity||currentForm?.quantity,
      image: currentForm?.image,
      gallery:currentForm?.image,
      imageView: currentForm?.imageView,
      formCount: index,
      isImageUpload: currentForm?.isImageUpload,
      // service: currentForm?.product_service||currentForm?.service,
      description: currentForm?.description||currentForm?.product_description,
      withDriver: currentForm?.with_driver ||  currentForm?.withDriver || false,
      withDriverPrice: currentForm?.withDriverPrice || currentForm?.with_driver_price ||"",
      selfDrive: currentForm?.selfDrive || currentForm?.self_driver || false,
      selfDrivePrice: currentForm?.selfDrivePrice || currentForm?.self_driver_price ||"",
      fuelType: currentForm?.fuel_type
    ? currentForm.fuel_type.split(", ").reduce((acc, type) => {
        acc[type] = true;
        return acc;
      }, {})
    : {},
      gearType: currentForm?.gear_type,
    });
    let galleryData=restaurantData[index]?.gallery;
    if(galleryData){
      let galleryImages=JSON.parse(galleryData);
      let baseUrl=`${environmentVariables?.s3UrlBackendUrl}`
      const addBaseUrlToImages = (images, baseUrl) => {
        return images.map(image => `${baseUrl}${image}`);
      };
      let imagesWithBaseUrl=addBaseUrlToImages(galleryImages,baseUrl);
      setImgGalleryArrayPreview(imagesWithBaseUrl)
      setImgGalleryArray(galleryImages)
    } 
    // if(restaurantData[index]?.image){
    //   setImage(restaurantData[index]?.image);
    // }
    // setImgGalleryArrayPreview()
  }, [restaurantData]);

  const deleteMinimktProduct = async (id, business_id) => {
    try {
      const response = await axios.delete(`${baseUrl}api/v1/business/deletecabproduct`, {
        data: { id, business_id },
        headers: {
          _token: userData?.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error deleting product:", error);
      return { success: false, message: error.message };
    }
  };
  const handleRemoveGalleryImg=(index)=>{
    let newGalleryImgs=[...imgGalleryArrayPreview];
    let newGalleryImgsFiles=[...imgGalleryArray];

    newGalleryImgs.splice(index,1);
    newGalleryImgsFiles.splice(index,1);
    
    // console.log(newGalleryImgs,index,'/;.;//////////////////////')
    setImgGalleryArrayPreview(newGalleryImgs);
    setImgGalleryArray(newGalleryImgsFiles)
  }

 const handleRemoveForm = async (index) => {
    const { id, business_id } = restaurantData[index];

    Swal.fire({
      title: t("Are_you_sure"),
      text: t("You_wont_be_able_to_revert_this"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yes_delete_it"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        if(restaurantData[index]?.isNew){
          removeForm(index);
          Swal.fire(t("Deleted!"), t("The_product_deleted"), t("Success"));
        }else{
          const result = await deleteMinimktProduct(id, business_id);
        if (result.success) {
          removeForm(index);
          Swal.fire(t("Deleted!"), t("The_product_deleted"), t("Success"));
        } else {
          console.error(t("Failed_delete_product"), result.message);
          Swal.fire(t("Error!"), t("Failed_delete_product"), t("Error"));
        }
        }
      }
    });
  };

  const handleFuelTypeChange = (e, fuelType) => {
    const currentFuelTypeValues = {...formik.values.fuelType};
    const currentChecked = Object.values(currentFuelTypeValues).filter(Boolean).length;
    
    const wouldBeChecked = !currentFuelTypeValues[fuelType];
    
    if (wouldBeChecked && currentChecked >= 2) {
      e.preventDefault();
      e.currentTarget.checked = false;
      toast.error("Only two fuel types can be selected at a time");
      return;
    }
    
    formik.setFieldValue("fuelType", {
      ...currentFuelTypeValues,
      [fuelType]: wouldBeChecked
    });
  };

  return (
    <div
      key={`form-${formCount}`}
      className=""
      id="AddNewStyleFormfieldpad"
    >
      <div className="serviceboxedittesti MobileviewServices">
        <i
          className="fa-solid fa-circle-xmark crossedit"
          onClick={() => handleRemoveForm(index)}
        >
          {" "}
        </i>

        <div className="boxbanner">
        <label className="imgrec" style={{fontSize:"12px"}}>{t("Cab_img_size")}</label>
          <div className="bannerboxedittesti ">
            <img
              style={{
                cursor:
                  data["isImageUpload"] && !addBtnDisable
                    ? "pointer"
                    : "inherit",
              }}
              onClick={() => {
                data["isImageUpload"] && !addBtnDisable
                  ? openFileInput()
                  : null;
              }}
              src={getImage(data)}
              className="mx-auto d-block img-fluid"
              
            />
            <i
              style={{
                display:
                  data["isImageUpload"] && !addBtnDisable ? "block" : "none",
              }}
              onClick={() => {
                data["isImageUpload"] && !addBtnDisable
                  ? openFileInput()
                  : null;
              }}
              id="uploadicon"
              className="fa fa-solid fa-upload"
            ></i>
          </div>
          {data["isImageUpload"] && !addBtnDisable && (
            <input
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={(e) => {
                handleChangeImage(e);
              }}
              className="form-control"
              type="file"
              placeholder={`${t("Title")}`}
              accept=".png, .jpg, .jpeg"
            />
          )}
        </div>
    
        <div className="AddDetailsTextimonialphnview">
        
            <div className="" style={{ fontSize: "16px", fontWeight: "500" }}>
            {data["isImageUpload"] && !addBtnDisable ? (
              <div>
                <input
                  {...formik.getFieldProps("name")}
                  className="form-control"
                  type="text"
                  placeholder={`${t("Name")}`}
                />
                {formik.touched.name && formik.errors.name ? (
                  <FormikErrorStyle>{formik.errors.name}</FormikErrorStyle>
                ) : null}
              </div>
            ) : (
              <div>{data["name"] == "undefined" ? "" : data["name"]}</div>
            )}
          </div>

          <div className="" style={{ fontSize: "16px", fontWeight: "500" }}>
            {data["isImageUpload"] && !addBtnDisable ? (
              <div>
                <input
                  {...formik.getFieldProps("price")}
                  className="form-control"
                  type="number"
                  placeholder={`${t("Car_seat")}`}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) => {
                    if (["-", "+", "e"].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                   onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      if (value > 0 && value <= 30 || e.target.value === "") {
                        formik.setFieldValue("price", e.target.value);
                      }else if (value > 30) {
                        formik.setFieldError("price", t("invalid_cab_seat"));
                      }
                    }}
                />
                {formik.touched.price && formik.errors.price ? (
                  <FormikErrorStyle>{formik.errors.price}</FormikErrorStyle>
                ) : null}
              </div>
            ) : (
              <div>{data["price"] == "undefined" ? "" : data["price"]}</div>
            )}
          </div>

          <div className="" style={{ fontSize: "16px", fontWeight: "500" }}>
            {data["isImageUpload"] && !addBtnDisable ? (
              <div>
                <textarea
                  {...formik.getFieldProps("description")}
                  className="form-control"
                  placeholder="description"
                />
                {formik.touched.description && formik.errors.description ? (
                  <FormikErrorStyle>{formik.errors.description}</FormikErrorStyle>
                ) : null}
              </div>
            ) : (
              <div>{data["description"] == "undefined" ? "" : data["description"]}</div>
            )}
          </div>

            {/* <div className="" style={{ fontSize: "16px", fontWeight: "500" }}>
            {data["isImageUpload"] && !addBtnDisable ? (
              <div>
                <textarea
                  {...formik.getFieldProps("name")}
                  className="form-control"
                  type="text"
                  placeholder={`${t("Name")}`}
                />
                {formik.touched.name && formik.errors.name ? (
                  <FormikErrorStyle>{formik.errors.name}</FormikErrorStyle>
                ) : null}
              </div>
            ) : (
              <div>{data["name"] == "undefined" ? "" : data["name"]}</div>
            )}
          </div> */}

          

          {data["isImageUpload"] && !addBtnDisable ? (
          <div className="cab-form-grp">
      {/* With Driver Option */}
      {/* <div>
      <label>
      <input
        type="checkbox"
        checked={formik.values.withDriver}
        onChange={(e) => formik.setFieldValue("withDriver", e.target.checked)}
        className="cabtextspacing"
      />
      With Driver
    </label>
    {formik.values.withDriver && (
      <input
        type="text"
        value={formik.values.withDriverPrice}
        onChange={(e) => formik.setFieldValue("withDriverPrice", e.target.value)}
        className="form-control"
        placeholder="Write price/day"
      
      />
    )}
  </div> */}

      {/* Self Drive Option */}
      <div>
    <label>
      <input
        type="checkbox"
        checked={formik.values.selfDrive}
        onChange={(e) => formik.setFieldValue("selfDrive", e.target.checked)}
        className="cabtextspacing"
      />
      Self Drive
    </label>
    {formik.values.selfDrive && (
      <input
        type="text"
        value={formik.values.selfDrivePrice}
        onChange={(e) => formik.setFieldValue("selfDrivePrice", e.target.value)}
        className="form-control"
        placeholder="Write price/day"
      />
    )}
  </div>

      {/* Fuel Type Option */}

      
      
      <div>
        <label className="form-label" style={{marginBottom:"0.25rem"}}>Fuel Type:</label>
        <div>
          <label>
            <input
              type="checkbox"
              checked={formik.values.fuelType.cng}
              className="checkboxspacing"
              onChange={(e) => handleFuelTypeChange(e,"cng")}
          />
            CNG
          </label>
          <label>
            <input
              type="checkbox"
              checked={formik.values.fuelType.el}
                className="checkboxspacing"
              onChange={(e) => handleFuelTypeChange(e,"el")}
      />

            Electric
          </label>
          <label>
            <input
              type="checkbox"
              checked={formik.values.fuelType.petrol}
                className="checkboxspacing"
              onChange={(e) => handleFuelTypeChange(e,"petrol")}
          />
            Petrol
          </label>
          <label>
            <input
              type="checkbox"
              checked={formik.values.fuelType.diesel}
                className="checkboxspacing"
              onChange={(e) => handleFuelTypeChange(e,"diesel")}
          />
        
            Diesel
          </label>
        </div>
      </div>
      

      {/* Gear Type Option */}
      <div>
        <label className="form-label">Gear Type:</label>
        <div>
          <label>
            <input
              type="radio"
              name="gearType"
              value="manual"
                className="checkboxspacing"
        checked={formik.values.gearType === "manual"}
        onChange={() => formik.setFieldValue("gearType", "manual")}
            />
            Manual
          </label>
          <label >
            <input
              type="radio"
              name="gearType"
               className="checkboxspacing"
              value="automatic"
              checked={formik.values.gearType === "automatic"}
             
              onChange={() => formik.setFieldValue("gearType", "automatic")}
              // checked={gearType === "automatic"}
              // onChange={() => setGearType("automatic")}
            />
            Automatic
          </label>
        </div>
      </div>
    </div>
  ) : (
          
          <></>
        )}



        {/* ////////////////////////////   multiple images //////////////// */}


        {data["isImageUpload"] && !addBtnDisable ? (
              <>
                <label className="form-label" style={{ paddingTop: "10px" }}>
                  {t('Add_product_photos')}
                </label>
                <div style={{ fontSize: "16px", fontWeight: "500" }}>
                  <span style={{ fontSize: "12px" }}> {t("Dimensions_pixels")}</span>
                </div>
                <div>
                  <input
                    onChange={(e) => handleGalleryImages(e)}
                    className="form-control"
                    type="file"
                    multiple
                    placeholder="Name"
                    // disabled={imgGalleryArrayPreview?.length >= 4}
                  />
                  {/* <p className="indicationMessage">{t("Four_img_validation")}</p> */}
                  {/* {formik.touched.name && formik.errors.name ? (
                    <FormikErrorStyle>{formik.errors.name}</FormikErrorStyle>
                  ) : null} */}
                </div>
              </>
            ) : (
              <></>
            )}
            {console.log("imgGalleryArrayPreview====>>>", imgGalleryArrayPreview)}
            
          
            <div className="productphotosmultiple">
          {imgGalleryArrayPreview&&imgGalleryArrayPreview.length>0&&imgGalleryArrayPreview.map((val,index)=>

            <>
            <div key={'gallery'+index} style={{position:"relative"}}>
              <img src={val}/>
              {data["isImageUpload"]&&!addBtnDisable?<><i onClick={()=>handleRemoveGalleryImg(index)} className="fa-solid fa-circle-xmark crossedit2"></i></>:<></>}
            </div>
            </>
                       
          )}
          </div>


        {/* /////////////////////////   end */}
      
      {/* <div className="" style={{ fontSize: "16px", fontWeight: "500" }}>
            {data["isImageUpload"] && !addBtnDisable ? (
              <div>
                <textarea
                  {...formik.getFieldProps("description")}
                  className="form-control"
                  placeholder="description"
                />
                {formik.touched.description && formik.errors.description ? (
                  <FormikErrorStyle>{formik.errors.description}</FormikErrorStyle>
                ) : null}
              </div>
            ) : (
              <div>{data["description"] == "undefined" ? "" : data["description"]}</div>
            )}
          </div> */}

          {/* {data["isImageUpload"]&&!addBtnDisable?<><label class="form-label" style={{paddingTop:"10px"}}>Add Product Photos</label></>:<></>}
          <div  className="" style={{ fontSize: "16px", fontWeight: "500" }}>
          {data["isImageUpload"] && !addBtnDisable ? (
              <div>
              <input
              onChange={(e)=>handleGalleryImages(e)}
                className="form-control"
                type="file"
                multiple
                placeholder="Name"
              />
             
            </div>
          ):(<></>)}
          </div>
          <div className="productphotosmultiple">
          {imgGalleryArrayPreview&&imgGalleryArrayPreview.length>0&&imgGalleryArrayPreview.map((val,index)=>

            <>
            <div style={{position:"relative"}}>
              <img loading="lazy" src={val}/>
              {data["isImageUpload"]&&!addBtnDisable?<><i onClick={()=>handleRemoveGalleryImg(index)} className="fa-solid fa-circle-xmark crossedit2"></i></>:<></>}
            </div>
            </>
                       
          )}
          </div> */}
          {console.log("addBtnDisable====>>>", addBtnDisable)}
          {console.log("data====>>>", data)}
          {data["isImageUpload"]&&!addBtnDisable?<></>:
          <>
          <button onClick={()=>{handleEditProduct()}} type="button" class="btn btn-cu addbuttonedit2">{t("Edit")} </button>
          </>}
          {data["isImageUpload"] && !addBtnDisable ? (
          <div className="form-group" style={{display:'flex', alignItems:'center', gap:'10px'}}>
            
            {formik.touched.category && formik.errors.category ? (
              <FormikErrorStyle>{formik.errors.category}</FormikErrorStyle>
            ) : null}
          </div>
        ) : (
          <></>
        )}

          {data["isImageUpload"] && !addBtnDisable ? (
            <button
              type="button"
              disabled={(!formik.isValid || !formik.values.name || addBtnDisable )  
              }
              onClick={() => {
                formik.handleSubmit();
                handleAddService({
                  name: formik.values.name,
                  price: formik.values.price,
                  // category: formik.values.category,
                  image: image,
                  imageView: data.imageView,
                  isImageUpload: data.isImageUpload,
                  gallery:imgGalleryArray,
                  // quantity: formik.values.quantity,
                  // service: formik.values.service,
                  description: formik.values.description,
                  withDriver: formik.values.withDriver,
                  withDriverPrice: formik.values.withDriverPrice,
                  selfDrive: formik.values.selfDrive,
                  selfDrivePrice: formik.values.selfDrivePrice,
                  fuelType: formik.values.fuelType,
                  gearType: formik.values.gearType
                });
              }}
              className="btn btn-cu editbutton editButtonTextimonial"
            >
              {t("Add")}
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default CabproductForm;
