import React from "react";
import "./Theme9.css";
import { environmentVariables } from "../../../../config/env.config";
import testimonialdefault from '../../../../Images/test9.png';
import { getImage } from "../../../Components/TestimonialGetImage/TestimonialGetImage";

  const TestimonialCarousel = ({ testimonials, currentElem, handleTouchStart, handleTouchMove, handleTouchEnd , colordata }) => {
  const testimonialStyle = {
    transform: `translateX(-${(currentElem * 100) / testimonials?.length}%)`,
    transition: "transform 0.5s ease-in-out",
    // width: `${testimonials * 100}%`,
  };
  //   console.log(testimonials, currentElem, "inside carousel");
  return (
    <>
      <div className="testimonials-container" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
          <div className="testimonials" style={testimonialStyle}>
        {testimonials.map((val, index) => {
          return (
            <div class="testibox" key={index}>
              <div className="testimonialcontent" style={{background: colordata?.dynamicBackgroundColor ? `${colordata?.dynamicBackgroundColor}80` : ""}}>
                <div className="testiimg">
                  <img
                    src={
                      getImage(val)
                    }
                    class="mx-auto d-block img-fluid clint-img2"
                    alt="Testimonial Image"
                  />
                </div>
                <div className="clintname">
                  <b className="testimonialname"  style={{color: colordata?.dynamicTextDescriptionColor ? `${colordata?.dynamicTextDescriptionColor}B3` : "" }}>
                    {val?.name != "undefined" ? val?.name : ""}
                  </b>
                  <p className="testimonialdescription" style={{color: colordata?.dynamicTextDescriptionColor ? `${colordata?.dynamicTextDescriptionColor}B3` : "" }}>
                    {val?.description != "undefined" ? val?.description : ""}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      </div>
    </>
  );
};

export default TestimonialCarousel;
