// Captcha.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { environmentVariables } from '../config/env.config';
import RefreshIcon from "../Images/refreshcaptcha.png";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";

const baseUrl = environmentVariables?.apiUrl;
function Captcha({ onCaptchaSolved }) {
  const [captcha, setCaptcha] = useState({ id:"", question: '', answer: 0 });
  const [userAnswer, setUserAnswer] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    fetchCaptcha();
  }, []);

  const fetchCaptcha = async () => {
    const response = await axios.get(`${baseUrl}api/v1/user/fetchCaptcha`); 
    const captcha = response.data;  
    
    localStorage.setItem(
      "captcha",
      JSON.stringify({ id: captcha.id, question: captcha.question })
    );

    setCaptcha({id:captcha?.id, question: captcha.question, answer:0});
    setUserAnswer('');
    onCaptchaSolved("");
  };

  const handleChange = (e) => {
    setUserAnswer(e.target.value);
    onCaptchaSolved(e.target.value);
  };

  const handleKeyPress = (e) => {
    // Allow only numeric input
    if (e.key && !/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  
  return (
    <div style={{marginTop:"0px"}}>
        <p className='heading' style={{paddingBottom:"5px"}}>{t("What_is")}: {captcha.question}</p>
        <div className='inputbuttoncontainer' style={{paddingBottom:"10px"}}>
            <input
                type="text"
                value={userAnswer}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                placeholder={t("Input_your_answer")}
                className='captchainput'
                maxLength={2}
            />
            <button type='button' className='refresscaptchabtn' onClick={fetchCaptcha}>
                <img className='refreshicon' src={RefreshIcon} alt="Refresh CAPTCHA" /> {t("Refresh")}
            </button>
        </div>
    </div>
  );
}

export default Captcha;
