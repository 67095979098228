import { useQuery } from 'react-query';
import { environmentVariables } from "../../config/env.config";
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Col, Row, Spinner } from 'react-bootstrap';
import styled from "styled-components";
import { useContext, useRef, useState } from 'react';
import { userContext } from '../../context/userContext';
import bannerimage from "../../Images/blackpic0.jpg";
import { toast } from 'react-toastify';
import "./SendNotificationStyle.css";
import {useTranslation} from 'react-i18next';
const Errormsg = styled.div`
    color: red;
    margin: 0 10px;
    font-size: 12px;
`;


export function SendNotification() {
    const { t }= useTranslation();    
    const baseUrl = environmentVariables?.apiUrl;
    const { userData } = useContext(userContext);
    const fileInputRefBanner = useRef(null);
    const [bannerData, setBannerData] = useState("");
    const [bannerImageUrl, setBannerImageUrl] = useState("");
    const [btnLoading, setBtnLoading] = useState(false);
    const fetchUserBusiness = async () => {
        let res = [];
        await axios.get(`${baseUrl}api/v1/business/fetchbusinessNameAndDesignation`, { headers: { _token: userData?.token } })
        .then((response) => {  
            res = response?.data?.data;     
        })
        .catch((error) => {
            console.log("error===>>>", error);
            res = [];
        });
        return res;
    };
    const { isLoading, error, data } = useQuery('businesslist', fetchUserBusiness);
    const validationSchema = Yup.object().shape({
        title: Yup.string().required(t("Title_required")),
        message: Yup.string().required(t("Message_required")),
        pwaIDs: Yup.string().required(t("Business_required")),
    });

    // Initialize Formik with validateOnChange set to true
    const formik = useFormik({
        initialValues: {
            title: '',
            message: '',
            pwaIDs: ''
        },
        validationSchema: validationSchema,
        validateOnChange: true, // Apply validation on each change
        onSubmit: async (values, { resetForm }) => {
            // console.log("Submitting form with values:", values); 
            setBtnLoading(true);            
            await handleSendPushNotification(values); 
            resetForm();
            setBannerData("");                      
            setBannerImageUrl("");                      
        },
    });

    const handleSendPushNotification = (data)=>{
        return new Promise((resolve, reject)=>{
            const formData = new FormData();
            formData.append("title", data?.title);
            formData.append("message", data?.message);
            formData.append("pwaIDs", data?.pwaIDs);
            formData.append("image", bannerData);
            axios
            .post(`${baseUrl}api/v1/business/sendPushNotificationOnPWA`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    _token: userData?.token,
                },
            })
            .then((res) => {
                setBtnLoading(false);
                if (res?.data?.status) {
                    toast.success(res?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000,
                    });
                }
                resolve(true);
            })
            .catch((err) => {
                console.log("error occurred====>>>>", err);
                toast.error(
                    err?.response?.data?.message?.message || err?.response?.data?.message,
                    {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000,
                    }
                );
                resolve(false);
            });
        })
    }

    const handleBannerChange = (e) => {
        const file = e.target.files[0];
        
        // List of accepted image MIME types
        const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        
        if (file && acceptedImageTypes.includes(file.type)) {
          setBannerData(file);
      
          // Use the FileReader API to display the selected image
          const reader = new FileReader();
          reader.onload = () => {
            setBannerImageUrl(reader.result); // Immediate image for showing
          };
          reader.readAsDataURL(file);
        } else {
          // Display an error message if the selected file is not an image
          toast.error("Please select a valid image file (JPEG, PNG, JPG).", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
      };
    const handleImageClickBanner = () => {
        fileInputRefBanner.current.click();
    };
    return (
        <>
            <Row className="ContainerNotification">
                <Col>
                    <form onSubmit={formik.handleSubmit} className='Formcontainer'>
                    <div className="form-group row ">
                            {/* <label className="col-sm-2 col-form-label LableName"> <b>Select notification image</b> </label> */}
                            <div className="mb-3 col-sm-6 mb-3">
                                <div className="bannerbox-pushnotification">
                                <img
                                    src={
                                    bannerImageUrl
                                        ? bannerImageUrl?.includes("base64")
                                        ? bannerImageUrl
                                        : bannerImageUrl == "null"
                                        ? ""
                                        : `${baseUrl}uploads/${bannerImageUrl}`
                                        : bannerimage
                                    // : ""
                                    }
                                    className="img-fluid"
                                    onClick={(e) => handleImageClickBanner(e)}
                                ></img>
                                </div>
                                <input
                                    className="form-control d-none"
                                    type="file"
                                    name="banner"
                                    onChange={handleBannerChange}
                                    ref={fileInputRefBanner}
                                />
                            </div>
                        </div>
                        <div className='form-group row LableWrapper'>
                            <label htmlFor="pwaIDs" className="col-sm-2 col-form-label LableName"><b>My Business</b></label>
                            <div className="col-sm-10 mb-3 InputContainer">
                                <div className="" id="business-list">
                                    <div className="selectfield_wrapper">
                                        <select
                                            value={formik.values.pwaIDs}
                                            name='pwaIDs'
                                            id='pwaIDs'
                                            onChange={formik.handleChange}
                                            className="form-select"
                                            style={{ width: "100%" }}
                                        >
                                        <option value="select business">Select Business </option>
                                        {data && data.length>0 &&
                                            data?.map((val) => {
                                                if(val.progressier_id != null){
                                                    return (<option value={val?.progressier_id}>{val?.title} </option>)
                                                }
                                            })}
                                        </select>
                                        <div
                                        style={{
                                            fontSize: "14px",
                                            color: "red",
                                            bottom: "70px",
                                        }}
                                        >
                                        </div>
                                        <Errormsg> {formik.errors.pwaIDs && formik.touched.pwaIDs && (
                                            <div>{formik.errors.pwaIDs}</div>
                                            )}
                                        </Errormsg>                           
                                    </div>                       
                                </div>
                            </div>
                        </div>  
                        <div className="form-group row LableWrapper">
                            <label htmlFor="title" className="col-sm-2 col-form-label LableName"><b>Title</b></label>
                            <div className="col-sm-10 mb-3 InputContainer">
                                <input type="text" className="form-control" onChange={formik.handleChange} id="title" name="title" placeholder="Enter title" value={formik.values.title} />
                                <Errormsg> {formik.errors.title && formik.touched.title && (
                                    <div>{formik.errors.title}</div>
                                    )}
                                </Errormsg>
                            </div>
                        </div>

                        <div className="form-group row LableWrapper" >
                            <label htmlFor="message" className="col-sm-2 col-form-label LableName"><b>Message</b></label>
                            <div className="col-sm-10 mb-3 InputContainer">
                                <textarea className="form-control TextAreaa"  rows={10} name='message' onChange={formik.handleChange} id="message" value={formik.values.message} placeholder="Enter message" />
                                <Errormsg> {formik.errors.message && formik.touched.message && (
                                    <div>{formik.errors.message}</div>
                                    )}
                                </Errormsg>
                            </div>
                        </div>
                        {/* <div className="form-group row">
                            <label className="col-sm-2 col-form-label LableName"> <b>Select notification image</b> </label>
                            <div className="mb-3 col-sm-6 mb-3">
                                <div className="bannerbox-pushnotification">
                                <img
                                    src={
                                    bannerImageUrl
                                        ? bannerImageUrl?.includes("base64")
                                        ? bannerImageUrl
                                        : bannerImageUrl == "null"
                                        ? ""
                                        : `${baseUrl}uploads/${bannerImageUrl}`
                                        : bannerimage
                                    // : ""
                                    }
                                    className="img-fluid"
                                    onClick={(e) => handleImageClickBanner(e)}
                                ></img>
                                </div>
                                <input
                                    className="form-control d-none"
                                    type="file"
                                    name="banner"
                                    onChange={handleBannerChange}
                                    ref={fileInputRefBanner}
                                />
                            </div>
                        </div> */}
                        <div className="form-group row">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-10">
                                <button type="submit" className='btn btn-sendpush save-cta Changeblock_saveandpreview_button' disabled={!formik.isValid}>{(btnLoading)?<Spinner size="sm"/>:"Send Notification"}</button>
                            </div>
                        </div>
                    </form>
                </Col>
            </Row>
        </>
    );
}