import React, { useEffect, useState } from "react";
import { environmentVariables } from "../../../config/env.config";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from "styled-components";
import axios from 'axios';
import { useTranslation } from "react-i18next";

const FormikErrorStyle = styled.div`
font-size:11px;
color: #ff3333;
padding-bottom: 5px;
`;

const SuggetionWrapper = styled.ul`
    display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   padding-left:0;
`;
const Suggesstionlist = styled.li`
padding: 5px;
border: 1px solid #d7d7d7;
justify-content: center;
display: flex;
border-radius: 10px;
margin: 10px;
cursor: pointer;
// text-transform: capitalize;
font-size: 13px;
`;

const ResumeServiceForm = ({
  formCount,
  servicesData,
  setIsAddServiceDisable,
  setServicesData,
  removeForm,
  index,
}) => {
  const [skills, setSkills] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [data, setData] = useState({
    formCount: formCount,
    banner: null,
    skillname: "",
    description: "",
    buttonLink: "",
    buttonName: "",
    bannerImage: null,
    isImageUpload: true,
  });
  const {t} = useTranslation();

  const [tempFormCount, setTempFormCount] = useState(0);
  const [addBtnDisable, setAddBtnDisable] = useState(false);
  
  const [image, setImage] = useState(null);
  const [isShowAdd, setIsShowAdd] = useState(true);
  const baseUrl = environmentVariables?.apiUrl;
  const validationSchema = Yup.object({
    // name: Yup.string().matches(/^[a-zA-Z]+([a-zA-Z' -]*[a-zA-Z])*$/, 'Only alphabets are allowed').min(2, 'Minimum 2 characters'),
    skillname: Yup.string().matches(/^[a-zA-Z0-9]+([a-zA-Z0-9' -]*[a-zA-Z0-9])*$/,t('Only_alphabets_Character_are_allowed')).required(t("Skillname_required")).min(2, t('Minimum_2_characters_required')),
  });

  const formik = useFormik({
    initialValues: {
      skillname: data.skillname || '',
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleAddService(formik.values.skillname);
    },
    initialTouched: {
      skillname: false, // Initialize touched state for each field
    },
  });

  const handleServiceImgChange = (e) => {
    setImage(e.target.files);
    if (e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setData({ ...data, bannerImage: reader.result });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    let currentForm = servicesData[index];
    setTempFormCount(formCount);
    // console.log(index,"inexx")
    setData({
      // formCount: index,
      formCount: currentForm?.formCount,
      banner: currentForm?.image, ///////////////////////
      skillname: currentForm?.skillname,
      description: currentForm?.description,
      buttonLink: currentForm?.buttonLink,
      buttonName: currentForm?.buttonName,
      bannerImage: currentForm?.bannerImage,
      isImageUpload: currentForm?.isImageUpload,
    });

      // If 'name' field already has data, disable the add button
  if (currentForm?.skillname) {
    setAddBtnDisable(true);
  } else {
    setAddBtnDisable(false);
  }


    data["skillname"] ? setIsShowAdd(false) : setIsShowAdd(true);
  }, [servicesData]);

  useEffect(() => {
    // console.log(data,"datatatat")
    setTempFormCount(data?.formCount);
  }, [data]);

  const handleAddService1 = (nameValue) => {
    setAddBtnDisable(true);
    setIsAddServiceDisable(false);

    const newServicesData = [...servicesData];
    newServicesData.forEach((val) => {
      if (val.formCount === tempFormCount) {
        val.banner = image;
        val.skillname = nameValue;
        val.description = data?.description;
        val.buttonLink = data?.buttonLink;
        val.buttonName = data?.buttonName;
        val.bannerImage = data?.bannerImage;
        val.isImageUpload = data?.isImageUpload;
      }
    });
    // console.log(newServicesData, "new ervices daata");
    setData(newServicesData);
    setServicesData(newServicesData);
    // setIsAddDisable(true);
    
  };

  const handleAddService = async (nameValue) => {
    setAddBtnDisable(true);
    setIsAddServiceDisable(false);
  
    try {
      // Check if the skill already exists in the list of skills
      const existingSkill = skills.find(skill => skill.skill_name.toLowerCase() === nameValue.toLowerCase());
  
      if (!existingSkill) {
        // Skill doesn't exist, add it to the database
        const response = await axios.post(`${baseUrl}api/v1/resume/addskills`, { skillName: nameValue });
        const newSkill = response.data.skill;
        
        // Add the new skill to the list of skills
        setSkills([...skills, newSkill]);
      }
    } catch (error) {
      console.error('Error adding skill:', error);
      // Handle error
    }
  
    // Update services data
    const newServicesData = [...servicesData];
    newServicesData.forEach((val) => {
      if (val.formCount === tempFormCount) {
        val.banner = image;
        val.skillname = nameValue;
        val.description = data?.description;
        val.buttonLink = data?.buttonLink;
        val.buttonName = data?.buttonName;
        val.bannerImage = data?.bannerImage;
        val.isImageUpload = data?.isImageUpload;
      }
    });
    setData(newServicesData);
    setServicesData(newServicesData);
  };
  

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const response = await axios.get(`${baseUrl}api/v1/resume/fetchskills`);
        setSkills(response.data.skills);
      } catch (error) {
        console.error('Error fetching skills:', error);
      }
    };

    fetchSkills();
  }, []);


  const handleSuggestionClick = (suggestion) => {
    setSelectedSuggestion(suggestion);
    formik.setFieldValue('skillname', suggestion.skill_name);
    setSuggestions([]); 
  };


  const handleSkillInputChange = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const suggestions = inputLength === 0 ? [] : skills.filter(skill =>
      skill.skill_name.toLowerCase().slice(0, inputLength) === inputValue
    );
    setSuggestions(suggestions);
  };

  return (
    <div key={`form-${formCount}`} className="col-12" id="AddNewStyleFormfieldpad">
      {/* {console.log(formCount,"formcountntttntn")} */}
      <div className="serviceboxedit">
        <i
          class="fa-solid fa-circle-xmark crosseditservice crossIconAdddetails"
          onClick={() => removeForm(index)}
        ></i>
       
        <div>
          {/* <label class="form-label">Name</label> */}
          {data["isImageUpload"] ? (
            <div>
              <input
               name="skillname"
                disabled={addBtnDisable}
                class="form-control"
                style={{ padding: "14px 25px", marginBottom: "10px" }}
                type="text"
                placeholder="New Skills"
                autoComplete="false"
                // onChange={formik.handleChange}
                onChange={(e) => {
                  formik.handleChange(e);
                  handleSkillInputChange(e.target.value);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.skillname}
              />
               <SuggetionWrapper>
        {suggestions.map((suggestion, index) => (
          <Suggesstionlist key={index}
          //  onClick={() => formik.setFieldValue('skillname', suggestion.skill_name)}
          onClick={() => handleSuggestionClick(suggestion)}
           >
            {suggestion.skill_name}
          </Suggesstionlist>
        ))}
      </SuggetionWrapper>
              {formik.touched.skillname && formik.errors.skillname ? (
                <FormikErrorStyle>{formik.errors.skillname}</FormikErrorStyle>
              ) : null}
            </div>
          ) : (
            <div className="servicenameedit" id="ServicesEditformstyle">{data["skillname"] || ""}</div>
          )}
        </div>

        
        {isShowAdd && !addBtnDisable ? (
          <button
            type="button"
            disabled={!formik.isValid || !formik.dirty}
            onClick={() => {
              formik.handleSubmit();
              handleAddService(formik.values.skillname);
            }}
            className="btn btn-cu addbuttoneditform"
          >
            Add
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ResumeServiceForm;
