import React, { useContext, useEffect, useState } from "react";
import "./Style.css";
import PageName from "../Utils/PageName";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { environmentVariables } from "../../config/env.config";
import QRCode from "qrcode.react";
import Chartt from "./Chartt";
import Charttb from "./Charttb";
import Charttc from "./Charttc";
import Charttd from "./Charttd";
import Chartte from "./Chartte";
import Charttf from "./Charttf";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import { useNavigate, NavLink } from "react-router-dom";
import Business from "./Businesslist";
import Chart_phy from "./Chart_phy";
import Chart_bustas from "./Chart_bustas";
import "./Dstyles.css";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { getCurrencyDataByCurrency } from "../../utils/utility";
import {useTranslation} from "react-i18next";
import { GetFormattedDate } from "../Utils/GetFormattedDate";

function SuperDashbord({ isChangeDynamicBusinessName }) {
   const { userData } = useContext(userContext);
     const { t } = useTranslation();
  const navigate = useNavigate();
  const [cardsData, setCardsData] = useState(null);
  // const [userId, setUserId] = useState(null);
  const [totalBusiness, setTotalBusiness] = useState("");
  const [totalExportedBusinesses, setTotalExportedBusinesses] = useState("");
  const [totalExportedUser, setTotalExportedUser] = useState("");
  const [totalActiveBusiness, setTotalActiveBusiness] = useState("");
  const [totalUsers, setTotalUsers] = useState("");
  const [storedData, setStoredData] = useState(
    localStorage.getItem("businessObj")
  );
  const [appData, setAppData] = useState([]);
  const [contData, setContData] = useState([]);
  const [chartsRs, setChartsRs] = useState([]);
  const [totalLength, setTotalLength] = useState(0);
  const [businessLink, setBusinessLink] = useState("");
  const [sevdays, setSevdays] = useState({});
  const [usersData, setUsersData] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const [newLeads, setNewLeads] = useState(0);
  const [cardsSold, setCardsSold] = useState(0);
  const [cardsDelivered, setCardsDelivered] = useState(0);
  const [pipelineLeads, setPipelineLeads] = useState(0);
  const [rejectedLeads, setRejectedLeads] = useState(0);
  const [closedLeads, setClosedLeads] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [currencySymbol, setcurrencySymbol]= useState(null); 
  const [onlineRevenue, setOnlineRevenue] = useState(0);
  const [offlineRevenue, setOfflineRevenue] = useState(0);
  const [isLoadings, setIsLoadings] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filters,setFilters]=useState({});
  const [filterType, setFilterType] = useState(""); // Initially set to empty
  const [customDateRange, setCustomDateRange] = useState([null, null]); // For custom date range
  const [startDate, endDate] = customDateRange;
  
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [graphData, setGraphData] = useState({
    transformedData: [],
    gData2: [],
  });
  const filterNames = {
    current_date: "Today",
    last_week: "Last Week",
    last_month: "Last Month",
    last_year: "Last Year",
  };
  
  const handleFilterChange = (type) => {
    setFilterType(type);
    handleSubmit(type);
    setCustomDateRange([null, null]); // Reset custom date range when switching filters
    setIsDatePickerVisible(false); // Hide the date picker on filter change
  };
  
  const handleCustomDateChange = (dates) => {
    setCustomDateRange(dates);
  };
  
  const handleSubmit = (type) => {
    let start_date = "";
    let end_date = "";
    const today = new Date();
  
    switch (type) {
      case "current_date":
        start_date = today.toISOString().split("T")[0];
        end_date = start_date;
        break;
  
      case "last_week":
        const lastWeekStart = new Date();
        lastWeekStart.setDate(today.getDate() - 7); // 7 days back
        start_date = lastWeekStart.toLocaleDateString('en-CA');
        end_date = today.toLocaleDateString('en-CA');
        break;
  
      case "last_month":
        const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0); // Last day of the previous month
        start_date = lastMonthStart.toLocaleDateString('en-CA');
        end_date = lastMonthEnd.toLocaleDateString('en-CA');
        break;
  
      case "last_year":
        const lastYearStart = new Date(today.getFullYear() - 1, 0, 1); // Start of the previous year
        const lastYearEnd = new Date(today.getFullYear() - 1, 11, 31); // End of the previous year
        start_date = lastYearStart.toLocaleDateString('en-CA');
        end_date = lastYearEnd.toLocaleDateString('en-CA');
        break;
  
      case "custom":
        if (startDate && endDate) {
          start_date = startDate.toLocaleDateString('en-CA');
          end_date = endDate.toLocaleDateString('en-CA');
        } else {
          alert("Please select both start and end dates for the custom filter.");
          return;
        }
        break;
  
      default:
        alert("Please select a valid filter type.");
        return;
    }
  
    crmData(start_date, end_date); // Send dates to fetch data
  };
  const checkUserType = () => {
    const { user_type, email } = userData;

    switch (user_type) {
      case "super_admin":
        return "";
      case "Sales Executive":
        return email;
      case "Team Leader":
        return usersData.map(item => item.email).join(",") || null;
      default:
        return null;
    }
  }
    const handleRefresh =  () => {
      if (isLoadings) return;
      setFilterType("");
      setIsLoadings(true); 
      setFilters({}); 
      
      try {
        setTimeout(() => {
          const response = crmData();
          console.log(response);
        }, 1000);
       console.log(response.data);
      } catch (error) {
        console.error("Error refreshing data:", error.message);
      }
    setTimeout(() => {
        setIsLoadings(false);
      }, 1000); 
    };
    useEffect(() => {
      crmData();
    
    }, [usersData]);
    const crmData = (start_date, end_date) => {
      let email = checkUserType();
      if (email == null) return;
    
      let data = JSON.stringify({ start_date, end_date, email });
      const prevToken = "7d68e2c38dfb38785e7a";
      const token = btoa(prevToken);
    
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${environmentVariables?.crmDubaiApi}/api/LeadController/totalLeadsSummary`,
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        data: data,
      };
    
      axios
        .request(config)
        .then((response) => {
          const data = response?.data;
          let cSymbol = getCurrencyDataByCurrency('AED');
          setcurrencySymbol(cSymbol);
          if (data.success) {
            setTotalLeads(data?.data?.total_leads || 0);
            setNewLeads(data?.data?.new_leads || 0);
            setCardsSold(data?.data?.card_ordered || 0);
            setCardsDelivered(data?.data?.card_delivered || 0);

            const pipelineLeads = (
              Number(data?.data?.whatsap_msg_Leads || 0) +
              Number(data?.data?.answered_leads || 0) +
              Number(data?.data?.call_leads || 0) +
              Number(data?.data?.follow_up_leads || 0) +
              Number(data?.data?.intro_call_leads || 0) +
              Number(data?.data?.prospect_leads || 0) +
              Number(data?.data?.customer_leads || 0)
            );

            const totalRevenue = (
              Number(data?.data?.online_revenue || 0) +
              Number(data?.data?.offline_revenue || 0)
            );

            const rejectedLeads = (
              Number(data?.data?.failed_leads || 0) +
              Number(data?.data?.rejected_leads || 0)
            );

            setPipelineLeads(pipelineLeads);
            setTotalRevenue(totalRevenue);
            setRejectedLeads(rejectedLeads);
            setClosedLeads(data?.data?.close_leads || 0);
            setOnlineRevenue(data?.data?.online_revenue || 0);
            setOfflineRevenue(data?.data?.offline_revenue || 0);
          } else {
            setTotalLeads(0);
            setNewLeads(0);
            setCardsSold(0);
            setCardsDelivered(0);
            setRejectedLeads(0);
            setClosedLeads(0);
            setPipelineLeads(0);
            setTotalRevenue(0);
            setOnlineRevenue(0);
            setOfflineRevenue(0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }; 
      
  const [GreatingMsg, setGreatingMsg] = useState("");
  const [data, setData] = useState([]);
  const [planExpiredata, setPlanExpireData] = useState([]);
  const tableExtensions = { export: false, print: false };

  async function getGreatingMsg() {
    try {
      const response = await axios.get(
        `${baseUrl}api/v1/admin/setting/fetchConfig`,
        {
          params: {
            cnf_type: "Web",
            name: ["Greeting"],
          },
        }
      );
      if (response.status === 200) {
        setGreatingMsg(response?.data?.result?.Greeting);
      }
    } catch (error) {
      console.error({ "Error:": error, msg: "ErrorLanding" });
    }
  }

  const baseUrl = environmentVariables?.apiUrl;
  const crmDubaiApi=environmentVariables?.crmDubaiApi;
  const getGraphData = () => {
    // console.log("aaaaaaaaaaaaaadatare")
    axios
      .get(`${baseUrl}api/v1/business/fetchGraphData`, {
        params: { UserId: userData?.id },
        headers: {
          _token: userData?.token,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          setTotalExportedBusinesses(res?.data?.totalExportedBusinesses);
          setTotalExportedUser(res?.data?.totalExportedUser);
          setTotalBusiness(res?.data?.totalBusinesses);
          setTotalActiveBusiness(res?.data?.totalActiveBusinesses);
          setTotalUsers(res?.data?.totalUser);
          setSevdays(res?.data);
          if (res?.data?.resultA?.sum_of_appointments) {
            setAppData(res.data.resultA.sum_of_appointments);
          }
          if (res?.data?.resultC?.sum_of_contacts) {
            setContData(res.data.resultC.sum_of_contacts);
          }
          if (res?.data?.resultViewr) {
            setChartsRs(res.data?.resultViewr);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.error("Resource not found:", error);
        } else {
          console.error("error:", error);
        }
      });
  };

  const userCountData = () => {
    axios
      .get(`${baseUrl}api/v1/user/userCount`, {
        headers: {
          _token: userData?.token,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          setData(res?.data);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.error("Resource not found:", error);
        } else {
          console.error("error:", error);
        }
      });
  };

  const userPlanExppireData = () => {
    axios
      .get(`${baseUrl}api/v1/user/expiringPlans`, {
        headers: {
          _token: userData?.token,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          setPlanExpireData(res?.data?.data);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.error("Resource not found:", error);
        } else {
          console.error("error:", error);
        }
      });
  };

  let data1 = graphData?.transformedData;
  let data2 = [["Language", "Speakers (in millions)"]];
  data2.push(...graphData.gData2);
  useEffect(() => {
    getGraphData();
    getGreatingMsg();
    userCountData();
    userPlanExppireData();
    if (storedData != "undefined") {
      const businessData = JSON.parse(storedData);
      const slugb = businessData?.slug;
      const originUrl = window.location.origin;
      setBusinessLink(`${originUrl}/${slugb}`);
    }
  }, [storedData]);
  useEffect(() => {
    getGraphData();
  }, []);
  const handleCopyClick = () => {
    const linkToCopy = window.location.href;
    navigator.clipboard
      .writeText(businessLink)
      .then((res) => {
        setCopied(true);
      })
      .catch((error) => {
        console.error("Failed to copy link: ", error);
      });
    toast.info("Link Copy on Clipboard", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  };
  const apiUrl = environmentVariables?.apiUrl;
  useEffect(() => {
    let config = {
      method: "get",
      url: `${apiUrl}api/v1/user/getDashboardData`,
      headers: {
        _token: userData?.token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setCardsData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status) {
          localStorage.clear();
          window.location.href = "/";
          window.location.reload();
        }
      });
  }, []);

  // const fetchBusinessData = async (userId) => {
  //   try {

  //     let config = {
  //       method: "get",
  //       maxBodyLength: Infinity,
  //       url: `${environmentVariables?.apiUrl}api/v1/business/fetchBusiness?id=${userId}`,  //note: same api on APP.js please check too
  //       headers: {
  //         _token: userData?.token,
  //       },
  //       withCredentials: true,
  //     };
  //     await axios
  //       .request(config)
  //       .then((response) => {
  //         setTotalBusiness(response?.data?.data.length)
  //         // console.log(response?.data?.data.length,"ResumeAvilable")

  //       })
  //       .catch((error) => {
  //         // setIsloader(false);
  //         // console.log(error?.response?.data?.message, "hell in error ");
  //       });
  //   } catch (err) {
  //     console.log(err, "error");
  //   }
  // };

  // useEffect(() => {
  //     fetchBusinessData(userId);
  // }, [userId]);

  const customStyles = {
    dataTableExtensions: {
      backgroundColor: "lightgray",
      padding: "10px",
    },
    headRow: {
      style: {
        backgroundColor: "#F8FBFF",
        borderRadius: "2px 2px 0 0",
        paddingLeft: "25px",
        border: "none",
        textTransform: "capitalize",
        position: "sticky",
        left: 0,
        top: 0,
        zIndex: 1,
        boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        whiteSpace: "nowrap",
        height: "70px",
      },
    },
    headCells: {
      style: {
        textAlign: "left",
        minHeight: "64px",
        fontFamily: "DM Sans",
        fontSize: "16px",
        fontWeight: "600",
      },
    },
    rows: {
      style: {
        padding: "6px 0px 6px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "70px !important",
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        color: "#000",
      },
    },
    BusinessColumn: {
      position: "sticky",
      left: "0",
      zIndex: "1",
      backgroundColor: "#fff",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  };

  const columns = [
    {
      name: <div>Country</div>,
      selector: (row) => row.country,
      fixed: "left",
      },
    {
      // name: "Free Plan(15 Days)",
      name: (
        <div>
          Free Plan <br /> <span>(15 Days)</span>
        </div>
      ),
      selector: (row) => row.freePlan,
    },
    {
      // name: "Silver Plan(Yearly)",
      name: (
        <div>
          Silver Plan <br /> <span>(Yearly)</span>
        </div>
      ),
      selector: (row) => row.silverPlan,
    },
    {
      // name: "Gold Plan(Yearly)",
      name: (
        <div>
          Gold Plan <br /> <span>(Yearly)</span>
        </div>
      ),
      selector: (row) => row.goldPlan,
    },
    {
      // name: "Platinum Plan(Monthly)",
      name: (
        <div>
          Platinum Plan <br /> <span>(Monthly)</span>
        </div>
      ),
      selector: (row) => row.platinumPlan,
    },
    {
      // name: "Diamond Plan(Monthly)",
      name: (
        <div>
          Diamond Plan <br /> <span>(Monthly)</span>
        </div>
      ),
      selector: (row) => row.diamondPlan,
    },
    {
      name: "Custom Plan",
      selector: (row) => row.customPlan,
    },
    {
      name: "Total Plan",
      selector: (row) => row.total,
    },
  ];
  const tableData = {
    columns,
    data,
  };

  const planExpirecolumns = [
    {
      name: "Name",
      selector: (row) => row.name,
      fixed: "left",
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Phone",
      selector: (row) => `${row.country_code || ""} ${row.contact || ""}`,
    },
    {
      name: "Plan",
      selector: (row) => row.planObj.name,
    },
    {
      name: "Plan Purchase Date",
      sortable: true,
      sortCoulmn: "Plan",
      selector: (row) => ( GetFormattedDate(row.planDetails?.start_date)),
      width: "250px",
    },
    {
      name: "Plan Expiry Date",
      selector: (row) => (GetFormattedDate(row.plan_expire_date))
    },
  ];

  const tablePlanExpireData = {
    planExpirecolumns,
    planExpiredata,
  };
 const paginationOptions = {
    rowsPerPageText: t("Rows_per_page"),
    rangeSeparatorText: t("Of"),
    selectAllRowsItem: true,
    selectAllRowsItemText: t("All"),
  };
  
  return (
    <>
      <PageName PageName="Dashboard" />
      <div className="row Greeting_total_wrapper superAdminDashboard">
        <div className="col-md-8 SuperDashboardTop">
          <h3>Welcome Back, Super Admin!</h3>
          <h4>Here’s what happening with your business </h4>
        </div>

        {/* <div className="col-md-4" id="grettingWrapper">
          <div className="tile  welcom">
            <div>
              <h6>
                <b>{userData?.name}</b>
              </h6>

              <p>{GreatingMsg}</p>
              {businessLink?.includes("undefined") ? (
                ""
              ) : (
                <button className="copybtn" onClick={handleCopyClick}>
                  Business Link <i class="fa-solid fa-copy"></i>
                </button>
              )}
            </div>
            <div className="QRcode_wrapper">
              <QRCode
                value={businessLink}
                style={{
                  height: "65px",
                  width: "66px",
                  margin: "auto",
                  display: "block",
                }}
              />
            </div>
          </div>
        </div> */}
        <div className="col-md-12" id="total_wrapper_container">
          <div className="row">
            <div class="col-md-3">
              <div class="tile bbox Total_Titlebox_container">
                <i
                  class="fa-solid fa-shop"
                  style={{ background: " #FBF1FF", color: "#9E3EBF" }}
                ></i>
                <div>
                  <h3>Total Business</h3>
                  {/* {console.log(sevdays,"dashboardtotaldata")} */}
                  {/* <h4>{sevdays?.totalBusiness?.total || 0}</h4> */}
                  {/* <h4>{sevdays?.totalBusiness?.total || 0}</h4> */}

                  {/* {console.log (sevdays,"sdfdsff")} */}
                  <h4>{totalBusiness || 0}</h4>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tile bbox Total_Titlebox_container">
                <i
                  class="fa-solid fa-business-time"
                  style={{ background: " #F3F1FF", color: "#51459E" }}
                ></i>
                <div>
                  {" "}
                  <h3>Total Active Business</h3>
                  {/* {console.log(cardsData?.totalActiveBusinesses,"dashboardtotaldata")} */}
                  <h4>{totalActiveBusiness || 0}</h4>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tile bbox Total_Titlebox_container">
                <i
                  class="fa-regular fa-calendar-check"
                  style={{ background: " #FFEFEB", color: "#F87C5B" }}
                ></i>
                <div>
                  <h3>Total Appointments</h3>
                  {/* <h4>{sevdays?.resultA?.total || 0}</h4> */}
                  <h4>{sevdays?.totalAppointmentCount || 0}</h4>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tile bbox">
                <i
                  class="fa-solid fa-users"
                  style={{ background: " #EDFBFF", color: "#1BACDC" }}
                ></i>
                <div>
                  <h3>Total Users</h3>
                  {/* <h4>{sevdays?.totalStaff?.total || 0}</h4> */}
                  <h4>{totalUsers || 0}</h4>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tile bbox">
                <i
                  class="fa-solid fa-business-time"
                  style={{ background: "rgb(219 245 241 / 72%)", color: "rgb(17 142 135)" }}
                ></i>
                <div>
                  <h3 >
                    Total Imported Business
                  </h3>
                  <h4>{totalExportedBusinesses || 0}</h4>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tile bbox">
                <i
                  class="fa-solid fa-users"
                  style={{ background: " rgb(255 241 251)", color: "rgb(222 76 145)"}}
                ></i>
                <div>
                  <h3>Total Imported User</h3>
                  <h4>{totalExportedUser || 0}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p>
        <strong>Users Plan</strong>
      </p>
      
      <div className="scrollable-columns" style={{marginBottom:"50px"}}>
                <div className="sticky-header-container">
                  <DataTableExtensions
                    {...tableData}
                    {...tableExtensions}
                    customStyles={customStyles}
                    filterPlaceholder="Search Appoinments"
                  >
                    <DataTable
                      columns={columns}
                      data={data}
                      noHeader
                      defaultSortField="id"
                      sortIcon={<SortIcon />}
                      defaultSortAsc={true}
                      highlightOnHover
                      paginationServer
                      dense
                       paginationComponentOptions={{
                        ...paginationOptions,
                      }}
                      customStyles={customStyles}
                      className="UsersPlanTable"
                      noDataComponent={<div style={{padding:"24px"}}>{t("No_records_found")}</div>}
                    />
                  </DataTableExtensions>
                </div>
              </div>
          
        <div style={{ display: "flex", justifyContent: "space-between", gap: "10px", marginBottom: "20px" }}>
        <div>
        <p>
          <strong>Staff Stats</strong>
        </p> 
        </div>
        <div style={{display:"flex"}}>
        <div
           className="requestcardfilterstyle"
           onClick={!isLoadings ? handleRefresh : null}
           style={{
             display: "inline-flex",
             justifyContent: "center",
             alignItems: "center",
             width: "44px", // Icon size width
             height: "44px", // Icon size height
             cursor: isLoadings ? "not-allowed" : "pointer", // Disable click during loading
             color: isLoadings ? "gray" : "blue", // Change color when loading
           }}
         >
           {isLoadings ? (
             <CircularLoader size={24} />
           ) : (
             <RefreshIcon style={{ fontSize: "24px" }} />
           )}
         </div>
                   {/* Dropdown for predefined filters */}
                   {/* Custom Date Picker Button */}
                   <div style={{position:'relative', margin:"0 10px"}}>
                   <button
                     className="btn btn-primary"
                     onClick={() => { setIsDatePickerVisible(true); setFilterType("custom"); }} // Open the date picker and set filter type to custom
                     style={{
                       padding: "8px 12px",
                       backgroundColor: "#007bff",
                       color: "white",
                       border: "none",
                       borderRadius: "5px",
                       fontSize: "14px",
                       cursor: "pointer",
                     }}
                   >
                     Custom Date Range
                   </button>
                   {/* Custom Date Picker */}
                   {isDatePickerVisible && filterType === "custom" && (
                     <div style={{ position: "absolute", top:'-2px', right:'0', zIndex:'2' }}>
                       <DatePicker
                         selectsRange
                         startDate={startDate}
                         endDate={endDate}
                         onChange={handleCustomDateChange}
                         dateFormat="yyyy-MM-dd"
                         placeholderText="Select a custom date range"
                         inline
                         style={{
                           padding: "8px",
                           borderRadius: "5px",
                           border: "1px solid #ccc",
                           fontSize: "14px",
                         }}
                       />
                       <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                         <button
                           className="btn btn-secondary"
                           onClick={() => setIsDatePickerVisible(false)} // Close the date picker
                           style={{
                             padding: "8px 12px",
                             backgroundColor: "#6c757d",
                             color: "white",
                             border: "none",
                             borderRadius: "5px",
                             fontSize: "14px",
                             cursor: "pointer",
                           }}
                         >
                           Close
                         </button>
                         <button
                           className="btn btn-success"
                           onClick={() => { handleSubmit("custom"); setIsDatePickerVisible(false); }} // Apply the date filter and close the date picker
                           style={{
                             padding: "8px 12px",
                             backgroundColor: "#28a745",
                             color: "white",
                             border: "none",
                             borderRadius: "5px",
                             fontSize: "14px",
                             cursor: "pointer",
                           }}
                         >
                           Apply
                         </button>
                       </div>
                     </div>
                   )}
                   </div>
                   <div className="filter-dropdown">
                     <select
                       className="form-select"
                       onChange={(e) => handleFilterChange(e.target.value)} // Update filterType state
                       value={filterType || "Filter"} // Show "Filter" if filterType is empty
                       style={{
                         padding: "8px",
                         borderRadius: "5px",
                         border: "1px solid #ccc",
                         fontSize: "14px",
                         width: "200px",
                         marginRight: (isDatePickerVisible && filterType === "custom") ? '70px':''
                       }}
                     >
                       <option value="Filter" disabled>Select Filter</option>
                       {Object.keys(filterNames).map((key) => (
                         <option key={key} value={key}>
                           {filterNames[key]}
                         </option>
                       ))}
                     </select>
                   </div>

        </div>
       </div>
              <div className="col-md-12" id="total_wrapper_container">
        <div className="row">
          <div className="col-md-3">
            <div className="tile bbox Total_Titlebox_container">
              <h3>Total Leads</h3>
              <h4>{totalLeads || 0}</h4>
            </div>
          </div>
          <div className="col-md-3">
            <div className="tile bbox Total_Titlebox_container">
              <h3>New Leads</h3>
              <h4>{newLeads || 0}</h4>
            </div>
          </div>
          <div className="col-md-3">
            <div className="tile bbox ">
              <h3>Closed Leads</h3>
              <h4>{closedLeads || 0}</h4>
            </div>
          </div>
          <div className="col-md-3">
            <div className="tile bbox ">
              <h3>Pipeline Leads</h3>
              <h4>{pipelineLeads || 0}</h4>
            </div>
          </div>
          <div className="col-md-3">
            <div className="tile bbox Total_Titlebox_container">
              <h3>Rejected Leads</h3>
              <h4>{rejectedLeads || 0}</h4>
            </div>
          </div>
          <div className="col-md-3">
            <div className="tile bbox Total_Titlebox_container">
              <h3>Cards Sold</h3>
              <h4>{cardsSold || 0}</h4>
            </div>
          </div>
          <div className="col-md-3">
            <div className="tile bbox ">
              <h3>Cards Delivered</h3>
              <h4>{cardsDelivered || 0}</h4>
            </div>
          </div>
          <div className="col-md-3">
            <div className="tile bbox Total_Titlebox_container">
            <h3>Total Revenue</h3>
                <h4>{currencySymbol}{totalRevenue || 0}</h4>
                <div className='revenue' style={{display:"inline-flex"}}>
                <h3 style={{margin:"0px 40px 0px 0px"}}>Online<br/><b>{currencySymbol}{onlineRevenue || 0}</b></h3>  
                <h3 style={{marginTop:"0px"}}>Offline<br/><b>{currencySymbol}{offlineRevenue || 0}</b></h3> 
            </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
       
        <p>
          <strong>Upcoming Plan Expirations</strong>
        </p>
        <NavLink to="/businessowner">
          <span
            style={{color:"blue",
              padding: "4px 8px",
              borderRadius: "4px",
              border: "1px solid blue",
            }}
          >
            View All
          </span>
        </NavLink>
      </div>
      <div className="scrollable-columns" style={{ marginBottom: "50px" }}>
        <div className="sticky-header-container planExpirationTable">
          <DataTable
            columns={planExpirecolumns}
            data={planExpiredata}
            noHeader
            defaultSortField="id"
            sortIcon={<SortIcon />}
            defaultSortAsc={true}
            highlightOnHover
            paginationServer
            dense
             paginationComponentOptions={{
                        ...paginationOptions,
                      }}
            customStyles={customStyles}
            noDataComponent={<div style={{padding:"24px"}}>{t("No_records_found")}</div>}
          />
        </div>
      </div>

      <div className="row">
        <div className="Dashboard_appointment_visitors_wrapper">
          <div className="col-12 col-lg-6 Appointment_wrapper">
            <div class="tile border-left">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5>Appointments </h5>
                <span>Last 7 Days</span>
              </div>
              {sevdays &&
              typeof sevdays === "object" &&
              Object.keys(sevdays).length > 0 ? (
                <Charttc data={sevdays} />
              ) : null}
            </div>
          </div>

          <div className="col-12 col-lg-6 Visitors_wrapper">
            <div class="tile border-left">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5>Visitor </h5>
                <span></span>
              </div>
              {sevdays &&
              typeof sevdays === "object" &&
              Object.keys(sevdays).length > 0 ? (
                <Charttb data={sevdays} />
              ) : null}
            </div>
          </div>
        </div>
        <div className="physicalcard_recentbusiness_wrapper">
          <div className="col-12 col-lg-8 recentbusines_card_wrapper">
            <div class="tile border-left ">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5>Recent Business </h5>
                <span></span>
              </div>
              {sevdays &&
              typeof sevdays === "object" &&
              Object.keys(sevdays).length > 0 ? (
                <Charttf data={sevdays} />
              ) : null}
            </div>
          </div>

          <div className="col-12 col-lg-4 kheight physical_card_wrapper">
            <div class="tile border-left ">
              <h5>Physical Card </h5>
              {sevdays &&
              typeof sevdays === "object" &&
              Object.keys(sevdays).length > 0 ? (
                <Chartte data={sevdays} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SuperDashbord;
//1112