import React from 'react'

const Profile = () => {
  return (
    <>  
         <div className='row servicebox tile'>



<div class="mb-3 col-md-8">
             <div className='boxbanner'>       
                <label class="form-label">Banner:</label>
           <div className='bannerbox'>
            <img loading="lazy" src='https://placehold.co/1200x699' className='mx-auto d-block img-fluid'></img>
           </div>

           <input class="form-control" type="file" placeholder="Title"/>
           <small>(200x200px, 1:1 Ratio)</small>
           </div>

           </div>

<div class="mb-3 col-md-4">
           <div className='boxbanner'>         
             <label class="form-label">Logo:</label>
           <div className='bannerbox'>
            <img loading="lazy" src='https://placehold.co/1200x699' className='mx-auto d-block img-fluid'></img>
           </div>

           <input class="form-control" type="file" placeholder="Title"/>
           <small>(200x200px, 1:1 Ratio)</small>
           </div>
        
           </div>

                 <div class="mb-3 col-md-4">
                  <label class="form-label">Title:</label>
                  <input class="form-control" type="text" placeholder="Title"/>
                </div>
                <div class="mb-3 col-md-4">
                  <label class="form-label">Designation:</label>
                  <input class="form-control" type="text" placeholder="Designation"/>
                </div>
                <div class="mb-3 col-md-4">
                  <label class="form-label">Sub Title:</label>
                  <input class="form-control" type="text" placeholder="Sub Title"/>
                </div>

                <div class="mb-3 col-md-12">
                  <label class="form-label">Description:</label>
                  <textarea class="form-control" rows="4" placeholder="Enter your address">Hi, I'm a graphic designer !</textarea>
                </div>
          
         
         
         
         
         
               
                 </div>
    </>
  )
}

export default Profile
