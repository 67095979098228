import parsePhoneNumberFromString from "libphonenumber-js";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const isValidPhoneNumber = (value, country) => {
    try {
      const phoneNumber = parsePhoneNumberFromString(value, country?.toUpperCase());
      return phoneNumber?.isValid() || false;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

export const contactFormSchema = (t) => {
  return Yup.object({
    name: Yup.string()

      .matches(
    /^[\p{L}\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF\u064B-\u065F]+([\p{L}\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF\u064B-\u065F' -]*[\p{L}\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF\u064B-\u065F])?\s*$/u,
    t("Only_alphabets_and_Arabic_characters_are_allowed")
  ).required(t("Name_required"))
  .min(2, t("Minimum_2_characters_required"))
  .max(25, t("Maximum_25_characters_are_allowed")),
  // .matches(
  //   /^[a-zA-Z]+([a-zA-Z' -]*[a-zA-Z])?\s*$/,
  //   "Only alphabets are allowed"
  // )
 
  contactNumber: Yup.string()
    .required(t("Please_enter_your_phone_number"))
    .test(
      "isValidPhoneNumber",
      t("Invalid_number_for_selected_country"),
      (value, context) =>
        isValidPhoneNumber(value, context?.parent?.countryCode || "IN")
    ),
  // textareaValue: Yup.string().required("message is required").min(3, "Message must be at least 3 characters"),
  textareaValue: Yup.string()
  // .matches(/^[\p{L}\d\s.,!?()&@$'’"_\-–%:;\/]*$/u, "Invalid characters detected")
  .matches(/^[\p{L}\d\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF\u064B-\u065F\s.,!?()&@$'’"_\-–%:;\/]*$/u, t("Invalid_characters_detected"))
  .required(t("Message_required"))
  .min(3, t("Message_must_be_at_least_3_characters")),
  email: Yup.string()
    .email(t("Invalid_email_address"))
    .required(t("Email_required")),
  });
};
