import React from 'react'
import {OrderCardWrapperContainerSM, TabSocialContainer } from './Theme14ExtStyleComponent'
import { getServicesArray } from "../../../../utils/utility";
import { useTranslation } from 'react-i18next';
export const TabAlternateUrls = (props) => {
  const { t } = useTranslation();
  const rs = props.rs;
  const colordata = props?.colordata;
  const headingsObj = props?.rs?.headingsObj;
  return (
    <div>
    <OrderCardWrapperContainerSM style={{height:"36vh"}}>
    <h1 className='useful_link' style={{color: colordata?.dynamicTextHeadingColor  || "#000"}}>{headingsObj?.alternateUrls_Heading || t("Useful_links")}</h1>
      <TabSocialContainer style={{justifyContent:"start", margin:"0px 50px"}}>
       
      <ul className="servesbox" style={{display:"grid", gridTemplateColumns:"repeat(1, auto)"}}>
                    {getServicesArray(rs?.alternatUrlObj?.content).map((val) => {
                      const url = val?.description?.startsWith("http")  
                        ? val?.description
                        : `https://${val?.description}`;
                      return (
                        val?.name &&
                        val?.description && (
                          <li key={val?.name} className="service-list" style={{listStyleType: "disc", color: "black"}}>
                            <a href={url} target="_blank" rel="noopener noreferrer" style={{color: colordata?.dynamicTextHeadingColor || "", fontWeight:"500", fontsize:"14px !important"

                            }}>
                              {val?.name}
                            </a>
                          </li>
                        )
                      );
                    })}
                  </ul>
        </TabSocialContainer>
    </OrderCardWrapperContainerSM>
    </div>
  )
}
