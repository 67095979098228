import React from "react";
import "./SecondSection.css";
import tiltMobile from "../../../Images/tilt-mobile.png";
import leftSlide from "../../../Images/left-slide.png";
import rightSlide from "../../../Images/right-slide.png";
import { useNavigate } from "react-router-dom";

const SecondSection = () => {
  const navigate = useNavigate(null);
  return (
    <>
      <div className="second-sec">
        <div className="container border-blue">
          <div className="row align-center">
            <div className="col-md-7 relative">
              <img loading="lazy" src={tiltMobile} />
              <div className="left-slide">
                {" "}
                <img loading="lazy" src={leftSlide} />{" "}
              </div>
              <div className="right-slide">
                {" "}
                <img loading="lazy" src={rightSlide} />
              </div>
            </div>
            <div className="col-md-5 second-text">
              <h2>Business cards can be customized in a few clicks!</h2>
              <h5>
                Personality is unique to each individual. Your card can be
                designed and customized using Businessbay. Make a great first
                impression and make connections with a tap.
              </h5>
              <div
                style={{cursor:"pointer"}}
                onClick={() => navigate("/register")}
                className="blue-button"
              >
                Design Your Card 
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondSection;
