import React, { useContext, useEffect, useState, useRef } from "react";
import PageName from "../Utils/PageName";
import { Link } from "react-router-dom";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { ToastContainer, toast } from "react-toastify";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { businessContext } from "../../context/businessContext";
import CircularLoader from "../../Backend/Components/CircularLoader/CircularLoader";
import Swal from 'sweetalert2';
import avatar from "../../Images/blackpic2.jpg";
import {useTranslation} from "react-i18next";

function Menubanner_admin() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);
  const [isCoupon, setIsCoupon] = useState(false);
  const [couponName, setCouponName] = useState("");
  const [isStatus, setIsStatus] = useState(false);
  const [couponTypes, setCouponTypes] = useState("");
  const [position, setposition] = useState("");
  const [link, setlink] = useState("");
  const [activate, setActivate] = useState(false);
  const createCouponModalRef = useRef(null);
  const tableExtensions = { export: false, print: false };
  const [isUpdate, setIsUpdate] = useState(false);
  const [couponId, setCouponId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [logoImageUrl, setLogoImageUrl] = useState("");
  const fileInputRefLogo = useRef(null);
  const [logoData, setLogoData] = useState("");
  const [mktCategory, setMktCategory] = useState([])



  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const fetchCoupon = async (isDeleted = false) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${baseUrl}api/v1/menubanner/readmenubanner`, {
        headers: {
          _token: userData?.token,
        },
      });
      setIsLoading(false);
      if (res.data.success) {
        setData(res.data.data);
      } else {
        setData(res.data.data);
        !isDeleted && toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      setIsLoading(false);

      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCoupon();
    return () => {
      // Remove the event listener when the component unmounts
      $(createCouponModalRef.current).off("hidden.bs.modal");
    };
  }, []);
  const handleClearState = () => {
    try {
      // e.preventDefault();
      setCouponName("");
      setCouponTypes("");
      setposition("");
      setlink("");
      setActivate(false);
      setIsUpdate(false);
      setCouponId("");
      setLogoData("");
      setLogoImageUrl("");
      setIsCoupon(false);
      setSelectedFile(null);
      if (fileInputRefLogo.current) {
        fileInputRefLogo.current.value = "";
      }
    } catch (err) {
      console.log(err, "erororo");
    }
  };
  const handleImageClickLogo = () => {
    fileInputRefLogo.current.click();
  };
  const handleLogoChange = (e) => {
    const file = e.target.files[0];

    const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

    if (file && acceptedImageTypes.includes(file.type)) {
      setLogoData(file); 
      const reader = new FileReader();
      reader.onload = () => {
        setLogoImageUrl(reader.result); 
      };
      reader.readAsDataURL(file);
    } else {
      toast.error("Please select a valid image file (JPEG, PNG, JPG).", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };
  const handleEdit = (el) => {
    try {
      setIsUpdate(true);
      setCouponId(el?.id);
      setCouponName(el?.name);
      setCouponTypes(el?.types);
      setposition(el?.position);
      setlink(el?.link);
      setSelectedFile(el?.file_upload);
      setActivate(el?.is_active == 0 ? false : true);
      setIsCoupon(true);
      setIsStatus(false);
      $(createCouponModalRef.current).modal("show");
    } catch (err) {
      console.log(err, "Errorro");
    }
  };

  const handleDelete = async (d) => {
    try {
      // Display confirmation dialog using Swal.fire
      const result = await Swal.fire({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      // Check if the user confirmed the deletion
      if (result.isConfirmed) {
        // Proceed with the deletion API call
        let { data: res } = await axios.delete(
          `${baseUrl}api/v1/menubanner/deletemenubanner/${d?.id}`,
          {
            headers: {
              _token: userData?.token,
            },
          }
        );

        // Check the response from the API call
        if (res && res?.success) {
          let isDeleted = true;
          fetchCoupon(isDeleted);
          toast.success(res?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
      }
    } catch (err) {
      console.log(err, "error");
    }
  };



  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Types",
      selector: (row) => row.types,
      sortable: true,
    },
    {
      name: "Position",
      selector: (row) => row.position,
      sortable: true,
    },
    {
      name: "Link",
      selector: (row) => row.link,
      sortable: true,
    },
    // {
    //   name: "USED",
    //   selector: (row) => row.used,
    //   sortable: true,
    // },
    {
      name: "Action",
      sortable: false,
      cell: (d) => [
        <>
          <i
            key={`trash-${d.id}`}
            className="fas fa-trash-alt"
            onClick={() => handleDelete(d)}
          ></i>{" "}
          &nbsp;&nbsp; &nbsp;{" "}
          <i
            key={`edit-${d.id}`}
            className="first fas fa-pen"
            onClick={() => {
              handleEdit(d);
              setLogoImageUrl(d?.file_upload)
            }}
          ></i>
        </>
      ]
    }
  ];

  const customStyles = {

    headRow: {
      style: {
        display: "flex",
        alignItems: "stretch",
        width: "100%",
        backgroundColor: "#E9E9E9",
        minHeight: "52px",
        borderRadius: "2px 2px 0px 0px",
        padding: "8px 25px",
        border: "none",

      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "52px",
        fontSize: "15px",

      },
    },
    rows: {
      style: {
        padding: "20px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "48px ",
      },
    },
  };



  const tableData = {
    columns,
    data,
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", couponName);
    formData.append("types", couponTypes);
    formData.append("position", position);
    formData.append("link", link);
    formData.append('file_upload', logoData || selectedFile);
    formData.append("is_active", activate);
    try {
      if (!isUpdate && couponId === "") {
        if (!couponName?.trim()) {
          toast.error("Coupon name is mandatory", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          return; // Exit if error occurs
        } else if (!couponTypes?.trim()) {
          toast.error("Coupon Types is mandatory", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          return; // Exit if error occurs
        } else if (!position?.trim()) {
          toast.error("Position is mandatory", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          return; // Exit if error occurs
        } else if (position === "percentage" && link > 99) {
          toast.error("Discount Value cannot be equal to or more than 100%", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          return; // Exit if error occurs
        } else if (!logoData){
          toast.error("Banner is mandatory", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          return;
        }
      }
      const obj = {
        name: couponName,
        types: couponTypes,
        position: position,
        link: link,
        is_active: activate,
      };
      let res;
      // if (isUpdate && couponId) {
      if (isUpdate && couponId !== "") {
        res = await axios.put(`${baseUrl}api/v1/menubanner/editmenubanner/${couponId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            _token: userData?.token,
          },
        });
      } else {
        res = await axios.post(`${baseUrl}api/v1/menubanner/createmenubanner`, formData, {
          headers: {
            _token: userData?.token,
            "Content-Type": "multipart/form-data",
          },
        });
        setSelectedFile(null);
      }
      
      if (res?.data?.success) {
        fetchCoupon();
        handleClearState();
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        // Close the modal
        $(createCouponModalRef.current).modal("hide");
      }
    } catch (err) {
      const errorMessage = err?.response?.data?.message || "An error occurred";
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  useEffect(()=>{
    const fetchbusinesscategories = async () => {
      try {
        const { data } = await axios.get(`${baseUrl}api/v1/business/fetch_business_category`);
       
        setMktCategory(data?.data)
      } catch (error) {
        console.log(error);
        toast.error("Failed to fetch categories", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    };
    fetchbusinesscategories();
  },[])
 const paginationOptions = {
    rowsPerPageText: t("Rows_per_page"),
    rangeSeparatorText: t("Of"),
    selectAllRowsItem: true,
    selectAllRowsItemText: t("All"),
  };
  
  return (
    <>
      <PageName PageName="Manage banner" />
      <article>
        <div className="main">
          <div data-bs-toggle="modal" data-bs-target="#createCouponModal">
            <button
              id="Currency_admin_button"
              type="button"
              className="save-cta"
              onClick={(e) => setIsCoupon(!isCoupon)}
            >
              + Create manage banner{" "}
            </button>
          </div>

          {isLoading ? (
            <CircularLoader size={40} />
          ) : (
            <DataTableExtensions
              {...tableData}
              filterPlaceholder="Search Coupon"
              customStyles={customStyles}
              {...tableExtensions}
            >
              <DataTable
                columns={columns}
                data={data}
                noHeader
                defaultSortField="id"
                sortIcon={<SortIcon />}
                // defaultSortAsc={false}
                pagination
                highlightOnHover
                dense
                 paginationComponentOptions={{
                        ...paginationOptions,
                      }}
                customStyles={customStyles}
                noDataComponent={<div style={{padding:"24px"}}>{t("No_records_found")}</div>}
              />
            </DataTableExtensions>
          )}
        </div>

        <div
          className={`modal fade ${isCoupon ? "show" : ""}`}
          id="createCouponModal" // Match this with data-bs-target
          tabIndex="-1"
          ref={createCouponModalRef}
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {isUpdate && couponId !== "" ? "Edit" : "Create New"} Manage banner
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => {
                    setIsCoupon(!isCoupon);
                    handleClearState();
                  }}
                ></button>
              </div>

              {/* Add form tag here */}
              <form onSubmit={(e) => handleSubmit(e)} encType="multipart/form-data">
                <div className="modal-body" style={{ padding: "0" }}>
                  <div className="mb-2" id="Coupons_admin_createform_container">
                    {/* Name */}
                    <input
                      placeholder="Name"
                      type="text"
                      value={couponName}
                      className="form-control"
                      onChange={(e) => setCouponName(e.target.value)}
                    />

                    {/* Types */}
                    {/* <select
                      className="form-control"
                      value={couponTypes}
                      onChange={(e) => setCouponTypes(e.target.value)}
                      required
                    >
                      <option value="">Select Type</option>
                      <option value="hotel">Hotels</option>
                      <option value="influencer">Influencer</option>
                      <option value="googlereview">Google Review</option>
                      <option value="construction">Real Estate & Construction</option>
                      <option value="salon">Spa & Salon</option>
                      <option value="doctor">Medical</option>
                      <option value="art">Art & Craft</option>
                      <option value="journalist">Journalist</option>
                      <option value="chef">Chef</option>
                      <option value="attorney">Attorney</option>
                      <option value="home_decor">Home Decor</option>
                      <option value="house_keeping">House Keeping Services</option>
                      <option value="education">Education</option>
                      <option value="rent_hire">Rent & Hire</option>
                      <option value="contractors">Contractors</option>
                      <option value="pet_shop">Pet Shops</option>
                      <option value="pg">PG/Hotels</option>
                      <option value="event_organizer">Event Organizers</option>
                      <option value="driving_schools">Driving Schools</option>
                      <option value="Pockers">Pockers & Movers</option>
                      <option value="printing">Printing & Publishing Services</option>
                      <option value="electricians">Electricians</option>
                      <option value="repair">Repair & Services</option>
                      <option value="Jawellery_showroom">Jewellery Showrooms</option>
                      <option value="gym">Gym/Yoga Center</option>
                      <option value="mini_market">Mini Marketplace</option>
                      <option value="restaurant">Restaurant</option>
                      <option value="cab">Cab</option>
                    </select> */}

                    <select
                     className="form-control"
                     value={couponTypes}
                      onChange={(e) => setCouponTypes(e.target.value)}
                      >
                    <option value="">Select Category*</option>
                    <option value="homebanner">Home Banner</option>
                    <option value="homebanner_2">Home Banner 2</option>
                    {mktCategory?.map((catname)=>(
                      <option  key={catname.id} value={catname.cat_slug}>{catname?.cat_name} </option>
                ))}
              </select>


                    {/* Position */}
                    <select
                      className="form-control"
                      onChange={(e) => setposition(e.target.value)}
                      value={position}
                    >
                      <option value="">Select Position</option>
                      <option value="Horizontal">Horizontal</option>
                      <option value="Vertical">Vertical</option>
                    </select>

                    {/* Link */}
                    <input
                      placeholder="link"
                      type="url"
                      className="form-control"
                      value={link}
                      onChange={(e) => setlink(e.target.value)}
                    />

                      <div className="mb-3 col-md-4">
                        <div className="detailsdptextlogowrapper">
                          <label className="form-label detailsdptext">
                          {couponTypes !== "homebanner" || couponTypes !== "homebanner_2" ?  "Recommended Size: 150 x 150 pixels" : "Recommended Size: 1920 x 586 pixels"}
                          </label>
                          <div className="" style={{width:"150px", height:"150px"}}>
                            <img
                              src={
                                logoImageUrl
                                  ? logoImageUrl?.includes("base64")
                                    ? logoImageUrl
                                    : logoImageUrl == "null"
                                      ? ""
                                      : `${baseUrl}${logoImageUrl}`
                                  : avatar
                              }
                              className="mx-auto d-block"
                              onClick={handleImageClickLogo}
                              style={{width:"100%", height:"150px"}}
                            />
                          </div>
                          <input
                            className="form-control d-none"
                            type="file"
                            name="logo"
                            onChange={handleLogoChange}
                            ref={fileInputRefLogo}
                            accept=".png, .jpg, .jpeg"
                          />
                        </div>
                      </div>
                    
                  </div>

                  {/* Activate */}
                  <div className="mb-2" id="Currency_Activate_Name">
                    Activate
                    <div id="Currency_Radio_wrapper">
                      <label>
                        <input
                          type="radio"
                          checked={activate === true}
                          onChange={() => setActivate(true)}
                        />{" "}
                        Yes
                      </label>
                      <label id="Currency_radio_No">
                        <input
                          type="radio"
                          checked={activate === false}
                          onChange={() => setActivate(false)}
                        />{" "}
                        No
                      </label>
                    </div>
                  </div>
                </div>

                {/* Submit button */}
                <div className="modal-footer" id="Coupanadmin_Button_Wrapper">
                  <button
                    id="Coupan_edit_Button"
                    type="submit"
                    className="btn btn-primary"
                    onClick={(e) => handleSubmit(e)}
                  >
                    {isStatus ? (
                      <CircularLoader size={20} />
                    ) : isUpdate && couponId !== "" ? (
                      "Update"
                    ) : (
                      "Create"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}

export default Menubanner_admin;
