import axios from "axios";
import { environmentVariables } from "../config/env.config";
import parsePhoneNumberFromString from "libphonenumber-js";
import moment from 'moment';
import NoImage from "../Images/No image available.png";


export const getContactInfo = (responseData)=>{
    let jsonData = responseData;
    if (jsonData?.length) {
      jsonData = JSON?.parse(jsonData);
    }
    if (jsonData?.length) {
      jsonData = JSON?.parse(jsonData);
    }
    return jsonData?.contactInfoAddress;
}

export const isValidEmail = (email) => {
  const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
  return emailRegex.test(email);
};

export const isValidPassword = (password) => {
  // Minimum length of 8 characters and at least one letter and one digit
  const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/;
  return passwordRegex.test(password);
};

export const isValidName = (name) => {
  const nameRegex = /^[A-Za-z -]{3,}$/;
  return nameRegex.test(name);
};

export const fetchFiles = (fileName, cardName)=>{
  return new Promise((resolve, reject)=>{
    try{
      let apiUrl  = `${environmentVariables?.apiUrl}api/v1/phy_card/fetchFiles`;
      let payload = {
        fileName:fileName, cardName:cardName
      }
      let config = {
        method: 'post',
        url: apiUrl,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : payload
      };
      
      axios.request(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        resolve(false);
      });
    }catch(error){
      console.log("error====>>>", error);
      resolve(false);
    }
  })
  
}

export const isValidPhoneNumber = (inputNumber, country) => {
 try{
  const phoneNumber = parsePhoneNumberFromString(inputNumber, country?.iso2?.toUpperCase());
  return phoneNumber?.isValid() || false;
 }catch(err){
  console.log(err)
 }
};

export   const getAvailableSlots = (slotsData, date)=>{
  const currentDate = moment();
  const dateWithTimeSlots = slotsData.map(slot => {
    return {
      ...slot,
      startDateTime: moment(`${date} ${slot.startDate}`, 'YYYY-MM-DD hh:mm A'),
      endDateTime: moment(`${date} ${slot.endDate}`, 'YYYY-MM-DD hh:mm A')
    };
  });
  const filtered = dateWithTimeSlots.filter(slot => {
    return slot.startDateTime.isAfter(currentDate);
  });
  return filtered;
}

export const getJSON = (str)=>{
    try {
        return JSON.parse(str) && !!str;
    } catch (e) {
        return false;
    }
}

export const getServicesArray = (services)=> {
  
  if (typeof services === 'string') {
      try {        
        const parsedArray = JSON.parse(services);
        if (Array.isArray(parsedArray)) {
          return parsedArray;
        }else{
          return typeof parsedArray =='object'? parsedArray: JSON.parse(parsedArray);
        }
      } catch (e) {
          console.error('Invalid JSON string:', e);
      }
  }
  
  return Array.isArray(services) ? services : [];
}



export const handleErrorImage = (event) => {
  event.target.src = NoImage;
};

export const fetchCountries = async () => {
  try {
    const response = await axios.get(`${environmentVariables?.apiUrl}api/v1/user/getCountries`);
    return response?.data?.result;
  } catch (error) {
    console.error("Error in fetching countries:", error);
    return [];
  }
};


export const getSlugFromURL = (url)=>{
  const parsedUrl = new URL(url);
  const slugData = parsedUrl.pathname.split("/");
  const slug = slugData.filter(Boolean).pop();
  return slug;
}
export const getShortDescription = (description, length = 100)=> {
  if (!description) return "";
  return description.length > length
    ? description.slice(0, length) + "..."
    : description;
}
export const getOrderStatus = (order_status) =>{
  if (order_status === "new") {
    return "Confirmed";
  } else if (order_status == "outfordelivery") {
    return "Out For Delivery";
  } else if (order_status == "failed") {
    return "Failed";
  } else if (order_status == "processing") {
    return "Processing";
  } else if (order_status == "cancelled") {
    return "Cancelled";
  } else if (order_status == "delivered") {
    return "Delivered";
  } else if (order_status == "return-request") {
    return "Return Request";
  } else if (order_status == "return-failed") {
    return "Return Failed";
  } else if (order_status == "return-success") {
    return "Return Success";
  } else if (order_status == "refunded") {
    return "Refunded";
  } else {
    return "--";
  }
}

export const formatSubscribers = (subscriberCount) => {
  if (subscriberCount < 1000) {
    return `${subscriberCount}`;
  } else if (subscriberCount >= 1000 && subscriberCount < 1000000) {
    return `${(subscriberCount / 1000).toFixed(2)}K`;
  } else {
    return `${(subscriberCount / 1000000).toFixed(2)}M`;
  }
}

export const getCurrencyData = (countryCode)=>{
  let currencySymbol = '';
  switch (countryCode) {
    case "IN":
      currencySymbol = "₹";
      break;
    case "US":
      currencySymbol = "$";
      break;
    case "AE":
      currencySymbol = "د.";
      break;
    default:
      currencySymbol = "₹";
      break;
  }
  return currencySymbol;
}
export const getCurrencyDataByCurrency = (currencyCode)=>{
  let currencyObj = {
    "INR":"₹",
    "USD":"$",
    "AED":"د.",
  }
  return currencyObj[currencyCode] || "₹";
}

export const getCurrencyByCountryCode = (countryCode)=>{
  let currencyByCountryObj = {
    "IN":"₹",
    "USA":"$",
    "AE":"د."
  }
  return currencyByCountryObj[countryCode] || "₹";
}

export const getcoutryfromlocation = () => {
  return new Promise((resolve, reject) => {
    let config = {
      method: 'get',
      url: `${environmentVariables?.apiUrl}api/v1/business/getCountryCodePhoneCode`,
      headers: { }
    };

    axios.request(config)
    .then((responseRes) => {
      if(!!responseRes.data){
        resolve(responseRes?.data?.result);
      }else{
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            try {
              const { latitude, longitude } = position.coords;
    
              const response = await fetch(
                `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
              );
              const data = await response.json();
    
              if (data?.countryCode) {
                let config = {
                  method: 'get',
                  url: `${environmentVariables?.apiUrl}api/v1/user/getCountryByCountryCode?ccode=${data?.countryCode}`,
                  headers: { }
                };
    
                axios.request(config)
                .then((responseData) => {                  
                  let dataresponse = responseData?.data?.result;
                  let newRes = {
                    countryCode:data?.countryCode,
                    phoneCode: !!dataresponse? '+'+dataresponse?.phone_code:""
                  }
                  resolve(newRes);
                })
                .catch((error) => {
                  console.log(error);
                  resolve(null);
                });
              } else {
                resolve(null);
              }
            } catch (error) {
              console.error("Error fetching location data:", error);
              reject(error);
            }
          },
          (error) => {
            console.error("Geolocation error:", error);
            reject(error);
          }
        );
      }
    })
    .catch((error) => {
      console.log(error);
      reject(error);
    });
  });
};
export const getSocialLinkData = (socialObj, socialName) => {
  return new Promise((resolve, reject)=>{
    let socialMediaInfo = socialObj?.content;        
    let socialLink = '';
    if (socialMediaInfo?.length) {  
      socialMediaInfo = JSON.parse(socialMediaInfo);        
      if(typeof socialMediaInfo == 'string'){
        socialMediaInfo = JSON.parse(socialMediaInfo);        
      }
      
      // Find the object where nameOfSocial is "Youtube"
      const socialLinkObj = socialMediaInfo.find(
        (social) => social.nameOfSocial === socialName
      );

      // Extract the link or handle if not found
      socialLink = socialLinkObj ? socialLinkObj.link : null;
    }
    resolve(socialLink);
  })
};

export const getContactImageData = (bid) =>{
  return new Promise((resolve, reject)=>{
    try{

      let data = JSON.stringify({
        "bid": bid
      });
    
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${environmentVariables?.apiUrl}api/v1/business/getContactImage`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
    
      axios.request(config)
      .then((response) => {
        if(!!response.data){
          resolve(response.data.data);
        }else{
          resolve(null);
        }
      })
      .catch((error) => {
        console.log(error);
        resolve(null);
      });

    }catch(error){
      console.log("error===>>>", error);
      resolve(null)      
    }
  })
} 

export const getFilterContactInfo = (data) => {
  if (!data) return '';
  return data.replace(/[\r\n]+/g, ' ').trim();
};

const currentSlug = window.location.pathname.split('/')[1];
const checkSubDomain = (slugData)=>{   
  let reservedSubdomain = ['www', 'uat', 'app', 'test','dashboard', 'appoinments','super_dashboard', 'influencer_dashboard', 'businesslist','Roles','user','requestCard_admin','Influencerorder_admin','PlansSuperAdmin','Coupons_admin','influencerpayments','Events_admin','Plan_enquiry','bulk_upload','userUploadList','Setting_admin','plans','contact','MinimarketEnquiry','send-notification','restaurantorders','profile','create-card', 'business', 'requestCard', 'create_pay_link', 'Dpopaymentlink', 'DpopaymentProductPurchase', 'influencer_orders', 'Influencers','superadminhiremedetails', 'influencer_coupons', 'DpopaymentBackProductPurchaseMiniMarket', 'AppointmentSuperAdmin', 'CabEnquiry', 'MinimarketEnquiry'];
  return reservedSubdomain.includes(slugData);
}

export const getSubdomainSlug = () => {
  const { hostname } = window.location;

  const mainDomainArray = [
    "localhost:3000",
    "uat.businessbay.io",
    "app.businessbay.io",
    "test.businessbay.io",
    "businessbay.io"
  ];

  if (!mainDomainArray.includes(hostname)) {
    const parts = hostname.split(".");
    if (parts.length > 1) {
      return parts[0];
    }
  }
  return "";
};
  
export const checkURLSubdomain = ()=>{
  let checkData = true;
  let notOpen = ['login', 'register', 'forgot-password', 'customer_login', 'customer_register', 'customer_profile'];
  let subdomainSlug = getSubdomainSlug();
  if(!!currentSlug){
    if(notOpen.includes(currentSlug)){
      checkData = true;
    }else{
      checkData = checkSubDomain(currentSlug);
    }
  } else if(!!subdomainSlug){
    if(notOpen.includes(subdomainSlug)){
      checkData = true;
    }else{
      checkData = checkSubDomain(subdomainSlug);
    }
  }
  return checkData;
}

export const getLanguage = (currentLang) => {        
  let allLang = {
      "en":"EN",
      "ar":"العربية"
  };
  return allLang[currentLang] || "EN";
};