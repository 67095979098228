// import React, { useEffect } from "react";
// import { useState } from "react";
// import { environmentVariables } from "../../../../config/env.config";
// import nextarrow from '../../../../Images/theme18-next-gallery-arrow.png';
// import prevarrow from '../../../../Images/theme-18-prev-gallery-arrow.png';

// const GalleryCarousel = ({ slides, video }) => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   // const [backgroundImage, setBackgroundImage] = useState(
//   //   `url(${environmentVariables?.apiUrl}uploads/gallery/${slides[0]?.image})`
//   // );
//   // const [backgroundImage1, setBackgroundImage1] = useState(
//   //   `url(${environmentVariables?.apiUrl}uploads/gallery/${slides[0]?.image})`
//   // );
//   // useEffect(() => {
//   //   setBackgroundImage(
//   //     `url(${environmentVariables?.apiUrl}uploads/gallery/${slides[currentIndex]?.image})`
//   //   );
//   //   setBackgroundImage1(
//   //     `url(${environmentVariables?.apiUrl}uploads/gallery/${
//   //       slides[currentIndex + 1]?.image
//   //     })`
//   //   );
//   //   console.log(
//   //     `url(${environmentVariables?.apiUrl}uploads/${slides[currentIndex]?.image})`,
//   //     "backgroundimage"
//   //   );
//   // }, [currentIndex]);

//   const [backgroundImage, setBackgroundImage] = useState(null);
//   const [backgroundImage1, setBackgroundImage1] = useState(null);
//   const [backgroundImage2, setBackgroundImage2] = useState(null);
//   const [backgroundImage3, setBackgroundImage3] = useState(null);
//   const [backgroundImage4, setBackgroundImage4] = useState(null);
//   const [backgroundImage5, setBackgroundImage5] = useState(null);

//   useEffect(() => {
//     if (slides.length > currentIndex) {
//       setBackgroundImage(
//         `url(${environmentVariables?.apiUrl}uploads/gallery/${slides[currentIndex]?.image})`
//       );
//       setBackgroundImage1(
//         `url(${environmentVariables?.apiUrl}uploads/gallery/${slides[currentIndex + 1]?.image
//         })`
//       );
//       setBackgroundImage2(
//         `url(${environmentVariables?.apiUrl}uploads/gallery/${slides[currentIndex + 2]?.image
//         })`
//       );
//       setBackgroundImage3(
//         `url(${environmentVariables?.apiUrl}uploads/gallery/${slides[currentIndex + 3]?.image
//         })`
//       );
//       setBackgroundImage4(
//         `url(${environmentVariables?.apiUrl}uploads/gallery/${slides[currentIndex + 4]?.image
//         })`
//       );
//       setBackgroundImage5(
//         `url(${environmentVariables?.apiUrl}uploads/gallery/${slides[currentIndex + 5]?.image
//         })`
//       );
//     }
//   }, [currentIndex, slides]);

//   const slideStyles = {
//     width: "100%",
//     height: "200px",
//     borderRadius: "0",
//     backgroundSize: "cover",
//     backgroundPosition: "center",
//   };
//   const sliderStyles = {
//     position: "relative",
//     height: "100%",
//   };

//   const rightArrowStyles = {
//     // position: "absolute",
//     // top: "50%",
//     // transform: "translate(0, -50%)",
//     // right: "32px",
//     fontSize: "24px",
//     color: "#000",
//     zIndex: 1,
//     cursor: "pointer",
//   };

//   const leftArrowStyles = {
//     // position: "absolute",
//     // top: "50%",
//     // transform: "translate(0, -50%)",
//     // left: "32px",
//     fontSize: "24px",
//     color: "#000",
//     zIndex: 1,
//     cursor: "pointer",
//   };

//   const slideStylesWidthBackground = {
//     ...slideStyles,
//   };
//   const slideStylesWidthBackground2 = {
//     ...slideStyles,
//     backgroundImage: `url(${environmentVariables?.apiUrl}uploads/${slides[currentIndex + 1]
//       })`,
//   };
//   const goToSlide = (slideIndex) => {
//     setCurrentIndex(slideIndex);
//   };

//   const dotsContainerStyles = {
//     display: "flex",
//     justifyContent: "center",
//     color: "#585858",
//   };

//   const dotStyle = {
//     margin: "0 3px",
//     cursor: "pointer",
//     fontSize: "30px",
//   };
//   const goToPrevious = () => {
//     const isFirstSlide = currentIndex === 0;
//     if (video) {
//       const newIndex = isFirstSlide ? slides.length : currentIndex - 1;
//       setCurrentIndex(newIndex);
//     } else {
//       const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
//       setCurrentIndex(newIndex);
//     }
//   };
//   const goToNext = () => {
//     if (video) {
//       const isLastSlide = currentIndex === slides.length;
//       const newIndex = isLastSlide ? 0 : currentIndex + 1;
//       setCurrentIndex(newIndex);
//     } else {
//       const isLastSlide = currentIndex === slides.length - 1;
//       const newIndex = isLastSlide ? 0 : currentIndex + 1;
//       setCurrentIndex(newIndex);
//     }
//   };
//   return (
//     <div className="theme18-gallery-wrapper">
//       <div  className="theme18-gallery-inner-wrapper"> 
//         <div onClick={goToPrevious} >   <img loading="lazy" src={prevarrow} alt='prev arrow' />   </div>        
//         <div onClick={goToNext} >  <img loading="lazy" src={nextarrow} alt="next arrow" />  </div>
//       </div>
//       {/* <div className="twoimages">
//         <div
//           style={{
//             ...slideStylesWidthBackground,
//             backgroundImage: backgroundImage,
//           }}
//         ></div>
//         <div
//           style={{
//             ...slideStylesWidthBackground2,
//             backgroundImage: backgroundImage1,
//           }}
//         ></div>
//       </div> */}
//       {slides.length > 0 && (
//        <>
       
//        <div className="twoimages ">
//           {backgroundImage && (
//             <div
//               style={{ ...slideStylesWidthBackground, backgroundImage: backgroundImage, }}></div>
//           )}
//           {backgroundImage1 && (
//             <div
//               style={{
//                 ...slideStylesWidthBackground2, backgroundImage: backgroundImage1,
//               }}
//             ></div>
//           )}
          
//         </div>
//         <div className="twoimages ">
//         {backgroundImage && (
//           <div
//             style={{ ...slideStylesWidthBackground, backgroundImage: backgroundImage2, }}></div>
//         )}
//         {backgroundImage1 && (
//           <div
//             style={{
//               ...slideStylesWidthBackground2, backgroundImage: backgroundImage3,
//             }}
//           ></div>
//         )}
        
//       </div>
//       <div className="twoimages ">
//       {backgroundImage && (
//         <div
//           style={{ ...slideStylesWidthBackground, backgroundImage: backgroundImage4, }}> </div>
//       )}
//       {backgroundImage1 && (
//         <div
//           style={{
//             ...slideStylesWidthBackground2, backgroundImage: backgroundImage5,
//           }}
//         ></div>
//       )}
      
//     </div></>
//       )}
//       {/* <div style={dotsContainerStyles}>
//         {slides.map((slide, slideIndex) => {
//           return (
//             <div
//               style={{ ...dotStyle, color: currentIndex == slideIndex ? "#ff713d" : "#525252", }}
//               key={slideIndex} onClick={() => goToSlide(slideIndex)}  >
//               ●
//             </div>
//           );
//         })}
//       </div> */}
//     </div>
//   );
// };

// export default GalleryCarousel;





import React, { useState, useEffect } from "react";
import { environmentVariables } from "../../../../config/env.config";
import nextarrow from '../../../../Images/theme18-next-gallery-arrow.png';
import prevarrow from '../../../../Images/theme-18-prev-gallery-arrow.png';
import { Lightbox } from "../../../Components/PortfolioPopup/PortfolioPopup";

const GalleryCarousel = ({ slides , colordata}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const imagesToShow = Math.min(6, slides.length);

  const updateBackgroundImages = () => {
    const indices = [];
    for (let i = 0; i < imagesToShow; i++) {
      indices.push((currentIndex + i) % slides.length);
    }
    return indices.map(index => `url(${environmentVariables?.s3UrlBackendUrl}gallery/${slides[index]?.image})`);
  };

  useEffect(() => {
    const backgroundImages = updateBackgroundImages();
    setBackgroundImages(backgroundImages);
  }, [currentIndex, slides]);

  const [backgroundImages, setBackgroundImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const slideStyles = {
    width: "100%",
    height: "109px",
    borderRadius: "0",
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: "0.2s"
  };
     
  const openLightbox = (index) => {
    setSelectedImage(index);
  };
  
  const closeLightbox = () => {
    setSelectedImage(null);
  };
  return (



    <div className="theme18-gallery-wrapper">
      <div className="theme18-gallery-inner-wrapper">
      <div onClick={goToPrevious} style={{borderRadius:'50%',background: colordata?.dynamicCtaColor || '',color: colordata?.dynamicCtaTextColor || "",borderColor: colordata?.dynamicCtaColor|| '',  }} >   <i class="fa fa-angle-left" style={{padding:'8px 12px',fontSize:'18px'}}></i>  </div>        
          <div onClick={goToNext} style={{borderRadius:'50%',background: colordata?.dynamicCtaColor || '',color: colordata?.dynamicCtaTextColor || "",borderColor: colordata?.dynamicCtaColor|| '',  }} >  <i class="fa fa-angle-right" style={{padding:'8px 12px',fontSize:'18px',}}></i>  </div>
      </div>
      {slides.length > 0 && (
        <div className="twoimages">
          {backgroundImages.slice().map((bgImage, index) => (
            <div key={index} style={{ ...slideStyles, cursor:"pointer", backgroundImage: bgImage }}   onClick={() => openLightbox((currentIndex + index) % slides.length)}></div>
          ))}
        </div>
      )}
       {selectedImage !== null && (
                        <Lightbox
                          src={`${environmentVariables?.s3UrlBackendUrl}gallery/${slides[selectedImage]?.image}`}
                          slides={slides}
                          currentIndex={selectedImage}
                          onClose={closeLightbox}
                          onPrevious={(index) => setSelectedImage(index)}
                          onNext={(index) => setSelectedImage(index)}
                        />
                      )}
    </div>
  );
};

export default GalleryCarousel;