import React from 'react';
import { useTranslation } from "react-i18next";
import CarImage from "./../../../../Images/DefaultBMWCabrio.png";
import CarImage1 from "./../../../../Images/DefaultToyota.png";
import CarImage2 from "./../../../../Images/DefaultHondacv.png";
import CarImage3 from "./../../../../Images/HondaCrv.png";
import cabFrame from "../../../../Images/cabFrame.png";
import { environmentVariables } from "../../../../config/env.config";
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './Theme22.css';

const AvilableCabTab = (props) => {
  const { t } = useTranslation();
  const { colorData } = props;
  const dispatch = useDispatch();
  return (
    // <div className='px-2'>
    //     <div className='capitalize font-Poppins text-xl text-white font-medium'>
    //     Available Cabs
    //     </div>

    //     <div className='bg-cabcard rounded flex my-4 ' style={{justifyContent:"space-between"}}>
    //         <div className='p-2'>
    //         <div className='p-2 font-DM text-white font-medium text-lg'> BMW Cabrio </div>
    //          <div className='py-1 px-2'>   
    //             <div className='font-DM text-D0D0D0 font-medium'> Automatic </div>
    //             <div className='font-DM text-D0D0D0 font-medium'> 3 seaters </div>
    //             <div className='font-DM text-D0D0D0 font-medium'>Octane</div>
    //             </div>
    //         </div>
    //         <div style={{marginTop:"-10px"}}>
    //             <img loading="lazy" style={{width:"240px"}} src={CarImage}/>
    //         </div>

    //     </div>


    //     <div className='bg-cabcard rounded flex my-4' style={{justifyContent:"space-between"}}>
    //         <div className='p-2'>
    //         <div className='p-2 font-DM text-white font-medium text-lg'> Toyota Camry </div>
    //          <div className='py-1 px-2'>   
    //             <div className='font-DM text-D0D0D0 font-medium'> Manual </div>
    //             <div className='font-DM text-D0D0D0 font-medium'> 4 seaters   </div>
    //             <div className='font-DM text-D0D0D0 font-medium'>Octane</div>
    //             </div>
    //         </div>
    //         <div style={{marginTop:"-10px"}}>
    //             <img loading="lazy" style={{width:"240px"}} src={CarImage1}/>
    //         </div>
    //     </div>

    //     <div className='bg-cabcard rounded flex my-4' style={{justifyContent:"space-between"}}>
    //         <div className='p-2'>
    //         <div className='p-2 font-DM text-white font-medium text-lg'>Honda CR-V</div>
    //          <div className='py-1 px-2'>   
    //             <div className='font-DM text-D0D0D0 font-medium'> Manual </div>
    //             <div className='font-DM text-D0D0D0 font-medium'> 2 seaters </div>
    //             <div className='font-DM text-D0D0D0 font-medium'>Octane</div>
    //             </div>
    //         </div>
    //         <div style={{marginTop:"-10px"}}>
    //             <img loading="lazy" style={{width:"240px"}} src={CarImage2}/>
    //         </div>

    //     </div>

    //     <div className='bg-cabcard rounded flex my-4' style={{justifyContent:"space-between"}}>
    //         <div className='p-2'>
    //         <div className='p-2 font-DM text-white font-medium text-lg'> Toyota Camry </div>
    //          <div className='py-1 px-2'>   
    //             <div className='font-DM text-D0D0D0 font-medium'> Manual </div>
    //             <div className='font-DM text-D0D0D0 font-medium'> 4 seaters </div>
    //             <div className='font-DM text-D0D0D0 font-medium'>Octane</div>
    //             </div>
    //         </div>
    //         <div style={{marginTop:"-10px"}}>
    //             <img loading="lazy" style={{width:"240px"}} src={CarImage1}/>
    //         </div>

    //     </div>

    //     <div className='bg-cabcard rounded flex my-4' style={{justifyContent:"space-between"}}>
    //         <div className='p-2'>
    //         <div className='p-2 font-DM text-white font-medium text-lg'> BMW Cabrio </div>
    //          <div className='py-1 px-2'>   
    //             <div className='font-DM text-D0D0D0 font-medium'> Automatic </div>
    //             <div className='font-DM text-D0D0D0 font-medium'> 3 seaters </div>
    //             <div className='font-DM text-D0D0D0 font-medium'>Octane</div>
    //             </div>
    //         </div>
    //         <div style={{marginTop:"-10px"}}>
    //             <img loading="lazy" style={{width:"240px"}} src={CarImage3}/>
    //         </div>

    //     </div>
    // </div>
        <div className='px-2'>
          <div className='capitalize font-Poppins text-xl font-medium' style={{color: colorData?.dynamicTextHeadingColor || "#fff"}}>
            {console.log("colorData===>>>", props?.colorData)}
            {props?.colorData?.cabProductHeading || props?.colorData?.rs?.headingsObj?.Cab_products_Heading || t("Available_cabs")}
          </div>
          {props?.cabs?.map((cab, index) => (
              
              <Link to="/cabdetails"  
              // state={{ cab }}
              state={{ cab, description: props.desc }} 
              onClick={()=>dispatch({ type : "SET_NAVIGATION_KEY", payload: "Cab_List_Page"})}>
            <div key={index} className='bg-cabcard rounded flex my-4 custom_cab_card' style={{ justifyContent: "space-between", overflow:"hidden", overflow:"hidden", backgroundColor: colorData?.dynamicBackgroundColor || "#1D1D1D" }}>
              <div className='p-2 name-seater'>
              {/* {console.log(cab,"cabcabcab")} */}
             
          
                <div className='px-2 font-DM font-medium text-lg cab_name' style={{paddingBottom:"0px !important", fontSize:"18px", color: colorData?.dynamicTextDescriptionColor || "#fff"}}>
                {cab?.product_name?.length > 24 ? cab.product_name.slice(0, 21) + "..." : cab?.product_name}

                </div>
              {cab?.self_driver === 1 &&   <div className='cab_price' style={{color: colorData?.dynamicTextDescriptionColor || "", padding:"0 5px"}}>₹{cab?.self_driver_price} / day</div> }
                <div className='py-1 px-2' style={{display:"flex", gap:"10px",
                  //  whiteSpace: "nowrap"
                   }}>
                  <div className='font-DM text-D0D0D0 font-medium cab_seater' style={{color: colorData?.dynamicTextDescriptionColor || ""}}>
                  {/* {cab?.product_seator} seater | {cab?.fuel_type} | {cab?.gear_type}  */}
                  {/* {cab?.product_seator} seater | {cab?.fuel_type?.toLowerCase().includes("el") ? "Electric" : cab?.fuel_type} | {cab?.gear_type} */}
                  {cab?.product_seator} seater | {cab?.gear_type} | {cab?.fuel_type?.replace(/\b(el)\b/i, "Electric")}
                   </div>
                  {/* <div className='font-DM text-D0D0D0 font-medium'>{cab?.product_description}</div> */}
                </div>
              </div>
              <div style={{ position:'relative'}}>
                 {cab?.self_driver === 1 &&   <div className='self_drive'>Self-Drive</div>}
                <img loading="lazy" style={{ width: "240px" }} src={cab?.image ? `${environmentVariables?.s3UrlBackendUrl}${cab?.image}` : cabFrame } alt={cab?.product_name} />
              </div>
            </div>
              </Link>
            )
          )}
       
        </div>
  )
}

export default AvilableCabTab