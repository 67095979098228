import React, { useState, useEffect, useRef, useContext } from "react";
import { environmentVariables } from "../../../config/env.config";
import "./../Updatedcss/BusinessChangeBlockUpdatedStyle.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { businessObjContext } from "../../../context/businessObjContext";
import { useTranslation } from "react-i18next";

const FormikErrorStyle = styled.div`
font-size:11px;
color: #ff3333;
`;


const alternateUrlForm = ({
  formCount,
  testimonialData,
  setIsAddAlternateUrlDisable,
  setTestimonialData,
  index,
  removeForm,
}) => {
  const baseUrl = environmentVariables?.apiUrl;
  const [image, setImage] = useState(null);
  const [addBtnDisable, setAddBtnDisable] = useState(false);
  const { businessObjData } = useContext(businessObjContext);
  const { t } = useTranslation();
  const [data, setData] = useState({
    formCount: formCount,
    image: null,
    name: "",
    description: "",
    imageView: null,
    isImageUpload: true,
  });

  // const validationSchema = Yup.object({
  //   name: Yup.string()
  //   .matches(/^[a-zA-Z0-9 ,.?\/()&@$'_\-\\"]*$/, "Only ‘,.?-_/@$&’ characters are allowed")
  //     .required("Name is required")
  //     .min(1, "Minimum 2 characters")
  //     .max(150, "Maximum 150 Characters"),
  //   description: Yup.string()
  //   .required("Link is required"),
  // });

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[\p{L}\p{N}\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF\u064B-\u065F ,.?\/()&@$'_\-\\"]*$/u, "Only letters, numbers, and ‘,.?-_/@$&’ characters are allowed")
      .required(t("Name_required"))
      .min(1, t("Minimum_1_character_required"))
      .max(150, t("Maximum_150_characters_allowed")),
  
    description: Yup.string()
      .required(t("Link_is_required")),
  });

  const formik = useFormik({
    initialValues: {
      name: data.name || "",
      description: data.description || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
    },
  });


  const handleAddService = (values) => {
    setAddBtnDisable(true);
    setIsAddAlternateUrlDisable(false);
    const newTestimonialData = testimonialData.map((val, index) => {
      if (val.formCount == formCount) {
        val.name = values?.name;
        val.description = values?.description;
        val.image = image;
        val.imageView = data?.imageView;
        val.isImageUpload = data?.isImageUpload;
        return val;
      }
      return val;
    });

    setTestimonialData(newTestimonialData);
  };


  useEffect(() => {
    let currentForm = testimonialData[index];

    setData({
      name: currentForm?.name,
      description: currentForm?.description,
      image: currentForm?.image,
      imageView: currentForm?.imageView,
      formCount: index,
      isImageUpload: currentForm?.isImageUpload,
    });

  }, [testimonialData]);

  return (
    <div
      key={`form-${formCount}`}
      className="col-12"
      id="AddNewStyleFormfieldpad"
    >
      <div className="serviceboxedittesti MobileviewServices">
        <i
          className="fa-solid fa-circle-xmark crossedit"
          onClick={() => removeForm(index)}
        >
          {" "}
        </i>
        <div className="AddDetailsTextimonialphnview">
          <div className="" style={{ fontSize: "16px", fontWeight: "500" }}>
            {data["isImageUpload"] && !addBtnDisable ? (
              <div>
                <input
                  {...formik.getFieldProps("name")}
                  className="form-control"
                  type="text"
                  placeholder={`${t("Name")}`}
                />
                {formik.touched.name && formik.errors.name ? (
                  <FormikErrorStyle>{formik.errors.name}</FormikErrorStyle>
                ) : null}
              </div>
            ) : (
              <div>{data["name"] == "undefined" ? "" : data["name"]}</div>
            )}
          </div>
          <div
            className=""
            style={{ fontSize: "14px", fontWeight: "400", paddingTop: "5px" }}
          >

            {data["isImageUpload"] && !addBtnDisable ? (
              <div>
                <textarea
                  {...formik.getFieldProps("description")}
                  className="form-control"
                  placeholder={`${t("Link")}`}
                  style={{height: "70px"}}
                />
                {formik.touched.description && formik.errors.description ? (
                  <FormikErrorStyle>{formik.errors.description}</FormikErrorStyle>
                ) : null}
              </div>
            ) : (
              <div>{data["description"] == "undefined" ? "" : data["description"]}</div>
            )}
          </div>
          {data["isImageUpload"] && !addBtnDisable ? (
            <button
              type="button"
 
              disabled={!formik.isValid || !formik.dirty || !formik.values.name || !formik.values.description
              }
              onClick={() => {
                formik.handleSubmit();
                handleAddService({
                  name: formik.values.name,
                  description: formik.values.description,
                  image: image,
                  imageView: data.imageView,
                  isImageUpload: data.isImageUpload,
                });
              }}
              className="btn btn-cu editbutton editButtonTextimonial"
            >
              {t("Add")}
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default alternateUrlForm;
