import React, { useContext, useState, useRef } from "react";
// import "./TemplatComman.css";
import { userContext } from "../../../../context/userContext";
import { environmentVariables } from "../../../../config/env.config"
import { useEffect } from "react";
// import "./Template11.css";
import "./Theme51.css";
import axios from "axios";
import { useTranslation } from "react-i18next";
// import bannerImage from "../../../../Images/banner1.jpg";
import arrow from "../../../../Images/theme-17-arrow.png";
import quoteup from "../../../../Images/theme17-up-quote.png";
import quotedown from "../../../../Images/theme17-down-quote.png";
// import "./Template11.css";

import theme6strip from "../../../../Images/theme6strip.png";
import TestimonialCarousel from "./TestimonialCarousel";
import GalleryCarousel from "./GalleryCarousel";
import { getServicesArray } from "../../../../utils/utility";
import { StarRating } from "../../../Components/StarRating/StarRating";
// import "./Theme1.css";

// import "./Template10.css";
const Theme51 = (props) => {
  const { t } = useTranslation();
  const [workingHour, setWorkingHour]= useState(false)
  const { userData } = useContext(userContext);
  const [selectedDate, setSelectedDate] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [appointmentData, setAppointmentData] = useState([]);
  const touchStartX = useRef(0);
  const calendarInputRef = useRef(null);
  const handleChangeAppointmentDate = (dateVal) => {
    setSelectedDate(dateVal);

    const dateObject = new Date(dateVal);

    const dayIndex = dateObject.getDay();

    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    if (props?.appointmentArray && props?.appointmentArray?.length != 0) {
      setAppointmentData(
        props?.appointmentArray[`${daysOfWeek[dayIndex].toLowerCase()}`]
      );
    } else {
      // const parsedContent = JSON.parse(
      //   props?.businessObj?.appointmentObj?.content
      // );
      // setAppointmentData(
      //   parsedContent[`${daysOfWeek[dayIndex].toLowerCase()}`]
      // );
      try {
        const parsedContent = JSON.parse(
          JSON.parse(props?.businessObj?.appointmentObj?.content)
        );
        setAppointmentData(
          parsedContent[`${daysOfWeek[dayIndex].toLowerCase()}`]
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const openCalendar = () => {
    if (calendarInputRef.current) {
      calendarInputRef.current.showPicker();
    }
  };

  const handleTouchMove = (e) => {
    const touchEndX = e.touches[0].clientX;
    const deltaX = touchEndX - touchStartX.current;

    if (Math.abs(deltaX) > 50) {
      // You can adjust this threshold to control how much swipe is needed to trigger the slide
      setCurrentIndex((prev) =>
        deltaX > 0
          ? Math.max(prev - 1, 0)
          : Math.min(prev + 1, testimonials.length - 1)
      );
    }
  };

  const handleTouchEnd = () => {
    touchStartX.current = 0;
  };
  // console.log(props.orderOfBlocks, "appointment inside iframe");

  useEffect(() => {
    getBlockOrder();
  }, [props?.businessObj, props.businessId, props.orderOfBlocks]);
  const getBrandNameForSocial = (brand) => {
    // console.log(brand);
    if (brand == "gmail") {
      return "google";
    } else return `${brand}`;
  };
  const getBlockOrder = async () => {
    // const handleGetBlock = async () => {
    if (props.orderOfBlocks) {
      let orders = {};
      props.orderOfBlocks.map((val) => {
        if (val.content == "Description") {
          orders["description"] = val?.position;
        } else if (val.content == "Contact Info") {
          orders["contact_info"] = val?.position;
        } else if (val.content == "Appointment") {
          orders["appointment"] = val?.position;
        } else if (val.content == "Service") {
          orders["service"] = val?.position;
        } else if (val.content == "Gallery") {
          orders["gallery"] = val.position;
        } else if (val.content == "Contact Form") {
          orders["contact_form"] = val.position;
        } else if (val.content == "Testimonials") {
          orders["testimonials"] = val.position;
        } else if (val.content == "Social") {
          orders["social"] = val.position;
        } else if (val.content == "Business Hour") {
          orders["business_hour"] = val.position;
        } else if (val.content == "Google Review") {
          orders["google_review"] = val.position;
        }
      });
      props.setOrder(orders);
    } else {
      try {
        let id = props?.businessObj?.id || props.businessId;
        if (props?.businessObj?.id) {
          let { data: get } = await axios.get(
            `${environmentVariables?.apiUrl}api/v1/business/FetchBlock?business_id=${id}`,
            {
              headers: {
                _token: userData?.token,
              },
              // withCredentials: true,
            }
          );
          // console.log(get?.data?.order, "order data");
          props.setOrder(get?.data?.order);
        }

        // console.log(get.data.order,"Getetgwef")
      } catch (err) {
        console.log(err, "error in template");
      }
    }
    // };
  };
  const getJSXElement = (elemName) => {
    // console.log(props?.appointmentArray, "elemNameelemName");
    if (elemName == "appointment") {
      return (
        props?.onState?.appointment && (
          // <article class="contact sec colorback">
          //   <h3 class="sectitle">Appoinment</h3>
          //   <select class="form-select" aria-label="Default select example">
          //     <option selected>Select time</option>
          //     {props?.appointmentArray?.map((val) => (
          //       <>
          //         <option value="1">
          //           {props.changeTimeFormat(val?.startDate)} -{" "}
          //           {props.changeTimeFormat(val?.endDate)}{" "}
          //         </option>
          //       </>
          //     ))}
          //   </select>
          // </article>
          <article className="makeappitmneets sec colorback">
            <h3 class="sectitle colorheading" style={{color: props?.dynamicTextDescriptionColor || ""}}>{props?.appointmentsHeading || t("Make_appointment")}</h3>
            <div className="contact-form">
              <div className="row">
                <div class="col-6 mb-3" style={{ position: "relative" }}>
                  <label className="labelclass" style={{color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}90` : ""}}> {t("Select_date")} </label>
                  <div style={{ position: "relative" }}>
                    <input
                      ref={calendarInputRef}
                      type="date"
                      class="form-control clear_string colorctaborder"
                      placeholder={t("Date")}
                      name="currentDate"
                      value={selectedDate}
                      onChange={(e) =>
                        handleChangeAppointmentDate(e.target.value)
                      }
                      style={{border: props?.dynamicTextDescriptionColor ? `1px solid ${props?.dynamicTextDescriptionColor}` : "", color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}` : ""}}
                    />
                    <div className="calanderIcon" onClick={openCalendar}>
                      <img src={environmentVariables?.calendarIcondaysnew} alt="Calendar Icon" />
                    </div>
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <label className="labelclass" style={{color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}90` : ""}}> {t("Select_time")} </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    // onChange={(e) => setSelectedTime(e.target.value)}
                    style={{border: props?.dynamicTextDescriptionColor ? `1px solid ${props?.dynamicTextDescriptionColor}` : "", color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}` : ""}}
                  >
                    <option value="Select time">{t("Select_time")}</option>
                    {appointmentData?.map((val) => (
                      <option
                        key={val?.startDate}
                        value={`${val?.startDate} - ${val?.endDate}`}
                      >
                        {`${val?.startDate} - ${val?.endDate}`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div class="col-12 mb-3">
                <button className="btn btnmakeappitmnets colorcta" style={{backgroundColor: props?.dynamicCtaColor, color: props?.dynamicCtaTextColor}}>
                  {t("Create_appointment")} <img loading="lazy" src={arrow} alt="arrow" />
                </button>
              </div>
            </div>
          </article>
        )
      );
    } else if (elemName == "business_hour") {
      return (
        props?.onState?.businessHours && (
        <article className="businessHours sec colorback">
          <h3 class="sectitle" style={{color: props?.dynamicTextDescriptionColor || "" }}>{props?.businessHourHeading || t("Working_hours")}   <i onClick={()=>{setWorkingHour(!workingHour)}} class="fa-solid fa-chevron-right rightcorner"></i>
          </h3>
          {/* <p className="colordescription">
                    Mon to Sat <span className="bgText">9AM to 9PM</span>
                    <br />
                    Sunday Closed!
                  </p> */}

                  <ul className="timelist" style={{ display: workingHour  ? 'none' : 'block' }}>
           { console.log(workingHour)}
            {props?.businessHourObject?.isMonday &&
              props?.businessHourObject?.modayStartDate &&
              props?.businessHourObject?.modayEndDate && (
                <li>
                  <div className="day colordescription" style={{color: props?.dynamicTextDescriptionColor || "" }}>{t("Monday")} :</div>
                  <div className="time" style={{color: props?.dynamicTextDescriptionColor || "" }}>
                    {" "}
                    <span className="colordescription">
                      {props.changeTimeFormat(
                        props?.businessHourObject?.modayStartDate
                      )}
                    </span>{" "}
                    -{" "}
                    <span className="colordescription">
                      {props.changeTimeFormat(
                        props?.businessHourObject?.modayEndDate
                      )}
                    </span>
                  </div>
                </li>
              )}
            {props?.businessHourObject?.isTuesday &&
              props?.businessHourObject?.tuesdayStartDate &&
              props?.businessHourObject?.tuesdayEndDate && (
                <li>
                  <div className="day colordescription" style={{color: props?.dynamicTextDescriptionColor || "" }}>{t("Tuesday")} :</div>
                  <div className="time" style={{color: props?.dynamicTextDescriptionColor || "" }}>
                    {" "}
                    <span className="colordescription">
                      {props.changeTimeFormat(
                        props?.businessHourObject?.tuesdayStartDate
                      )}
                    </span>{" "}
                    -{" "}
                    <span className="colordescription">
                      {props.changeTimeFormat(
                        props?.businessHourObject?.tuesdayEndDate
                      )}
                    </span>
                  </div>
                </li>
              )}
            {props?.businessHourObject?.isWednesDay &&
              props?.businessHourObject?.wednesdayStartDate &&
              props?.businessHourObject?.wednesdayEndDate && (
                <li>
                  <div className="day colordescription" style={{color: props?.dynamicTextDescriptionColor || "" }}>{t("Wednesday")} :</div>
                  <div className="time" style={{color: props?.dynamicTextDescriptionColor || "" }}>
                    {" "}
                    <span className="colordescription">
                      {props.changeTimeFormat(
                        props?.businessHourObject?.wednesdayStartDate
                      )}
                    </span>{" "}
                    -{" "}
                    <span className="colordescription">
                      {props.changeTimeFormat(
                        props?.businessHourObject?.wednesdayEndDate
                      )}
                    </span>
                  </div>
                </li>
              )}
            {props?.businessHourObject?.isthrusdayDay &&
              props?.businessHourObject?.thrusdayStartDate &&
              props?.businessHourObject?.thrusdayEndDate && (
                <li>
                  <div className="day colordescription" style={{color: props?.dynamicTextDescriptionColor || "" }}>{t("Thursday")} :</div>
                  <div className="time" style={{color: props?.dynamicTextDescriptionColor || "" }}>
                    {" "}
                    <span className="colordescription">
                      {props.changeTimeFormat(
                        props?.businessHourObject?.thrusdayStartDate
                      )}
                    </span>{" "}
                    -{" "}
                    <span className="colordescription">
                      {props.changeTimeFormat(
                        props?.businessHourObject?.thrusdayEndDate
                      )}
                    </span>
                  </div>
                </li>
              )}
            {props?.businessHourObject?.isfridayDay &&
              props?.businessHourObject?.fridayStartDate &&
              props?.businessHourObject?.fridayEndDate && (
                <li>
                  <div className="day colordescription" style={{color: props?.dynamicTextDescriptionColor || "" }}>{t("Friday")} :</div>
                  <div className="time" style={{color: props?.dynamicTextDescriptionColor || "" }}>
                    {" "}
                    <span className="colordescription">
                      {props.changeTimeFormat(
                        props?.businessHourObject?.fridayStartDate
                      )}
                    </span>{" "}
                    -{" "}
                    <span className="colordescription">
                      {props.changeTimeFormat(
                        props?.businessHourObject?.fridayEndDate
                      )}
                    </span>
                  </div>
                </li>
              )}
            {props?.businessHourObject?.issaturdayDay &&
              props?.businessHourObject?.SaturdayStartDate &&
              props?.businessHourObject?.SaturdayEndDate && (
                <li>
                  <div className="day colordescription" style={{color: props?.dynamicTextDescriptionColor || "" }}>{t("Saturday")} :</div>
                  <div className="time" style={{color: props?.dynamicTextDescriptionColor || "" }}>
                    {" "}
                    <span className="colordescription">
                      {props.changeTimeFormat(
                        props?.businessHourObject?.SaturdayStartDate
                      )}
                    </span>{" "}
                    -{" "}
                    <span className="colordescription">
                      {props.changeTimeFormat(
                        props?.businessHourObject?.SaturdayEndDate
                      )}
                    </span>
                  </div>
                </li>
              )}
            {props?.businessHourObject?.issundayDay &&
              props?.businessHourObject?.SundayStartDate &&
              props?.businessHourObject?.SundayEndDate && (
                <li>
                  <div className="day colordescription" style={{color: props?.dynamicTextDescriptionColor || "" }}>{t("Sunday")} :</div>
                  <div className="time" style={{color: props?.dynamicTextDescriptionColor || "" }}>
                    {" "}
                    <span className="colordescription">
                      {props.changeTimeFormat(
                        props?.businessHourObject?.SundayStartDate
                      )}
                    </span>{" "}
                    -{" "}
                    <span className="colordescription">
                      {props.changeTimeFormat(
                        props?.businessHourObject?.SundayEndDate
                      )}
                    </span>
                  </div>
                </li>
              )}
          </ul>
        </article>
      ));
    } else if (elemName == "contact_info") {
      return (
        props?.onState?.contactInfo && (
          <article class="contact sec colorback">
            <h3 class="sectitle" style={{color: props?.dynamicTextDescriptionColor || "" }}>{props?.contactHeading || t("Contact_info")} </h3>
           
            <ul class="conlink">
              <li>
                <a>
                  <i class="fa-solid fa-phone colordescription"  style={{color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}90` : "" }}></i>
                  <span className="colordescription" style={{color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}90` : "" }}>{props?.contactInfo?.contactInfoPhone}</span>
              {props?.contactInfo?.optionalContactInfoPhone?.split("-")?.[1]?.length > 0 &&    <span className="colordescription" style={{color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}90` : "" }}> , { props?.contactInfo?.optionalContactInfoPhone} </span>} 
                </a>
              </li>

              <li>
                <a>
                  <i class="fa-solid fa-envelope colordescription" style={{color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}90` : "" }}></i>
                  <span className="colordescription" style={{color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}90` : "" }}>{props?.contactInfo?.contactInfoEmail}</span>
                </a>
              </li>
              {/* <li>
                <a href="">
                <i class="fa-solid fa-envelope colordescription"></i>
                  <span className="colordescription">
                   
                    {props?.contactInfo?.contactInfoWebUrl ||
                      `www.Shreyassinghal.com`}
                  </span>
                </a>
              </li> */}
              {props?.contactInfo?.contactInfoWebUrl &&
              <li>
                <a>
                <i class="fa-solid fa-link colordescription" style={{color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}90` : "" }}></i>
                  <span style={{marginLeft:"5px", wordBreak:"break-all",color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}90` : "" }}>{props?.contactInfo?.contactInfoWebUrl}</span>
                </a>
              </li>
              }
             <li style={{display: "flex"}}>
              <i className="fa-solid fa-location-dot" style={{color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}90` : "" }}></i>
              <div>
                <a href="#">
                  <span className="colordescription" style={{color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}90` : "" }}>{props?.contactInfo?.contactInfoAddress}</span>
                </a>
                {props?.contactInfo?.contactInfoOptAddress && (
                  <>
                    <p style={{color: props?.dynamicTextDescriptionColor || "black", fontSize: "14px"}}>{t("Alternative_address")}</p>
                    <a href="#">
                      <span className="colordescription" style={{color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}90` : "" }}>{props?.contactInfo?.contactInfoOptAddress}</span>
                    </a>
                  </>
                )}
              </div>
            </li>

              
            </ul>
          </article>
        )
      );
    } else if (elemName == "custom_html") {
      return <></>;
    } else if (elemName == "google_review") {
      return (
        props?.onState?.googlereview == true && (
          <article class="contact sec colorback">
            <h3 class="sectitle colorheading" style={{color: props?.dynamicTextDescriptionColor || "" }}>{props?.reviewHeading || t("Google_review")}</h3>
            <ul class="conlink">
              <li>
                <p
                  style={{
                    wordBreak: "break-all",
                    // maxHeight: "77px",
                    // overflow: "hidden",
                    fontSize: "16px",
                    // color: "#fff",
                    color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}90` : ""
                  }}
                >
                  {props?.googlereview !== undefined && props?.googlereview}
                  
                </p>
              </li>
            </ul>
          </article>
        )
      );
    } else if (elemName == "contact_form") {
      return (
        props?.onState?.contactForm && (
          <article className="conatctform sec colormainback">
            <h3 class="sectitle"  style={{color: props?.dynamicTextDescriptionColor || "" }}>{props?.contactformHeading || t("Contact_form")}</h3>
            <div class="contact-form">
              <form>
                <div class="col-12 mb-3">
                  {/* <lable class="contactformLableName">Name*</lable> */}
                  <input
                    type="text"
                    class="form-control clear_string colorctaborder"
                    placeholder={`${t("Name")}`}
                    name="last_name"
                    // value=""
                    required=""
                    style={{border: props?.dynamicTextDescriptionColor ? `1px solid ${props?.dynamicTextDescriptionColor}` : "", color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}` : ""}}
                  />
                </div>
                <div class="col-12 mb-3">
                  {/* <lable class="contactformLableName">Email*</lable> */}
                  <input
                    type="text"
                    class="form-control colorctaborder"
                    placeholder={`${t("Email")}`}
                    name="email"
                    // value=""
                    required=""
                    style={{border: props?.dynamicTextDescriptionColor ? `1px solid ${props?.dynamicTextDescriptionColor}` : "", color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}` : ""}}
                  />
                </div>
                <div class="col-12 mb-3">
                  {/* <lable class="contactformLableName">Phone*</lable> */}
                  <input
                    type="text"
                    class="form-control clear_string colorctaborder"
                    maxlength="10"
                    minlength="10"
                    placeholder={`${t("Mobile_number")}`}
                    name="mobile_number"
                    // value=""
                    required=""
                    style={{border: props?.dynamicTextDescriptionColor ? `1px solid ${props?.dynamicTextDescriptionColor}` : "", color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}` : ""}}
                  />
                </div>
               

                <div class="col-12 mb-3">
                  {/* <lable class="contactformLableName">Case Description</lable> */}
                  <textarea
                    class="form-control clear_string colorctaborder"
                    rows="3"
                    placeholder={`${t("Your_message")}`}
                    required=""
                    name="message"
                    style={{border: props?.dynamicTextDescriptionColor ? `1px solid ${props?.dynamicTextDescriptionColor}` : `1px solid #6c6c6c`, color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}` : "", background: props?.dynamicBackgroundColor ? `${props?.dynamicBackgroundColor}` : "transparent"}}
                  ></textarea>
                </div>

                <div class="col-12 mb-3">
                  <button type="button" class="btn btn-submitform colordescription colorcta" style={{background: props?.dynamicCtaColor, color: props?.dynamicCtaTextColor || "" }}>
                  {t("Submit")}
                    {/* <img loading="lazy" src={arrow} alt="arrow" /> */}
                  </button>
                </div>
              </form>
            </div>
          </article>
        )
      );
    } else if (elemName == "description") {
      return (
        <article className="abouttemplate sec">
          <h3 class="sectitle" style={{color: props?.dynamicTextDescriptionColor || "" }}>{props?.descriptionHeading || t("About_me")}</h3>
          <p className="para" style={{color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}90` : "" }} 
           dangerouslySetInnerHTML={{
            __html: (props.description ||
              ` Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.`).replace(/(?:\r\n|\r|\n)/g, '<br>'),
          }}>
            
          </p>
        </article>
      );
    } else if (elemName == "gallery") {
      return (
        props?.onState?.gallery && 
        <article className = "gallery sec sec-common">
          <h3 class="sectitle" style={{color: props?.dynamicTextDescriptionColor || "" }}>{props?.galleryHeading || t("Gallery")} </h3>
          {props?.galleryData && (
            <>
              <GalleryCarousel slides={props?.galleryData}  colordata={props}/>
            </>
          )}
          {/* {props.existingGalleryImages ? (
            <GalleryCarousel slides={props?.existingGalleryImages} />
          ) : (
            props?.gallaryObject?.gallaryArray && (
              <GalleryCarousel slides={props?.gallaryObject?.gallaryArray} />
            )
          )} */}
          {/* {props.galleryView && (
            <GalleryCarousel slides={props?.galleryView} staticImage={true} />
          )} */}
          {/* {props?.gallaryObject?.gallary_video && (
            <video className="mx-auto d-block img-fluid" controls>
              <source
                src={
                  props?.gallaryObject?.gallary_video &&
                  `${environmentVariables?.apiUrl}uploads/${props?.gallaryObject?.gallary_video}`
                }
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          )} */}
        </article>
      );
    } else if (elemName == "more") {
      return <></>;
    } else if (elemName == "service") {
      return (
        props?.onState?.services && (
          <article className="servicesName sec colormainback">
            <h3 class="sectitle colorheading" style={{color: props?.dynamicTextDescriptionColor || "" }}>{props?.serviceHeading || t("Services")} </h3>
            <div className="mainservicebox">
              {/* <ul className="servesbox">
                {props?.servicesArray?.map((val) => (
                  <li> {val?.name}</li>
                ))}
              </ul> */}
              {props?.servicesArray && (
                <ul className="servesbox">
                  {getServicesArray(props?.servicesArray).map(
                    (val) =>
                      val?.name != null && <li className="colordescription" key={val?.name}style={{background: props?.dynamicMainBackgroundColor || "", border: props?.dynamicTextDescriptionColor ? `1px solid ${props?.dynamicTextDescriptionColor}` : "",color: props?.dynamicTextHeadingColor||''}}> {val?.name}</li>
                  )}
                </ul>
              )}
            </div>
          </article>
        )
      );
    } else if (elemName == "social") {
      return (
        props?.onState?.socials && (
          <article className="social-links-sec" style={{margin : '23px 18px'}}>
            <h3 class="sectitle colorheading" style={{color: props?.dynamicTextDescriptionColor || "" }}>{props?.socialHeading || t("Social_links")}</h3>
            <ul class="socil-link socil-link-inner">
              {props?.socialMediaData?.map((val) => (
                <li style={{background: props?.dynamicMainBackgroundColor || "" }}>
                  <a>
                    {/* <i
                      class={`fa-brands fa-${getBrandNameForSocial(
                        val?.nameOfSocial.toLowerCase()
                      )}`}
                    ></i> */}
                    <i
                      className={`fa-${
                        val?.nameOfSocial.toLowerCase() === "star"
                          ? "solid"
                          : "brands"
                      } fa-${getBrandNameForSocial(
                        val?.nameOfSocial.toLowerCase()
                      )}`}
                      style={{color: props?.dynamicTextHeadingColor || "" }}></i>
                  </a>
                </li>
              ))}
            </ul>
          </article>
        )
      );
    } else if (elemName == "testimonials") {
      return (
        props?.onState?.testimonials && (
          <div>
            {/* <article class="testimoniyalbox sec colormainback">
              <img loading="lazy" className="quoteup" src={quoteup} alt="quote" />
              <img loading="lazy" className="quotedown" src={quotedown} alt="quote" />
            
              <h3 class="sectitle testimonial">Testimonials </h3>

              <div>
                <TestimonialCarousel
                  testimonials={props?.testimonialArray}
                  currentElem={currentIndex}
                  handleTouchStart={handleTouchStart}
                  handleTouchMove={handleTouchMove}
                  handleTouchEnd={handleTouchEnd}
                />
              </div>
            </article> */}

            <article class="testimoniyalbox sec colormainback">
                  <div className="testimonial-inner">
                    <h3 class="sectitle testimonial"  style={{color: props?.dynamicTextDescriptionColor || "" }}>{props?.testimonialHeading || t("Testimonial")} </h3>

                    <div>
                <TestimonialCarousel
                  testimonials={props?.testimonialArray}
                  currentElem={currentIndex}
                  handleTouchStart={handleTouchStart}
                  handleTouchMove={handleTouchMove}
                  handleTouchEnd={handleTouchEnd}
                  colordata={props}
                />
                
              </div>
                  </div>
                </article>



            {/* <div className="carousel-container">
              <button
                onClick={() =>
                  currentIndex == 0
                    ? setCurrentIndex(props.testimonialArray.length - 1)
                    : setCurrentIndex((prev) => prev - 1)
                }
                className="carousel-btn colorcta colordescription"
              >
                <i class="fa fa-angle-left"></i>
              </button>
              <button
                onClick={() => {
                  currentIndex == props.testimonialArray.length - 1
                    ? setCurrentIndex(0)
                    : setCurrentIndex((prev) => prev + 1);
                }}
                className="carousel-btn colorcta colordescription"
              >
                <i class="fa fa-angle-right"></i>
              </button>
            </div> */}
          </div>
        )
      );
    }else if (elemName == "alternateUrls") {
          return (
           props?.onState?.alternateurl && (
            <>
            <article className="servicesName sec colormainback">
              <h3 className="sectitle service colorheading"  style={{color: props?.dynamicTextDescriptionColor || "" }}>{props?.alternateUrlsHeading || t("Useful_links")}</h3>
              <div className="mainservicebox">
                {props?.alternateUrlArray && (
                  <ul className="servesbox">
                    {getServicesArray(props?.alternateUrlArray).map((val) => {
                      const url = val?.description?.startsWith("http")
                        ? val?.description
                        : `https://${val?.description}`;
                      return (
                        val?.name &&
                        val?.description && (
                          <li key={val?.name} style={{background: props?.dynamicMainBackgroundColor || "", border: props?.dynamicTextDescriptionColor ? `1px solid ${props?.dynamicTextDescriptionColor}` : ""}}>
                            <a className="colordescription" href={url} target="_blank" style={{color: props?.dynamicTextHeadingColor || "#fff"}} rel="noopener noreferrer">
                              {val?.name}
                            </a>
                          </li>
                        )
                      );
                    })}
                  </ul>
                )}
              </div>
            </article>
            </>
           )
          )
        }
  };

  const getBlocksOrder = () => {
    let BlocksArray = [];
    for (const key in props.order) {
      BlocksArray[props.order[key]] = getJSXElement(key);
    }
    return BlocksArray;
  };

  return (
    <div>
      <div class="main-temaplt" id="temp51">
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ width: "420px",backgroundColor: props?.dynamicMainBackgroundColor || ""   }} className="col-12 wtemplate colormainback">
              <article className="imgsechere sec">
                <img
                  src={
                    props?.bannerImageUrl
                      ? props.getImage(props.bannerImageUrl)
                      : environmentVariables?.s3BannerUrlTheme35
                  }
                  className="mx-auto d-block img-fluid coverimghere "
                />
 <div className="imageOverlay" style={{
  background: props?.dynamicMainBackgroundColor ? `linear-gradient(184.37deg, rgba(244, 244, 244, 0) 34.97%, ${props?.dynamicMainBackgroundColor})` : ""
}}></div>
                
              </article>
<section className="mainsection" style={{background: props?.dynamicBackgroundColor || "" }}>
<div className="chevron">
                  {" "}
                  <i class="fa-solid fa-chevron-up"></i>
                </div>
  
{/* <div className="logoTextContainer"> */}
                  <article className="personalinfo">
                    {/* <img
                      src={
                        props?.logoImageUrl
                          ? props.getImage(props?.logoImageUrl)
                          : logoImage
                      }
                      className="mx-auto d-block img-fluid logo-profile"
                    /> */}
                    <h3 className="username" style={{color: props?.dynamicTextDescriptionColor || "" }}>{props.subTitle || "Name"}</h3>
                    <p className="titleuser" style={{color: props?.dynamicTextDescriptionColor || "" }}>
                      {props.designation || "Designation"}
                    </p>
                    
                    {/* <p className="companeyname">
                  {props.subTitle || "Company Name"}
                </p> */}
                 <p className="reviewrating colordescription"  style={{color: props?.dynamicTextDescriptionColor || "" }}>
                  <StarRating value={props?.rating} totalStars={5} starSize="16px" themeNo={51} />
                <span className="ratingspan" style={{color: props?.dynamicTextDescriptionColor || "" }}> {props.rating }</span>   ({t("Based_on")} {props.review } {t("Reviews")})
                  </p>
                  </article>
                {/* </div> */}

              {getBlocksOrder().map((val) => (val == undefined ? <></> : val))}

</section>
              {/* <div class="fixedd">
                <article class="fixedfooter">
                  <div class="footerleft">
                    <div
                      class="qrcodeshaere"
                      data-bs-toggle="modal"
                      data-bs-target="#appotiments"
                    >
                      <i class="fa-solid fa-qrcode"></i>
                    </div>
                  </div>
                  <div class="addtocinatct">+Add To Contact</div>
                </article>
              </div> */}
              <div
                class="modal fade"
                id="appotiments"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                      {t("Modal_title")}
                      </h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <article class="personalinfo modal-info ">
                        <canvas
                          height="160"
                          width="160"
                          style={{ height: "128px", width: "128px" }}
                        ></canvas>
                        <h3 class="username">Subhan</h3>
                        <p class="titleuser colordescription">Software Developer</p>
                        <p class="companeyname">Xenophobe Tech</p>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        //   ref={createUserRef}
        id="appotimnets"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
               {t("Make_appointment")}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form className="row">
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control clear_string colorctaborder"
                    placeholder={`${t("Full_name")}`}
                    //   value={appointmentName}
                    //   onChange={(e) =>
                    //     setAppointmentName(e.target.value)
                    //   }
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    eror
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control clear_string colorctaborder"
                    placeholder={`${t("Email")}`}
                    //   value={appointmentEmail}
                    //   onChange={(e) =>
                    //     setAppointmentEmail(e.target.value)
                    //   }
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",
                      bottom: "70px",
                    }}
                  >
                    'errormsg'
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control clear_string colorctaborder"
                    placeholderText={t("Mobile_number")}
                    //   value={appointmentContactNumber}
                    //   onChange={(e) =>
                    //     setAppointmentContactNumber(e.target.value)
                    //   }
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    'errormsgni'
                  </div>
                </div>
                <div class="mb-2 mt-4 col-md-12">
                  <button
                    //   onClick={handleSubmits}
                    class="btn btn-primary me-3"
                    type="button"
                  >
                    <i class="bi bi-check-circle-fill ms-2 "></i>
                    {t("Submit")}
                  </button>
                </div>
              </form>
            </div>
            {/* <ToastContainer /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theme51;
