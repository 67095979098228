import React from "react";
import { useState } from "react";
import Template1 from "../../Templates/Template1";

const PrviewTheme = ({ sharedData }) => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <>
      <div className="col-12 col-lg-4">
        <div className="tile-aa">
          <div className="privewmobile">
            <div className="viewlink border-bottom">
              <div className="linkl">
                <p>https://linko.page/s2vhcxfmt7gb {sharedData}</p>
              </div>
              <div className="linkr">
                <ul className="linkandcopy">
                  <li>
                    <i class="fa-solid fa-copy"></i>
                  </li>
                  <li>
                    <i class="fa-solid fa-arrow-up-right-from-square"></i>
                  </li>
                </ul>
              </div>
            </div>

            <div className="viewlink  preview_nav ">
              <div className="linkl">
                <ul className="btntabhere">
                  <li
                    onClick={() => handleTabChange(1)}
                    className={activeTab === 1 ? "active" : ""}
                  >
                    <i class="fa-solid fa-eye"></i>
                  </li>
                  <li
                    onClick={() => handleTabChange(2)}
                    className={activeTab === 2 ? "active" : ""}
                  >
                    <i class="fa-solid fa-qrcode"></i>
                  </li>
                </ul>
              </div>
              <div className="linkr">
                <button className="btn btn-save">Save</button>
              </div>
            </div>

            {activeTab === 1 && (
              <div className="movileview">
                <div class="device-header"></div>
                <img loading="lazy" src="" className="mx-auto d-block img-fluid" />

                <Template1 />
              </div>
            )}

            {activeTab === 2 && (
              <div className="QrCodesView my-5">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/QR_Code_Example.svg/1200px-QR_Code_Example.svg.png"
                  className="mx-auto d-block img-fluid"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PrviewTheme;
