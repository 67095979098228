import NewBusinessDetilsCreate from "./NewBusinessDetilsCreate";
import NewTestimonialsCreate from "./NewTestimonialsCreate";
import NewAlternateUrlCreate from "./NewAlternateUrlCreate";
import NewRestaurantMenuCreate from "./NewRestaurantMenuCreate";
import NewAppointmentsCreate from "./NewAppointmentsCreate";
import NewSocialmediaCreate from "./NewSocialmediaCreate";
import NewGallerycreate from "./NewGallerycreate";
import { SketchPicker } from "react-color";
import { AsyncPaginate } from "react-select-async-paginate";
import NewBusinessHour from "./NewBusinessHour";
import NewRestaurantHour from "./NewRestaurantHour";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import bannerimage from "../../../Images/blackpic.jpg";
import closeimg from "../../../Images/close.png";
import avatar from "../../../Images/blackpic2.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { environmentVariables } from "../../../config/env.config";
import React, { useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { userContext } from "../../../context/userContext";
import ViewThem from "./ViewThem";
import { businessContext } from "../../../context/businessContext";
import { businessObjContext } from "../../../context/businessObjContext";
import CircularLoader from "../../Components/CircularLoader/CircularLoader";
import "./../Updatedcss/BusinessChangeBlockUpdatedStyle.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Modal, Slider, Button } from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import styled from "styled-components";
import "./DetailsStylee.css";
import { currencyContext } from "../../../context/currencyContext.js";
import { getCurrencyData, getcoutryfromlocation, isValidPhoneNumber } from "../../../utils/utility.js";
import NewminimarketProductCreate from "./NewminimarketProductCreate.js";
import TermsConditionsCreate from "./NewTermsConditionsCreate.js";
import NewBusinessDetilsCreatesaloon from "./NewBusinessDetilsCreatesaloon.js";
import NewCabProductCreate from "./NewCabProductCreate.js";
import { debounce } from "lodash";
import Addinfluancerdetailspopup from "./Addinfluancerdetailspopup.js";
// import TooltipImg from "../../../../src/Images/tooltip.svg";
import TooltipImg from "../../../../src/Images/info.png";
import './Additional.css'
import { ThemeColorContext } from "../../../context/ThemeColorContext.js";
import { changeThemeColor, changeThemeColorCTA, changeThemeColorCTAText, changeThemeColorDesc, changeThemeColorMainBackground, changeThemeColorText } from "../../../common/changeThemeColor.js";
import NewBusinessDetilsCreateKeywords from "./NewBusinessDetilsCreateKeywords.js";
import { useTranslation } from "react-i18next";

const ErrorStyle = styled.div`
  color: #ff1717;
  font-size: 12px;
`;

function Details({
  appointmentSlotDuration,
  setAppointmentSlotDuration,
  businessObj,
  mobilePreviewLoading,
  themeColor,
  handleFetchById,
  themeNumber,
  activeTab,
  setActiveTab,
  nextStatusData,
  handleNextStatus,
  refs
}) {
  const { currencyObjData } = useContext(currencyContext);
  const { t } = useTranslation();
  const { userData, setUserData } = useContext(userContext);
  const { businessObjData } = useContext(businessObjContext);
  const { resumeData } = useContext(businessObjContext);
  const { businessData, setBusinessData } = useContext(businessContext);
  const [countryName, setCountryName] = useState("India");
  const [addressPlaceId, setAddressPlaceId] = useState("");
  const [addressLat, setAddressLat] = useState("");
  const [addressLng, setAddressLng] = useState("");
  const [isModalOpeninf, setModalOpeninf] = useState(false);
  // const [headingsObj,setheadingObj]=useState("");
  // const [updatedHeadingsObj, setUpdatedHeadingsObj] = useState({});
  const { dynamicMainBackgroundColor, setDynamicMainBackgroundColor, dynamicBackgroundColor, setDynamicBackgroundColor, dynamicTextDescriptionColor, setDynamicTextDescriptionColor, dynamicTextHeadingColor, setDynamicTextHeadingColor, dynamicCtaColor, setdynamicCtaColor, dynamicCtaTextColor, setdynamicCtaTextColor } = useContext(ThemeColorContext)



  const fileInputRefBanner = useRef(null);
  const fileInputRefLogo = useRef(null);
  const baseUrl = environmentVariables?.apiUrl;

  const [title, setTitle] = useState("");
  const [isDine, setIsDine] = useState(0);
  const [isShowBusinessName, setIsShowBusinessName] = useState(1);
  const [isShowAddress, setIsShowAddress] = useState(1);
  const [designation, setDesignation] = useState("");
  const [googlereview, setGoogleReview] = useState("");
  const [googleaddress, setgoogleAddress] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [description, setDescription] = useState("");
  const [bannerImageUrl, setBannerImageUrl] = useState("");
  const [logoImageUrl, setLogoImageUrl] = useState("");
  const [slug, setSlug] = useState("");
  const [invalidPhoneErr, setInvalidPhoneErr] = useState("");
  const [invalidOptPhoneErr, setInvalidOptPhoneErr] = useState("");
  const [isBannerRequired, setIsBannerRequired] = useState(false);
  //contact info
  const [isAddressDisabled, setIsAddressDisabled] = useState(false);
  const [isEnabledContactInfo, setIsEnabledContactInfo] = useState(true);
  const [isEnabledGoogleReview, setIsEnabledGoogleReview] = useState(false);
  const [isEnabledContactForm, setIsEnabledContactForm] = useState(false);
  const [existingGalleryImages, setExistingGalleryImages] = useState([]);
  //headingsObj
  const [descriptionHeading, setDescriptionHeading] = useState("");
  const [contactHeading, setcontactHeading] = useState("");
  const [galleryHeading, setGalleryHeading] = useState("");
  const [appointmentsHeading, setAppointmentsHeading] = useState("");
  const [businessHourHeading, setBusinessHourHeading] = useState("");
  const [socialHeading, setSocialHeading] = useState("");
  const [alternateUrlsHeading, seAlternateUrlsHeading] = useState("");
  const [reviewHeading,setreviewHeading]=useState("");
  const [contactformHeading,setcontactformHeading]=useState("");
  const [serviceHeading,setserviceHeading]=useState("");
  const [testimonialHeading,settestimonialHeading]=useState("");
  const [cabProductHeading, setCabProductHeading] = useState("");
  const [termConditionHeading, setTermConditionHeading] = useState("");
  const [cuisineHeading, setCuisineHeading] = useState("");
  const [menuHeading, setMenuHeading] = useState("");
  const [orderHeading, setOrderHeading] = useState("");

  // order
  const [isEnabledOrders, setIsEnabledOrders] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [contactInfoPhone, setContactInfoPhone] = useState("");
  const [optionalContactInfoPhone, setOptionalContactInfoPhone] = useState("");
  const [contactInfoEmail, setContactInfoEmail] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isAddSubmit, setIsAddSubmit] = useState(false);
  const [isAddressSubmit, setIsAddressSubmit] = useState(false);
  const [contactInfoWebUrl, setContactInfoWebUrl] = useState("");
  const [contactInfoAddress, setContactInfoAddress] = useState("");
  const [contactInfoOptAddress, setContactInfoOptAddress] = useState("");
  const [galleryView, setGalleryView] = useState([]);
  const [galleryData, setGalleryData] = useState([]);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [businessId, setBusinessId] = useState("");
  const [logoData, setLogoData] = useState("");
  const [bannerData, setBannerData] = useState("");
  const [dataFromInfluencerChild, setDataFromInfluencerChild] = useState("");
  const [onState, setOnState] = useState({
    contactInfo: true,
    services: false,
    testimonials: false,
    alternateurl: false,
    restaurantmenu: false,
    miniproduct: false,
    termsconditions: false,
    socials: false,
    businessHours: false,
    gallery: false,
    appointment: false,
    contactForm: false,
    orders: false,
    googlereview: false,
    cabproduct: false,
    influencerproduct: false,
    hireMe: false,
    metakeyword: false,
  });
  //services array
  const [servicesArray, setServicesArray] = useState([]);
  const [isEnabledServices, setIsEnabledServices] = useState(false);

  // keywords metatag array
  const [keywordArray, setKeywordArray] = useState([]);
  const [isEnabledKeyword, setIsEnabledKeyword] = useState(false);



  //testimonial arry
  const [testimonialArray, setTestimonialArray] = useState([]);
  const [isTestimonial, setIsTestimonial] = useState(false);

  // alternate url
  const [alternateUrlArray, setAlternateUrlArray] = useState([]);
  const [isAlternateUrl, setAlternateUrl] = useState(false);


  const httpProtocol = environmentVariables?.httpProtocol;
  const urlParams = new URLSearchParams(window.location.search);
  const influencerBusinessId = urlParams.get("id");


  // menu array
  const [restaurantArray, setRestaurantArray] = useState([]);
  const [isRestaurant, setIsRestaurant] = useState(false);
  const [minimarketArray, setMiniMarketArray] = useState([]);
  // const [minimarketTempArray, setMiniMarketTempArray] = useState([]);
  const [isMinimktProduct, setIsMinimktProduct] = useState(false);

  const [cabArray, setCabArray] = useState([]);
  const [isCabProduct, setIsCabProduct] = useState(false);

  const [isInfluencerProduct, setIsInfluencerProduct] = useState(false);
  const [isHireMe, setIsHireMe] = useState(false)

  //appointment array
  const [isAppointment, setIsAppointment] = useState(false);
  const [appointmentArray, setAppointmentArray] = useState([]);
  //social
  const [isSocial, setIsSocial] = useState(false);
  const [socialMediaData, setSocialMediaData] = useState([]);

  const [isTermsConditions, setIsTermsConditions] = useState(false);
  const [termsConditionData, setTermsConditionData] = useState([]);
  //business hours
  const [businessHourObject, setBusinessHourObject] = useState({});
  const [IsBusinessHourObject, setIsBusinessHourObject] = useState(false);
  const [agentCode, setAgentCode] = useState("");
  const [verifyCodeError, setVerifyCodeError] = useState('');
  const [isVerifyCodeVerified, setIsVerifyCodeVerified] = useState(true);
  const [isVerifyCodeSubmit, setIsVerifyCodeSubmit] = useState(false);
  //galary array
  const [gallaryObject, setGallaryObject] = useState({});
  const [isGallaryEnable, SetIsGallaryEnable] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [divStates, setDivStates] = useState({
    div1: false,
    div2: false,
    div3: false,
    div4: false,
    div5: false,
    div6: false,
    div7: false,
    div8: false,
    div9: false,
    div10: false,
  });

  const [rating, setRating] = useState('');
  const [link, setLink] = useState(null);
  const [reviews, setReviews] = useState('');
  const [tempRating, setTempRating] = useState(null);
  const [tempLink, setTempLink] = useState(null);
  const [tempReviews, setTempReviews] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [isAddressVerified, setIsAddressVerified] = useState(false);
  const [latLngError, setLatLngError] = useState("");
  const [dynamicCountry, setDynamicCountry] = useState("");
  const [allInfluencer, setAllInfluencer] = useState("");
  const [currencyData, setCurrencyData] = useState("");
  const [instaName, setInstaName] = useState("");
  const [youtubeName, setYoutubeName] = useState("");
  const [pixel_id, setPixelId] = useState("");
  const [countryCode, setCountryCode] = useState("in");

  //select business owner
  const [businessOwnerList, setBusinessOwnerList] = useState([]);
  const [businessOwnerId, setBusinessOwnerId] = useState(null);
  const [businessOwnerName, setbusinessOwnerName] = useState('');
  //crm dubai
  const [teamleadId, setteamleadId] = useState("");
  const [verifyCodeErrort, setVerifyCodeErrort] = useState('');
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [canonicalTag, setCanonicalTag] = useState("");
  // toggle colorpicker
  const colorPickerContainerRef = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];
  const [isColorPickerOpen, setColorPickerOpen] = useState([false, false, false, false, false, false]);
  const toggleColorPicker = (index) => {
    // const openingColorPicker = [...isColorPickerOpen];
    // const openingColorPicker=[false,false,false,false,false];
    // openingColorPicker[index]= !openingColorPicker[index];
    const updatedPickers = isColorPickerOpen.map((state, i) =>
      i === index ? !state : false);
    setColorPickerOpen(updatedPickers);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = colorPickerContainerRef.every(
        (ref) => ref.current && !ref.current.contains(event.target)
      );

      if (isOutside) {
        setColorPickerOpen([false, false, false, false, false, false]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [editableHeading, setEditableHeading] = useState(null);
  const handleHeadingEdit = (heading) => {
    if (editableHeading === heading) {
      setEditableHeading(null);
    } else {
      setEditableHeading(heading);
    }
  };
  const openModalinfluancer = () => setModalOpeninf(true);
  const closeModalInfluancer = () => setModalOpeninf(false);
  const [selectedPercentage, setSelectedPercentage] = useState("25");
  // Debounced function to call the API
  const fetchGoogleRating = async () => {
    try {
      const responseFirstAPI = await axios.post(`${baseUrl}api/v1/business/findGoogleRating`, {
        businessName: [formik.values.googlereview],
        locationName: [formik.values.googleaddress],
      });
      setIsAddSubmit(true);
      if (responseFirstAPI.status === 200 && responseFirstAPI.data.rating) {
        setTempRating(responseFirstAPI.data.rating);
        setTempLink(responseFirstAPI.data.placeId);
        setTempReviews(responseFirstAPI.data.totalreview);
        setIsAddSubmit(false)
      } else {
        setTempRating('');
        setTempLink(null);
        setTempReviews('');
        setIsAddSubmit(false)
      }
    } catch (error) {
      console.log("Error calling findGoogleRating API:", error);
      setIsAddSubmit(false)
    }
  };

  const getCurrency = async () => {
    try {
      axios
        .get(`${baseUrl}api/v1/currency`, {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((response) => {
          setCurrencyData(response?.data?.data);

        })
        .catch((error) => {
          console.log(error);

        });
    } catch (err) { }
  };

  useEffect(() => {
    fetchDynamicCountry()
    fetchAllInfluencers()
    getCurrency()
  }, [])
  useEffect(() => {
    changeThemeColor(dynamicBackgroundColor);
  }, [dynamicBackgroundColor]);
  useEffect(() => {
    changeThemeColorMainBackground(dynamicMainBackgroundColor);
  }, [dynamicMainBackgroundColor]);
  useEffect(() => {
    changeThemeColorDesc(dynamicTextDescriptionColor);
  }, [dynamicTextDescriptionColor]);
  useEffect(() => {
    changeThemeColorText(dynamicTextHeadingColor);
  }, [dynamicTextHeadingColor]);
  useEffect(() => {
    changeThemeColorCTA(dynamicCtaColor);
  }, [dynamicCtaColor]);
  useEffect(() => {
    changeThemeColorCTAText(dynamicCtaTextColor);
  }, [dynamicCtaTextColor]);


  useEffect(() => {
    fetchProductsByBusinessId();
  }, [influencerBusinessId])

  // useEffect(() => {
  //   console.log(nextStatusData,"activeTab===>>>", activeTab);

  //   if (!!nextStatusData && activeTab) {
  //     handleSubmit();
  //   }
  // }, [nextStatusData])
  useImperativeHandle(refs, () => handleSubmit);
  const fetchProductsByBusinessId = () => {
    axios
      .get(`${environmentVariables?.apiUrl}api/v1/influencer/getproductbyBusinessId?id=${influencerBusinessId}`)
      .then((response) => {
        setDataFromInfluencerChild(response?.data?.result);
      })
      .catch((error) => {
        console.log("Error fetching products:", error);
      });
  };

  const handleGoogleAddressChange = (e) => {
    const newGoogleAddress = e.target.value;

    formik.setFieldValue("googleaddress", newGoogleAddress);

    setIsVerified(false);
  };

  const handleBlurReview = async () => {
    const newGoogleAddress = formik.values.googleaddress;
    if (formik.values.googlereview === '' || newGoogleAddress === '') {
      return;
    }
    setIsAddSubmit(true);
    try {
      const responseFirstAPI = await axios.post(`${baseUrl}api/v1/business/findGoogleRating`, {
        businessName: formik.values.googlereview,
        locationName: newGoogleAddress,
      });

      if (responseFirstAPI.status === 200 && responseFirstAPI.data.rating) {
        setTempRating(responseFirstAPI.data.rating);
        setTempLink(responseFirstAPI.data.placeId);
        setTempReviews(responseFirstAPI.data.totalreview);
      } else {
        setTempRating('');
        setTempLink(null);
        setTempReviews('');
      }
    } catch (error) {
      console.log("Error calling findGoogleRating API:", error);
    } finally {
      setIsAddSubmit(false);
    }
  };

  const handleCorrectData = () => {
    setRating(tempRating);
    setLink(tempLink);
    setReviews(tempReviews);
    setIsVerified(true);
  };

  const handleCorrectAddressData = () => {
    setIsAddressVerified(true);
    setIsAddressDisabled(true);
  };

  const handleIncorrectAddressData = () => {
    setIsAddressVerified(false);
    setIsAddressDisabled(false);
  };

  const handleIncorrectData = () => {
    setRating('');
    setLink(null);
    setReviews('');
    setTempRating('');
    setTempLink(null);
    setTempReviews('');
    setIsVerified(false);
  };

  const handleRadioChange = (event) => {
    formik.handleChange(event); // Update Formik's state

    // Update isBannerRequired based on the selected value
    setIsBannerRequired(event.target.value === "1");
  };

  const handlePWARadioChange = (event) => {
    formik.handleChange(event);
  };
  const handleHireMeRadioChange = (event) => {
    formik.handleChange(event);
  };

  const toggleDiv = (divId) => {
    setDivStates({
      ...divStates,
      [divId]: !divStates[divId],
    });
  };
  // Function to handle code verification
  const verifyCodeAPI = async (code) => {
    const response = await fetch(`${baseUrl}api/v1/business/verifyagentcode`, {  // Use the correct backend URL
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ agentCode: code }),  // Sending the entered
    });
    if (response.ok)
      setIsVerifyCodeVerified(true);
    return response.json();  // Return the response as JSON
  };
  const handleVerifyCode = async (code) => {
    setIsVerifyCodeSubmit(true);
    setVerifyCodeError('');

    try {
      console.log("Agent Code to verify:", code);
      const response = await verifyCodeAPI(code); // API call to verify the agent code
      console.log("API Response:", response);

      if (response?.success) {
        console.log("Code verified successfully");
        setIsVerifyCodeVerified(true);
        setVerifyCodeError('');
      } else {
        console.log("Code verification failed");
        setIsVerifyCodeVerified(false);
        setVerifyCodeError('Invalid code. Please try again.');
      }
    } catch (error) {
      console.error("Verification Error:", error); // Log any errors
      setIsVerifyCodeVerified(false);
      setVerifyCodeError('Verification failed. Please try later.');
    } finally {
      setIsVerifyCodeSubmit(false);
    }
  };

  const validateAgentCodeInput = async (e) => {
    const value = e.target.value.toUpperCase();
    const alphanumericRegex = /^[A-Z0-9]*$/;

    if (alphanumericRegex.test(value) || value === "") {
      setAgentCode(value);
      setVerifyCodeError('');
    } else {
      setVerifyCodeError('Invalid characters. Only alphanumeric values are allowed.');
    }

    if (value === "") {
      setAgentCode("");
      setVerifyCodeError("");
      setIsVerifyCodeVerified(false);
    }

    if (value.length === 6) {
      await handleVerifyCode(value); // Verify code if length is 6
    }
  };

  const validateTeamLeadInput = async (e) => {
    const value = e.target.value;
    const numericRegex = /^[0-9]*$/;

    if (numericRegex.test(value)) {
      setteamleadId(value);
      setVerifyCodeErrort("");
    } else {
      setVerifyCodeErrort("Invalid input. Only numeric values are allowed.");
    }
    if (value === "") {
      setteamleadId("");
      setVerifyCodeErrort("");
    }
  };

  const isBannerShow = (theme) => {
    const themeswithbanner = [
      "theme1",
      "theme2",
      "theme3",
      "theme4",
      "theme5",
      "theme6",
      "theme7",
      "theme8",
      "theme9",
      "theme10",
      "theme11",
      "theme13",
      "theme15",
      "theme16",
      "theme17",
      "theme18",
      "theme19",
      "theme20",
      "theme21",
      "theme22",
      "theme23",
      "theme24",
      "theme25",
      "theme26",
      "theme27",
      "theme28",
      "theme29",
      "theme30",
      "theme31",
      "theme32",
      "theme33",
      "theme34",
      "theme35",
      "theme36",
      "theme37",
      "theme38",
      "theme39",
      "theme40",
      "theme41",
      "theme42",
      "theme43",
      "theme44",
      "theme45",
      "theme46",
      "theme47",
      "theme48",
      "theme49",
      "theme50",
      "theme51",
      "theme52",
      "theme53",
      "theme54",
      "theme55",

    ];
    if (themeswithbanner.includes(theme)) {
      return true;
    } else {
      return false;
    }
  };
  const isLogoShow = (theme) => {
    const themeswithbanner = ["theme10"];
    if (!themeswithbanner.includes(theme)) {
      return true;
    } else {
      return false;
    }
  };


  useEffect(() => {
    if (businessObj && businessObj?.id) {
      setBusinessId(businessObj?.id);
      setTitle(businessObj?.title || "");
      setPixelId(businessObj?.pixel_id || "");
      setMetaTitle(businessObj?.metaTitle || "");
      setMetaDescription(businessObj?.metaDescription || "");
      setCanonicalTag(businessObj?.canonicalTag || "");
      setIsDine(businessObj?.is_dine || 0);
      setIsShowBusinessName(businessObj?.is_showBusinessName === null ? 1 : businessObj?.is_showBusinessName);
      setIsShowAddress(businessObj?.is_showAddress === null ? 1 : businessObj?.is_showAddress);
      setDesignation(businessObj?.designation || "");
      setYoutubeName(businessObj?.youtubeName || "");
      setInstaName(businessObj?.instaName || "");
      setGoogleReview(businessObj?.Googlereview || "");
      setgoogleAddress(businessObj?.googleaddress || "");
      // setLink(businessObj?.reviewlink || "");
      setRating(businessObj?.rating || "");
      setReviews(businessObj?.review || "");
      setSubTitle(businessObj?.sub_title || "");
      setDescription(businessObj?.description || "");
      // if(businessObjData?.bo_id === businessObjData?.created_by){
      setBusinessOwnerId(businessObj?.bo_id || "");
      // setSelectBusinessOwnerId({
      //   label:"",
      //   value:businessObj?.bo_id
      // })
      // }
      setBannerImageUrl(businessObj?.banner || "");
      setLogoImageUrl(businessObj?.logo || "");
      setSlug(businessObj?.slug || "");
      setAgentCode(businessObj?.agentCode || "");
      setteamleadId(businessObj?.teamleadId || "");
      const reviewLink = businessObj?.reviewlink || "";
      setLink(reviewLink);
      if (reviewLink) {
        // Extract the place_id from the review URL
        const placeIdMatch = reviewLink.match(/place_id:([^&]+)/);
        if (placeIdMatch) {
          setTempLink(placeIdMatch[1]);
          setIsVerified(true);
        } else {
          setTempLink("");
          setIsVerified(false);
        }
      } else {
        setIsVerified(false);
        setTempLink("");
      }
    } else {
      setBusinessId("");
      setTitle("");
      setPixelId("");
      setMetaTitle("");
      setMetaDescription("");
      setCanonicalTag("");
      setIsDine(0);
      setIsShowBusinessName(1);
      setIsShowAddress(1);
      setDesignation("");
      setGoogleReview("");
      setgoogleAddress("");
      setLink("");
      setRating("");
      setReviews("");
      setSubTitle("");
      setDescription("");
      setBannerImageUrl("");
      setLogoImageUrl("");
      setSlug("");
      setIsVerified(false);
      setTempLink("");
      setAgentCode("");
      setteamleadId("");
    }

    let contactInfoData;
    if (
      businessObj &&
      businessObj?.contactInfo &&
      businessObj?.contactInfo?.business_id &&
      businessObj?.contactInfo?.content
    ) {
      contactInfoData = JSON.parse(businessObj?.contactInfo?.content);
      if (contactInfoData.length > 0) {
        contactInfoData = JSON.parse(contactInfoData);
      }
    }
    if (
      contactInfoData?.contactInfoAddress ||
      contactInfoData?.contactInfoPhone ||
      contactInfoData?.optionalContactInfoPhone ||
      contactInfoData?.contactInfoWebUrl ||
      contactInfoData?.contactInfoEmail
    ) {
      setContactInfoPhone(contactInfoData?.contactInfoPhone);
      setOptionalContactInfoPhone(contactInfoData?.optionalContactInfoPhone)
      setContactInfoEmail(contactInfoData?.contactInfoEmail);
      setContactInfoWebUrl(contactInfoData?.contactInfoWebUrl);
      setContactInfoAddress(contactInfoData?.contactInfoAddress);

      if (!!contactInfoData?.contactInfoAddress && businessObj?.addressPlaceId) {
        setAddressPlaceId(businessObj?.addressPlaceId);
        setAddressLat(businessObj?.latitude);
        setAddressLng(businessObj?.longitude);
        setIsAddressVerified(true);
        setIsAddressDisabled(true);
      }
      setContactInfoOptAddress(contactInfoData?.contactInfoOptAddress);

    } else {
      // setIsEnabledContactInfo(false);
      setContactInfoPhone("");
      setContactInfoEmail("");
      setContactInfoWebUrl("");
      setContactInfoAddress("");
      setContactInfoOptAddress("");
      setOptionalContactInfoPhone("");
      setIsAddressVerified(false);
      setIsAddressDisabled(false);
    }    
    
    if ( businessObj && businessObj?.headingsObj && businessObj?.headingsObj?.business_id ) {
      setcontactHeading( businessObj?.headingsObj?.contactinfo_Heading);
      setDescriptionHeading(businessObj?.headingsObj?.description_Heading);
      setGalleryHeading(businessObj?.headingsObj?.gallery_Heading);
      setAppointmentsHeading(businessObj?.headingsObj?.appointment_Heading);
      setBusinessHourHeading(businessObj?.headingsObj?.businesshour_Heading);
      setSocialHeading(businessObj?.headingsObj?.social_Heading);
      seAlternateUrlsHeading(businessObj?.headingsObj?.alternateUrls_Heading);
      setreviewHeading( businessObj?.headingsObj?.googlereview_Heading);
      setcontactformHeading( businessObj?.headingsObj?.contactform_Heading);
      setserviceHeading( businessObj?.headingsObj?.service_Heading);
      settestimonialHeading( businessObj?.headingsObj?.testimonials_Heading);
      setCabProductHeading( businessObj?.headingsObj?.Cab_products_Heading);
      setTermConditionHeading( businessObj?.headingsObj?.TermsandConditions_Heading);
      setCuisineHeading( businessObj?.headingsObj?.Cuisine_Heading);
      setMenuHeading( businessObj?.headingsObj?.Menu_Heading);
      setOrderHeading( businessObj?.headingsObj?.Order_Heading);
    }

    else {
      setcontactHeading("");
      setDescriptionHeading("");
      setGalleryHeading("");
      setAppointmentsHeading("");
      setBusinessHourHeading("")
      setSocialHeading("")
      seAlternateUrlsHeading("");
      setreviewHeading("");
      setcontactformHeading("");
      setserviceHeading("");
      settestimonialHeading("");
      setCabProductHeading("");
      setTermConditionHeading("");
      setCuisineHeading("");
      setMenuHeading("");
      setOrderHeading("");
    }



    if (
      businessObj &&
      businessObj?.is_InfluencerProduct == 1
    ) {
      setIsInfluencerProduct(true);
      setOnState({ ...onState, influencerproduct: true });
    } else {
      setIsInfluencerProduct(false);
    }
    if (
      businessObj &&
      businessObj?.gallaryObj &&
      businessObj?.gallaryObj?.is_enabled
    ) {
      SetIsGallaryEnable(true);
      setOnState({ ...onState, gallery: true });
      const galleryData = JSON.parse(businessObj?.gallaryObj?.content);
      setGallaryObject(galleryData);
      if (galleryData?.gallaryArray) {
        setExistingGalleryImages([...galleryData?.gallaryArray]);
      }
    } else {
      SetIsGallaryEnable(false);
    }
    if (
      businessObj &&
      businessObj?.businessHourObj &&
      businessObj?.businessHourObj?.is_enabled
    ) {
      setIsBusinessHourObject(true);
      setOnState({ ...onState, businessHours: true });
    } else {
      setIsBusinessHourObject(false);
    }
    if (
      businessObj &&
      businessObj?.SocialMediaObj &&
      businessObj?.SocialMediaObj?.is_enabled
    ) {
      setIsSocial(true);
      setOnState({ ...onState, socials: true });
    } else {
      setIsSocial(false);
    }
    if (
      businessObj &&
      businessObj?.appointmentObj &&
      businessObj?.appointmentObj?.is_enabled
    ) {
      setIsAppointment(true);
      setOnState({ ...onState, appointment: true });
    } else {
      setIsAppointment(false);
    }
    if (
      businessObj &&
      businessObj?.testimonialObj &&
      businessObj?.testimonialObj?.is_enabled == 1
    ) {
      setIsTestimonial(true);
      setOnState({ ...onState, testimonials: true });
    } else {
      setIsTestimonial(false);
    }



    if (
      businessObj &&
      businessObj?.metaKeywordObj &&
      businessObj?.metaKeywordObj?.is_enabled == 1
    ) {
      setIsEnabledKeyword(true);
      setOnState({ ...onState, metakeyword: true });
    } else {
      setIsEnabledKeyword(false);
    }



    if (
      businessObj &&
      businessObj?.alternatUrlObj &&
      businessObj?.alternatUrlObj?.is_enabled == 1
    ) {
      setAlternateUrl(true);
      setOnState({ ...onState, alternateurl: true });
    } else {
      setAlternateUrl(false);
    }
    if (
      businessObj &&
      businessObj?.menu &&
      businessObj?.menu?.is_enabled == 1
    ) {
      setIsRestaurant(true);
      setOnState({ ...onState, restaurantmenu: true });
    } else {
      setIsRestaurant(false);
    }
    if (
      businessObj &&
      businessObj?.miniMktProductArr &&
      businessObj?.is_mini_mktproduct == 1
    ) {
      setIsMinimktProduct(true);
      setOnState({ ...onState, miniproduct: true });
    } else {
      setIsMinimktProduct(false);
    }
    if (
      businessObj &&
      businessObj?.is_CabProduct == 1
    ) {
      setIsCabProduct(true);
      setOnState({ ...onState, cabproduct: true });
    } else {
      setIsCabProduct(false);
    }
    if (
      businessObj &&
      businessObj?.cabTermsConditions &&
      businessObj?.cabTermsConditions?.is_enabled == 1
    ) {
      setIsTermsConditions(true);
      setOnState({ ...onState, cabTermsConditions: true });
    } else {
      setIsTermsConditions(false);
    }

    if (
      businessObj &&
      businessObj?.serviceObj &&
      businessObj?.serviceObj?.is_enabled
    ) {
      setIsEnabledServices(true);
      setOnState({
        ...onState,
        services: true,
      });
    } else {
      setIsEnabledServices(false);
    }

    if (
      businessObj &&
      businessObj?.contactInfo &&
      businessObj?.contactInfo?.is_enabled
    ) {
      setIsEnabledContactInfo(true);
      setOnState({
        ...onState,
        contactInfo: true,
      });
    } else {
      // setIsEnabledContactInfo(false);
    }
    if (businessObj && businessObj?.is_enabledGooglereview == 1) {
      setIsEnabledGoogleReview(true);
      // let obj={...onstate}
      setOnState({
        ...onState,
        googlereview: true,
      });
    } else {
      setIsEnabledGoogleReview(false);
    }

    if (businessObj && businessObj?.is_contact_form_enabled) {
      setIsEnabledContactForm(true);
    } else {
      setIsEnabledContactForm(false);
    }
    if (businessObj && businessObj?.is_order_enabled) {
      setIsEnabledOrders(true);
    } else {
      setIsEnabledOrders(false);
    }
    if (businessObj?.newGalleryImages) {
      setGalleryData(businessObj?.newGalleryImages);
    }
    if (businessObj?.is_allow_in_search == 1) {
      setIsBannerRequired(true)
    }
    if (businessObj?.influencer_profit?.length > 0) {
      setSelectedPercentage(businessObj?.influencer_profit)
    } else {
      setSelectedPercentage("25")
    }
    

    formik.setValues({
      ...formik.values,
      contactInfoPhone: contactInfoData?.contactInfoPhone || (userData?.contact?.startsWith('+') ? userData?.contact : `${userData?.country_code || ""}${userData?.contact || ""}`) || "",
      optionalContactInfoPhone: contactInfoData?.optionalContactInfoPhone || (userData?.contact?.startsWith('+') ? userData?.contact : `${userData?.country_code || ""}`) || "",
      contactInfoEmail: contactInfoData?.contactInfoEmail || userData?.email || "",
      contactInfoWebUrl: contactInfoData?.contactInfoWebUrl || "",
      contactInfoAddress: contactInfoData?.contactInfoAddress || "",
      subTitle: businessObj?.sub_title || userData?.name || "",
      instaName: businessObj?.instaName || "",
      youtubeName: businessObj?.youtubeName || "",
      designation: businessObj?.designation || "",
      googlereview: businessObj?.googlereview || "",
      googleaddress: businessObj?.googleaddress || "",
      description: businessObj?.description || "",
      description: businessObj?.description || "",
      is_allow_in_search: businessObj?.is_allow_in_search !== undefined ? businessObj.is_allow_in_search : 1,
      is_enabledPwa: businessObj?.is_enabledPwa !== undefined ? businessObj.is_enabledPwa : 1,
      shipCountry: businessObj?.shipCountry || "IN",
      influencer_id: businessObj?.influencer_id || "",
      is_enabledHireMe: businessObj?.is_enabledHireMe || "",
    });
  }, [businessObj]);


  useEffect(() => {
    let stateObj = {};
    if (businessObj?.testimonialObj?.is_enabled) {
      stateObj["testimonials"] = true;
    }
    if (businessObj?.metaKeywordObj?.is_enabled) {
      stateObj["metakeyword"] = true;
    }
    if (businessObj?.alternatUrlObj?.is_enabled) {
      stateObj["alternateurl"] = true;
    }
    if (businessObj?.menu?.is_enabled) {
      stateObj["restaurantmenu"] = true;
    }
    if (businessObj?.is_mini_mktproduct == 1) {
      stateObj["miniproduct"] = true;
    }
    if (businessObj?.serviceObj?.is_enabled) {
      stateObj["services"] = true;
    }
    if (businessObj?.contactInfo?.is_enabled == 0) {
      stateObj["contactInfo"] = false;
    } else {
      stateObj["contactInfo"] = true;
    }
    if (businessObj?.gallaryObj?.is_enabled) {
      stateObj["gallery"] = true;
    }
    if (businessObj?.businessHourObj?.is_enabled) {
      stateObj["businessHours"] = true;
    }
    if (businessObj?.SocialMediaObj?.is_enabled) {
      stateObj["socials"] = true;
    }
    if (businessObj?.cabTermsConditions?.is_enabled) {
      stateObj["termsconditions"] = true;
    }
    if (businessObj?.is_CabProduct == 1) {
      stateObj["cabproduct"] = true;
    }
    if (businessObj?.is_InfluencerProduct == 1) {
      stateObj["influencerproduct"] = true;
    }
    if (businessObj?.appointmentObj?.is_enabled) {
      stateObj["appointment"] = true;
    }
    if (businessObj?.is_contact_form_enabled) {
      stateObj["contactForm"] = true;
    }
    if (businessObj?.is_order_enabled) {
      stateObj["orders"] = true;
    }
    if (
      businessObj?.is_enabledGooglereview &&
      businessObj?.is_enabledGooglereview == 1
    ) {
      stateObj["googlereview"] = true;
    }
    setOnState(stateObj);
  }, [businessObj]);

  const handleBannerChange = (e) => {
    const file = e.target.files[0];

    // List of accepted image MIME types
    const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

    if (file && acceptedImageTypes.includes(file.type)) {
      setBannerData(file);

      // Use the FileReader API to display the selected image
      const reader = new FileReader();
      reader.onload = () => {
        setBannerImageUrl(reader.result); // Immediate image for showing
      };
      reader.readAsDataURL(file);
    } else {
      // Display an error message if the selected file is not an image
      toast.error("Please select a valid image file (JPEG, PNG, JPG).", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];

    // List of accepted image MIME types
    const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

    if (file && acceptedImageTypes.includes(file.type)) {
      setLogoData(file); // Setting logo data

      setSrc(URL.createObjectURL(file));
      setModalOpen(true);

      // Use the FileReader API to display the selected image
      const reader = new FileReader();
      reader.onload = () => {
        setLogoImageUrl(reader.result); // Immediate image for showing
      };
      reader.readAsDataURL(file);
    } else {
      // Display an error message if the selected file is not an image
      toast.error("Please select a valid image file (JPEG, PNG, JPG).", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };


  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // const validationSchema = Yup.object().shape({
  //   contactInfoPhone: Yup.string().min(10, "Enter valid Mobile Number"),
  //   optionalContactInfoPhone: Yup.string().min(0, "Enter valid Mobile Number"),
  //   contactInfoEmail: Yup.string().email("Enter valid Email").required("Email is required"),
  //   contactInfoAddress: Yup.string().required("Address is required"),
  //   contactInfoWebUrl: Yup.string(),
  //   subTitle: Yup.string()
  //      .matches(
  //        /^[a-zA-Z0-9'. -]*$/,
  //        "Only letters, numbers, . ' - and spaces are allowed."
  //      )
  //     .min(2, "Minimum 2 characters required")
  //     .max(63, "Maximum 63 characters are allowed in name."),
  //   designation: Yup.lazy((value) => {
  //     if (["theme14", "theme20", "theme22"].includes(businessObjData?.theme_color)) {
  //       return Yup.string().nullable();
  //     } else {
  //       return Yup.string()
  //         .matches(
  //           /^[a-zA-Z0-9 ,.?\/()&@$'’"_\-\n\r–%:!#$%&\(\)\-\+\[\]\{\};:'“”,.~@\/?]*$/,
  //           "Invalid input"
  //         )
  //         .min(2, "Minimum 2 characters required")
  //         .max(100, "Maximum 100 characters required")
  //         .required("Designation is required");
  //     }
  //   }),
  //   googlereview: Yup.string(),
  //   description: Yup.string()
  //     .required("Description is required.")
  //     .matches(/^[a-zA-Z0-9 ,.?\/()&@$'’"_\-\n\r–%:!#$%&\(\)\-\+\[\]\{\};:'“”,.~@\/?]*$/, "Description contains invalid characters."),
  //   is_allow_in_search: Yup.number().oneOf([0, 1], "Please select an option"),
  //   is_enabledPwa:Yup.number().oneOf([0, 1], "Please select an option"),
  // });



  const validationSchema = Yup.object().shape({
    contactInfoPhone: Yup.string().min(10, t("Enter_valid_mobile_number")),
    optionalContactInfoPhone: Yup.string().min(0, t("Enter_valid_mobile_number")),
    contactInfoEmail: Yup.string().email(t("Enter_valid_email")).required(t("Email_required")),
    contactInfoAddress: Yup.string()
      .matches(/^[\p{L}\p{N}\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF\u064B-\u065F\s.,\/()&@$'’"_\-\n\r–%:]*$/u, t("Invalid_address_format"))
      .required(t("Address_required")),
    contactInfoWebUrl: Yup.string(),
    subTitle: Yup.string().matches(
      //  /^[\p{L}\p{N}\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF'.\s-]*$/u,
      /^[\p{L}\p{N}\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF\u064B-\u065F'.\s،-]*$/u,
      t("Char_allowed")
    ).min(2, t("Minimum_2_characters_required"))
      .max(63, t("Maximum_63_char_name")),
    designation: Yup.lazy((value) => {
      if (["theme14", "theme20", "theme22"].includes(businessObjData?.theme_color)) {
        return Yup.string().nullable();
      } else {
        return Yup.string()
          .matches(
            /^[\p{L}\p{N}\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF\u064B-\u065F ,.?\/()&@$'’"_\-\n\r–%:!#$%&\(\)\-\+\[\]\{\};:'“”,.~@\/?]*$/u,
            t("Invalid_input")
          )
          .min(2, t("Minimum_2_characters_required"))
          .max(100, t("Maximum_100_characters_required"))
          .required(t("Designation_required"));
      }
    }),
    googlereview: Yup.string(),
    description: Yup.string()
      .required(t("Description_required"))
      .matches(
        // /^[\u0621-\u064A\u0660-\u0669\d ,.?\/()&@$'’"_\-\n\r–%:!#$%&()\-\+\[\]\{\};:'“”,.~@\/?]*$/u,
        /^[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFFa-zA-Z\d ,.?\/()&@$'’"_\-\n\r–%:!#$%&()\-\+\[\]\{\};:'“”,.~@\/?]*$/u,

        t("Description_contains_invalid_characters.")
      ),
    is_allow_in_search: Yup.number().oneOf([0, 1], t("Please_select_option")),
    is_enabledPwa: Yup.number().oneOf([0, 1], t("Please_select_option")),
  });
  const fetchDynamicCountry = async () => {
    try {
      axios
        .get(`${baseUrl}api/v1/user/getCountries`, {
        })
        .then((response) => {
          setDynamicCountry(response?.data?.result);
          // setIsLoading(false)

        })
        .catch((error) => {
          console.log(error, "error in fetching");
          // setIsLoading(false)
        });
    } catch (err) {
      console.log(err, " eroor iiiiiiiii")
    }
  };

  const fetchAllInfluencers = async () => {
    try {
      axios
        .get(`${baseUrl}api/v1/business/getAllInfluencers`, {
        })
        .then((response) => {
          setAllInfluencer(response?.data?.data);
        })
        .catch((error) => {
          console.log(error, "error in fetching");
        });
    } catch (err) {
      console.log(err, " eroor iiiiiiiii")
    }
  };


  const [countryData, setCountryData] = useState({});

  const fetchDynamicCountryForDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}api/v1/user/getCountries`);
      const countryArray = response?.data?.result; // Assuming 'result' contains the country list

      // Transform the response data to match the desired format
      const dynamicCountryData = countryArray.reduce((acc, country) => {
        acc[country.phone_code] = {
          name: country.name,
          iso2: country.short_code,
          dialCode: country.phone_code,
        };
        return acc;
      }, {});

      // Update the state with the transformed data
      setCountryData(dynamicCountryData);
    } catch (error) {
      console.log(error, "Error fetching country data");
    }
  };

  useEffect(() => {
    fetchDynamicCountryForDetails();
  }, []);

  const handleSubmit = async (e) => {
    setIsSubmit(true);
    // let link = "";
    // let rating = "";
    // let reviews = "";

    await formik.validateForm();
    // try {
    //   const responseFirstAPI = await axios.post(`${baseUrl}api/v1/business/findGoogleRating`, {
    //     businessName: [formik.values.googlereview],
    //     locationName: ['Shop No. 7, Bird Sanctuary, Okhla Bird Sanctuary, Rd, Pocket K, Sector 95, Noida, Uttar Pradesh 201301']
    //   });

    //   // Check if the API call was successful and if it returned a rating
    //   if (responseFirstAPI.status === 200 && responseFirstAPI.data.rating) {
    //     rating = responseFirstAPI?.data?.rating;
    //     link = responseFirstAPI?.data?.placeId;
    //     reviews = responseFirstAPI?.data?.totalreview;
    //   } else {
    //     rating = null;
    //     link = null;
    //     reviews = null;
    //   }
    // } catch (error) {
    //   // Handle the case where the API call fails
    // }
    if (businessObjData?.theme_color !== 'theme12') {
      // Check if the banner is required and the banner image URL is missing
      if (isBannerRequired && !bannerImageUrl) {
        toast.error(t("Banner_validation"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsSubmit(false);
        return;
      }
    }

    // if (invalidPhoneErr) {
    //   toast.error(invalidPhoneErr, {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 1000,
    //   });
    //   setIsSubmit(false);
    //   return;
    // }

    // if (invalidOptPhoneErr) {
    //   toast.error(invalidOptPhoneErr, {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 1000,
    //   });
    //   setIsSubmit(false);
    //   return;
    // }
    if (!formik.values.contactInfoEmail) {
      toast.error(t("Email_required"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmit(false);
      return;
    }
    if (!formik.values.contactInfoAddress) {
      toast.error(t("Address_required"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmit(false);
      return;
    }
    if (!isAddressVerified) {
      toast.error(t("Address_not_verified"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmit(false);
      return;
    }
    console.log("businessOwnerId====>>>", businessOwnerId);

    if (!businessOwnerId && (userData.user_type === 'Team Leader' || userData.user_type === 'Sales Executive' || userData.user_type === 'super_admin')) {
      toast.error(t("Business_owner_required"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmit(false);
      return;
    }

    // if(!agentCode && (userData.user_type === 'Team Leader' || userData.user_type === 'Sales Executive' || userData.user_type === 'super_admin')){
    //   toast.error("Agent Code is required.", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 1000,
    //   });
    //   setIsSubmit(false);
    //   return;
    // }

    // if(!teamleadId && (userData.user_type === 'Team Leader' || userData.user_type === 'Sales Executive')){
    //   toast.error("Teamlead Id is required.", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 1000,
    //   });
    //   setIsSubmit(false);
    //   return;
    // }


    if (!formik.isValid) {
      console.log("errors>>>>>", formik?.errors);
      if (formik?.errors?.subTitle) {
        toast.error(formik?.errors?.subTitle, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.designation) {
        toast.error(formik?.errors?.designation, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.description) {
        toast.error(formik?.errors?.description, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.contactInfoEmail) {
        toast.error(formik?.errors?.contactInfoEmail, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.contactInfoWebUrl) {
        toast.error(formik?.errors?.contactInfoWebUrl, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.contactInfoPhone) {
        toast.error(formik?.errors?.contactInfoPhone, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.optionalContactInfoPhone) {
        toast.error(formik?.errors?.optionalContactInfoPhone, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.contactInfoAddress) {
        toast.error(formik?.errors?.contactInfoAddress, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
      setIsSubmit(false);
      return;
    }
    if (businessObjData?.theme_color === 'theme14' ||
      businessObjData?.theme_color === 'theme22' ||
      businessObjData?.theme_color === 'theme20') {
    } else {
      if (businessObjData?.theme_color === 'theme12') {
        if (!isEnabledGoogleReview || !rating || rating.length === 0) {
          toast.error(t("Google_review_required"), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsSubmit(false);
          return;
        }
      } else {
        if (isEnabledGoogleReview && (!rating || rating.length === 0)) {
          toast.error(t("Google_details"), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsSubmit(false);
          return;
        }
      }
    }



    if (
      businessObjData?.theme_color === 'theme12' ||
      businessObjData?.theme_color === 'theme20' ||
      businessObjData?.theme_color === 'theme17' ||
      businessObjData?.theme_color === 'theme22' ||
      businessObjData?.theme_color === 'theme53'
    ) {
    } else {
      if (isEnabledServices) {
        if (!servicesArray || servicesArray.length === 0) {
          toast.error(t("Service_required"), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsSubmit(false);
          return;
        }

        const hasEmptyName = servicesArray.some(service => !service.name?.trim());
        if (hasEmptyName) {
          toast.error(t("Service_name_mandatory"), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsSubmit(false);
          return;
        }
      }
    }

    if (businessObjData?.theme_color === 'theme20') {
      if (isEnabledServices) {
        if (!servicesArray || servicesArray.length === 0) {
          toast.error(t("Category_required"), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsSubmit(false);
          return;
        }
      }
    }



    if (businessObjData?.theme_color !== 'theme22'
    ) {
    } else {
      if (isCabProduct && (!cabArray || cabArray.length === 0)) {
        toast.error(t("Cab_product_required"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsSubmit(false);
        return;
      }
    }

    if (
      businessObjData?.theme_color === 'theme12' ||
      businessObjData?.theme_color === 'theme14' ||
      businessObjData?.theme_color === 'theme15' ||
      businessObjData?.theme_color === 'theme16' ||
      businessObjData?.theme_color === 'theme20' ||
      businessObjData?.theme_color === 'theme22' ||
      businessObjData?.theme_color === 'theme53'
    ) {
    } else {
      if (isTestimonial && (!testimonialArray || testimonialArray.length === 0)) {
        toast.error(t("Testimonial_required"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsSubmit(false);
        return;
      }
    }

    if (isAlternateUrl && (!alternateUrlArray || alternateUrlArray.length === 0)) {
      toast.error(t("Url_required"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmit(false);
      return;
    }

    if (isSocial && (!socialMediaData || socialMediaData.length === 0)) {
      toast.error(t("Social_media_required"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmit(false);
      return;
    }

    if (
      businessObjData?.theme_color === 'theme22' ||
      businessObjData?.theme_color === 'theme12' ||
      businessObjData?.theme_color === 'theme20') {
    } else {
      if (IsBusinessHourObject) {
        const {
          isMonday, isTuesday, isWednesDay,
          isfridayDay, issaturdayDay,
          issundayDay, isthrusdayDay
        } = businessHourObject;

        const allDaysFalse = !isMonday && !isTuesday && !isWednesDay && !isfridayDay && !issaturdayDay && !issundayDay && !isthrusdayDay;

        if (allDaysFalse) {
          toast.error(t("Business_hour"), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsSubmit(false);
          return;
        }
      }
    }


    if (
      businessObjData?.theme_color === 'theme22' ||
      businessObjData?.theme_color === 'theme17' ||
      businessObjData?.theme_color === 'theme20') {
    } else {
      if (isGallaryEnable && (!galleryData || galleryData.length === 0)) {
        toast.error(t("Portfolio_required"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsSubmit(false);
        return;
      }
    }
    let titleData = title.trim();
    if (titleData == '' || titleData == null) {
      toast.error(t("Company_name_required"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmit(false);
      return;
    }
    const formData = new FormData();
    // get currency data 

    let currencyData = (!!formik.values.shipCountry) ? getCurrencyData(formik.values.shipCountry) : "₹";

    formData.append("description_Heading", descriptionHeading);
    formData.append("gallery_Heading", galleryHeading);
    formData.append("appointment_Heading", appointmentsHeading);
    formData.append("businesshour_Heading", businessHourHeading);
    formData.append("social_Heading", socialHeading);
    formData.append("alternateUrls_Heading", alternateUrlsHeading);
    formData.append("reviewlink", link);
    formData.append("rating", rating);
    formData.append("review", reviews);
    formData.append("banner", bannerData);
    formData.append("logo", logoData);
    formData.append("businessId", businessId);
    formData.append("title", title);
    formData.append("contactinfo_Heading", contactHeading);
    formData.append("googlereview_Heading",reviewHeading);
    formData.append("contactform_Heading",contactformHeading);
    formData.append("service_Heading",serviceHeading);
    formData.append("testimonials_Heading",testimonialHeading);
    formData.append("Cab_products_Heading",cabProductHeading);
    formData.append("TermsandConditions_Heading",termConditionHeading);
    formData.append("Menu_Heading",menuHeading);
    formData.append("Order_Heading",orderHeading);

    formData.append("isDine", isDine);
    formData.append("isShowBusinessName", isShowBusinessName);
    formData.append("isShowAddress", isShowAddress);
    formData.append("designation", formik.values.designation);
    formData.append("instaName", formik.values.instaName);
    formData.append("youtubeName", formik.values.youtubeName);
    formData.append("hireMe", formik.values.is_enabledHireMe);
    formData.append("googlereview", formik.values.googlereview);
    formData.append("googleaddress", formik.values.googleaddress);
    formData.append("sub_title", formik.values.subTitle);
    formData.append("description", formik.values.description);
    formData.append("shipCountry", formik.values.shipCountry);
    formData.append("currency_symbol", currencyData);
    formData.append("influencer_id", formik.values.influencer_id);
    formData.append("slug", slug);
    // formData.append("is_allow_in_search", isCheckboxChecked ? 1 : 0);
    formData.append("is_allow_in_search", formik.values.is_allow_in_search)
    formData.append("is_enabledPwa", formik.values.is_enabledPwa)
    formData.append("influencer_profit", selectedPercentage)
    let formCount = 0;
    formData.append(
      "is_enabledContactInfo",
      isEnabledContactInfo == true ? 1 : 0
    );
    formData.append(
      "isEnabledGoogleReview",
      isEnabledGoogleReview == true ? 1 : 0
    );
    formData.append(
      "is_contact_form_enabled",
      isEnabledContactForm == true ? 1 : 0
    );
    formData.append("is_order_enabled", isEnabledOrders == true ? 1 : 0);
    let latData = addressLat.toString();
    let lngData = addressLng.toString();
    let addressPlaceIdData = addressPlaceId.toString();
    let contactOptionallength = formik.values.optionalContactInfoPhone?.split("-")?.[1]?.length;

    let content = {
      contactInfoPhone: formik.values.contactInfoPhone,
      optionalContactInfoPhone: (contactOptionallength > 0) ? formik.values.optionalContactInfoPhone : '',
      contactInfoEmail: formik.values.contactInfoEmail,
      contactInfoWebUrl: formik.values.contactInfoWebUrl,
      contactInfoAddress,
      contactInfoOptAddress,
      addressLat: latData,
      addressLng: lngData,
      addressPlaceId: addressPlaceIdData,
    };
    if (isEnabledContactInfo == true) {
      formData.append("content", JSON.stringify(content));
    }
    formData.append("is_services", isEnabledServices ? 1 : 0);
    if (servicesArray && servicesArray.length) {
      servicesArray.forEach((file, index) => {
        if (file?.banner && file?.banner["0"].name?.length) {
          formData.append(`servicesArray`, file?.banner["0"]);
        } else if (file && file.image) {
          formData.append(`servicesImages[${index}]`, file?.image);
        }
        formData.append(`serviceId[${index}]`, file?.id)
        formData.append(`serviceDescription[${index}]`, file?.description);
        formData.append(`serviceName[${index}]`, file?.name);
        formData.append(`serviceButtonLink[${index}]`, file?.buttonLink);
        formData.append(`serviceButtonName[${index}]`, file?.buttonName);
      });
    }



    formData.append("is_keyword", isEnabledKeyword ? 1 : 0);
    if (keywordArray && keywordArray.length) {
      keywordArray.forEach((file, index) => {
        formData.append(`keywordId[${index}]`, file?.id)
        formData.append(`keywordName[${index}]`, file?.name);

      });
    }



    //testimonial array

    formData.append("is_testimonial", isTestimonial ? 1 : 0);
    if (testimonialArray && testimonialArray.length) {
      testimonialArray.forEach((file, index) => {
        if (
          file &&
          file.image &&
          file.image["0"] &&
          file.image["0"].name?.length
        ) {
          formData.append(`testimonialArray`, file?.image["0"]);
        } else {
          formData.append(`testimonialImageArray[${index}]`, file?.image);
        }
        formData.append(`testimonialDescription[${index}]`, file?.description);
        formData.append(`testimonialName[${index}]`, file?.name);
        formData.append(`testimonialFormCount[${index}]`, index);
      });
    }

    // alternate url
    formData.append("is_alternateurl", isAlternateUrl ? 1 : 0);
    if (alternateUrlArray && alternateUrlArray.length) {
      alternateUrlArray.forEach((file, index) => {
        if (
          file &&
          file.image &&
          file.image["0"] &&
          file.image["0"].name?.length
        ) {
          formData.append(`alternateurlArray`, file?.image["0"]);
        } else {
          formData.append(`alternateImageArray[${index}]`, file?.image);
        }
        formData.append(`alternateurlDescription[${index}]`, file?.description);
        formData.append(`alternateurlName[${index}]`, file?.name);
        formData.append(`alternateUrlFormCount[${index}]`, index);
      });
    }

    //restaurantmenuarray

    formData.append("is_menu", isRestaurant ? 1 : 0);
    if (restaurantArray && restaurantArray.length) {
      restaurantArray.forEach((file, index) => {
        if (
          file &&
          file.image &&
          file.image["0"] &&
          file.image["0"].name?.length
        ) {
          formData.append(`restaurantArray`, file?.image["0"]);
        } else {
          formData.append(`restaurantImageArray[${index}]`, file?.image);
        }
        formData.append(`dishPrice[${index}]`, file.price);
        formData.append(`dishName[${index}]`, file?.name);
        formData.append(`dishCategory[${index}]`, file?.category);
        formData.append(`restaurantFormCount[${index}]`, index);
      });
    }

    // mini market place products
    let dataAllArr = [...minimarketArray]
    // dataAllArr = dataAllArr?.filter((e) => e.price)

    formData.append("is_mini_mktproduct", isMinimktProduct ? 1 : 0);
    if (dataAllArr && dataAllArr.length) {
      dataAllArr.forEach((file, index) => {
        if (
          file &&
          file.image &&
          file.image["0"] &&
          file.image["0"].name?.length
        ) {
          formData.append(`mini_mktproduct`, file?.image["0"]);
        } else {
          formData.append(`restaurantImageArray[${index}]`, file?.image);
        }
        formData.append(`minimktproductPrice[${index}]`, (!!file.price) ? file.price : 0);
        formData.append(`minimktproductQuantity[${index}]`, (!!file.quantity) ? file.quantity : 0);
        formData.append(`minimktproductName[${index}]`, (!!file?.name) ? file?.name : "");
        formData.append(`minimktproductCategory[${index}]`, file?.category);
        formData.append(`minimktproductservice[${index}]`, file?.service);
        formData.append(`minimktproductDescription[${index}]`, (!!file?.description) ? file?.description : "")
        formData.append(`restaurantFormCount[${index}]`, index);
      });
    }


    formData.append("is_business_hours_enabled", IsBusinessHourObject ? 1 : 0);
    formData.append("businesshoursOBject", JSON.stringify(businessHourObject));

    //appointment array
    formData.append("appointmentContent", JSON.stringify(appointmentArray));
    formData.append("is_appointment", isAppointment ? 1 : 0);

    //social media
    formData.append("is_social", isSocial ? 1 : 0);
    formData.append("isSocalData", JSON.stringify(socialMediaData));

    // terms and conditions data
    formData.append("is_tnc", isTermsConditions ? 1 : 0);
    formData.append("tncData", JSON.stringify(termsConditionData))


    // gallary
    formData.append("isGallaryEnable", isGallaryEnable ? 1 : 0);
    formData.append("isCabProduct", isCabProduct ? 1 : 0);
    formData.append("isInfluencerProduct", isInfluencerProduct ? 1 : 0);
    // formData.append(`gallary`, gallaryObject?.gallary);
    galleryData &&
      galleryData.length &&
      galleryData.map((val, index) => {
        formData.append("gallary", val);
      });
    existingGalleryImages.forEach((val) => {
      formData.append("gallaryArray", val);
    });
    formData.append("gallary_video", gallaryObject?.gallary_video);
    formData.append("gallaryImage", gallaryObject?.gallaryImage);
    formData.append("gallaryVideoLink", gallaryObject?.videoLink);
    formData.append("country", countryName);
    if (userData?.user_type != "super_admin" && userData?.user_type != "Team Leader" && userData?.user_type != "Sales Executive") {
      formData.append("agentCode", agentCode || "");
    }
   
    // formData.append("teamleadId",teamleadId);        
    formData.append("bo_id", (!!formik.values.influencer_id && businessObjData?.theme_color == "theme53") ? formik.values.influencer_id : businessOwnerId);
    formData.append("pixel_id", pixel_id);
    formData.append("dynamicMainBackgroundColor", dynamicMainBackgroundColor);
    formData.append("dynamicBackgroundColor", dynamicBackgroundColor);
    formData.append("dynamicTextHeadingColor", dynamicTextHeadingColor);
    formData.append("dynamicTextDescriptionColor", dynamicTextDescriptionColor);
    formData.append("dynamicCtaColor", dynamicCtaColor);
    formData.append("metaTitle", metaTitle);
    formData.append("metaDescription", metaDescription);
    formData.append("canonicalTag", canonicalTag);
    formData.append("dynamicCtaTextColor", dynamicCtaTextColor);
    // HireMe content
    // formData.append("hireMe", isHireMe ? 1 : 0);
    console.log(formData,'ffffffffffffffff')
    axios
      .post(`${baseUrl}api/v1/business/updateDetails`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          _token: userData?.token,
        },
      }).then((res) => {
        handleNextStatus();
        if (res?.data?.success) {
          toast.success(t("Business_data_updated"), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setActiveTab("tab3");
          setIsSubmit(false);
        } else {
          toast.error(
            res?.data?.message?.message,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
        }
      })
      .catch((err) => {
        console.log(err, "error occurred");
        toast.error(
          err?.response?.data?.message?.message || err?.response?.data?.message,
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          }
        );
        setIsSubmit(false);
      });
    // } catch (err) {
    //   toast.error(err, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   return;
    // }

    const data = JSON.stringify({ id: teamleadId });
    const prevToken = "7d68e2c38dfb38785e7a"
    const token = btoa(prevToken);
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.crmDubaiApi}/api/LeadController/sinkBusinessBayLead`,
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      }).catch((error) => {
        console.log(error);
      });
  };
  const getCountryCode = async () => {
    const countryData = await getcoutryfromlocation();
    return countryData?.countryCode;
  }
  const formik = useFormik({
    initialValues: {
      shipCountry: "IN",
      influencer_id: "",
      contactInfoPhone: "",
      contactInfoEmail: "",
      optionalContactInfoPhone: "",
      contactInfoWebUrl: "",
      subTitle: businessObj?.sub_title || "",
      youtubeName: businessObj?.youtubeName || "",
      is_enabledHireMe: businessObj?.is_enabledHireMe || "",
      desiinstaNamegnation: businessObj?.instaName || "",
      designation: businessObj?.designation || "",
      description: businessObj?.description || "",
      googlereview: businessObj?.googlereview || "",
      googleaddress: businessObj?.googleaddress || "",
      is_allow_in_search: businessObj?.is_allow_in_search || 1,
      is_enabledPwa: businessObj?.is_enabledPwa || 1,
      influencer_profit: businessObj?.influencer_profit || "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const getBusinessDataBySlug = (slug) => {
    return new Promise((resolve, reject) => {
      try {
        if (!!slug) {
          axios
            .get(`${baseUrl}api/v1/business/fetchBusinessBySlug`, {
              params: { slug: slug },
              headers: {
                //   _token: userData?.token,
              },
              // withCredentials: true,
            })
            .then((res) => {
              resolve(res?.data?.data);
            })
            .catch((err) => {
              console.log({ msg: "err", err: err });
              resolve(false);
            });
        } else {
          resolve(false);
        }
      } catch (error) {
        console.error(error);
        resolve(false);
      }
    });
  };

  const handleSubmitWithoutTabChange = async (savetype = '') => {
    // try {
    setIsLoadingSubmit(true);
    // let link = "";
    // let rating = "";
    // let reviews = "";

    //   try {
    //   const responseFirstAPI = await axios.post(`${baseUrl}api/v1/business/findGoogleRating`, {
    //     businessName: [formik.values.googlereview]
    //   });

    //   // Check if the API call was successful and if it returned a rating
    //   if (responseFirstAPI.status === 200 && responseFirstAPI.data.rating) {
    //     rating = responseFirstAPI?.data?.rating;
    //     link = responseFirstAPI?.data?.placeId;
    //     reviews = responseFirstAPI?.data?.totalreview;
    //   } else {
    //     rating = null;
    //     link = null;
    //     reviews = null;
    //   }
    // } catch (error) {
    //   // Handle the case where the API call fails
    // }
    if (businessObjData?.theme_color !== 'theme12') {
      // Check if the banner is required and the banner image URL is missing
      if (isBannerRequired && !bannerImageUrl) {
        toast.error(t("Banner_validation"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsLoadingSubmit(false);
        return;
      }
    }

    // if (invalidPhoneErr) {
    //   toast.error(invalidPhoneErr, {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 1000,
    //   });
    //   setIsLoadingSubmit(false);

    //   return;
    // }

    // if (invalidOptPhoneErr) {
    //   toast.error(invalidOptPhoneErr, {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 1000,
    //   });
    //   setIsLoadingSubmit(false);
    //   return;
    // }
    // e.preventDefault();

    if (!formik.values.contactInfoEmail) {
      toast.error(t("Email_required"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsLoadingSubmit(false);
      return;

    }

    if (!formik.values.contactInfoAddress) {
      toast.error(t("Address_required"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsLoadingSubmit(false);
      return;
    }

    if (!isAddressVerified) {
      toast.error(t("Address_not_verified"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsLoadingSubmit(false);
      return;
    }

    if (!businessOwnerId && (userData.user_type === 'Team Leader' || userData.user_type === 'Sales Executive' || userData.user_type === 'super_admin')) {
      toast.error(t("Business_owner_required"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsLoadingSubmit(false);
      return;
    }

    // if(!agentCode && (userData.user_type === 'Team Leader' || userData.user_type === 'Sales Executive' || userData.user_type === 'super_admin')){
    //   toast.error("Agent Code is required.", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 1000,
    //   });
    //   setIsLoadingSubmit(false);
    //   return;
    // }

    // if(!teamleadId && (userData.user_type === 'Team Leader' || userData.user_type === 'Sales Executive')){
    //   toast.error("teamlead Id is required.", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 1000,
    //   });
    //   setIsLoadingSubmit(false);
    //   return;
    // }

    if (!formik.isValid) {
      if (formik?.errors?.subTitle) {
        toast.error(formik?.errors?.subTitle, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.designation) {
        toast.error(formik?.errors?.designation, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.description) {
        toast.error(formik?.errors?.description, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.contactInfoEmail) {
        toast.error(formik?.errors?.contactInfoEmail, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.contactInfoWebUrl) {
        toast.error(formik?.errors?.contactInfoWebUrl, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.contactInfoPhone) {
        toast.error(formik?.errors?.contactInfoPhone, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.optionalContactInfoPhone) {
        toast.error(formik?.errors?.optionalContactInfoPhone, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
      setIsLoadingSubmit(false)
      return;
    }

    // setting field validation

    if (businessObjData?.theme_color === 'theme14' ||
      businessObjData?.theme_color === 'theme22' ||
      businessObjData?.theme_color === 'theme20') {
    } else {
      if (businessObjData?.theme_color === 'theme12') {
        if (!isEnabledGoogleReview || !rating || rating.length === 0) {
          toast.error(t("Google_review_required"), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsLoadingSubmit(false);
          return;
        }
      } else {
        if (isEnabledGoogleReview && (!rating || rating.length === 0)) {
          toast.error(t("Google_details"), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsLoadingSubmit(false);
          return;
        }
      }
    }

    if (
      businessObjData?.theme_color === 'theme12' ||
      businessObjData?.theme_color === 'theme17' ||
      businessObjData?.theme_color === 'theme20' ||
      businessObjData?.theme_color === 'theme22'
    ) {
    } else {
      if (isEnabledServices) {
        if (!servicesArray || servicesArray.length === 0) {
          toast.error(t("Service_required"), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsLoadingSubmit(false);
          return;
        }

        const hasEmptyName = servicesArray.some(service => !service.name?.trim());
        if (hasEmptyName) {
          toast.error(t("Service_name_mandatory"), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsLoadingSubmit(false);
          return;
        }
      }
    }

    if (businessObjData?.theme_color === 'theme20') {
      if (isEnabledServices) {
        if (!servicesArray || servicesArray.length === 0) {
          toast.error(t("Category_required"), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsLoadingSubmit(false);
          return;
        }
      }
    }


    if (businessObjData?.theme_color === 'theme22') {
      if (isCabProduct) {
        if (!isCabProduct || cabArray.length === 0) {
          toast.error(t("Cab_product_required"), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsLoadingSubmit(false);
          return;
        }
      }
    }

    if (
      businessObjData?.theme_color === 'theme12' ||
      businessObjData?.theme_color === 'theme14' ||
      businessObjData?.theme_color === 'theme15' ||
      businessObjData?.theme_color === 'theme16' ||
      businessObjData?.theme_color === 'theme20' ||
      businessObjData?.theme_color === 'theme22'
    ) {
    } else {
      if (isTestimonial && (!testimonialArray || testimonialArray.length === 0)) {
        toast.error(t("Testimonial_required"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsLoadingSubmit(false);
        return;
      }
    }

    if (isAlternateUrl && (!alternateUrlArray || alternateUrlArray.length === 0)) {
      toast.error(t("Url_required"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsLoadingSubmit(false);
      return;
    }

    if (isSocial && (!socialMediaData || socialMediaData.length === 0)) {
      toast.error(t("Social_media_required"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsLoadingSubmit(false)
      return;
    }

    if (
      businessObjData?.theme_color === 'theme22' ||
      businessObjData?.theme_color === 'theme12' ||
      businessObjData?.theme_color === 'theme20') {
    } else {
      if (IsBusinessHourObject) {
        const {
          isMonday, isTuesday, isWednesDay,
          isfridayDay, issaturdayDay,
          issundayDay, isthrusdayDay
        } = businessHourObject;

        const allDaysFalse = !isMonday && !isTuesday && !isWednesDay && !isfridayDay && !issaturdayDay && !issundayDay && !isthrusdayDay;

        if (allDaysFalse) {
          toast.error(t("Business_hour"), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsLoadingSubmit(false);
          return;
        }
      }
    }

    if (
      businessObjData?.theme_color === 'theme22' ||
      businessObjData?.theme_color === 'theme17' ||
      businessObjData?.theme_color === 'theme20') {
    } else {
      if (isGallaryEnable && (!galleryData || galleryData.length === 0)) {
        toast.error(t("Portfolio_required"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsLoadingSubmit(false);
        return;
      }
    }
    let titleData = title.trim();
    if (titleData == '' || titleData == null) {
      toast.error(t("Company_name_required"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmit(false);
      return;
    }

    const formData = new FormData();

    let currencyData = (!!formik.values.shipCountry) ? getCurrencyData(formik.values.shipCountry) : "₹";
    formData.append("description_Heading", descriptionHeading);
    formData.append("gallery_Heading", galleryHeading);
    formData.append("appointment_Heading", appointmentsHeading);
    formData.append("businesshour_Heading", businessHourHeading);
    formData.append("social_Heading", socialHeading);
    formData.append("alternateUrls_Heading", alternateUrlsHeading);
    formData.append("contactinfo_Heading", contactHeading);
    formData.append("googlereview_Heading",reviewHeading);
    formData.append("contactform_Heading",contactformHeading);
    formData.append("service_Heading",serviceHeading);
    formData.append("testimonials_Heading",testimonialHeading);
    formData.append("Cab_products_Heading",cabProductHeading);
    formData.append("TermsandConditions_Heading",termConditionHeading);
    formData.append("Menu_Heading",menuHeading);
    formData.append("Order_Heading",orderHeading);
    formData.append("banner", bannerData);
    formData.append("logo", logoData);
    formData.append("reviewlink", link);
    formData.append("rating", rating);
    formData.append("review", reviews);
    formData.append("businessId", businessId);
    formData.append("title", title);
    formData.append("isDine", isDine);
    formData.append("isShowBusinessName", isShowBusinessName);
    formData.append("isShowAddress", isShowAddress);
    formData.append("designation", formik.values.designation);
    formData.append("instaName", formik.values.instaName);
    formData.append("youtubeName", formik.values.youtubeName);
    formData.append("hireMe", formik.values.is_enabledHireMe);
    formData.append("googlereview", formik.values.googlereview);
    formData.append("googleaddress", formik.values.googleaddress);
    formData.append("sub_title", formik.values.subTitle);
    formData.append("description", formik.values.description);
    formData.append("slug", slug);
    formData.append("is_allow_in_search", formik.values.is_allow_in_search);
    formData.append("is_enabledPwa", formik.values.is_enabledPwa);
    formData.append("influencer_profit", selectedPercentage)
    formData.append("shipCountry", formik.values.shipCountry);
    formData.append("currency_symbol", currencyData);
    formData.append("influencer_id", formik.values.influencer_id);
    let formCount = 0;
    formData.append(
      "is_enabledContactInfo",
      isEnabledContactInfo == true ? 1 : 0
    );
    formData.append(
      "isEnabledGoogleReview",
      isEnabledGoogleReview == true ? 1 : 0
    );
    let latData = addressLat.toString();
    let lngData = addressLng.toString();
    let addressPlaceIdData = addressPlaceId.toString();
    let contactOptionallength = formik.values.optionalContactInfoPhone?.split("-")?.[1]?.length;

    let content = {
      contactInfoPhone: formik.values.contactInfoPhone,
      optionalContactInfoPhone: (contactOptionallength > 0) ? formik.values.optionalContactInfoPhone : '',
      contactInfoEmail: formik.values.contactInfoEmail,
      contactInfoWebUrl: formik.values.contactInfoWebUrl,
      contactInfoAddress,
      contactInfoOptAddress,
      addressLat: latData,
      addressLng: lngData,
      addressPlaceId: addressPlaceIdData,
    };

    if (isEnabledContactInfo == true) {
      formData.append("content", JSON.stringify(content));
    }

    formData.append(
      "is_contact_form_enabled",
      isEnabledContactForm == true ? 1 : 0
    );
    formData.append("is_order_enabled", isEnabledOrders == true ? 1 : 0);
    formData.append("is_services", isEnabledServices ? 1 : 0);
    if (servicesArray && servicesArray.length) {
      servicesArray.forEach((file, index) => {
        if (file?.banner && file?.banner["0"].name?.length) {
          formData.append(`servicesArray`, file?.banner["0"]);
        } else if (file && file.image) {
          formData.append(`servicesImages[${index}]`, file?.image);
        }
        formData.append(`serviceId[${index}]`, file?.id)
        formData.append(`serviceDescription[${index}]`, file?.description);
        formData.append(`serviceName[${index}]`, file?.name);
        formData.append(`serviceButtonLink[${index}]`, file?.buttonLink);
        formData.append(`serviceButtonName[${index}]`, file?.buttonName);
      });
    }



    formData.append("is_keyword", isEnabledKeyword ? 1 : 0);
    if (keywordArray && keywordArray.length) {
      keywordArray.forEach((file, index) => {
        formData.append(`keywordId[${index}]`, file?.id)
        formData.append(`keywordName[${index}]`, file?.name);

      });
    }


    //testimonial array

    formData.append("is_testimonial", isTestimonial ? 1 : 0);
    if (testimonialArray && testimonialArray.length) {
      testimonialArray.forEach((file, index) => {
        if (
          file &&
          file.image &&
          file.image["0"] &&
          file.image["0"].name?.length
        ) {
          formData.append(`testimonialArray`, file?.image["0"]);
        } else {
          formData.append(`testimonialImageArray[${index}]`, file?.image);
        }
        formData.append(`testimonialDescription[${index}]`, file?.description);
        formData.append(`testimonialName[${index}]`, file?.name);
        formData.append(`testimonialFormCount[${index}]`, index);
      });
    }

    // alternate url
    formData.append("is_alternateurl", isAlternateUrl ? 1 : 0);
    if (alternateUrlArray && alternateUrlArray.length) {
      alternateUrlArray.forEach((file, index) => {
        if (
          file &&
          file.image &&
          file.image["0"] &&
          file.image["0"].name?.length
        ) {
          formData.append(`alternateurlArray`, file?.image["0"]);
        } else {
          formData.append(`alternateImageArray[${index}]`, file?.image);
        }
        formData.append(`alternateurlDescription[${index}]`, file?.description);
        formData.append(`alternateurlName[${index}]`, file?.name);
        formData.append(`alternateUrlFormCount[${index}]`, index);
      });
    }

    //restaurantmenuarray

    formData.append("is_menu", isRestaurant ? 1 : 0);
    console.log(restaurantArray,"restaurantArray===>")
    if (restaurantArray && restaurantArray.length) {
      restaurantArray.forEach((file, index) => {
        if (
          file &&
          file.image &&
          file.image["0"] &&
          file.image["0"].name?.length
        ) {
          formData.append(`restaurantArray`, file?.image["0"]);
        } else {
          formData.append(`restaurantImageArray[${index}]`, file?.image);
        }
        formData.append(`dishCategory[${index}]`, file?.category);
        formData.append(`dishName[${index}]`, file?.name);
        formData.append(`restaurantFormCount[${index}]`, index);
        if (Array.isArray(file?.price) && Array.isArray(file?.quantity)) {
          formData.append(`dishPrice[${index}]`, file.price.join(',')); 
          formData.append(`dishQuantity[${index}]`, file.quantity.join(','));
        } else {
          formData.append(`dishPrice[${index}]`, JSON.stringify([]));
          formData.append(`dishQuantity[${index}]`, JSON.stringify([]));
        }
      });
    }

    // mini market place products
    let dataAllArr = [...minimarketArray]
    // dataAllArr = dataAllArr?.filter((e) => e.price);
    formData.append("is_mini_mktproduct", isMinimktProduct ? 1 : 0);
    if (dataAllArr && dataAllArr.length) {
      dataAllArr.forEach((file, index) => {
        if (
          file &&
          file.image &&
          file.image["0"] &&
          file.image["0"].name?.length
        ) {
          formData.append(`mini_mktproduct`, file?.image["0"]);
        } else {
          formData.append(`restaurantImageArray[${index}]`, file?.image);
        }
        formData.append(`minimktproductPrice[${index}]`, (!!file.price) ? file.price : 0);
        formData.append(`minimktproductQuantity[${index}]`, (!!file.quantity) ? file.quantity : 0);
        formData.append(`minimktproductName[${index}]`, (!!file?.name) ? file?.name : "");
        formData.append(`minimktproductCategory[${index}]`, file?.category);
        formData.append(`minimktproductservice[${index}]`, file?.service);
        formData.append(`minimktproductDescription[${index}]`, (!!file?.description) ? file?.description : "")
        formData.append(`restaurantFormCount[${index}]`, index);
      });
    }


    formData.append("is_business_hours_enabled", IsBusinessHourObject ? 1 : 0);
    formData.append("businesshoursOBject", JSON.stringify(businessHourObject));

    //appointment array
    formData.append("appointmentContent", JSON.stringify(appointmentArray));
    formData.append("is_appointment", isAppointment ? 1 : 0);

    //social media
    formData.append("is_social", isSocial ? 1 : 0);
    formData.append("isSocalData", JSON.stringify(socialMediaData));

    //terms and conditions data
    formData.append("is_tnc", isTermsConditions ? 1 : 0);
    formData.append("tncData", JSON.stringify(termsConditionData))

    formData.append("isCabProduct", isCabProduct ? 1 : 0);
    formData.append("isInfluencerProduct", isInfluencerProduct ? 1 : 0);

    // gallary
    formData.append("isGallaryEnable", isGallaryEnable ? 1 : 0);
    // formData.append(`gallary`, gallaryObject?.gallary);
    galleryData &&
      galleryData.length &&
      galleryData.map((val, index) => {
        formData.append("gallary", val);
      });
    existingGalleryImages.forEach((val) => {
      formData.append("gallaryArray", val);
    });
    formData.append("gallary_video", gallaryObject?.gallary_video);
    formData.append("gallaryImage", gallaryObject?.gallaryImage);
    formData.append("gallaryVideoLink", gallaryObject?.videoLink);
    formData.append("country", countryName);
    if (userData?.user_type != "super_admin" && userData?.user_type != "Team Leader" && userData?.user_type != "Sales Executive") {
      formData.append("agentCode", agentCode || "");
    }
    // formData.append("teamleadId",teamleadId || "");
    formData.append("bo_id", (!!formik.values.influencer_id && businessObjData?.theme_color == "theme53") ? formik.values.influencer_id : businessOwnerId);
    formData.append("pixel_id", pixel_id);
    formData.append("dynamicMainBackgroundColor", dynamicMainBackgroundColor);
    formData.append("dynamicBackgroundColor", dynamicBackgroundColor);
    formData.append("dynamicTextHeadingColor", dynamicTextHeadingColor);
    formData.append("dynamicTextDescriptionColor", dynamicTextDescriptionColor);
    formData.append("dynamicCtaColor", dynamicCtaColor);
    formData.append("dynamicCtaTextColor", dynamicCtaTextColor);
    // formData.append("hireMe", isHireMe ? 1 : 0);
    formData.append("metaTitle", metaTitle);
    formData.append("metaDescription", metaDescription);
    formData.append("canonicalTag", canonicalTag);
    console.log(formData,'ffffffffffffffff')
    axios
      .post(`${baseUrl}api/v1/business/updateDetails`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          _token: userData?.token,
        },
      })
      .then(async (res) => {

        if (res?.data?.success) {
          let businessData = await getBusinessDataBySlug(
            businessObj?.slug ? businessObj?.slug : ""
          );
          // setMiniMarketArray([])
          if (savetype != 'productadd') {

            toast.success(t("Business_data_updated"), {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });

            let redirectUrl =
              businessData?.slug_subdomain != null
                ? httpProtocol + businessData?.slug_subdomain
                : businessObj?.slug
                  ? "/" + businessObj?.slug
                  : "/";

            window.open(`${redirectUrl}`, "_blank");
          }

          // window.open(
          //   `http://${businessData?.slug_subdomain}`,
          //   "_blank"
          // );
        } else {
          toast.error(
            res?.data?.message?.message,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
        }
        setIsLoadingSubmit(false);
      })
      .catch((err) => {
        console.log(err, "error occurred");
        toast.error(err?.response?.data?.message?.message || err?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsLoadingSubmit(false);
      });
    // } catch (err) {
    //   toast.error(err, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   return;
    // }
  };
  const handleImageClickBanner = () => {
    fileInputRefBanner.current.click();
  };

  const handleImageClickLogo = () => {
    fileInputRefLogo.current.click();
  };

  const handlePreviousClick = () => {
    if (activeTab === "tab2") {
      setActiveTab("tab1");
    } else if (activeTab === "tab3") {
      setActiveTab("tab2");
    }
  };

  const handleChangeCompanyName = (e) => {
    // const alphanumericWithSpaceRegex = /^[a-zA-Z0-9 ,.?\/()&@$‘’'"_\-\\\"\n\r!#$%&\(\)\-\+\[\]\{\};:'“”,.~@\/?]*$/;
    const allowedCharsRegex = /^[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF\u064B-\u065Fa-zA-Z0-9 ,.?\/()&@$'''"_\-\\\"\n\r!#%&\(\)\+\[\]\{\};:'"",.~@\/?]*$/;
    if (
      e.target.value === "" || allowedCharsRegex.test(e.target.value)
    ) {
      setTitle(e.target.value);
    }
  };

  const handledescriptionHeading = (e) => {
    const allowedCharsRegex = /^[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF\u064B-\u065Fa-zA-Z0-9 ,.?\/()&@$'''"_\-\\\"\n\r!#%&\(\)\+\[\]\{\};:'"",.~@\/?]*$/;
    if (
      e.target.value === "" || allowedCharsRegex.test(e.target.value)
    ) {
      setDescriptionHeading(e.target.value);
    }
  };

  const handleChangePixelId = (e) => {
    // const alphanumericWithSpaceRegex = /^[a-zA-Z0-9 ,.?\/()&@$‘’'"_\-\\\"\n\r!#$%&\(\)\-\+\[\]\{\};:'“”,.~@\/?]*$/;
    // if (
    //   e.target.value === "" ||
    //   alphanumericWithSpaceRegex.test(e.target.value)
    // ) {
    setPixelId(e.target.value);
    // }
  };

  const handleChangeMetaTitle = (e) => {
    const value = e.target.value;
    if (value.length <= 65) {
      setMetaTitle(value);
    }
  };

  const handleChangeMetades = (e) => {
    const value = e.target.value;
    if (value.length <= 165) {
      setMetaDescription(value);
    }
  };

  const handleChangeCanicaltag = (e) => {
    setCanonicalTag(e.target.value);
  };

  const [src, setSrc] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [slideValue, setSlideValue] = useState(10);
  const cropRef = useRef(null);
  const inputRef = useRef(null);

  const handleSave = async () => {
    const dataUrl = cropRef.current.getImage().toDataURL();
    setLogoImageUrl(dataUrl);
    setModalOpen(false);

    // Convert data URL to Blob
    const byteString = atob(dataUrl.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: "image/png" }); // Adjust the MIME type accordingly
    // Create a File object from the Blob
    const file = new File([blob], "filename.png", { type: "image/png" }); // Adjust the file name and type accordingly
    setLogoData(file);
  };
  const boxStyle = {};
  const modalStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#000000b3",
  };
  const handleIsDine = (e) => {
    let isDineData = e.target.checked ? 1 : 0;
    setIsDine(isDineData);
  }
  const handleIsShow = (e) => {
    let isShowData = e.target.checked ? 1 : 0;
    setIsShowBusinessName(isShowData);
  }
  const handleIsShowAddress = (e) => {
    let isAddData = e.target.checked ? 1 : 0;
    setIsShowAddress(isAddData);
  }
  useEffect(() => {
    if (onState.restaurantmenu) {
      setOnState((prevState) => ({ ...prevState, orders: true }));
      setIsEnabledOrders(true);
    } else {
      setOnState((prevState) => ({ ...prevState, orders: false }));
      setIsEnabledOrders(false);
    }
  }, [onState.restaurantmenu]);
  const [contactInfoAddressError, setContactInfoAddressError] = useState("");
  const [contactInfoOptAddressError, setContactInfoOptAddressError] = useState("");
  const handleContactInfoAddressChange = (e) => {
    const value = e.target.value;
    console.log("value===>>>", value);

    // Pattern to match characters not allowed (excluding newline \n)
    const specialCharPattern = /[^\p{L}\p{N}\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF\u064B-\u065F ,.?\/()&@$'’"_\-\n\r–%:]/u;
    console.log("specialCharPattern===>>>", specialCharPattern.test(value));
    if (specialCharPattern.test(value)) {
      setContactInfoAddressError('Special characters are not allowed.');
    } else {
      setContactInfoAddressError('');
    }
    // Regular expression to allow only letters, numbers, spaces, and newline
    // const filteredValue = value.replace(/[^a-zA-Z0-9 ,.?\/()&@$'’"_\-\n\r–%:]/g, '');
    const filteredValue = value.replace(/[^\p{L}\d ,.?\/()&@$'’"_\-\n\r–%:]/gu, '');

    setContactInfoAddress(filteredValue);
    formik.setFieldValue("contactInfoAddress", filteredValue);
    setAddressPlaceId("");
    setTimeout(() => {
      setContactInfoAddressError('');
    }, 5000)
  };

  const handleContactInfoOptAddressChange = (e) => {
    const value = e.target.value;
    // Pattern to match characters not allowed (excluding newline \n)
    const specialCharPattern = /[^\p{L}\p{N}\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF\u064B-\u065F ,.?\/()&@$'’"_\-\n\r–%:]/u;
    if (specialCharPattern.test(value)) {
      setContactInfoOptAddressError('Special characters are not allowed.');
    } else {
      setContactInfoOptAddressError('');
    }
    // Regular expression to allow only letters, numbers, spaces, and newline
    // const filteredValue = value.replace(/[^a-zA-Z0-9 ,.?\/()&@$'’"_\-\n\r–%:]/g, '');
    const filteredValue = value.replace(/[^\p{L}\d ,.?\/()&@$'’"_\-\n\r–%:]/gu, '');

    setContactInfoOptAddress(filteredValue);
    setTimeout(() => {
      setContactInfoOptAddressError('');
    }, 5000)
  };


  // Function to get country data by dial code
  const getCountryByDialCode = (dialCode) => {
    return countryData[dialCode] || null;
  };
  const [formErrors, setFormErrors] = React.useState({});

  const handleBlur22 = (value, phoneData) => {
    const extractPhoneNumber = (phone) => {
      if (phone.includes('-')) {
        let val = phone.split("-");
        return (val.length > 1) ? val[1] : "";
      } else {
        return phone.startsWith(`+${phoneData.dialCode}`) ? phone.slice(phoneData.dialCode.length + 1) : phone;
      }
    };

    const extractCountryCode = (phone) => {
      if (phone.startsWith('+')) {
        phone = phone.replace('+', '');

        if (phone.includes('-')) {
          return phone.split('-')[0];
        }
        return phone.substring(0, 2) === "91" ? phone.substring(0, 2) : phone.substring(0, 3);
      }
      return null;
    };

    let contactNumber = formik.values.contactInfoPhone;
    let optionalContactNumber = formik.values.optionalContactInfoPhone;

    if (!!contactNumber) {
      const contactDialCode = extractCountryCode(contactNumber);
      const phoneDataForContact = getCountryByDialCode(contactDialCode);

      let valArray = extractPhoneNumber(contactNumber);
      if (valArray === '') {
        setInvalidPhoneErr(t("Please_enter_number"));
      } else if (!isValidPhoneNumber(valArray, phoneDataForContact)) {
        // setInvalidPhoneErr(`Invalid phone number for ${phoneDataForContact?.name || 'unknown country'}`);
      } else {
        setInvalidPhoneErr('');
      }
    }

    // Handle validation for optionalContactNumber
    if (!!optionalContactNumber) {
      const optionalDialCode = extractCountryCode(optionalContactNumber);
      const phoneDataForOptional = getCountryByDialCode(optionalDialCode);

      let valArray = extractPhoneNumber(optionalContactNumber);
      if (valArray === '') {
        setInvalidOptPhoneErr(``);
      } else if (!isValidPhoneNumber(valArray, phoneDataForOptional)) {
        // setInvalidOptPhoneErr(`Invalid phone number for ${phoneDataForOptional?.name || 'unknown country'}`);
      } else {
        setInvalidOptPhoneErr('');
      }
    }
  };

  const handleBlur = (value, phoneData) => {
    phoneData.iso2 = phoneData.countryCode;
    let contactNumber = formik.values.contactInfoPhone;

    if (!!contactNumber) {
      let val = contactNumber.split("-");
      // let valArray = (val.length>0)?val[1]:"";
      let valArray = (val.length > 0) ? val.slice(1).join('') : "";
      let valueData = (valArray) ? valArray.replace("-", "") : "";

      if (valueData == '') {
        setInvalidPhoneErr(t("Please_enter_number"));
      } else if (!isValidPhoneNumber(valueData, phoneData)) {
        // setInvalidPhoneErr(`Invalid phone number for ${phoneData.name}`);
      } else {
        setInvalidPhoneErr('');
      }
    }
  };
  const handleBlurOptional = (value, phoneData) => {
    phoneData.iso2 = phoneData.countryCode;
    let contactNumber = formik.values.optionalContactInfoPhone;

    if (!!contactNumber) {
      let val = contactNumber.split("-");
      // let valArray = (val.length>0)?val[1]:"";
      let valArray = (val.length > 0) ? val.slice(1).join('') : "";
      let valueData = (valArray) ? valArray.replace("-", "") : "";

      if (valueData == '') {
        setInvalidOptPhoneErr("");
      } else if (!isValidPhoneNumber(valueData, phoneData)) {
        // setInvalidOptPhoneErr(`Invalid phone number for ${phoneData.name}`);
      } else {
        setInvalidOptPhoneErr('');
      }
    }
  };

  const handleGetLatLang = () => {
    setIsAddressSubmit(true);
    setLatLngError("");
    let addressData = title + ' ' + contactInfoAddress;
    let data = JSON.stringify({
      "address": addressData
    });

    let config = {
      method: 'post',
      url: `${baseUrl}api/v1/business/getLatLng`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        setIsAddressSubmit(false);
        if (response.data) {
          setAddressPlaceId(response?.data?.placeId);
          setAddressLng(response?.data?.longitude);
          setAddressLat(response?.data?.latitude);
        }
      })
      .catch((error) => {
        setIsAddressSubmit(false);
        setAddressPlaceId("");
        setAddressLng("");
        setAddressLat("");
        setLatLngError(t("Unable_to_fetch_coordinates"));
        console.log(error, "error");
      });
  }

  // const getLogoSizeLabel = (themeColor) => {
  //   switch (themeColor) {
  //     case "theme1":
  //      return `(${t("Size")}: 350 x 200)`;
  //     case "theme2":
  //      return `(${t("Size")}: 350 x 200)`;
  //     case "theme3":
  //      return `(${t("Size")}: 350 x 200)`;
  //     case "theme4":
  //      return `(${t("Size")}: 350 x 200)`;
  //     case "theme5":
  //      return `(${t("Size")}: 350 x 200)`;
  //     case "theme6":
  //      return `(${t("Size")}: 350 x 200)`;
  //     case "theme7":
  //      return `(${t("Size")}: 420 x 300)`;
  //     case "theme8":
  //      return `(${t("Size")}: 350 x 200)`;
  //     case "theme9":
  //      return `(${t("Size")}: 350 x 200)`;
  //     case "theme10":
  //      return `(${t("Size")}: 350 x 200)`;
  //     case "theme11":
  //       return "(Size : 400 x 900)";
  //     case "theme12":
  //      return `(${t("Size")}: 350 x 200)`;
  //     case "theme13":
  //      return `(${t("Size")}: 350 x 200)`;
  //     case "theme14":
  //       return "(Size : 400 x 200)";
  //     case "theme15":
  //       return "(Size : 350 x 450)";
  //     case "theme16":
  //       return "(Size : 400 x 550)";
  //     case "theme18":
  //       return "(Size : 350 x 450)";
  //     case "theme20":
  //       return "(Size : 400 x 160)";
  //     case "theme41":
  //       return "(Size : 350 x 450)";
  //     case "theme44":
  //       return "(Size : 328 x 480)";
  //     case "theme48":
  //       return "(Size : 350 x 450)";
  //     case "theme35":
  //         return "(Size : 420 x 500)";
  //     case "theme53":
  //         return "(Size : 430 x 540)";



  //     default:
  //       return "(Size : 400 x 200)";
  //   }
  // };


  const getLogoSizeLabel = (themeColor) => {
    switch (themeColor) {
      case "theme1":
        return `(${t("Size")}: 350 x 200)`;
      case "theme2":
        return `(${t("Size")}: 350 x 200)`;
      case "theme3":
        return `(${t("Size")}: 400 x 300)`;
      case "theme4":
        return `(${t("Size")}: 350 x 200)`;
      case "theme5":
        return `(${t("Size")}: 350 x 200)`;
      case "theme6":
        return `(${t("Size")}: 420 x 300)`;
      case "theme7":
        return `(${t("Size")}: 420 x 300)`;
      case "theme8":
        return `(${t("Size")}: 350 x 200)`;
      case "theme9":
        return `(${t("Size")}: 420 x 300)`;
      case "theme10":
        return `(${t("Size")}: 350 x 200)`;
      case "theme11":
        return `(${t("Size")}: 1153 x 500)`;
      case "theme12":
        return `(${t("Size")}: 350 x 200)`;
      case "theme13":
        return `(${t("Size")}: 350 x 200)`;
      case "theme14":
        return `(${t("Size")}: 520 x 320)`;
      case "theme15":
        return `(${t("Size")}: 328 x 480)`;
      case "theme16":
        return `(${t("Size")}: 240 x 350)`;
      case "theme18":
        return `(${t("Size")}: 344 x 420)`;
      case "theme20":
        return `(${t("Size")}: 400 x 160)`;
      case "theme41":
        return `(${t("Size")}: 344 x 450)`;
      case "theme44":
        return `(${t("Size")}: 328 x 480)`;
      case "theme48":
        return `(${t("Size")}: 344 x 450)`;
      case "theme35":
        return `(${t("Size")}: 420 x 500)`;
      case "theme53":
        return `(${t("Size")}: 420 x 540)`;
      case "theme43":
        return `(${t("Size")}: 400 x 420)`;
      case "theme46":
        return `(${t("Size")}: 400 x 420)`;
      case "theme38":
        return `(${t("Size")}: 420 x 275)`;
      case "theme29":
        return `(${t("Size")}: 328 x 480)`;
      case "theme42":
        return `(${t("Size")}: 400 x 300)`;
      case "theme28":
        return `(${t("Size")}: 400 x 420)`;
      case "theme36":
        return `(${t("Size")}: 400 x 420)`;
      case "theme34":
        return `(${t("Size")}: 400 x 420)`;
      case "theme32":
        return `(${t("Size")}: 400 x 200)`;
      case "theme33":
        return `(${t("Size")}: 420 x 300)`;
      case "theme52":
        return `(${t("Size")}: 328 x 480)`;
      case "theme40":
        return `(${t("Size")}: 328 x 480)`;
      case "theme51":
        return `(${t("Size")}: 420 x 500)`;
      case "theme22":
        return `(${t("Size")}: 520 x 220)`;
      default:
        return `(${t("Size")}: 400 x 200)`;
    }
  };

  const handleFetchedInfluencerData = (data) => {
    fetchProductsByBusinessId();
  };

  const fetchinfluencerpercentage = (percentage) => {
    setSelectedPercentage(percentage);
    console.log("Selected Percentage in business detials:", percentage);
  };

  const ProductHandlerdelete = (id, bid) => {

    let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/influencer/deleteInfluencerProduct?id=${id}&bid=${bid}`,
      headers: {
        "Content-Type": "multipart/form-data",
        _token: userData?.token,
      },
    };

    axios.request(config)
      .then((response) => {
        fetchProductsByBusinessId();
        toast.success(t("Product_deleted_successfully"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        })
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const selectedCurrency = Array.isArray(currencyData) && currencyData?.length > 0
    ? currencyData.find(currency => currency.country_iso === formik.values.shipCountry)
    : null;

  const currencyName = selectedCurrency ? selectedCurrency.symbol : "₹";

  //Business owner dropdown logic
  // function getBusinessOwnerList(){
  //   let idType;
  //   if (userData.user_type === "Team Leader") {
  //     idType ="Team_Leader"
  //   }
  //   if (userData.user_type === "Staff") {

  //     idType = "Staff"
  //   }
  //   if (userData.user_type === "super_admin") {

  //     idType =  "super_admin"
  //   }

  //   const url = `${baseUrl}api/v1/user/getTlbusowmer?${idType}=${userData.id}`;

  //   axios.get(url).then(response => { 
  //     setBusinessOwnerList(response?.data?.data?.users);
  //   })
  // }

  const [selectBusinessOwnerId, setSelectBusinessOwnerId] = useState("");

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    let idType;
    if (userData.user_type === "Team Leader") {
      idType = "Team_Leader";
    } else if (userData.user_type === "Sales Executive") {
      idType = "Staff";
    } else if (userData.user_type === "super_admin") {
      idType = "super_admin";
    }

    const url = `${baseUrl}api/v1/user/getTlbusowmer?${idType}=${userData.id}&page=${page}&limit=10&search=${searchQuery}`;

    try {
      const response = await axios.get(url);
      const data = response?.data?.data?.users;
      const hasMore = response?.data?.data?.pagination?.currentPage < response?.data?.data?.pagination?.totalPages;

      return {
        options: data.map((user) => ({
          label: user.name,
          value: user.id,
        })),
        hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Error fetching business owners:", error);
      return { options: [], hasMore: false };
    }
  };

  useEffect(() => {
    // You can fetch initial data if needed, or just rely on AsyncPaginate's dynamic loading
  }, [userData]);





  // useEffect(() => {
  //   getBusinessOwnerList();
  //   if (businessObjData?.bo_id === businessObjData?.created_by) {
  //     setBusinessOwnerId(businessObjData?.bo_id);
  //   }
  // }, []);

  const fetchCountryfromlocation = async () => {
    try {
      const countryData = await getcoutryfromlocation();
      setCountryCode(countryData?.countryCode.toLowerCase());
      formik.setFieldValue("shipCountry", countryData?.countryCode);
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
  };

  useEffect(() => {
    fetchCountryfromlocation()
  }, []);

  const handleChangeComplete = (color) => {
    setDynamicBackgroundColor(color.hex);
  };

  const HeadingcolorhandleChangeComplete = (color) => {
    setDynamicTextHeadingColor(color.hex);
  };

  const DescriptioncolorhandleChangeComplete = (color) => {
    setDynamicTextDescriptionColor(color.hex);
  };

  const mainBghandleChangeComplete = (color) => {
    setDynamicMainBackgroundColor(color.hex);
  };

  const CtacolorhandleChangeComplete = (color) => {
    setdynamicCtaColor(color.hex);
  };
  const ctaTextcolorHandler = (color) => {
    setdynamicCtaTextColor(color.hex);
  };


  // Set default color in color picker
  useEffect(() => {
    businessObj?.dynamicMainBackgroundColor ? setDynamicMainBackgroundColor(businessObj?.dynamicMainBackgroundColor) : null;
    businessObj?.dynamicBackgroundColor ? setDynamicBackgroundColor(businessObj?.dynamicBackgroundColor) : null;
    businessObj?.dynamicTextHeadingColor ? setDynamicTextHeadingColor(businessObj?.dynamicTextHeadingColor) : null;
    businessObj?.dynamicTextDescriptionColor ? setDynamicTextDescriptionColor(businessObj?.dynamicTextDescriptionColor) : null;
    businessObj?.dynamicCtaColor ? setdynamicCtaColor(businessObj?.dynamicCtaColor) : null;
    businessObj?.dynamicCtaTextColor ? setdynamicCtaTextColor(businessObj?.dynamicCtaTextColor) : null;
    return () => {
      setDynamicBackgroundColor("");
      setDynamicTextHeadingColor("");
      setDynamicTextDescriptionColor("");
      setdynamicCtaColor("");
      setDynamicMainBackgroundColor("");
      setdynamicCtaTextColor("");
    }
  }, [businessObj]);


  return (
    <>
      <div className="ChangeBlock_Sequenceview_Wrapper">
        <div className="row ChangeBlock_Sequenceview_Container">
          <div className="col-12 col-lg-8 ChangeBlock_Container ChangeblockContainerpd">
            {themeNumber == 12 ? (
              <div className="tile">
                <form onSubmit={formik.handleSubmit} className="row">
                  <div className="d-flex justify-content-between mb-4 col-md-12 AddDetailsBanner_logoWrapper">
                    {isBannerShow(businessObj?.theme_color) ? (
                      <div class="mb-4 col-md-8">
                        {/* <div className="boxbanner DetailsSelectBanner_wrapper">
                        <label className="form-label">Select Banner</label>
                        <div className="bannerbox">
                          <img
                            src={
                              bannerImageUrl
                                ? bannerImageUrl?.includes("base64")
                                  ? bannerImageUrl
                                  : bannerImageUrl == "null"
                                  ? ""
                                  : `${baseUrl}uploads/${bannerImageUrl}`
                                : bannerimage
                              // : ""
                            }
                            className="mx-auto d-block img-fluid"
                            onClick={(e) => handleImageClickBanner(e)}
                          ></img>
                         
                        </div>
                        <input
                          className="form-control d-none"
                          type="file"
                          name="banner" // Set the name to match the Multer field name
                          onChange={handleBannerChange}
                          ref={fileInputRefBanner}
                        />
                      </div> */}
                      </div>
                    ) : (
                      <></>
                    )}
                    {isLogoShow(businessObj?.theme_color) ? (
                      <div className="mb-3 col-md-4">
                        <div className="boxbanner detailsdptextlogowrapper">
                          <label className="form-label detailsdptext">
                            {t("Select_logo")}
                          </label>
                          <div className="bannerbox2 AdddetailsdpContainer">
                            <img
                              src={
                                logoImageUrl
                                  ? logoImageUrl?.includes("base64")
                                    ? logoImageUrl
                                    : logoImageUrl == "null"
                                      ? ""
                                      : `${environmentVariables?.s3UrlBackendUrl}${logoImageUrl}`
                                  : avatar
                              }
                              className="mx-auto d-block"
                              onClick={handleImageClickLogo}
                            />
                          </div>
                          {/* <p>
                      <strong>Size : (150 x 150)</strong>
                    </p> */}
                          <input
                            className="form-control d-none"
                            type="file"
                            name="logo" // Set the name to match the Multer field name
                            onChange={handleLogoChange}
                            ref={fileInputRefLogo}
                            accept=".png, .jpg, .jpeg"
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="AddDetailsNameDestiCom_Wrapper">
                    <div className="mb-3 col-md-4 AddDetailswidContainer">
                      <label className="form-label">{t("Name")}*</label>
                      <input
                        // value={subTitle}
                        className="form-control"
                        type="text"
                        placeholder={`${t("Name")}`}
                        // onChange={(e) => setSubTitle(e.target.value)}
                        {...formik.getFieldProps("subTitle")}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.subTitle && formik.errors.subTitle && (
                        <ErrorStyle className="error">
                          {formik.errors.subTitle}
                        </ErrorStyle>
                      )}
                    </div>

                    <div className="mb-3 col-md-4 AddDetailswidContainer AddDetailsMarContainer">
                      <label className="form-label">{t("Designation")}* </label>
                      <input
                        // value={designation}
                        {...formik.getFieldProps("designation")}
                        className="form-control"
                        type="text"
                        placeholder={`${t("Designation")}`}
                      // onChange={(e) => setDesignation(e.target.value)}
                      />
                      {formik.touched.designation &&
                        formik.errors.designation && (
                          <ErrorStyle className="error">
                            {formik.errors.designation}
                          </ErrorStyle>
                        )}
                    </div>

                    <div className="mb-3 col-md-4 AddDetailswidContainer">
                      <label className="form-label">{t("Company_name")}*</label>
                      <input
                        value={title}
                        className="form-control"
                        type="text"
                        placeholder={`${t("Company_name")}`}
                        // onChange={(e) => setTitle(e.target.value)}
                        onChange={(e) => handleChangeCompanyName(e)}
                      />
                    </div>
                  </div>



                  <div className="mb-3 col-md-12">
                  <div  className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative', alignItems:"center" }}>
                          {
                          editableHeading === 'description' ?
                            (<input
                              value={descriptionHeading}
                              className="form-control"
                              type="text"
                              placeholder={`${t("descriptionheading")}`}
                              onChange={(e) => handledescriptionHeading(e)}
                              style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                            />) : ( <label className="form-label">{descriptionHeading ? descriptionHeading : t("Description")}*</label>)                      
                          }
                          <button type="button"
                                  style={{fontSize : "12px", marginBottom:"0.5rem" }}
                                  onClick={(e) => {
                                    handleHeadingEdit('description');
                                  }} >
                                  {editableHeading === 'description' ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}
                          </button>
                  </div>
                    <textarea
                      {...formik.getFieldProps("description")}
                      className="form-control"
                      rows="4"
                      placeholder={`${t("Description")}`}
                    ></textarea>
                    {formik.touched.description &&
                      formik.errors.description && (
                        <ErrorStyle className="error">
                          {formik.errors.description}
                        </ErrorStyle>
                      )}
                  </div>

                  {/* <div className="mb-3 col-md-12">
                    <label className="form-label">{t("Description")}*</label>
                    <input
                      value={descriptionHeading}
                      className="form-control"
                      type="text"
                      placeholder={`${t("descriptionheading")}`}
                      onChange={(e) => handledescriptionHeading(e)}
                    />
                    <textarea
                      {...formik.getFieldProps("description")}
                      className="form-control"
                      rows="4"
                      placeholder={`${t("Description")}`}
                    >
                    </textarea>
                    {formik.touched.description &&
                      formik.errors.description && (
                        <ErrorStyle className="error">
                          {formik.errors.description}
                        </ErrorStyle>
                      )}
                  </div> */}


                      {/* Hi, I'm a graphic designer ! */}

                  {/* <div className="mb-3 col-md-12">
                    <label className="form-label">{t("Description")}*</label>
                    <input
                      value={descriptionHeading}
                      className="form-control"
                      type="text"
                      placeholder={`${t("descriptionheading")}`}
                      onChange={(e) => handledescriptionHeading(e)}
                    />
                    <textarea
                      {...formik.getFieldProps("description")}
                      className="form-control"
                      rows="4"
                      placeholder={`${t("Description")}`}
                    > */}
                      {/* Hi, I'm a graphic designer ! */}
                    {/* </textarea>
                    {formik.touched.description &&
                      formik.errors.description && (
                        <ErrorStyle className="error">
                          {formik.errors.description}
                        </ErrorStyle>
                  </div>
                      )} */}

                  {/* {(userData.user_type === 'Team Leader' || userData.user_type === 'Sales Executive' || userData.user_type === 'super_admin') && (
                    <div className="mb-3 col-md-12">
                      <label className="form-label" style={{ fontSize: "17px" }}>
                        Business Owner
                      </label>
                      <div className="form-control">
                        <select
                          style={{ width: '100%' }}
                          value={businessOwnerId}
                          onChange={(e) => setBusinessOwnerId(e.target.value)}
                          disabled={businessObjData?.bo_id === businessObjData?.created_by}
                        >
                          <option value="">Select Business Owner</option>
                          {businessOwnerList?.map((ele) => (
                            <option key={ele.id} value={ele.id}>
                              {ele.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )} */}

                  {(userData.user_type === "Team Leader" ||
                    userData.user_type === "Sales Executive" ||
                    userData.user_type === "super_admin") && (
                      <div className="mb-3 col-md-12">
                        <label className="form-label" style={{ fontSize: "17px" }}>
                          {t("Business_owner")}
                        </label>
                        <div className="form-control" style={{position: "relative", zIndex: "100"}}>
                          <AsyncPaginate
                            className="asyncPaginate"
                            // style={{ zIndex: '990 !important' }}
                            value={selectBusinessOwnerId} // Controlled by state
                            loadOptions={loadOptions}
                            onChange={(selectedOption) => {
                              setSelectBusinessOwnerId(selectedOption);
                              setBusinessOwnerId(selectedOption?.value);
                              setbusinessOwnerName(businessObj?.Owner_name);
                            }}
                            additional={{
                              page: 1,
                            }}
                            placeholder={businessObj?.Owner_name === 'Super Admin' ? t("Select_business_owner") : businessObj?.Owner_name || t("Select_business_owner")}

                            isDisabled={userData.user_type !== "super_admin" && (businessObjData?.bo_id === businessObjData?.created_by)}
                          />
                        </div>
                      </div>
                    )}

                  {/* <div
                    className="accordion-body AddDetailsContactInfoInnerForm"
                    style={{ padding: "0 20px" }}
                  >
                    <div className="d-flex">
                      <h5
                        style={{
                          fontWeight: "400",
                          padding: "10px 0",
                          fontWeight: "600",
                          fontSize: "16px",
                        }}
                        border-0
                      >
                        Google Review
                      </h5>
                    </div>
                    <div className="row MobileDetailsWrapper">
                      <div className="mb-3 col-md-12 MobilePhoneFieldWrapper">
                        <input
                          {...formik.getFieldProps("googlereview")}
                          className="form-control"
                          type="text"
                          placeholder="Domain Name"
                        />
                        {formik.touched.googlereview &&
                          formik.errors.googlereview && (
                            <ErrorStyle className="error">
                              {formik.errors.googlereview}
                            </ErrorStyle>
                          )}
                      </div>
                    </div>
                  </div> */}


                  <label className="form-label" style={{ fontSize: "17px" }}>
                    {t("Settings")}
                  </label>
                  <div className="accordion" id="accordionExample">

                    <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
  <div className="d-flex" style={{ gap: "8px", alignItems: "center", width: 'fit-content', zIndex: '99', position: 'relative' }}>
    {editableHeading === 'contactInfo' ? (
      <input
        type="text"
        value={contactHeading}
        onChange={(e) => setcontactHeading(e.target.value)}
        placeholder={t("Contact_info_heading")}
        style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize: '14px', borderRadius: '4px', fontWeight: '400' }}
      />
    ) : (
      <h5 style={{ fontWeight: "400" }} border-0>
        {contactHeading ? contactHeading : t("Contact_info")}
      </h5>
    )}
    <button
      type="button"
      style={{ fontSize: "12px" }}
      onClick={() => handleHeadingEdit('contactInfo')}
    >
      {editableHeading === 'contactInfo' ? (
        <i className="fa-solid fa-check" style={{ background: 'green', borderRadius: '4px', padding: '5px', color: '#fff' }}></i>
      ) : (
        <i className="fas fa-edit"></i>
      )}
    </button>
  </div>

  <div className="form-check form-switch">
    <label className="form-check-label">
      {t("On_Off")}
    </label>
    <input
      onClick={() => {
        setIsEnabledContactInfo(!isEnabledContactInfo);
        setOnState({
          ...onState,
          contactInfo: !onState.contactInfo,
        });
      }}
      className="form-check-input"
      type="checkbox"
      role="switch"
      id="flexSwitchCheckDefault"
      onChange={() => toggleDiv("div1")}
      checked={onState?.contactInfo}
    />
  </div>
  <button
    type="button"
    className="accordion-button collapsed accordianCustom"
    data-bs-toggle="collapse"
    data-bs-target="#collapseOne"
    aria-expanded="false"
    aria-controls="collapseOne"
  >
  </button>
</h2>

                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse "
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body AddDetailsContactInfoInnerForm">
                          {/* {divStates.div1 && ( */}
                          <div className="row MobileDetailsWrapper">
                            <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                              <label className="form-label">
                                {t("Mobile_number")}
                              </label>

                              <PhoneInput
                                country={countryCode}
                                enableSearch={true}
                                countryCodeEditable={false}
                                value={formik.values.contactInfoPhone || `+${countryCode}`}
                                onChange={(value, country) => {
                                  if (country.dialCode) {
                                    formik.setFieldValue(
                                      "contactInfoPhone",
                                      `+${country.dialCode}-${value.substring(
                                        country.dialCode.length
                                      )}`
                                    );
                                    setCountryName(country.name);
                                  } else {
                                    formik.setFieldValue(
                                      "contactInfoPhone",
                                      value
                                    );
                                  }
                                }}
                                // onBlur={formik.handleBlur("contactInfoPhone")}
                                onBlur={(e, country) => handleBlur(e, country) && formik.handleBlur("contactInfoPhone")}
                                // {...formik.getFieldProps('contactInfoPhone')}
                                className="DetailsInfoPhoneInput"
                                isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
                              />
                              {formik.touched.contactInfoPhone &&
                                formik.errors.contactInfoPhone && (
                                  <ErrorStyle className="error">
                                    {formik.errors.contactInfoPhone}
                                  </ErrorStyle>
                                )}
                              {invalidPhoneErr && <ErrorStyle className="error">
                                {invalidPhoneErr}
                              </ErrorStyle>}
                            </div>
                            <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                              <label className="form-label">
                                {t("Alternative_mobile_number")}
                              </label>
                              <PhoneInput
                                country={countryCode}
                                enableSearch={true}
                                countryCodeEditable={false}
                                value={formik.values.optionalContactInfoPhone || `+${countryCode}`}
                                onChange={(value, country) => {
                                  if (country.dialCode) {
                                    formik.setFieldValue(
                                      "optionalContactInfoPhone",
                                      `+${country.dialCode}-${value.substring(
                                        country.dialCode.length
                                      )}`
                                    );
                                    setCountryName(country.name);
                                  } else {
                                    formik.setFieldValue(
                                      "optionalContactInfoPhone",
                                      value
                                    );
                                  }
                                }}
                                // onBlur={formik.handleBlur("optionalContactInfoPhone")}
                                onBlur={(e, country) => handleBlurOptional(e, country) && formik.handleBlur("optionalContactInfoPhone")}
                                // {...formik.getFieldProps('optionalContactInfoPhone')}
                                className="DetailsInfoPhoneInput"
                                isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
                              />
                              {formik.touched.optionalContactInfoPhone &&
                                formik.errors.optionalContactInfoPhone && (
                                  <ErrorStyle className="error">
                                    {formik.errors.optionalContactInfoPhone}
                                  </ErrorStyle>
                                )}
                              {invalidOptPhoneErr && <ErrorStyle className="error">
                                {invalidOptPhoneErr}
                              </ErrorStyle>}
                            </div>

                            <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                              <label className="form-label">{t("Email")}*</label>
                              <input
                                {...formik.getFieldProps("contactInfoEmail")}
                                // value={contactInfoEmail}
                                // value={formik.values.contactInfoEmail}
                                className="form-control"
                                type="text"
                                placeholder={`${t("Email")}`}
                              // onChange={(e) =>
                              //   setContactInfoEmail(e.target.value)
                              // }
                              // onChange={formik.handleChange('contactInfoEmail')}
                              />
                              {formik.touched.contactInfoEmail &&
                                formik.errors.contactInfoEmail && (
                                  <ErrorStyle className="error">
                                    {formik.errors.contactInfoEmail}
                                  </ErrorStyle>
                                )}
                            </div>

                            <div className="mb-3 col-md-12">
                              <label className="form-label">{t("Address")}*</label>
                              <textarea
                                value={contactInfoAddress}
                                className="form-control"
                                rows="3"
                                maxLength={250}
                                placeholder={`${t("Address")}`}
                                onChange={(e) =>
                                  handleContactInfoAddressChange(e)
                                }
                                // onBlur={handleGetLatLang}
                                disabled={isAddressDisabled}
                              ></textarea>
                              {contactInfoAddressError && <p className="error-mgs text-danger">{contactInfoAddressError}</p>}
                              {formik.errors.contactInfoAddress && (
                                <ErrorStyle className="error">
                                  {formik.errors.contactInfoAddress}
                                </ErrorStyle>
                              )}
                              <a className="btn btn-info-detail" onClick={handleGetLatLang}> {t("Submit")}</a>
                              {latLngError && <p className="text-danger mt-2" style={{ fontSize: "12px" }}>{latLngError}</p>}
                              {isAddressSubmit ? (
                                <CircularLoader size={40} />
                              ) : (
                                addressPlaceId && formik.values.contactInfoAddress && (
                                  <div className="">
                                    <div className="">
                                      <a
                                        href={`https://www.google.com/maps/place/?q=place_id:${addressPlaceId}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn btn-link"
                                        style={{ fontSize: "14px", color: "#00b721", fontWeight: "500", padding: "10px 0px" }}
                                      >
                                        {t("Business_address_verify")}
                                      </a>
                                    </div>
                                    <div className="">
                                      {isAddressVerified ? (
                                        <>
                                          <button className="btn btn-success me-2 selectreviewverified" disabled>
                                            {t("Address_verified")}
                                          </button>
                                          <button className="selectreviewedit" onClick={handleIncorrectAddressData}>
                                            {t("Edit")}
                                          </button>
                                        </>
                                      ) : (
                                        <button className="btn btn-success me-2 selectreview 
                                          verifybusinesswtd" onClick={handleCorrectAddressData}>
                                          {t("Verify_address")}
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                            <div className="mb-3 col-md-12">
                              <label className="form-label">{t("Alternative_address")}</label>
                              <textarea
                                value={contactInfoOptAddress}
                                className="form-control"
                                rows="3"
                                maxLength={250}
                                placeholder={`${t("Address")}`}
                                onChange={(e) =>
                                  handleContactInfoOptAddressChange(e)
                                }
                              ></textarea>
                              {contactInfoOptAddressError && <p className="error-mgs text-danger">{contactInfoOptAddressError}</p>}
                            </div>
                          </div>
                          {/* )} */}
                        </div>

                      </div>
                    </div>
                    <div className="accordion-item">
                      {/* <h2 className="accordion-header" id="headingZero">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseZero"
                          aria-expanded="false"
                          aria-controls="collapseZero"
                          onClick={(e) => { }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }} border-0>
                              {t("Google_review")}
                            </h5>
                          </div>
                        </button>
                        <input
             type="text"
             value={reviewHeading}
             onChange={(e) => setreviewHeading(e.target.value)}
             placeholder="Enter Google review"
             style={{ marginRight: "10px", padding: "5px",border:"1px solid green" }}
           />
                        <div className="form-check form-switch">
                          <label className="form-check-label">{t("On_Off")}</label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            onChange={() => toggleDiv("div8")}
                            checked={onState?.googlereview}
                            onClick={(e) => {
                              setIsEnabledGoogleReview(!isEnabledGoogleReview);
                              setOnState({
                                ...onState,
                                googlereview: !onState.googlereview,
                              });
                            }}
                          />

                        </div>
                      </h2> */}

<h2 className="accordion-header" id="headingZero">
                            <div className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative' }}>
                            {editableHeading === 'googleReview' ? (
                                    <input
                                      type="text"
                                      value={reviewHeading}
                                      onClick={(e) => e.stopPropagation()}
                                      onChange={(e) => setreviewHeading(e.target.value)}
                                      placeholder={t("Enter_google_review_heading")}
                                      style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                                      />
                                  ) : (
                                    <h5 style={{ fontWeight: "400" }} border-0>
                                      {reviewHeading ? reviewHeading : t("Google_review")}
                                    </h5>
                                  )}
                                  <button
                                    type="button"
                                    style={{fontSize : "12px" }}
                                    onClick={(e) => {
                                      handleHeadingEdit('googleReview');
                                    }}
                                  >
                                    {editableHeading === 'googleReview' ?  <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}
                                  </button>
                                 
                                </div>
                              <div className="form-check form-switch">
                                <label className="form-check-label">{t("On_Off")}</label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  onChange={() => toggleDiv("div8")}
                                  checked={onState?.googlereview}
                                  onClick={(e) => {
                                    setIsEnabledGoogleReview(!isEnabledGoogleReview);
                                    setOnState({
                                      ...onState,
                                      googlereview: !onState.googlereview,
                                    });
                                  }}
                                />

                              </div>
                              <button
                              type="button"
                              className="accordion-button collapsed  accordianCustom"

                              data-bs-toggle="collapse"
                              data-bs-target="#collapseZero"
                              aria-expanded="false"
                              aria-controls="collapseZero"

                            >

                            </button>
                            </h2>




                      <div
                        id="collapseZero"
                        className="accordion-collapse collapse "
                        aria-labelledby="headingZero"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body AddDetailsContactInfoInnerForm">
                          <div className="row MobileDetailsWrapper">
                            <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                              <input
                                {...formik.getFieldProps("googlereview")}
                                className="form-control"
                                type="text"
                                placeholder={`${t("Business_name")}`}
                                onBlur={handleBlurReview}
                                disabled={isVerified}
                              />
                              {formik.touched.googlereview && formik.errors.googlereview && (
                                <ErrorStyle className="error">
                                  {formik.errors.googlereview}
                                </ErrorStyle>
                              )}
                            </div>
                            <div className="mb-3 col-md-3 MobilePhoneFieldWrapper">
                              <input
                                {...formik.getFieldProps("googleaddress")}
                                className="form-control"
                                type="text"
                                placeholder={`${t("City_name")}`}
                                onChange={handleGoogleAddressChange}
                                onBlur={handleBlurReview}
                                disabled={isVerified}
                              />
                              {formik.touched.googleaddress && formik.errors.googleaddress && (
                                <ErrorStyle className="error">
                                  {formik.errors.googleaddress}
                                </ErrorStyle>
                              )}
                            </div>
                            <div className="mb-3 col-md-3 MobilePhoneFieldWrapper">
                              <a className="btn selectreviewverifiedbtn" onClick={fetchGoogleRating}>
                                {t("Submit")}
                              </a>
                            </div>
                          </div>

                          {isAddSubmit ? (
                            <CircularLoader size={40} />
                          ) : (
                            tempLink && (
                              <>
                                <div className="">
                                  <a
                                    href={`https://www.google.com/maps/place/?q=place_id:${tempLink}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-link"
                                    style={{ fontSize: "14px", color: "#00b721", fontWeight: "500", padding: "10px 0px" }}
                                  >
                                    {t("verify_business")}
                                  </a>
                                </div>
                                <div className="">
                                  {isVerified ? (
                                    <>
                                      <button className="btn btn-success me-2 selectreviewverified verifybusinesswtd" disabled>
                                        {t("Business_verified")}
                                      </button>
                                      <button className="selectreviewedit verifybusinesswtd" onClick={handleIncorrectData}>
                                        {t("Edit")}
                                      </button>
                                    </>
                                  ) : (
                                    <button className="btn btn-success me-2 selectreview verifybusinesswtd" onClick={handleCorrectData}>
                                      {t("Business_verify")}
                                    </button>
                                  )}
                                </div>
                              </>
                            )
                          )}
                        </div>
                      </div>
                    </div>

                    {/* ============== Alternate Urls of (google review)======================== */}

                    <div className="accordion-item">
                      {/* <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#ten"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={() => {
                          }}
                        >
                          <div className="d-flex" style={{ alignItems: "center", gap: "8px" }}>
                            <h5 style={{ fontWeight: "400" }}>{t("Alternate_urls")}</h5>
                          </div>
                        </button>
                        <input
                          type="text"
                          value={alternateUrlsHeading}
                          onChange={(e) => seAlternateUrlsHeading(e.target.value)}
                          placeholder="Alternate Urls Heading"
                          style={{ marginRight: "10px", padding: "5px", border: "1px solid green" }}
                        />
                        <div className="form-check form-switch">
                          <label
                            className="form-check-label"
                          >
                            {t("On_Off")}
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={onState?.alternateurl}
                            onClick={() => {
                              setAlternateUrl(!isAlternateUrl);
                              setOnState({
                                ...onState,
                                alternateurl: !onState.alternateurl,
                              });
                            }}
                            onChange={() => toggleDiv("div10")}
                          />
                        </div>
                      </h2> */}

                          <h2 className="accordion-header" id="headingThree">
                          <div className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative' }}>
                                {editableHeading === 'alternateUrl' ? (
                                  <input
                                    type="text"
                                    value={alternateUrlsHeading}
                                    onChange={(e) => seAlternateUrlsHeading(e.target.value)}
                                    placeholder={t("Alternate_urls_heading")}
                                    style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                                    />
                                ) : (
                                  <h5 style={{ fontWeight: "400" }} border-0>
                                    {alternateUrlsHeading ? alternateUrlsHeading : t("Alternate_urls")}
                                  </h5>
                                )}
                                <button
                                  type="button"
                                  style={{fontSize : "12px" }}
                                  onClick={(e) => {
                                    handleHeadingEdit('alternateUrl');
                                    e.stopPropagation();
                                  }}
                                >
                                  {editableHeading === 'alternateUrl'  ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}
                                </button>
                              </div>

                            <div className="form-check form-switch">
                              <label
                                className="form-check-label"
                              >
                                {t("On_Off")}
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                checked={onState?.alternateurl}
                                onClick={() => {
                                  setAlternateUrl(!isAlternateUrl);
                                  setOnState({
                                    ...onState,
                                    alternateurl: !onState.alternateurl,
                                  });
                                }}
                                onChange={() => toggleDiv("div10")}
                              />
                            </div>
                            <button
                              type="button"
                              className="accordion-button collapsed  accordianCustom"

                              data-bs-toggle="collapse"
                              data-bs-target="#ten"
                              aria-expanded="false"
                              aria-controls="ten"

                            >
                            </button>
                          </h2>


                      <div
                        id="ten"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body"
                          id="AddnewServicesformwrapper"
                        >
                          <div className="row">
                            <NewAlternateUrlCreate
                              setAlternateUrlArray={setAlternateUrlArray}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      {/* <h2 className="accordion-header" id="headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#five"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={() => {

                          }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}>{t("Social_media")}</h5>
                          </div>
                        </button>
                        <input
                          type="text"
                          value={socialHeading}
                          onChange={(e) => setSocialHeading(e.target.value)}
                          placeholder="social Heading"
                          style={{ marginRight: "10px", padding: "5px", border: "1px solid green" }}
                        />
                        <div className="form-check form-switch">
                          <label
                            className="form-check-label"

                          >
                            {t("On_Off")}
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={onState?.socials}
                            onChange={() => toggleDiv("div5")}
                            onClick={() => {
                              setIsSocial(!isSocial);
                              setOnState({
                                ...onState,
                                socials: !onState.socials,
                              });
                            }}
                          />
                        </div>
                      </h2> */}


                          <h2 className="accordion-header" id="headingFive">
                          <div className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative' }}>
                                {editableHeading === 'socialMedia' ? (
                                  <input
                                    type="text"
                                    value={socialHeading}
                                    onChange={(e) => setSocialHeading(e.target.value)}
                                    placeholder={t("Enter_Social_heading")}
                                    style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                                    />
                                ) : (
                                  <h5 style={{ fontWeight: "400" }} border-0>
                                    {socialHeading ? socialHeading : t("Social_media")}
                                  </h5>
                                )}
                                <button
                                  type="button"
                                  style={{fontSize : "12px" }}
                                  onClick={(e) => {
                                    handleHeadingEdit('socialMedia');
                                    e.stopPropagation();
                                  }}
                                >
                                  {editableHeading === 'socialMedia' ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}
                                </button>
                              </div>

                            <div className="form-check form-switch">
                              <label
                                className="form-check-label"
                              // for="flexSwitchCheckDefault"
                              >
                                {t("On_Off")}
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                checked={onState?.socials}
                                onChange={() => toggleDiv("div5")}
                                onClick={() => {
                                  setIsSocial(!isSocial);
                                  setOnState({
                                    ...onState,
                                    socials: !onState.socials,
                                  });
                                }}
                              />
                            </div>
                            <button
                              type="button"
                              className="accordion-button collapsed  accordianCustom"

                              data-bs-toggle="collapse"
                              data-bs-target="#five"
                              aria-expanded="false"
                              aria-controls="five"

                            >

                            </button>
                          </h2>


                      <div
                        id="five"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body"
                          id="AddnewServicesformwrapper"
                          style={{ padding: "15px 0" }}
                        >

                          <div
                            className="row"
                            style={{ justifyContent: "center" }}
                          >
                            <NewSocialmediaCreate
                              setSocialMediaData={setSocialMediaData}
                              socialMediaData={socialMediaData}
                            />
                          </div>

                        </div>
                      </div>
                    </div>

                    {/* <div className="accordion-item">
                      <div className="accordion-header" id="headingseven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#seven"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={(e) => {

                          }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}>{t("Portfoliovv")} </h5>
                          </div>
                        </button>
                        <input
                          type="text"
                          value={galleryHeading}
                          onChange={(e) => setGalleryHeading(e.target.value)}
                          placeholder="Portfolio Heading"
                          style={{ marginRight: "10px", padding: "5px", border: "1px solid green" }}
                        />
                        <div className="form-check form-switch">
                          <label
                            className="form-check-label"

                          >
                            {t("On_Off")}
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={onState?.gallery}
                            onClick={(e) => {
                              SetIsGallaryEnable(!isGallaryEnable);
                              setOnState({
                                ...onState,
                                gallery: !onState.gallery,
                              });
                            }}
                            onChange={() => toggleDiv("div7")}
                          />
                        </div>
                      </div>
                      <div
                        id="seven"
                        className={`accordion-collapse collapse`}
                        aria-labelledby="headingseven"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body"
                          id="AddnewGallaryformwrapper"
                        >
                          <div className="row">
                            <NewGallerycreate
                              businessId={businessId}
                              existingGalleryImages={existingGalleryImages}
                              setExistingGalleryImages={
                                setExistingGalleryImages
                              }
                              gallaryObject={gallaryObject}
                              galleryData={galleryData}
                              setGalleryData={setGalleryData}
                              galleryView={galleryView}
                              setGalleryView={setGalleryView}
                              setGallaryObject={setGallaryObject}
                              isGallaryEnable={isGallaryEnable}
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}



<div className="accordion-item">
                            <div className="accordion-header" id="headingseven">
                            <div className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative' }}>
                                  {editableHeading === 'gallery' ? (
                                    <input
                                      type="text"
                                      value={galleryHeading}
                                      onChange={(e) => setGalleryHeading(e.target.value)}
                                      placeholder={t("Enter_gallery_heading")}
                                      style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                                      />
                                  ) : (
                                    <h5 style={{ fontWeight: "400" }} border-0>
                                      {galleryHeading ? galleryHeading :  t("Portfolio")}
                                    </h5>
                                  )}
                                  <button
                                    type="button"
                                    style={{fontSize : "12px" }}
                                    onClick={(e) => {
                                      handleHeadingEdit('gallery');
                                      e.stopPropagation();
                                    }}
                                  >
                                    {editableHeading === 'gallery' ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}
                                  </button>
                                </div>

                              <div className="form-check form-switch">
                                <label
                                  className="form-check-label"
                                // for="flexSwitchCheckDefault"
                                >
                                  {t("On_Off")}
                                </label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  checked={onState?.gallery}
                                  onClick={(e) => {
                                    SetIsGallaryEnable(!isGallaryEnable);
                                    setOnState({
                                      ...onState,
                                      gallery: !onState.gallery,
                                    });
                                  }}
                                  onChange={() => toggleDiv("div7")}
                                />
                              </div>

                              <button
                              type="button"
                              className="accordion-button collapsed  accordianCustom"

                              data-bs-toggle="collapse"
                              data-bs-target="#seven"
                              aria-expanded="false"
                              aria-controls="seven"

                            >

                            </button>
                            </div>
                            <div
                              id="seven"
                              className={`accordion-collapse collapse`}
                              aria-labelledby="headingseven"
                              data-bs-parent="#accordionExample"
                            >
                              <div
                                className="accordion-body"
                                id="AddnewGallaryformwrapper"
                              >
                                <div className="row">
                                  <NewGallerycreate
                                    businessId={businessId}
                                    existingGalleryImages={existingGalleryImages}
                                    setExistingGalleryImages={
                                      setExistingGalleryImages
                                    }
                                    gallaryObject={gallaryObject}
                                    galleryData={galleryData}
                                    setGalleryData={setGalleryData}
                                    galleryView={galleryView}
                                    setGalleryView={setGalleryView}
                                    setGallaryObject={setGallaryObject}
                                    isGallaryEnable={isGallaryEnable}
                                  />
                                </div>
                              </div>
                            </div>
                    </div>

                    {/* =============Agent Code Google Review============ */}


                    {(userData.user_type === 'Sales Executive' || userData.user_type === 'Team Leader' || userData.user_type === 'super_admin') ? " " : <div className="accordion-item" style={{ padding: "10px 25px" }}>
                      <h2 className="accordion-header" id="headingEight">
                        <div className="d-flex justify-content-between" style={{ width: "100%" }}>
                          <h5 style={{ fontWeight: "400", display: "flex", alignItems: "center" }}>{t("Agent_code")} {t("Optional")}</h5>
                          <input
                            type="text"
                            className="form-control"
                            id="agentCode"
                            value={userData.user_type === 'Sales Executive' ? businessObj?.agentCode || agentCode : agentCode}
                            onChange={validateAgentCodeInput}
                            maxLength={6}
                            placeholder={`${t("Agent_code")}`}
                            style={{ width: "50%" }}
                            readOnly={!!businessObj?.agentCode && (userData.user_type === 'Sales Executive' || !userData.user_type)}
                          />
                        </div>
                        {isVerifyCodeSubmit ? (
                          <>
                            <CircularLoader size={40} />
                            {verifyCodeError && <p className="error-mgs text-danger">{verifyCodeError}</p>}
                          </>
                        ) : agentCode.length === 6 && isVerifyCodeVerified ? (
                          <p className="text-success mt-2">{t("Verified")}</p>
                        ) : agentCode.length === 6 && !isVerifyCodeVerified ? (
                          <p className="text-danger mt-2">{t("Not_verified")}</p>
                        ) : agentCode.length > 0 && agentCode.length < 6 ? (
                          <p className="text-danger mt-2">{t("agent_code_limit")}</p>
                        ) : null}
                      </h2>
                    </div>}
                    {(userData?.user_type == "super_admin" || userData?.user_type == "Team Leader" || userData?.user_type == "Sales Executive") &&
                      <div className="accordion-item" style={{ padding: "10px 25px" }}>
                        <h2 className="accordion-header" id="headingEight">
                          <div className="d-flex justify-content-between" style={{ width: "100%" }}>
                            <h5 style={{ fontWeight: "400", display: "flex", alignItems: "center" }}>{t("Lead_id")}</h5>
                            <input
                              type="text"
                              className="form-control"
                              id="teamlead"
                              value={teamleadId}
                              // value={userData.user_type === 'Sales Executive' ? businessObj?.teamleadId || teamleadId : teamleadId}
                              onChange={validateTeamLeadInput}
                              maxLength={6}
                              placeholder={`${t("Lead_id")}`}
                              style={{ width: "50%" }}
                            // readOnly={!!businessObj?.teamleadId && (userData.user_type === 'Sales Executive' || !userData.user_type)}
                            />
                          </div>
                        </h2>
                      </div>
                    }
                    {userData?.user_type == "super_admin" && (
                      <div className="accordion-item">
                        <div className="accordion-header" id="headingeight">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#eight"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <div className="d-flex">
                              <h5 style={{ fontWeight: "400" }}>{t("Meta_pixel_code")}</h5>
                            </div>
                          </button>

                        </div>
                        <div
                          id="eight"
                          className={`accordion-collapse collapse`}
                          aria-labelledby="headingeight"
                          data-bs-parent="#accordionExample"
                          style={{ paddingTop: "10px" }}
                        >
                          <div className="mb-3 col-md-12 AddDetailswidContainer">
                            <input
                              value={pixel_id}
                              className="form-control"
                              type="text"
                              placeholder={`${t("Meta_pixel_code")}`}
                              onChange={(e) => handleChangePixelId(e)}
                            />
                          </div>

                        </div>
                      </div>
                    )}


                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h4 className="accordion-header" id="headingTwo">
                          <button
                            style={{ fontWeight: "400", fontSize: "15.3px" }}
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseColorPicker"
                            aria-expanded="false"
                            aria-controls="collapseColorPicker"
                          >
                            {t("Theme_color_picker")}
                          </button>
                        </h4>
                        <div
                          id="collapseColorPicker"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="color-picker-container"  >
                              {/* Main Background Color Picker */}
                              <div className="color-picker-box">

                                <div className="color-picker-item">

                                  <p className="colorpicker-name"> {t("Main_background_color")}:</p>
                                  <div className="color-picker-info" style={{ background: dynamicMainBackgroundColor ? `${dynamicMainBackgroundColor}13` : "", border: dynamicMainBackgroundColor ? `1px solid ${dynamicMainBackgroundColor}20` : "" }}>


                                    <div className="color-picker-info-detail" ref={colorPickerContainerRef[0]}>
                                      <div className="color-box-container" style={{ borderColor: dynamicMainBackgroundColor, }}>
                                        <div className="color-box"
                                          onClick={() => toggleColorPicker(0)}
                                          style={{
                                            backgroundColor:
                                              dynamicMainBackgroundColor,
                                            borderColor: dynamicMainBackgroundColor,
                                          }}
                                        ></div>
                                        {isColorPickerOpen[0] && (<SketchPicker
                                          color={dynamicMainBackgroundColor}
                                          onChangeComplete={mainBghandleChangeComplete}
                                        />)}
                                      </div>

                                      <div>

                                        <span
                                          style={{ fontSize: '13px' }}
                                          className="hex-color-name"
                                        >
                                          {t("Hex")}: {dynamicMainBackgroundColor}
                                        </span>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      onClick={() =>
                                        setDynamicMainBackgroundColor("")
                                      }
                                      className="reset-button"
                                    >
                                      {t("Reset")}
                                    </button>
                                  </div>
                                </div>

                                {/* <div className="color-preview">
                                
                                
                              </div> */}
                              </div>

                              {/* Background Color Picker */}
                              <div className="color-picker-box">

                                <div className="color-picker-item">

                                  <p className="colorpicker-name" > {t("Background_color")}:</p>
                                  <div className="color-picker-info" style={{ background: dynamicBackgroundColor ? `${dynamicBackgroundColor}13` : "", border: dynamicBackgroundColor ? `1px solid ${dynamicBackgroundColor}20` : "" }}>


                                    <div className="color-picker-info-detail" ref={colorPickerContainerRef[1]}>
                                      <div className="color-box-container" style={{ borderColor: dynamicBackgroundColor }}>
                                        <div className="color-box"
                                          onClick={() => toggleColorPicker(1)}
                                          style={{
                                            backgroundColor:
                                              dynamicBackgroundColor,
                                            borderColor: dynamicBackgroundColor,
                                          }}
                                        ></div>
                                        {isColorPickerOpen[1] && (<SketchPicker
                                          color={dynamicBackgroundColor}
                                          onChangeComplete={handleChangeComplete}
                                        />)}
                                      </div>

                                      <div>

                                        <span
                                          style={{ fontSize: '13px' }} className="hex-color-name"
                                        >
                                          {t("Hex")}: {dynamicBackgroundColor}
                                        </span>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      onClick={() =>
                                        setDynamicBackgroundColor("")
                                      }
                                      className="reset-button"
                                    >
                                      {t("Reset")}
                                    </button>
                                  </div>
                                </div>
                              </div>

                              {/* Heading Color Picker */}
                              <div className="color-picker-box">

                                <div className="color-picker-item">

                                  <p className="colorpicker-name" > {t("Heading_color")}:</p>
                                  <div className="color-picker-info" style={{ background: dynamicTextHeadingColor ? `${dynamicTextHeadingColor}13` : "", border: dynamicTextHeadingColor ? `1px solid ${dynamicTextHeadingColor}20` : "" }}>


                                    <div className="color-picker-info-detail" ref={colorPickerContainerRef[2]}>
                                      <div className="color-box-container" style={{ borderColor: dynamicTextHeadingColor }}>
                                        <div className="color-box"
                                          onClick={() => toggleColorPicker(2)}
                                          style={{
                                            backgroundColor:
                                              dynamicTextHeadingColor,
                                            borderColor: dynamicTextHeadingColor,
                                          }}
                                        ></div>
                                        {isColorPickerOpen[2] && (<SketchPicker
                                          color={dynamicTextHeadingColor}
                                          onChangeComplete={
                                            HeadingcolorhandleChangeComplete
                                          }
                                        />)}
                                      </div>

                                      <div>

                                        <span
                                          style={{ fontSize: '13px' }} className="hex-color-name"
                                        >
                                          {t("Hex")}: {dynamicTextHeadingColor}
                                        </span>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      onClick={() =>
                                        setDynamicTextHeadingColor("")
                                      }
                                      className="reset-button"
                                    >
                                      {t("Reset")}
                                    </button>
                                  </div>
                                </div>
                              </div>

                              {/* Description Color Picker */}
                              <div className="color-picker-box">

                                <div className="color-picker-item">

                                  <p className="colorpicker-name"> {t("Description_color")}:</p>
                                  <div className="color-picker-info" style={{ background: dynamicTextDescriptionColor ? `${dynamicTextDescriptionColor}13` : "", border: dynamicTextDescriptionColor ? `1px solid ${dynamicTextDescriptionColor}20` : "" }}>


                                    <div className="color-picker-info-detail" ref={colorPickerContainerRef[3]}>
                                      <div className="color-box-container" style={{
                                        borderColor: dynamicTextDescriptionColor
                                      }}>
                                        <div className="color-box"
                                          onClick={() => toggleColorPicker(3)}
                                          style={{
                                            backgroundColor:
                                              dynamicTextDescriptionColor
                                            ,
                                            borderColor: dynamicTextDescriptionColor
                                            ,
                                          }}
                                        ></div>
                                        {isColorPickerOpen[3] && (<SketchPicker
                                          color={dynamicTextDescriptionColor}
                                          onChangeComplete={
                                            DescriptioncolorhandleChangeComplete
                                          }
                                        />)}
                                      </div>

                                      <div>

                                        <span
                                          style={{ fontSize: '13px' }} className="hex-color-name"
                                        >
                                          {t("Hex")}: {dynamicTextDescriptionColor
                                          }
                                        </span>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      onClick={() =>
                                        setDynamicTextDescriptionColor("")
                                      }
                                      className="reset-button"
                                    >
                                      {t("Reset")}
                                    </button>
                                  </div>
                                </div>

                              </div>

                              {/* CTA Color Picker */}
                              <div className="color-picker-box">

                                <div className="color-picker-item">

                                  <p className="colorpicker-name"> {t("Cta_color")}:</p>
                                  <div className="color-picker-info" style={{ background: dynamicCtaColor ? `${dynamicCtaColor}13` : "", border: dynamicCtaColor ? `1px solid ${dynamicCtaColor}20` : "" }}>


                                    <div className="color-picker-info-detail" ref={colorPickerContainerRef[4]}>
                                      <div className="color-box-container" style={{
                                        borderColor: dynamicCtaColor
                                      }}>
                                        <div className="color-box"
                                          onClick={() => toggleColorPicker(4)}
                                          style={{
                                            backgroundColor:
                                              dynamicCtaColor
                                            ,
                                            borderColor: dynamicCtaColor
                                            ,
                                          }}
                                        ></div>
                                        {isColorPickerOpen[4] && (<SketchPicker
                                          color={dynamicCtaColor}
                                          onChangeComplete={
                                            CtacolorhandleChangeComplete
                                          }
                                        />)}
                                      </div>

                                      <div>

                                        <span
                                          style={{ fontSize: '13px' }} className="hex-color-name"
                                        >
                                          {t("Hex")}: {dynamicCtaColor
                                          }
                                        </span>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      onClick={() =>
                                        setdynamicCtaColor("")
                                      }
                                      className="reset-button"
                                    >
                                      {t("Reset")}
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {/* CTA TeXt Color Picker */}
                              <div className="color-picker-box">

                                <div className="color-picker-item">

                                  <p className="colorpicker-name"> {t("Cta_text_color")}:</p>
                                  <div className="color-picker-info" style={{ background: dynamicCtaTextColor ? `${dynamicCtaTextColor}13` : "", border: dynamicCtaTextColor ? `1px solid ${dynamicCtaTextColor}20` : "" }}>


                                    <div className="color-picker-info-detail" ref={colorPickerContainerRef[5]}>
                                      <div className="color-box-container" style={{ borderColor: dynamicCtaTextColor, }}>
                                        <div className="color-box"
                                          onClick={() => toggleColorPicker(5)}
                                          style={{
                                            backgroundColor:
                                              dynamicCtaTextColor,
                                            borderColor: dynamicCtaTextColor,
                                          }}
                                        ></div>
                                        {isColorPickerOpen[5] && (<SketchPicker
                                          color={dynamicCtaTextColor}
                                          onChangeComplete={ctaTextcolorHandler}
                                        />)}
                                      </div>

                                      <div>

                                        <span
                                          style={{ fontSize: '13px' }}
                                          className="hex-color-name"
                                        >
                                          {t("Hex")}: {dynamicCtaTextColor}
                                        </span>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      onClick={() =>
                                        setdynamicCtaTextColor("")
                                      }
                                      className="reset-button"
                                    >
                                      {t("Reset")}
                                    </button>
                                  </div>
                                </div>

                                {/* <div className="color-preview">
                                
                                
                              </div> */}
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwofive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwofive"
                          aria-expanded="false"
                          aria-controls="collapseTwofive"
                          onClick={(e) => {
                          }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}> {t("Keywords_metatags")}</h5>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseTwofive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwofive"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body"
                          id="AddnewServicesformwrapper"
                        >
                          <div className="row">
                            <NewBusinessDetilsCreateKeywords
                              setKeywordArray={setKeywordArray}
                              businessObj={businessObj}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="headingnine">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#nine"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}>{t("Meta_title")}</h5>
                          </div>
                        </button>

                      </div>
                      <div
                        id="nine"
                        className={`accordion-collapse collapse`}
                        aria-labelledby="headingnine"
                        data-bs-parent="#accordionExample"
                        style={{ paddingTop: "10px" }}
                      >
                        <div className="mb-3 col-md-12 AddDetailswidContainer">
                          <input
                            value={metaTitle}
                            className="form-control"
                            type="text"
                            placeholder={`${t("Meta_title")}`}
                            onChange={(e) => handleChangeMetaTitle(e)}
                          />
                          {metaTitle.length >= 65 && (
                            <small style={{ color: "red" }}>
                              {t("Meta_titl_limit")}
                            </small>
                          )}
                        </div>

                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="headingeleven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#eleven"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}>{t("Meta_description")}</h5>
                          </div>
                        </button>

                      </div>
                      <div
                        id="eleven"
                        className={`accordion-collapse collapse`}
                        aria-labelledby="headingeleven"
                        data-bs-parent="#accordionExample"
                        style={{ paddingTop: "10px" }}
                      >
                        <div className="mb-3 col-md-12 AddDetailswidContainer">
                          <input
                            value={metaDescription}
                            className="form-control"
                            type="text"
                            placeholder={`${t("Meta_description")}`}
                            onChange={(e) => handleChangeMetades(e)}
                          />
                          {metaDescription.length >= 165 && (
                            <small style={{ color: "red" }}>
                              {t("Meta_desc_limit")}
                            </small>
                          )}
                        </div>

                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="headingtwelve">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#twelve"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}>{t("Canonical_tag")}</h5>
                          </div>
                        </button>

                      </div>
                      <div
                        id="twelve"
                        className={`accordion-collapse collapse`}
                        aria-labelledby="headingtwelve"
                        data-bs-parent="#accordionExample"
                        style={{ paddingTop: "10px" }}
                      >
                        <div className="mb-3 col-md-12 AddDetailswidContainer">
                          <input
                            value={canonicalTag}
                            className="form-control"
                            type="text"
                            placeholder={`${t("Canonical_tag")}`}
                            onChange={(e) => handleChangeCanicaltag(e)}
                          />
                        </div>

                      </div>
                    </div>

                  </div>

                  <div className="row Adddetails_saveprev_ButtonWrapper">
                    <div className="Changeblock_saveandpreview_buttonWrapper Changeblock_saveandpreview_buttonWrappertwo">
                      <button
                        className="btn btn-cu save-cta Changeblock_saveandpreview_button "
                        id="changeblockprenxtbtn"
                        type="button"
                        onClick={handlePreviousClick}
                      >
                        {t("Previous")}
                      </button>
                      {/* </div>
                  <div className="Changeblock_saveandpreview_buttonWrapper"> */}
                      {isSubmit ? (
                        <CircularLoader size={40} />
                      ) : (
                        <button
                          type="button"
                          className="btn btn-cu save-cta Changeblock_saveandpreview_button"
                          id="changeblockprenxtbtn"
                          onClick={(e) => handleSubmit(e)}
                        >
                          {t("Save_next")}
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            ) : themeNumber == 14 ? (
              ////////////////////////////    restaurant detIALS PAGE  START  ////////////////////////////////////

              <div className="tile">
                <form onSubmit={formik.handleSubmit} className="row">
                  <div className="flex items-center i-agree">
                    <div className="mr-2">
                      <label>{t("Marketplace_text")}</label>
                    </div>
                    <div className="mr-2">
                      <input
                        type="radio"
                        name="is_allow_in_search"
                        value={1}
                        id="yes"
                        checked={formik.values.is_allow_in_search == 1}
                        onChange={handleRadioChange}
                        onBlur={formik.handleBlur}
                      />
                      <label htmlFor="yes" className="mr-4">{t("Yes")}</label>
                    </div>
                    <div className="mr-2">
                      <input
                        type="radio"
                        name="is_allow_in_search"
                        value={0}
                        id="no"
                        checked={formik.values.is_allow_in_search == 0}
                        onChange={handleRadioChange}
                        onBlur={formik.handleBlur}
                      />
                      <label htmlFor="no">{t("No")}</label>
                    </div>

                  </div>
                  <div className="flex items-center i-agree">
                    <div className="mr-2 ">
                      <label>{t("Pwa_text")}</label>
                    </div>
                    <div className="mr-2">
                      <input
                        type="radio"
                        name="is_enabledPwa"
                        className="radiobtn"
                        value={1}
                        id="yess"
                        checked={formik.values.is_enabledPwa == 1}
                        onChange={handlePWARadioChange}
                        onBlur={formik.handleBlur}
                      />
                      <label htmlFor="yess" className="mr-4">{t("Yes")}</label>
                    </div>
                    <div className="mr-2">
                      <input
                        className="radiobtn"
                        type="radio"
                        name="is_enabledPwa"
                        value={0}
                        id="noo"
                        checked={formik.values.is_enabledPwa == 0}
                        onChange={handlePWARadioChange}
                        onBlur={formik.handleBlur}
                      />
                      <label htmlFor="noo">{t("No")}</label>
                    </div>

                  </div>
                  <div className="d-flex justify-content-between mb-4 col-md-12 AddDetailsBanner_logoWrapper">
                    {/* {isBannerShow(businessObj?.theme_color) ? ( */}
                    <div class="mb-4 col-md-8">
                      <div className="boxbanner DetailsSelectBanner_wrapper">
                        <label className="form-label detailsdptext">
                          {t("Select_banner")} <span style={{ fontSize: "12px" }}>{getLogoSizeLabel(businessObj?.theme_color)}</span>
                          <span className="text-danger">
                            {isBannerRequired ? " *" : " "}
                          </span>
                        </label>
                        <div className="bannerbox">
                          <img
                            src={
                              bannerImageUrl
                                ? bannerImageUrl?.includes("base64")
                                  ? bannerImageUrl
                                  : bannerImageUrl == "null"
                                    ? ""
                                    : `${environmentVariables?.s3UrlBackendUrl}${bannerImageUrl}`
                                : bannerimage
                              // : ""
                            }
                            className="mx-auto d-block img-fluid"
                            onClick={(e) => handleImageClickBanner(e)}
                          ></img>
                        </div>
                        <input
                          className="form-control d-none"
                          type="file"
                          name="banner" // Set the name to match the Multer field name
                          onChange={handleBannerChange}
                          ref={fileInputRefBanner}
                        />

                        <p className="imgserve">{t("Note_banner")}</p>
                      </div>
                    </div>

                    {/* {isLogoShow(businessObj?.theme_color) ? (
                  <div className="mb-3 col-md-4">
                    <div className="boxbanner detailsdptextlogowrapper">
                      <label className="form-label detailsdptext DetailsSelectLogo">
                       Select Logo (Size : 150 x 150)
                      </label>
                      <div className="bannerbox2 AdddetailsdpContainer">
                        <img
                          src={
                            logoImageUrl
                              ? logoImageUrl?.includes("base64")
                                ? logoImageUrl
                                : logoImageUrl == "null"
                                ? ""
                                : `${baseUrl}uploads/${logoImageUrl}`
                              : avatar
                          }
                          className="mx-auto d-block"
                          onClick={handleImageClickLogo}
                        />
                      </div>
                      
                      <input
                        className="form-control d-none"
                        type="file"
                        name="logo" // Set the name to match the Multer field name
                        onChange={handleLogoChange}
                        ref={fileInputRefLogo}
                        accept=".png, .jpg, .jpeg"
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )} */}
                  </div>

                  <div className="AddDetailsNameDestiCom_Wrapper">
                    {/* <div className="mb-3 col-md-4 AddDetailswidContainer">
                      <label className="form-label">Name*</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Name"
                        {...formik.getFieldProps("subTitle")}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.subTitle && formik.errors.subTitle && (
                        <ErrorStyle className="error">
                          {formik.errors.subTitle}
                        </ErrorStyle>
                      )}
                    </div>

                    <div className="mb-3 col-md-4 AddDetailswidContainer AddDetailsMarContainer">
                      <label className="form-label">Designation* </label>
                      <input
                        {...formik.getFieldProps("designation")}
                        className="form-control"
                        type="text"
                        placeholder="Designation"
                      />
                      {formik.touched.designation &&
                        formik.errors.designation && (
                          <ErrorStyle className="error">
                            {formik.errors.designation}
                          </ErrorStyle>
                        )}
                    </div> */}

                    <div className="mb-3 col-md-4 AddDetailswidContainer">
                      <label className="form-label">{t("Restaurant_name")} </label>
                      <input
                        value={title}
                        className="form-control"
                        type="text"
                        placeholder={`${t("Restaurant_name")}`}
                        onChange={(e) => handleChangeCompanyName(e)}

                      />
                    </div>
                    <div className="mb-3 col-md-4 AddDetailswidContainer dine_available">
                      <label className="form-label">
                        <input
                          type="checkbox"
                          checked={isShowBusinessName ? true : false}
                          onChange={(e) => handleIsShow(e)}
                        />
                        {t("Show_hide")}
                      </label>
                    </div>
                    <div className="mb-3 col-md-4 AddDetailswidContainer dine_available">
                      <label className="form-label">
                        <input type="checkbox" checked={isDine ? true : false} onChange={(e) => handleIsDine(e)} /> {`${t("Is_dine_in_available")}`}</label>
                    </div>
                  </div>


                  <div className="mb-3 col-md-12">
                  <div  className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative', alignItems:"center" }}>
                          {
                          editableHeading === 'description' ?
                            (<input
                              value={descriptionHeading}
                              className="form-control"
                              type="text"
                              placeholder={`${t("descriptionheading")}`}
                              onChange={(e) => handledescriptionHeading(e)}
                              autoFocus
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  handleHeadingEdit('description');
                                  e.preventDefault();
                                }
                              }}
                              style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                            />) : ( <label className="form-label">{descriptionHeading ? descriptionHeading : t("Description")}*</label>)                      
                          }
                          <button type="button"
                                  style={{fontSize : "12px", marginBottom:"0.5rem" }}
                                  onClick={(e) => {
                                    handleHeadingEdit('description');
                                  }} >
                                  {editableHeading === 'description' ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}
                          </button>
                  </div>
                    <textarea
                      {...formik.getFieldProps("description")}
                      className="form-control"
                      rows="4"
                      placeholder={`${t("Description")}`}
                    ></textarea>
                    {formik.touched.description &&
                      formik.errors.description && (
                        <ErrorStyle className="error">
                          {formik.errors.description}
                        </ErrorStyle>
                      )}
                  </div>

                  {/* {(userData.user_type === 'Team Leader' || userData.user_type === 'Sales Executive' || userData.user_type === 'super_admin') && (
                      <div className="mb-3 col-md-12">
                        <label className="form-label" style={{ fontSize: "17px" }}>
                          Business Owner
                        </label>
                        <div className="form-control">
                          <select
                            style={{ width: '100%' }}
                            value={businessOwnerId}
                            onChange={(e) => setBusinessOwnerId(e.target.value)}
                            disabled={businessObjData?.bo_id === businessObjData?.created_by}
                          >
                            <option value="">Select Business Owner</option>
                            {businessOwnerList?.map((ele) => (
                              <option key={ele.id} value={ele.id}>
                                {ele.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )} */}

                  {(userData.user_type === "Team Leader" ||
                    userData.user_type === "Sales Executive" ||
                    userData.user_type === "super_admin") && (
                      <div className="mb-3 col-md-12">
                        <label className="form-label" style={{ fontSize: "17px" }}>
                          {t("Business_owner")}
                        </label>
                        <div className="form-control" style={{position: "relative", zIndex: "100"}}>
                          <AsyncPaginate
                            className="asyncPaginate"
                            // style={{ zIndex: '999 !important' }}
                            value={selectBusinessOwnerId} // Controlled by state
                            loadOptions={loadOptions}
                            onChange={(selectedOption) => {
                              setSelectBusinessOwnerId(selectedOption);
                              setBusinessOwnerId(selectedOption?.value);
                              setbusinessOwnerName(businessObj?.Owner_name);
                            }}
                            additional={{
                              page: 1,
                            }}
                            placeholder={businessObj?.Owner_name === 'Super Admin' ? t("Select_business_owner") : businessObj?.Owner_name || t("Select_business_owner")}

                            isDisabled={userData.user_type !== "super_admin" && (businessObjData?.bo_id === businessObjData?.created_by)}
                          />
                        </div>
                      </div>
                    )}
                  <label className="form-label" style={{ fontSize: "17px" }}>
                    {t("Settings")}
                  </label>

                  <div className="accordion" id="accordionExample">
                    {/* ///SERVICES-----------------services  */}
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          onClick={(e) => {

                          }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}>{t("Cuisine")}</h5>
                          </div>
                        </button>
                          {/* <input
                            type="text"
                            value={cuisineHeading}
                            onChange={(e) => setCuisineHeading(e.target.value)}
                            placeholder="Cuisine Heading"
                            style={{ marginRight: "10px", padding: "5px", border: "1px solid green" }}
                          /> */}
                        <div className="form-check form-switch">
                          <label
                            className="form-check-label"
                          >
                            {t("On_Off")}
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={onState?.services}
                            onClick={(e) => {
                              setIsEnabledServices(!isEnabledServices);
                              setOnState({
                                ...onState,
                                services: !onState.services,
                              });
                            }}
                            onChange={() => toggleDiv("div2")}
                          />
                        </div>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body"
                          id="AddnewServicesformwrapper"
                        >
                          <div className="row">
                            <NewBusinessDetilsCreate
                              setServicesArray={setServicesArray}
                              businessObj={businessObj}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* =========Menu============== */}
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                      <div className="d-flex" style={{ gap: "8px", alignItems: "center", width: 'fit-content', zIndex: '99', position: 'relative' }}>
                            {editableHeading === 'menuHeading' ? (
                              <input
                                type="text"
                                value={menuHeading}
                                onChange={(e) => setMenuHeading(e.target.value)}
                                autoFocus
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    handleHeadingEdit('menuHeading');
                                    e.preventDefault();
                                  }
                                }}
                                placeholder={t("Menu_heading")}
                                style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize: '14px', borderRadius: '4px', fontWeight: '400' }}
                              />
                            ) : (
                              <h5 style={{ fontWeight: "400" }} border-0>
                                {menuHeading ? menuHeading : t("Menu")}
                              </h5>
                            )}
                            <button
                              type="button"
                              style={{ fontSize: "12px" }}
                              onClick={(e) => {
                                handleHeadingEdit('menuHeading');
                                e.stopPropagation();
                              }}
                            >
                              {editableHeading === 'menuHeading' ? <i class="fa-solid fa-check" style={{ background: 'green', borderRadius: '4px', padding: '5px', color: '#fff' }}></i> : <i class="fas fa-edit"></i>}
                            </button>
                          </div>
                        <button
                          className="accordion-button collapsed accordianCustom"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={() => { }}
                        >
                        </button>
                        <div className="form-check form-switch">
                          <label className="form-check-label">{t("On_Off")}</label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={onState?.restaurantmenu}
                            onClick={() => {
                              setIsRestaurant(!isRestaurant);
                              setOnState((prevState) => ({
                                ...prevState,
                                restaurantmenu: !prevState.restaurantmenu,
                              }));
                            }}
                            onChange={() => toggleDiv("div3")}
                          />
                        </div>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body"
                          id="AddnewServicesformwrapper"
                        >
                          <div className="row">
                            <NewRestaurantMenuCreate
                              setRestaurantArray={setRestaurantArray}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*------Review & Rating ------------ */}

                    {/* <div className="accordion-item">
                      <h2 className="accordion-header" id="headingZero">
                       
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }} border-0>
                              Review & Rating
                            </h5>
                          </div>

                        <div className="form-check form-switch">
                          <label className="form-check-label">{t("On_Off")}</label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            onChange={() => toggleDiv("div8")}
                            checked={onState?.googlereview}
                            onClick={(e) => {
                              setIsEnabledGoogleReview(!isEnabledGoogleReview);
                              setOnState({
                                ...onState,
                                googlereview: !onState.googlereview,
                              });
                            }}

                          />
                        </div>
                      </h2>
                     
                    </div> */}

                    {/*------ Order ------------ */}
                    <div className="accordion-item ContactFormPadIssue">
                      <h2 className="accordion-header">
                      <div className="d-flex" style={{ gap: "8px", alignItems: "center", width: 'fit-content', zIndex: '99', position: 'relative' }}>
                            {editableHeading === 'orderHeading' ? (
                              <input
                                type="text"
                                value={orderHeading}
                                onChange={(e) => setOrderHeading(e.target.value)}
                                autoFocus
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    handleHeadingEdit('orderHeading');
                                    e.preventDefault();
                                  }
                                }}
                                placeholder={t("Order_heading")}
                                style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize: '14px', borderRadius: '4px', fontWeight: '400' }}
                              />
                            ) : (
                              <h5 style={{ fontWeight: "400" }} border-0>
                                {orderHeading ? orderHeading : t("Order")}
                              </h5>
                            )}
                            <button
                              type="button"
                              style={{ fontSize: "12px" }}
                              onClick={(e) => {
                                handleHeadingEdit('orderHeading');
                                e.stopPropagation();
                              }}
                            >
                              {editableHeading === 'orderHeading' ? <i class="fa-solid fa-check" style={{ background: 'green', borderRadius: '4px', padding: '5px', color: '#fff' }}></i> : <i class="fas fa-edit"></i>}
                            </button>
                          </div>
                        <div className="form-check form-switch">
                          <label className="form-check-label">{t("On_Off")}</label>
                          <input
                            // onClick={(e) => {
                            //   setIsEnabledOrders(!isEnabledOrders);
                            //   setOnState((prevState) => ({
                            //     ...prevState,
                            //     orders: !prevState.orders,
                            //   }));
                            // }}
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={onState?.orders}
                            disabled
                          />
                        </div>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse "
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      ></div>
                    </div>


                    {/* ============== Alternate Urls======================== */}

                    {/* <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#ten"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={() => {
                          }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}>{t("Alternate_urls")}</h5>
                          </div>
                        </button>
                        <input
                          type="text"
                          value={alternateUrlsHeading}
                          onChange={(e) => seAlternateUrlsHeading(e.target.value)}
                          placeholder="Alternate Urls Heading"
                          style={{ marginRight: "10px", padding: "5px", border: "1px solid green" }}
                        />
                        <div className="form-check form-switch">
                          <label
                            className="form-check-label"
                          >
                            {t("On_Off")}
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={onState?.alternateurl}
                            onClick={() => {
                              setAlternateUrl(!isAlternateUrl);
                              setOnState({
                                ...onState,
                                alternateurl: !onState.alternateurl,
                              });
                            }}
                            onChange={() => toggleDiv("div10")}
                          />
                        </div>
                      </h2>
                      <div
                        id="ten"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body"
                          id="AddnewServicesformwrapper"
                        >
                          <div className="row">
                            <NewAlternateUrlCreate
                              setAlternateUrlArray={setAlternateUrlArray}
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}

                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingThree">
                          <div className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative' }}>
                            {/* <h5 style={{ fontWeight: "400" }}>{t("Alternate_urls")}</h5>
                              </div>
                            </button>
                            <input
                                type="text"
                                value={alternateUrlsHeading}
                                onChange={(e) => seAlternateUrlsHeading(e.target.value)}
                                placeholder="Alternate Urls Heading"
                                style={{ marginRight: "10px", padding: "5px", border:"1px solid green" }}
                                /> */}
                                {editableHeading === 'alternateUrl' ? (
                                  <input
                                    type="text"
                                    value={alternateUrlsHeading}
                                    onChange={(e) => seAlternateUrlsHeading(e.target.value)}
                                    autoFocus
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') {
                                        handleHeadingEdit('alternateUrl');
                                        e.preventDefault();
                                      }
                                    }}
                                    placeholder={t("Alternate_urls_heading")}
                                    style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                                    />
                                ) : (
                                  <h5 style={{ fontWeight: "400" }} border-0>
                                    {alternateUrlsHeading ? alternateUrlsHeading : t("Alternate_urls")}
                                  </h5>
                                )}
                                <button
                                  type="button"
                                  style={{fontSize : "12px" }}
                                  onClick={(e) => {
                                    handleHeadingEdit('alternateUrl');
                                    e.stopPropagation();
                                  }}
                                >
                                  {editableHeading === 'alternateUrl'  ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}
                                </button>
                              </div>

                            <div className="form-check form-switch">
                              <label
                                className="form-check-label"
                              >
                                {t("On_Off")}
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                checked={onState?.alternateurl}
                                onClick={() => {
                                  setAlternateUrl(!isAlternateUrl);
                                  setOnState({
                                    ...onState,
                                    alternateurl: !onState.alternateurl,
                                  });
                                }}
                                onChange={() => toggleDiv("div10")}
                              />
                            </div>
                            <button
                              type="button"
                              className="accordion-button collapsed  accordianCustom"

                              data-bs-toggle="collapse"
                              data-bs-target="#ten"
                              aria-expanded="false"
                              aria-controls="ten"

                            >

                            </button>
                          </h2>
                          <div
                            id="ten"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div
                              className="accordion-body"
                              id="AddnewServicesformwrapper"
                            >
                              <div className="row">
                                <NewAlternateUrlCreate
                                  setAlternateUrlArray={setAlternateUrlArray}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                    {/* =====Opening Hours===== */}

                    {/* <div className="accordion-item">
                      <h2 className="accordion-header" id="headingsix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#six"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={(e) => { }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}>{t("Opening_hours")}</h5>
                          </div>
                        </button>
                        <div className="form-check form-switch">
                          <label className="form-check-label">{t("On_Off")}</label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={onState?.businessHours}
                            onChange={() => toggleDiv("div6")}
                            onClick={(e) => {
                              setIsBusinessHourObject(!IsBusinessHourObject);
                              setOnState({
                                ...onState,
                                businessHours: !onState.businessHours,
                              });
                            }}
                          />
                        </div>
                      </h2>
                      <div
                        id="six"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingsix"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body"
                          style={{ padding: "10px 0" }}
                        >
                          <div className="row" style={{ padding: "10px 0" }}>
                            <NewRestaurantHour
                              setAppointmentArray={setAppointmentArray}
                              appointmentSlotDuration={appointmentSlotDuration}
                              setAppointmentSlotDuration={
                                setAppointmentSlotDuration
                              }
                              setBusinessHourObject={setBusinessHourObject}
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                      <div className="accordion-item">
                            <h2 className="accordion-header" id="headingsix">
                            <div className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative' }}>
                            
                                  {editableHeading === 'openingHours' ? (
                                    <input
                                      type="text"
                                      value={businessHourHeading}
                                      onChange={(e) => setBusinessHourHeading(e.target.value)}
                                      autoFocus
                                      onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                          handleHeadingEdit('openingHours');
                                          e.preventDefault();
                                        }
                                      }}
                                      placeholder={t("Enter_opening_hours_heading")}
                                      style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                                      />
                                  ) : (
                                    <h5 style={{ fontWeight: "400" }} border-0>
                                      {businessHourHeading ? businessHourHeading : t("Opening_hours")}
                                    </h5>
                                  )}
                                  <button
                                    type="button"
                                    style={{fontSize : "12px" }}
                                    onClick={(e) => {
                                      handleHeadingEdit('openingHours');
                                    }}
                                  >
                                    {editableHeading === 'openingHours' ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}
                                  </button>
                                </div>

                              <div className="form-check form-switch">
                                <label
                                  className="form-check-label"
                                // for="flexSwitchCheckDefault"
                                >
                                  {t("On_Off")}
                                </label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  checked={onState?.businessHours}
                                  onChange={() => toggleDiv("div6")}
                                  onClick={(e) => {
                                    setIsBusinessHourObject(!IsBusinessHourObject);
                                    setIsAppointment(false);
                                    setOnState({
                                      ...onState,
                                      businessHours: !onState.businessHours,
                                      appointment: false,
                                    });
                                  }}
                                />
                              </div>
                              <button
                              type="button"
                              className="accordion-button collapsed  accordianCustom"

                              data-bs-toggle="collapse"
                              data-bs-target="#six"
                              aria-expanded="false"
                              aria-controls="six"

                            >

                            </button>
                            </h2>
                            <div
                              id="six"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingsix"
                              data-bs-parent="#accordionExample"
                            >
                              <div
                                className="accordion-body"
                                style={{ padding: "10px 0" }}
                              >
                                {/* {divStates.div6 && ( */}
                                <div className="row" style={{ padding: "10px 0" }}>
                                  <NewBusinessHour
                                    setAppointmentArray={setAppointmentArray}
                                    appointmentSlotDuration={appointmentSlotDuration}
                                    setAppointmentSlotDuration={
                                      setAppointmentSlotDuration
                                    }
                                    setBusinessHourObject={setBusinessHourObject}
                                  />
                                </div>
                                {/* )} */}
                              </div>
                            </div>
                          </div>

                    {/* ======gallery=========== */}

                    {/* <div className="accordion-item">
                      <div className="accordion-header" id="headingseven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#seven"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={(e) => { }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}>{t("Gallery")}</h5>
                          </div>
                        </button>
                        <div className="form-check form-switch">
                          <label className="form-check-label">{t("On_Off")}</label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={onState?.gallery}
                            onClick={(e) => {
                              SetIsGallaryEnable(!isGallaryEnable);
                              setOnState({
                                ...onState,
                                gallery: !onState.gallery,
                              });
                            }}
                            onChange={() => toggleDiv("div7")}
                          />
                        </div>
                      </div>
                      <div
                        id="seven"
                        className={`accordion-collapse collapse`}
                        aria-labelledby="headingseven"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body"
                          id="AddnewGallaryformwrapper"
                        >
                          <div className="row">
                            <NewGallerycreate
                              businessId={businessId}
                              existingGalleryImages={existingGalleryImages}
                              setExistingGalleryImages={
                                setExistingGalleryImages
                              }
                              gallaryObject={gallaryObject}
                              galleryData={galleryData}
                              setGalleryData={setGalleryData}
                              galleryView={galleryView}
                              setGalleryView={setGalleryView}
                              setGallaryObject={setGallaryObject}
                              isGallaryEnable={isGallaryEnable}
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="accordion-item">
                            <div className="accordion-header" id="headingseven">
                            <div className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative' }}>
                                  {editableHeading === 'gallery' ? (
                                    <input
                                      type="text"
                                      value={galleryHeading}
                                      onChange={(e) => setGalleryHeading(e.target.value)}
                                      autoFocus
                                      onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                          handleHeadingEdit('gallery');
                                          e.preventDefault();
                                        }
                                      }}
                                      placeholder={t("Enter_gallery_heading")}
                                      style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                                      />
                                  ) : (
                                    <h5 style={{ fontWeight: "400" }} border-0>
                                      {galleryHeading ? galleryHeading :  t("Gallery")}
                                    </h5>
                                  )}
                                  <button
                                    type="button"
                                    style={{fontSize : "12px" }}
                                    onClick={(e) => {
                                      handleHeadingEdit('gallery');
                                      e.stopPropagation();
                                    }}
                                  >
                                    {editableHeading === 'gallery' ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}
                                  </button>
                                </div>

                              <div className="form-check form-switch">
                                <label
                                  className="form-check-label"
                                // for="flexSwitchCheckDefault"
                                >
                                  {t("On_Off")}
                                </label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  checked={onState?.gallery}
                                  onClick={(e) => {
                                    SetIsGallaryEnable(!isGallaryEnable);
                                    setOnState({
                                      ...onState,
                                      gallery: !onState.gallery,
                                    });
                                  }}
                                  onChange={() => toggleDiv("div7")}
                                />
                              </div>

                              <button
                              type="button"
                              className="accordion-button collapsed  accordianCustom"

                              data-bs-toggle="collapse"
                              data-bs-target="#seven"
                              aria-expanded="false"
                              aria-controls="seven"

                            >

                            </button>
                            </div>
                            <div
                              id="seven"
                              className={`accordion-collapse collapse`}
                              aria-labelledby="headingseven"
                              data-bs-parent="#accordionExample"
                            >
                              <div
                                className="accordion-body"
                                id="AddnewGallaryformwrapper"
                              >
                                <div className="row">
                                  <NewGallerycreate
                                    businessId={businessId}
                                    existingGalleryImages={existingGalleryImages}
                                    setExistingGalleryImages={
                                      setExistingGalleryImages
                                    }
                                    gallaryObject={gallaryObject}
                                    galleryData={galleryData}
                                    setGalleryData={setGalleryData}
                                    galleryView={galleryView}
                                    setGalleryView={setGalleryView}
                                    setGallaryObject={setGallaryObject}
                                    isGallaryEnable={isGallaryEnable}
                                  />
                                </div>
                              </div>
                            </div>
                    </div>

                    {/* =========social Media=================== */}

                    {/* <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#five"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={() => { }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}>{t("Social_media")}</h5>
                          </div>
                        </button>
                        <input
                          type="text"
                          value={socialHeading}
                          onChange={(e) => setSocialHeading(e.target.value)}
                          placeholder="BusinessHours Heading"
                          style={{ marginRight: "10px", padding: "5px", border: "1px solid green" }}
                        />
                        <div className="form-check form-switch">
                          <label className="form-check-label">{t("On_Off")}</label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={onState?.socials}
                            onChange={() => toggleDiv("div5")}
                            onClick={() => {
                              setIsSocial(!isSocial);
                              setOnState({
                                ...onState,
                                socials: !onState.socials,
                              });
                            }}
                          />
                        </div>
                      </h2>
                      <div
                        id="five"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body"
                          id="AddnewServicesformwrapper"
                          style={{ padding: "15px 0" }}
                        >
                          <div
                            className="row"
                            style={{ justifyContent: "center" }}
                          >
                            <NewSocialmediaCreate
                              setSocialMediaData={setSocialMediaData}
                              socialMediaData={socialMediaData}
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="accordion-item">
                          <h2 className="accordion-header" id="headingFive">
                          <div className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative' }}>
                            {/* <h5 style={{ fontWeight: "400" }}>{t("Social_media")}</h5>
                              </div>
                            </button>
                            <input
                                type="text"
                                value={socialHeading}
                                onChange={(e) => setSocialHeading(e.target.value)}
                                placeholder="BusinessHours Heading"
                                style={{ marginRight: "10px", padding: "5px", border:"1px solid green" }}
                                /> */}
                                {editableHeading === 'socialMedia' ? (
                                  <input
                                    type="text"
                                    value={socialHeading}
                                    onChange={(e) => setSocialHeading(e.target.value)}
                                    autoFocus
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') {
                                        handleHeadingEdit('socialMedia');
                                        e.preventDefault();
                                      }
                                    }}
                                    placeholder={t("Enter_Social_heading")}
                                    style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                                    />
                                ) : (
                                  <h5 style={{ fontWeight: "400" }} border-0>
                                    {socialHeading ? socialHeading : t("Social_media")}
                                  </h5>
                                )}
                                <button
                                  type="button"
                                  style={{fontSize : "12px" }}
                                  onClick={(e) => {
                                    handleHeadingEdit('socialMedia');
                                    e.stopPropagation();
                                  }}
                                >
                                  {editableHeading === 'socialMedia' ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}
                                </button>
                              </div>

                            <div className="form-check form-switch">
                              <label
                                className="form-check-label"
                              // for="flexSwitchCheckDefault"
                              >
                                {t("On_Off")}
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                checked={onState?.socials}
                                onChange={() => toggleDiv("div5")}
                                onClick={() => {
                                  setIsSocial(!isSocial);
                                  setOnState({
                                    ...onState,
                                    socials: !onState.socials,
                                  });
                                }}
                              />
                            </div>
                            <button
                              type="button"
                              className="accordion-button collapsed  accordianCustom"

                              data-bs-toggle="collapse"
                              data-bs-target="#five"
                              aria-expanded="false"
                              aria-controls="five"

                            >

                            </button>
                          </h2>
                          <div
                            id="five"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFive"
                            data-bs-parent="#accordionExample"
                          >
                            <div
                              className="accordion-body"
                              id="AddnewServicesformwrapper"
                              style={{ padding: "15px 0" }}
                            >
                              {/* {divStates.div5 && ( */}
                              <div
                                className="row"
                                style={{ justifyContent: "center" }}
                              >
                                <NewSocialmediaCreate
                                  setSocialMediaData={setSocialMediaData}
                                  socialMediaData={socialMediaData}
                                />
                              </div>
                              {/* )} */}
                            </div>
                          </div>
                    </div>

                    {/*------ contact INFO ------------ */}

                    {/* <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                          onClick={(e) => { }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }} border-0>
                              {t("Contact_info")}
                            </h5>
                          </div>
                        </button>

                        <div className="form-check form-switch">
                          <label className="form-check-label">{t("On_Off")}</label>
                          <input
                            onClick={(e) => {
                              setIsEnabledContactInfo(!isEnabledContactInfo);
                              setOnState({
                                ...onState,
                                contactInfo: !onState.contactInfo,
                              });
                            }}
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            onChange={() => toggleDiv("div1")}
                            checked={onState?.contactInfo}
                          />
                        </div>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse "
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body AddDetailsContactInfoInnerForm">
                          <div className="row MobileDetailsWrapper">
                            <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                              <label className="form-label">
                                {t("Mobile_number")}<span style={{ fontSize: "10px" }}>({t("Enter_whatsapp_msg")})</span>
                              </label>

                              <PhoneInput
                                country={countryCode}
                                enableSearch={true}
                                countryCodeEditable={false}
                                value={formik.values.contactInfoPhone || `+${countryCode}`}
                                onChange={(value, country) => {
                                  if (country.dialCode) {
                                    formik.setFieldValue(
                                      "contactInfoPhone",
                                      `+${country.dialCode}-${value.substring(
                                        country.dialCode.length
                                      )}`
                                    );
                                    setCountryName(country.name);
                                    setInvalidPhoneErr("");
                                  } else {
                                    formik.setFieldValue(
                                      "contactInfoPhone",
                                      value
                                    );
                                    setInvalidPhoneErr("");
                                  }
                                }}
                                onBlur={(e, country) => handleBlur(e, country) && formik.handleBlur("contactInfoPhone")}
                                isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
                                className="DetailsInfoPhoneInput"
                              />

                              {formik.touched.contactInfoPhone &&
                                formik.errors.contactInfoPhone && (
                                  <ErrorStyle className="error">
                                    {formik.errors.contactInfoPhone}
                                  </ErrorStyle>
                                )}
                              {invalidPhoneErr && <ErrorStyle className="error">
                                {invalidPhoneErr}
                              </ErrorStyle>}
                            </div>
                            <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                              <label className="form-label">
                                {t("Alternative_mobile_number")}
                              </label>
                              <PhoneInput
                                country={countryCode}
                                enableSearch={true}
                                countryCodeEditable={false}
                                value={formik.values.optionalContactInfoPhone || `+${countryCode}`}
                                onChange={(value, country) => {
                                  if (country.dialCode) {
                                    formik.setFieldValue(
                                      "optionalContactInfoPhone",
                                      `+${country.dialCode}-${value.substring(
                                        country.dialCode.length
                                      )}`
                                    );
                                    setCountryName(country.name);
                                  } else {
                                    formik.setFieldValue(
                                      "optionalContactInfoPhone",
                                      value
                                    );
                                  }
                                }}
                                // onBlur={formik.handleBlur("optionalContactInfoPhone")}
                                onBlur={(e, country) => handleBlurOptional(e, country) && formik.handleBlur("optionalContactInfoPhone")}
                                // {...formik.getFieldProps('optionalContactInfoPhone')}
                                className="DetailsInfoPhoneInput"
                                isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
                              />
                              {formik.touched.optionalContactInfoPhone &&
                                formik.errors.optionalContactInfoPhone && (
                                  <ErrorStyle className="error">
                                    {formik.errors.optionalContactInfoPhone}
                                  </ErrorStyle>
                                )}
                              {invalidOptPhoneErr && <ErrorStyle className="error">
                                {invalidOptPhoneErr}
                              </ErrorStyle>}
                            </div>

                            <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                              <label className="form-label">{t("Email")}*</label>
                              <input
                                {...formik.getFieldProps("contactInfoEmail")}
                                className="form-control"
                                type="text"
                                placeholder={`${t("Email")}`}
                              />
                              {formik.touched.contactInfoEmail &&
                                formik.errors.contactInfoEmail && (
                                  <ErrorStyle className="error">
                                    {formik.errors.contactInfoEmail}
                                  </ErrorStyle>
                                )}
                            </div>
                            <div className="mb-3 col-md-12">
                              <label className="form-label">{t("Web_url")}</label>
                              <input
                                {...formik.getFieldProps("contactInfoWebUrl")}
                                // value={contactInfoWebUrl}
                                className="form-control"
                                type="text"
                                placeholder={`${t("Web_url")}`}
                              // onChange={(e) =>
                              //   setContactInfoWebUrl(e.target.value)
                              // }
                              />
                              {formik.touched.contactInfoWebUrl &&
                                formik.errors.contactInfoWebUrl && (
                                  <ErrorStyle className="error">
                                    {formik.errors.contactInfoWebUrl}
                                  </ErrorStyle>
                                )}
                            </div>
                            <div className="mb-3 col-md-12">
                              <label className="form-label">{t("Address")}*</label>
                              <textarea
                                value={contactInfoAddress}
                                className="form-control"
                                rows="3"
                                maxLength={250}
                                placeholder={`${t("Address")}`}
                                onChange={(e) =>
                                  handleContactInfoAddressChange(e)
                                }
                                // onBlur={handleGetLatLang}
                                disabled={isAddressDisabled}
                              ></textarea>
                              {contactInfoAddressError && <p className="error-mgs text-danger">{contactInfoAddressError}</p>}
                              {formik.errors.contactInfoAddress && (
                                <ErrorStyle className="error">
                                  {formik.errors.contactInfoAddress}
                                </ErrorStyle>
                              )}
                              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "6px" }}> <a className="btn btn-info-detail" onClick={handleGetLatLang}>{t("Submit")}</a><label className="form-label">
                                <input type="checkbox" checked={isShowAddress ? true : false} onChange={(e) => handleIsShowAddress(e)} /> {t("Show_hide")}</label>
                              </div>
                              {latLngError && <p className="text-danger mt-2" style={{ fontSize: "12px" }}>{latLngError}</p>}
                              {isAddressSubmit ? (
                                <CircularLoader size={40} />
                              ) : (
                                addressPlaceId && formik.values.contactInfoAddress && (
                                  <div className="">
                                    <div className="">
                                      <a
                                        href={`https://www.google.com/maps/place/?q=place_id:${addressPlaceId}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn btn-link"
                                        style={{ fontSize: "14px", color: "#00b721", fontWeight: "500", padding: "10px 0px" }}
                                      >
                                        {t("Business_address_verify")}
                                      </a>
                                    </div>
                                    <div className="">
                                      {isAddressVerified ? (
                                        <>
                                          <button className="btn btn-success me-2 selectreviewverified" disabled>
                                            {t("Address_verified")}
                                          </button>
                                          <button className="selectreviewedit" onClick={handleIncorrectAddressData}>
                                            {t("Edit")}
                                          </button>
                                        </>
                                      ) : (
                                        <button className="btn btn-success me-2 selectreview 
                                          verifybusinesswtd" onClick={handleCorrectAddressData}>
                                          {t("Verify_address")}
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>

                            <div className="mb-3 col-md-12">
                              <label className="form-label">{t("Alternative_address")}</label>
                              <textarea
                                value={contactInfoOptAddress}
                                className="form-control"
                                rows="3"
                                maxLength={250}
                                placeholder={`${t("Address")}`}
                                onChange={(e) =>
                                  handleContactInfoOptAddressChange(e)
                                }
                              ></textarea>
                              {contactInfoOptAddressError && <p className="error-mgs text-danger">{contactInfoOptAddressError}</p>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne">
                            <div className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative' }}>
                              {editableHeading === 'contactInfo' ? (
                                <input
                                  type="text"
                                  value={contactHeading}
                                  onChange={(e) => setcontactHeading(e.target.value)}
                                  autoFocus
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      handleHeadingEdit('contactInfo');
                                      e.preventDefault();
                                    }
                                  }}
                                  placeholder={t("Contact_info_heading")}
                                  style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                                />
                              ) : (
                                <h5 style={{ fontWeight: "400" }} border-0>
                                  {contactHeading ? contactHeading : t("Contact_info")}
                                </h5>
                              )}
                              <button
                                type="button"
                                style={{fontSize : "12px" }}
                                onClick={(e) => {
                                  handleHeadingEdit('contactInfo');
                                }}
                              >
                                {editableHeading === 'contactInfo' ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}

                              </button>
                            </div>

                            <div className="form-check form-switch">
                              <label
                                className="form-check-label"
                              >
                                {t("On_Off")}
                              </label>
                              <input
                                onClick={(e) => {
                                  setIsEnabledContactInfo(!isEnabledContactInfo);
                                  setOnState({
                                    ...onState,
                                    contactInfo: !onState.contactInfo,
                                  });
                                }}
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                onChange={() => toggleDiv("div1")}
                                checked={onState?.contactInfo}
                              />
                            </div>
                            <button
                              type="button"
                              className="accordion-button collapsed  accordianCustom"

                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="false"
                              aria-controls="collapseOne"

                            >

                            </button>


                          </h2>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse "
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body AddDetailsContactInfoInnerForm">
                              {/* {divStates.div1 && ( */}
                              <div className="row MobileDetailsWrapper">
                                <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                                  <label className="form-label">
                                    {/* Mobile Number* */}
                                    {t("Mobile_number")}<span style={{ fontSize: "10px" }}>({t("Enter_whatsapp_msg")})</span>
                                  </label>
                                  {/* <input
                      value={contactInfoPhone}
                      type="number"
                      className="form-control"
                      onChange={(e) =>
                        setContactInfoPhone(e.target.value)
                      }
                      placeholder="Phone Number"
                    /> */}
                                  <PhoneInput
                                    country={countryCode}
                                    enableSearch={true}
                                    countryCodeEditable={false}
                                    value={formik.values.contactInfoPhone || `+${countryCode}`}
                                    onChange={(value, country) => {
                                      if (country.dialCode) {
                                        formik.setFieldValue(
                                          "contactInfoPhone",
                                          `+${country.dialCode}-${value.substring(
                                            country.dialCode.length
                                          )}`
                                        );
                                        setCountryName(country.name);
                                      } else {
                                        formik.setFieldValue(
                                          "contactInfoPhone",
                                          value
                                        );
                                      }
                                    }}
                                    // onBlur={formik.handleBlur("contactInfoPhone")}
                                    onBlur={(e, country) => handleBlur(e, country) && formik.handleBlur("contactInfoPhone")}
                                    // {...formik.getFieldProps('contactInfoPhone')}
                                    className="DetailsInfoPhoneInput"
                                    isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
                                  />
                                  {formik.touched.contactInfoPhone &&
                                    formik.errors.contactInfoPhone && (
                                      <ErrorStyle className="error">
                                        {formik.errors.contactInfoPhone}
                                      </ErrorStyle>
                                    )}
                                  {invalidPhoneErr && <ErrorStyle className="error">
                                    {invalidPhoneErr}
                                  </ErrorStyle>}
                                </div>
                                <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                                  <label className="form-label">
                                    {t("Alternative_mobile_number")}
                                  </label>

                                  <PhoneInput
                                    country={countryCode}
                                    enableSearch={true}
                                    countryCodeEditable={false}
                                    value={formik.values.optionalContactInfoPhone || `+${countryCode}`}
                                    onChange={(value, country) => {
                                      if (country.dialCode) {
                                        formik.setFieldValue(
                                          "optionalContactInfoPhone",
                                          `+${country.dialCode}-${value.substring(
                                            country.dialCode.length
                                          )}`
                                        );
                                        setCountryName(country.name);
                                      } else {
                                        formik.setFieldValue(
                                          "optionalContactInfoPhone",
                                          value
                                        );
                                      }
                                    }}
                                    // onBlur={formik.handleBlur("optionalContactInfoPhone")}
                                    onBlur={(e, country) => handleBlurOptional(e, country) && formik.handleBlur("optionalContactInfoPhone")}
                                    // {...formik.getFieldProps('optionalContactInfoPhone')}
                                    className="DetailsInfoPhoneInput"
                                    isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
                                  />
                                  {formik.touched.optionalContactInfoPhone &&
                                    formik.errors.optionalContactInfoPhone && (
                                      <ErrorStyle className="error">
                                        {formik.errors.optionalContactInfoPhone}78
                                      </ErrorStyle>
                                    )}
                                  {invalidOptPhoneErr && <ErrorStyle className="error">
                                    {invalidOptPhoneErr}
                                  </ErrorStyle>}
                                </div>
                                <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                                  <label className="form-label">{t("Email")}*</label>
                                  <input
                                    {...formik.getFieldProps("contactInfoEmail")}
                                    // value={contactInfoEmail}
                                    // value={formik.values.contactInfoEmail}
                                    className="form-control"
                                    type="text"
                                    placeholder={`${t("Email")}`}
                                  // onChange={(e) =>
                                  //   setContactInfoEmail(e.target.value)
                                  // }
                                  // onChange={formik.handleChange('contactInfoEmail')}
                                  />
                                  {formik.touched.contactInfoEmail &&
                                    formik.errors.contactInfoEmail && (
                                      <ErrorStyle className="error">
                                        {formik.errors.contactInfoEmail}
                                      </ErrorStyle>
                                    )}
                                </div>
                                <div className="mb-3 col-md-6">
                                  <label className="form-label">{t("Web_url")}</label>
                                  <input
                                    {...formik.getFieldProps("contactInfoWebUrl")}
                                    // value={contactInfoWebUrl}
                                    className="form-control"
                                    type="text"
                                    placeholder={`${t("Web_url")}`}
                                  // onChange={(e) =>
                                  //   setContactInfoWebUrl(e.target.value)
                                  // }
                                  />
                                  {formik.touched.contactInfoWebUrl &&
                                    formik.errors.contactInfoWebUrl && (
                                      <ErrorStyle className="error">
                                        {formik.errors.contactInfoWebUrl}
                                      </ErrorStyle>
                                    )}
                                </div>
                                {themeColor == "theme53" &&
                                  <>
                                    <div className="mb-3 col-md-6">
                                      <label className="form-label">{t("Select_influencer")}*</label>
                                      <div className="col">
                                        <select
                                          className="form-select"
                                          name="influencer_id"
                                          value={formik.values.influencer_id}
                                          onChange={formik.handleChange}
                                        >
                                          <option value=""> {t("Select_influencer")}</option>
                                          {!!allInfluencer && allInfluencer?.length > 0 && allInfluencer?.map((influencer) => (
                                            !!influencer?.id &&
                                            <option key={influencer?.name} value={influencer?.id}>
                                              {influencer?.name?.charAt(0)?.toUpperCase() + influencer?.name?.slice(1)}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    <div className="mb-3 col-md-6">
                                      <label className="form-label">{t("Shipping_country")}</label>
                                      <div className="col">
                                        <select
                                          className="form-select"
                                          name="shipCountry"
                                          value={formik.values.shipCountry || "IN"}
                                          onChange={formik.handleChange}
                                        >
                                          <option value=""> {t("Select Country")}</option>
                                          {!!dynamicCountry && dynamicCountry?.length > 0 && dynamicCountry?.map((country) => (
                                            !!country?.id &&
                                            <option key={country?.name} value={country?.short_code}>
                                              {country?.name?.charAt(0)?.toUpperCase() + country?.name?.slice(1)}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </>
                                }

                                <div className="mb-3 col-md-12">
                                  <label className="form-label">{themeNumber == "53" ? t("Address") : t("Address")}</label>
                                  <textarea
                                    value={contactInfoAddress}
                                    className="form-control"
                                    rows="3"
                                    maxLength={250}
                                    placeholder={`${t("Address")}`}
                                    onChange={(e) =>
                                      handleContactInfoAddressChange(e)
                                    }
                                    // onBlur={handleGetLatLang}
                                    disabled={isAddressDisabled}
                                  ></textarea>
                                  {contactInfoAddressError && <p className="error-mgs text-danger">{contactInfoAddressError}</p>}
                                  {formik.touched.contactInfoAddress &&
                                    formik.errors.contactInfoAddress && (
                                      <ErrorStyle className="error">
                                        {formik.errors.contactInfoAddress}
                                      </ErrorStyle>
                                    )}
                                  <a className="btn btn-info-detail" onClick={handleGetLatLang}> {t("Submit")}</a>
                                  {latLngError && <p className="text-danger mt-2" style={{ fontSize: "12px" }}>{latLngError}</p>}
                                  {isAddressSubmit ? (
                                    <CircularLoader size={40} />
                                  ) : (
                                    addressPlaceId && formik.values.contactInfoAddress && (
                                      <div className="">
                                        <div className="">
                                          <a
                                            href={`https://www.google.com/maps/place/?q=place_id:${addressPlaceId}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="btn btn-link"
                                            style={{ fontSize: "14px", color: "#00b721", fontWeight: "500", padding: "10px 0px" }}
                                          >
                                            {t("Business_address_verify")}
                                          </a>
                                        </div>
                                        <div className="">
                                          {isAddressVerified ? (
                                            <>
                                              <button className="btn btn-success me-2 selectreviewverified" disabled>
                                                {t("Address_verified")}
                                              </button>
                                              <button className="selectreviewedit" onClick={handleIncorrectAddressData}>
                                                {t("Edit")}
                                              </button>
                                            </>
                                          ) : (
                                            <button className="btn btn-success me-2 selectreview 
                                              verifybusinesswtd" onClick={handleCorrectAddressData}>
                                              {t("Verify_address")}
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                                <div className="mb-3 col-md-12">
                                  <label className="form-label">{t("Alternative_address")}</label>
                                  <textarea
                                    value={contactInfoOptAddress}
                                    className="form-control"
                                    rows="3"
                                    maxLength={250}
                                    placeholder={`${t("Address")}`}
                                    onChange={(e) =>
                                      handleContactInfoOptAddressChange(e)
                                    }
                                  ></textarea>
                                  {contactInfoOptAddressError && <p className="error-mgs text-danger">{contactInfoOptAddressError}</p>}
                                </div>
                              </div>
                              {/* )} */}
                            </div>
                          </div>
                    </div>

                    {/* =============Agent Code Restaurant============ */}
                    {(userData.user_type === 'Sales Executive' || userData.user_type === 'Team Leader' || userData.user_type === 'super_admin') ? " " : <div className="accordion-item" style={{ padding: "10px 25px" }}>
                      <h2 className="accordion-header" id="headingEight">
                        <div className="d-flex justify-content-between" style={{ width: "100%" }}>
                          <h5 style={{ fontWeight: "400", display: "flex", alignItems: "center" }}>{t("Agent_code")} {t("Optional")}</h5>
                          <input
                            type="text"
                            className="form-control"
                            id="agentCode"
                            value={userData.user_type === 'Sales Executive' ? businessObj?.agentCode || agentCode : agentCode}
                            onChange={validateAgentCodeInput}
                            maxLength={6}
                            placeholder={`${t("Agent_code")}`}
                            style={{ width: "50%" }}
                            readOnly={!!businessObj?.agentCode && (userData.user_type === 'Sales Executive' || !userData.user_type)}
                          />
                        </div>
                        {isVerifyCodeSubmit ? (
                          <>
                            <CircularLoader size={40} />
                            {verifyCodeError && <p className="error-mgs text-danger">{verifyCodeError}</p>}
                          </>
                        ) : agentCode.length === 6 && isVerifyCodeVerified ? (
                          <p className="text-success mt-2">{t("Verified")}</p>
                        ) : agentCode.length === 6 && !isVerifyCodeVerified ? (
                          <p className="text-danger mt-2">{t("Not_verified")}</p>
                        ) : agentCode.length > 0 && agentCode.length < 6 ? (
                          <p className="text-danger mt-2">{t("agent_code_limit")}</p>
                        ) : null}
                      </h2>
                    </div>}
                    {(userData?.user_type == "super_admin" || userData?.user_type == "Team Leader" || userData?.user_type == "Sales Executive") &&
                      <div className="accordion-item" style={{ padding: "10px 25px" }}>
                        <h2 className="accordion-header" id="headingEight">
                          <div className="d-flex justify-content-between" style={{ width: "100%" }}>
                            <h5 style={{ fontWeight: "400", display: "flex", alignItems: "center" }}>{t("Lead_id")}</h5>
                            <input
                              type="text"
                              className="form-control"
                              id="teamlead"
                              value={teamleadId}
                              // value={userData.user_type === 'Sales Executive' ? businessObj?.teamleadId || teamleadId : teamleadId}
                              onChange={validateTeamLeadInput}
                              maxLength={6}
                              placeholder={`${t("Lead_id")}`}
                              style={{ width: "50%" }}
                            // readOnly={!!businessObj?.teamleadId && (userData.user_type === 'Sales Executive' || !userData.user_type)}
                            />
                          </div>
                        </h2>
                      </div>
                    }

                    {/* =============appointments============ */}
                    {/* <div class="accordion-item">
                      <h2 class="accordion-header" id="headingFour">
                        <div className="d-flex">
                          <h5 style={{ fontWeight: "400" }}>Appointments</h5>
                          <div class="form-check form-switch">
                            <label
                              class="form-check-label"
                            >
                              {t("On_Off")}
                            </label>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              checked={onState?.appointment}
                              disabled={!onState?.businessHours}
                              onChange={() => toggleDiv("div4")}
                              onClick={() => {
                                setIsAppointment(!isAppointment);
                                setOnState({
                                  ...onState,
                                  appointment: !onState?.appointment,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </h2>

                    </div> */}

                    {
                      userData?.user_type == "super_admin" &&
                      <div className="accordion-item">
                        <div className="accordion-header" id="headingeight">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#eight"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <div className="d-flex">
                              <h5 style={{ fontWeight: "400" }}>{t("Meta_pixel_code")}</h5>
                            </div>
                          </button>

                        </div>
                        <div
                          id="eight"
                          className={`accordion-collapse collapse`}
                          aria-labelledby="headingeight"
                          data-bs-parent="#accordionExample"
                          style={{ paddingTop: "10px" }}
                        >
                          <div className="mb-3 col-md-12 AddDetailswidContainer">
                            <input
                              value={pixel_id}
                              className="form-control"
                              type="text"
                              placeholder={`${t("Meta_pixel_code")}`}
                              onChange={(e) => handleChangePixelId(e)}
                            />
                          </div>

                        </div>
                      </div>
                    }
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwofive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwofive"
                          aria-expanded="false"
                          aria-controls="collapseTwofive"
                          onClick={(e) => {
                          }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}> {t("Keywords_metatags")}</h5>
                          </div>
                        </button>
                      </h2>
                      <div
                        id="collapseTwofive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwofive"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body"
                          id="AddnewServicesformwrapper"
                        >
                          <div className="row">
                            <NewBusinessDetilsCreateKeywords
                              setKeywordArray={setKeywordArray}
                              businessObj={businessObj}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="headingnine">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#nine"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}>{t("Meta_title")}</h5>
                          </div>
                        </button>

                      </div>
                      <div
                        id="nine"
                        className={`accordion-collapse collapse`}
                        aria-labelledby="headingnine"
                        data-bs-parent="#accordionExample"
                        style={{ paddingTop: "10px" }}
                      >
                        <div className="mb-3 col-md-12 AddDetailswidContainer">
                          <input
                            value={metaTitle}
                            className="form-control"
                            type="text"
                            placeholder={`${t("Meta_title")}`}
                            onChange={(e) => handleChangeMetaTitle(e)}
                          />
                          {metaTitle.length >= 65 && (
                            <small style={{ color: "red" }}>
                              {t("Meta_titl_limit")}
                            </small>
                          )}
                        </div>

                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="headingeleven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#eleven"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}>{t("Meta_description")}</h5>
                          </div>
                        </button>

                      </div>
                      <div
                        id="eleven"
                        className={`accordion-collapse collapse`}
                        aria-labelledby="headingeleven"
                        data-bs-parent="#accordionExample"
                        style={{ paddingTop: "10px" }}
                      >
                        <div className="mb-3 col-md-12 AddDetailswidContainer">
                          <input
                            value={metaDescription}
                            className="form-control"
                            type="text"
                            placeholder={`${t("Meta_description")}`}
                            onChange={(e) => handleChangeMetades(e)}
                          />
                          {metaDescription.length >= 165 && (
                            <small style={{ color: "red" }}>
                              {t("Meta_desc_limit")}
                            </small>
                          )}
                        </div>

                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="headingtwelve">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#twelve"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}>{t("Canonical_tag")}</h5>
                          </div>
                        </button>

                      </div>
                      <div
                        id="twelve"
                        className={`accordion-collapse collapse`}
                        aria-labelledby="headingtwelve"
                        data-bs-parent="#accordionExample"
                        style={{ paddingTop: "10px" }}
                      >
                        <div className="mb-3 col-md-12 AddDetailswidContainer">
                          <input
                            value={canonicalTag}
                            className="form-control"
                            type="text"
                            placeholder={`${t("Canonical_tag")}`}
                            onChange={(e) => handleChangeCanicaltag(e)}
                          />
                        </div>

                      </div>
                    </div>
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h4 className="accordion-header" id="headingTwo">
                          <button
                            style={{ fontWeight: "400", fontSize: "15.3px" }}
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseColorPicker"
                            aria-expanded="false"
                            aria-controls="collapseColorPicker"
                          >
                            {t("Theme_color_picker")}
                          </button>
                        </h4>
                        <div
                          id="collapseColorPicker"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="color-picker-container" >
                              {/* Main Background Color Picker */}
                              <div className="color-picker-box">

                                <div className="color-picker-item">

                                  <p className="colorpicker-name"> {t("Main_background_color")}:</p>
                                  <div className="color-picker-info" style={{ background: dynamicMainBackgroundColor ? `${dynamicMainBackgroundColor}13` : "", border: dynamicMainBackgroundColor ? `1px solid ${dynamicMainBackgroundColor}20` : "" }}>


                                    <div className="color-picker-info-detail" ref={colorPickerContainerRef[0]}>
                                      <div className="color-box-container" style={{ borderColor: dynamicMainBackgroundColor, }}>
                                        <div className="color-box"
                                          onClick={() => toggleColorPicker(0)}
                                          style={{
                                            backgroundColor:
                                              dynamicMainBackgroundColor,
                                            borderColor: dynamicMainBackgroundColor,
                                          }}
                                        ></div>
                                        {isColorPickerOpen[0] && (<SketchPicker
                                          color={dynamicMainBackgroundColor}
                                          onChangeComplete={mainBghandleChangeComplete}
                                        />)}
                                      </div>

                                      <div>

                                        <span
                                          style={{ fontSize: '13px' }}
                                          className="hex-color-name"
                                        >
                                          {t("Hex")}: {dynamicMainBackgroundColor}
                                        </span>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      onClick={() =>
                                        setDynamicMainBackgroundColor("")
                                      }
                                      className="reset-button"
                                    >
                                      {t("Reset")}
                                    </button>
                                  </div>
                                </div>

                                {/* <div className="color-preview">
                                
                                
                              </div> */}
                              </div>

                              {/* Background Color Picker */}
                              <div className="color-picker-box">

                                <div className="color-picker-item">

                                  <p className="colorpicker-name" > {t("Background_color")}:</p>
                                  <div className="color-picker-info" style={{ background: dynamicBackgroundColor ? `${dynamicBackgroundColor}13` : "", border: dynamicBackgroundColor ? `1px solid ${dynamicBackgroundColor}20` : "" }}>


                                    <div className="color-picker-info-detail" ref={colorPickerContainerRef[1]}>
                                      <div className="color-box-container" style={{ borderColor: dynamicBackgroundColor }}>
                                        <div className="color-box"
                                          onClick={() => toggleColorPicker(1)}
                                          style={{
                                            backgroundColor:
                                              dynamicBackgroundColor,
                                            borderColor: dynamicBackgroundColor,
                                          }}
                                        ></div>
                                        {isColorPickerOpen[1] && (<SketchPicker
                                          color={dynamicBackgroundColor}
                                          onChangeComplete={handleChangeComplete}
                                        />)}
                                      </div>

                                      <div>

                                        <span
                                          style={{ fontSize: '13px' }} className="hex-color-name"
                                        >
                                          {t("Hex")}: {dynamicBackgroundColor}
                                        </span>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      onClick={() =>
                                        setDynamicBackgroundColor("")
                                      }
                                      className="reset-button"
                                    >
                                      {t("Reset")}
                                    </button>
                                  </div>
                                </div>
                              </div>

                              {/* Heading Color Picker */}
                              <div className="color-picker-box">

                                <div className="color-picker-item">

                                  <p className="colorpicker-name" > {t("Heading_color")}:</p>
                                  <div className="color-picker-info" style={{ background: dynamicTextHeadingColor ? `${dynamicTextHeadingColor}13` : "", border: dynamicTextHeadingColor ? `1px solid ${dynamicTextHeadingColor}20` : "" }}>


                                    <div className="color-picker-info-detail" ref={colorPickerContainerRef[2]}>
                                      <div className="color-box-container" style={{ borderColor: dynamicTextHeadingColor }}>
                                        <div className="color-box"
                                          onClick={() => toggleColorPicker(2)}
                                          style={{
                                            backgroundColor:
                                              dynamicTextHeadingColor,
                                            borderColor: dynamicTextHeadingColor,
                                          }}
                                        ></div>
                                        {isColorPickerOpen[2] && (<SketchPicker
                                          color={dynamicTextHeadingColor}
                                          onChangeComplete={
                                            HeadingcolorhandleChangeComplete
                                          }
                                        />)}
                                      </div>

                                      <div>

                                        <span
                                          style={{ fontSize: '13px' }} className="hex-color-name"
                                        >
                                          {t("Hex")}: {dynamicTextHeadingColor}
                                        </span>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      onClick={() =>
                                        setDynamicTextHeadingColor("")
                                      }
                                      className="reset-button"
                                    >
                                      {t("Reset")}
                                    </button>
                                  </div>
                                </div>
                              </div>

                              {/* Description Color Picker */}
                              <div className="color-picker-box">

                                <div className="color-picker-item">

                                  <p className="colorpicker-name"> {t("Description_color")}:</p>
                                  <div className="color-picker-info" style={{ background: dynamicTextDescriptionColor ? `${dynamicTextDescriptionColor}13` : "", border: dynamicTextDescriptionColor ? `1px solid ${dynamicTextDescriptionColor}20` : "" }}>


                                    <div className="color-picker-info-detail" ref={colorPickerContainerRef[3]}>
                                      <div className="color-box-container" style={{
                                        borderColor: dynamicTextDescriptionColor
                                      }}>
                                        <div className="color-box"
                                          onClick={() => toggleColorPicker(3)}
                                          style={{
                                            backgroundColor:
                                              dynamicTextDescriptionColor
                                            ,
                                            borderColor: dynamicTextDescriptionColor
                                            ,
                                          }}
                                        ></div>
                                        {isColorPickerOpen[3] && (<SketchPicker
                                          color={dynamicTextDescriptionColor}
                                          onChangeComplete={
                                            DescriptioncolorhandleChangeComplete
                                          }
                                        />)}
                                      </div>

                                      <div>

                                        <span
                                          style={{ fontSize: '13px' }} className="hex-color-name"
                                        >
                                          {t("Hex")}: {dynamicTextDescriptionColor
                                          }
                                        </span>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      onClick={() =>
                                        setDynamicTextDescriptionColor("")
                                      }
                                      className="reset-button"
                                    >
                                      {t("Reset")}
                                    </button>
                                  </div>
                                </div>

                              </div>

                              {/* CTA Color Picker */}
                              <div className="color-picker-box">

                                <div className="color-picker-item">

                                  <p className="colorpicker-name"> {t("Cta_color")}:</p>
                                  <div className="color-picker-info" style={{ background: dynamicCtaColor ? `${dynamicCtaColor}13` : "", border: dynamicCtaColor ? `1px solid ${dynamicCtaColor}20` : "" }}>


                                    <div className="color-picker-info-detail" ref={colorPickerContainerRef[4]}>
                                      <div className="color-box-container" style={{
                                        borderColor: dynamicCtaColor
                                      }}>
                                        <div className="color-box"
                                          onClick={() => toggleColorPicker(4)}
                                          style={{
                                            backgroundColor:
                                              dynamicCtaColor
                                            ,
                                            borderColor: dynamicCtaColor
                                            ,
                                          }}
                                        ></div>
                                        {isColorPickerOpen[4] && (<SketchPicker
                                          color={dynamicCtaColor}
                                          onChangeComplete={
                                            CtacolorhandleChangeComplete
                                          }
                                        />)}
                                      </div>

                                      <div>

                                        <span
                                          style={{ fontSize: '13px' }} className="hex-color-name"
                                        >
                                          {t("Hex")}: {dynamicCtaColor
                                          }
                                        </span>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      onClick={() =>
                                        setdynamicCtaColor("")
                                      }
                                      className="reset-button"
                                    >
                                      {t("Reset")}
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {/* CTA TeXt Color Picker */}
                              <div className="color-picker-box">

                                <div className="color-picker-item">

                                  <p className="colorpicker-name"> {t("Cta_text_color")}:</p>
                                  <div className="color-picker-info" style={{ background: dynamicCtaTextColor ? `${dynamicCtaTextColor}13` : "", border: dynamicCtaTextColor ? `1px solid ${dynamicCtaTextColor}20` : "" }}>


                                    <div className="color-picker-info-detail" ref={colorPickerContainerRef[5]}>
                                      <div className="color-box-container" style={{ borderColor: dynamicCtaTextColor, }}>
                                        <div className="color-box"
                                          onClick={() => toggleColorPicker(5)}
                                          style={{
                                            backgroundColor:
                                              dynamicCtaTextColor,
                                            borderColor: dynamicCtaTextColor,
                                          }}
                                        ></div>
                                        {isColorPickerOpen[5] && (<SketchPicker
                                          color={dynamicCtaTextColor}
                                          onChangeComplete={ctaTextcolorHandler}
                                        />)}
                                      </div>

                                      <div>

                                        <span
                                          style={{ fontSize: '13px' }}
                                          className="hex-color-name"
                                        >
                                          {t("Hex")}: {dynamicCtaTextColor}
                                        </span>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      onClick={() =>
                                        setdynamicCtaTextColor("")
                                      }
                                      className="reset-button"
                                    >
                                      {t("Reset")}
                                    </button>
                                  </div>
                                </div>

                                {/* <div className="color-preview">
                                
                                
                              </div> */}
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row Adddetails_saveprev_ButtonWrapper">
                    <div className="Changeblock_saveandpreview_buttonWrapper Changeblock_saveandpreview_buttonWrappertwo">
                      <button
                        className="btn btn-cu save-cta Changeblock_saveandpreview_button "
                        id="changeblockprenxtbtn"
                        type="button"
                        onClick={handlePreviousClick}
                      >
                        {t("Previous")}
                      </button>

                      {isSubmit ? (
                        <CircularLoader size={40} />
                      ) : (
                        <button
                          type="button"
                          className="btn btn-cu save-cta Changeblock_saveandpreview_button"
                          id="changeblockprenxtbtn"
                          onClick={(e) => handleSubmitWithoutTabChange(e)}
                        >
                          {t("Save_preview")}
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              ////////////////////////////    restaurant detIALS PAGE End ////////////////////////////////////

              themeNumber == 20 ? (
                /////////////////////////////////////// minimarketplace  1945 - 2860////////////////////////////////




                <div className="tile">
                  <form onSubmit={formik.handleSubmit} className="row">
                    <div className="flex items-center i-agree">
                      <div className="mr-2">
                        <label>{t("Marketplace_text")}</label>
                      </div>
                      <div className="mr-2">
                        <input
                          type="radio"
                          name="is_allow_in_search"
                          value={1}
                          id="yes"
                          checked={formik.values.is_allow_in_search == 1}
                          onChange={handleRadioChange}
                          onBlur={formik.handleBlur}
                        />
                        <label htmlFor="yes" className="mr-4">{t("Yes")}</label>
                      </div>
                      <div className="mr-2">
                        <input
                          type="radio"
                          name="is_allow_in_search"
                          value={0}
                          id="no"
                          checked={formik.values.is_allow_in_search == 0}
                          onChange={handleRadioChange}
                          onBlur={formik.handleBlur}
                        />
                        <label htmlFor="no">{t("No")}</label>
                      </div>

                    </div>
                    <div className="flex items-center i-agree">
                      <div className="mr-2 ">
                        <label>{t("Pwa_text")}</label>
                      </div>
                      <div className="mr-2">
                        <input
                          type="radio"
                          name="is_enabledPwa"
                          className="radiobtn"
                          value={1}
                          id="yess"
                          checked={formik.values.is_enabledPwa == 1}
                          onChange={handlePWARadioChange}
                          onBlur={formik.handleBlur}
                        />
                        <label htmlFor="yess" className="mr-4">{t("Yes")}</label>
                      </div>
                      <div className="mr-2">
                        <input
                          className="radiobtn"
                          type="radio"
                          name="is_enabledPwa"
                          value={0}
                          id="noo"
                          checked={formik.values.is_enabledPwa == 0}
                          onChange={handlePWARadioChange}
                          onBlur={formik.handleBlur}
                        />
                        <label htmlFor="noo">{t("No")}</label>
                      </div>

                    </div>
                    <div className="d-flex justify-content-between mb-4 col-md-12 AddDetailsBanner_logoWrapper">
                      {isBannerShow(businessObj?.theme_color) ? (
                        <div class="mb-4 col-md-8">
                          <div className="boxbanner DetailsSelectBanner_wrapper">
                            <label className="form-label detailsdptext">
                              {t("Select_banner")} <span style={{ fontSize: "12px" }}>{getLogoSizeLabel(businessObj?.theme_color)}</span>
                              <span className="text-danger">
                                {isBannerRequired ? " *" : " "}
                              </span>
                            </label>
                            <div className="bannerbox">
                              <img
                                src={
                                  bannerImageUrl
                                    ? bannerImageUrl?.includes("base64")
                                      ? bannerImageUrl
                                      : bannerImageUrl == "null"
                                        ? ""
                                        : `${environmentVariables?.s3UrlBackendUrl}${bannerImageUrl}`
                                    : bannerimage
                                  // : ""
                                }
                                className="mx-auto d-block img-fluid"
                                onClick={(e) => handleImageClickBanner(e)}
                              ></img>
                            </div>
                            <input
                              className="form-control d-none"
                              type="file"
                              name="banner" // Set the name to match the Multer field name
                              onChange={handleBannerChange}
                              ref={fileInputRefBanner}
                            />
                            <p className="imgserve">{t("Note_banner")}</p>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* <div className="row justify-content-end px-0 mb-4">
           
            <div className="col-12 col-lg-3 Changeblock_saveandpreview_buttonWrapper">
              {isSubmit ? (
                <CircularLoader size={40} />
              ) : (
                <button
                  type="button"
                  className="btn btn-cu mb-4 w-100 save-cta Changeblock_saveandpreview_button"
                  onClick={(e) => handleSubmit(e)}
                >
                  {"Save & Next"}
                </button>
              )}
            </div>
          </div> */}
                      {/* <div class="mb-3 col-md-12">
          <div className="sec-gap">
            <h3 className="templatheading">Your Page Code</h3>
            <div class="input-group mb-1">
              <span class="input-group-text" id="basic-addon3">
                {`https://vmycards.com/users/${businessObj?.slug}`}
              </span>
              <input
                value={businessObj?.slug}
                type="text"
                class="form-control"
                disabled={true}
              />
            </div>
            <small className="etidspan">
              *minimum 5 characters required.
            </small>
          </div>
        </div> */}

                      {isLogoShow(businessObj?.theme_color) ? (
                        <div className="mb-3 col-md-4">
                          <div className="boxbanner detailsdptextlogowrapper">
                            <label className="form-label detailsdptext DetailsSelectLogo">
                              {t("Select_logo")}
                            </label>
                            <div className="bannerbox2 AdddetailsdpContainer">
                              <img
                                src={
                                  logoImageUrl
                                    ? logoImageUrl?.includes("base64")
                                      ? logoImageUrl
                                      : logoImageUrl == "null"
                                        ? ""
                                        : `${baseUrl}uploads/${logoImageUrl}`
                                    : avatar
                                }
                                className="mx-auto d-block"
                                onClick={handleImageClickLogo}
                              />
                            </div>
                            {/* <p>
                <strong>Size : (150 x 150)</strong>
              </p> */}
                            <input
                              className="form-control d-none"
                              type="file"
                              name="logo" // Set the name to match the Multer field name
                              onChange={handleLogoChange}
                              ref={fileInputRefLogo}
                              accept=".png, .jpg, .jpeg"
                            />
                          </div>

                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="AddDetailsNameDestiCom_Wrapper">
                      <div className="mb-3 col-md-4 AddDetailswidContainer">
                        <label className="form-label">{t("Name")}*</label>
                        <input
                          // value={subTitle}
                          className="form-control"
                          type="text"
                          placeholder={`${t("Name")}`}
                          // maxLength="40"
                          // onChange={(e) => setSubTitle(e.target.value)}
                          {...formik.getFieldProps("subTitle")}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.subTitle && formik.errors.subTitle && (
                          <ErrorStyle className="error">
                            {formik.errors.subTitle}
                          </ErrorStyle>
                        )}
                      </div>

                      {/* <div className="mb-3 col-md-4 AddDetailswidContainer AddDetailsMarContainer">
                  <label className="form-label">Designation* </label>
                  <input
                    // value={designation}
                    {...formik.getFieldProps("designation")}
                    className="form-control"
                    type="text"
                    placeholder="Designation"
                    // onChange={(e) => setDesignation(e.target.value)}
                  />
                  {formik.touched.designation &&
                    formik.errors.designation && (
                      <ErrorStyle className="error">
                        {formik.errors.designation}
                      </ErrorStyle>
                    )}
                </div> */}

                      <div className="mb-3 col-md-4 AddDetailswidContainer AddDetailsMarContainer">
                        <label className="form-label">{t("Company_name")}*</label>
                        <input
                          value={title}
                          className="form-control"
                          type="text"
                          placeholder={`${t("Company_name")}`}
                          // onChange={(e) => setTitle(e.target.value)}
                          onChange={(e) => handleChangeCompanyName(e)}
                        />
                      </div>
                      <div className="mb-3 col-md-4 AddDetailswidContainer dine_available">
                        <label className="form-label">
                          <input
                            type="checkbox"
                            checked={isShowBusinessName ? true : false}
                            onChange={(e) => handleIsShow(e)}
                          />
                          {t("Show_hide")}
                        </label>
                      </div>
                    </div>

                    <div className="mb-3 col-md-12">
                  <div  className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative', alignItems:"center" }}>
                          {
                          editableHeading === 'description' ?
                            (<input
                              value={descriptionHeading}
                              className="form-control"
                              type="text"
                              placeholder={`${t("descriptionheading")}`}
                              onChange={(e) => handledescriptionHeading(e)}
                              style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                            />) : ( <label className="form-label">{descriptionHeading ? descriptionHeading : t("Description")}*</label>)                      
                          }
                          <button type="button"
                                  style={{fontSize : "12px", marginBottom:"0.5rem" }}
                                  onClick={(e) => {
                                    handleHeadingEdit('description');
                                  }} >
                                  {editableHeading === 'description' ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}
                          </button>
                  </div>
                    <textarea
                      {...formik.getFieldProps("description")}
                      className="form-control"
                      rows="4"
                      placeholder={`${t("Description")}`}
                    ></textarea>
                    {formik.touched.description &&
                      formik.errors.description && (
                        <ErrorStyle className="error">
                          {formik.errors.description}
                        </ErrorStyle>
                      )}
                  </div>

                    {/* {(userData.user_type === 'Team Leader' || userData.user_type === 'Sales Executive' || userData.user_type === 'super_admin') && (
                          <div className="mb-3 col-md-12">
                            <label className="form-label" style={{ fontSize: "17px" }}>
                              Business Owner
                            </label>
                            <div className="form-control">
                              <select
                                style={{ width: '100%' }}
                                value={businessOwnerId}
                                onChange={(e) => setBusinessOwnerId(e.target.value)}
                                disabled={businessObjData?.bo_id === businessObjData?.created_by}
                              >
                                <option value="">Select Business Owner</option>
                                {businessOwnerList?.map((ele) => (
                                  <option key={ele.id} value={ele.id}>
                                    {ele.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )} */}

                    {(userData.user_type === "Team Leader" ||
                      userData.user_type === "Sales Executive" ||
                      userData.user_type === "super_admin") && (
                        <div className="mb-3 col-md-12">
                          <label className="form-label" style={{ fontSize: "17px" }}>
                            {t("Business_owner")}
                          </label>
                          <div className="form-control" style={{position: "relative", zIndex: "100"}}>
                            <AsyncPaginate
                              className="asyncPaginate"
                              // style={{ zIndex: '999 !important' }}
                              value={selectBusinessOwnerId} // Controlled by state
                              loadOptions={loadOptions}
                              onChange={(selectedOption) => {
                                setSelectBusinessOwnerId(selectedOption);
                                setBusinessOwnerId(selectedOption?.value);
                                setbusinessOwnerName(businessObj?.Owner_name);
                              }}
                              additional={{
                                page: 1,
                              }}
                              placeholder={businessObj?.Owner_name === 'Super Admin' ? t("Select_business_owner") : businessObj?.Owner_name || t("Select_business_owner")}

                              isDisabled={userData.user_type !== "super_admin" && (businessObjData?.bo_id === businessObjData?.created_by)}
                            />
                          </div>
                        </div>
                      )}

                    {/* <div class="mb-3 col-md-12 ">
          <div class="form-group">
            <label class="form-label">Personalized link:</label>
            <div class="input-group">
              <input
                type="text"
                className="form-control"
                readonly=""
                value=" https://vmycards.com/freedom-5"
              />
              <input
                className="input-group-text text-start"
                placeholder="Enter Slug"
                name="slug"
                type="text"
                onChange={(e) => setSlug(e.target.value)}
                value={slug}
              />
            </div>
          </div>
        </div> */}
                    <label className="form-label" style={{ fontSize: "17px" }}>
                      {t("Settings")}
                    </label>
                    {/* <h5 style={{fontWeight: "400"}} className="settings">Settings:</h5> */}

                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                            <div className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative' }}>
                              {editableHeading === 'contactInfo' ? (
                                <input
                                  type="text"
                                  value={contactHeading}
                                  onChange={(e) => setcontactHeading(e.target.value)}
                                  placeholder={t("Contact_info_heading")}
                                  style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                                />
                              ) : (
                                <h5 style={{ fontWeight: "400" }} border-0>
                                  {contactHeading ? contactHeading : t("Contact_info")}
                                </h5>
                              )}
                              <button
                                type="button"
                                style={{fontSize : "12px" }}
                                onClick={(e) => {
                                  handleHeadingEdit('contactInfo');
                                }}
                              >
                                {editableHeading === 'contactInfo' ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}

                              </button>
                            </div>

                            <div className="form-check form-switch">
                              <label
                                className="form-check-label"
                              >
                                {t("On_Off")}
                              </label>
                              <input
                                onClick={(e) => {
                                  setIsEnabledContactInfo(!isEnabledContactInfo);
                                  setOnState({
                                    ...onState,
                                    contactInfo: !onState.contactInfo,
                                  });
                                }}
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                onChange={() => toggleDiv("div1")}
                                checked={onState?.contactInfo}
                              />
                            </div>
                            <button
                              type="button"
                              className="accordion-button collapsed  accordianCustom"

                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="false"
                              aria-controls="collapseOne"

                            >

                            </button>
                          </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse "
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body AddDetailsContactInfoInnerForm">
                            {/* {divStates.div1 && ( */}
                            <div className="row MobileDetailsWrapper">
                              <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                                <label className="form-label">
                                  {t("Mobile_number")}
                                </label>
                                <PhoneInput
                                  country={countryCode}
                                  enableSearch={true}
                                  countryCodeEditable={false}
                                  value={formik.values.contactInfoPhone || `+${countryCode}`}
                                  onChange={(value, country) => {
                                    if (country.dialCode) {
                                      formik.setFieldValue(
                                        "contactInfoPhone",
                                        `+${country.dialCode}-${value.substring(
                                          country.dialCode.length
                                        )}`
                                      );

                                      setCountryName(country.name);
                                    } else {
                                      formik.setFieldValue(
                                        "contactInfoPhone",
                                        value
                                      );
                                    }
                                  }}
                                  onBlur={(e, country) => handleBlur(e, country) && formik.handleBlur("contactInfoPhone")}
                                  className="DetailsInfoPhoneInput"
                                  isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
                                />
                                {formik.touched.contactInfoPhone &&
                                  formik.errors.contactInfoPhone && (
                                    <ErrorStyle className="error">
                                      {formik.errors.contactInfoPhone}
                                    </ErrorStyle>
                                  )}
                                {invalidPhoneErr && <ErrorStyle className="error">
                                  {invalidPhoneErr}
                                </ErrorStyle>}
                              </div>
                              <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                                <label className="form-label">
                                  {t("Alternative_mobile_number")}
                                </label>
                                <PhoneInput
                                  country={countryCode}
                                  enableSearch={true}
                                  countryCodeEditable={false}
                                  value={formik.values.optionalContactInfoPhone || `+${countryCode}`}
                                  onChange={(value, country) => {
                                    if (country.dialCode) {
                                      formik.setFieldValue(
                                        "optionalContactInfoPhone",
                                        `+${country.dialCode}-${value.substring(
                                          country.dialCode.length
                                        )}`
                                      );
                                      setCountryName(country.name);
                                    } else {
                                      formik.setFieldValue(
                                        "optionalContactInfoPhone",
                                        value
                                      );
                                    }
                                  }}
                                  // onBlur={formik.handleBlur("optionalContactInfoPhone")}
                                  onBlur={(e, country) => handleBlurOptional(e, country) && formik.handleBlur("optionalContactInfoPhone")}
                                  // {...formik.getFieldProps('optionalContactInfoPhone')}
                                  className="DetailsInfoPhoneInput"
                                  isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
                                />
                                {formik.touched.optionalContactInfoPhone &&
                                  formik.errors.optionalContactInfoPhone && (
                                    <ErrorStyle className="error">
                                      {formik.errors.optionalContactInfoPhone}
                                    </ErrorStyle>
                                  )}
                                {invalidOptPhoneErr && <ErrorStyle className="error">
                                  {invalidOptPhoneErr}
                                </ErrorStyle>}
                              </div>
                              <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                                <label className="form-label">{t("Email")}*</label>
                                <input
                                  {...formik.getFieldProps("contactInfoEmail")}
                                  // value={contactInfoEmail}
                                  // value={formik.values.contactInfoEmail}
                                  className="form-control"
                                  type="text"
                                  placeholder={`${t("Email")}`}
                                // onChange={(e) =>
                                //   setContactInfoEmail(e.target.value)
                                // }
                                // onChange={formik.handleChange('contactInfoEmail')}
                                />
                                {formik.touched.contactInfoEmail &&
                                  formik.errors.contactInfoEmail && (
                                    <ErrorStyle className="error">
                                      {formik.errors.contactInfoEmail}
                                    </ErrorStyle>
                                  )}
                              </div>
                              <div className="mb-3 col-md-6">
                                <label className="form-label">{t("Shipping_country")}</label>
                                <div className="col">
                                  <select
                                    className="form-select"
                                    name="shipCountry"
                                    value={formik.values.shipCountry || "IN"}
                                    onChange={formik.handleChange}
                                  >
                                    <option value=""> {t("Select Country")}</option>
                                    {!!dynamicCountry && dynamicCountry?.length > 0 && dynamicCountry?.map((country) => (
                                      !!country?.id &&
                                      <option key={country?.name} value={country?.short_code}>
                                        {country?.name?.charAt(0)?.toUpperCase() + country?.name?.slice(1)}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="mb-3 col-md-12">
                                <label className="form-label">{t("Web_url")}</label>
                                <input
                                  {...formik.getFieldProps("contactInfoWebUrl")}
                                  // value={contactInfoWebUrl}
                                  className="form-control"
                                  type="text"
                                  placeholder={`${t("Web_url")}`}
                                // onChange={(e) =>
                                //   setContactInfoWebUrl(e.target.value)
                                // }
                                />
                                {formik.touched.contactInfoWebUrl &&
                                  formik.errors.contactInfoWebUrl && (
                                    <ErrorStyle className="error">
                                      {formik.errors.contactInfoWebUrl}
                                    </ErrorStyle>
                                  )}
                              </div>
                              <div className="mb-3 col-md-12">
                                <label className="form-label">{t("Address")}*</label>
                                <textarea
                                  value={contactInfoAddress}
                                  className="form-control"
                                  rows="3"
                                  maxLength={250}
                                  placeholder={`${t("Address")}`}
                                  onChange={(e) =>
                                    handleContactInfoAddressChange(e)
                                  }
                                  // onBlur={handleGetLatLang}
                                  disabled={isAddressDisabled}
                                ></textarea>
                                {contactInfoAddressError && <p className="error-mgs text-danger">{contactInfoAddressError}</p>}
                                {formik.errors.contactInfoAddress && (
                                  <ErrorStyle className="error">
                                    {formik.errors.contactInfoAddress}
                                  </ErrorStyle>
                                )}
                                <div style={{ display: "flex", justifyContent: "space-between" }}>  <a className="btn btn-info-detail" onClick={handleGetLatLang}>{t("Submit")}</a> <label className="form-label">
                                  <input type="checkbox" checked={isShowAddress ? true : false} onChange={(e) => handleIsShowAddress(e)} /> {t("Show_hide")}</label>
                                </div>
                                {latLngError && <p className="text-danger mt-2" style={{ fontSize: "12px" }}>{latLngError}</p>}
                                {isAddressSubmit ? (
                                  <CircularLoader size={40} />
                                ) : (
                                  addressPlaceId && formik.values.contactInfoAddress && (
                                    <div className="">
                                      <div className="">
                                        <a
                                          href={`https://www.google.com/maps/place/?q=place_id:${addressPlaceId}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="btn btn-link"
                                          style={{ fontSize: "14px", color: "#00b721", fontWeight: "500", padding: "10px 0px" }}
                                        >
                                          {t("Business_address_verify")}
                                        </a>
                                      </div>
                                      <div className="">
                                        {isAddressVerified ? (
                                          <>
                                            <button className="btn btn-success me-2 selectreviewverified" disabled>
                                              {t("Address_verified")}
                                            </button>
                                            <button className="selectreviewedit" onClick={handleIncorrectAddressData}>
                                              {t("Edit")}
                                            </button>
                                          </>
                                        ) : (
                                          <button className="btn btn-success me-2 selectreview 
                                            verifybusinesswtd" onClick={handleCorrectAddressData}>
                                            {t("Verify_address")}
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>

                              <div className="mb-3 col-md-12">
                                <label className="form-label">{t("Alternative_address")}</label>
                                <textarea
                                  value={contactInfoOptAddress}
                                  className="form-control"
                                  rows="3"
                                  maxLength={250}
                                  placeholder={`${t("Address")}`}
                                  onChange={(e) =>
                                    handleContactInfoOptAddressChange(e)
                                  }
                                ></textarea>
                                {contactInfoOptAddressError && <p className="error-mgs text-danger">{contactInfoOptAddressError}</p>}
                              </div>
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                      </div>





                      {/* ///SERVICES-----------------services  */}
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                            onClick={(e) => {
                            }}
                          >
                            <div className="d-flex">
                              <h5 style={{ fontWeight: "400" }}>{t("Category")}</h5>
                            </div>
                          </button>
                          <div className="form-check form-switch">
                            <label
                              className="form-check-label"
                            >
                              {t("On_Off")}
                            </label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              checked={onState?.services}
                              onClick={(e) => {
                                setIsEnabledServices(!isEnabledServices);
                                setOnState({
                                  ...onState,
                                  services: !onState.services,
                                });
                              }}
                              onChange={() => toggleDiv("div2")}
                            />
                          </div>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div
                            className="accordion-body"
                            id="AddnewServicesformwrapper"
                          >
                            <div className="row">
                              <NewBusinessDetilsCreate
                                setServicesArray={setServicesArray}
                                businessObj={businessObj}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* ========= MINI Marketplace Products ============== */}
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                            onClick={() => { }}
                          >
                            <div className="d-flex">
                              <h5 style={{ fontWeight: "400" }}>{t("Products")}</h5>
                            </div>
                          </button>
                          <div className="form-check form-switch">
                            <label className="form-check-label">{t("On_Off")}</label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              checked={onState?.miniproduct}
                              onClick={() => {
                                setIsMinimktProduct(!isMinimktProduct);
                                setOnState((prevState) => ({
                                  ...prevState,
                                  miniproduct: !prevState.miniproduct,
                                }));
                              }}
                              onChange={() => toggleDiv("div9")}
                            />
                          </div>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div
                            className="accordion-body"
                            id="AddnewServicesformwrapper"
                          >
                            <div className="row">
                              <NewminimarketProductCreate
                                setMiniMarketArray={setMiniMarketArray}
                                servicesArray={servicesArray}
                                handleFetchById={handleFetchById}
                                handleSubmitWithoutTabChange={handleSubmitWithoutTabChange}
                                formikData={formik}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* ============== Alternate Urls======================== */}

                      <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                          <div className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative' }}>
                            {/* <h5 style={{ fontWeight: "400" }}>{t("Alternate_urls")}</h5>
                              </div>
                            </button>
                            <input
                                type="text"
                                value={alternateUrlsHeading}
                                onChange={(e) => seAlternateUrlsHeading(e.target.value)}
                                placeholder="Alternate Urls Heading"
                                style={{ marginRight: "10px", padding: "5px", border:"1px solid green" }}
                                /> */}
                                {editableHeading === 'alternateUrl' ? (
                                  <input
                                    type="text"
                                    value={alternateUrlsHeading}
                                    onChange={(e) => seAlternateUrlsHeading(e.target.value)}
                                    placeholder={t("Alternate_urls_heading")}
                                    style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                                    />
                                ) : (
                                  <h5 style={{ fontWeight: "400" }} border-0>
                                    {alternateUrlsHeading ? alternateUrlsHeading : t("Alternate_urls")}
                                  </h5>
                                )}
                                <button
                                  type="button"
                                  style={{fontSize : "12px" }}
                                  onClick={(e) => {
                                    handleHeadingEdit('alternateUrl');
                                    e.stopPropagation();
                                  }}
                                >
                                  {editableHeading === 'alternateUrl'  ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}
                                </button>
                              </div>

                            <div className="form-check form-switch">
                              <label
                                className="form-check-label"
                              >
                                {t("On_Off")}
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                checked={onState?.alternateurl}
                                onClick={() => {
                                  setAlternateUrl(!isAlternateUrl);
                                  setOnState({
                                    ...onState,
                                    alternateurl: !onState.alternateurl,
                                  });
                                }}
                                onChange={() => toggleDiv("div10")}
                              />
                            </div>
                            <button
                              type="button"
                              className="accordion-button collapsed  accordianCustom"

                              data-bs-toggle="collapse"
                              data-bs-target="#ten"
                              aria-expanded="false"
                              aria-controls="ten"

                            >

                            </button>
                          </h2>
                        <div
                          id="ten"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div
                            className="accordion-body"
                            id="AddnewServicesformwrapper"
                          >
                            <div className="row">
                              <NewAlternateUrlCreate
                                setAlternateUrlArray={setAlternateUrlArray}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* =========social=================== */}
                      <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFive">
                          <div className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative' }}>
                            {/* <h5 style={{ fontWeight: "400" }}>{t("Social_media")}</h5>
                              </div>
                            </button>
                            <input
                                type="text"
                                value={socialHeading}
                                onChange={(e) => setSocialHeading(e.target.value)}
                                placeholder="BusinessHours Heading"
                                style={{ marginRight: "10px", padding: "5px", border:"1px solid green" }}
                                /> */}
                                {editableHeading === 'socialMedia' ? (
                                  <input
                                    type="text"
                                    value={socialHeading}
                                    onChange={(e) => setSocialHeading(e.target.value)}
                                    placeholder={t("Enter_Social_heading")}
                                    style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                                    />
                                ) : (
                                  <h5 style={{ fontWeight: "400" }} border-0>
                                    {socialHeading ? socialHeading : t("Social_media")}
                                  </h5>
                                )}
                                <button
                                  type="button"
                                  style={{fontSize : "12px" }}
                                  onClick={(e) => {
                                    handleHeadingEdit('socialMedia');
                                    e.stopPropagation();
                                  }}
                                >
                                  {editableHeading === 'socialMedia' ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}
                                </button>
                              </div>

                            <div className="form-check form-switch">
                              <label
                                className="form-check-label"
                              // for="flexSwitchCheckDefault"
                              >
                                {t("On_Off")}
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                checked={onState?.socials}
                                onChange={() => toggleDiv("div5")}
                                onClick={() => {
                                  setIsSocial(!isSocial);
                                  setOnState({
                                    ...onState,
                                    socials: !onState.socials,
                                  });
                                }}
                              />
                            </div>
                            <button
                              type="button"
                              className="accordion-button collapsed  accordianCustom"

                              data-bs-toggle="collapse"
                              data-bs-target="#five"
                              aria-expanded="false"
                              aria-controls="five"

                            >

                            </button>
                          </h2>
                        <div
                          id="five"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFive"
                          data-bs-parent="#accordionExample"
                        >
                          <div
                            className="accordion-body"
                            id="AddnewServicesformwrapper"
                            style={{ padding: "15px 0" }}
                          >
                            {/* {divStates.div5 && ( */}
                            <div
                              className="row"
                              style={{ justifyContent: "center" }}
                            >
                              <NewSocialmediaCreate
                                setSocialMediaData={setSocialMediaData}
                                socialMediaData={socialMediaData}
                              />
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                      </div>
                      {/* =====business hour===== */}


                      {/* =============Agent Code Mini Market============ */}
                      {(userData.user_type === 'Sales Executive' || userData.user_type === 'Team Leader' || userData.user_type === 'super_admin') ? " " : <div className="accordion-item" style={{ padding: "10px 25px" }}>
                        <h2 className="accordion-header" id="headingEight">
                          <div className="d-flex justify-content-between" style={{ width: "100%" }}>
                            <h5 style={{ fontWeight: "400", display: "flex", alignItems: "center" }}>{t("Agent_code")} {t("Optional")}</h5>
                            <input
                              type="text"
                              className="form-control"
                              id="agentCode"
                              value={userData.user_type === 'Sales Executive' ? businessObj?.agentCode || agentCode : agentCode}
                              onChange={validateAgentCodeInput}
                              maxLength={6}
                              placeholder={`${t("Agent_code")}`}
                              style={{ width: "50%" }}
                              readOnly={!!businessObj?.agentCode && (userData.user_type === 'Sales Executive' || !userData.user_type)}
                            />
                          </div>
                          {isVerifyCodeSubmit ? (
                            <>
                              <CircularLoader size={40} />
                              {verifyCodeError && <p className="error-mgs text-danger">{verifyCodeError}</p>}
                            </>
                          ) : agentCode.length === 6 && isVerifyCodeVerified ? (
                            <p className="text-success mt-2">{t("Verified")}</p>
                          ) : agentCode.length === 6 && !isVerifyCodeVerified ? (
                            <p className="text-danger mt-2">{t("Not_verified")}</p>
                          ) : agentCode.length > 0 && agentCode.length < 6 ? (
                            <p className="text-danger mt-2">{t("agent_code_limit")}</p>
                          ) : null}
                        </h2>
                      </div>}
                      {(userData?.user_type == "super_admin" || userData?.user_type == "Team Leader" || userData?.user_type == "Sales Executive") &&
                        <div className="accordion-item" style={{ padding: "10px 25px" }}>
                          <h2 className="accordion-header" id="headingEight">
                            <div className="d-flex justify-content-between" style={{ width: "100%" }}>
                              <h5 style={{ fontWeight: "400", display: "flex", alignItems: "center" }}>{t("Lead_id")}</h5>
                              <input
                                type="text"
                                className="form-control"
                                id="teamlead"
                                value={teamleadId}
                                // value={userData.user_type === 'Sales Executive' ? businessObj?.teamleadId || teamleadId : teamleadId}
                                onChange={validateTeamLeadInput}
                                maxLength={6}
                                placeholder={`${t("Lead_id")}`}
                                style={{ width: "50%" }}
                              // readOnly={!!businessObj?.teamleadId && (userData.user_type === 'Sales Executive' || !userData.user_type)}
                              />
                            </div>
                          </h2>
                        </div>
                      }
                      {userData?.user_type == "super_admin" &&
                        <div className="accordion-item">
                          <div className="accordion-header" id="headingeight">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#eight"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              <div className="d-flex">
                                <h5 style={{ fontWeight: "400" }}>{t("Meta_pixel_code")}</h5>
                              </div>
                            </button>

                          </div>
                          <div
                            id="eight"
                            className={`accordion-collapse collapse`}
                            aria-labelledby="headingeight"
                            data-bs-parent="#accordionExample"
                            style={{ paddingTop: "10px" }}
                          >
                            <div className="mb-3 col-md-12 AddDetailswidContainer">
                              <input
                                value={pixel_id}
                                className="form-control"
                                type="text"
                                placeholder={`${t("Meta_pixel_code")}`}
                                onChange={(e) => handleChangePixelId(e)}
                              />
                            </div>

                          </div>
                        </div>
                      }

                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwofive">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwofive"
                            aria-expanded="false"
                            aria-controls="collapseTwofive"
                            onClick={(e) => {
                            }}
                          >
                            <div className="d-flex">
                              <h5 style={{ fontWeight: "400" }}> {t("Keywords_metatags")}</h5>
                            </div>
                          </button>
                        </h2>
                        <div
                          id="collapseTwofive"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwofive"
                          data-bs-parent="#accordionExample"
                        >
                          <div
                            className="accordion-body"
                            id="AddnewServicesformwrapper"
                          >
                            <div className="row">
                              <NewBusinessDetilsCreateKeywords
                                setKeywordArray={setKeywordArray}
                                businessObj={businessObj}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <div className="accordion-header" id="headingnine">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#nine"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <div className="d-flex">
                              <h5 style={{ fontWeight: "400" }}>{t("Meta_title")}</h5>
                            </div>
                          </button>

                        </div>
                        <div
                          id="nine"
                          className={`accordion-collapse collapse`}
                          aria-labelledby="headingnine"
                          data-bs-parent="#accordionExample"
                          style={{ paddingTop: "10px" }}
                        >
                          <div className="mb-3 col-md-12 AddDetailswidContainer">
                            <input
                              value={metaTitle}
                              className="form-control"
                              type="text"
                              placeholder={`${t("Meta_title")}`}
                              onChange={(e) => handleChangeMetaTitle(e)}
                            />
                            {metaTitle.length >= 65 && (
                              <small style={{ color: "red" }}>
                                {t("Meta_titl_limit")}
                              </small>
                            )}
                          </div>

                        </div>
                      </div>

                      <div className="accordion-item">
                        <div className="accordion-header" id="headingeleven">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#eleven"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <div className="d-flex">
                              <h5 style={{ fontWeight: "400" }}>{t("Meta_description")}</h5>
                            </div>
                          </button>

                        </div>
                        <div
                          id="eleven"
                          className={`accordion-collapse collapse`}
                          aria-labelledby="headingeleven"
                          data-bs-parent="#accordionExample"
                          style={{ paddingTop: "10px" }}
                        >
                          <div className="mb-3 col-md-12 AddDetailswidContainer">
                            <input
                              value={metaDescription}
                              className="form-control"
                              type="text"
                              placeholder={`${t("Meta_description")}`}
                              onChange={(e) => handleChangeMetades(e)}
                            />
                            {metaDescription.length >= 165 && (
                              <small style={{ color: "red" }}>
                                {t("Meta_desc_limit")}
                              </small>
                            )}
                          </div>

                        </div>
                      </div>

                      <div className="accordion-item">
                        <div className="accordion-header" id="headingtwelve">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#twelve"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <div className="d-flex">
                              <h5 style={{ fontWeight: "400" }}>{t("Canonical_tag")}</h5>
                            </div>
                          </button>

                        </div>
                        <div
                          id="twelve"
                          className={`accordion-collapse collapse`}
                          aria-labelledby="headingtwelve"
                          data-bs-parent="#accordionExample"
                          style={{ paddingTop: "10px" }}
                        >
                          <div className="mb-3 col-md-12 AddDetailswidContainer">
                            <input
                              value={canonicalTag}
                              className="form-control"
                              type="text"
                              placeholder={`${t("Canonical_tag")}`}
                              onChange={(e) => handleChangeCanicaltag(e)}
                            />
                          </div>

                        </div>
                      </div>

                    </div>
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h4 className="accordion-header" id="headingTwo">
                          <button
                            style={{ fontWeight: "400", fontSize: "15.3px" }}
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseColorPicker"
                            aria-expanded="false"
                            aria-controls="collapseColorPicker"
                          >
                            {t("Theme_color_picker")}
                          </button>
                        </h4>
                        <div
                          id="collapseColorPicker"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="color-picker-container" >
                              {/* Main Background Color Picker */}
                              <div className="color-picker-box">

                                <div className="color-picker-item">

                                  <p className="colorpicker-name"> {t("Main_background_color")}:</p>
                                  <div className="color-picker-info" style={{ background: dynamicMainBackgroundColor ? `${dynamicMainBackgroundColor}13` : "", border: dynamicMainBackgroundColor ? `1px solid ${dynamicMainBackgroundColor}20` : "" }}>


                                    <div className="color-picker-info-detail" ref={colorPickerContainerRef[0]}>
                                      <div className="color-box-container" style={{ borderColor: dynamicMainBackgroundColor, }}>
                                        <div className="color-box"
                                          onClick={() => toggleColorPicker(0)}
                                          style={{
                                            backgroundColor:
                                              dynamicMainBackgroundColor,
                                            borderColor: dynamicMainBackgroundColor,
                                          }}
                                        ></div>
                                        {isColorPickerOpen[0] && (<SketchPicker
                                          color={dynamicMainBackgroundColor}
                                          onChangeComplete={mainBghandleChangeComplete}
                                        />)}
                                      </div>

                                      <div>

                                        <span
                                          style={{ fontSize: '13px' }}
                                          className="hex-color-name"
                                        >
                                          {t("Hex")}: {dynamicMainBackgroundColor}
                                        </span>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      onClick={() =>
                                        setDynamicMainBackgroundColor("")
                                      }
                                      className="reset-button"
                                    >
                                      {t("Reset")}
                                    </button>
                                  </div>
                                </div>

                                {/* <div className="color-preview">
                                
                                
                              </div> */}
                              </div>

                              {/* Background Color Picker */}
                              <div className="color-picker-box">

                                <div className="color-picker-item">

                                  <p className="colorpicker-name" > {t("Background_color")}:</p>
                                  <div className="color-picker-info" style={{ background: dynamicBackgroundColor ? `${dynamicBackgroundColor}13` : "", border: dynamicBackgroundColor ? `1px solid ${dynamicBackgroundColor}20` : "" }}>


                                    <div className="color-picker-info-detail" ref={colorPickerContainerRef[1]}>
                                      <div className="color-box-container" style={{ borderColor: dynamicBackgroundColor }}>
                                        <div className="color-box"
                                          onClick={() => toggleColorPicker(1)}
                                          style={{
                                            backgroundColor:
                                              dynamicBackgroundColor,
                                            borderColor: dynamicBackgroundColor,
                                          }}
                                        ></div>
                                        {isColorPickerOpen[1] && (<SketchPicker
                                          color={dynamicBackgroundColor}
                                          onChangeComplete={handleChangeComplete}
                                        />)}
                                      </div>

                                      <div>

                                        <span
                                          style={{ fontSize: '13px' }} className="hex-color-name"
                                        >
                                          {t("Hex")}: {dynamicBackgroundColor}
                                        </span>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      onClick={() =>
                                        setDynamicBackgroundColor("")
                                      }
                                      className="reset-button"
                                    >
                                      {t("Reset")}
                                    </button>
                                  </div>
                                </div>
                              </div>

                              {/* Heading Color Picker */}
                              <div className="color-picker-box">

                                <div className="color-picker-item">

                                  <p className="colorpicker-name" > {t("Heading_color")}:</p>
                                  <div className="color-picker-info" style={{ background: dynamicTextHeadingColor ? `${dynamicTextHeadingColor}13` : "", border: dynamicTextHeadingColor ? `1px solid ${dynamicTextHeadingColor}20` : "" }}>


                                    <div className="color-picker-info-detail" ref={colorPickerContainerRef[2]}>
                                      <div className="color-box-container" style={{ borderColor: dynamicTextHeadingColor }}>
                                        <div className="color-box"
                                          onClick={() => toggleColorPicker(2)}
                                          style={{
                                            backgroundColor:
                                              dynamicTextHeadingColor,
                                            borderColor: dynamicTextHeadingColor,
                                          }}
                                        ></div>
                                        {isColorPickerOpen[2] && (<SketchPicker
                                          color={dynamicTextHeadingColor}
                                          onChangeComplete={
                                            HeadingcolorhandleChangeComplete
                                          }
                                        />)}
                                      </div>

                                      <div>

                                        <span
                                          style={{ fontSize: '13px' }} className="hex-color-name"
                                        >
                                          {t("Hex")}: {dynamicTextHeadingColor}
                                        </span>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      onClick={() =>
                                        setDynamicTextHeadingColor("")
                                      }
                                      className="reset-button"
                                    >
                                      {t("Reset")}
                                    </button>
                                  </div>
                                </div>
                              </div>

                              {/* Description Color Picker */}
                              <div className="color-picker-box">

                                <div className="color-picker-item">

                                  <p className="colorpicker-name"> {t("Description_color")}:</p>
                                  <div className="color-picker-info" style={{ background: dynamicTextDescriptionColor ? `${dynamicTextDescriptionColor}13` : "", border: dynamicTextDescriptionColor ? `1px solid ${dynamicTextDescriptionColor}20` : "" }}>


                                    <div className="color-picker-info-detail" ref={colorPickerContainerRef[3]}>
                                      <div className="color-box-container" style={{
                                        borderColor: dynamicTextDescriptionColor
                                      }}>
                                        <div className="color-box"
                                          onClick={() => toggleColorPicker(3)}
                                          style={{
                                            backgroundColor:
                                              dynamicTextDescriptionColor
                                            ,
                                            borderColor: dynamicTextDescriptionColor
                                            ,
                                          }}
                                        ></div>
                                        {isColorPickerOpen[3] && (<SketchPicker
                                          color={dynamicTextDescriptionColor}
                                          onChangeComplete={
                                            DescriptioncolorhandleChangeComplete
                                          }
                                        />)}
                                      </div>

                                      <div>

                                        <span
                                          style={{ fontSize: '13px' }} className="hex-color-name"
                                        >
                                          {t("Hex")}: {dynamicTextDescriptionColor
                                          }
                                        </span>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      onClick={() =>
                                        setDynamicTextDescriptionColor("")
                                      }
                                      className="reset-button"
                                    >
                                      {t("Reset")}
                                    </button>
                                  </div>
                                </div>

                              </div>

                              {/* CTA Color Picker */}
                              <div className="color-picker-box">

                                <div className="color-picker-item">

                                  <p className="colorpicker-name"> {t("Cta_color")}:</p>
                                  <div className="color-picker-info" style={{ background: dynamicCtaColor ? `${dynamicCtaColor}13` : "", border: dynamicCtaColor ? `1px solid ${dynamicCtaColor}20` : "" }}>


                                    <div className="color-picker-info-detail" ref={colorPickerContainerRef[4]}>
                                      <div className="color-box-container" style={{
                                        borderColor: dynamicCtaColor
                                      }}>
                                        <div className="color-box"
                                          onClick={() => toggleColorPicker(4)}
                                          style={{
                                            backgroundColor:
                                              dynamicCtaColor
                                            ,
                                            borderColor: dynamicCtaColor
                                            ,
                                          }}
                                        ></div>
                                        {isColorPickerOpen[4] && (<SketchPicker
                                          color={dynamicCtaColor}
                                          onChangeComplete={
                                            CtacolorhandleChangeComplete
                                          }
                                        />)}
                                      </div>

                                      <div>

                                        <span
                                          style={{ fontSize: '13px' }} className="hex-color-name"
                                        >
                                          {t("Hex")}: {dynamicCtaColor
                                          }
                                        </span>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      onClick={() =>
                                        setdynamicCtaColor("")
                                      }
                                      className="reset-button"
                                    >
                                      {t("Reset")}
                                    </button>
                                  </div>
                                </div>
                              </div>

                              {/* CTA TeXt Color Picker */}
                              <div className="color-picker-box">

                                <div className="color-picker-item">

                                  <p className="colorpicker-name"> {t("Cta_text_color")}:</p>
                                  <div className="color-picker-info" style={{ background: dynamicCtaTextColor ? `${dynamicCtaTextColor}13` : "", border: dynamicCtaTextColor ? `1px solid ${dynamicCtaTextColor}20` : "" }}>


                                    <div className="color-picker-info-detail" ref={colorPickerContainerRef[5]}>
                                      <div className="color-box-container" style={{ borderColor: dynamicCtaTextColor, }}>
                                        <div className="color-box"
                                          onClick={() => toggleColorPicker(5)}
                                          style={{
                                            backgroundColor:
                                              dynamicCtaTextColor,
                                            borderColor: dynamicCtaTextColor,
                                          }}
                                        ></div>
                                        {isColorPickerOpen[5] && (<SketchPicker
                                          color={dynamicCtaTextColor}
                                          onChangeComplete={ctaTextcolorHandler}
                                        />)}
                                      </div>

                                      <div>

                                        <span
                                          style={{ fontSize: '13px' }}
                                          className="hex-color-name"
                                        >
                                          {t("Hex")}: {dynamicCtaTextColor}
                                        </span>
                                      </div>
                                    </div>

                                    <button
                                      type="button"
                                      onClick={() =>
                                        setdynamicCtaTextColor("")
                                      }
                                      className="reset-button"
                                    >
                                      {t("Reset")}
                                    </button>
                                  </div>
                                </div>

                                {/* <div className="color-preview">
                                
                                
                              </div> */}
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row Adddetails_saveprev_ButtonWrapper">
                      <div className="Changeblock_saveandpreview_buttonWrapper Changeblock_saveandpreview_buttonWrappertwo">
                        <button
                          className="btn btn-cu save-cta Changeblock_saveandpreview_button "
                          id="changeblockprenxtbtn"
                          type="button"
                          onClick={handlePreviousClick}
                        >
                          {t("Previous")}
                        </button>
                        {/* </div>
            <div className="Changeblock_saveandpreview_buttonWrapper"> */}
                        {isSubmit ? (
                          <CircularLoader size={40} />
                        ) : (
                          <button
                            type="button"
                            className="btn btn-cu save-cta Changeblock_saveandpreview_button"
                            id="changeblockprenxtbtn"
                            onClick={(e) => handleSubmitWithoutTabChange(e)}
                          >
                            {t("Save_preview")}
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>



                ////////////////////////////////////end mini marketplace ////////////////////////////

              ) : (
                themeNumber == 22 ? <> <div className="tile">
                  <form onSubmit={formik.handleSubmit} className="row">
                    <div className="flex items-center i-agree">
                      <div className="mr-2">
                        <label>{t("Marketplace_text")}</label>
                      </div>
                      <div className="mr-2">
                        <input
                          type="radio"
                          name="is_allow_in_search"
                          value={1}
                          id="yes"
                          checked={formik.values.is_allow_in_search == 1}
                          onChange={handleRadioChange}
                          onBlur={formik.handleBlur}
                        />
                        <label htmlFor="yes" className="mr-4">{t("Yes")}</label>
                      </div>
                      <div className="mr-2">
                        <input
                          type="radio"
                          name="is_allow_in_search"
                          value={0}
                          id="no"
                          checked={formik.values.is_allow_in_search == 0}
                          onChange={handleRadioChange}
                          onBlur={formik.handleBlur}
                        />
                        <label htmlFor="no">{t("No")}</label>
                      </div>

                    </div>
                    <div className="flex items-center i-agree">
                      <div className="mr-2 ">
                        <label>{t("Pwa_text")}</label>
                      </div>
                      <div className="mr-2">
                        <input
                          type="radio"
                          name="is_enabledPwa"
                          className="radiobtn"
                          value={1}
                          id="yess"
                          checked={formik.values.is_enabledPwa == 1}
                          onChange={handlePWARadioChange}
                          onBlur={formik.handleBlur}
                        />
                        <label htmlFor="yess" className="mr-4">{t("Yes")}</label>
                      </div>
                      <div className="mr-2">
                        <input
                          className="radiobtn"
                          type="radio"
                          name="is_enabledPwa"
                          value={0}
                          id="noo"
                          checked={formik.values.is_enabledPwa == 0}
                          onChange={handlePWARadioChange}
                          onBlur={formik.handleBlur}
                        />
                        <label htmlFor="noo">{t("No")}</label>
                      </div>

                    </div>
                    <div className="d-flex justify-content-between mb-4 col-md-12 AddDetailsBanner_logoWrapper">
                      {isBannerShow(businessObj?.theme_color) ? (
                        <div class="mb-4 col-md-8">
                          <div className="boxbanner DetailsSelectBanner_wrapper">
                            <label className="form-label detailsdptext">
                              {t("Select_banner")} <span style={{ fontSize: "12px" }}>{getLogoSizeLabel(businessObj?.theme_color)}</span>
                              <span className="text-danger">
                                {isBannerRequired ? " *" : " "}
                              </span>
                            </label>
                            <div className="bannerbox">
                              <img
                                src={
                                  bannerImageUrl
                                    ? bannerImageUrl?.includes("base64")
                                      ? bannerImageUrl
                                      : bannerImageUrl == "null"
                                        ? ""
                                        : `${environmentVariables?.s3UrlBackendUrl}${bannerImageUrl}`
                                    : bannerimage
                                  // : ""
                                }
                                className="mx-auto d-block img-fluid"
                                onClick={(e) => handleImageClickBanner(e)}
                              ></img>
                            </div>
                            <input
                              className="form-control d-none"
                              type="file"
                              name="banner" // Set the name to match the Multer field name
                              onChange={handleBannerChange}
                              ref={fileInputRefBanner}
                            />
                            <p className="imgserve">{t("Note_banner")}</p>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* <div className="row justify-content-end px-0 mb-4">
         
          <div className="col-12 col-lg-3 Changeblock_saveandpreview_buttonWrapper">
            {isSubmit ? (
              <CircularLoader size={40} />
            ) : (
              <button
                type="button"
                className="btn btn-cu mb-4 w-100 save-cta Changeblock_saveandpreview_button"
                onClick={(e) => handleSubmit(e)}
              >
                {"Save & Next"}
              </button>
            )}
          </div>
        </div> */}
                      {/* <div class="mb-3 col-md-12">
        <div className="sec-gap">
          <h3 className="templatheading">Your Page Code</h3>
          <div class="input-group mb-1">
            <span class="input-group-text" id="basic-addon3">
              {`https://vmycards.com/users/${businessObj?.slug}`}
            </span>
            <input
              value={businessObj?.slug}
              type="text"
              class="form-control"
              disabled={true}
            />
          </div>
          <small className="etidspan">
            *minimum 5 characters required.
          </small>
        </div>
      </div> */}

                      {isLogoShow(businessObj?.theme_color) ? (
                        <div className="mb-3 col-md-4">
                          {themeNumber != 18 ? (
                            <div className="boxbanner detailsdptextlogowrapper">
                              <label className="form-label detailsdptext DetailsSelectLogo">
                                {t("Select_logo")}
                              </label>
                              <div className="bannerbox2 AdddetailsdpContainer">
                                <img
                                  src={
                                    logoImageUrl
                                      ? logoImageUrl?.includes("base64")
                                        ? logoImageUrl
                                        : logoImageUrl == "null"
                                          ? ""
                                          : `${environmentVariables?.s3UrlBackendUrl}${logoImageUrl}`
                                      : avatar
                                  }
                                  className="mx-auto d-block"
                                  onClick={handleImageClickLogo}
                                />
                              </div>
                              {/* <p>
              <strong>Size : (150 x 150)</strong>
            </p> */}
                              <input
                                className="form-control d-none"
                                type="file"
                                name="logo" // Set the name to match the Multer field name
                                onChange={handleLogoChange}
                                ref={fileInputRefLogo}
                                accept=".png, .jpg, .jpeg"
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="AddDetailsNameDestiCom_Wrapper">
                      <div className="mb-3 col-md-4 AddDetailswidContainer">
                        <label className="form-label">{t("Name")}*</label>
                        <input
                          // value={subTitle}
                          className="form-control"
                          type="text"
                          placeholder={`${t("Name")}`}
                          // maxLength="40"
                          // onChange={(e) => setSubTitle(e.target.value)}
                          {...formik.getFieldProps("subTitle")}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.subTitle && formik.errors.subTitle && (
                          <ErrorStyle className="error">
                            {formik.errors.subTitle}
                          </ErrorStyle>
                        )}
                      </div>
                      {/* 
                    <div className="mb-3 col-md-4 AddDetailswidContainer AddDetailsMarContainer">
                      <label className="form-label">Designation* </label>
                      <input
                        // value={designation}
                        {...formik.getFieldProps("designation")}
                        className="form-control"
                        type="text"
                        placeholder="Designation"
                      // onChange={(e) => setDesignation(e.target.value)}
                      />
                      {formik.touched.designation &&
                        formik.errors.designation && (
                          <ErrorStyle className="error">
                            {formik.errors.designation}
                          </ErrorStyle>
                        )}
                    </div> */}

                      <div className="mb-3 col-md-4 AddDetailswidContainer AddDetailsMarContainer">
                        <label className="form-label">{t("Company_name")}*</label>
                        <input
                          value={title}
                          className="form-control"
                          type="text"
                          placeholder={`${t("Company_name")}`}
                          // onChange={(e) => setTitle(e.target.value)}
                          onChange={(e) => handleChangeCompanyName(e)}
                        />
                      </div>
                      <div className="mb-3 col-md-4 AddDetailswidContainer dine_available">
                        <label className="form-label">
                          <input
                            type="checkbox"
                            checked={isShowBusinessName ? true : false}
                            onChange={(e) => handleIsShow(e)}
                          />
                          {t("Show_hide")}
                        </label>
                      </div>
                    </div>


                    <div className="mb-3 col-md-12">
                      <div className="d-flex" style={{ gap: "8px", alignItems: "center", width: 'fit-content', zIndex: '99', position: 'relative', alignItems: "center" }}>
                        {
                          editableHeading === 'description' ?
                            (<input
                              value={descriptionHeading}
                              className="form-control"
                              type="text"
                              placeholder={`${t("descriptionheading")}`}
                              onChange={(e) => handledescriptionHeading(e)}
                              autoFocus
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  handleHeadingEdit('description');
                                  e.preventDefault();
                                }
                              }}
                              style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize: '14px', borderRadius: '4px', fontWeight: '400' }}
                            />) : (<label className="form-label">{descriptionHeading ? descriptionHeading : t("Description")}*</label>)
                        }
                        <button type="button"
                          style={{ fontSize: "12px", marginBottom: "0.5rem" }}
                          onClick={(e) => {
                            handleHeadingEdit('description');
                          }} >
                          {editableHeading === 'description' ? <i class="fa-solid fa-check" style={{ background: 'green', borderRadius: '4px', padding: '5px', color: '#fff' }}></i> : <i class="fas fa-edit"></i>}
                        </button>
                      </div>
                      <textarea
                        // value={description}
                        {...formik.getFieldProps("description")}
                        className="form-control"
                        rows="4"
                        placeholder={`${t("Description")}`}
                      // onChange={(e) => setDescription(e.target.value)}
                      >
                        {/* Hi, I'm a graphic designer ! */}
                      </textarea>
                      {formik.touched.description &&
                        formik.errors.description && (
                          <ErrorStyle className="error">
                            {formik.errors.description}
                          </ErrorStyle>
                        )}
                    </div>

                    {/* {(userData.user_type === 'Team Leader' || userData.user_type === 'Sales Executive' || userData.user_type === 'super_admin') && (
                            <div className="mb-3 col-md-12">
                              <label className="form-label" style={{ fontSize: "17px" }}>
                                Business Owner
                              </label>
                              <div className="form-control">
                                <select
                                  style={{ width: '100%' }}
                                  value={businessOwnerId}
                                  onChange={(e) => setBusinessOwnerId(e.target.value)}
                                  disabled={businessObjData?.bo_id === businessObjData?.created_by}
                                >
                                  <option value="">Select Business Owner</option>
                                  {businessOwnerList?.map((ele) => (
                                    <option key={ele.id} value={ele.id}>
                                      {ele.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          )} */}

                    {(userData.user_type === "Team Leader" ||
                      userData.user_type === "Sales Executive" ||
                      userData.user_type === "super_admin") && (
                        <div className="mb-3 col-md-12">
                          <label className="form-label" style={{ fontSize: "17px" }}>
                            {t("Business_owner")}
                          </label>
                          <div className="form-control" style={{position: "relative", zIndex: "100"}}>
                            <AsyncPaginate
                              className="asyncPaginate"
                              // style={{ zIndex: '999 !important' }}
                              value={selectBusinessOwnerId} // Controlled by state
                              loadOptions={loadOptions}
                              onChange={(selectedOption) => {

                                setSelectBusinessOwnerId(selectedOption);
                                setBusinessOwnerId(selectedOption?.value);
                                setbusinessOwnerName(businessObj?.Owner_name);
                              }}
                              additional={{
                                page: 1,
                              }}
                              placeholder={businessObj?.Owner_name === 'Super Admin' ? t("Select_business_owner") : businessObj?.Owner_name || t("Select_business_owner")}

                              isDisabled={userData.user_type !== "super_admin" && (businessObjData?.bo_id === businessObjData?.created_by)}
                            />
                          </div>
                        </div>
                      )}
                    {/* <div class="mb-3 col-md-12 ">
        <div class="form-group">
          <label class="form-label">Personalized link:</label>
          <div class="input-group">
            <input
              type="text"
              className="form-control"
              readonly=""
              value=" https://vmycards.com/freedom-5"
            />
            <input
              className="input-group-text text-start"
              placeholder="Enter Slug"
              name="slug"
              type="text"
              onChange={(e) => setSlug(e.target.value)}
              value={slug}
            />
          </div>
        </div>
      </div> */}
                    <label className="form-label" style={{ fontSize: "17px" }}>
                      {t("Settings")}
                    </label>
                    {/* <h5 style={{fontWeight: "400"}} className="settings">Settings:</h5> */}

                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <div className="d-flex" style={{ gap: "8px", alignItems: "center", width: 'fit-content', zIndex: '99', position: 'relative' }}>
                            {editableHeading === 'contactInfo' ? (
                              <input
                                type="text"
                                value={contactHeading}
                                onChange={(e) => setcontactHeading(e.target.value)}
                                autoFocus
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    handleHeadingEdit('contactInfo');
                                    e.preventDefault();
                                  }
                                }}
                                placeholder={t("Contact_info_heading")}
                                style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize: '14px', borderRadius: '4px', fontWeight: '400' }}
                              />
                            ) : (
                              <h5 style={{ fontWeight: "400" }} border-0>
                                {contactHeading ? contactHeading : t("Contact_info")}
                              </h5>
                            )}
                            <button
                              type="button"
                              style={{ fontSize: "12px" }}
                              onClick={(e) => {
                                handleHeadingEdit('contactInfo');
                                e.stopPropagation();
                              }}
                            >
                              {editableHeading === 'contactInfo' ? <i class="fa-solid fa-check" style={{ background: 'green', borderRadius: '4px', padding: '5px', color: '#fff' }}></i> : <i class="fas fa-edit"></i>}
                            </button>
                          </div>
                          <button
                            className="accordion-button collapsed accordianCustom"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                          </button>
                          <div className="form-check form-switch">
                            <label
                              className="form-check-label"
                            // for="flexSwitchCheckDefault"
                            >
                              {t("On_Off")}
                            </label>
                            <input
                              onClick={(e) => {
                                setIsEnabledContactInfo(!isEnabledContactInfo);
                                setOnState({
                                  ...onState,
                                  contactInfo: !onState.contactInfo,
                                });
                              }}
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              onChange={() => toggleDiv("div1")}
                              checked={onState?.contactInfo}
                            />
                          </div>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse "
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body AddDetailsContactInfoInnerForm">
                            {/* {divStates.div1 && ( */}
                            <div className="row MobileDetailsWrapper">
                              <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                                <label className="form-label">
                                  {t("Mobile_number")}
                                </label>
                                {/* <input
                    value={contactInfoPhone}
                    type="number"
                    className="form-control"
                    onChange={(e) =>
                      setContactInfoPhone(e.target.value)
                    }
                    placeholder="Phone Number"
                  /> */}
                                <PhoneInput
                                  country={countryCode}
                                  enableSearch={true}
                                  countryCodeEditable={false}
                                  value={formik.values.contactInfoPhone || `+${countryCode}`}
                                  onChange={(value, country) => {
                                    if (country.dialCode) {
                                      formik.setFieldValue(
                                        "contactInfoPhone",
                                        `+${country.dialCode}-${value.substring(
                                          country.dialCode.length
                                        )}`
                                      );
                                      setCountryName(country.name);
                                    } else {
                                      formik.setFieldValue(
                                        "contactInfoPhone",
                                        value
                                      );
                                    }
                                  }}
                                  // onBlur={formik.handleBlur("contactInfoPhone")}
                                  onBlur={(e, country) => handleBlur(e, country) && formik.handleBlur("contactInfoPhone")}
                                  // {...formik.getFieldProps('contactInfoPhone')}
                                  className="DetailsInfoPhoneInput"
                                  isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
                                />
                                {formik.touched.contactInfoPhone &&
                                  formik.errors.contactInfoPhone && (
                                    <ErrorStyle className="error">
                                      {formik.errors.contactInfoPhone}
                                    </ErrorStyle>
                                  )}
                                {invalidPhoneErr && <ErrorStyle className="error">
                                  {invalidPhoneErr}
                                </ErrorStyle>}
                              </div>
                              <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                                <label className="form-label">
                                  {t("Alternative_mobile_number")}
                                </label>
                                <PhoneInput
                                  country={countryCode}
                                  enableSearch={true}
                                  countryCodeEditable={false}
                                  value={formik.values.optionalContactInfoPhone || `+${countryCode}`}
                                  onChange={(value, country) => {
                                    if (country.dialCode) {
                                      formik.setFieldValue(
                                        "optionalContactInfoPhone",
                                        `+${country.dialCode}-${value.substring(
                                          country.dialCode.length
                                        )}`
                                      );
                                      setCountryName(country.name);
                                    } else {
                                      formik.setFieldValue(
                                        "optionalContactInfoPhone",
                                        value
                                      );
                                    }
                                  }}
                                  // onBlur={formik.handleBlur("optionalContactInfoPhone")}
                                  onBlur={(e, country) => handleBlurOptional(e, country) && formik.handleBlur("optionalContactInfoPhone")}
                                  // {...formik.getFieldProps('optionalContactInfoPhone')}
                                  className="DetailsInfoPhoneInput"
                                  isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
                                />
                                {formik.touched.optionalContactInfoPhone &&
                                  formik.errors.optionalContactInfoPhone && (
                                    <ErrorStyle className="error">
                                      {formik.errors.optionalContactInfoPhone}
                                    </ErrorStyle>
                                  )}
                                {invalidOptPhoneErr && <ErrorStyle className="error">
                                  {invalidOptPhoneErr}
                                </ErrorStyle>}
                              </div>
                              <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                                <label className="form-label">{t("Email")}*</label>
                                <input
                                  {...formik.getFieldProps("contactInfoEmail")}
                                  // value={contactInfoEmail}
                                  // value={formik.values.contactInfoEmail}
                                  className="form-control"
                                  type="text"
                                  placeholder={`${t("Email")}`}
                                // onChange={(e) =>
                                //   setContactInfoEmail(e.target.value)
                                // }
                                // onChange={formik.handleChange('contactInfoEmail')}
                                />
                                {formik.touched.contactInfoEmail &&
                                  formik.errors.contactInfoEmail && (
                                    <ErrorStyle className="error">
                                      {formik.errors.contactInfoEmail}
                                    </ErrorStyle>
                                  )}
                              </div>
                              <div className="mb-3 col-md-12">
                                <label className="form-label">{t("Web_url")}</label>
                                <input
                                  {...formik.getFieldProps("contactInfoWebUrl")}
                                  // value={contactInfoWebUrl}
                                  className="form-control"
                                  type="text"
                                  placeholder={`${t("Web_url")}`}
                                // onChange={(e) =>
                                //   setContactInfoWebUrl(e.target.value)
                                // }
                                />
                                {formik.touched.contactInfoWebUrl &&
                                  formik.errors.contactInfoWebUrl && (
                                    <ErrorStyle className="error">
                                      {formik.errors.contactInfoWebUrl}
                                    </ErrorStyle>
                                  )}
                              </div>
                              <div className="mb-3 col-md-12">
                                <label className="form-label">{t("Address")}*</label>
                                <textarea
                                  value={contactInfoAddress}
                                  className="form-control"
                                  rows="3"
                                  maxLength={250}
                                  placeholder={`${t("Address")}`}
                                  onChange={(e) =>
                                    handleContactInfoAddressChange(e)
                                  }
                                  // onBlur={handleGetLatLang}
                                  disabled={isAddressDisabled}
                                ></textarea>
                                {contactInfoAddressError && <p className="error-mgs text-danger">{contactInfoAddressError}</p>}
                                {formik.errors.contactInfoAddress && (
                                  <ErrorStyle className="error">
                                    {formik.errors.contactInfoAddress}
                                  </ErrorStyle>
                                )}
                                <a className="btn btn-info-detail" style={{ width: "90px" }} onClick={handleGetLatLang}>{t("Submit")}</a>
                                {latLngError && <p className="text-danger mt-2" style={{ fontSize: "12px" }}>{latLngError}</p>}
                                {isAddressSubmit ? (
                                  <CircularLoader size={40} />
                                ) : (
                                  addressPlaceId && formik.values.contactInfoAddress && (
                                    <div className="">
                                      <div className="">
                                        <a
                                          href={`https://www.google.com/maps/place/?q=place_id:${addressPlaceId}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="btn btn-link"
                                          style={{ fontSize: "14px", color: "#00b721", fontWeight: "500", padding: "10px 0px" }}
                                        >
                                          {t("Business_address_verify")}
                                        </a>
                                      </div>
                                      <div className="">
                                        {isAddressVerified ? (
                                          <>
                                            <button className="btn btn-success me-2 selectreviewverified" disabled>
                                              {t("Address_verified")}
                                            </button>
                                            <button className="selectreviewedit" onClick={handleIncorrectAddressData}>
                                              {t("Edit")}
                                            </button>
                                          </>
                                        ) : (
                                          <button className="btn btn-success me-2 selectreview 
                                            verifybusinesswtd" onClick={handleCorrectAddressData}>
                                            {t("Verify_address")}
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                              <div className="mb-3 col-md-12">
                                <label className="form-label">{t("Alternative_address")}</label>
                                <textarea
                                  value={contactInfoOptAddress}
                                  className="form-control"
                                  rows="3"
                                  maxLength={250}
                                  placeholder={`${t("Address")}`}
                                  onChange={(e) =>
                                    handleContactInfoOptAddressChange(e)
                                  }
                                ></textarea>
                                {contactInfoOptAddressError && <p className="error-mgs text-danger">{contactInfoOptAddressError}</p>}
                              </div>
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                      </div>

                      {/* =========Menu============== */}
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <div className="d-flex" style={{ gap: "8px", alignItems: "center", width: 'fit-content', zIndex: '99', position: 'relative' }}>
                            {editableHeading === 'cabProduct' ? (
                              <input
                                type="text"
                                value={cabProductHeading}
                                onChange={(e) => setCabProductHeading(e.target.value)}
                                autoFocus
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    handleHeadingEdit('cabProduct');
                                    e.preventDefault();
                                  }
                                }}
                                placeholder={t("Cab_product_heading")}
                                style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize: '14px', borderRadius: '4px', fontWeight: '400' }}
                              />
                            ) : (
                              <h5 style={{ fontWeight: "400" }} border-0>
                                {cabProductHeading ? cabProductHeading : t("Cab_products")}
                              </h5>
                            )}
                            <button
                              type="button"
                              style={{ fontSize: "12px" }}
                              onClick={(e) => {
                                handleHeadingEdit('cabProduct');
                                e.stopPropagation();
                              }}
                            >
                              {editableHeading === 'cabProduct' ? <i class="fa-solid fa-check" style={{ background: 'green', borderRadius: '4px', padding: '5px', color: '#fff' }}></i> : <i class="fas fa-edit"></i>}
                            </button>
                          </div>
                          <button
                            className="accordion-button collapsed accordianCustom"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                          </button>
                          <div className="form-check form-switch">
                            <label className="form-check-label">{t("On_Off")}</label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              checked={onState?.cabproduct}
                              onClick={() => {
                                setIsCabProduct(!isCabProduct);
                                setOnState({
                                  ...onState,
                                  cabproduct: !onState.cabproduct,
                                });
                              }}
                              onChange={() => toggleDiv("div9")}
                            />
                          </div>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div
                            className="accordion-body"
                            id="AddnewServicesformwrapper"
                          >
                            <div className="row">
                              <NewCabProductCreate
                                setCabArray={setCabArray}
                                servicesArray={servicesArray}
                                handleFetchById={handleFetchById}
                                handleSubmitWithoutTabChange={handleSubmitWithoutTabChange}
                                formikData={formik}
                              />
                            </div>
                          </div>
                        </div>
                      </div>



                      {/* ============== Alternate Urls======================== */}

                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <div className="d-flex" style={{ gap: "8px", alignItems: "center", width: 'fit-content', zIndex: '99', position: 'relative' }}>
                            {editableHeading === 'alternateUrls' ? (
                              <input
                                type="text"
                                value={alternateUrlsHeading}
                                onChange={(e) => seAlternateUrlsHeading(e.target.value)}
                                autoFocus
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    handleHeadingEdit('alternateUrls');
                                    e.preventDefault();
                                  }
                                }}
                                placeholder={t("Alternate_urls_heading")}
                                style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize: '14px', borderRadius: '4px', fontWeight: '400' }}
                              />
                            ) : (
                              <h5 style={{ fontWeight: "400" }} border-0>
                                {alternateUrlsHeading ? alternateUrlsHeading : t("Alternate_urls")}
                              </h5>
                            )}
                            <button
                              type="button"
                              style={{ fontSize: "12px" }}
                              onClick={(e) => {
                                handleHeadingEdit('alternateUrls');
                                e.stopPropagation();
                              }}
                            >
                              {editableHeading === 'alternateUrls' ? <i class="fa-solid fa-check" style={{ background: 'green', borderRadius: '4px', padding: '5px', color: '#fff' }}></i> : <i class="fas fa-edit"></i>}
                            </button>
                          </div>
                          <button
                            className="accordion-button collapsed accordianCustom"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#ten"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                          </button>
                          <div className="form-check form-switch">
                            <label
                              className="form-check-label"
                            >
                              {t("On_Off")}
                            </label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              checked={onState?.alternateurl}
                              onClick={() => {
                                setAlternateUrl(!isAlternateUrl);
                                setOnState({
                                  ...onState,
                                  alternateurl: !onState.alternateurl,
                                });
                              }}
                              onChange={() => toggleDiv("div10")}
                            />
                          </div>
                        </h2>
                        <div
                          id="ten"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div
                            className="accordion-body"
                            id="AddnewServicesformwrapper"
                          >
                            <div className="row">
                              <NewAlternateUrlCreate
                                setAlternateUrlArray={setAlternateUrlArray}
                              />
                            </div>
                          </div>
                        </div>
                      </div>


                      {/* =========social=================== */}
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFive">
                          
                        <div className="d-flex" style={{ gap: "8px", alignItems: "center", width: 'fit-content', zIndex: '99', position: 'relative' }}>
                            {editableHeading === 'socialMedia' ? (
                              <input
                                type="text"
                                value={socialHeading}
                                onChange={(e) => setSocialHeading(e.target.value)}
                                autoFocus
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    handleHeadingEdit('socialMedia');
                                    e.preventDefault();
                                  }
                                }}
                                placeholder="Enter Social Media Heading"
                                style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize: '14px', borderRadius: '4px', fontWeight: '400' }}
                              />
                            ) : (
                              <h5 style={{ fontWeight: "400" }} border-0>
                                {socialHeading ? socialHeading : t("Social_media")}
                              </h5>
                            )}
                            <button
                              type="button"
                              style={{ fontSize: "12px" }}
                              onClick={(e) => {
                                handleHeadingEdit('socialMedia');
                                e.stopPropagation();
                              }}
                            >
                              {editableHeading === 'socialMedia' ? <i class="fa-solid fa-check" style={{ background: 'green', borderRadius: '4px', padding: '5px', color: '#fff' }}></i> : <i class="fas fa-edit"></i>}
                            </button>
                          </div>

                          <button
                            className="accordion-button collapsed accordianCustom"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#five"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                          </button>

                          <div className="form-check form-switch">
                            <label
                              className="form-check-label"
                            // for="flexSwitchCheckDefault"
                            >
                              {t("On_Off")}
                            </label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              checked={onState?.socials}
                              onChange={() => toggleDiv("div5")}
                              onClick={() => {
                                setIsSocial(!isSocial);
                                setOnState({
                                  ...onState,
                                  socials: !onState.socials,
                                });
                              }}
                            />
                          </div>
                        </h2>
                        <div
                          id="five"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFive"
                          data-bs-parent="#accordionExample"
                        >
                          <div
                            className="accordion-body"
                            id="AddnewServicesformwrapper"
                            style={{ padding: "15px 0" }}
                          >
                            {/* {divStates.div5 && ( */}
                            <div
                              className="row"
                              style={{ justifyContent: "center" }}
                            >
                              <NewSocialmediaCreate
                                setSocialMediaData={setSocialMediaData}
                                socialMediaData={socialMediaData}
                              />
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingsix">
                          <div className="d-flex" style={{ gap: "8px", alignItems: "center", width: 'fit-content', zIndex: '99', position: 'relative' }}>
                            {editableHeading === 'termCondition' ? (
                              <input
                                type="text"
                                value={termConditionHeading}
                                onChange={(e) => setTermConditionHeading(e.target.value)}
                                autoFocus
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    handleHeadingEdit('termCondition');
                                    e.preventDefault();
                                  }
                                }}
                                placeholder={t("Terms_conditions_heading")}
                                style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize: '14px', borderRadius: '4px', fontWeight: '400' }}
                              />
                            ) : (
                              <h5 style={{ fontWeight: "400" }} border-0>
                                {termConditionHeading ? termConditionHeading : t("Terms_conditions")}
                              </h5>
                            )}
                            <button
                              type="button"
                              style={{ fontSize: "12px" }}
                              onClick={(e) => {
                                handleHeadingEdit('termCondition');
                                e.stopPropagation();
                              }}
                            >
                              {editableHeading === 'termCondition' ? <i class="fa-solid fa-check" style={{ background: 'green', borderRadius: '4px', padding: '5px', color: '#fff' }}></i> : <i class="fas fa-edit"></i>}
                            </button>
                          </div>
                          <button
                            className="accordion-button collapsed accordianCustom"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#six"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                          </button>
                          <div className="form-check form-switch">
                            <label
                              className="form-check-label"
                            // for="flexSwitchCheckDefault"
                            >
                              {t("On_Off")}
                            </label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              checked={onState?.termsconditions}
                              onChange={() => toggleDiv("div5")}
                              onClick={() => {
                                setIsTermsConditions(!isTermsConditions);
                                setOnState({
                                  ...onState,
                                  termsconditions: !onState.termsconditions,
                                });
                              }}
                            />
                          </div>
                        </h2>
                        <div
                          id="six"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingsix"
                          data-bs-parent="#accordionExample"
                        >
                          <div
                            className="accordion-body"
                            id="AddnewServicesformwrapper"
                            style={{ padding: "15px 0" }}
                          >
                            {/* {divStates.div5 && ( */}
                            <div
                              className="row"
                              style={{ justifyContent: "center" }}
                            >
                              <TermsConditionsCreate
                                setTermsConditionData={setTermsConditionData}
                                termsConditionData={termsConditionData}
                              />
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                      </div>


                      {formik.errors.is_allow_in_search && formik.touched.is_allow_in_search && (
                        <div style={{ color: "red", fontSize: "12px" }}>{formik.errors.is_allow_in_search}</div>
                      )}

                      {/* =============Agent Code Cab============ */}
                      {(userData.user_type === 'Sales Executive' || userData.user_type === 'Team Leader' || userData.user_type === 'super_admin') ? " " : <div className="accordion-item" style={{ padding: "10px 25px" }}>
                        <h2 className="accordion-header" id="headingEight">
                          <div className="d-flex justify-content-between" style={{ width: "100%" }}>
                            <h5 style={{ fontWeight: "400", display: "flex", alignItems: "center" }}>{t("Agent_code")} {t("Optional")}</h5>
                            <input
                              type="text"
                              className="form-control"
                              id="agentCode"
                              value={userData.user_type === 'Sales Executive' ? businessObj?.agentCode || agentCode : agentCode}
                              onChange={validateAgentCodeInput}
                              maxLength={6}
                              placeholder={`${t("Agent_code")}`}
                              style={{ width: "50%" }}
                              readOnly={!!businessObj?.agentCode && (userData.user_type === 'Sales Executive' || !userData.user_type)}
                            />
                          </div>
                          {isVerifyCodeSubmit ? (
                            <>
                              <CircularLoader size={40} />
                              {verifyCodeError && <p className="error-mgs text-danger">{verifyCodeError}</p>}
                            </>
                          ) : agentCode.length === 6 && isVerifyCodeVerified ? (
                            <p className="text-success mt-2">{t("Verified")}</p>
                          ) : agentCode.length === 6 && !isVerifyCodeVerified ? (
                            <p className="text-danger mt-2">{t("Not_verified")}</p>
                          ) : agentCode.length > 0 && agentCode.length < 6 ? (
                            <p className="text-danger mt-2">{t("agent_code_limit")}</p>
                          ) : null}
                        </h2>
                      </div>}
                      {(userData?.user_type == "super_admin" || userData?.user_type == "Team Leader" || userData?.user_type == "Sales Executive") &&
                        <div className="accordion-item" style={{ padding: "10px 25px" }}>
                          <h2 className="accordion-header" id="headingEight">
                            <div className="d-flex justify-content-between" style={{ width: "100%" }}>
                              <h5 style={{ fontWeight: "400", display: "flex", alignItems: "center" }}>{t("Lead_id")}</h5>
                              <input
                                type="text"
                                className="form-control"
                                id="teamlead"
                                value={teamleadId}
                                // value={userData.user_type === 'Sales Executive' ? businessObj?.teamleadId || teamleadId : teamleadId}
                                onChange={validateTeamLeadInput}
                                maxLength={6}
                                placeholder={`${t("Lead_id")}`}
                                style={{ width: "50%" }}
                              // readOnly={!!businessObj?.teamleadId && (userData.user_type === 'Sales Executive' || !userData.user_type)}
                              />
                            </div>
                          </h2>
                        </div>
                      }
                      {
                        userData?.user_type == "super_admin" &&
                        <div className="accordion-item">
                          <div className="accordion-header" id="headingeight">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#eight"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              <div className="d-flex">
                                <h5 style={{ fontWeight: "400" }}>{t("Meta_pixel_code")}</h5>
                              </div>
                            </button>

                          </div>
                          <div
                            id="eight"
                            className={`accordion-collapse collapse`}
                            aria-labelledby="headingeight"
                            data-bs-parent="#accordionExample"
                            style={{ paddingTop: "10px" }}
                          >
                            <div className="mb-3 col-md-12 AddDetailswidContainer">
                              <input
                                value={pixel_id}
                                className="form-control"
                                type="text"
                                placeholder={`${t("Meta_pixel_code")}`}
                                onChange={(e) => handleChangePixelId(e)}
                              />
                            </div>

                          </div>
                        </div>
                      }

                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h4 className="accordion-header" id="headingTwo">
                            <button
                              style={{ fontWeight: "400", fontSize: "15.3px" }}
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseColorPicker"
                              aria-expanded="false"
                              aria-controls="collapseColorPicker"
                            >
                              {t("Theme_color_picker")}
                            </button>
                          </h4>
                          <div
                            id="collapseColorPicker"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="color-picker-container" >
                                {/* Main Background Color Picker */}
                                <div className="color-picker-box">

                                  <div className="color-picker-item">

                                    <p className="colorpicker-name"> {t("Main_background_color")}:</p>
                                    <div className="color-picker-info" style={{ background: dynamicMainBackgroundColor ? `${dynamicMainBackgroundColor}13` : "", border: dynamicMainBackgroundColor ? `1px solid ${dynamicMainBackgroundColor}20` : "" }}>


                                      <div className="color-picker-info-detail" ref={colorPickerContainerRef[0]}>
                                        <div className="color-box-container" style={{ borderColor: dynamicMainBackgroundColor, }}>
                                          <div className="color-box"
                                            onClick={() => toggleColorPicker(0)}
                                            style={{
                                              backgroundColor:
                                                dynamicMainBackgroundColor,
                                              borderColor: dynamicMainBackgroundColor,
                                            }}
                                          ></div>
                                          {isColorPickerOpen[0] && (<SketchPicker
                                            color={dynamicMainBackgroundColor}
                                            onChangeComplete={mainBghandleChangeComplete}
                                          />)}
                                        </div>

                                        <div>

                                          <span
                                            style={{ fontSize: '13px' }}
                                            className="hex-color-name"
                                          >
                                            {t("Hex")}: {dynamicMainBackgroundColor}
                                          </span>
                                        </div>
                                      </div>

                                      <button
                                        type="button"
                                        onClick={() =>
                                          setDynamicMainBackgroundColor("")
                                        }
                                        className="reset-button"
                                      >
                                        {t("Reset")}
                                      </button>
                                    </div>
                                  </div>

                                  {/* <div className="color-preview">
                                
                                
                              </div> */}
                                </div>

                                {/* Background Color Picker */}
                                <div className="color-picker-box">

                                  <div className="color-picker-item">

                                    <p className="colorpicker-name" > {t("Background_color")}:</p>
                                    <div className="color-picker-info" style={{ background: dynamicBackgroundColor ? `${dynamicBackgroundColor}13` : "", border: dynamicBackgroundColor ? `1px solid ${dynamicBackgroundColor}20` : "" }}>


                                      <div className="color-picker-info-detail" ref={colorPickerContainerRef[1]}>
                                        <div className="color-box-container" style={{ borderColor: dynamicBackgroundColor }}>
                                          <div className="color-box"
                                            onClick={() => toggleColorPicker(1)}
                                            style={{
                                              backgroundColor:
                                                dynamicBackgroundColor,
                                              borderColor: dynamicBackgroundColor,
                                            }}
                                          ></div>
                                          {isColorPickerOpen[1] && (<SketchPicker
                                            color={dynamicBackgroundColor}
                                            onChangeComplete={handleChangeComplete}
                                          />)}
                                        </div>

                                        <div>

                                          <span
                                            style={{ fontSize: '13px' }} className="hex-color-name"
                                          >
                                            {t("Hex")}: {dynamicBackgroundColor}
                                          </span>
                                        </div>
                                      </div>

                                      <button
                                        type="button"
                                        onClick={() =>
                                          setDynamicBackgroundColor("")
                                        }
                                        className="reset-button"
                                      >
                                        {t("Reset")}
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                {/* Heading Color Picker */}
                                <div className="color-picker-box">

                                  <div className="color-picker-item">

                                    <p className="colorpicker-name" > {t("Heading_color")}:</p>
                                    <div className="color-picker-info" style={{ background: dynamicTextHeadingColor ? `${dynamicTextHeadingColor}13` : "", border: dynamicTextHeadingColor ? `1px solid ${dynamicTextHeadingColor}20` : "" }}>


                                      <div className="color-picker-info-detail" ref={colorPickerContainerRef[2]}>
                                        <div className="color-box-container" style={{ borderColor: dynamicTextHeadingColor }}>
                                          <div className="color-box"
                                            onClick={() => toggleColorPicker(2)}
                                            style={{
                                              backgroundColor:
                                                dynamicTextHeadingColor,
                                              borderColor: dynamicTextHeadingColor,
                                            }}
                                          ></div>
                                          {isColorPickerOpen[2] && (<SketchPicker
                                            color={dynamicTextHeadingColor}
                                            onChangeComplete={
                                              HeadingcolorhandleChangeComplete
                                            }
                                          />)}
                                        </div>

                                        <div>

                                          <span
                                            style={{ fontSize: '13px' }} className="hex-color-name"
                                          >
                                            {t("Hex")}: {dynamicTextHeadingColor}
                                          </span>
                                        </div>
                                      </div>

                                      <button
                                        type="button"
                                        onClick={() =>
                                          setDynamicTextHeadingColor("")
                                        }
                                        className="reset-button"
                                      >
                                        {t("Reset")}
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                {/* Description Color Picker */}
                                <div className="color-picker-box">

                                  <div className="color-picker-item">

                                    <p className="colorpicker-name"> {t("Description_color")}:</p>
                                    <div className="color-picker-info" style={{ background: dynamicTextDescriptionColor ? `${dynamicTextDescriptionColor}13` : "", border: dynamicTextDescriptionColor ? `1px solid ${dynamicTextDescriptionColor}20` : "" }}>


                                      <div className="color-picker-info-detail" ref={colorPickerContainerRef[3]}>
                                        <div className="color-box-container" style={{
                                          borderColor: dynamicTextDescriptionColor
                                        }}>
                                          <div className="color-box"
                                            onClick={() => toggleColorPicker(3)}
                                            style={{
                                              backgroundColor:
                                                dynamicTextDescriptionColor
                                              ,
                                              borderColor: dynamicTextDescriptionColor
                                              ,
                                            }}
                                          ></div>
                                          {isColorPickerOpen[3] && (<SketchPicker
                                            color={dynamicTextDescriptionColor}
                                            onChangeComplete={
                                              DescriptioncolorhandleChangeComplete
                                            }
                                          />)}
                                        </div>

                                        <div>

                                          <span
                                            style={{ fontSize: '13px' }} className="hex-color-name"
                                          >
                                            {t("Hex")}: {dynamicTextDescriptionColor
                                            }
                                          </span>
                                        </div>
                                      </div>

                                      <button
                                        type="button"
                                        onClick={() =>
                                          setDynamicTextDescriptionColor("")
                                        }
                                        className="reset-button"
                                      >
                                        {t("Reset")}
                                      </button>
                                    </div>
                                  </div>

                                </div>

                                {/* CTA Color Picker */}
                                <div className="color-picker-box">

                                  <div className="color-picker-item">

                                    <p className="colorpicker-name"> {t("Cta_color")}:</p>
                                    <div className="color-picker-info" style={{ background: dynamicCtaColor ? `${dynamicCtaColor}13` : "", border: dynamicCtaColor ? `1px solid ${dynamicCtaColor}20` : "" }}>


                                      <div className="color-picker-info-detail" ref={colorPickerContainerRef[4]}>
                                        <div className="color-box-container" style={{
                                          borderColor: dynamicCtaColor
                                        }}>
                                          <div className="color-box"
                                            onClick={() => toggleColorPicker(4)}
                                            style={{
                                              backgroundColor:
                                                dynamicCtaColor
                                              ,
                                              borderColor: dynamicCtaColor
                                              ,
                                            }}
                                          ></div>
                                          {isColorPickerOpen[4] && (<SketchPicker
                                            color={dynamicCtaColor}
                                            onChangeComplete={
                                              CtacolorhandleChangeComplete
                                            }
                                          />)}
                                        </div>

                                        <div>

                                          <span
                                            style={{ fontSize: '13px' }} className="hex-color-name"
                                          >
                                            {t("Hex")}: {dynamicCtaColor
                                            }
                                          </span>
                                        </div>
                                      </div>

                                      <button
                                        type="button"
                                        onClick={() =>
                                          setdynamicCtaColor("")
                                        }
                                        className="reset-button"
                                      >
                                        {t("Reset")}
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                {/* CTA TeXt Color Picker */}
                                <div className="color-picker-box">

                                  <div className="color-picker-item">

                                    <p className="colorpicker-name"> {t("Cta_text_color")}:</p>
                                    <div className="color-picker-info" style={{ background: dynamicCtaTextColor ? `${dynamicCtaTextColor}13` : "", border: dynamicCtaTextColor ? `1px solid ${dynamicCtaTextColor}20` : "" }}>


                                      <div className="color-picker-info-detail" ref={colorPickerContainerRef[5]}>
                                        <div className="color-box-container" style={{ borderColor: dynamicCtaTextColor, }}>
                                          <div className="color-box"
                                            onClick={() => toggleColorPicker(5)}
                                            style={{
                                              backgroundColor:
                                                dynamicCtaTextColor,
                                              borderColor: dynamicCtaTextColor,
                                            }}
                                          ></div>
                                          {isColorPickerOpen[5] && (<SketchPicker
                                            color={dynamicCtaTextColor}
                                            onChangeComplete={ctaTextcolorHandler}
                                          />)}
                                        </div>

                                        <div>

                                          <span
                                            style={{ fontSize: '13px' }}
                                            className="hex-color-name"
                                          >
                                            {t("Hex")}: {dynamicCtaTextColor}
                                          </span>
                                        </div>
                                      </div>

                                      <button
                                        type="button"
                                        onClick={() =>
                                          setdynamicCtaTextColor("")
                                        }
                                        className="reset-button"
                                      >
                                        {t("Reset")}
                                      </button>
                                    </div>
                                  </div>

                                  {/* <div className="color-preview">
                                
                                
                              </div> */}
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div className="row Adddetails_saveprev_ButtonWrapper">
                      <div className="Changeblock_saveandpreview_buttonWrapper Changeblock_saveandpreview_buttonWrappertwo">
                        <button
                          className="btn btn-cu save-cta Changeblock_saveandpreview_button "
                          id="changeblockprenxtbtn"
                          type="button"
                          onClick={handlePreviousClick}
                        >
                          {t("Previous")}
                        </button>
                        {/* </div>
          <div className="Changeblock_saveandpreview_buttonWrapper"> */}
                        {isSubmit ? (
                          <CircularLoader size={40} />
                        ) : (
                          <button
                            type="button"
                            className="btn btn-cu save-cta Changeblock_saveandpreview_button"
                            id="changeblockprenxtbtn"
                            onClick={(e) => handleSubmitWithoutTabChange(e)}
                          >
                            {t("Save_preview")}
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div></> : <>
                  <div className="tile">
                    <form onSubmit={formik.handleSubmit} className="row">
                      {(businessObj?.theme_color != 'theme53') &&
                        <div className="flex items-center i-agree">
                          <div className="mr-2 ">
                            <label>{t("Marketplace_text")}</label>
                          </div>
                          <div className="mr-2">
                            <input
                              type="radio"
                              name="is_allow_in_search"
                              className="radiobtn"
                              value={1}
                              id="yes"
                              checked={formik.values.is_allow_in_search == 1}
                              onChange={handleRadioChange}
                              onBlur={formik.handleBlur}
                            />
                            <label htmlFor="yes" className="mr-4">{t("Yes")}</label>
                          </div>
                          <div className="mr-2">
                            <input
                              className="radiobtn"
                              type="radio"
                              name="is_allow_in_search"
                              value={0}
                              id="no"
                              checked={formik.values.is_allow_in_search == 0}
                              onChange={handleRadioChange}
                              onBlur={formik.handleBlur}
                            />
                            <label htmlFor="no">{t("No")}</label>
                          </div>

                        </div>
                      }
                      <div className="flex items-center i-agree">
                        <div className="mr-2 ">
                          <label>{t("Pwa_text")}</label>
                        </div>
                        <div className="mr-2">
                          <input
                            type="radio"
                            name="is_enabledPwa"
                            className="radiobtn"
                            value={1}
                            id="yess"
                            checked={formik.values.is_enabledPwa == 1}
                            onChange={handlePWARadioChange}
                            onBlur={formik.handleBlur}
                          />
                          <label htmlFor="yess" className="mr-4">{t("Yes")}</label>
                        </div>
                        <div className="mr-2">
                          <input
                            className="radiobtn"
                            type="radio"
                            name="is_enabledPwa"
                            value={0}
                            id="noo"
                            checked={formik.values.is_enabledPwa == 0}
                            onChange={handlePWARadioChange}
                            onBlur={formik.handleBlur}
                          />
                          <label htmlFor="noo">{t("No")}</label>
                        </div>

                      </div>
                      <div className="d-flex justify-content-between mb-4 col-md-12 AddDetailsBanner_logoWrapper">
                        {isBannerShow(businessObj?.theme_color) ? (
                          <div class="mb-4 col-md-8">
                            <div className="boxbanner DetailsSelectBanner_wrapper">
                              <label className="form-label detailsdptext">
                                {t("Select_banner")} <span style={{ fontSize: "12px" }}>{getLogoSizeLabel(businessObj?.theme_color)}</span>
                                <span className="text-danger">
                                  {isBannerRequired ? " *" : " "}
                                </span>
                              </label>
                              <div className="bannerbox 55">
                                <img
                                  src={
                                    bannerImageUrl
                                      ? bannerImageUrl?.includes("base64")
                                        ? bannerImageUrl
                                        : bannerImageUrl == "null"
                                          ? ""
                                          : `${environmentVariables?.s3UrlBackendUrl}${bannerImageUrl}`
                                      : bannerimage
                                    // : ""
                                  }
                                  className="mx-auto d-block img-fluid"
                                  onClick={(e) => handleImageClickBanner(e)}
                                ></img>
                              </div>
                              <input
                                className="form-control d-none"
                                type="file"
                                name="banner" // Set the name to match the Multer field name
                                onChange={handleBannerChange}
                                ref={fileInputRefBanner}
                              />
                              <p className="imgserve">{t("Note_banner")}</p>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* <div className="row justify-content-end px-0 mb-4">
           
            <div className="col-12 col-lg-3 Changeblock_saveandpreview_buttonWrapper">
              {isSubmit ? (
                <CircularLoader size={40} />
              ) : (
                <button
                  type="button"
                  className="btn btn-cu mb-4 w-100 save-cta Changeblock_saveandpreview_button"
                  onClick={(e) => handleSubmit(e)}
                >
                  {"Save & Next"}
                </button>
              )}
            </div>
          </div> */}
                        {/* <div class="mb-3 col-md-12">
          <div className="sec-gap">
            <h3 className="templatheading">Your Page Code</h3>
            <div class="input-group mb-1">
              <span class="input-group-text" id="basic-addon3">
                {`https://vmycards.com/users/${businessObj?.slug}`}
              </span>
              <input
                value={businessObj?.slug}
                type="text"
                class="form-control"
                disabled={true}
              />
            </div>
            <small className="etidspan">
              *minimum 5 characters required.
            </small>
          </div>
        </div> */}

                        {isLogoShow(businessObj?.theme_color) ? (
                          <div className="mb-3 col-md-4">
                            {themeNumber != 18 && themeNumber != 35 && themeNumber != 41 && themeNumber != 36 && themeNumber != 48 && themeNumber != 53 && themeNumber != 51 && themeNumber != 54 ? (
                              <div className="boxbanner detailsdptextlogowrapper">
                                <label className="form-label detailsdptext DetailsSelectLogo">
                                  {t("Select_logo")}
                                </label>
                                <div className="bannerbox2 AdddetailsdpContainer">
                                  <img
                                    src={
                                      logoImageUrl
                                        ? logoImageUrl?.includes("base64")
                                          ? logoImageUrl
                                          : logoImageUrl == "null"
                                            ? ""
                                            : `${environmentVariables?.s3UrlBackendUrl}${logoImageUrl}`
                                        : avatar
                                    }
                                    className="mx-auto d-block"
                                    onClick={handleImageClickLogo}
                                  />
                                </div>
                                {/* <p>
                <strong>Size : (150 x 150)</strong>
              </p> */}
                                <input
                                  className="form-control d-none"
                                  type="file"
                                  name="logo" // Set the name to match the Multer field name
                                  onChange={handleLogoChange}
                                  ref={fileInputRefLogo}
                                  accept=".png, .jpg, .jpeg"
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="AddDetailsNameDestiCom_Wrapper">
                        <div className="mb-3 col-md-4 AddDetailswidContainer">
                          <label className="form-label">{t("Name")}*</label>
                          <input
                            // value={subTitle}
                            className="form-control"
                            type="text"
                            placeholder={`${t("Name")}`}
                            // maxLength="40"
                            // onChange={(e) => setSubTitle(e.target.value)}
                            {...formik.getFieldProps("subTitle")}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.subTitle && formik.errors.subTitle && (
                            <ErrorStyle className="error">
                              {formik.errors.subTitle}
                            </ErrorStyle>
                          )}
                        </div>

                        <div className="mb-3 col-md-4 AddDetailswidContainer AddDetailsMarContainer">
                          <label className="form-label">{t("Designation")}* </label>
                          <input
                            // value={designation}
                            {...formik.getFieldProps("designation")}
                            className="form-control"
                            type="text"
                            placeholder={`${t("Designation")}`}
                          // onChange={(e) => setDesignation(e.target.value)}
                          />
                          {formik.touched.designation &&
                            formik.errors.designation && (
                              <ErrorStyle className="error">
                                {formik.errors.designation}
                              </ErrorStyle>
                            )}
                        </div>

                        <div className="mb-3 col-md-4 AddDetailswidContainer">
                          <label className="form-label">{t("Company_name")}*</label>
                          <input
                            value={title}
                            className="form-control"
                            type="text"
                            placeholder={`${t("Company_name")}`}
                            // onChange={(e) => setTitle(e.target.value)}
                            onChange={(e) => handleChangeCompanyName(e)}
                          />
                        </div>

                      </div>

                      {themeColor == "theme53" &&
                        <>
                          {/* <div className="mb-3 col-md-4 AddDetailswidContainer AddDetailsMarContainer">
                          <label className="form-label">Instagram Account Name </label>
                          <input
                            {...formik.getFieldProps("instaName")}
                            className="form-control"
                            type="text"
                            placeholder="Insta"
                          />
                          {formik.touched.instaName &&
                            formik.errors.instaName && (
                              <ErrorStyle className="error">
                                {formik.errors.instaName}
                              </ErrorStyle>
                            )}
                        </div> */}
                          <div className='col-md-12 flex' >
                            <div className="mb-3 col-md-4 AddDetailswidContainer AddDetailsMarContainer">
                              <label className="form-label">{t("Youtube_account_name")}</label>
                              <input
                                {...formik.getFieldProps("youtubeName")}
                                className="form-control"
                                type="text"
                                placeholder={`${t("Youtube_account_name")}`}
                              />
                              {formik.touched.youtubeName &&
                                formik.errors.youtubeName && (
                                  <ErrorStyle className="error">
                                    {formik.errors.youtubeName}
                                  </ErrorStyle>
                                )}
                            </div>


                            {/* <div className="mb-3 col-md-4 flex items-center i-agree"> */}
                            <div className="mb-3 col-md-4 AddDetailswidContainer AddDetailsMarContainer">
                              <label className='form-label'>{t("Hire_me")}</label>
                              {/* <div className="mr-2 ">
                          </div> */}
                              <div className='flex items-center form-control' style={{ display: 'flex' }}>

                                <div className="mr-2">
                                  <input
                                    type="radio"
                                    name="is_enabledHireMe"
                                    className="radiobtn"
                                    value={1}
                                    id="yesHireMe"
                                    checked={formik.values.is_enabledHireMe == 1}
                                    onChange={handleHireMeRadioChange}
                                    onBlur={formik.handleBlur}
                                  />
                                  <label htmlFor="yesHireMe" className="mr-4">{t("Yes")}</label>
                                </div>
                                <div className="mr-2">
                                  <input
                                    className="radiobtn"
                                    type="radio"
                                    name="is_enabledHireMe"
                                    value={0}
                                    id="noHireMe"
                                    checked={formik.values.is_enabledHireMe == 0}
                                    onChange={handleHireMeRadioChange}
                                    onBlur={formik.handleBlur}
                                  />
                                  <label htmlFor="noHireMe">{t("No")}</label>
                                </div>
                              </div>
                            </div>
                          </div>

                        </>
                      }



                      <div className="mb-3 col-md-12">
                        <div  className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative', alignItems:"center" }}>
                          {
                          editableHeading === 'description' ?
                            (<input
                              value={descriptionHeading}
                              className="form-control"
                              type="text"
                              placeholder={`${t("descriptionheading")}`}
                              onChange={(e) => handledescriptionHeading(e)}
                              style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                            />) : ( <label className="form-label">{descriptionHeading ? descriptionHeading : t("Description")}*</label>)                      
                          }
                          <button type="button"
                                  style={{fontSize : "12px", marginBottom:"0.5rem" }}
                                  onClick={(e) => {
                                    handleHeadingEdit('description');
                                  }} >
                                  {editableHeading === 'description' ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}
                          </button>
                        </div>
                        <textarea
                          // value={description}
                          {...formik.getFieldProps("description")}
                          className="form-control"
                          rows="4"
                          placeholder={`${t("Description")}`}
                        // onChange={(e) => setDescription(e.target.value)}
                        >
                          {/* Hi, I'm a graphic designer ! */}
                        </textarea>
                        {formik.touched.description &&
                          formik.errors.description && (
                            <ErrorStyle className="error">
                              {formik.errors.description}
                            </ErrorStyle>
                          )}
                      </div>

                      {/* {(userData.user_type === 'Team Leader' || userData.user_type === 'Sales Executive'|| userData.user_type === 'super_admin') && (
                                <div className="mb-3 col-md-12">
                                  <label className="form-label" style={{ fontSize: "17px" }}>
                                    Business Owner
                                  </label>
                                  <div className="form-control">
                                    <select
                                      style={{ width: '100%' }}
                                      value={businessOwnerId}
                                      onChange={(e) => setBusinessOwnerId(e.target.value)}
                                      disabled={businessObjData?.bo_id === businessObjData?.created_by}
                                    >
                                      <option value="">Select Business Owner</option>
                                      {businessOwnerList?.map((ele) => (
                                        <option key={ele.id} value={ele.id}>
                                          {ele.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              )} */}

                      {(userData.user_type === "Team Leader" ||
                        userData.user_type === "Sales Executive" ||
                        userData.user_type === "super_admin") && themeColor != "theme53" && (
                          <div className="mb-3 col-md-12">
                            <label className="form-label" style={{ fontSize: "17px" }}>
                              {t("Business_owner")}
                            </label>
                            <div className="form-control" style={{position: "relative", zIndex: "100"}}>

                              <AsyncPaginate
                                className="asyncPaginate"
                                // style={{ zIndex: '999 !important' }}
                                value={selectBusinessOwnerId} // Controlled by state
                                loadOptions={loadOptions}
                                onChange={(selectedOption) => {
                                  setSelectBusinessOwnerId(selectedOption);
                                  setBusinessOwnerId(selectedOption?.value);
                                  setbusinessOwnerName(businessObj?.Owner_name);

                                }}
                                additional={{
                                  page: 1,
                                }}
                                placeholder={businessObj?.Owner_name === 'Super Admin' ? "Select business owner" : businessObj?.Owner_name || "Select business owner"}
                                isDisabled={userData.user_type !== "super_admin" && (businessObjData?.bo_id === businessObjData?.created_by)}
                              />
                            </div>
                          </div>
                        )}
                      {/* <div class="mb-3 col-md-12 ">
          <div class="form-group">
            <label class="form-label">Personalized link:</label>
            <div class="input-group">
              <input
                type="text"
                className="form-control"
                readonly=""
                value=" https://vmycards.com/freedom-5"
              />
              <input
                className="input-group-text text-start"
                placeholder="Enter Slug"
                name="slug"
                type="text"
                onChange={(e) => setSlug(e.target.value)}
                value={slug}
              />
            </div>
          </div>
        </div> */}

                      {/* <div style={{  margin: "20px 0", display:"grid" , gridTemplateColumns:"1fr 1fr 1fr"}}>

<div style={{margin:"0 10px"}}>
   <SketchPicker
        color={dynamicMainBackgroundColor}
        onChangeComplete={mainBghandleChangeComplete}

      />
      <div style={{ marginTop: "30px", fontWeight: "bold" }}>
       main Background  Color: <span style={{ color: dynamicMainBackgroundColor }}>{dynamicMainBackgroundColor}</span>
         
      </div>
    
      <div
        style={{
          marginTop: "10px",
          height: "50px",
          width: "50px",
          backgroundColor: dynamicMainBackgroundColor,
          border: "1px solid #000",
          display: "inline-block",
        }}
      ></div>
   
   </div>

   <div style={{margin:"0 10px"}}>
   <SketchPicker
        color={dynamicBackgroundColor}
        onChangeComplete={handleChangeComplete}

      />
      <div style={{ marginTop: "30px", fontWeight: "bold" }}>
        Background Color: <span style={{ color: dynamicBackgroundColor }}>{dynamicBackgroundColor}</span>
      </div>
      <div
        style={{
          marginTop: "10px",
          height: "50px",
          width: "50px",
          backgroundColor: dynamicBackgroundColor,
          border: "1px solid #000",
          display: "inline-block",
        }}
      ></div>
   </div>

   <div style={{margin:"0 10px"}}>
   <SketchPicker
        color={dynamicTextHeadingColor}
        onChangeComplete={HeadingcolorhandleChangeComplete}

      />
      <div style={{ marginTop: "30px", fontWeight: "bold" }}>
        Heading Color: <span style={{ color: dynamicTextHeadingColor }}>{dynamicTextHeadingColor}</span>
      </div>
      <div
        style={{
          marginTop: "10px",
          height: "50px",
          width: "50px",
          backgroundColor: dynamicTextHeadingColor,
          border: "1px solid #000",
          display: "inline-block",
        }}
      ></div>
   </div>


   <div style={{margin:"0 10px"}}>
   <SketchPicker
        color={dynamicTextDescriptionColor}
        onChangeComplete={DescriptioncolorhandleChangeComplete}

      />
      <div style={{ marginTop: "30px", fontWeight: "bold" }}>
         Color: <span style={{ color: dynamicTextDescriptionColor }}>{dynamicTextDescriptionColor}</span>
      </div>
      <div
        style={{
          marginTop: "10px",
          height: "50px",
          width: "50px",
          backgroundColor: dynamicTextDescriptionColor,
          border: "1px solid #000",
          display: "inline-block",
        }}
      ></div>
   </div>



   <div style={{margin:"0 10px"}}>
   <SketchPicker
        color={dynamicCtaColor}
        onChangeComplete={CtacolorhandleChangeComplete}

      />
      <div style={{ marginTop: "30px", fontWeight: "bold" }}>
        CTA Color: <span style={{ color: dynamicCtaColor }}>{dynamicCtaColor}</span>
      </div>
      <div
        style={{
          marginTop: "10px",
          height: "50px",
          width: "50px",
          backgroundColor: dynamicCtaColor,
          border: "1px solid #000",
          display: "inline-block",
        }}
      ></div>
   </div>


      
    </div> */}



                      <label className="form-label" style={{ fontSize: "17px" }}>
                        {t("Settings")}
                      </label>
                      {/* <h5 style={{fontWeight: "400"}} className="settings">Settings:</h5> */}

                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne">
                            <div className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative' }}>
                              {editableHeading === 'contactInfo' ? (
                                <input
                                  type="text"
                                  value={contactHeading}
                                  onChange={(e) => setcontactHeading(e.target.value)}
                                  placeholder={t("Contact_info_heading")}
                                  style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                                />
                              ) : (
                                <h5 style={{ fontWeight: "400" }} border-0>
                                  {contactHeading ? contactHeading : t("Contact_info")}
                                </h5>
                              )}
                              <button
                                type="button"
                                style={{fontSize : "12px" }}
                                onClick={(e) => {
                                  handleHeadingEdit('contactInfo');
                                }}
                              >
                                {editableHeading === 'contactInfo' ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}

                              </button>
                            </div>

                            <div className="form-check form-switch">
                              <label
                                className="form-check-label"
                              >
                                {t("On_Off")}
                              </label>
                              <input
                                onClick={(e) => {
                                  setIsEnabledContactInfo(!isEnabledContactInfo);
                                  setOnState({
                                    ...onState,
                                    contactInfo: !onState.contactInfo,
                                  });
                                }}
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                onChange={() => toggleDiv("div1")}
                                checked={onState?.contactInfo}
                              />
                            </div>
                            <button
                              type="button"
                              className="accordion-button collapsed  accordianCustom"

                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="false"
                              aria-controls="collapseOne"

                            >

                            </button>


                          </h2>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse "
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body AddDetailsContactInfoInnerForm">
                              {/* {divStates.div1 && ( */}
                              <div className="row MobileDetailsWrapper">
                                <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                                  <label className="form-label">
                                    {/* Mobile Number* */}
                                    {themeNumber == "53" ? t("Mobile_number") : `${t("Mobile_number")}*`}
                                  </label>
                                  {/* <input
                      value={contactInfoPhone}
                      type="number"
                      className="form-control"
                      onChange={(e) =>
                        setContactInfoPhone(e.target.value)
                      }
                      placeholder="Phone Number"
                    /> */}
                                  <PhoneInput
                                    country={countryCode}
                                    enableSearch={true}
                                    countryCodeEditable={false}
                                    value={formik.values.contactInfoPhone || `+${countryCode}`}
                                    onChange={(value, country) => {
                                      if (country.dialCode) {
                                        formik.setFieldValue(
                                          "contactInfoPhone",
                                          `+${country.dialCode}-${value.substring(
                                            country.dialCode.length
                                          )}`
                                        );
                                        setCountryName(country.name);
                                      } else {
                                        formik.setFieldValue(
                                          "contactInfoPhone",
                                          value
                                        );
                                      }
                                    }}
                                    // onBlur={formik.handleBlur("contactInfoPhone")}
                                    onBlur={(e, country) => handleBlur(e, country) && formik.handleBlur("contactInfoPhone")}
                                    // {...formik.getFieldProps('contactInfoPhone')}
                                    className="DetailsInfoPhoneInput"
                                    isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
                                  />
                                  {formik.touched.contactInfoPhone &&
                                    formik.errors.contactInfoPhone && (
                                      <ErrorStyle className="error">
                                        {formik.errors.contactInfoPhone}
                                      </ErrorStyle>
                                    )}
                                  {invalidPhoneErr && <ErrorStyle className="error">
                                    {invalidPhoneErr}
                                  </ErrorStyle>}
                                </div>
                                <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                                  <label className="form-label">
                                    {t("Alternative_mobile_number")}
                                  </label>

                                  <PhoneInput
                                    country={countryCode}
                                    enableSearch={true}
                                    countryCodeEditable={false}
                                    value={formik.values.optionalContactInfoPhone || `+${countryCode}`}
                                    onChange={(value, country) => {
                                      if (country.dialCode) {
                                        formik.setFieldValue(
                                          "optionalContactInfoPhone",
                                          `+${country.dialCode}-${value.substring(
                                            country.dialCode.length
                                          )}`
                                        );
                                        setCountryName(country.name);
                                      } else {
                                        formik.setFieldValue(
                                          "optionalContactInfoPhone",
                                          value
                                        );
                                      }
                                    }}
                                    // onBlur={formik.handleBlur("optionalContactInfoPhone")}
                                    onBlur={(e, country) => handleBlurOptional(e, country) && formik.handleBlur("optionalContactInfoPhone")}
                                    // {...formik.getFieldProps('optionalContactInfoPhone')}
                                    className="DetailsInfoPhoneInput"
                                    isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
                                  />
                                  {formik.touched.optionalContactInfoPhone &&
                                    formik.errors.optionalContactInfoPhone && (
                                      <ErrorStyle className="error">
                                        {formik.errors.optionalContactInfoPhone}78
                                      </ErrorStyle>
                                    )}
                                  {invalidOptPhoneErr && <ErrorStyle className="error">
                                    {invalidOptPhoneErr}
                                  </ErrorStyle>}
                                </div>
                                <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                                  <label className="form-label">{t("Email")}*</label>
                                  <input
                                    {...formik.getFieldProps("contactInfoEmail")}
                                    // value={contactInfoEmail}
                                    // value={formik.values.contactInfoEmail}
                                    className="form-control"
                                    type="text"
                                    placeholder={`${t("Email")}`}
                                  // onChange={(e) =>
                                  //   setContactInfoEmail(e.target.value)
                                  // }
                                  // onChange={formik.handleChange('contactInfoEmail')}
                                  />
                                  {formik.touched.contactInfoEmail &&
                                    formik.errors.contactInfoEmail && (
                                      <ErrorStyle className="error">
                                        {formik.errors.contactInfoEmail}
                                      </ErrorStyle>
                                    )}
                                </div>
                                <div className="mb-3 col-md-6">
                                  <label className="form-label">{t("Web_url")}</label>
                                  <input
                                    {...formik.getFieldProps("contactInfoWebUrl")}
                                    // value={contactInfoWebUrl}
                                    className="form-control"
                                    type="text"
                                    placeholder={`${t("Web_url")}`}
                                  // onChange={(e) =>
                                  //   setContactInfoWebUrl(e.target.value)
                                  // }
                                  />
                                  {formik.touched.contactInfoWebUrl &&
                                    formik.errors.contactInfoWebUrl && (
                                      <ErrorStyle className="error">
                                        {formik.errors.contactInfoWebUrl}
                                      </ErrorStyle>
                                    )}
                                </div>
                                {themeColor == "theme53" &&
                                  <>
                                    <div className="mb-3 col-md-6">
                                      <label className="form-label">{t("Select_influencer")}*</label>
                                      <div className="col">
                                        <select
                                          className="form-select"
                                          name="influencer_id"
                                          value={formik.values.influencer_id}
                                          onChange={formik.handleChange}
                                        >
                                          <option value=""> {t("Select_influencer")}</option>
                                          {!!allInfluencer && allInfluencer?.length > 0 && allInfluencer?.map((influencer) => (
                                            !!influencer?.id &&
                                            <option key={influencer?.name} value={influencer?.id}>
                                              {influencer?.name?.charAt(0)?.toUpperCase() + influencer?.name?.slice(1)}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    <div className="mb-3 col-md-6">
                                      <label className="form-label">{t("Shipping_country")}</label>
                                      <div className="col">
                                        <select
                                          className="form-select"
                                          name="shipCountry"
                                          value={formik.values.shipCountry || "IN"}
                                          onChange={formik.handleChange}
                                        >
                                          <option value=""> {t("Select Country")}</option>
                                          {!!dynamicCountry && dynamicCountry?.length > 0 && dynamicCountry?.map((country) => (
                                            !!country?.id &&
                                            <option key={country?.name} value={country?.short_code}>
                                              {country?.name?.charAt(0)?.toUpperCase() + country?.name?.slice(1)}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </>
                                }

                                <div className="mb-3 col-md-12">
                                  <label className="form-label">{themeNumber == "53" ? t("Address") : t("Address")}</label>
                                  <textarea
                                    value={contactInfoAddress}
                                    className="form-control"
                                    rows="3"
                                    maxLength={250}
                                    placeholder={`${t("Address")}`}
                                    onChange={(e) =>
                                      handleContactInfoAddressChange(e)
                                    }
                                    // onBlur={handleGetLatLang}
                                    disabled={isAddressDisabled}
                                  ></textarea>
                                  {contactInfoAddressError && <p className="error-mgs text-danger">{contactInfoAddressError}</p>}
                                  {formik.touched.contactInfoAddress &&
                                    formik.errors.contactInfoAddress && (
                                      <ErrorStyle className="error">
                                        {formik.errors.contactInfoAddress}
                                      </ErrorStyle>
                                    )}
                                  <a className="btn btn-info-detail" onClick={handleGetLatLang}> {t("Submit")}</a>
                                  {latLngError && <p className="text-danger mt-2" style={{ fontSize: "12px" }}>{latLngError}</p>}
                                  {isAddressSubmit ? (
                                    <CircularLoader size={40} />
                                  ) : (
                                    addressPlaceId && formik.values.contactInfoAddress && (
                                      <div className="">
                                        <div className="">
                                          <a
                                            href={`https://www.google.com/maps/place/?q=place_id:${addressPlaceId}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="btn btn-link"
                                            style={{ fontSize: "14px", color: "#00b721", fontWeight: "500", padding: "10px 0px" }}
                                          >
                                            {t("Business_address_verify")}
                                          </a>
                                        </div>
                                        <div className="">
                                          {isAddressVerified ? (
                                            <>
                                              <button className="btn btn-success me-2 selectreviewverified" disabled>
                                                {t("Address_verified")}
                                              </button>
                                              <button className="selectreviewedit" onClick={handleIncorrectAddressData}>
                                                {t("Edit")}
                                              </button>
                                            </>
                                          ) : (
                                            <button className="btn btn-success me-2 selectreview 
                                              verifybusinesswtd" onClick={handleCorrectAddressData}>
                                              {t("Verify_address")}
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                                <div className="mb-3 col-md-12">
                                  <label className="form-label">{t("Alternative_address")}</label>
                                  <textarea
                                    value={contactInfoOptAddress}
                                    className="form-control"
                                    rows="3"
                                    maxLength={250}
                                    placeholder={`${t("Address")}`}
                                    onChange={(e) =>
                                      handleContactInfoOptAddressChange(e)
                                    }
                                  ></textarea>
                                  {contactInfoOptAddressError && <p className="error-mgs text-danger">{contactInfoOptAddressError}</p>}
                                </div>
                              </div>
                              {/* )} */}
                            </div>
                          </div>
                        </div>
                        {(businessObj?.theme_color == 'theme16' || 'theme1' || 'theme2' || 'theme3' || 'theme4' || 'theme5' || 'theme6' || 'theme7' || 'theme9' || 'theme11' || 'theme15' || 'theme17' || 'theme18') &&
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingZero">
                            <div className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative' }}>
                            {editableHeading === 'googleReview' ? (
                                    <input
                                      type="text"
                                      value={reviewHeading}
                                      onClick={(e) => e.stopPropagation()}
                                      onChange={(e) => setreviewHeading(e.target.value)}
                                      placeholder={t("Enter_google_review_heading")}
                                      style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                                      />
                                  ) : (
                                    <h5 style={{ fontWeight: "400" }} border-0>
                                      {reviewHeading ? reviewHeading : t("Google_review")}
                                    </h5>
                                  )}
                                  <button
                                    type="button"
                                    style={{fontSize : "12px" }}
                                    onClick={(e) => {
                                      handleHeadingEdit('googleReview');
                                    }}
                                  >
                                    {editableHeading === 'googleReview' ?  <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}
                                  </button>
                                 
                                </div>
                              <div className="form-check form-switch">
                                <label className="form-check-label">{t("On_Off")}</label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  onChange={() => toggleDiv("div8")}
                                  checked={onState?.googlereview}
                                  onClick={(e) => {
                                    setIsEnabledGoogleReview(!isEnabledGoogleReview);
                                    setOnState({
                                      ...onState,
                                      googlereview: !onState.googlereview,
                                    });
                                  }}
                                />

                              </div>
                              <button
                              type="button"
                              className="accordion-button collapsed  accordianCustom"

                              data-bs-toggle="collapse"
                              data-bs-target="#collapseZero"
                              aria-expanded="false"
                              aria-controls="collapseZero"

                            >

                            </button>
                            </h2>
                            <div
                              id="collapseZero"
                              className="accordion-collapse collapse "
                              aria-labelledby="headingZero"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body AddDetailsContactInfoInnerForm">
                                <div className="row MobileDetailsWrapper">
                                  <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                                    <input
                                      {...formik.getFieldProps("googlereview")}
                                      className="form-control"
                                      type="text"
                                      placeholder={`${t("Business_name")}`}
                                      onBlur={handleBlurReview}
                                      disabled={isVerified}
                                    />
                                    {formik.touched.googlereview && formik.errors.googlereview && (
                                      <ErrorStyle className="error">
                                        {formik.errors.googlereview}
                                      </ErrorStyle>
                                    )}
                                  </div>
                                  <div className="mb-3 col-md-3 MobilePhoneFieldWrapper">
                                    <input
                                      {...formik.getFieldProps("googleaddress")}
                                      className="form-control"
                                      type="text"
                                      placeholder={`${t("City_name")}`}
                                      onChange={handleGoogleAddressChange}
                                      onBlur={handleBlurReview}
                                      disabled={isVerified}
                                    />
                                    {formik.touched.googleaddress && formik.errors.googleaddress && (
                                      <ErrorStyle className="error">
                                        {formik.errors.googleaddress}
                                      </ErrorStyle>
                                    )}
                                  </div>
                                  <div className="mb-3 col-md-3 MobilePhoneFieldWrapper">
                                    <a className="btn selectreviewverifiedbtn" onClick={fetchGoogleRating}>
                                      {t("Submit")}
                                    </a>
                                  </div>
                                </div>

                                {isAddSubmit ? (
                                  <CircularLoader size={40} />
                                ) : (
                                  tempLink && (
                                    <div className="">
                                      <div className="">
                                        <a
                                          href={`https://www.google.com/maps/place/?q=place_id:${tempLink}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="btn btn-link"
                                          style={{ fontSize: "14px", color: "#00b721", fontWeight: "500", padding: "10px 0px" }}
                                        >
                                          {t("verify_business")}
                                        </a>
                                      </div>
                                      <div className="">
                                        {isVerified ? (
                                          <>
                                            <button className="btn btn-success me-2 selectreviewverified" disabled>
                                              {t("Business_verified")}
                                            </button>
                                            <button className="selectreviewedit" onClick={handleIncorrectData}>
                                              {t("Edit")}
                                            </button>
                                          </>
                                        ) : (
                                          <button className="btn btn-success me-2 selectreview 
                                          verifybusinesswtd" onClick={handleCorrectData}>
                                            {t("Business_verify")}
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        }



                        <div className="accordion-item ContactFormPadIssue">
                          <h2 className="accordion-header">
                            <div className="d-flex border-0" style={{ alignItems: "center", gap: "8px" }}>
                              {editableHeading === 'contactForm' ? (
                                <input
                                  type="text"
                                  value={contactformHeading}
                                  onClick={(e) => e.stopPropagation()}
                                  onChange={(e) => setcontactformHeading(e.target.value)}
                                  placeholder={t("Enter_contact_form_heading")}
                                  style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                                />
                              ) : (
                                <h5 style={{ fontWeight: "400" }} border-0>
                                  {contactformHeading ? contactformHeading : t("Contact_form")}
                                </h5>
                              )}
                              <button
                                type="button"
                                style={{fontSize : "12px" }}
                                onClick={(e) => {
                                  handleHeadingEdit('contactForm');
                                  e.stopPropagation();
                                }}
                              >
                                {editableHeading === 'contactForm' ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}
                              </button>
                            </div>



                            <div className="form-check form-switch">
                              <label
                                className="form-check-label"
                              // for="flexSwitchCheckDefault"
                              >
                                {t("On_Off")}
                              </label>
                              <input
                                onClick={(e) => {
                                  setIsEnabledContactForm(!isEnabledContactForm);
                                  setOnState({
                                    ...onState,
                                    contactForm: !onState.contactForm,
                                  });
                                }}
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                // onChange={() => toggleDiv("div1")}
                                checked={onState?.contactForm}
                              />
                            </div>
                          </h2>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse "
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          ></div>
                        </div>
                        {/* ///SERVICES-----------------services  */}

                        {themeNumber != 53 || themeNumber == 54 ? (
                          <>
                            {themeNumber !== 15 || themeNumber == 40 ? (
                              (themeNumber == 7 || themeNumber == 9) ? (
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingTwo">
                                  <div className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative' }}>
                            {editableHeading === 'service' ? (
                                          <input
                                            type="text"
                                            value={serviceHeading}
                                            onChange={(e) => setserviceHeading(e.target.value)}
                                            placeholder={t("Enter_service_heading")}
                                            style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                                            />
                                        ) : (
                                          <h5 style={{ fontWeight: "400" }} border-0>
                                            {serviceHeading ? serviceHeading : t("Services")}
                                          </h5>
                                        )}
                                        <button
                                          type="button"
                                          style={{fontSize : "12px" }}
                                          onClick={(e) => {
                                            handleHeadingEdit('service');
                                            e.stopPropagation();
                                          }}
                                        >
                                          {editableHeading === 'service'  ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}

                                        </button>
                                      </div>
                                    <div className="form-check form-switch">
                                      <label
                                        className="form-check-label"
                                      >
                                        {t("On_Off")}
                                      </label>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="flexSwitchCheckDefault"
                                        checked={onState?.services}
                                        onClick={(e) => {
                                          setIsEnabledServices(!isEnabledServices);
                                          setOnState({
                                            ...onState,
                                            services: !onState.services,
                                          });
                                        }}
                                        onChange={() => toggleDiv("div2")}
                                      />
                                    </div>
                                    <button
                              type="button"
                              className="accordion-button collapsed  accordianCustom"

                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"

                            >

                            </button>
                                  </h2>
                                  <div
                                    id="collapseTwo"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div
                                      className="accordion-body"
                                      id="AddnewServicesformwrapper"
                                    >
                                      <div className="row">
                                        <NewBusinessDetilsCreatesaloon
                                          setServicesArray={setServicesArray}
                                          businessObj={businessObj}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingTwo">
                                  <div className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative' }}>
                            {/* <h5 style={{ fontWeight: "400" }}>
                                          {themeNumber == 4 || themeNumber == 18 ? t("Specialization") : t("Services")}
                                        </h5>
                                      </div>
                                    </button>
                                    <input
                                        type="text"
                                        value={serviceHeading}
                                        onChange={(e) => setserviceHeading(e.target.value)}
                                        placeholder="Enter Service"
                                        style={{ marginRight: "10px", padding: "5px",border:"1px solid green" }}
                                      /> */}
                                        {editableHeading === 'service' ? (
                                          <input
                                            type="text"
                                            value={serviceHeading}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={(e) => setserviceHeading(e.target.value)}
                                            placeholder={t("Enter_service_heading")}
                                            style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                                            />
                                        ) : (
                                          <h5 style={{ fontWeight: "400" }} border-0>
                                            {serviceHeading ? serviceHeading : (themeNumber == 4 || themeNumber == 18) ? t("Specialization") : t("Services")}
                                          </h5>
                                        )}
                                        <button
                                          type="button"
                                          style={{fontSize : "12px" }}
                                          onClick={(e) => {
                                            handleHeadingEdit('service');
                                            e.stopPropagation();
                                          }}
                                        >
                                          {editableHeading === 'service' ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}
                                        </button>
                                      </div>
                                    <div className="form-check form-switch">
                                      <label
                                        className="form-check-label"
                                      >
                                        {t("On_Off")}
                                      </label>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="flexSwitchCheckDefault"
                                        checked={onState?.services}
                                        onClick={(e) => {
                                          setIsEnabledServices(!isEnabledServices);
                                          setOnState({
                                            ...onState,
                                            services: !onState.services,
                                          });
                                        }}
                                        onChange={() => toggleDiv("div2")}
                                      />
                                    </div>
                                    <button
                              type="button"
                              className="accordion-button collapsed  accordianCustom"

                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"

                            >

                            </button>
                                  </h2>
                                  <div
                                    id="collapseTwo"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div
                                      className="accordion-body"
                                      id="AddnewServicesformwrapper"
                                    >
                                      <div className="row">
                                        <NewBusinessDetilsCreate
                                          setServicesArray={setServicesArray}
                                          businessObj={businessObj}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            ) : (
                              null
                            )}
                          </>
                        ) : (
                          <></>)}


                        {/* =========testimonial============== */}
                        {themeNumber != 16 && themeNumber != 15 && themeNumber != 53 ? (
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                            <div className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative' }}>
                            {/* <h5 style={{ fontWeight: "400" }}>{t("Testimonials")}</h5>
                                </div>
                              </button>
                              <input
             type="text"
             value={testimonialHeading}
             onChange={(e) => settestimonialHeading(e.target.value)}
             placeholder="Enter Testimonial"
             style={{ marginRight: "10px", padding: "5px",border:"1px solid green" }}
           /> */}
                                  {editableHeading === 'testimonial' ? (
                                    <input
                                      type="text"
                                      value={testimonialHeading}
                                      onClick={(e) => e.stopPropagation()}
                                      onChange={(e) => settestimonialHeading(e.target.value)}
                                      placeholder={t("Enter_testimonial_heading")}
                                      style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                                      />
                                  ) : (
                                    <h5 style={{ fontWeight: "400" }} border-0>
                                      {testimonialHeading ? testimonialHeading : t("Testimonials")}
                                    </h5>
                                  )}
                                  <button
                                    type="button"
                                    style={{fontSize : "12px" }}
                                    onClick={(e) => {
                                      handleHeadingEdit('testimonial');
                                      e.stopPropagation();
                                    }}
                                  >
                                    {editableHeading === 'testimonial' ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}
                                  </button>
                                </div>

                              <div className="form-check form-switch">
                                <label
                                  className="form-check-label"
                                // for="flexSwitchCheckDefault"
                                >
                                  {t("On_Off")}
                                </label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  checked={onState?.testimonials}
                                  onClick={() => {
                                    setIsTestimonial(!isTestimonial);
                                    setOnState({
                                      ...onState,
                                      testimonials: !onState.testimonials,
                                    });
                                  }}
                                  onChange={() => toggleDiv("div3")}
                                />
                              </div>
                              <button
                              type="button"
                              className="accordion-button collapsed  accordianCustom"

                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"

                            >

                            </button>
                            </h2>
                            <div
                              id="collapseThree"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingThree"
                              data-bs-parent="#accordionExample"
                            >
                              <div
                                className="accordion-body"
                                id="AddnewServicesformwrapper"
                              >
                                <div className="row">
                                  <NewTestimonialsCreate
                                    setTestimonialArray={setTestimonialArray}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          null
                        )}


                        {/* ============== Alternate Urls======================== */}

                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingThree">
                          <div className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative' }}>
                            {/* <h5 style={{ fontWeight: "400" }}>{t("Alternate_urls")}</h5>
                              </div>
                            </button>
                            <input
                                type="text"
                                value={alternateUrlsHeading}
                                onChange={(e) => seAlternateUrlsHeading(e.target.value)}
                                placeholder="Alternate Urls Heading"
                                style={{ marginRight: "10px", padding: "5px", border:"1px solid green" }}
                                /> */}
                                {editableHeading === 'alternateUrl' ? (
                                  <input
                                    type="text"
                                    value={alternateUrlsHeading}
                                    onChange={(e) => seAlternateUrlsHeading(e.target.value)}
                                    placeholder={t("Alternate_urls_heading")}
                                    style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                                    />
                                ) : (
                                  <h5 style={{ fontWeight: "400" }} border-0>
                                    {alternateUrlsHeading ? alternateUrlsHeading : t("Alternate_urls")}
                                  </h5>
                                )}
                                <button
                                  type="button"
                                  style={{fontSize : "12px" }}
                                  onClick={(e) => {
                                    handleHeadingEdit('alternateUrl');
                                    e.stopPropagation();
                                  }}
                                >
                                  {editableHeading === 'alternateUrl'  ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}
                                </button>
                              </div>

                            <div className="form-check form-switch">
                              <label
                                className="form-check-label"
                              >
                                {t("On_Off")}
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                checked={onState?.alternateurl}
                                onClick={() => {
                                  setAlternateUrl(!isAlternateUrl);
                                  setOnState({
                                    ...onState,
                                    alternateurl: !onState.alternateurl,
                                  });
                                }}
                                onChange={() => toggleDiv("div10")}
                              />
                            </div>
                            <button
                              type="button"
                              className="accordion-button collapsed  accordianCustom"

                              data-bs-toggle="collapse"
                              data-bs-target="#ten"
                              aria-expanded="false"
                              aria-controls="ten"

                            >

                            </button>
                          </h2>
                          <div
                            id="ten"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div
                              className="accordion-body"
                              id="AddnewServicesformwrapper"
                            >
                              <div className="row">
                                <NewAlternateUrlCreate
                                  setAlternateUrlArray={setAlternateUrlArray}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* =========social=================== */}
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingFive">
                          <div className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative' }}>
                            {/* <h5 style={{ fontWeight: "400" }}>{t("Social_media")}</h5>
                              </div>
                            </button>
                            <input
                                type="text"
                                value={socialHeading}
                                onChange={(e) => setSocialHeading(e.target.value)}
                                placeholder="BusinessHours Heading"
                                style={{ marginRight: "10px", padding: "5px", border:"1px solid green" }}
                                /> */}
                                {editableHeading === 'socialMedia' ? (
                                  <input
                                    type="text"
                                    value={socialHeading}
                                    onChange={(e) => setSocialHeading(e.target.value)}
                                    placeholder={t("Enter_Social_heading")}
                                    style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                                    />
                                ) : (
                                  <h5 style={{ fontWeight: "400" }} border-0>
                                    {socialHeading ? socialHeading : t("Social_media")}
                                  </h5>
                                )}
                                <button
                                  type="button"
                                  style={{fontSize : "12px" }}
                                  onClick={(e) => {
                                    handleHeadingEdit('socialMedia');
                                    e.stopPropagation();
                                  }}
                                >
                                  {editableHeading === 'socialMedia' ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}
                                </button>
                              </div>

                            <div className="form-check form-switch">
                              <label
                                className="form-check-label"
                              // for="flexSwitchCheckDefault"
                              >
                                {t("On_Off")}
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                checked={onState?.socials}
                                onChange={() => toggleDiv("div5")}
                                onClick={() => {
                                  setIsSocial(!isSocial);
                                  setOnState({
                                    ...onState,
                                    socials: !onState.socials,
                                  });
                                }}
                              />
                            </div>
                            <button
                              type="button"
                              className="accordion-button collapsed  accordianCustom"

                              data-bs-toggle="collapse"
                              data-bs-target="#five"
                              aria-expanded="false"
                              aria-controls="five"

                            >

                            </button>
                          </h2>
                          <div
                            id="five"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFive"
                            data-bs-parent="#accordionExample"
                          >
                            <div
                              className="accordion-body"
                              id="AddnewServicesformwrapper"
                              style={{ padding: "15px 0" }}
                            >
                              {/* {divStates.div5 && ( */}
                              <div
                                className="row"
                                style={{ justifyContent: "center" }}
                              >
                                <NewSocialmediaCreate
                                  setSocialMediaData={setSocialMediaData}
                                  socialMediaData={socialMediaData}
                                />
                              </div>
                              {/* )} */}
                            </div>
                          </div>
                        </div>
                        {/* =====business hour===== */}
                        {themeNumber != 53 ? (
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingsix">
                            <div className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative' }}>
                            {/* <h5 style={{ fontWeight: "400" }}>
                                    {t("Business_hours")}
                                  </h5>
                                </div>
                              </button>
                              <input
                                type="text"
                                value={businessHourHeading}
                                onChange={(e) => setBusinessHourHeading(e.target.value)}
                                placeholder="BusinessHours Heading"
                                style={{ marginRight: "10px", padding: "5px", border:"1px solid green" }}
                                /> */}
                                  {editableHeading === 'businessHours' ? (
                                    <input
                                      type="text"
                                      value={businessHourHeading}
                                      onChange={(e) => setBusinessHourHeading(e.target.value)}
                                      placeholder={t("Enter_business_hour_heading")}
                                      style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                                      />
                                  ) : (
                                    <h5 style={{ fontWeight: "400" }} border-0>
                                      {businessHourHeading ? businessHourHeading : t("Business_hours")}
                                    </h5>
                                  )}
                                  <button
                                    type="button"
                                    style={{fontSize : "12px" }}
                                    onClick={(e) => {
                                      handleHeadingEdit('businessHours');
                                    }}
                                  >
                                    {editableHeading === 'businessHours' ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}
                                  </button>
                                </div>

                              <div className="form-check form-switch">
                                <label
                                  className="form-check-label"
                                // for="flexSwitchCheckDefault"
                                >
                                  {t("On_Off")}
                                </label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  checked={onState?.businessHours}
                                  onChange={() => toggleDiv("div6")}
                                  onClick={(e) => {
                                    setIsBusinessHourObject(!IsBusinessHourObject);
                                    setIsAppointment(false);
                                    setOnState({
                                      ...onState,
                                      businessHours: !onState.businessHours,
                                      appointment: false,
                                    });
                                  }}
                                />
                              </div>
                              <button
                              type="button"
                              className="accordion-button collapsed  accordianCustom"

                              data-bs-toggle="collapse"
                              data-bs-target="#six"
                              aria-expanded="false"
                              aria-controls="six"

                            >

                            </button>
                            </h2>
                            <div
                              id="six"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingsix"
                              data-bs-parent="#accordionExample"
                            >
                              <div
                                className="accordion-body"
                                style={{ padding: "10px 0" }}
                              >
                                {/* {divStates.div6 && ( */}
                                <div className="row" style={{ padding: "10px 0" }}>
                                  <NewBusinessHour
                                    setAppointmentArray={setAppointmentArray}
                                    appointmentSlotDuration={appointmentSlotDuration}
                                    setAppointmentSlotDuration={
                                      setAppointmentSlotDuration
                                    }
                                    setBusinessHourObject={setBusinessHourObject}
                                  />
                                </div>
                                {/* )} */}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* =============appointments============ */}
                        {themeNumber != 53 ? (
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFour">
                            <div className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative' }}>
                                {/* <h5 style={{ fontWeight: "400" }}>{t("Appointments")}</h5>
                                <input
                                type="text"
                                value={appointmentsHeading}
                                onChange={(e) => setAppointmentsHeading(e.target.value)}
                                placeholder="Appointments Heading"
                                style={{ marginRight: "10px", padding: "5px", border:"1px solid green" }}
                                /> */}
                                {editableHeading === 'appointments' ? (
                                  <input
                                    type="text"
                                    value={appointmentsHeading}
                                    onChange={(e) => setAppointmentsHeading(e.target.value)}
                                    placeholder={t("Enter_appointment_heading")}
                                    style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                                    />
                                ) : (
                                  <h5 style={{ fontWeight: "400" }} border-0>
                                    {appointmentsHeading ? appointmentsHeading : t("Appointments")}
                                  </h5>
                                )}
                                <button
                                  type="button"
                                  style={{fontSize : "12px" }}
                                  onClick={(e) => {
                                    handleHeadingEdit('appointments');
                                   
                                  }}
                                >
                                  {editableHeading === 'appointments' ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}
                                </button>
                               
                              </div>
                              <div class="form-check form-switch">
                                  <label
                                    class="form-check-label"
                                  // for="flexSwitchCheckDefault"
                                  >
                                    {t("On_Off")}
                                  </label>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                    checked={onState?.appointment}
                                    disabled={!onState?.businessHours}
                                    onChange={() => toggleDiv("div4")}
                                    onClick={() => {
                                      setIsAppointment(!isAppointment);
                                      setOnState({
                                        ...onState,
                                        appointment: !onState?.appointment,
                                      });
                                    }}
                                  />
                                </div>
                            </h2>
                            {/* <div
              id="four"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                {divStates.div4 && (
                <div className="row">
                  <NewAppointmentsCreate
                    businessHourObject={businessHourObject}
                    appointmentSlotDuration={appointmentSlotDuration}
                    businessObj={businessObj}
                    appointmentArray={appointmentArray}
                    setAppointmentArray={setAppointmentArray}
                  />
                </div>
                )}
              </div>
            </div> */}
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* ======gallary=========== */}
                        {themeNumber !== 17 ? (
                          <div className="accordion-item">
                            <div className="accordion-header" id="headingseven">
                            <div className="d-flex" style={{ gap: "8px", alignItems: "center" , width:'fit-content', zIndex:'99', position:'relative' }}>
                            {/* <h5 style={{ fontWeight: "400" }}>

                                    {themeNumber !== "53" ? t("Portfolio") : t("Brand_deals")}</h5>
                                </div>
                              </button>
                              <input
                                type="text"
                                value={galleryHeading}
                                onChange={(e) => setGalleryHeading(e.target.value)}
                                placeholder="Portfolio Heading"
                                style={{ marginRight: "10px", padding: "5px", border:"1px solid green" }}
                                /> */}
                                  {editableHeading === 'portfolio' ? (
                                    <input
                                      type="text"
                                      value={galleryHeading}
                                      onChange={(e) => setGalleryHeading(e.target.value)}
                                      placeholder={t("Enter_gallery_heading")}
                                      style={{ marginRight: "10px", padding: "5px", border: "1px solid #cacaca", fontSize:'14px', borderRadius:'4px', fontWeight:'400' }}
                                      />
                                  ) : (
                                    <h5 style={{ fontWeight: "400" }} border-0>
                                      {galleryHeading ? galleryHeading : themeNumber !== "53" ? t("Portfolio") : t("Brand_deals")}
                                    </h5>
                                  )}
                                  <button
                                    type="button"
                                    style={{fontSize : "12px" }}
                                    onClick={(e) => {
                                      handleHeadingEdit('portfolio');
                                      e.stopPropagation();
                                    }}
                                  >
                                    {editableHeading === 'portfolio' ? <i class="fa-solid fa-check" style={{background:'green', borderRadius:'4px', padding: '5px', color:'#fff'}}></i> : <i class="fas fa-edit"></i>}
                                  </button>
                                </div>

                              <div className="form-check form-switch">
                                <label
                                  className="form-check-label"
                                // for="flexSwitchCheckDefault"
                                >
                                  {t("On_Off")}
                                </label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  checked={onState?.gallery}
                                  onClick={(e) => {
                                    SetIsGallaryEnable(!isGallaryEnable);
                                    setOnState({
                                      ...onState,
                                      gallery: !onState.gallery,
                                    });
                                  }}
                                  onChange={() => toggleDiv("div7")}
                                />
                              </div>

                              <button
                              type="button"
                              className="accordion-button collapsed  accordianCustom"

                              data-bs-toggle="collapse"
                              data-bs-target="#seven"
                              aria-expanded="false"
                              aria-controls="seven"

                            >

                            </button>
                            </div>
                            <div
                              id="seven"
                              className={`accordion-collapse collapse`}
                              aria-labelledby="headingseven"
                              data-bs-parent="#accordionExample"
                            >
                              <div
                                className="accordion-body"
                                id="AddnewGallaryformwrapper"
                              >
                                <div className="row">
                                  <NewGallerycreate
                                    businessId={businessId}
                                    existingGalleryImages={existingGalleryImages}
                                    setExistingGalleryImages={
                                      setExistingGalleryImages
                                    }
                                    gallaryObject={gallaryObject}
                                    galleryData={galleryData}
                                    setGalleryData={setGalleryData}
                                    galleryView={galleryView}
                                    setGalleryView={setGalleryView}
                                    setGallaryObject={setGallaryObject}
                                    isGallaryEnable={isGallaryEnable}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        {/* =============Agent Code============ */}
                        {

                          (userData.user_type === 'Sales Executive' || userData.user_type === 'Team Leader' || userData.user_type === 'super_admin') ? " " : <div className="accordion-item" style={{ padding: "10px 25px" }}>
                            <h2 className="accordion-header" id="headingEight">
                              <div className="d-flex justify-content-between" style={{ width: "100%" }}>
                                <h5 style={{ fontWeight: "400", display: "flex", alignItems: "center" }}>{t("Agent_code")} {t("Optional")}</h5>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="agentCode"
                                  value={userData.user_type === 'Sales Executive' ? businessObj?.agentCode || agentCode : agentCode}
                                  onChange={validateAgentCodeInput}
                                  maxLength={6}
                                  placeholder={`${t("Agent_code")}`}
                                  style={{ width: "50%" }}
                                  readOnly={!!businessObj?.agentCode && (userData.user_type === 'Sales Executive' || !userData.user_type)}
                                />
                              </div>
                              {isVerifyCodeSubmit ? (
                                <>
                                  <CircularLoader size={40} />
                                  {verifyCodeError && <p className="error-mgs text-danger">{verifyCodeError}</p>}
                                </>
                              ) : agentCode.length === 6 && isVerifyCodeVerified ? (
                                <p className="text-success mt-2">{t("Verified")}</p>
                              ) : agentCode.length === 6 && !isVerifyCodeVerified ? (
                                <p className="text-danger mt-2">{t("Not_verified")}</p>
                              ) : agentCode.length > 0 && agentCode.length < 6 ? (
                                <p className="text-danger mt-2">{t("agent_code_limit")}</p>
                              ) : null}
                            </h2>
                          </div>

                        }
                        {themeNumber == 53 ? (
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThreeone">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThreeone"
                                aria-expanded="false"
                                aria-controls="collapseThreeone"
                                onClick={() => {
                                  // setOnState({
                                  //   ...onState,
                                  // });
                                }}
                              >
                                <div className="d-flex">
                                  <h5 style={{ fontWeight: "400" }}>{t("Product")}</h5>
                                </div>
                              </button>
                              {/* <div className="form-check form-switch">
                                <label
                                  className="form-check-label"
                                // for="flexSwitchCheckDefault"
                                >
                                  {t("On_Off")}
                                </label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  checked={onState?.influencerproduct}
                                  onClick={() => {
                                    setIsInfluencerProduct(!isInfluencerProduct);
                                    setOnState({
                                      ...onState,
                                      influencerproduct: !onState.influencerproduct,
                                    });
                                  }}
                                  onChange={() => toggleDiv("div31")}
                                />
                              </div> */}

                            </h2>
                            <div
                              id="collapseThreeone"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingThreeone"
                              data-bs-parent="#accordionExample"
                            >
                              <div
                                className="accordion-body"
                                id="AddnewServicesformwrapper"
                              >
                                {/* <div className="row">
                                   <NewTestimonialsCreate
                                    setTestimonialArray={setTestimonialArray}
                                  /> 
                                  <button className="btn btn-cu addbuttonedit"> Add product </button>
                                </div> */}
                                <div className="infaddproduct">
                                  {!!dataFromInfluencerChild && dataFromInfluencerChild?.length > 0 && dataFromInfluencerChild?.map((pdata, index) => {
                                    return (
                                      <div className="influencerproductctn">
                                        {/* <div>{pdata?.productData?.product_name}</div> */}

                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                          <div className="influencerproductimgcont" style={{ position: "relative" }}>
                                            <div className="influencerproductdv"
                                              //  onClick={ProductHandlerdelete}
                                              onClick={() => ProductHandlerdelete(pdata?.id, businessId)}
                                            > <img loading="lazy" className="influencerproductimg" src={closeimg} />
                                            </div>
                                          <img loading="lazy" src={`${environmentVariables?.s3UrlBackendUrl}${pdata?.productData?.image}`} style={{ width: "150px", height: "130px", borderRadius: "8px", border: "2px solid #e3e3e3" }} />
                                          </div>
                                          <div className="Influencerproductname">{pdata?.productData?.product_name}</div>

                                          <div style={{ display: "flex" }}>

                                            <div className="Influencerproductname listingPrice"><span>
                                              {currencyName}{(!!pdata?.listing_price) ? parseFloat(pdata.listing_price).toFixed(2) : ""} </span>
                                            </div>

                                            <div className="tooltip-wrapperLp">
                                              <h3 className="tooltipcontainer_detail">
                                                <span className="tooltip-target-detail"> <img className="tooltipimgDetail" src={TooltipImg} style={{ width: '18px' }} />  </span>
                                                <span className="tooltip-detailLp">{t("Listing_price")}</span>
                                              </h3>
                                            </div>
                                          </div>
                                          <div className="Influencerproductname basePrice">({t('Base_price')}: &nbsp; <span> {currencyName}{pdata?.base_price ? parseFloat(pdata.base_price).toFixed(2) : "0.00"}) </span> </div>

                                          {/* <div className="tooltip-wrapperBp">
  <h3 className="tooltipcontainer_detail">
    <span className="tooltip-target-detail"> <img loading="lazy" className="tooltipimgDetail" src={TooltipImg}  style={{width:'18px'}}/>  </span> Base Price: {currencyName}{pdata?.base_price}
    <span className="tooltip-detail">This represents the base price.</span>
  </h3>
                </div> */}


                                        </div>
                                      </div>
                                    )
                                  })}
                                </div>
                                <div>
                                  <button type="button" className="btn btn-cu save-cta Changeblock_saveandpreview_button" onClick={openModalinfluancer} >{t("Choose_products")}</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          null
                        )}
                        {themeNumber == 53 && (
                          <Addinfluancerdetailspopup formikData={formik.values} isOpen={isModalOpeninf} onClose={closeModalInfluancer} handleFetchedInfluencerData={handleFetchedInfluencerData} selectedPercentage={selectedPercentage} fetchinfluencerpercentage={fetchinfluencerpercentage} />
                        )}



                        {formik.errors.is_allow_in_search && formik.touched.is_allow_in_search && (
                          <div style={{ color: "red", fontSize: "12px" }}>{formik.errors.is_allow_in_search}</div>
                        )}


                        {/* newteamleadid for crm */}
                        {(userData?.user_type == "super_admin" || userData?.user_type == "Team Leader" || userData?.user_type == "Sales Executive") &&
                          <div className="accordion-item" style={{ padding: "10px 25px" }}>
                            <h2 className="accordion-header" id="headingEight">
                              <div className="d-flex justify-content-between" style={{ width: "100%" }}>
                                <h5 style={{ fontWeight: "400", display: "flex", alignItems: "center" }}>{t("Lead_id")}</h5>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="teamlead"
                                  value={teamleadId}
                                  // value={userData.user_type === 'Sales Executive' ? businessObj?.teamleadId || teamleadId : teamleadId}
                                  onChange={validateTeamLeadInput}
                                  maxLength={6}
                                  placeholder={`${t("Lead_id")}`}
                                  style={{ width: "50%" }}
                                // readOnly={!!businessObj?.teamleadId && (userData.user_type === 'Sales Executive' || !userData.user_type)}
                                />
                              </div>
                            </h2>
                          </div>
                        }

                        {
                          userData?.user_type == "super_admin" &&
                          <div className="accordion-item">
                            <div className="accordion-header" id="headingeight">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#eight"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                              >
                                <div className="d-flex">
                                  <h5 style={{ fontWeight: "400" }}>{t("Meta_pixel_code")}</h5>
                                </div>
                              </button>

                            </div>
                            <div
                              id="eight"
                              className={`accordion-collapse collapse`}
                              aria-labelledby="headingeight"
                              data-bs-parent="#accordionExample"
                              style={{ paddingTop: "10px" }}
                            >
                              <div className="mb-3 col-md-12 AddDetailswidContainer">
                                <input
                                  value={pixel_id}
                                  className="form-control"
                                  type="text"
                                  placeholder={`${t("Meta_pixel_code")}`}
                                  onChange={(e) => handleChangePixelId(e)}
                                />
                              </div>

                            </div>
                          </div>
                        }


















                        {/* /////////////////////////////////////////   meta keywords //////////////////////////////////////////// */}


                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingTwofive">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwofive"
                              aria-expanded="false"
                              aria-controls="collapseTwofive"
                              onClick={(e) => {
                              }}
                            >
                              <div className="d-flex">
                                <h5 style={{ fontWeight: "400" }}>{t("Keywords_metatags")}</h5>
                              </div>
                            </button>
                          </h2>
                          <div
                            id="collapseTwofive"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwofive"
                            data-bs-parent="#accordionExample"
                          >
                            <div
                              className="accordion-body"
                              id="AddnewServicesformwrapper"
                            >
                              <div className="row">
                                <NewBusinessDetilsCreateKeywords
                                  setKeywordArray={setKeywordArray}
                                  businessObj={businessObj}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion" id="accordionExample">
                          <div className="accordion-item">
                            <h4 className="accordion-header" id="headingTwo">
                              <button
                                style={{ fontWeight: "400", fontSize: "15.3px" }}
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseColorPicker"
                                aria-expanded="false"
                                aria-controls="collapseColorPicker"
                              >
                                {t("Theme_color_picker")}
                              </button>
                            </h4>
                            <div
                              id="collapseColorPicker"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingTwo"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <div className="color-picker-container" >
                                  {/* Main Background Color Picker */}
                                  <div className="color-picker-box">

                                    <div className="color-picker-item">

                                      <p className="colorpicker-name">{t("Main_background_color")}:</p>
                                      <div className="color-picker-info" style={{ background: dynamicMainBackgroundColor ? `${dynamicMainBackgroundColor}13` : "", border: dynamicMainBackgroundColor ? `1px solid ${dynamicMainBackgroundColor}20` : "" }}>


                                        <div className="color-picker-info-detail" ref={colorPickerContainerRef[0]}>
                                          <div className="color-box-container" style={{ borderColor: dynamicMainBackgroundColor, }}>
                                            <div className="color-box"
                                              onClick={() => toggleColorPicker(0)}
                                              style={{
                                                backgroundColor:
                                                  dynamicMainBackgroundColor,
                                                borderColor: dynamicMainBackgroundColor,
                                              }}
                                            ></div>
                                            {isColorPickerOpen[0] && (<SketchPicker
                                              color={dynamicMainBackgroundColor}
                                              onChangeComplete={mainBghandleChangeComplete}
                                            />)}
                                          </div>

                                          <div>

                                            <span
                                              style={{ fontSize: '13px' }}
                                              className="hex-color-name"
                                            >
                                              {t("Hex")}: {dynamicMainBackgroundColor}
                                            </span>
                                          </div>
                                        </div>

                                        <button
                                          type="button"
                                          onClick={() =>
                                            setDynamicMainBackgroundColor("")
                                          }
                                          className="reset-button"
                                        >
                                          {t("Reset")}
                                        </button>
                                      </div>
                                    </div>

                                    {/* <div className="color-preview">
                                
                                
                              </div> */}
                                  </div>

                                  {/* Background Color Picker */}
                                  <div className="color-picker-box">

                                    <div className="color-picker-item">

                                      <p className="colorpicker-name" >{t("Background_color")}:</p>
                                      <div className="color-picker-info" style={{ background: dynamicBackgroundColor ? `${dynamicBackgroundColor}13` : "", border: dynamicBackgroundColor ? `1px solid ${dynamicBackgroundColor}20` : "" }}>


                                        <div className="color-picker-info-detail" ref={colorPickerContainerRef[1]}>
                                          <div className="color-box-container" style={{ borderColor: dynamicBackgroundColor }}>
                                            <div className="color-box"
                                              onClick={() => toggleColorPicker(1)}
                                              style={{
                                                backgroundColor:
                                                  dynamicBackgroundColor,
                                                borderColor: dynamicBackgroundColor,
                                              }}
                                            ></div>
                                            {isColorPickerOpen[1] && (<SketchPicker
                                              color={dynamicBackgroundColor}
                                              onChangeComplete={handleChangeComplete}
                                            />)}
                                          </div>

                                          <div>

                                            <span
                                              style={{ fontSize: '13px' }} className="hex-color-name"
                                            >
                                              {t("Hex")}: {dynamicBackgroundColor}
                                            </span>
                                          </div>
                                        </div>

                                        <button
                                          type="button"
                                          onClick={() =>
                                            setDynamicBackgroundColor("")
                                          }
                                          className="reset-button"
                                        >
                                          {t("Reset")}
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Heading Color Picker */}
                                  <div className="color-picker-box">

                                    <div className="color-picker-item">

                                      <p className="colorpicker-name" >{t("Heading_color")}:</p>
                                      <div className="color-picker-info" style={{ background: dynamicTextHeadingColor ? `${dynamicTextHeadingColor}13` : "", border: dynamicTextHeadingColor ? `1px solid ${dynamicTextHeadingColor}20` : "" }}>


                                        <div className="color-picker-info-detail" ref={colorPickerContainerRef[2]}>
                                          <div className="color-box-container" style={{ borderColor: dynamicTextHeadingColor }}>
                                            <div className="color-box"
                                              onClick={() => toggleColorPicker(2)}
                                              style={{
                                                backgroundColor:
                                                  dynamicTextHeadingColor,
                                                borderColor: dynamicTextHeadingColor,
                                              }}
                                            ></div>
                                            {isColorPickerOpen[2] && (<SketchPicker
                                              color={dynamicTextHeadingColor}
                                              onChangeComplete={
                                                HeadingcolorhandleChangeComplete
                                              }
                                            />)}
                                          </div>

                                          <div>

                                            <span
                                              style={{ fontSize: '13px' }} className="hex-color-name"
                                            >
                                              {t("Hex")}: {dynamicTextHeadingColor}
                                            </span>
                                          </div>
                                        </div>

                                        <button
                                          type="button"
                                          onClick={() =>
                                            setDynamicTextHeadingColor("")
                                          }
                                          className="reset-button"
                                        >
                                          {t("Reset")}
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Description Color Picker */}
                                  <div className="color-picker-box">

                                    <div className="color-picker-item">

                                      <p className="colorpicker-name">{t("Description_color")}:</p>
                                      <div className="color-picker-info" style={{ background: dynamicTextDescriptionColor ? `${dynamicTextDescriptionColor}13` : "", border: dynamicTextDescriptionColor ? `1px solid ${dynamicTextDescriptionColor}20` : "" }}>


                                        <div className="color-picker-info-detail" ref={colorPickerContainerRef[3]}>
                                          <div className="color-box-container" style={{
                                            borderColor: dynamicTextDescriptionColor
                                          }}>
                                            <div className="color-box"
                                              onClick={() => toggleColorPicker(3)}
                                              style={{
                                                backgroundColor:
                                                  dynamicTextDescriptionColor
                                                ,
                                                borderColor: dynamicTextDescriptionColor
                                                ,
                                              }}
                                            ></div>
                                            {isColorPickerOpen[3] && (<SketchPicker
                                              color={dynamicTextDescriptionColor}
                                              onChangeComplete={
                                                DescriptioncolorhandleChangeComplete
                                              }
                                            />)}
                                          </div>

                                          <div>

                                            <span
                                              style={{ fontSize: '13px' }} className="hex-color-name"
                                            >
                                              {t("Hex")}: {dynamicTextDescriptionColor
                                              }
                                            </span>
                                          </div>
                                        </div>

                                        <button
                                          type="button"
                                          onClick={() =>
                                            setDynamicTextDescriptionColor("")
                                          }
                                          className="reset-button"
                                        >
                                          {t("Reset")}
                                        </button>
                                      </div>
                                    </div>

                                  </div>

                                  {/* CTA Color Picker */}
                                  <div className="color-picker-box">

                                    <div className="color-picker-item">

                                      <p className="colorpicker-name">{t("Cta_color")}:</p>
                                      <div className="color-picker-info" style={{ background: dynamicCtaColor ? `${dynamicCtaColor}13` : "", border: dynamicCtaColor ? `1px solid ${dynamicCtaColor}20` : "" }}>


                                        <div className="color-picker-info-detail" ref={colorPickerContainerRef[4]}>
                                          <div className="color-box-container" style={{
                                            borderColor: dynamicCtaColor
                                          }}>
                                            <div className="color-box"
                                              onClick={() => toggleColorPicker(4)}
                                              style={{
                                                backgroundColor:
                                                  dynamicCtaColor
                                                ,
                                                borderColor: dynamicCtaColor
                                                ,
                                              }}
                                            ></div>
                                            {isColorPickerOpen[4] && (<SketchPicker
                                              color={dynamicCtaColor}
                                              onChangeComplete={
                                                CtacolorhandleChangeComplete
                                              }
                                            />)}
                                          </div>

                                          <div>

                                            <span
                                              style={{ fontSize: '13px' }} className="hex-color-name"
                                            >
                                              {t("Hex")}: {dynamicCtaColor
                                              }
                                            </span>
                                          </div>
                                        </div>

                                        <button
                                          type="button"
                                          onClick={() =>
                                            setdynamicCtaColor("")
                                          }
                                          className="reset-button"
                                        >
                                          {t("Reset")}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  {/* CTA TeXt Color Picker */}
                                  <div className="color-picker-box">

                                    <div className="color-picker-item">

                                      <p className="colorpicker-name">{t("Cta_text_color")}:</p>
                                      <div className="color-picker-info" style={{ background: dynamicCtaTextColor ? `${dynamicCtaTextColor}13` : "", border: dynamicCtaTextColor ? `1px solid ${dynamicCtaTextColor}20` : "" }}>


                                        <div className="color-picker-info-detail" ref={colorPickerContainerRef[5]}>
                                          <div className="color-box-container" style={{ borderColor: dynamicCtaTextColor, }}>
                                            <div className="color-box"
                                              onClick={() => toggleColorPicker(5)}
                                              style={{
                                                backgroundColor:
                                                  dynamicCtaTextColor,
                                                borderColor: dynamicCtaTextColor,
                                              }}
                                            ></div>
                                            {isColorPickerOpen[5] && (<SketchPicker
                                              color={dynamicCtaTextColor}
                                              onChangeComplete={ctaTextcolorHandler}
                                            />)}
                                          </div>

                                          <div>

                                            <span
                                              style={{ fontSize: '13px' }}
                                              className="hex-color-name"
                                            >
                                              {t("Hex")}: {dynamicCtaTextColor}
                                            </span>
                                          </div>
                                        </div>

                                        <button
                                          type="button"
                                          onClick={() =>
                                            setdynamicCtaTextColor("")
                                          }
                                          className="reset-button"
                                        >
                                          {t("Reset")}
                                        </button>
                                      </div>
                                    </div>

                                    {/* <div className="color-preview">
                                
                                
                              </div> */}
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="accordion-item">
                          <div className="accordion-header" id="headingnine">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#nine"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              <div className="d-flex">
                                <h5 style={{ fontWeight: "400" }}>{t("Meta_title")}</h5>
                              </div>
                            </button>

                          </div>
                          <div
                            id="nine"
                            className={`accordion-collapse collapse`}
                            aria-labelledby="headingnine"
                            data-bs-parent="#accordionExample"
                            style={{ paddingTop: "10px" }}
                          >
                            <div className="mb-3 col-md-12 AddDetailswidContainer">
                              <input
                                value={metaTitle}
                                className="form-control"
                                type="text"
                                placeholder={`${t("Meta_title")}`}
                                onChange={(e) => handleChangeMetaTitle(e)}
                              />
                              {metaTitle.length >= 65 && (
                                <small style={{ color: "red" }}>
                                  {t("Meta_titl_limit")}
                                </small>
                              )}
                            </div>

                          </div>
                        </div>

                        <div className="accordion-item">
                          <div className="accordion-header" id="headingeleven">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#eleven"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              <div className="d-flex">
                                <h5 style={{ fontWeight: "400" }}>{t("Meta_description")}</h5>
                              </div>
                            </button>

                          </div>
                          <div
                            id="eleven"
                            className={`accordion-collapse collapse`}
                            aria-labelledby="headingeleven"
                            data-bs-parent="#accordionExample"
                            style={{ paddingTop: "10px" }}
                          >
                            <div className="mb-3 col-md-12 AddDetailswidContainer">
                              <input
                                value={metaDescription}
                                className="form-control"
                                type="text"
                                placeholder={`${t("Meta_description")}`}
                                onChange={(e) => handleChangeMetades(e)}
                              />
                              {metaDescription.length >= 165 && (
                                <small style={{ color: "red" }}>
                                  {t("Meta_desc_limit")}
                                </small>
                              )}
                            </div>

                          </div>
                        </div>

                        <div className="accordion-item">
                          <div className="accordion-header" id="headingtwelve">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#twelve"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              <div className="d-flex">
                                <h5 style={{ fontWeight: "400" }}>{t("Canonical_tag")}</h5>
                              </div>
                            </button>

                          </div>
                          <div
                            id="twelve"
                            className={`accordion-collapse collapse`}
                            aria-labelledby="headingtwelve"
                            data-bs-parent="#accordionExample"
                            style={{ paddingTop: "10px" }}
                          >
                            <div className="mb-3 col-md-12 AddDetailswidContainer">
                              <input
                                value={canonicalTag}
                                className="form-control"
                                type="text"
                                placeholder={`${t("Canonical_tag")}`}
                                onChange={(e) => handleChangeCanicaltag(e)}
                              />
                            </div>

                          </div>
                        </div>

                      </div>

                      <div className="row Adddetails_saveprev_ButtonWrapper">
                        <div className="Changeblock_saveandpreview_buttonWrapper Changeblock_saveandpreview_buttonWrappertwo">
                          <button
                            className="btn btn-cu save-cta Changeblock_saveandpreview_button "
                            id="changeblockprenxtbtn"
                            type="button"
                            onClick={handlePreviousClick}
                          >
                            {t("Previous")}
                          </button>
                          {/* </div>
            <div className="Changeblock_saveandpreview_buttonWrapper"> */}
                          {isSubmit ? (
                            <CircularLoader size={40} />
                          ) : (
                            <button
                              type="button"
                              className="btn btn-cu save-cta Changeblock_saveandpreview_button"
                              id="changeblockprenxtbtn"
                              onClick={(e) => handleSubmit(e)}
                            >
                              {t("Save_next")}
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )
            )}
          </div>

          {/* <div>
              <button type="button" className="btn btn-cu save-cta Changeblock_saveandpreview_button" onClick={openModalinfluancer} > + Add Influancer</button>
          </div> */}

          {/* <Addinfluancerdetailspopup isOpen={isModalOpeninf} onClose={closeModalInfluancer} /> */}

          <div className="ChangeBlockView_Container">
            <ViewThem
              mobilePreviewLoading={mobilePreviewLoading}
              isLoadingSubmit={isLoadingSubmit}
              handleSubmit={handleSubmitWithoutTabChange}
              activeTab={activeTab}
              existingGalleryImages={existingGalleryImages}
              galleryView={galleryView}
              businessObj={businessObj}
              title={title}
              isDine={isDine}
              isShowBusinessName={isShowBusinessName}
              isShowAddress={isShowAddress}
              themeColor={themeColor}
              themeNumber={themeNumber}
              designation={formik.values.designation}
              googlereview={formik.values.googlereview}
              subTitle={formik.values.subTitle}
              description={formik.values.description}
              bannerImageUrl={bannerImageUrl}
              logoImageUrl={logoImageUrl}
              contactInfo={{
                contactInfoOptAddress,
                contactInfoAddress,
                contactInfoEmail: formik.values.contactInfoEmail,
                contactInfoPhone: formik.values.contactInfoPhone,
                optionalContactInfoPhone: formik.values.optionalContactInfoPhone,
                contactInfoWebUrl: formik.values.contactInfoWebUrl,
              }}
              businessHourObject={businessHourObject}
              socialMediaData={socialMediaData}
              termsConditionData={termsConditionData}
              servicesArray={servicesArray}
              testimonialArray={testimonialArray}
              alternateUrlArray={alternateUrlArray}
              restaurantArray={restaurantArray}
              minimarketArray={minimarketArray}
              cabArray={cabArray}
              gallaryObject={gallaryObject}
              galleryData={galleryData}
              onState={onState}
              appointmentArray={appointmentArray}
              rating={rating}
              reviews={reviews}
              dynamicMainBackgroundColor={dynamicMainBackgroundColor}
              dynamicTextHeadingColor={dynamicTextHeadingColor}
              dynamicBackgroundColor={dynamicBackgroundColor}
              dynamicTextDescriptionColor={dynamicTextDescriptionColor}
              dynamicCtaColor={dynamicCtaColor}
              dynamicCtaTextColor={dynamicCtaTextColor}
              keywordArray={keywordArray}
              descriptionHeading={descriptionHeading}
              galleryHeading={galleryHeading}
              appointmentsHeading={appointmentsHeading}
              businessHourHeading={businessHourHeading}
              socialHeading={socialHeading}
              alternateUrlsHeading={alternateUrlsHeading}
              contactHeading={contactHeading}
              reviewHeading={reviewHeading}
              serviceHeading={serviceHeading}
              testimonialHeading={testimonialHeading}
              contactformHeading={contactformHeading}
              cabProductHeading={cabProductHeading}
              termConditionHeading={termConditionHeading}
              menuHeading={menuHeading}
              orderHeading={orderHeading}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
      <Modal sx={modalStyle} open={modalOpen}

      >

        <Box sx={boxStyle}>

          <AvatarEditor
            ref={cropRef}
            image={src}
            style={{ width: "100%", height: "100%" }}
            border={50}
            borderRadius={150}
            color={[0, 0, 0, 0.72]}
            scale={slideValue / 15}
            rotate={0}
          />

          <Slider
            min={10}
            max={50}
            sx={{
              margin: "0 auto",
              width: "80%",
              color: "cyan",
            }}
            size="medium"
            defaultValue={slideValue}
            value={slideValue}
            onChange={(e) => setSlideValue(e.target.value)}
          />

          <Box
            sx={{
              display: "flex",
              padding: "10px",
              border: "3px solid white",
              background: "black",
              flexDirection: "column"
            }}
          >
            <div style={{ color: "red", fontWeight: "500", margin: "5px 10px 10px 10px", fontSize: "14px", }}> {t("Please_use_the_zoom_logo")}</div>
            <Button
              size="small"
              sx={{
                marginBottom: "10px",
                color: "white",
                borderColor: "white",
              }}
              variant="outlined"
              onClick={(e) => setModalOpen(false)}
            >
              {t("Cancel")}
            </Button>
            <Button
              sx={{ background: "#5596e6" }}
              size="small"
              variant="contained"
              onClick={handleSave}
            >
              {t("Save")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default Details;
