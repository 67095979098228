import React from 'react'
import { getServicesArray } from "../../../../utils/utility";

const CabAlternateUrl = ({altdata}) => {
  let rs = altdata?.rs;
  return (
    <>
      <ul className="servesbox alturl">
        {getServicesArray(rs?.alternatUrlObj?.content).map((val) => {
          const url = val?.description?.startsWith("http")
            ? val?.description
            : `https://${val?.description}`;
          return (
            val?.name &&
            val?.description && (
              <li key={val?.name} className="service-list py-2 font-DM text-lg" style={{color: `${altdata?.dynamicTextHeadingColor}B3` || "#B8B8B8"}}>
                <a href={url} target="_blank" rel="noopener noreferrer" style={{color: `${altdata?.dynamicTextHeadingColor}B3` || "#B8B8B8"}}>
                  {val?.name}
                </a>
              </li>
            )
          );
        })}
      </ul>

    </>
  )
}

export default CabAlternateUrl

