import React from "react";
import logoWhite from "../../Images/logo-white.png";
import { Link } from "react-router-dom";
import "./FooterUpdatedStyle.css";
import XLogo from "../../Images/XLogo.png";
import "bootstrap/dist/css/bootstrap.min.css";

function Footer() {
  const smoothBehaviour = () => {
    // Scroll to the top of the page smoothly
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer>
      <div class="topfooter">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-4">
              <a href="">
                <img loading="lazy" src={logoWhite} class="img-fluid" style={{width:"156px"}}/>
              </a>
            </div>
            <div class="col-12 col-lg-8">
              <ul class="socilmedia">
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=61551454974033"
                    target="_blank"
                  >
                    <i class="fa-brands fa-facebook-f"></i>
                    <span>Facebook</span>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/_business_bay/" target="_blank">
                    <i class="fa-brands fa-instagram"></i>
                    <span>Instagram</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/business-bay/?viewAsMember=true"
                    target="_blank"
                  >
                    <i class="fa-brands fa-linkedin"></i>
                    <span>Linkedin</span>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/businessbay__" target="_blank">
                    <div
                      style={{
                        border: "1px solid #fff",
                        borderRadius: "4px",
                        width: "25px",
                        height: "25px",
                        lineHeight: "25px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign:"center"
                      }}
                    >
                      <img loading="lazy" src={XLogo} alt="X Logo" />
                    </div>
                    {/* <i class="fa-brands fa-x-twitter"></i> */}
                    {/* <i class="fa-brands fa-square-x-twitter"></i> */}
                    {/* <FontAwesomeIcon icon="fa-brands fa-x-twitter" /> */}
                    <span>Twitter</span>
                  </a>
                </li>
                <li>
                  <a href="https://in.pinterest.com/businessbay_/" target="_blank">
                    <i class="fa-brands fa-pinterest"></i>
                    <span>Pinterest</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="main-footer">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-5">
              <div class="footer-bigarea">
                <h3>
                  Get Bulk <br />
                  <span>Orders</span>
                </h3>
                <Link to="/register">Get Started</Link>
              </div>
            </div>
            <div class="col-12 col-lg-7">
              <div class="row">
                <div class="col-12 col-lg-4">
                  <div class="f-link accordion-list">
                    <h3>Useful Links</h3>

                    <ul class="usefullink_listyle">
                      {/* <li>
                <Link to="/" onClick={smoothBehaviour}>Overview</Link> 
                </li>
                <li>
                <Link to="/about" onClick={smoothBehaviour}> About Us</Link> 
                </li> */}
                      <li>
                        <Link to="/login"> Login</Link>
                      </li>
                      <li>
                        <Link to="/register" onClick={smoothBehaviour}>
                          Register
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="f-link accordion-list">
                    <h3> Links</h3>
                    <ul class="usefullink_listyle">
                      <li>
                        {" "}
                        <Link to="/faqs" onClick={smoothBehaviour}>
                          {" "}
                          FAQs
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/shipping" onClick={smoothBehaviour}>
                          {" "}
                          Shipping
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/privacy" onClick={smoothBehaviour}>
                          {" "}
                          Privacy
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="refund" onClick={smoothBehaviour}>
                          {" "}
                          Cancellation & Refund
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/term" onClick={smoothBehaviour}>
                          {" "}
                          T&Cs
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="f-link accordion-list ">
                    <h3>Contact Info </h3>
                    <ul>
                      <li>
                        <div class="infofooter">
                          <i class="fa-solid fa-location-dot"></i>
                          <div class="info">
                            Metaspace Labs Information Technology L.L.C <br />
                            UAE- Dubai - Al meydan road - KML building - office
                            121
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="infofooter">
                          <i class="fa-solid fa-location-dot"></i>
                          <div class="info">
                            Metaspace Technology Pvt. Ltd. <br />
                             B-2, Block B, Sector 4, Noida, Uttar Pradesh 201301
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="infofooter">
                          <i class="fa-solid fa-envelope"></i>
                          <a
                            href="mailto:info@businessbay.io"
                            class="info"
                            style={{ textDecoration: "none"}}
                          >
                            {" "}
                            info@businessbay.io{" "}
                          </a>
                        </div>
                      </li>
                      <li>
                        <div class="infofooter">
                          <i class="fa-solid fa-phone"></i>
                          <a
                            href="tel:+919355610532"
                            class="info"
                            style={{ textDecoration: "none" }}
                          >
                           +971-565954252
                          </a>
                        </div>
                      </li>

                      <li>
                        <div class="infofooter">
                          <i class="fa-solid fa-phone"></i>
                          <a
                            href="tel:+919355610532"
                            class="info"
                            style={{ textDecoration: "none" , paddingBottom: "15px"}}
                          >
                            +91-9818867316
                          </a>
                        </div>
                      </li>
                  
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="copyright_old">
        <div class="container">
          <div class="row " style={{display:"flex",justifyContent:"center", alignItems:"center"}}>
            <div class="col-12 col-lg-6">
              <p class="copyy text-center">
                <a href="" style={{ textDecoration: "none" }}>
                  Copyright © Businessbay | All Rights Reserved{" "}
                  {new Date().getFullYear()}
                </a>
                
              </p>
            </div>
            <div class="col-12 col-lg-6 " style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
     <h5
          onClick={() => window.open(`https://metaspacelab.io/`, "_blank")}
          style={{
            cursor: "pointer",
            // fontSize: "12px",
            // position: "absolute",
            // right: "40px",
            // top: "24px",
            color: "#fff",

          }}
          className="poweredbytextold belowpowerdbyclass"
        >
          Powered by : Metaspace Labs Information Technology L.L.C / Metaspace Technologies Pvt Ltd
        </h5>
            </div>
          </div>
        </div>
      
      </div>
    </footer>
  );
}

export default Footer;
