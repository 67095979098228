import React from "react";
import styled from "styled-components";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {useTranslation} from "react-i18next";
import { OrderDetail, OrderStatuskey, OrderreviewKeyValueWrapper, 
  OrderreviewValue, PlaceOrder, PlaceOrderWrapper, TabOrderReviewScreen, 
  TaborderDetailWrapper,DrawerCloseButtonWrapper,DrawerCloseButton } from "./Theme14ExtStyleComponent";


const Orderreview = ({ formData,createOrder,closeOrderReview }) => {
  const { t } = useTranslation();
const formatDate = (date) => {
  const options = { month: "short", day: "numeric", year: "numeric" };
  return new Date(date).toLocaleDateString(undefined, options);
};

// Get today's date in the desired format
const today = formatDate(new Date());

const handlePlaceOrderClick = () => {
  createOrder();
  closeOrderReview();
};


  return (
    <TabOrderReviewScreen>
         <TaborderDetailWrapper>
           <OrderDetail>
           {t("Order_review")}
           </OrderDetail>
          </TaborderDetailWrapper>
  
          <OrderreviewKeyValueWrapper>
           <OrderStatuskey>{t("Name")}</OrderStatuskey>
           <OrderreviewValue>{formData?.name}</OrderreviewValue>
         </OrderreviewKeyValueWrapper>
         <OrderreviewKeyValueWrapper>
           <OrderStatuskey> {t("Phone")}</OrderStatuskey>
           <OrderreviewValue>{formData?.phone} </OrderreviewValue>
         </OrderreviewKeyValueWrapper>
         <OrderreviewKeyValueWrapper>
           <OrderStatuskey>{t("Delivery_address")}  </OrderStatuskey>
           <OrderreviewValue> {formData?.city} {formData?.state} </OrderreviewValue>
         </OrderreviewKeyValueWrapper>
         {/* <OrderreviewKeyValueWrapper>
           <OrderStatuskey>Order ID</OrderStatuskey>
           <OrderreviewValue>#FM209391 </OrderreviewValue>
         </OrderreviewKeyValueWrapper> */}
         <OrderreviewKeyValueWrapper>
           <OrderStatuskey> {t("Order_date")}</OrderStatuskey>
           <OrderreviewValue>{today} </OrderreviewValue>
         </OrderreviewKeyValueWrapper>
         <OrderreviewKeyValueWrapper>
           <OrderStatuskey>{t("Payment_method")}</OrderStatuskey>
           <OrderreviewValue>{t("COD")} </OrderreviewValue>
         </OrderreviewKeyValueWrapper>
         <PlaceOrderWrapper>
           <button onClick={handlePlaceOrderClick}
           style={{background: "transparent", border: "none",fontWeight:"700",color:"#fff",fontSize:"24px"}}>
            {t("Place_order")}
           </button>
         </PlaceOrderWrapper>
         <DrawerCloseButtonWrapper onClick={closeOrderReview}>
          <DrawerCloseButton>X</DrawerCloseButton>
        </DrawerCloseButtonWrapper>
       </TabOrderReviewScreen>
  );
};
export default Orderreview;
