import React, { useContext, useState, useEffect, useRef } from "react";
import { environmentVariables } from "../../../config/env.config";
import testimonialdefault from "../../../Images/testimonialdefault.png";
import avatarImage from "../../../Images/DishReview.png";
import "./../Updatedcss/BusinessChangeBlockUpdatedStyle.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { currencyContext } from "../../../context/currencyContext.js";
import { useTranslation } from "react-i18next";

const FormikErrorStyle = styled.div`
font-size:11px;
color: #ff3333;
text-align: justify;
    padding-bottom: 10px;
`;


const restaurantForm = ({
  formCount,
  restaurantData,
  setIsAddTestimonialDisable,
  setRestaurantData,
  index,
  removeForm,
}) => {
  const fileInputRef = useRef();
  const openFileInput = () => {
    fileInputRef.current.click();
  };
  const baseUrl = environmentVariables?.apiUrl;
  const [image, setImage] = useState(null);
  const [addBtnDisable, setAddBtnDisable] = useState(false);
  const { currencyObjData } = useContext(currencyContext);
  const [priceArray, setPriceArray] = useState([])
  const [quantityArray, setQuantityArray] = useState([])
  const [newData, setnewData] = useState({
    price: '',
    quantity: ''
  })
  const { t } = useTranslation();
  const [data, setData] = useState({
    formCount: formCount,
    image: null,
    name: "",
    price: [],
    quantity: [],
    category: "",
    imageView: null,
    isImageUpload: true,
  });



  const addPriceQuantity = () => {
    if (newData.price && newData.quantity) {
      const updatePrice = priceArray;
      const updateQuantity = quantityArray;
      updatePrice.push(newData.price)
      updateQuantity.push(newData.quantity)
      setPriceArray(updatePrice)
      setQuantityArray(updateQuantity)
      setnewData({ price: "", quantity: "" });
    };
  }

  const removePriceQuantity = (index) => {
    if (priceArray.length > 1 && quantityArray.length) {
      setPriceArray(priceArray.filter((_, i) => i !== index));
      setQuantityArray(quantityArray.filter((_, i) => i !== index));
    }
  };



  const getImage = (data) => {
    if (data["imageView"]) {
      return data["imageView"];
    } else {
      if (data["image"]) {
        if (data["image"] != "null") {
          return `${environmentVariables?.s3UrlBackendUrl}${data["image"]}`;
        } else {
          return testimonialdefault;
        }
      } else {
        return avatarImage;
      }
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      // .matches(/^[a-zA-Z0-9 ,.?\/()&@$'_\-\\"]*$/, "Only ‘,.?-_/@$&’ characters are allowed")
      .matches(/^[\u0600-\u06FFa-zA-Z0-9 ,.?\/()&@$'_\-\\"]*$/, `${t("Only")} ‘,.?-_/@$&’ ${t("characters_are_allowed")}`)
      .required(t("Name_required"))
      .min(2, t("Minimum_2_characters_required"))
      .max(45, t("Maximum_45_characters")),
    description: Yup.string()
      .min(10, t("Minimum_10_characters"))
      .max(400, t("Maximum_400_characters")),
    category: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: data.name || "",
      price: priceArray || "",
      quantity: quantityArray || '',
      category: data.category || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleAddService(values);
    },
  });


  // const handleAddService = (values) => {
  //   setAddBtnDisable(true);
  //   setIsAddTestimonialDisable(false);
  //   const newRestaurantData = restaurantData.map((val, index) => {
  //     if (val.formCount == formCount) {
  //       val.name = values?.name;
  //       val.price = values?.price;
  //       val.category = values?.category;
  //       val.image = image;
  //       val.imageView = data?.imageView;
  //       val.isImageUpload = data?.isImageUpload;
  //       return val;
  //     }
  //     return val;
  //   });

  //   setRestaurantData(newRestaurantData);
  // };


  const handleAddService = (values) => {
    setAddBtnDisable(true);
    setIsAddTestimonialDisable(false);

    const newRestaurantData = restaurantData.map((val, index) => {
      if (val.formCount == formCount) {
        return {
          ...val,
          name: values?.name,
          category: values?.category,
          image: image,
          imageView: data?.imageView,
          isImageUpload: data?.isImageUpload,
          price: priceArray,
          quantity: quantityArray
        };
      }
      return val;
    });

    setRestaurantData(newRestaurantData);
  };



  const handleChangeImage = (e) => {
    setImage(e.target.files);
    if (e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setData({ ...data, imageView: reader.result });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  useEffect(() => {
    let currentForm = restaurantData[index];
    console.log("Current Form Data on UseEffect:", currentForm);
    setData({
      name: currentForm?.name,
      price: currentForm?.price,
      quantity: currentForm?.quantity,
      category: currentForm?.category,
      image: currentForm?.image,
      imageView: currentForm?.imageView,
      formCount: index,
      isImageUpload: currentForm?.isImageUpload,

    });

  }, [restaurantData]);

  return (
    <div
      key={`form-${formCount}`}
      className="col-12"
      id="AddNewStyleFormfieldpad"
    >
      <div className="serviceboxedittesti MobileviewServices">
        <i
          className="fa-solid fa-circle-xmark crossedit"
          onClick={() => removeForm(index)}
        >
          {" "}
        </i>

        <div className="boxbanner">
          <label className="imgrec" style={{ fontSize: "12px" }}>{t("Restaurant_product_size")}</label>
          <div className="bannerboxedittesti">

            <img
              style={{
                cursor:
                  data["isImageUpload"] && !addBtnDisable
                    ? "pointer"
                    : "inherit",
              }}
              onClick={() => {
                data["isImageUpload"] && !addBtnDisable
                  ? openFileInput()
                  : null;
              }}
              src={getImage(data)}
              className="mx-auto d-block img-fluid"
            />
            <i
              style={{
                display:
                  data["isImageUpload"] && !addBtnDisable ? "block" : "none",
              }}
              onClick={() => {
                data["isImageUpload"] && !addBtnDisable
                  ? openFileInput()
                  : null;
              }}
              id="uploadicon"
              className="fa fa-solid fa-upload"
            ></i>
          </div>
          {data["isImageUpload"] && !addBtnDisable && (
            <input
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={(e) => {
                handleChangeImage(e);
              }}
              className="form-control"
              type="file"
              placeholder={`${t("Title")}`}
              accept=".png, .jpg, .jpeg"
            />
          )}
        </div>
        <div className="AddDetailsTextimonialphnview">
          <div className="" style={{ fontSize: "16px", fontWeight: "500" }}>
            {data["isImageUpload"] && !addBtnDisable ? (
              <div>
                <input
                  {...formik.getFieldProps("name")}
                  className="form-control"
                  type="text"
                  placeholder={`${t("Dish_name")}`}
                />
                {formik.touched.name && formik.errors.name ? (
                  <FormikErrorStyle>{formik.errors.name}</FormikErrorStyle>
                ) : null}
              </div>
            ) : (
              <div>{data["name"] == "undefined" ? "" : data["name"]}</div>
            )}
          </div>

          {/* <div className="" style={{ fontSize: "16px", fontWeight: "500" }}>
            {data["isImageUpload"] && !addBtnDisable ? (
              <div>
               <div style={{display:'flex'}}> <input
                  {...formik.getFieldProps("price")}
                  className="form-control"
                  type="number"
                  placeholder={`${t("Price_in")} ${currencyObjData?.name || 'INR'} `}
                />

                <input type="text"  className="form-control"  placeholder={`quantity`}/>

                <button
                // disabled={!isAddShow || inputValText.trim() === ''}
                class="btn"
                onClick={onAddText}
              >
                +
              </button>
                
                
                </div>
                {formik.touched.price && formik.errors.price ? (
                  <FormikErrorStyle>{formik.errors.price}</FormikErrorStyle>
                ) : null}
              </div>
            ) : (
              <div>{data["price"] == "undefined" ? "" : data["price"]}</div>
            )}
          </div>  */}

          <div key={index} style={{ display: "flex", gap: "10px", marginBottom: "10px",justifyContent:"center" }}>
            {data["isImageUpload"] && !addBtnDisable ? (

              <div style={{ display: "flex", gap: '8px', alignItems:'center' }}> <input
                type="number"
                style={{padding:'18px 12px'}}
                // {...formik.getFieldProps("price")}
                className="form-control"
                name='price'
                placeholder={`${t("Price_in")} ${currencyObjData?.name || "INR"}`}
                value={newData.price}
                onChange={(e) => {
                  const value = e.target.value;
                  // Allow only numbers and limit to 8 digits
                  if (/^\d{0,8}$/.test(value)) {
                    setnewData((prevData) => ({
                      ...prevData,
                      price: value ? parseFloat(value) : ""
                    }));
                  }
                }}
              />

                <input
                style={{padding:'18px 12px'}}
                  type="text"
                  className="form-control"
                  name='quantity'
                  placeholder="Quantity"
                  value={newData.quantity}
                  onChange={(e) =>
                    setnewData((prevData) => ({
                      ...prevData,
                      quantity: e.target.value
                    }))
                  }
                />


                <div className="btn btn-cu" style={{height: '35px',
    color: '#fff',
    background: '#145388'}} onClick={addPriceQuantity}>+</div>
              </div>

            ) : (
              <div>{data.price == "undefined" ? "" : data.price[0]}</div>
            )}
          </div>


          {(priceArray.length > 0 && quantityArray.length > 0) && (
            <ul>
              {priceArray.map((item, index) => (
                <div key={index} className="crosstext">
                  {item && (
                    <i
                      class="fa-solid fa-circle-xmark"
                      onClick={() => removePriceQuantity(index)}
                    ></i>
                  )}
                  <li>{item} - {quantityArray[index]}</li>
                </div>
              ))}
            </ul>
          )}




          {data["isImageUpload"] && !addBtnDisable ? (
            <div className="form-group" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <label style={{ display: 'flex', alignItems: 'center', gap: '5px', justifyContent: 'center' }}>
                <input
                  type="radio"
                  name="category"
                  value="veg"
                  checked={formik.values.category === "veg"}
                  onChange={formik.handleChange}
                />
                {t("Veg")}
              </label>
              <label style={{ display: 'flex', alignItems: 'center', gap: '5px', justifyContent: 'center' }}>
                <input
                  type="radio"
                  name="category"
                  value="nonveg"
                  checked={formik.values.category === "nonveg"}
                  onChange={formik.handleChange}
                />
                {t("Non_veg")}
              </label>
              {formik.touched.category && formik.errors.category ? (
                <FormikErrorStyle>{formik.errors.category}</FormikErrorStyle>
              ) : null}
            </div>
          ) : (
            <div>{data["category"]}</div>
          )}

          {data["isImageUpload"] && !addBtnDisable ? (
            <button
              type="button"
              disabled={!formik.isValid || !formik.dirty || !formik.values.name ||
                !formik.values.price || !formik.values.category || !image ||
                addBtnDisable || priceArray.length === 0 ||
                quantityArray.length === 0
              }
              onClick={() => {
                formik.handleSubmit();
                handleAddService({
                  name: data.name,
                  price: priceArray,
                  quantity: quantityArray,
                  category: data.category,
                  image: image,
                  imageView: data.imageView,
                  isImageUpload: data.isImageUpload,
                });
              }}
              className="btn btn-cu editbutton editButtonTextimonial"
            >
              {t("Add")}
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default restaurantForm;