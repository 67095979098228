import React, { useEffect, useState } from "react";
import { environmentVariables } from "../../../config/env.config";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import axios from "axios";
import { useTranslation } from "react-i18next";

const FormikErrorStyle = styled.div`
  font-size: 11px;
  color: #ff3333;
  padding-bottom: 5px;
`;

const ServiceFormsaloon = ({
  formCount,
  servicesData,
  setIsAddServiceDisable,
  setServicesData,
  removeForm,
  index,
  businessObjData,
}) => {
  const [data, setData] = useState({
    formCount: formCount,
    banner: null,
    id: null,
    name: "",
    description: "",
    buttonLink: "",
    buttonName: "",
    bannerImage: null,
    isImageUpload: true,
  });

  const [tempFormCount, setTempFormCount] = useState(0);
  const [addBtnDisable, setAddBtnDisable] = useState(false);
  const [image, setImage] = useState(null);
  const [isShowAdd, setIsShowAdd] = useState(true);
  const [dynamicMarketplaceCategories, setDynamicMarketplaceCategories] =
    useState([]);
    const { t } = useTranslation();
  const validationSchema = Yup.object({
    // name: Yup.string().matches(/^[a-zA-Z]+([a-zA-Z' -]*[a-zA-Z])*$/, 'Only alphabets are allowed').min(2, 'Minimum 2 characters'),
    name: Yup.string()
      .matches(
        /^[a-zA-Z0-9&]+([a-zA-Z0-9&' -]*[a-zA-Z0-9&])*$/,
        t("Only_alphabets_Character_are_allowed")
      )
      .min(1, t("Minimum_1_characters"))
      .test("unique", t("Service_already_exists") , (value) => {
        return !servicesData.some(
          (service) => service.name?.toLowerCase() === value?.toLowerCase()
        );
      }),
      description: Yup.string()
      .required(t("Category_is_required")),
  });

  const formik = useFormik({
    initialValues: {
      name: data.name || "",
      description: data.description || "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleAddService(formik.values.name,formik.values.description);
    },
  });

  const getMarketplaceCategories = () => {
    let config = {
      method: "get",
      url: `${environmentVariables?.apiUrl}api/v1/business/allCategory`,
    };
    axios
      .request(config)
      .then((response) => {
        setDynamicMarketplaceCategories(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getMarketplaceCategories();
  }, []);


  useEffect(() => {
    let currentForm = servicesData[index];
    setTempFormCount(formCount);
    setData({
      formCount: currentForm?.formCount,
      banner: currentForm?.image,
      id: currentForm?.id,
      name: currentForm?.name,
      description: currentForm?.description,
      buttonLink: currentForm?.buttonLink,
      buttonName: currentForm?.buttonName,
      bannerImage: currentForm?.bannerImage,
      isImageUpload: currentForm?.isImageUpload,
    });

    if (currentForm?.name) {
      setAddBtnDisable(true);
    } else {
      setAddBtnDisable(false);
    }

    data["name"] ? setIsShowAdd(false) : setIsShowAdd(true);
  }, [servicesData]);

  useEffect(() => {
    setTempFormCount(data?.formCount);
  }, [data]);

  const handleAddService = (nameValue,descriptionValue) => {
    setAddBtnDisable(true);
    setIsAddServiceDisable(false);

    const filteredCategories = dynamicMarketplaceCategories.filter(
      (val) => val.id == nameValue
    );
    const newServicesData = [...servicesData];

    newServicesData.forEach((val) => {
      if (val.formCount === tempFormCount) {
        (val.id = nameValue), (val.banner = image);
        val.name =
          businessObjData?.theme_color != "theme20"
            ? nameValue
            : filteredCategories[0]?.category_Name;
        val.description = descriptionValue;
        val.buttonLink = data?.buttonLink;
        val.buttonName = data?.buttonName;
        val.bannerImage = data?.bannerImage;
        val.isImageUpload = data?.isImageUpload;
      }
    });
    console.log(newServicesData, "new ervices daata");
    setData(newServicesData);
    setServicesData(newServicesData);
  };

  return (
    <div
      key={`form-${formCount}`}
      className="col-12"
      id="AddNewStyleFormfieldpad"
    >
      
      
      <div className="serviceboxedit">
        <i
          class="fa-solid fa-circle-xmark crosseditservice crossIconAdddetails"
          onClick={() => removeForm(index)}
        ></i>
        <div>
        
          {data["isImageUpload"] ? (
            <div>
                <input
                  name="name"
                  disabled={addBtnDisable}
                  class="form-control"
                  style={{ padding: "14px 25px", marginBottom: "10px" }}
                  type="text"
                  placeholder="Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
               {formik.touched.name && formik.errors.name ? (
                <FormikErrorStyle>{formik.errors.name}</FormikErrorStyle>
              ) : null}

              <select
                name="description"
                disabled={addBtnDisable}
                className="form-control"
                style={{ padding: "14px 25px", marginBottom: "10px", cursor: "pointer" }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
              >
                <option value="">{t("Select_category")}</option>
                <option value="all">{t("Both")}</option>
                <option value="male">{t("Male")}</option>
                <option value="female">{t("Female")}</option>
              </select>
              {formik.touched.description && formik.errors.description ? (
                <FormikErrorStyle>
                  {formik.errors.description}
                </FormikErrorStyle>
              ) : null}
            </div>
          ) : (
            <div className="servicenameedit" id="ServicesEditformstyle">
              {data["name"] || ""}
              <div>
    {data["description"] && data["description"] !== "undefined" ? data["description"] : ""}
  </div>
            </div>
          )}
        </div>

        {isShowAdd && !addBtnDisable ? (
          <button
            type="button"
            disabled={!formik.isValid || !formik.dirty}
            onClick={() => {
              formik.handleSubmit();
              handleAddService(formik.values.name);
            }}
            className="btn btn-cu addbuttoneditform"
          >
            {t("Add")}
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ServiceFormsaloon;
