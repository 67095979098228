export const GetFormattedDate = (date) => {
 
    if (!date) 
        return "--";

    const newDate = new Date(date).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      return newDate
}
