import { useState } from "react";
import { environmentVariables } from "../../../config/env.config";

const lightboxBackground = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: "rgba(0, 0, 0, 0.8)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const lightboxContainer = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  maxWidth: "400px",
  minWidth: "340px",
  height: "100%",
  maxHeight: "350px",
  minHeight: "250px",
  overflow: "hidden",
  borderRadius: "10px",
};
const lightboxImage = {
  width: "100%",
  height:"100%",
  objectFit: "cover",
};
const crossIcon = {
  backgroundColor: "#ff441b",
  position: "absolute",
  top: "-20px",
  right: "8px",
  color: "white",
  fontSize: "20px",
  cursor: "pointer",
  padding: "5px 12px",
  borderRadius: "50%",
  transform: "translate(50%)",
  fontWeight: "900",
};

export const Lightbox = ({
  src,
  slides,
  currentIndex,
  onClose,
  onNext,
  onPrevious,
}) => {
  const [activeImage, setActiveImage] = useState(src);

  const handleThumbnailClick = (newSrc,index) => {
    setActiveImage(newSrc);
    onPrevious(index); 
  };

  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % slides.length;
    setActiveImage(
      `${environmentVariables?.s3UrlBackendUrl}gallery/${slides[nextIndex]?.image}`
    );
    onNext(nextIndex); 
  };

  const handlePrevious = () => {
    const prevIndex = (currentIndex - 1 + slides.length) % slides.length;
    setActiveImage(
      `${environmentVariables?.s3UrlBackendUrl}gallery/${slides[prevIndex]?.image}`
    );
    onPrevious(prevIndex); 
  };
  return (
    <div style={lightboxBackground}>
      <div
        style={{
          maxWidth: "500px",
          position: "relative",
          padding: "20px",
          backgroundColor: "#fff",
          borderRadius: "10px",
        }}
      >
        <div onClick={onClose} style={crossIcon}>
          ✕
        </div>
        <div style={lightboxContainer}>
          <div
            style={{
              position: "absolute",
              left: "40px",
              top: "50%",
              bottom:"50%",
              transform:"translate(50%, -50%)",
              left:"0",
              zIndex: 1001,
              cursor: "pointer",
              color: "#000",
              fontSize: "20px",
              background: "#FFFFFFCC",
              borderRadius: "50%",
              height: "40px",
              width: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handlePrevious}
          >
            <i class="fa-solid fa-chevron-left"></i>
          </div>
          <img
            src={activeImage}
            alt="Expanded View"
            style={lightboxImage}
            //   onClick={onClose}
          />
          <div
            style={{
              position: "absolute",
              right: "40px",
              top: "50%",
              bottom:"50%",
              transform:"translate(50%, -50%)",
              zIndex: 1001,
              cursor: "pointer",
              color: "#000",
              fontSize: "20px",
              background: "#FFFFFFCC",
              borderRadius: "50%",
              height: "40px",
              width: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleNext}
          >
            <i class="fa-solid fa-chevron-right"></i>
          </div>
        </div>
        {/* <div
          style={{
            display: "flex",
            overflow: "scroll",
            maxHeight: "120px",
            marginTop: "20px",
            gap: "20px",
          }}
        >
          {slides?.map((item, index) => {
            const thumbnailSrc = `${environmentVariables?.s3UrlBackendUrl}gallery/${item?.image}`;
            return (
              <div
                style={{maxWidth:"200px",  minWidth: "200px", overflow: "hidden", }}
                key={index}
                onClick={() => handleThumbnailClick(thumbnailSrc,index)}
              >
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "10px",
                    border: index == currentIndex ? "1.5px solid black" : "none",
                    cursor:"pointer"
                  }}
                  src={`${environmentVariables?.s3UrlBackendUrl}gallery/${item?.image}`}
                />
              </div>
            );
          })}
        </div> */}
      </div>
    </div>
  );
};
