import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { object, string, ref } from "yup";

// const { t } = useTranslation(); 
// export const shippingAddressValidation = yup.object().shape({
//   address_type: yup.string().required(t("Please_select_address_type")),
//   fullname: yup
//     .string()
//     .required("Full Name is required")
//     .min(3, "length should be greater then 3")
//     .max(45),
//   mobile1: yup
//     .number()
//     .test("is-valid-number", "Mobile number must be valid", (value) => {
//       if (!value) {
//         return true; // Skip validation if the value is empty
//       }
//       const mobileNumberRegex = /^\d{7,12}$/;
//       return mobileNumberRegex.test(value);
//     })
//     .required("Mobile Number is required"),
//   address1: yup.string().required("Address 1 is required"),
//   country: yup.string().min(4).required("Country is required"),
//   state: yup.string().min(2).required("State is required"),
//   city: yup.string().min(4).required("City is required"),
//   pincode: yup.number().min(1),
//   landmark: yup.string().min(4),
// });

export const shippingAddressValidation = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    address_type: yup.string().required(t("Please_select_address_type")),
    fullname: yup
      .string()
      .required(t("Full_name_required"))
      .min(3, t("Length_should_greater_then_3"))
      .max(45),
    mobile1: yup
      .number()
      .test("is-valid-number", t("Mobile_no_must_valid"), (value) => {
        if (!value) {
          return true; // Skip validation if the value is empty
        }
        const mobileNumberRegex = /^\d{7,12}$/;
        return mobileNumberRegex.test(value);
      })
      .required(t("Mobile_no_required")),
    address1: yup.string().required(t("Address_1_required")),
    country: yup.string().min(4).required(t("Country_required")),
    state: yup.string().min(2).required(t("State_required")),
    city: yup.string().min(4).required(t("City_required")),
    pincode: yup.number().min(1),
    landmark: yup.string().min(4),
  });
};
