import React, { useContext, useState, useRef } from "react";
// import "./TemplatComman.css";
import { userContext } from "../../../../context/userContext";
import { environmentVariables } from "../../../../config/env.config";
import { useEffect } from "react";
// import "./Template11.css";
import "./Theme8.css";
import axios from "axios";
// import "./Template11.css";

import theme6strip from "../../../../Images/theme6strip.png";
import TestimonialCarousel from "./TestimonialCarousel";
import GalleryCarousel from "./GalleryCarousel";
import { getServicesArray } from "../../../../utils/utility";
// import "./Theme1.css";

// import "./Template10.css";
const Theme8 = (props) => {
  const { userData } = useContext(userContext);
  const [selectedDate, setSelectedDate] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [appointmentData, setAppointmentData] = useState([]);
  const touchStartX = useRef(0);
  const handleChangeAppointmentDate = (dateVal) => {
    setSelectedDate(dateVal);

    const dateObject = new Date(dateVal);

    const dayIndex = dateObject.getDay();

    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    if (props?.appointmentArray && props?.appointmentArray?.length != 0) {
      setAppointmentData(
        props?.appointmentArray[`${daysOfWeek[dayIndex].toLowerCase()}`]
      );
    } else {
      // const parsedContent = JSON.parse(
      //   props?.businessObj?.appointmentObj?.content
      // );
      // setAppointmentData(
      //   parsedContent[`${daysOfWeek[dayIndex].toLowerCase()}`]
      // );
      try {
        const parsedContent = JSON.parse(
          JSON.parse(props?.businessObj?.appointmentObj?.content)
        );
        setAppointmentData(
          parsedContent[`${daysOfWeek[dayIndex].toLowerCase()}`]
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    const touchEndX = e.touches[0].clientX;
    const deltaX = touchEndX - touchStartX.current;

    if (Math.abs(deltaX) > 50) {
      // You can adjust this threshold to control how much swipe is needed to trigger the slide
      setCurrentIndex((prev) =>
        deltaX > 0
          ? Math.max(prev - 1, 0)
          : Math.min(prev + 1, testimonials.length - 1)
      );
    }
  };

  const handleTouchEnd = () => {
    touchStartX.current = 0;
  };
  // console.log(props.orderOfBlocks, "appointment inside iframe");

  useEffect(() => {
    getBlockOrder();
  }, [props?.businessObj, props.businessId, props.orderOfBlocks]);
  const getBrandNameForSocial = (brand) => {
    // console.log(brand);
    if (brand == "gmail") {
      return "google";
    } else return `${brand}`;
  };
  const getBlockOrder = async () => {
    // const handleGetBlock = async () => {
    if (props.orderOfBlocks) {
      let orders = {};
      props.orderOfBlocks.map((val) => {
        if (val.content == "Description") {
          orders["description"] = val?.position;
        } else if (val.content == "Contact Info") {
          orders["contact_info"] = val?.position;
        } else if (val.content == "Appointment") {
          orders["appointment"] = val?.position;
        } else if (val.content == "Service") {
          orders["service"] = val?.position;
        } else if (val.content == "Gallery") {
          orders["gallery"] = val.position;
        } else if (val.content == "Contact Form") {
          orders["contact_form"] = val.position;
        } else if (val.content == "Testimonials") {
          orders["testimonials"] = val.position;
        } else if (val.content == "Social") {
          orders["social"] = val.position;
        } else if (val.content == "Business Hour") {
          orders["business_hour"] = val.position;
        } else if (val.content == "Google Review") {
          orders["google_review"] = val.position;
        }
      });
      props.setOrder(orders);
    } else {
      try {
        let id = props?.businessObj?.id || props.businessId;
        if (props?.businessObj?.id) {
          let { data: get } = await axios.get(
            `${environmentVariables?.apiUrl}api/v1/business/FetchBlock?business_id=${id}`,
            {
              headers: {
                _token: userData?.token,
              },
              // withCredentials: true,
            }
          );
          // console.log(get?.data?.order, "order data");
          props.setOrder(get?.data?.order);
        }

        // console.log(get.data.order,"Getetgwef")
      } catch (err) {
        console.log(err, "error in template");
      }
    }
    // };
  };
  const getJSXElement = (elemName) => {
    // console.log(props?.appointmentArray, "elemNameelemName");
    if (elemName == "appointment") {
      return (
        props?.onState?.appointment && (
          // <article class="contact sec colorback">
          //   <h3 class="sectitle">Appoinment</h3>
          //   <select class="form-select" aria-label="Default select example">
          //     <option selected>Select time</option>
          //     {props?.appointmentArray?.map((val) => (
          //       <>
          //         <option value="1">
          //           {props.changeTimeFormat(val?.startDate)} -{" "}
          //           {props.changeTimeFormat(val?.endDate)}{" "}
          //         </option>
          //       </>
          //     ))}
          //   </select>
          // </article>
          <article className="makeappitmneets sec colorback">
            <h3 class="sectitle colorheading">Make Appointment</h3>
            <div className="contact-form">
              <div class="col-12 mb-3">
                <input
                  type="date"
                  class="form-control clear_string colorctaborder"
                  placeholder="Date"
                  name="currentDate"
                  value={selectedDate}
                  onChange={(e) => handleChangeAppointmentDate(e.target.value)}
                />
              </div>
              <div class="col-12 mb-3">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  // onChange={(e) => setSelectedTime(e.target.value)}
                >
                  <option value="Select time">Select time</option>
                  {appointmentData?.map((val) => (
                    <option
                      key={val?.startDate}
                      value={`${val?.startDate} - ${val?.endDate}`}
                    >
                      {`${val?.startDate} - ${val?.endDate}`}
                    </option>
                  ))}
                </select>
              </div>
              <div class="col-12 mb-3">
                <button className="btn btnmakeappitmnets colorcta">
                  Make An Appointment
                </button>
              </div>
            </div>
          </article>
        )
      );
    } else if (elemName == "business_hour") {
      return (
        props?.onState?.businessHours && (
          <article className="businessHours_sec">
            <h3 class="sectitle">Working Hours</h3>

            <ul class="timelist">
              {props?.businessHourObject?.isMonday &&
                props?.businessHourObject?.modayStartDate &&
                props?.businessHourObject?.modayEndDate && (
                  <li>
                    <div className="day colordescription">Monday</div>
                    <div className="time">
                      {" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.modayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.modayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              {props?.businessHourObject?.isTuesday &&
                props?.businessHourObject?.tuesdayStartDate &&
                props?.businessHourObject?.tuesdayEndDate && (
                  <li>
                    <div className="day colordescription">Tuesday</div>
                    <div className="time">
                      {" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.tuesdayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.tuesdayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              {props?.businessHourObject?.isWednesDay &&
                props?.businessHourObject?.wednesdayStartDate &&
                props?.businessHourObject?.wednesdayEndDate && (
                  <li>
                    <div className="day colordescription">Wednesday</div>
                    <div className="time">
                      {" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.wednesdayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.wednesdayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              {props?.businessHourObject?.isthrusdayDay &&
                props?.businessHourObject?.thrusdayStartDate &&
                props?.businessHourObject?.thrusdayEndDate && (
                  <li>
                    <div className="day colordescription">Thursday</div>
                    <div className="time">
                      {" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.thrusdayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.thrusdayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              {props?.businessHourObject?.isfridayDay &&
                props?.businessHourObject?.fridayStartDate &&
                props?.businessHourObject?.fridayEndDate && (
                  <li>
                    <div className="day colordescription">Friday</div>
                    <div className="time">
                      {" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.fridayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.fridayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              {props?.businessHourObject?.issaturdayDay &&
                props?.businessHourObject?.SaturdayStartDate &&
                props?.businessHourObject?.SaturdayEndDate && (
                  <li>
                    <div className="day colordescription">Saturday</div>
                    <div className="time">
                      {" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.SaturdayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.SaturdayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              {props?.businessHourObject?.issundayDay &&
                props?.businessHourObject?.SundayStartDate &&
                props?.businessHourObject?.SundayEndDate && (
                  <li>
                    <div className="day colordescription">Sunday</div>
                    <div className="time">
                      {" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.SundayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.SundayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
            </ul>
          </article>
        )
      );
    } else if (elemName == "contact_info") {
      return (
        props?.onState?.contactInfo && (
          <article class="contact sec colorback">
            <h3 class="sectitle colorheading">Contact </h3>
            <ul class="conlink">
              <li>
                <a className="infomain">
                  <i class="fa-solid fa-phone info_icons"></i>
                  <span className="colordescription">{props?.contactInfo?.contactInfoPhone}</span>
                  {props?.contactInfo?.optionalContactInfoPhone?.split("-")?.[1]?.length > 0 &&    <span className="colordescription"> , { props?.contactInfo?.optionalContactInfoPhone} </span>} 
                </a>
              </li>

              <li>
                <a className="infomain">
                  <i class="fa-solid fa-envelope info_icons"></i>
                  <span className="colordescription">{props?.contactInfo?.contactInfoEmail}</span>
                </a>
              </li>
              {/* <li>
                <a href="">
                <i class="fa-solid fa-envelope colordescription"></i>
                  <span className="colordescription">
                   
                    {props?.contactInfo?.contactInfoWebUrl ||
                      `www.Shreyassinghal.com`}
                  </span>
                </a>
              </li> */}
               {props?.contactInfo?.contactInfoWebUrl && 
               <li>
                <a className="infomain">
                <i class="fa-solid fa-link colordescription"></i>
                  <span style={{marginLeft:"5px", wordBreak:"break-all"}}>{props?.contactInfo?.contactInfoWebUrl}</span>
                </a>
              </li>
    }
     {props?.contactInfo?.contactInfoAddress && 
              <li>
                <a className="infomain">
                  <i class="fa-solid fa-map info_icons"></i>
                  <span className="colordescription">{props?.contactInfo?.contactInfoAddress}</span>
                </a>
                {props?.contactInfo?.contactInfoOptAddress && 
                <a className="infomain">
                <i
                    class="fa-solid fa-location-dot"
                  ></i>
                  <span className="colordescription">{props?.contactInfo?.contactInfoOptAddress}</span>
                  </a>
                  }
              </li>
    }
            </ul>
          </article>
        )
      );
    } else if (elemName == "custom_html") {
      return <></>;
    } else if (elemName == "google_review") {
      return (
         props?.onState?.googlereview == true &&  (
          <article class="contact sec colorback">
            <h3 class="sectitle colorheading">Google review</h3>
            <ul class="conlink">
              <li>
                 <p className="colordescription" style={{wordBreak:"break-all", maxHeight:"77px",overflow:"hidden", fontSize:"16px"}}>
                  {props?.googlereview !== undefined && props?.googlereview}
                  </p>
              </li>
            </ul>
          </article>
        )
      );
    }
     else if (elemName == "contact_form") {
      return (
        props?.onState?.contactForm && (
          <article className="Conatctform_sec">
            <h3 class="Contact_Form_sectitle">Contact </h3>
            <div class="contact-form">
              <form>
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control clear_string colorctaborder"
                    placeholder="Full Name"
                    name="last_name"
                    value=""
                    required=""
                  />
                </div>
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control colorctaborder"
                    placeholder="Email"
                    name="email"
                    value=""
                    required=""
                  />
                </div>
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control clear_string colorctaborder"
                    maxlength="10"
                    minlength="10"
                    placeholder="Mobile Number"
                    name="mobile_number"
                    value=""
                    required=""
                  />
                </div>
                <div class="col-12 mb-3">
                  <textarea
                    class="form-control clear_string colorctaborder"
                    rows="3"
                    placeholder="Message*"
                    required=""
                    name="message"
                  ></textarea>
                </div>

                <div class="col-12 mb-3">
                  <button type="button" class="btn btn-submitform colordescription colorcta">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </article>
        )
      );
    } else if (elemName == "description") {
      return (
        <article className="abouttemplate sec">
          <h3 class="sectitle colorheading">About me</h3>
          <p class="aboutus_paragraph">
            {props.description ||
              `We offer premium salon services, including hair styling, skincare, and beauty treatments. Our expert stylists and beauty professionals deliver a relaxing experience tailored to each client’s unique style and needs.`}
          </p>
        </article>
      );
    } else if (elemName == "gallery") {
      return (
        props?.onState?.gallery && (
          <article class="gallery sec sec-common colormainback">
            <h3 class="sectitle colorheading">Portfolio </h3>
            {props?.galleryData && (
              <>
                <GalleryCarousel slides={props?.galleryData} />
              </>
            )}
            {/* {props.existingGalleryImages ? (
              <GalleryCarousel slides={props?.existingGalleryImages} />
            ) : (
              props?.gallaryObject?.gallaryArray && (
                <GalleryCarousel slides={props?.gallaryObject?.gallaryArray} />
              )
            )} */}
            {/* {props.galleryView && (
              <GalleryCarousel slides={props?.galleryView} staticImage={true} />
            )} */}
            {/* {props?.gallaryObject?.gallary_video && (
              <video className="mx-auto d-block img-fluid" controls>
                <source
                  src={
                    props?.gallaryObject?.gallary_video &&
                    `${environmentVariables?.apiUrl}uploads/${props?.gallaryObject?.gallary_video}`
                  }
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            )} */}
          </article>
        )
      );
    } else if (elemName == "more") {
      return <></>;
    } else if (elemName == "service") {
      return (
        props?.onState?.services && (
          <article className="servicesName sec" class="Service_name">
            <h3 class="sectitle colorheading">Services </h3>
            <div className="mainservicebox">
              {/* <ul className="servesbox">
                {props?.servicesArray?.map((val) => (
                  <li> {val?.name}</li>
                ))}
              </ul> */}
              {props?.servicesArray && (
                <ul className="servesbox">
                  {getServicesArray(props?.servicesArray).map(
                    (val) =>
                      val?.name != null && <li className="colordescription" key={val?.name}> {val?.name}</li>
                  )}
                </ul>
              )}
            </div>
          </article>
        )
      );
    } else if (elemName == "social") {
      return (
        props?.onState?.socials && (
          <article className="social-links-sec sec colorback">
            <h3 class="sectitle">Social Media</h3>
            <ul class="socil-link">
              {/* {props?.socialMediaData?.map((val) => (
                <li class="social_media_li">
                  <a href={val?.link}>
                    <i
                      class={`fa-brands fa-${getBrandNameForSocial(
                        val?.nameOfSocial.toLowerCase()
                      )}`}
                    ></i>
                  </a>
                </li>
              ))} */}
              {props?.socialMediaData?.map(
                (val) =>
                  val.link &&
                  getBrandNameForSocial(val.nameOfSocial.toLowerCase()) && (
                    <li className="social_media_li" key={val.link}>
                      <a>
                        {/* <i
                          className={`fa-brands fa-${getBrandNameForSocial(
                            val.nameOfSocial.toLowerCase()
                          )}`}
                        ></i> */}
                         <i
                      className={`fa-${val?.nameOfSocial.toLowerCase() === 'star' ? 'solid' : 'brands'} fa-${getBrandNameForSocial(
                        val?.nameOfSocial.toLowerCase()
                      )}`}
                    ></i>
                      </a>
                    </li>
                  )
              )}
            </ul>
          </article>
        )
      );
    } else if (elemName == "testimonials") {
      return (
        props?.onState?.testimonials && (
          <article class="testimoniyalbox sec colormainback">
            <h3 class="sectitle testimonial">Testimonial </h3>
            <div>
              <TestimonialCarousel
                testimonials={props?.testimonialArray}
                currentElem={currentIndex}
                handleTouchStart={handleTouchStart}
                handleTouchMove={handleTouchMove}
                handleTouchEnd={handleTouchEnd}
              />
            </div>
          </article>
        )
      );
    }else if (elemName == "alternateUrls") {
          return (
           props?.onState?.alternateurl && (
            <>
            <article className="servicesName sec colormainback">
              <h3 className="sectitle service colorheading">Useful Links</h3>
              <div className="mainservicebox">
                {props?.alternateUrlArray && (
                  <ul className="servesbox">
                    {getServicesArray(props?.alternateUrlArray).map((val) => {
                      const url = val?.description?.startsWith("http")
                        ? val?.description
                        : `https://${val?.description}`;
                      return (
                        val?.name &&
                        val?.description && (
                          <li key={val?.name}>
                            <a href={url} target="_blank" rel="noopener noreferrer">
                              {val?.name}
                            </a>
                          </li>
                        )
                      );
                    })}
                  </ul>
                )}
              </div>
            </article>
            </>
           )
          )
        }
  };

  const getBlocksOrder = () => {
    let BlocksArray = [];
    for (const key in props.order) {
      BlocksArray[props.order[key]] = getJSXElement(key);
    }
    return BlocksArray;
  };

  return (
    <div>
      <div class="main-temaplt" id="temp8">
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ width: "420px" }} className="col-12 wtemplate colormainback">
              {/* <div class="circle"></div>
              <div class="circle2"></div> */}
              {/* <article className="imgsechere sec">
                <img
                  src={
                    props?.bannerImageUrl
                      ? props.getImage(props.bannerImageUrl)
                      : null
                  }
                  className="mx-auto d-block img-fluid coverimghere "
                />
                <img
                  src={
                    props?.logoImageUrl
                      ? props.getImage(props?.logoImageUrl)
                      : null
                  }
                  className="mx-auto d-block img-fluid logo-profile"
                />
              </article>

              <article className="personalinfo sec">
                <h3 className="username">{props.title || "Name"}</h3>
                <p className="titleuser">
                  {props.designation || "Designation"}
                </p>
                <p className="companeyname">
                  {props.subTitle || "Company Name"}
                </p>
              </article> */}
              <article className="imgsechere sec">
                <img
                  src={
                    props?.bannerImageUrl
                      ? props.getImage(props.bannerImageUrl)
                      : environmentVariables?.s3BannerUrlTheme8
                  }
                  className="mx-auto d-block img-fluid coverimghere "
                />
              </article>
              <article class="personalinfo sec colormainback">
                <div className="logo-parent">
                  <img
                    src={
                      props?.logoImageUrl
                        ? props.getImage(props?.logoImageUrl)
                        : environmentVariables?.s3UrllogoUrlTheme8
                    }
                    className="mx-auto d-block img-fluid logo-profile"
                  />
                </div>
                <div class="innerinfo">
                  <h3 className="username">{props.subTitle || "Name"}</h3>
                  <p className="titleuser">
                    {props.designation || "Designation"}
                  </p>
                </div>
              </article>
              {/* <article class="personalinfo sec colormainback">
                <img
                  src={
                    props?.logoImageUrl
                      ? props.getImage(props?.logoImageUrl)
                      : logoImage
                  }
                  className="mx-auto d-block img-fluid logo-profile"
                />

                <div class="innerinfo">
                  <h3 className="username">{props.subTitle || "Name"}</h3>
                  <p className="titleuser">
                    {props.designation || "Designation"}
                  </p> */}
                  {/* <p className="companeyname">{rs.sub_title || "Company Name"}</p> */}
                  {/* <!-- <p class='companeyname'>Company</p> --> */}
                {/* </div>
              </article> */}
              {getBlocksOrder().map((val) => (val == undefined ? <></> : val))}

              {/* <div class="fixedd">
                <article class="fixedfooter">
                  <div class="footerleft">
                    <div
                      class="qrcodeshaere"
                      data-bs-toggle="modal"
                      data-bs-target="#appotiments"
                    >
                      <i class="fa-solid fa-qrcode"></i>
                    </div>
                  </div>
                  <div class="addtocinatct">+Add To Contact</div>
                </article>
              </div> */}
              <div
                class="modal fade"
                id="appotiments"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Modal title
                      </h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <article class="personalinfo modal-info ">
                        <canvas
                          height="160"
                          width="160"
                          style={{ height: "128px", width: "128px" }}
                        ></canvas>
                        <h3 class="username">Subhan</h3>
                        <p class="titleuser colordescription">Software Developer</p>
                        <p class="companeyname">Xenophobe Tech</p>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        //   ref={createUserRef}
        id="appotimnets"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                Make Appointment
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form className="row">
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control clear_string colorctaborder"
                    placeholder="Full Name"
                    //   value={appointmentName}
                    //   onChange={(e) =>
                    //     setAppointmentName(e.target.value)
                    //   }
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    eror
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control clear_string colorctaborder"
                    placeholder="Email"
                    //   value={appointmentEmail}
                    //   onChange={(e) =>
                    //     setAppointmentEmail(e.target.value)
                    //   }
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",
                      bottom: "70px",
                    }}
                  >
                    'errormsg'
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control clear_string colorctaborder"
                    placeholder="Mobile No."
                    //   value={appointmentContactNumber}
                    //   onChange={(e) =>
                    //     setAppointmentContactNumber(e.target.value)
                    //   }
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    'errormsgni'
                  </div>
                </div>
                <div class="mb-2 mt-4 col-md-12">
                  <button
                    //   onClick={handleSubmits}
                    class="btn btn-primary me-3"
                    type="button"
                  >
                    <i class="bi bi-check-circle-fill ms-2 "></i>
                    Submit
                  </button>
                </div>
              </form>
            </div>
            {/* <ToastContainer /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theme8;
