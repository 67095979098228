import React from 'react'
import { GalleryImage, GalleryImageWrapper, GalleryWrapper, NewTabGallaryWrapper, NewTabGallaryWrapperContainer, NewTabMenuHeadingWrapper } from './Theme14ExtStyleComponent'
import GalleryIconImage from "../../../../Images/Restaurant/GalleryImage1.png";
import GalleryIconImage2 from "../../../../Images/Restaurant/GalleryIconImage2.png";
import GalleryIconImage3 from "../../../../Images/Restaurant/GalleryIconImage3.png";
import { environmentVariables } from "../../../../config/env.config";


export const TabGallery = (props) => {
  let newGalleryImages = props?.rs?.newGalleryImages
  return (
    <NewTabGallaryWrapperContainer>
    <NewTabGallaryWrapper>
      {/* <GalleryWrapper>
        <GalleryImageWrapper>
        <GalleryImage src={GalleryIconImage}/>
        </GalleryImageWrapper>
        <GalleryImageWrapper>
        <GalleryImage src={GalleryIconImage2}/>
        </GalleryImageWrapper>
        <GalleryImageWrapper>
        <GalleryImage src={GalleryIconImage3}/>
        </GalleryImageWrapper>
        <GalleryImageWrapper>
        <GalleryImage src={GalleryIconImage}/>
        </GalleryImageWrapper>
        <GalleryImageWrapper>
        <GalleryImage src={GalleryIconImage2}/>
        </GalleryImageWrapper>
        <GalleryImageWrapper>
        <GalleryImage src={GalleryIconImage}/>
        </GalleryImageWrapper>
       
      </GalleryWrapper> */}
      <GalleryWrapper>
        {newGalleryImages.map((image, index) => (
          <GalleryImageWrapper key={index}>
            <GalleryImage src={`${environmentVariables?.s3UrlBackendUrl}gallery/${image.image}`} alt={`Gallery Image ${index + 1}`} />
          </GalleryImageWrapper>
        ))}
      </GalleryWrapper>
   </NewTabGallaryWrapper>
  </NewTabGallaryWrapperContainer>
  )
}
