import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  Component,
} from "react";
import PageName from "../Utils/PageName";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { environmentVariables } from "../../config/env.config";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import RefreshIcon from "@mui/icons-material/Refresh";
import styled from "styled-components";
import "./UpdateRequestcardstyle.css";
import Modal from "@mui/material/Modal";
import {useTranslation} from "react-i18next";

import {
  Box,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import FilterIcon from "../../../src/Images/FilterIcon.png";
import NewFilterIcon from "../../../src/Images/newFilter.svg";
import DownloadExcel from "../../../src/Images/downloadExcel.svg";
import { useCallback, useMemo } from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { v4 as uuidv4 } from "uuid";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import parsePhoneNumberFromString from "libphonenumber-js";
import CreateUser from "./CreateUser";
import { CountryDropdown } from "react-country-region-selector";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { getCurrencyDataByCurrency, getCurrencyByCountryCode } from "../../utils/utility";
const AddUserText = styled.div`
  @media (max-width: 420px) {
    display: none !important;
  }
`;

const Boxstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  // height: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  zIndex: 999,
  borderRadius: "15px",
  border: "none",
  outline: "none",
  focus: "none",
  boxShadow:
    " rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;",
  padding: "3rem 5rem",
  "@media (max-width: 650px)": {
    width: "425px",
    padding: "2rem",
  },
  "@media (max-width: 430px)": {
    width: "380px",
    padding: "2rem",
  },
  "@media (max-width: 390px)": {
    width: "350px",
    padding: "2rem",
  },
};

const customStyles = {
  dataTableExtensions: {
    backgroundColor: "lightgray",
    padding: "10px",
  },
  headRow: {
    style: {
      backgroundColor: "#F8FBFF",
      borderRadius: "2px 2px 0 0",
      paddingLeft: "25px",
      border: "none",
      textTransform: "none",
      position: "sticky",
      left: 0,
      top: 0,
      zIndex: 1,
      boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.1)",
      overflow: "hidden",
      whiteSpace: "nowrap",
      height: "70px",
    },
  },
  headCells: {
    style: {
      textAlign: "left",
      minHeight: "64px",
      fontFamily: "DM Sans",
      fontSize: "16px",
      fontWeight: "600",
    },
  },
  rows: {
    style: {
      padding: "6px 0px 6px 25px",
      minHeight: "70px !important",
      fontFamily: "DM Sans",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      color: "#000",
    },
  },
  UserNameColumn: {
    position: "sticky",
    left: "0",
    zIndex: "1",
    backgroundColor: "#fff",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
};

function Staff() {
  const navigate = useNavigate();
  const { userData } = useContext(userContext);
  const createUserRef = useRef(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isvarify, setIsVarify] = useState(false);

  const baseUrl = environmentVariables?.apiUrl;
  const crmDubaiApi = environmentVariables?.crmDubaiApi;
  const fronturl = environmentVariables?.frntUrl;
  const [action] = useState({ fromUser: false });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paginationTotalRows, setPaginationTotalRows] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState([]);
  const showLastLoginDateColumn = userData.user_type == "super_admin";
  const [totalLeads, setTotalLeads] = useState(0);
  const [newLeads, setNewLeads] = useState(0);
  const [cardsSold, setCardsSold] = useState(0);
  const [cardsDelivered, setCardsDelivered] = useState(0);
  const [pipelineLeads, setPipelineLeads] = useState(0);
  const [rejectedLeads, setRejectedLeads] = useState(0);
  const [closedLeads, setClosedLeads] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [onlineRevenue, setOnlineRevenue] = useState(0);
  const [currencySymbol, setcurrencySymbol] = useState(null);
  const [offlineRevenue, setOfflineRevenue] = useState(0);
  const [isLoadings, setIsLoadings] = useState(false);
    const { t } = useTranslation();
  const [userCreateData, setUserCreateData] = useState({
    name: "",
    email: "",
    password: "",
    contact: "",
    roleId: 0,
    country: "",
    staff_type: "",
    under_tl: "",
  });
  const [roleData, setRoleData] = useState(null);
  const [countryData, setCountryData] = useState(null);
  const [usersData, setUsersData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchOption, setSearchOption] = useState("-1");
  const [usersDataSearch, setUsersDataSearch] = useState(null);
  const [usersDataApi, setUsersDataApi] = useState(null);
  const [userId, setUserId] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [roleapiselect, setroleapiselect] = useState(true);
  const [errorMsgName, setErrorMsgName] = useState(null);
  const [errorMsgEmail, setErrorMsgEmail] = useState(null);
  const [errorMsgPassword, setErrorMsgPassword] = useState(null);
  const [errorMsgRole, setErrorMsgRole] = useState(null);
  const [userListData, setUserListData] = useState([]);
  const [isLoader, setIsloader] = useState(true);
  const [sortOrder, setSortOrder] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);

  const tableExtensions = {
    export: false,
    print: false,
  };

  const handleChangeUserData = (e, type) => {
    setErrorMsgName("");
    if (type === "contact" || type === "country") {
      setUserCreateData({ ...userCreateData, [type]: e });
    } else {
      setUserCreateData({ ...userCreateData, [type]: e.target.value });
    }
  };
  const [role, setRole] = useState([]);
  const [country, setCountry] = useState([]);
  const handleEditUser = (id) => {
    getRoleData();
    if (usersData?.length > 0) {
      const editUserData = usersData.filter((val) => val?.id == id);
      setUserId(id);
      setUserCreateData({
        name: editUserData[0]?.name,
        email: editUserData[0]?.email,
        password: "",
        contact: `${editUserData[0]?.country_code || "+91"} ${
          editUserData[0]?.contact || ""
        }`,
        roleId: editUserData[0]?.role_id,
        country: editUserData[0].country,
      });
      setShowCreateModal(false);
    } else {
      alert("Staffs not available");
    }
  };

  const handleDeleteUser = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let data = JSON.stringify({
          userId: id,
        });

        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `${environmentVariables?.apiUrl}api/v1/user/deleteUser?isStaff=true`,
          headers: {
            _token: userData?.token,
            "Content-Type": "application/json",
          },
          // withCredentials: true,
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            Swal.fire("Deleted!", "Selected user has been deleted.", "success");
            getUsersData();
          })
          .catch((error) => {
            Swal.fire(
              "Error!",
              error?.response?.data?.message || error?.message,
              "error"
            );
          });
      }
    });
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  };

  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/;
    return passwordRegex.test(password);
  };

  const isValidContact = (contact, country) => {
    const phoneNumber = parsePhoneNumberFromString(
      contact,
      country?.iso2?.toUpperCase()
    );
    return phoneNumber?.isValid() || false;
  };

  const isValidName = (name) => {
    const nameRegex = /^[A-Za-z -]{3,}$/;
    return nameRegex.test(name);
  };

  const handleEditSubmit = () => {
    if (userCreateData.name == "" || userCreateData.name?.trim() == "") {
      toast.error(t("Name_is_mandatory"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!isValidName(userCreateData.name)) {
      if (userCreateData.name?.trim().length < 3) {
        // setErrorMsgName("Name should be at least 3 characters long");
        toast.error(t("Name_should_be_at_least_3_characters_long"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        return;
      } else {
        toast.error(t("Name_can_only_contain_alphabets_hyphens_and_spaces"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        // setErrorMsgName("Name can only contain alphabets, hyphens, and spaces");
        return;
      }
    }
    if (
      userCreateData.roleId == "" ||
      userCreateData.roleId == 0 ||
      !userCreateData.roleId
    ) {
      toast.error(t("Role_mandatory"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      // setErrorMsgRole("Role is mandatory");
      return;
    }
    if (!isValidContact(userCreateData.contact)) {
      toast.error(t("Invalid_number"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    const data = {
      roleId: userCreateData["roleId"].toString(),
      name: userCreateData["name"],
      email: userCreateData["email"],
      contact: userCreateData["contact"],
      country: userCreateData["country"],
      userId: userId.toString(),
    };
    setIsSubmit(true);
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/user/updateStaffUser`,
      headers: {
        _token: userData?.token,
        "Content-Type": "application/json",
      },
      // withCredentials: true,
      data: JSON.stringify(data),
    };

    axios
      .request(config)
      .then((response) => {
        // getCountryData();
        toast.success(t("Staff_updated"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        getUsersData();
        setIsSubmit(false);

        if (typeof bootstrap !== "undefined" && bootstrap.Modal) {
          const modal = new bootstrap.Modal(createUserRef.current);
          modal.hide(); // Close the modal
        }
      })
      .catch((error) => {
        setIsSubmit(false);
        toast.error(error?.response?.data?.message || error?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        if (!error?.response?.data?.message) {
          if (typeof bootstrap !== "undefined" && bootstrap.Modal) {
            const modal = new bootstrap.Modal(createUserRef.current);
            modal.hide(); // Close the modal
          }
        }
      });
  };
  const clearData = () => {
    setUserCreateData({
      name: "",
      email: "",
      password: "",
      contact: "",
      roleId: 0,
      country: "",
    });
    setShowCreateModal(true);
    setroleapiselect(true);
  };

  const getRoleData = () => {
    let type = "";
    userData?.user_type === "Team Leader" ? (type = "sales") : (type = "staff");

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/user/getseperaterole?type=${type}`,
      headers: {
        _token: userData?.token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setRoleData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status) {
          // navigate("/login");
        }
      });
  };
  const getRole = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/role/rolesList?role=staff`,
      headers: {
        _token: userData?.token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setRole(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status) {
          // navigate("/login");
        }
      });
  };

  const [tempData, setTempData] = useState([]);
  const [allParentName, setAllParentName] = useState([]);
  const [pagelimitdata, setPagelimitdata] = useState(null);
  const getUsersData = (page = 1) => {
    let config;

    if (userData.user_type === "Team Leader") {
      // API for Team Leader
      config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${environmentVariables?.apiUrl}api/v1/user/gettldata?tlid=${userData.id}&page=${page}&limit=${rowsPerPage}&role=${activeRoleName}&country=${activeCountry}&sortOrder=${sortOrder}&sortColumn=${sortColumn}&search=${searchQuery}`,
        headers: {
          _token: userData?.token,
        },
      };
    } else {
      // Default API for other user types
      config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${environmentVariables?.apiUrl}api/v1/user/staffdata?page=${page}&limit=${rowsPerPage}&role=${activeRoleName}&country=${activeCountry}&sortOrder=${sortOrder}&sortColumn=${sortColumn}&search=${searchQuery}`,
        headers: {
          _token: userData?.token,
        },
      };
    }

    axios
      .request(config)
      .then((response) => {
        setIsloader(false);
        setIsLoading(false);

        const countryData = response?.data?.data?.countries;
        console.log(countryData, "-----------countryData------------");
        setCountry(countryData);
        const users = response?.data?.data?.users; // Updated based on backend structure
        setPagelimitdata(response?.data?.data?.pagination.totalRecords);

        if (!users) {
          console.error("No staff data found.");
          return;
        }

        setUsersData(users); // Setting the user data
        let temp = [];

        // Check if user is super admin and handle role names accordingly
        if (userData && userData.user_type === "super_admin") {
          temp = users?.map((el) => el.roleObj?.name); // Accessing role name from roleObj
          setUserListData(users);
        } else {
          temp = users?.map((el) => el.roleObj?.name); // Accessing role name from roleObj
        }

        // Create a unique set of role names
        let temp2 = new Set(temp);
        temp2 = [...temp2];
        temp2 = temp2.filter((el) => el != null);

        // Setting various user data states
        setTempData(users);
        setUsersDataSearch(users);
        setUsersDataApi(users);

        // Extract parent user names if available
        let allParentUserData = users?.map((el) => {
          let hasParentUser =
            el?.parentUser && Object.keys(el?.parentUser).length !== 0;
          return hasParentUser ? el?.parentUser?.name : "";
        });

        allParentUserData = new Set(allParentUserData);
        allParentUserData = [...allParentUserData];
        allParentUserData = allParentUserData.filter(
          (el) => el != null && el !== ""
        );

        setAllParentName(allParentUserData);
        setPaginationTotalRows(
          response?.data?.data?.pagination?.totalRecords || 0
        ); // Updated based on backend structure
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsloader(false); // Ensure loader is stopped on error
        setIsLoading(false); // Ensure loading state is set to false on error
      });
  };

  const getUsersDataForExport = (page, exportType = "") => {
    return new Promise((resolve, reject) => {
      try {
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${environmentVariables?.apiUrl}api/v1/user/getAlluser_withstaff?export=${exportType}&tlid=${userData.id}&role=${activeRoleName}&country=${activeCountry}&sortOrder=${sortOrder}&sortColumn=${sortColumn}&search=${searchQuery}`,
          headers: {
            _token: userData?.token,
          },
          // withCredentials: true,
        };
        axios
          .request(config)
          .then((response) => {
            setIsloader(false);
            let temp3 = response?.data?.data?.users;
            console.log("tttttttttttttttttttttttt", temp3);
            resolve(temp3);
          })
          .catch((error) => {
            console.log(error);
            resolve(false);
          });
      } catch (error) {
        console.log("error====>>>", error);
        resolve(false);
      }
    });
  };

  const handleOnSelectedRowsChange = useCallback(
    ({ selectedRows }) => {
      if (!action.fromUser) return; //the component always trigger this with 0 selected rows when it renders a page, what would clear the selection

      selectedRowsPerPage[currentPage] = selectedRows; //there is no way to tell if a row was DEselected, so I had to control the selected rows per page,
      //the array would get an index to control each page
    },
    [currentPage, selectedRowsPerPage, action.fromUser]
  );

  const handlePageChange = (page) => {
    const nextPage = page;
    getUsersData(nextPage, searchQuery);
    setCurrentPage(page);
    setShowCreateModal(false);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
    setSelectedRowsPerPage([]);

    getUsersData(1, searchQuery);
  };

  const viewHandler = (id) => {
    navigate(`/viewstaffdetails/${id}`);
  };

  const handleVerify = async (userId) => {
    try {
      setIsVarify(true);
      const response = await fetch(
        `${environmentVariables?.apiUrl}api/v1/user/verifyUser`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            _token: userData?.token,
          },
          body: JSON.stringify({ userId: userId }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to verify staff");
      }

      const responseData = await response.json();
      setIsVarify(false);
      getUsersData(currentPage, searchQuery);
      // console.log(responseData.message);
    } catch (error) {
      console.error("Error verifying staff:", error.message);
      setIsVarify(false);
    }
  };

  const exportToExcel = async () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    // Check if userListData is defined before using it
    if (!userListData) {
      console.error("userListData is not defined");
      return;
    }
    let usersAllData = await getUsersDataForExport(1, "export");
    const formattedData =
      usersAllData &&
      usersAllData?.map((item) => ({
        Name: item?.name,
        Email: item?.email,
        country: item?.country,
        contact: `${item?.country_code || "+91"} ${item?.contact || ""}`,
        createdDate: new Date(item?.createdAt).toLocaleDateString("en-GB"),
        EmailVerified: item?.email_verified_at ? "Yes" : "No",
        Agent_Code: item?.agent_code || "---",
      }));
    const uid = uuidv4(); // Generate a unique identifier
    const timestamp = new Date().toISOString().replace(/:/g, "-").split(".")[0]; // Format timestamp
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileName = `STAFFSDATA`;
    saveAs(data, fileName);
  };

  useEffect(() => {
    // getCountryData();
    getRoleData();
    getUsersData(searchQuery);
  }, [rowsPerPage, searchQuery, roleapiselect]);

  const handleToggleStatus = (userId, currentStatus) => {
    const newStatus = currentStatus == "0" ? "1" : "0";
    axios
      .put(
        `${baseUrl}api/v1/user/updateStatusOfUser/${userId}`,
        { status: newStatus },
        {
          headers: {
            _token: userData?.token,
          },
        }
      )
      .then((response) => {
        toast.success(t("User_status_updated_successfully"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        getUsersData(searchQuery);
      })

      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        console.error("Error updating staff status:", error);
      });
  };

  const handleSort = (column, sortDirection) => {
  const key = column.sortCoulmn; // Ensure this matches the actual property name
  const direction = sortDirection === 'asc' ? 'asc' : 'desc';
  setSortColumn(key);
  setSortOrder(direction);
  getUsersData(1);
  setShowCreateModal(false);
};

useEffect(() => {
  getUsersData(1, searchQuery); // Fetch users data whenever sorting changes
}, [sortColumn, sortOrder]);

  useEffect(() => {
    getUsersData(1, searchQuery); // Fetch users data whenever sorting changes
  }, [sortColumn, sortOrder]);

  const columns = [
    {
      name: t("Agent_code"),
      selector: (row) => row?.agent_code,
      sortCoulmn: "agent_code",
      sortable: true,
      width: "150px",
      cell: (row) => (row?.agent_code ? row.agent_code : "---"),
    },
    {
      name: t("Name"),
      selector: (row) => row?.name,
      sortCoulmn: "name",
      sortable: true,
      cell: (d) => {
        const capitalizedName =
          d?.name?.charAt(0)?.toUpperCase() + d?.name?.slice(1);
        return capitalizedName;
      },
      color: "red",
      fixed: "left",
      width: "170px",
    },
    {
      name: t("Email"),
      selector: (row) => row?.email,
      sortCoulmn: "email",
      sortable: true,
      cell: (row) => <div style={{ width: "1000px" }}>{row?.email}</div>,
      width: "200px",
    },
    {
      name: t("Mobile_number"),
      sortable: true,
      sortCoulmn: "contact",
      selector: (row) => `${row?.country_code || ""} ${row?.contact || ""}`,
      width: "150px",
    },
    {
      name: t("Location"),
      selector: (row) => row?.country,
      sortCoulmn: "country",
      sortable: true,
      width: "150px",
      cell: (row) => (row?.country ? row.country : "---"),
    },
    {
      name: t("Role"),
      sortCoulmn: "roleName",
      selector: (row) => row?.roleName,
      sortable: true,
      cell: (d) => {
        const capitalizedRoleName =
          d?.roleName?.charAt(0)?.toUpperCase() + d?.roleName?.slice(1);
        return capitalizedRoleName || "---";
      },
      width: "150px",
    },
    {
      name: t("Registration_date"),
      // selector: (row) => new Date(row?.createdAt).toLocaleDateString(),
      selector: (row) => {
        return moment(row?.createdAt).format("DD/MM/YYYY");
      },
      sortCoulmn: "createdAt",
      sortable: true,
      width: "150px",
    },
    {
      name: t("Email_verified_head"),
      selector: (row) => row?.email_verified_at,
      sortCoulmn: "email_verified_at",
      sortable: true,
      omit: !showLastLoginDateColumn,
      cell: (row) => {
        const [isLoading, setIsLoading] = useState(false);
        if (!row.email_verified_at) {
          return (
            <div style={{ color: "red" }}>
              <button
                style={{
                  borderRadius: "5px",
                  backgroundColor: "red",
                  color: "white",
                  padding: "5px 10px",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsLoading(true);
                  handleVerify(row.id).finally(() => {
                    setIsLoading(false);
                  });
                }}
              >
                {isLoading ? <CircularLoader size={20} /> : "Verify"}
              </button>
            </div>
          );
        } else {
          return (
            <div style={{ color: "green" }}>
              <span>{t("verified")}</span>
            </div>
          );
        }
      },
      width: "150px",
      hidden: !userData || userData.user_type !== "super_admin",
    },
    {
      name: t("Action"),
      sortable: false,
      cell: (d) => (
        <>
          {userData?.user_type == "super_admin" ? (
            <>
          <i onClick={() => viewHandler(d.id)} className="first fas fa-eye"></i>
      
              <i
                style={{ width: "30px", marginRight: "15px" }}
                key={d.id}
                data-bs-toggle="modal"
                data-bs-target="#edituser"
                onClick={() => handleEditUser(d.id)}
                className="first fas fa-pen"
                // style={{  marginRight: '15px' }}
              ></i>
              <i
                style={{ width: "50px" }}
                onClick={() => handleDeleteUser(d.id)}
                className="fas fa-trash-alt"
                // style={{  marginRight: '15px' }}
              ></i>
              
            </>
          ) : (
            <>
             <i onClick={() => viewHandler(d.id)} className="first fas fa-eye"></i>
              <i
                style={{ width: "30px", marginRight: "15px" }}
                key={d.id}
                data-bs-toggle="modal"
                data-bs-target="#edituser"
                onClick={() => handleEditUser(d.id)}
                className="first fas fa-pen"
                // style={{  marginRight: '15px' }}
              ></i>
              {userData.user_type === "Team Leader" ? (
                ""
              ) : (
                <i
                  style={{ width: "50px" }}
                  onClick={() => handleDeleteUser(d.id)}
                  className="fas fa-trash-alt"
                  // style={{  marginRight: '15px' }}
                ></i>
              )}
            </>
          )}

          <div
            className="form-check form-switch"
            style={{ marginLeft: "15px" }}
          >
            <input
              className="form-check-input"
              // type="checkbox"
              type="checkbox"
              checked={d?.isDeleted == "0" ? true : false}
              id={`flexSwitchCheckDefault_${d.id}`}
              onClick={() => {
                // console.log(userData?.user_type,"2wss")
                handleToggleStatus(d.id, d.isDeleted);
              }}
              style={{ marginLeft: "-20px" }}
            />
          </div>
        </>
      ),
      minWidth: "1px",
      width: "250px",
    },
    // {
    //   name: "View details",
    //   sortable: false,
    //   cell: (d) => (
    //     <div>
    //       <i onClick={() => viewHandler(d.id)} className="first fas fa-eye"></i>
    //     </div>
    //   ),
    // },
  ];

  const tableData = {
    columns,
    data: usersData,
  };

  const [activeRoleName, setActiveRoleName] = useState("");
  const [activeCountry, setActiveCountry] = useState("");
  const [activeParentName, setActiveParentName] = useState("");
  const [selectedDateFrom, setSelectedDateFrom] = useState("");
  const [selectedDateTo, setSelectedDateTo] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState({});
  const handleChangeRoleData = (e) => {
    setActiveRoleName(e.target.value);
  };

  const handleChangeCountryData = (e) => {
    const countryName = e.target.value;
    setActiveCountry(countryName);
  };

  const handleFilterOpen = () => {
    setShowFilter(!showFilter);
    setShowCreateModal(false);
    setroleapiselect(false);
    getRole();
  };

  const handleFilterClose = () => {
    setShowFilter(false);
    setroleapiselect(true);
  };

  useEffect(() => {
    let shouldFetchData = false;
    let filteredData = tempData;

    // Check role filter
    if (activeRoleName === "All Role") {
      if (userData && userData.user_type === "super_admin") {
        shouldFetchData = true;
      }
      if (userData && userData.user_type === "Team Leader") {
        shouldFetchData = true;
      }
    } else if (activeRoleName && activeRoleName !== "All Role") {
      shouldFetchData = true;
    }

    // Check country filter
    if (activeCountry === "") {
      if (userData && userData.user_type === "super_admin") {
        shouldFetchData = true;
      }
    } else if (activeCountry) {
      shouldFetchData = true;
    }

    // Apply parent user filter directly on `filteredData`
    if (activeParentName && activeParentName !== "All Role") {
      filteredData = filteredData.filter(
        (el) => el?.parentUser?.name === activeParentName
      );
    }

    // Update the displayed data
    setUsersData(filteredData);

    if (shouldFetchData) {
      getUsersData(1, searchQuery);
    }
  }, [
    activeRoleName,
    selectedDateFrom,
    selectedDateTo,
    activeParentName,
    activeCountry,
  ]);
 const paginationOptions = {
    rowsPerPageText: t("Rows_per_page"),
    rangeSeparatorText: t("Of"),
    selectAllRowsItem: true,
    selectAllRowsItemText: t("All"),
  };
  
  const handleReset = () => {
    setActiveRoleName("All Role");
    setActiveCountry("");
    setUsersData(tempData);
    setActiveParentName("");
    setShowFilter(false);
    setroleapiselect(false);
  };

  const handleCreateUser = (status) => {
    if (status) {
      getUsersData();
      setShowCreateModal(false);
    }
  };

  const handleSearchInputChange = (event) => {
    // Update the search query state when the user types in the search input
    setSearchQuery(event.target.value);
  };

  const checkUserType = () => {
    const { user_type, email } = userData;

    switch (user_type) {
      case "super_admin":
        return "";
      case "Sales Executive":
        return email;
      case "Team Leader":
        return usersData.map((item) => item.email).join(",") || null;
      default:
        return null;
    }
  };
  const handleRefresh = () => {
    if (isLoadings) return;
    setFilterType("");
    setIsLoadings(true);
    setFilters({});

    try {
      setTimeout(() => {
        const response = crmData();
        console.log(response);
      }, 1000);
      console.log(response.data);
    } catch (error) {
      console.error("Error refreshing data:", error.message);
    }
    setTimeout(() => {
      setIsLoadings(false);
    }, 1000);
  };

  useEffect(() => {
    if (userData?.user_type === "Team Leader") {
      crmData();
    }
  }, [usersData]);

  const [filterType, setFilterType] = useState(""); // Initially set to empty
  const [customDateRange, setCustomDateRange] = useState([null, null]); // For custom date range
  const [startDate, endDate] = customDateRange;
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false); // New state for controlling visibility of date picker

  const filterNames = {
    current_date: t("Today"),
    last_week: t("Last_week"),
    last_month: t("Last_month"),
    last_year: t("Last_year"),
  };

  const handleFilterChange = (type) => {
    setFilterType(type);
    handleSubmit(type);
    setCustomDateRange([null, null]); // Reset custom date range when switching filters
    setIsDatePickerVisible(false); // Hide the date picker on filter change
  };

  const handleCustomDateChange = (dates) => {
    setCustomDateRange(dates);
  };

  const handleSubmit = (type) => {
    let start_date = "";
    let end_date = "";
    const today = new Date();

    switch (type) {
      case "current_date":
        start_date = today.toISOString().split("T")[0];
        end_date = start_date;
        break;

      case "last_week":
        const lastWeekStart = new Date();
        lastWeekStart.setDate(today.getDate() - 7); // 7 days back
        start_date = lastWeekStart.toLocaleDateString("en-CA");
        end_date = today.toLocaleDateString("en-CA");
        break;

      case "last_month":
        const lastMonthStart = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0); // Last day of the previous month
        start_date = lastMonthStart.toLocaleDateString("en-CA");
        end_date = lastMonthEnd.toLocaleDateString("en-CA");
        break;

      case "last_year":
        const lastYearStart = new Date(today.getFullYear() - 1, 0, 1); // Start of the previous year
        const lastYearEnd = new Date(today.getFullYear() - 1, 11, 31); // End of the previous year
        start_date = lastYearStart.toLocaleDateString("en-CA");
        end_date = lastYearEnd.toLocaleDateString("en-CA");
        break;

      case "custom":
        if (startDate && endDate) {
          start_date = startDate.toLocaleDateString("en-CA");
          end_date = endDate.toLocaleDateString("en-CA");
        } else {
          alert(
            "Please select both start and end dates for the custom filter."
          );
          return;
        }
        break;

      default:
        alert("Please select a valid filter type.");
        return;
    }

    crmData(start_date, end_date); // Send dates to fetch data
  };

  const crmData = (start_date, end_date) => {
    let email = checkUserType();
    if (email == null) return;

    let data = JSON.stringify({ start_date, end_date, email });
    const prevToken = "7d68e2c38dfb38785e7a";
    const token = btoa(prevToken);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.crmDubaiApi}/api/LeadController/totalLeadsSummary`,
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const data = response?.data;
        let cSymbol = null;
        userData.pay_currency !== null
          ? (cSymbol = getCurrencyDataByCurrency(userData?.pay_currency))
          : (cSymbol = getCurrencyByCountryCode(userData?.country));
        setcurrencySymbol(cSymbol);
        if (data.success) {
          setTotalLeads(data?.data?.total_leads || 0);
          setNewLeads(data?.data?.new_leads || 0);
          setCardsSold(data?.data?.card_ordered || 0);
          setCardsDelivered(data?.data?.card_delivered || 0);

          const pipelineLeads =
            Number(data?.data?.whatsap_msg_Leads || 0) +
            Number(data?.data?.answered_leads || 0) +
            Number(data?.data?.call_leads || 0) +
            Number(data?.data?.follow_up_leads || 0) +
            Number(data?.data?.intro_call_leads || 0) +
            Number(data?.data?.prospect_leads || 0) +
            Number(data?.data?.customer_leads || 0);

          const totalRevenue =
            Number(data?.data?.online_revenue || 0) +
            Number(data?.data?.offline_revenue || 0);

          const rejectedLeads =
            Number(data?.data?.failed_leads || 0) +
            Number(data?.data?.rejected_leads || 0);

          setPipelineLeads(pipelineLeads);
          setTotalRevenue(totalRevenue);
          setRejectedLeads(rejectedLeads);
          setClosedLeads(data?.data?.close_leads || 0);
          setOnlineRevenue(data?.data?.online_revenue || 0);
          setOfflineRevenue(data?.data?.offline_revenue || 0);
        } else {
          setTotalLeads(0);
          setNewLeads(0);
          setCardsSold(0);
          setCardsDelivered(0);
          setRejectedLeads(0);
          setClosedLeads(0);
          setPipelineLeads(0);
          setTotalRevenue(0);
          setOnlineRevenue(0);
          setOfflineRevenue(0);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <PageName PageName={t("Staffs")} />
      <article>
        {userData?.user_type === "Team Leader" && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                marginBottom: "20px",
              }}
            >
              <div
                className="requestcardfilterstyle"
                onClick={!isLoadings ? handleRefresh : null}
                style={{
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "44px",
                  height: "44px",
                  cursor: isLoadings ? "not-allowed" : "pointer",
                  color: isLoadings ? "gray" : "blue",
                }}
              >
                {isLoadings ? (
                  <CircularLoader size={24} />
                ) : (
                  <RefreshIcon style={{ fontSize: "24px" }} />
                )}
              </div>
              {/* Dropdown for predefined filters */}

              {/* Custom Date Picker Button */}
              <div style={{ position: "relative" }}>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setIsDatePickerVisible(true);
                    setFilterType("custom");
                  }} // Open the date picker and set filter type to custom
                  style={{
                    padding: "8px 12px",
                    backgroundColor: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  {t("Custom_date_range")}
                </button>

                {/* Custom Date Picker */}
                {isDatePickerVisible && filterType === "custom" && (
                  <div
                    style={{
                      position: "absolute",
                      top: "-2px",
                      right: "0",
                      zIndex: "2",
                    }}
                  >
                    <DatePicker
                      selectsRange
                      startDate={startDate}
                      endDate={endDate}
                      onChange={handleCustomDateChange}
                      dateFormat="yyyy-MM-dd"
                      placeholderText="Select a custom date range"
                      inline
                      style={{
                        padding: "8px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        fontSize: "14px",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <button
                        className="btn btn-secondary"
                        onClick={() => setIsDatePickerVisible(false)} // Close the date picker
                        style={{
                          padding: "8px 12px",
                          backgroundColor: "#6c757d",
                          color: "white",
                          border: "none",
                          borderRadius: "5px",
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                      >
                        {t("Close")}
                      </button>
                      <button
                        className="btn btn-success"
                        onClick={() => {
                          handleSubmit("custom");
                          setIsDatePickerVisible(false);
                        }} // Apply the date filter and close the date picker
                        style={{
                          padding: "8px 12px",
                          backgroundColor: "#28a745",
                          color: "white",
                          border: "none",
                          borderRadius: "5px",
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                      >
                        {t("Apply")}
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="filter-dropdown">
                <select
                  className="form-select"
                  onChange={(e) => handleFilterChange(e.target.value)} // Update filterType state
                  value={filterType || "Filter"} // Show "Filter" if filterType is empty
                  style={{
                    padding: "8px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    fontSize: "14px",
                    width: "200px",
                    marginRight:
                      isDatePickerVisible && filterType === "custom"
                        ? "70px"
                        : "",
                  }}
                >
                  <option value="Filter" disabled>
                    {t("Select_filter")}
                  </option>
                  {Object.keys(filterNames).map((key) => (
                    <option key={key} value={key}>
                      {filterNames[key]}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-md-12" id="total_wrapper_container">
              <div className="row">
                <div className="col-md-3">
                  <div className="tile bbox Total_Titlebox_container">
                    <h3>{t("Total_leads")}</h3>
                    <h4>{totalLeads || 0}</h4>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="tile bbox Total_Titlebox_container">
                    <h3>{t("New_leads")}</h3>
                    <h4>{newLeads || 0}</h4>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="tile bbox Total_Titlebox_container">
                    <h3>{t("Closed_leads")}</h3>
                    <h4>{closedLeads || 0}</h4>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="tile bbox ">
                    <h3>{t("Pipeline_leads")}</h3>
                    <h4>{pipelineLeads || 0}</h4>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="tile bbox Total_Titlebox_container">
                    <h3>{t("Rejected_leads")}</h3>
                    <h4>{rejectedLeads || 0}</h4>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="tile bbox Total_Titlebox_container">
                    <h3>{t("Cards_sold")}</h3>
                    <h4>{cardsSold || 0}</h4>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="tile bbox ">
                    <h3>{t("Cards_delivered")}</h3>
                    <h4>{cardsDelivered || 0}</h4>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="tile bbox Total_Titlebox_container">
                    <h3>{t("Total_revenue")}</h3>
                    <h4>
                      {currencySymbol}
                      {totalRevenue || 0}
                    </h4>
                    <div className="revenue" style={{ display: "inline-flex" }}>
                      <h3 style={{ margin: "0px 40px 0px 0px" }}>
                      {t("Online")}
                        <br />
                        <b>
                          {currencySymbol}
                          {onlineRevenue || 0}
                        </b>
                      </h3>
                      <h3 style={{ marginTop: "0px" }}>
                      {t("Offline")}
                        <br />
                        <b>
                          {currencySymbol}
                          {offlineRevenue || 0}
                        </b>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="row">
          <div class="col-md-9">
            <div className="d-flex">
              {userData.user_type === "super_admin" ? (
                <div>
                 
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
         
        </div>
        <div className="">
          <div className="col-md-12" style={{display:"flex", justifyContent:"space-between"}}>
            <div
              className="addusermain2"
              style={{ margin: "20px 0px 15px 0px" }}
            >
              <h3>{t("Staff_list")}</h3>
            </div>
            <a
                class="icon-btn addrolemain_roleAdduser"
                onClick={() => clearData()}
              >
                <AddUserText className="AddNewPlanClass">
                  {" "}
                  {t("Add_staff")}
                </AddUserText>
                <span className="addrole_role">+</span>
              </a>
          </div>
          <div style={{
                overflow:"scroll",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "10px 10px 0 0 ",
                padding: "20px",
                marginBottom: "-16px",
                background:"#fff",
                gap:"20px"
              }}>
          <div class="user-select-items">
            <select
              style={{ cursor: "pointer" }}
              value={activeCountry}
              onChange={(e) => handleChangeCountryData(e)}
              class="form-select"
            >
              {""}
              <option value={""}>{t("Select_country")}</option>
              {country &&
                country.map((val, index) => (
                  <option key={index} value={val?.country}>
                    {val?.country}
                  </option>
                ))}
            </select>
          </div>
            <p
              className="addusermainright"
              style={{marginTop:"0" }}>
             
              <input
                    className=" customsearchbyword searchUser"
                    type="text"
                    value={searchQuery}
                    onChange={(e) => handleSearchInputChange(e)}
                    placeholder={t("Search_staffs_email")}
                  />

              
              {((role && role.length > 0) || (country && country.length > 0)) &&
                userData?.user_type === "super_admin" && (
                  <>
                    <div
                      className="filter-button-wrapper"
                      style={{ display: "flex" }}
                    >
                      {/* <span
                        class="FilterIconTextWrapper"
                        onClick={handleFilterOpen}
                      >
                        {" "}
                        <div className="FilterIcon">
                          {" "}
                          <img loading="lazy" src={FilterIcon} width={28} />
                          <div className="filtertext">Filters</div>
                        </div>
                      </span> */}
                      {/* <button
                        className="btn btn-primary d-flex gap-2 items-center user-filter"
                        onClick={handleFilterOpen}
                        id="FilterIconText"
                      >  <img src={NewFilterIcon}/>
                        Filter
                      </button> */}
                    </div>
                    <Modal
                      open={showFilter}
                      onClose={handleFilterClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={Boxstyle}>
                        {showFilter && (
                          <div class="Contact_filter_fieldbutton_wrapper">
                            <div class="Contact_filter_field_wrapper UserEditForme">
                              <div class=" col-md-2 Contact_filter_Inputfield">
                                <select
                                  value={activeRoleName}
                                  onChange={(e) => handleChangeRoleData(e)}
                                  class="form-select"
                                >
                                  {" "}
                                  <option value={"All Role"}>All Role</option>
                                  {role &&
                                    role.map((val, index) => (
                                      <option key={index} value={val?.role_id}>
                                        {val?.role_name}{" "}
                                      </option>
                                    ))}
                                </select>
                                <div
                                  style={{
                                    fontSize: "14px",
                                    color: "red",
                                    bottom: "70px",
                                  }}
                                ></div>
                              </div>
                              <div class=" col-md-2 Contact_filter_Inputfield">
                                {/* <select
                                  style={{ width: "272px" }}
                                  value={activeCountry}
                                  onChange={(e) => handleChangeCountryData(e)}
                                  class="form-select"
                                >
                                  {""}
                                  <option value={""}>Select Country</option>
                                  {country &&
                                    country.map((val, index) => (
                                      <option key={index} value={val?.country}>{val?.country}</option> 
                                    ))}
                                </select> */}
                                <div
                                  style={{
                                    fontSize: "14px",
                                    color: "red",
                                    bottom: "70px",
                                  }}
                                ></div>
                              </div>
                              <div class="contact_filter_buttonwrapper ">
                                <button
                                  class="contact_filter_buttonstyle"
                                  onClick={handleFilterClose}
                                >
                                  Apply
                                </button>
                                <button
                                  class="contact_filter_buttonstyle"
                                  onClick={() => handleReset()}
                                >
                                  Reset{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </Box>
                    </Modal>
                  </>
                )}

<button
                className="btn d-flex btn-primary gap-1 bg-transparent border-0 excel-button"
                onClick={exportToExcel}
              > <img src={DownloadExcel}/>
                {t("Export")}    </button>
            </p>
          </div>
        </div>

        {isLoader ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "55%",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <CircularLoader size={40} />
          </div>
        ) : (
          <div className="main">
            {isLoading ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "55%",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <CircularLoader size={40} />
              </div>
            ) : (
              <>
                <div className="scrollable-columns">
                  <div className="sticky-header-container">
                    <DataTableExtensions
                      {...tableData}
                      {...tableExtensions}
                      customStyles={customStyles}
                      filterPlaceholder="Search Staff"
                    >
                      {pagelimitdata <= 10 ? (
                        <DataTable
                          columns={columns}
                          data={tableData.data}
                          noHeader
                          defaultSortField="id"
                          sortIcon={<SortIcon />}
                          sortServer
                          onSort={handleSort}
                          highlightOnHover
                          onChangePage={handlePageChange}
                          onChangeRowsPerPage={handleRowsPerPageChange}
                          onSelectedRowsChange={handleOnSelectedRowsChange}
                          dense
                           paginationComponentOptions={{
                        ...paginationOptions,
                      }}
                          customStyles={customStyles}
                          noDataComponent={<div style={{padding:"24px"}}>{t("No_records_found")}</div>}
                        />
                      ) : (
                        <DataTable
                          columns={columns}
                          data={tableData.data}
                          noHeader
                          defaultSortField="id"
                          sortIcon={<SortIcon />}
                          sortServer
                          onSort={handleSort}
                          highlightOnHover
                          pagination
                          paginationServer
                          paginationTotalRows={paginationTotalRows}
                          paginationPerPage={paginationPerPage}
                          paginationRowsPerPageOptions={[
                            10, 20, 50, 100, 300, 1000, 10000,
                          ]}
                          onChangePage={handlePageChange}
                          onChangeRowsPerPage={handleRowsPerPageChange}
                          onSelectedRowsChange={handleOnSelectedRowsChange}
                          dense
                           paginationComponentOptions={{
                        ...paginationOptions,
                      }}
                          customStyles={customStyles}
                          noDataComponent={<div style={{padding:"24px"}}>{t("No_records_found")}</div>}
                        />
                      )}
                    </DataTableExtensions>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </article>

      <CreateUser
        showModal={showCreateModal}
        userCreateData={userCreateData}
        roleData={roleData}
        handleCreateUser={handleCreateUser}
        isStaff={true}
        onClose={() => setShowCreateModal(false)}
      />

      <div
        class="modal fade"
        id="edituser"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={createUserRef}
      >
        <div class="modal-dialog">
          <div
            class="modal-content userUpdate_action_form"
            id="exampleModalLabelContainer"
          >
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {t("Update_staff")}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form class="row">
                <div class="mb-2 col-md-12">
                  <input
                    onChange={(e) => {
                      handleChangeUserData(e, "name");
                    }}
                    value={userCreateData["name"]}
                    class="form-control"
                    type="name"
                    placeholder={t("Name")}
                  />
                </div>
                <div class="mb-2 col-md-12">
                  <input
                    value={userCreateData["email"]}
                    class="form-control"
                    type="email"
                    placeholder={t("Email")}
                  />
                </div>
                <div className="mb-2 col-md-12">
                  <CountryDropdown
                    disabled={true}
                    style={{
                      backgroundColor: "white",
                      opacity: 0.6,
                    }}
                    value={userCreateData["country"] || ""}
                    onChange={(e) => handleChangeUserData(e, "country")}
                    className="form-control form-select"
                    // valueType="short"
                  />
                </div>
                <div class="mb-2 col-md-12">
                  <PhoneInput
                    name="contact"
                    country={"in"}
                    countryCodeEditable={false}
                    enableSearch={true}
                    value={userCreateData["contact"]}
                    onChange={(phone, country, e, formattedValue) => {
                      handleChangeUserData(formattedValue, "contact");
                    }}
                    isValid={(value, country) => isValidContact(value, country)}
                    inputProps={{
                      className: "form-control",
                      placeholder: t("Mobile_number"),
                      required: true,
                    }}
                  />
                </div>
                <div class="mb-2 col-md-12">
                  <select
                    value={userCreateData["roleId"]}
                    onChange={(e) => handleChangeUserData(e, "roleId")}
                    class="form-select"
                  >
                    <option value={0}>{t("Staff_role")}</option>
                    {roleData &&
                      roleData.map((val, inde) => (
                        <option key={inde} value={val?.id}>
                          {val?.name}{" "}
                        </option>
                      ))}
                  </select>
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    color: "red",
                    position: "absolute",
                    bottom: "70px",
                  }}
                >
                  {errorMsg}
                </div>
                <div class="mb-2 flex-center col-md-12">
                  <button
                    onClick={handleEditSubmit}
                    class="btn btn-primary me-3 save-cta"
                    type="button"
                    data-bs-dismiss="modal"
                  >
                    {t("Update")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Staff;
