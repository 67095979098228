import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { OTPSchema } from "../../../../common/registerValidationCustomer";
import CircularLoader from "../../../Components/CircularLoader/CircularLoader";
import { environmentVariables } from "../../../../config/env.config";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OtpPopup = ({
  isModalOpen,
  setIsModalOpen,
  isModalOpenRegister,
  setIsModalOpenRegister,
  isOtpModalOpen,
  setIsOtpModalOpen,
  forwardedemail,
  setFormwadedEmail,
}) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];
  const [otpError, setOtpError] = useState("");
   const { t } = useTranslation();
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChangeOtp = (e, index) => {
    const inputValue = e.target.value;

    if (
      /^[0-9]*$/.test(inputValue) ||
      inputValue === "" ||
      e.key === "Backspace" ||
      e.key === "Delete"
    ) {
      let arr = [...otp];
      arr[index] = inputValue;
      setOtp(arr);

      if (
        index < inputRefs.length - 1 &&
        inputValue !== "" &&
        e.key !== "Delete" &&
        e.key !== "Backspace"
      ) {
        // Check if the next input exists before calling focus
        if (inputRefs[index + 1]?.current) {
          inputRefs[index + 1].current.focus();
        }
      }

      if (e.key === "Backspace" && index > 0) {
        // Check if the previous input exists before calling focus
        if (inputRefs[index - 1]?.current) {
          inputRefs[index - 1].current.focus();
        }

        // Clear the last input field in the OTP array
        let arr = [...otp];
        arr[arr.length - 1] = "";
        setOtp(arr);
      }
    } else {
      e.preventDefault();
    }
  };

  const initialValues = {
    email: forwardedemail,
  };
  let formik = useFormik({
    initialValues: initialValues,
    validationSchema: OTPSchema(t),
    onSubmit: async (values) => {
      handleSubmitVerify();
    },
  });

  const { values, errors, handleSubmit } = formik;
  const handleSubmitVerify = () => {
    const newOtp = otp.join("");

    if (otp.some((digit) => digit === "")) {
      setOtpError(t("Otp_must_non_empty"));
      setIsSubmit(false);
      return;
    }

    setIsSubmit(true);
    setOtpError("");

    let data = {
      email: formik.values.email,
      otp: newOtp,
    };

    let config = {
      method: "post",
      url: `${environmentVariables?.apiUrl}api/v1/user/verifyOtp`,
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // navigate("/reset-password", {
        //   state: { email: formik.values.email, otp: otp },
        // });
        setIsSubmit(false);
        setOtp(["", "", "", "", "", ""]);
        toast.success(t("Email_verified"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setTimeout(() => {
          setIsModalOpenRegister(false);
          setIsOtpModalOpen(false);
          setIsModalOpen(true);
        }, 1500);
      })
      .catch((error) => {
        setOtp(["", "", "", "", "", ""]); 
        
        toast.error( error?.response?.data?.message || error?.response?.data || error?.message, {
          autoClose: 2000,
        });
        setIsSubmit(false);
      });
  };
  return (
    <>
      <div className="modal-overlay">
        <div className="modal-content innerlogin custom_otp" style={{ bottom: "20vh" }}>
          <div className="form-section">
            <h3 className="innercreatetitle" style={{paddingTop:"30px"}}>{t("Enter_verification_code")}</h3>
            <div className="login-inner-form">
              <div
                style={{
                  display: "flex",
                  flexDirection:"column",
                  // justifyContent: "space-between",
                  alignItems: "center",
                  gap: "8px",
                  justifyContent: "center",
                  paddingBottom:"20px"
                }}
              >
                <div  style={{
                  display: "flex",
                  // justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                  justifyContent: "center",
                }}>
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    className="square-input"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleChangeOtp(e, index)}
                    onKeyDown={(e) => handleChangeOtp(e, index)}
                    ref={inputRefs[index]}
                  />
                ))}
                </div>
                {otpError && <div style={{color:"red"}}>{otpError}</div>}
              </div>
            </div>
          </div>
          <div className="form-group clearfix mb-0" style={{margin:"0 auto",maxWidth:'300px',width:'100%'}}>
            <button
              onClick={handleSubmit}
              type="button"
              className="btn-theme login_button"
              disabled={isSubmit}
              style={{ width: "100%",  }}
            >
              {/* {isSubmit ? <CircularLoader size={25} /> : "Login"} */}
              {!isSubmit ? `${t("Submit")}` : null}
              {isSubmit && <CircularLoader size={20} />}
              {/* Login */}
            </button>
          </div>

          <button
            className="innermodal-close"
            onClick={() => {
              setIsOtpModalOpen(false);
            }}
          >
            {t("Close")}
          </button>
        </div>
        {/* <ToastContainer /> */}
      </div>
    </>
  );
};

export default OtpPopup;
