import React from 'react';
import CarImage from "./../../../../Images/DefaultBMWCabrio.png";
import CarImage1 from "./../../../../Images/DefaultToyota.png";
import CarImage2 from "./../../../../Images/DefaultHondacv.png";
import CarImage3 from "./../../../../Images/HondaCrv.png";
import { environmentVariables } from "../../../../config/env.config";


const AvilableCabTab1 = (props) => {
  return (
    <div className='px-2'>
        <div className='capitalize font-Poppins text-xl text-white font-medium'>
        Available Cabs
        </div>

        <div className='bg-cabcard rounded flex my-4 ' style={{justifyContent:"space-between"}}>
            <div className='p-2'>
            <div className='p-2 font-DM text-white font-medium text-lg'> BMW Cabrio </div>
             <div className='py-1 px-2'>   
                <div className='font-DM text-D0D0D0 font-medium'> Automatic </div>
                <div className='font-DM text-D0D0D0 font-medium'> 3 seaters </div>
                <div className='font-DM text-D0D0D0 font-medium'>Octane</div>
                </div>
            </div>
            <div style={{marginTop:"-10px"}}>
                <img loading="lazy" style={{width:"240px"}} src={CarImage}/>
            </div>

        </div>


         <div className='bg-cabcard rounded flex my-4' style={{justifyContent:"space-between"}}>
             <div className='p-2'>
             <div className='p-2 font-DM text-white font-medium text-lg'> Toyota Camry </div>
              <div className='py-1 px-2'>   
                 <div className='font-DM text-D0D0D0 font-medium'> Manual </div>
                 <div className='font-DM text-D0D0D0 font-medium'> 4 seaters   </div>
                 <div className='font-DM text-D0D0D0 font-medium'>Octane</div>
                 </div>
             </div>
             <div style={{marginTop:"-10px"}}>
                 <img loading="lazy" style={{width:"240px"}} src={CarImage1}/>
             </div>
         </div>

         <div className='bg-cabcard rounded flex my-4' style={{justifyContent:"space-between"}}>
             <div className='p-2'>
             <div className='p-2 font-DM text-white font-medium text-lg'>Honda CR-V</div>
              <div className='py-1 px-2'>   
                 <div className='font-DM text-D0D0D0 font-medium'> Manual </div>
                 <div className='font-DM text-D0D0D0 font-medium'> 2 seaters </div>
                 <div className='font-DM text-D0D0D0 font-medium'>Octane</div>
                 </div>
             </div>
             <div style={{marginTop:"-10px"}}>
                 <img loading="lazy" style={{width:"240px"}} src={CarImage2}/>
             </div>

         </div>

         <div className='bg-cabcard rounded flex my-4' style={{justifyContent:"space-between"}}>
             <div className='p-2'>
             <div className='p-2 font-DM text-white font-medium text-lg'> Toyota Camry </div>
              <div className='py-1 px-2'>   
                 <div className='font-DM text-D0D0D0 font-medium'> Manual </div>
                 <div className='font-DM text-D0D0D0 font-medium'> 4 seaters </div>
                 <div className='font-DM text-D0D0D0 font-medium'>Octane</div>
                 </div>
             </div>
             <div style={{marginTop:"-10px"}}>
                 <img loading="lazy" style={{width:"240px"}} src={CarImage1}/>
             </div>

         </div>

         <div className='bg-cabcard rounded flex my-4' style={{justifyContent:"space-between"}}>
             <div className='p-2'>
             <div className='p-2 font-DM text-white font-medium text-lg'> BMW Cabrio </div>
              <div className='py-1 px-2'>   
                 <div className='font-DM text-D0D0D0 font-medium'> Automatic </div>
                 <div className='font-DM text-D0D0D0 font-medium'> 3 seaters </div>
                 <div className='font-DM text-D0D0D0 font-medium'>Octane</div>
                 </div>
             </div>
             <div style={{marginTop:"-10px"}}>
                 <img loading="lazy" style={{width:"240px"}} src={CarImage3}/>
             </div>

         </div>
     </div>
     
  )
}

export default AvilableCabTab1;