import * as yup from "yup";
import { object, string, ref } from "yup";
import { useTranslation } from "react-i18next";

const getCharacterValidationError = (str) => {
const { t } = useTranslation();
  return `${t("Password_atleast_one")} ${str} ${t("Character")}`;
};
export const changePasswordValidation =()=> {
const { t } = useTranslation();

return yup.object().shape({
  password: string()
    .required(t("Please_enter_a_password"))
    // check minimum characters
    .min(8, t("Password_at_least_8_characters"))
    // different error messages for different requirements
    .matches(/[0-9]/, getCharacterValidationError(t("Digit")))
    .matches(/[a-z]/, getCharacterValidationError(t("Lowercase")))
    .matches(/[A-Z]/, getCharacterValidationError(t("Uppercase")))
    .matches(/[@$!#^-_=+%*?&]/, getCharacterValidationError(t("Symbol"))),
  cpassword: string()
    .required(t("Retype_password"))
    // use oneOf to match one of the values inside the array.
    // use "ref" to get the value of passwrod.
    .oneOf([ref("password")], t("Passwords_does_not_match")),
})}
