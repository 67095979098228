import { useParams } from 'react-router-dom';
import React, { useRef, useContext, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { userContext } from "../../context/userContext";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import Charttb from "./Charttb";
import Charttc from "./Charttc";
import Chartte from "./Chartte";
import Charttf from "./Charttf";
import Modal from "@mui/material/Modal";
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import {  Box, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from "@mui/material";

import DatePicker from "react-datepicker";
import { getCurrencyDataByCurrency, getCurrencyByCountryCode } from '../../utils/utility';
import { useTranslation } from "react-i18next";

const ViewStaffDetails = () => {
  const { t } = useTranslation();
  const { userData } = useContext(userContext);
  const [sevdays, setSevdays] = useState({});
  const [busData, setBusData] = useState({});
  const baseUrl = environmentVariables?.apiUrl;
  const crmDubaiApi=environmentVariables?.crmDubaiApi;
  const apiUrl = environmentVariables?.apiUrl;
  const [selectedDateFrom, setSelectedDateFrom] = useState("");
  const [selectedDateTo, setSelectedDateTo] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [sortOrder, setSortOrder] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [userId, setUserId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeBusinessStatus, setBusinessStatus] = useState("All status");
  const [currentPage, setCurrentPage] = useState(1);
  const [sId, setId]=useState("");
  const [totalLeads, setTotalLeads] = useState(0);
  const [newLeads, setNewLeads] = useState(0);
  const [cardsSold, setCardsSold] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [onlineRevenue, setOnlineRevenue] = useState(0);
  const [offlineRevenue, setOfflineRevenue] = useState(0);
  const [cardsDelivered, setCardsDelivered] = useState(0);
  const [rejectedLeads, setRejectedLeads] = useState(0);
  const [closedLeads, setClosedLeads] = useState(0);
  const [pipelineLeads, setPipelineLeads] = useState(0);
  const [isLoadings, setIsLoadings] = useState(false);
  const [filters,setFilters]=useState({});
  const fromDate = useRef(null);
  const toDate = useRef(null);
  // const { id } = useParams();
  let id;
const params = useParams();
if (params?.id) {
  id = params.id;
} else {
  id = userData.id;
}

  const url = `${apiUrl}api/v1/user/viewalldetails?id=${id}&startDate=${selectedDateFrom}&endDate=${selectedDateTo}`;
  
  const [staffname, setstaffnme] = useState(null);
  const [staffemail, setstaffemail]= useState(null);
  const [staffmobile, setstaffmobile]= useState(null);
  const [country, setstaffcountry]= useState(null);
  const [roleName, setroleName]= useState(null);
  const [userType, setuserType]= useState(null);
  const [currencySymbol, setcurrencySymbol]= useState(null); 
  const [createdAt, setCreatedAt]=useState(null);
  const [agentCode, setAgentCode] = useState(null)
  // const getGraphData = () => {
  //   axios
  //     .get(`${baseUrl}api/v1/business/fetchGraphData`, {
  //       params: { UserId: userData?.id },
  //       headers: {
  //         _token: userData?.token,
  //       },
  //     })
  //     .then((res) => {
  //       if (res?.status === 200) {
  //         setSevdays(res?.data);
  //         setBusData(res?.data?.totalBusiness?.rs);
  //         if (res?.data?.resultA?.sum_of_appointments) {
  //           setAppData(res.data.resultA.sum_of_appointments);
  //         }
  //         if (res?.data?.resultC?.sum_of_contacts) {
  //           setContData(res.data.resultC.sum_of_contacts);
  //         }
  //         if (res?.data?.resultViewr) {
  //           setChartsRs(res.data?.resultViewr);
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       if (error.response && error.response.status === 404) {
  //         console.error("Resource not found:", error);
  //       } else {
  //         console.error("error:", error);
  //       }
  //     });
  // };

  // useEffect(() => {
  //   getGraphData();
  // }, []);

  async function getviewdata() {
    try {
      const response = await axios.get(url);
      setAgentCode(response.data.data.agent_code);
      setstaffnme(response.data.data.name);
      setstaffemail(response.data.data.email);
      setstaffmobile(response.data.data.contact);
      setstaffcountry(response.data.data.country);
      setroleName(response.data.data.roleName);
      setuserType(response.data.data.user_type);
      setCreatedAt(new Date(response.data.data.createdAt).toLocaleDateString("en-GB"));
      // let cSymbol = getCurrencyDataByCurrency(response?.data?.data?.pay_currency);
      let cSymbol = null;
      response?.data?.data?.pay_currency !== null
      ? (cSymbol = getCurrencyDataByCurrency(response?.data?.data?.pay_currency))
      : (cSymbol = getCurrencyByCountryCode(response?.data?.data?.countryAbbr));      
      setcurrencySymbol(cSymbol);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getviewdata();
  }, [id, selectedDateFrom, selectedDateTo, sortOrder, sortColumn, userId]);
  //   userId,
  //   page = 1,
  //   rowsPerPage,
  //   searchQuery
  // ) => {
  //   try {
  //     let config = {
  //       method: "get",
  //       maxBodyLength: Infinity,
  //       url: `${apiUrl}api/v1/business/fetchBusiness?id=${userId}&page=${page}&limit=${rowsPerPage}&businessStatus=${activeBusinessStatus}&startDate=${selectedDateFrom}&endDate=${selectedDateTo}&search=${searchQuery}&sortOrder=${sortOrder}&sortColumn=${sortColumn}`, //note: same api on APP.js please check too
  //       headers: {
  //         _token: userData?.token,
  //       },
  //       withCredentials: true,
  //     };
  //     await axios
  //       .request(config)
  //       .then((response) => {

  //         // setIsloader(false);
  //         setResumeAvailable(response?.data?.data?.resume_id)

  //         let newData = response?.data?.data.map((val) => {
  //           val.created_at = val.created_at.split("T")[0];
  //           return val;
  //         });

  //         // newData = newData?.sort(
  //         //   (x, y) => new Date(y?.created_at) - new Date(x?.created_at)
  //         // );
  //         // setTempData(newData);

  //         setPaginationTotalRows(response?.data?.totalItems);
  //         setTotalcount(response?.data?.totalItems);
  //         setTotalActivecount(response?.data?.activeCount);
  //         setTotalInActivecount(response?.data?.inactiveCount);
  //         setBusinesslistData(response?.data?.data);
  //         setBusinesslistData(newData);
  //         let filteredData = [];

  //         // if (activeBusinessStatus != "All Status") {
  //         //   filteredData = newData.filter(
  //         //     (el) => el?.status == activeBusinessStatus
  //         //   );
  //         // } else {
  //         //   filteredData = newData;
  //         // }
  //         // if (selectedDateFrom && selectedDateTo) {
  //         //   filteredData = filteredData?.filter((el) => {
  //         //     const date = new Date(el.created_at);
  //         //     return date >= selectedDateFrom && date <= selectedDateTo;
  //         //   });
  //         // }
  //         // // setTempData(filteredData);
  //         // if (activeBusinessStatus != "") {
  //         //   setBusinesslistData(filteredData);
  //         // } else {
  //         //   setBusinesslistData(newData);
  //         // }
  //       })
  //       .catch((error) => {
  //         // setIsloader(false);
  //       });
  //     // setBusinesslistData(newData);
  //     setIsLoadings(false);
  //   } catch (err) {
  //     console.log(err, "error");
  //   }
  // };

  // useEffect(()=>{
  //   fetchBusinessData(userId, 1, 10, searchQuery);
  // },[sortColumn, sortOrder]);


  const handleCopy = () => {
    navigator.clipboard.writeText(agentCode || 'Not Available').then(() => {
      toast.success("Agent Code Copied! ", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      }); // just a sample alert, replace with toast
    });
  };


const [filterType, setFilterType] = useState("");
const [customDateRange, setCustomDateRange] = useState([null, null]); // For custom date range
const [startDate, endDate] = customDateRange;
  // lead data from crm


    const [isDatePickerVisible, setIsDatePickerVisible] = useState(false); 
  const filterNames = {
    current_date: t("Today"),
    last_week: t("Last_week"),
    last_month: t("Last_month"),
    last_year: t("Last_year"),
  };
  
  const handleFilterChange = (type) => {
    setFilterType(type);
    handleSubmit(type);
    setCustomDateRange([null, null]);
    setIsDatePickerVisible(false);
  };
  
  const handleCustomDateChange = (dates) => {   
    setCustomDateRange(dates);
  };
  
  const handleSubmit = (type) => {
    let start_date = "";
    let end_date = "";
    const today = new Date(); 
    
    switch (type) {
      case "current_date":
        start_date = today.toISOString().split("T")[0];
        end_date = start_date;
        break;
  
      case "last_week":
        const lastWeekStart = new Date();
        lastWeekStart.setDate(today.getDate() - 7); // 7 days back
        start_date = lastWeekStart.toLocaleDateString('en-CA');
        end_date = today.toLocaleDateString('en-CA');
        break;
  
      case "last_month":
        // Get the first day of the current month
        const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);        
        // Get the last day of the previous month
        const lastDayOfLastMonth = new Date(firstDayOfCurrentMonth - 1);        
        // Get the first day of the previous month
        const firstDayOfLastMonth = new Date(lastDayOfLastMonth.getFullYear(), lastDayOfLastMonth.getMonth(), 1);
        start_date = firstDayOfLastMonth.toLocaleDateString('en-CA');
        end_date = lastDayOfLastMonth.toLocaleDateString('en-CA');
        break;
  
      case "last_year":
        const lastYearStart = new Date(today.getFullYear() - 1, 0, 1);
        const lastYearEnd = new Date(today.getFullYear() - 1, 11, 31);
        start_date = lastYearStart.toLocaleDateString('en-CA');
        end_date = lastYearEnd.toLocaleDateString('en-CA');
        break;
  
      case "custom":
        if (startDate && endDate) {          
          setFilterType("");
          start_date = startDate.toLocaleDateString('en-CA');
          end_date = endDate.toLocaleDateString('en-CA');
        } else {
          alert("Please select both start and end dates for the custom filter.");
          return;
        }
        break;
  
      default:
        alert("Please select a valid filter type.");
        return;
    }
    crmData(start_date, end_date);
  };

    const handleRefresh =  () => {
      if (isLoadings) return;
      setFilterType("");
      setIsLoadings(true); 
      setFilters({}); 
      
      try {
        setTimeout(() => {
          const response = crmData();
          console.log(response);
        }, 1000);
      } catch (error) {
        console.error("Error refreshing data:", error.message);
      }
    setTimeout(() => {
        setIsLoadings(false);
      }, 1000); 
    };

    const crmData = (start_date, end_date) => {
      let email = staffemail;
      if (email == null) return;
      let data = JSON.stringify({ start_date, end_date, email });
      const prevToken = "7d68e2c38dfb38785e7a";
      const token = btoa(prevToken);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        // url: "https://crmdubai.businessbay.io/api/LeadController/totalLeadsSummary",
       url: `${environmentVariables?.crmDubaiApi}/api/LeadController/totalLeadsSummary`,
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        data: data,
      };
        axios
        .request(config)
        .then((response) => {
          const data = response?.data;
          if (data.success) {
            setTotalLeads(data?.data?.total_leads || 0);
            setNewLeads(data?.data?.new_leads || 0);
            setCardsSold(data?.data?.card_ordered || 0);
            setCardsDelivered(data?.data?.card_delivered || 0);

            const pipelineLeads = (
              Number(data?.data?.whatsap_msg_Leads || 0) +
              Number(data?.data?.answered_leads || 0) +
              Number(data?.data?.call_leads || 0) +
              Number(data?.data?.follow_up_leads || 0) +
              Number(data?.data?.intro_call_leads || 0) +
              Number(data?.data?.prospect_leads || 0) +
              Number(data?.data?.customer_leads || 0)
            );

            const totalRevenue = (
              Number(data?.data?.online_revenue || 0) +
              Number(data?.data?.offline_revenue || 0)
            );

            const rejectedLeads = (
              Number(data?.data?.failed_leads || 0) +
              Number(data?.data?.rejected_leads || 0)
            );

            setPipelineLeads(pipelineLeads);
            setTotalRevenue(totalRevenue);
            setRejectedLeads(rejectedLeads);
            setClosedLeads(data?.data?.close_leads || 0);
            setOnlineRevenue(data?.data?.online_revenue || 0);
            setOfflineRevenue(data?.data?.offline_revenue || 0);
          } else {
            setTotalLeads(0);
            setNewLeads(0);
            setCardsSold(0);
            setCardsDelivered(0);
            setRejectedLeads(0);
            setClosedLeads(0);
            setPipelineLeads(0);
            setTotalRevenue(0);
            setOnlineRevenue(0);
            setOfflineRevenue(0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }; 

    useEffect(() => {
      crmData();
    }, [staffemail]);

  return (
    <>
     <div className="col-md-12" id="total_wrapper_container">
     <div className="row">
          <div className="col-md-12">
            <div className="tile bbox Total_Titlebox_container">
           
              <div onClick={handleCopy} style={{display:"flex",margin:"10px 0px 20px 0px"}}>
              <h3 style={{color:"black",fontWeight:"bold",fontSize:"28px",margin: "4px 0px 10px 20px",textTransform:"capitalize"}}>{staffname}</h3>
                <h3 style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  color: "blue",
                  marginLeft:"10px",
                  fontWeight:"bold",
                  padding: "5px 8px",
                  borderRadius: "50px",
                  background: "#e7e7e7"
                }}>
               {agentCode ||'Not Available' }{" "}
                  {/* <i className="fa-solid fa-copy"></i> */}
                  {" "}
                </h3>
              </div>
              <h3 style={{ color: "grey", fontSize: "16px",margin:"10px 0px 10px 20px"}}>{roleName} {!!userType? `(${userType})`:''}</h3>
              <div style={{display:'flex',flexWrap:'wrap',gap:'5px',justifyContent:'space-between',maxWidth:'45%'}}>
              <div style={{ display: 'flex', alignItems: 'center', flex: '1 1 calc(40% - 10px)' }}>
                <i className="fa fa-phone" style={{fontSize: '20px',display:'flex',background:'none',color:'gray' }}></i>
                <h3 style={{color:'black'}}> {staffmobile}</h3>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', flex: '1 1 calc(40% - 10px)' }}>
                <i className="fa fa-envelope" style={{ fontSize: '20px',display:'flex',background:'none',color:'gray' }}></i>
                <h3 style={{color:'black'}}> {staffemail}</h3>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', flex: '1 1 calc(40% - 10px)' }}>
                <i className="fa fa-location-dot" style={{ fontSize: '20px',display:'flex',background:'none',color:'gray'  }}></i>
                <h3 style={{color:'black'}}>{country}</h3>
              </div>

              <div style={{ display: 'flex', alignItems: 'center', flex: '1 1 calc(40% - 10px)' }}>
                <i className="fa fa-calendar" style={{ fontSize: '20px',display:'flex',background:'none',color:'gray'  }}></i>
                {t("Registration_date")}:<h3 style={{color:'black',padding: '0px 0px 0px 5px'}}> {createdAt}</h3>
              </div>
              </div>
            </div>
          </div>
          {/* {renderEnquiryComponent()} */}
        </div>
        </div>




<div style={{ display: "flex", justifyContent: "flex-end", gap: "10px", marginBottom: "20px" }}>
        <div
          className="requestcardfilterstyle"
          onClick={!isLoadings ? handleRefresh : null}
          style={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            width: "44px",
            height: "44px",
            cursor: isLoadings ? "not-allowed" : "pointer",
            color: isLoadings ? "gray" : "blue",
          }}
        >
          {isLoadings ? (
            <CircularLoader size={24} />
          ) : (
            <RefreshIcon style={{ fontSize: "24px" }} />
          )}
        </div>

        {/* Dropdown for predefined filters */}
     
        {/* Custom Date Picker Button */}
        <div style={{ position: 'relative' }}>
          <button
            className="btn btn-primary"
            onClick={() => { setIsDatePickerVisible(true); setFilterType(""); }} // Open the date picker and set filter type to custom
            style={{
              padding: "8px 12px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "5px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {t("Custom_date_range")}
          </button>

          {/* Custom Date Picker */}
          {isDatePickerVisible  && (
            <div style={{ position: "absolute", top: '-2px', right: '0', zIndex: '2' }}>
              <DatePicker
                selectsRange
                startDate={startDate}
                endDate={endDate}
                onChange={handleCustomDateChange}
                dateFormat="yyyy-MM-dd"
                placeholderText="Select a custom date range"
                inline
                style={{
                  padding: "8px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  fontSize: "14px",
                }}
              />
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                <button
                  className="btn btn-secondary"
                  onClick={() => setIsDatePickerVisible(false)} // Close the date picker
                  style={{
                    padding: "8px 12px",
                    backgroundColor: "#6c757d",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  {t("Close")}
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => { handleSubmit("custom"); setIsDatePickerVisible(false); }} // Apply the date filter and close the date picker
                  style={{
                    padding: "8px 12px",
                    backgroundColor: "#28a745",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  {t("Apply")}
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="filter-dropdown">
          <select
            className="form-select"
            onChange={(e) => handleFilterChange(e.target.value)} // Update filterType state
            value={filterType || "Filter"} // Show "Filter" if filterType is empty
            style={{
              padding: "8px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              fontSize: "14px",
              width: "200px",
              marginRight: (isDatePickerVisible && filterType === "custom") ? '70px' : ''
            }}
          >
            <option value="Filter" disabled>{t("Select_filter")}</option>
            {Object.keys(filterNames).map((key) => (
              <option key={key} value={key}>
                {filterNames[key]}
              </option>
            ))}
          </select>
        </div>

      </div>







  <div className="col-md-12" id="total_wrapper_container">
        <div className="row">
          <div className="col-md-3">
            <div className="tile bbox Total_Titlebox_container">
              <i className="fa-solid fa-business-time"></i>
              <h3>{t("Total_leads")}</h3>
              <h4>{totalLeads || 0}</h4>
            </div>
          </div>
          <div className="col-md-3">
            <div className="tile bbox ">
              <i className="fa-solid fa-users"></i>
              <h3>{t("New_leads")}</h3>
              <h4>{newLeads || 0}</h4>
            </div>
          </div>
          <div className="col-md-3">
            <div className="tile bbox Total_Titlebox_container">
              <i className=" fa-regular fa-handshake"></i>
              <h3>{t("Closed_leads")}</h3>
              <h4>{closedLeads || 0}</h4>
            </div>
          </div>
          <div className="col-md-3">
            <div className="tile bbox ">
              <i className="fa-solid fa-users"></i>
              <h3>{t("Pipeline_leads")}</h3>
              <h4>{pipelineLeads || 0}</h4>
            </div>
          </div>
          <div className="col-md-3">
            <div className="tile bbox Total_Titlebox_container">
              <i className="fa-solid fa-business-time"></i>
              <h3>{t("Rejected_leads")}</h3>
              <h4>{rejectedLeads || 0}</h4>
            </div>
          </div>
          <div className="col-md-3">
            <div className="tile bbox Total_Titlebox_container">
              <i className="fa-solid fa-business-time"></i>
              <h3>{t("Cards_sold")}</h3>
              <h4>{cardsSold || 0}</h4>
            </div>
          </div>
          <div className="col-md-3">
            <div className="tile bbox Total_Titlebox_container">
              <i className=" fa-regular fa-handshake"></i>
              <h3>{t("Cards_delivered")}</h3>
              <h4>{cardsDelivered || 0}</h4>
            </div>
          </div>
          <div className="col-md-3">
            <div className="tile bbox Total_Titlebox_container">
              <i className="fa-solid fa-business-time"></i>
              <h3>{t("Total_revenue")}</h3>
                <h4>{currencySymbol}{totalRevenue || 0}</h4>
                <div className='revenue' style={{display:"inline-flex"}}>
                <h3 style={{margin:"0px 40px 0px 0px"}}>{t("Online")}<br/><b>{currencySymbol}{onlineRevenue || 0}</b></h3>  
                <h3 style={{marginTop:"0px"}}>{t("Offline")}<br/><b>{currencySymbol}{offlineRevenue || 0}</b></h3> 
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewStaffDetails;