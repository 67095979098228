import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const language = localStorage.getItem("langchosed") || "ens";

i18n.use(initReactI18next).init({
  resources: {
    ar: { translation: require("./language/arbic.json") },
    en: { translation: require("./language/english.json")}
  },

  lng: language,
  fallbackLng: "en",
  interpolation: { escapeValue: false }
});

export default i18n;
