import React, { useContext, useEffect, useState, useRef } from "react";
import PageName from "../Utils/PageName";
import { environmentVariables } from "../../config/env.config";

import axios from "axios";
import { userContext } from "../../context/userContext";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import Swal from 'sweetalert2';
import {useTranslation} from "react-i18next";

function AddShipping() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);

  const [isCurrency, setIsCurrency] = useState(false);
  const [Charges, setCharges] = useState("");
  const [shortform, setShortform] = useState("");
  const [isStatus, setIsStatus] = useState(false);
  const [Country, setCountry] = useState("");
  const [currencyValue, setCurrencyValue] = useState("");
  const [activate, setActivate] = useState(false);
  const createCurrencyModalRef = useRef(null);

  const [isUpdate, setIsUpdate] = useState(false);
  const [currencyId, setCurrencyId] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchCharges = async () => {
    try {
      axios
        .get(`${baseUrl}api/v1/shipping/fetchShippingCharges`, {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((response) => {
          setData(response?.data?.result);
          setIsLoading(false)
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false)
        });
    } catch (err) {}
  };

  useEffect(() => {
    fetchCharges();
    return () => {
      $(createCurrencyModalRef.current).off("hidden.bs.modal");
    };
  }, []);

  const handleClearState = () => {
    try {
      // e.preventDefault();
      setCharges("");
      setCountry("");
      setIsUpdate(false);
      setCurrencyId("");
    } catch (err) {
      console.log(err, "erororo");
    }
  };

  const handleEdit = (el) => {
    try {
      setIsUpdate(true);
      setCurrencyId(el?.id);
      setCharges(el?.charge);
      setCountry(el?.country);
      setShortform(el?.shortform)
      $(createCurrencyModalRef.current).modal("show");
    } catch (err) {
      console.log(err, "Errorr ");
    }
  };

  const handleDelete = async (d) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
  
      if (result.isConfirmed) {
      let { data: res } = await axios.delete(
        `${baseUrl}api/v1/shipping/deleteShippingCharges/${d?.id}`,
        {
          headers: {
            _token: userData?.token,
          },
        }
      );
      if (res && res?.success) {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    }
    fetchCharges();
    } catch (err) {
      console.log(err, "erorororor");
    }
  };
  const columns = [
    {
      name: "country",
      selector: (row) => row?.country,
      sortable: true,
    },
    {
      name: "charges",
      selector: (row) => row?.charge,
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      cell: (d) => [
        <>
          <i
            key={`trash-${d.id}`}
            className="fas fa-trash-alt"
            onClick={() => handleDelete(d)}
          ></i>{" "}
          &nbsp;&nbsp; &nbsp;{" "}
          <i
            key={`edit-${d.id}`}
            className="fas fa-edit"
            onClick={() => {
              handleEdit(d);
            }}
            style={{cursor:"pointer",fontSize:"22px", fontWeight:"100"}}
          ></i>
          ,
        </>,
      ],
    },
  ];

  const customStyles = {
    
    headRow: {
      style: {
        display: "flex",
        alignItems: "stretch",
        width: "100%",
        backgroundColor: "#E9E9E9",
        minHeight: "52px",
        borderRadius: "2px 2px 0px 0px",
        padding: "8px 25px",
        border: "none",
       
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "52px",
        fontSize:"15px",
      
      },
    },
    rows: {
      style: {
        padding: "20px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "48px ",
      },
    },
  };


  const tableData = {
    columns,
    data,
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      // console.log(isUpdate,"isUpdate@!@!@@@",currencyId ,"currencyId ")
      // if (isUpdate == false && currencyId == "") {
      //   if (currencyName == "" || currencyName?.trim() == "") {
      //     toast.error("Currency name is mandatory", {
      //       position: toast.POSITION.TOP_RIGHT,
      //       autoClose: 1000,
      //     });
      //     return;
      //   } else if (symbol == "" || symbol?.trim() == "") {
      //     toast.error("Currency symbol is mandatory", {
      //       position: toast.POSITION.TOP_RIGHT,
      //       autoClose: 1000,
      //     });
      //     return;
      //   } else if (currencyValue == "" || currencyValue?.trim() == "") {
      //     toast.error("Currency Value is mandatory", {
      //       position: toast.POSITION.TOP_RIGHT,
      //       autoClose: 1000,
      //     });
      //     return;
      //   }
      // }

      const numericCurrencyName = parseFloat(Charges);
      if (isNaN(numericCurrencyName)) {
        toast.error("Charges must be a valid number", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        return;
      }

      let obj = {
        charge: numericCurrencyName,
        country: Country,
      };
      let res;
      if (isUpdate == true && currencyId != "") {
        obj.currencyId = currencyId;
        res = await axios.put(`${baseUrl}api/v1/shipping/editShippingCharges/${currencyId}`, obj, {
          headers: {
            _token: userData?.token,
          },
        });
      } else {
        res = await axios.post(`${baseUrl}api/v1/shipping/shippingCharges`, obj, {
          headers: {
            _token: userData?.token,
          },
        });
      }
      if (res && res?.data && res?.data?.success) {
        fetchCharges();
        handleClearState();
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        $(createCurrencyModalRef.current).modal("hide");
      }
    } catch (err) {
      toast.error(
        err?.response?.data?.message?.message || err?.response?.data?.message,
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        }
      );
    }
  };

   const paginationOptions = {
    rowsPerPageText: t("Rows_per_page"),
    rangeSeparatorText: t("Of"),
    selectAllRowsItem: true,
    selectAllRowsItemText: t("All"),
  };
  
  return (
    <>
      <PageName PageName="Manage Currency" />
      <article>
        <div className="main">
          <div data-bs-toggle="modal" data-bs-target="#createCurrencyModal">
            <button
            id="Currency_admin_button"
              type="button"
              className="save-cta"
              onClick={(e) => setIsCurrency(!isCurrency)}
            >
             + Add Charges{" "}
            </button>
            </div>
          {isLoading ? (
            <CircularLoader size={40} />
          ) : (
          <DataTableExtensions 
          {...tableData} 
          filterPlaceholder="Search shipping charges"
          customStyles={customStyles}
          >
            <DataTable
              columns={columns}
              data={data}
              noHeader
              defaultSortField="id"
              sortIcon={<SortIcon />}
              defaultSortAsc={true}
              pagination
              highlightOnHover
              dense
               paginationComponentOptions={{
                        ...paginationOptions,
                      }}
              customStyles={customStyles}
              noDataComponent={<div style={{padding:"24px"}}>{t("No_records_found")}</div>}
            />
          </DataTableExtensions>
           )}
        </div>
        <div
          className={`modal fade ${isCurrency ? "show" : ""}`}
          id="createCurrencyModal" 
          tabIndex="-1"
          ref={createCurrencyModalRef}
        >
          <div className="modal-dialog">
            <div className="modal-content" style={{width:"100%"}}>
              <div className="modal-header">
                <h5 className="modal-title">
                  {" "}
                  {isUpdate && currencyId != "" ? "Edit" : "Add"}{" "}
                  Charges 
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => handleClearState(e)}
                ></button>
              </div>
              <div className="modal-body" id="Create_newCurrency_Container">
                <div className="container">
                  <div className="row" id="create_new_currency_form">
                    <div className="col-md-6" id="create_new_currency_Inputfield">
                      <div className="mb-2">
                        <input
                          type="number"
                          placeholder="Charges"
                          value={Charges}
                          className="form-control"
                          onChange={(e) => setCharges(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6" id="create_new_currency_Inputfield">
                      <div className="mb-2">
                        <input
                          type="text"
                          placeholder="Country"
                          className="form-control"
                          value={Country}
                          onChange={(e) => setCountry(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer" id="Updatee_config_Button_Wrapper">
                <button
                id="Update_config_Button"
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => handleSubmit(e)}
                >
                  {isStatus ? (
                    <CircularLoader size={20} />
                  ) : isUpdate && currencyId != "" ? (
                    "Update"
                  ) : (
                    "ADD"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}

export default AddShipping;
