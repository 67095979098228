import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import { environmentVariables } from "../../config/env.config";
import { userContext } from "../../context/userContext";
import { currencyContext } from "../../context/currencyContext.js";
import PageName from "../Utils/PageName";
import successright from '../../Images/check.png'
import { json, useNavigate } from "react-router";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import { useTranslation } from "react-i18next";


const DpoPaymentPlan = () => {
   const { t } = useTranslation();
    const [paymentData, setPaymentData] = useState(null);
    const [amount,setAmount]= useState(null)
    const [isVerifying, setIsVerifying] = useState(true);
    const [DeliveryDate, setDeliveryDate] = useState(null);
    const { userData } = useContext(userContext);
    const [paymentPopUp, setPaymentPopUp] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const navigate = useNavigate();
    const { search } = location;
    // const urlParams = new URLSearchParams(search);
    const urlParams = new URLSearchParams(window.location.search);
    const transactionToken = urlParams.get('TransactionToken');
    const companyRef = urlParams.get('CompanyRef');
    const TransID = urlParams.get('TransID')
    const [ttransID,setTtransID]=useState("NA");
    let planData = JSON.parse(localStorage.getItem("plan_pay"));
  

    const handleClosePopUp = () => {
        setPaymentPopUp(false);
        if (paymentSuccess) {
          navigate("/plans");
        } else {
          navigate("/plans");
        }
      };

  const handleRedirect = async () => {
    // Extract transaction token and company reference from URL params
    const urlParams = new URLSearchParams(window.location.search);
    const plan_id = urlParams.get('plan_id')
   

    if (transactionToken && companyRef) {
      try {
        const verifyResponse = await fetch(`${environmentVariables?.apiUrl}api/v1/payment/VerifyToken`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            transaction_token: transactionToken
          }),
        })
        if (!verifyResponse.ok) {
          throw new Error('Failed to verify payment');
        }
        const verifyData = await verifyResponse.json();
        setPaymentData(verifyData.result.API3G);
        setAmount(verifyData?.result?.API3G?.TransactionFinalAmount?._text);

        setTimeout(async () => {
          try {
            const paymentOrderResponse = await fetch(`${environmentVariables?.apiUrl}api/v1/plan/create_plan_orders`, {
              method: 'POST',
              headers: {
                _token: userData?.token,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                plan_id: urlParams.get('plan_id'),
                plan_name: urlParams.get('plan_name')||'NA',
                order_id: Date.now(),
                card_number: "999",
                card_exp_month: "33",
                price: 10,
                price_currency: "USD",
                txn_id: ttransID || 'NA',
                payment_status: "success",
                receipt: "receipt_info",
                store_id: 1,
                payment_type: "DPO Payment",
                payment_data: "",
                name: userData?.created_by != 0 ? userData?.parentUser?.name : userData?.name,
                email: userData?.created_by != 0 ? userData?.parentUser?.email : userData?.email,
                user_id: userData?.created_by != 0 ? userData?.created_by : userData?.id,
              }),
            });
            console.log(paymentOrderResponse,"paymentOrderResponse")


            if (!paymentOrderResponse.ok) {
              throw new Error('Failed to process payment order');
            }
  
            const paymentOrderData = await paymentOrderResponse.json();
            console.log('Payment order processed successfully:', paymentOrderData);
  
            setPaymentPopUp(true);
            setPaymentSuccess(true);
          } catch (error) {
            console.error('Error processing payment order:', error);
          }
        }, 2000);
      } catch (error) {
        console.error('Error verifying/payment order:', error);
      } finally {
        setIsVerifying(false);
      }
    } else {
      setIsVerifying(false);
    }
  };
  useEffect(() => {
    handleRedirect();
    setTtransID(TransID)
  }, []);
 
  return (
    <>
    <PageName PageName="DPO Payment" />
    {isVerifying ? (
      <div className="loader-container">
        <CircularLoader />
      </div>
    ) : (
      <form>
        <button
          type="submit"
          className="btn try_theme_btn mt-3 mx-auto d-block"
        >
          <CircularLoader size={20} />
        </button>
      </form>
    )}

    {paymentPopUp && (
      <div className="popup-outer">
        <div className="popup-overlay"></div>
        <div className="popup-inner">
          <div className="fs-1 payment_heaader">
            {/* {paymentSuccess ? "Payment Successful" : "Payment Failed"} */}
          </div>
          <img loading="lazy" src={successright} className="" />
          <div className="inner-box mt-4">
            <h1 className="name">{t("Payment_details")}</h1>
            <div className="orderbox">
              <ul className="list-group mb-3">
                <li className="list-group-item d-flex justify-content-between">
                  <span>{t("Receiver")}</span>
                  <strong>{t("Businessbay")}</strong>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <span>{t("Payment_method_type")}</span>
                  <strong>{t("Dpo_payment")}</strong>
                </li>

                <li className="list-group-item d-flex justify-content-between">
                  <span>{t("Amount_paid")}</span>
                  <strong>{paymentData?.TransactionCurrency?._text} {paymentData?.TransactionAmount?._text}</strong>
                </li>

                <li className="list-group-item d-flex justify-content-between">
                  <span>{t("Transaction_id")}</span>
                  <strong>{ttransID}</strong>
                </li>
              </ul>
              <button
                type="submit"
                className="btn try_theme_btn mx-auto d-block submitclose"
                onClick={handleClosePopUp}
              >
                {t("Close")}
              </button>
            </div>
            <div className="order-box">
              {/* Your payment details here */}
            </div>
          </div>
        </div>
      </div>
    )}
  </>
  );
};
export default DpoPaymentPlan;