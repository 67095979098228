import React, { useState } from "react";
import "./Subscribe.css";
import subsImg from "../../../Images/subs-img.png";
import Swal from "sweetalert2";
import CircularLoader from "../../../Backend/Components/CircularLoader/CircularLoader";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { environmentVariables } from "../../../config/env.config";
import { useNavigate } from "react-router-dom";
const Subscribe = () => {
  const navigate = useNavigate(null);
  const [email, setEmail] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  
  const [message, setMessage] = useState("");
  function isValidEmail(email) {
    // Define the regular expression pattern for a valid email address
    var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Use the test method to check if the email matches the pattern
    var isValid = pattern.test(email);

    // If isValid is true, the email is valid; otherwise, it's not
    return isValid;
  }
  const handleSubmit = () => {
    if (!isValidEmail(email)) {
      setErrorEmail(true);
      toast.error("Enter Valid Email", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } else {
      setIsSubmit(true);
      let data = {
        email: email,
        textareaValue: message,
      };
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${environmentVariables?.apiUrl}api/v1/info/saveContactInfo`,
        data: data,
      };

      axios 
        .request(config)
        .then((response) => {
          setEmail("");
          setMessage("");
          setIsSubmit(false);
          // Swal.fire("Success", "Thank you for contacting us!", "We have received your message. We will reach you out immediately!");
          Swal.fire({
            icon: "success",
            title: "Thank you for contacting us!",
            text: "We have received your message. We will reach you out immediately!",
            // icon: "question"
          });
         
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message || "Something went wrong", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          Swal.fire(
            "error",
            err?.response?.data?.message || "Something went wrong!",
            "error"
          );
          setIsSubmit(false);
        });
    }
  };
  return (
    <>
      <div className="container-fluid black">
        <div className="container subscribe-main">
          <h2>Get your NFC business card</h2>
          <h5>
            Just share your details and our team will get back to you shortly.
          </h5>
          <div className="subscribe" style={{display:"flex"}}>
            <img loading="lazy" src={subsImg} />
            <input
              onChange={(e) => {
                setEmail(e.target.value);
                setErrorEmail(false);
              }}
              value={email}
              type="text"
              placeholder="Your email address"
            />
         
          </div>
        <div className="mailerrormsg">
        {errorEmail && (
              <span className="error-message">Enter a valid email</span>
            )}
        </div>
          <div className="subscribe">
            <input
              onChange={(e) => setMessage(e.target.value)}
              type="textarea"
              value={message}
              placeholder="Message"
            />
          </div>
          <button
            style={{ cursor: "pointer" , marginTop:"5px" }}
            onClick={handleSubmit}
            className="blue-button-subscribe"
          >
            {isSubmit ? <CircularLoader size={20} /> : `Contact Us`}
          </button>
          {/* <h4>
            After clicking “Get started” button you agree with our{" "}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/term-conditions")}
              className="blue-text"
            >
              Terms and Conditions.
            </span>
          </h4> */}
        </div>
      </div>
    </>
  );
};

export default Subscribe;
