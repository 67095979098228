import React, { useContext, useState, useRef } from "react";
import { userContext } from "../../../../context/userContext";
import { environmentVariables } from "../../../../config/env.config";
import { useEffect } from "react";
import "./Theme14.css";
import axios from "axios";
import TopHeaderImage from "../../../../Images/Restaurant/TopImage.png";
import MenuIcon from "../../../../Images/Restaurant/Tabmenu.png";
import MenuIcon2 from "../../../../Images/Restaurant/Tabmenu2.png";
import OrderIcon from "../../../../Images/Restaurant/tabOrder.png";
import OrderIcon2 from "../../../../Images/Restaurant/tabOrder2.png";
import OpeningIcon from "../../../../Images/Restaurant/tabopeninghr.png";
import OpeningIcon2 from "../../../../Images/Restaurant/tabopeninghr2.png";
import GalleryIcon from "../../../../Images/Restaurant/tabimage-gallery.png";
import GalleryIcon2 from "../../../../Images/Restaurant/tabimage-gallery2.png";
import SocialIcon from "../../../../Images/Restaurant/tabsocial-media.png";
import SocialIcon2 from "../../../../Images/Restaurant/tabsocial-media2.png";
import ContactIcon from "../../../../Images/Restaurant/tabcontact-us.png";
import ContactIcon2 from "../../../../Images/Restaurant/tabcontact-us2.png";
import AboutIcon from "../../../../Images/Restaurant/tabinfo-button.png";
import AboutIcon2 from "../../../../Images/Restaurant/tabinfo-button2.png";
import AddmoreIcon from "../../../../Images/Restaurant/addmoreicon.png";
import PhoneContact from "../../../../Images/Restaurant/phoneIcon.png";
import AddressIcon from "../../../../Images/Restaurant/address.png";
import MailIDIcon from "../../../../Images/Restaurant/mailid.png";
import WebsiteIcon from "../../../../Images/Restaurant/website.png";
import { currencyContext } from "../../../../context/currencyContext.js";
import { useTranslation } from "react-i18next";
import {
  AboutParagraph, AboutText, AboutTextWrapper,
  AddorderIncrementWrapper, AddorderText, AddorderTextContainer, AddorderValue, Addorderseller, ChefName, ContactIconWrapper, ContactTabIcon,
  ContactTabValue, Container, Cross, CrossWrapper, Designation, GalleryImage, GalleryImageWrapper, GalleryWrapper, Image, IncrementSpan, MenuAddMoreWrapper,
  MenuAddmoreIcon, MenuBestSaller, MenuCardWrapper, MenuDeatilsContainer, MenuImage, MenuImageWrapper, MenuName, MenuNameWrapper, MenuPrice, MenuPriceWrapper,
  MenuStar, MenuStarWrapper, MenuStarvalue, MenuNonvageFilter, MenuNonvagefilterWrapper, MenuVageFilter, MenuVagefilterWrapper, MondaySatWrapper, MondayText, MondayTextWrapper, MondayTextvalue, Name,
  NewTabGallaryWrapper, NewTabGallaryWrapperContainer, NewTabMenuHeadingWrapper, NewTabMenuText, NewTabMenuWrapper, NewTabOrderMenuWrapper, NewTabOrderWrapper,
  OpeningHours, OpeningHoursContainer, OrderCardWrapper, OrderCardWrapperContainer, OrderImage, OrderImageWrapper, OrderNameWrapper, OrderParagraph,
  OrderStarImageWrapper, OrderStatus, OrderStatusCardWrapper, OrderStatusCardWrapper2, OrderStatusKeyValueWrapper, OrderStatusValue, OrderStatuskey,
  OrderTextWrapper, Ordername, OrderstarImage, OrderstatusContainernew, SocialmediaIconn, SundayText, SundayTextValue, SundayWrapper, TabContactPhoneWrapper,
  TabImage, TabImageWrapper, TabSocialContainer, TabSocialIConWrapper, TabText, TabTextWrapper, TabWrapper, TabWrapperContainer, TextDetails, TopImageWrapper,
  Wrapper, SpancurrencySymbol
} from "./Theme14ExtStyleComponent";
import { getContactInfo, getServicesArray } from "../../../../utils/utility.js";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const DineIn = styled.div`
    color: #fff;
    font-size: 10px;
    background: linear-gradient(to right, #3D8C20, #7BD25B);
    padding: 7px;
    border-radius: 50px 0 0 50px;
    width: 100px;
    right: 0;
    top: 20px;
    position: absolute;
      @media(max-width:480px) {
              position: absolute;
              right: 0;
              top: -35px!important;
            }
`;
const LocationInfo = styled.div`
    color: #fff;
    font-size: 12px;
    background: #252525bd;
    padding: 7px 15px;
    margin-top: 5px;
    border-radius: 50px;
    border: 1px solid #CD2F34;
`;

// import "./Template10.css";
const Theme14 = (props) => {
  const { userData } = useContext(userContext);
  const { currencyObjData } = useContext(currencyContext);
  const [activeTab, setActiveTab] = useState("menu");
  const { t } = useTranslation();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    getBlockOrder();
  }, [props?.businessObj, props.businessId, props.orderOfBlocks]);
  const getBrandNameForSocial = (brand) => {
    if (brand == "gmail") {
      return "google";
    } else return `${brand}`;
  };
  const getBlockOrder = async () => {
    // const handleGetBlock = async () => {
    if (props.orderOfBlocks) {
      let orders = {};
      props.orderOfBlocks.map((val) => {
        if (val.content == "Description") {
          orders["description"] = val?.position;
        } else if (val.content == "Contact Info") {
          orders["contact_info"] = val?.position;
        } else if (val.content == "Appointment") {
          orders["appointment"] = val?.position;
        } else if (val.content == "Service") {
          orders["service"] = val?.position;
        } else if (val.content == "Gallery") {
          orders["gallery"] = val.position;
        } else if (val.content == "Contact Form") {
          orders["contact_form"] = val.position;
        } else if (val.content == "Testimonials") {
          orders["testimonials"] = val.position;
        } else if (val.content == "Social") {
          orders["social"] = val.position;
        } else if (val.content == "Business Hour") {
          orders["business_hour"] = val.position;
        }
      });
      props.setOrder(orders);
    } else {
      try {
        let id = props?.businessObj?.id || props.businessId;
        if (props?.businessObj?.id) {
          let { data: get } = await axios.get(
            `${environmentVariables?.apiUrl}api/v1/business/FetchBlock?business_id=${id}`,
            {
              headers: {
                _token: userData?.token,
              },
              // withCredentials: true,
            }
          );
          // console.log(get?.data?.order, "order data");
          props.setOrder(get?.data?.order);
        }

        // console.log(get.data.order,"Getetgwef")
      } catch (err) {
        console.log(err, "error in template");
      }
    }
    // };
  };


  const getCorrectDay = (day) => {
    const dayMappings = {
      "Monday": t("Monday"),
      "Tuesday": t("Tuesday"),
      "WednesDay": t("Wednesday"),
      "thrusdayDay": t("Thursday"),
      "fridayDay": t("Friday"),
      "saturdayDay": t("Saturday"),
      "sundayDay": t("Sunday")
    };

    return dayMappings[day] || day;
  };

  const getOpeningHours = (businessHourObject) => {
    let daysOfWeek = ["Monday", "Tuesday", "WednesDay", "thrusdayDay", "fridayDay", "saturdayDay", "sundayDay"];
    let startDay = null;
    let endDay = null;
    let closedDays = [];

    // Find the first and last true values in businessHourObject
    for (let day of daysOfWeek) {
      if (businessHourObject[`is${day}`]) {
        if (!startDay) startDay = day;
        endDay = day;
      } else {
        closedDays.push(day);
      }
    }

    // Replace misspelled days with correct spellings
    startDay = getCorrectDay(startDay);
    endDay = getCorrectDay(endDay);

    // Construct opening hours string
    let openingHours = "";
    if (startDay && endDay) {
      openingHours = startDay === endDay ? startDay : `${startDay} - ${endDay}`;
    } else if (startDay) {
      openingHours = startDay;
    }

    let closedDaysString = "";
    if (closedDays.length > 0) {
      closedDaysString = closedDays.map(day => getCorrectDay(day)).join(", ");
    }

    return { openingHours, closedDays: closedDaysString };
  };

  const { openingHours, closedDays } = getOpeningHours(props.businessHourObject);
  return (
    <div>
      <div class="main-temaplt" id="temp14">
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ width: "420px", background: props?.dynamicMainBackgroundColor || "" }} className="col-12 wtemplate">

              <Wrapper>
                <TopImageWrapper InternalTheme>
                  <Image src={props?.bannerImageUrl
                        ? props.getImage(props.bannerImageUrl)
                        : environmentVariables?.s3BannerUrlTheme14} />
                  <TextDetails InternalTheme>
                    {props?.isDine && <DineIn>{t("Dine_in")}</DineIn>}
                    {props?.isShowBusinessName ? (<Name InternalTheme>{props.title}</Name>) : null}
                    {props?.onState?.services && (
                      <div className="mainservicebox">

                        {props?.servicesArray && props?.servicesArray?.length > 0 && (
                          <ul className="servesbox">
                            {getServicesArray(props.servicesArray)
                              .filter(val => val?.name)
                              .map((val, index, array) => (
                                <React.Fragment key={val?.name}>
                                  <li>{val.name}</li>
                                  {index < array.length - 1 && <li key={`${val?.name}-dash`}>-</li>}
                                </React.Fragment>
                              ))}
                          </ul>
                        )}
                      </div>
                    )}
                    {props?.isShowAddress ? (
                      <LocationInfo style={{ border: props?.dynamicCtaColor ? `1px solid ${props?.dynamicCtaColor}50` : "", background: props?.dynamicMainBackgroundColor || "", color: props?.dynamicTextHeadingColor || "" }}>

                        {getContactInfo(props?.contactInfo)}
                      </LocationInfo>
                    ) : null}
                    {/* {props?.isDine && <DineIn>Dine-in available</DineIn>} */}
                    {/* <ChefName>{props.subTitle || "Name"}</ChefName>
                    <Designation> {props.designation || "Designation"}</Designation> */}
                  </TextDetails>
                </TopImageWrapper>

                <TabWrapperContainer style={{ background: props?.dynamicMainBackgroundColor || "" }}>

                  {props.onState.restaurantmenu && (

                    <TabWrapper

                      active={activeTab === "menu"}
                      onClick={() => handleTabClick("menu")}
                      background={activeTab === "menu" ? "#FCEBEB" : "#F3F3F3"}
                      color={activeTab === "menu" ? "#FCEBEB" : "#F3F3F3"}
                      style={{ background: (activeTab === 'menu' && props?.dynamicCtaColor) ? `${props?.dynamicCtaColor}70` : "" }}
                    >
                      <TabImageWrapper>
                        <TabImage src={activeTab === "menu" ? MenuIcon2 : MenuIcon} />
                      </TabImageWrapper>
                      <TabTextWrapper>

                        <TabText
                          color={activeTab === "menu" ? "#DE3036" : "#A2A3A4"}
                          style={{color: activeTab==='menu' ? props?.dynamicCtaTextColor : ""}}
                        >{props?.menuHeading || t("Menu")}</TabText>
                      </TabTextWrapper>

                    </TabWrapper>
                  )}
                  {/* {props.onState.googlereview && (
                    <TabWrapper
                      active={activeTab === "review"}
                      onClick={() => handleTabClick("review")}
                      background={activeTab === "review" ? "#FCEBEB" : "#F3F3F3"}
                    >
                      <TabImageWrapper>
                        <TabImage
                          src={activeTab === "review" ? ReviewIcon2 : ReviewIcon}
                        />
                      </TabImageWrapper>
                      <TabTextWrapper>
                        <TabText
                          color={activeTab === "review" ? "#DE3036" : "#A2A3A4"}
                        >Review & Rating</TabText>
                      </TabTextWrapper>

                    </TabWrapper>
                  )} */}
                  {props.onState.orders && (
                    <TabWrapper
                      active={activeTab === "order"}
                      onClick={() => handleTabClick("order")}
                      background={activeTab === "order" ? "#FCEBEB" : "#F3F3F3"}
                      style={{ background: (activeTab === 'order' && props?.dynamicCtaColor) ? `${props?.dynamicCtaColor}70` : "" }}
                    >
                      <TabImageWrapper>
                        <TabImage
                          src={activeTab === "order" ? OrderIcon2 : OrderIcon}
                        />
                      </TabImageWrapper>
                      <TabTextWrapper>
                        <TabText
                          color={activeTab === "order" ? "#DE3036" : "#A2A3A4"}
                          style={{color: activeTab==='order' ? props?.dynamicCtaTextColor : ""}}
                        >{props?.orderHeading || t("Order")}</TabText>
                      </TabTextWrapper>

                    </TabWrapper>
                  )}
                  {props.onState.businessHours && (
                    <TabWrapper
                      active={activeTab === "openinghours"}
                      onClick={() => handleTabClick("openinghours")}
                      background={activeTab === "openinghours" ? "#FCEBEB" : "#F3F3F3"}
                      style={{ background: (activeTab === 'openinghours' && props?.dynamicCtaColor) ? `${props?.dynamicCtaColor}70` : "" }}
                    >
                      <TabImageWrapper>
                        <TabImage src={activeTab === "openinghours" ? OpeningIcon2 : OpeningIcon} />
                      </TabImageWrapper>
                      <TabTextWrapper>
                        <TabText
                          color={activeTab === "openinghours" ? "#DE3036" : "#A2A3A4"}
                          style={{color: activeTab === "openinghours" ? props?.dynamicCtaTextColor : ""}}
                        >{props?.businessHourHeading || t("Opening_hours")}</TabText>
                      </TabTextWrapper>

                    </TabWrapper>
                  )}
                  {props.onState.gallery && (
                    <TabWrapper
                      active={activeTab === "Gallery"}
                      onClick={() => handleTabClick("Gallery")}
                      background={activeTab === "Gallery" ? "#FCEBEB" : "#F3F3F3"}
                      style={{ background: (activeTab === 'Gallery' && props?.dynamicCtaColor) ? `${props?.dynamicCtaColor}70` : "" }}
                    >
                      <TabImageWrapper>
                        <TabImage src={activeTab === "Gallery" ? GalleryIcon2 : GalleryIcon} />
                      </TabImageWrapper>
                      <TabTextWrapper>
                        <TabText
                          color={activeTab === "Gallery" ? "#DE3036" : "#A2A3A4"}
                          style={{color: activeTab==='Gallery' ? props?.dynamicCtaTextColor : ""}}
                        >{props?.galleryHeading || t("Gallery")}</TabText>
                      </TabTextWrapper>

                    </TabWrapper>
                  )}
                  {props.onState.socials && (
                    <TabWrapper
                      active={activeTab === "Socialmedia"}
                      onClick={() => handleTabClick("Socialmedia")}
                      background={activeTab === "Socialmedia" ? "#FCEBEB" : "#F3F3F3"}
                      style={{ background: (activeTab === 'Socialmedia' && props?.dynamicCtaColor) ? `${props?.dynamicCtaColor}70` : "" }}
                    >
                      <TabImageWrapper>
                        <TabImage src={activeTab === "Socialmedia" ? SocialIcon2 : SocialIcon} />
                      </TabImageWrapper>
                      <TabTextWrapper>
                        <TabText
                          color={activeTab === "Socialmedia" ? "#DE3036" : "#A2A3A4"}
                          style={{color: activeTab==='Socialmedia' ? props?.dynamicCtaTextColor : ""}}
                        >{props?.socialHeading || t("Social_media")}</TabText>
                      </TabTextWrapper>

                    </TabWrapper>
                  )}
                  {props.onState.contactInfo && (
                    <TabWrapper
                      active={activeTab === "Contactinfo"}
                      onClick={() => handleTabClick("Contactinfo")}
                      background={activeTab === "Contactinfo" ? "#FCEBEB" : "#F3F3F3"}
                      style={{ background: (activeTab === 'Contactinfo' && props?.dynamicCtaColor) ? `${props?.dynamicCtaColor}70` : "" }}
                    >
                      <TabImageWrapper>

                        <TabImage src={activeTab === "Contactinfo" ? ContactIcon2 : ContactIcon} />

                      </TabImageWrapper>
                      <TabTextWrapper>
                        <TabText
                          color={activeTab === "Contactinfo" ? "#DE3036" : "#A2A3A4"}
                          style={{color: activeTab==='Contactinfo' ? props?.dynamicCtaTextColor : ""}}
                        >{props?.contactHeading || t("Contact_info")}</TabText>
                      </TabTextWrapper>
                    </TabWrapper>
                  )}

                  <TabWrapper
                    active={activeTab === "about"}
                    onClick={() => handleTabClick("about")}
                    background={activeTab === "about" ? "#FCEBEB" : "#F3F3F3"}
                    style={{ background: (activeTab === 'about' && props?.dynamicCtaColor) ? `${props?.dynamicCtaColor}70` : "" }}
                  >
                    <TabImageWrapper>
                      <TabImage
                        src={activeTab === "about" ? AboutIcon2 : AboutIcon} />

                    </TabImageWrapper>
                    <TabTextWrapper>
                      <TabText
                        color={activeTab === "about" ? "#DE3036" : "#A2A3A4"}
                        style={{color: activeTab==='about' ? props?.dynamicCtaTextColor : ""}}
                      > {props?.descriptionHeading || t("About")}</TabText>
                    </TabTextWrapper>

                  </TabWrapper>


                </TabWrapperContainer>
              </Wrapper>
              {activeTab === "menu" && (
                <div>
                  <NewTabMenuWrapper>
                    {/* <NewTabMenuHeadingWrapper>
                      <NewTabMenuText InternalTheme>Menu!!</NewTabMenuText>
                    </NewTabMenuHeadingWrapper> */}
                    <Container InternalTheme>
                      <MenuDeatilsContainer InternalTheme>
                        {Array.isArray(props?.restaurantArray) && props.restaurantArray.length === 0 ? (
                          <div style={{
                            fontFamily: "Plus Jakarta Sans",
                            fontWeight: 400,
                            fontSize: "20px",
                            color: props?.dynamicTextHeadingColor || "#101010",
                            textAlign: 'center',
                            height: '500px',
                            display: 'flex',
                            // alignItems: 'center',
                            justifyContent: 'center',
                            position: 'absolute',

                            }}>
                              We serve delectable dishes made from the finest ingredients in a welcoming atmosphere. Our menu caters to all tastes, ensuring a memorable dining experience, whether you're here for a quick bite or a special occasion.
                            </div>
                          ) : (
                        props?.restaurantArray?.map((menuItem, index) => (
                          <MenuCardWrapper key={index} style={{background: props?.dynamicBackgroundColor || ""}}>
                            <MenuImageWrapper>
                              {/* <MenuImage src={`${environmentVariables?.apiUrl}uploads/${menuItem?.image}`}  /> */}
                              <MenuImage src={menuItem?.imageView ? menuItem.imageView : `${environmentVariables?.s3UrlBackendUrl}${menuItem?.image}`} />

                                {/* {index === 0 && <MenuBestSaller src={BestSellerIcon} />} */}
                                {/* <MenuVagefilterWrapper>
              <MenuVageFilter></MenuVageFilter>
            </MenuVagefilterWrapper> */}
                                {menuItem.category !== "veg" ? (
                                  <MenuNonvagefilterWrapper>
                                    <MenuNonvageFilter />
                                  </MenuNonvagefilterWrapper>
                                ) : (
                                  <MenuVagefilterWrapper>
                                    <MenuVageFilter />
                                  </MenuVagefilterWrapper>
                                )}
                              </MenuImageWrapper>
                              <MenuNameWrapper>
                                <MenuName InternalTheme style={{ color: props?.dynamicTextDescriptionColor || "" }}>{menuItem?.name}</MenuName>
                              </MenuNameWrapper>
                              {/* <MenuStarWrapper>
            <MenuStar src={StarIcon} />
            <MenuStarvalue>{menuItem?.rating}</MenuStarvalue>
          </MenuStarWrapper> */}
                              <MenuPriceWrapper>
                                <MenuPrice InternalTheme style={{ color: props?.dynamicTextDescriptionColor || "" }}>
                                  <SpancurrencySymbol>{currencyObjData?.symbol}</SpancurrencySymbol>
                                  {typeof menuItem?.price === "string"
                                    ? menuItem.price.split(",")[0]
                                    : menuItem?.price[0]}
                                </MenuPrice>                              <MenuAddMoreWrapper>
                                  <MenuAddmoreIcon InternalTheme src={AddmoreIcon} />
                                </MenuAddMoreWrapper>
                              </MenuPriceWrapper>
                            </MenuCardWrapper>
                          )))}
                      </MenuDeatilsContainer>
                    </Container>
                  </NewTabMenuWrapper>
                </div>
              )}

              {activeTab === "review" && (
                // <NewTabOrderWrapper>
                //   <NewTabMenuHeadingWrapper>
                //     <NewTabMenuText InternalTheme> Review & Rating </NewTabMenuText>
                //   </NewTabMenuHeadingWrapper>
                //   <OrderCardWrapperContainer>
                //     <OrderCardWrapper>
                //       <OrderImageWrapper>
                //         <OrderImage InternalTheme src={OrderImg} />

                //         <OrderTextWrapper>
                //           <OrderNameWrapper>
                //             <Ordername>Christina Smith</Ordername>
                //           </OrderNameWrapper>
                //           <OrderStarImageWrapper>
                //             <OrderstarImage InternalTheme src={OrderreviewIcon} />
                //           </OrderStarImageWrapper>
                //           <OrderParagraph>Eodem modo typi, qui nunc nobis videntur parum clari, fiant sollemnes futurum.  </OrderParagraph>
                //         </OrderTextWrapper>
                //       </OrderImageWrapper>
                //     </OrderCardWrapper>
                //     <OrderCardWrapper>
                //       <OrderImageWrapper>
                //         <OrderImage InternalTheme src={OrderImg} />

                //         <OrderTextWrapper>
                //           <OrderNameWrapper>
                //             <Ordername>Christina Smith</Ordername>
                //           </OrderNameWrapper>
                //           <OrderStarImageWrapper>
                //             <OrderstarImage src={OrderreviewIcon} />
                //           </OrderStarImageWrapper>
                //           <OrderParagraph>Eodem modo typi, qui nunc nobis videntur parum clari, fiant sollemnes futurum.  </OrderParagraph>
                //         </OrderTextWrapper>
                //       </OrderImageWrapper>
                //     </OrderCardWrapper>
                //   </OrderCardWrapperContainer>

                // </ NewTabOrderWrapper>
                ''
              )}

              {activeTab === "order" && (
                // <NewTabOrderMenuWrapper>
                //   <NewTabMenuHeadingWrapper>
                //     <NewTabMenuText InternalTheme>Orders</NewTabMenuText>
                //   </NewTabMenuHeadingWrapper>
                //   <OrderstatusContainernew>
                //     <OrderStatusCardWrapper2>
                //       <AddOrderContainer>
                //         <AddorderContainerWrapper>
                //           <AddorderImage InternalTheme src={OrderImageeIcon} />
                //           <Addorderseller />
                //         </AddorderContainerWrapper>
                //       </AddOrderContainer>
                //       <AddorderTextContainer>
                //         <AddorderText InternalTheme>Cherry Healthy</AddorderText>
                //         <AddorderValue InternalTheme>₹289.00</AddorderValue>
                //         <AddorderIncrementWrapper>
                //           <AddorderIncrement> <IncrementSpan>-</IncrementSpan> 14 <IncrementSpan>+</IncrementSpan></AddorderIncrement>
                //         </AddorderIncrementWrapper>
                //       </AddorderTextContainer>
                //       <CrossWrapper InternalTheme>
                //         <Cross >X</Cross>
                //       </CrossWrapper>
                //     </OrderStatusCardWrapper2>
                //     <OrderStatusCardWrapper>
                //       <OrderStatus>
                //         Order Status:
                //       </OrderStatus>
                //       <OrderStatusKeyValueWrapper>
                //         <OrderStatuskey InternalTheme>Order ID</OrderStatuskey>
                //         <OrderStatusValue InternalTheme>#FM209391</OrderStatusValue>
                //       </OrderStatusKeyValueWrapper>
                //       <OrderStatusKeyValueWrapper>
                //         <OrderStatuskey InternalTheme>Order date</OrderStatuskey>
                //         <OrderStatusValue InternalTheme>Apr 1, 2024 | 40mins</OrderStatusValue>
                //       </OrderStatusKeyValueWrapper>
                //       <OrderStatusKeyValueWrapper>
                //         <OrderStatuskey InternalTheme>Order ID</OrderStatuskey>
                //         <OrderStatusValue InternalTheme>#FM209391</OrderStatusValue>
                //       </OrderStatusKeyValueWrapper>
                //     </OrderStatusCardWrapper>
                //   </OrderstatusContainernew>

                // </NewTabOrderMenuWrapper>
                ""
              )}

              {activeTab === "openinghours" && (
                <OrderCardWrapperContainer>
                  <OpeningHoursContainer style={{ backgroundColor: props?.dynamicBackgroundColor || "" }}>
                    <OpeningHours style={{color: props?.dynamicTextDescriptionColor || ""}}>{props?.businessHourHeading || t("Opening_hours")}</OpeningHours>
                    <MondaySatWrapper>
                      <MondayTextWrapper> <MondayText InternalTheme style={{ color: props?.dynamicTextDescriptionColor || "" }}>{openingHours}</MondayText></MondayTextWrapper>
                      <MondayTextWrapper>
                        {/* <MondayTextvalue InternalTheme> 10:00 AM to 10:00 PM </MondayTextvalue> */}
                        <MondayTextvalue InternalTheme style={{ color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}90` : "" }}>{props.businessHourObject.isMonday ? `${props.businessHourObject.modayStartDate} ${t("To")} ${props.businessHourObject.modayEndDate}` : '10:00 to 18:00'}</MondayTextvalue>
                      </MondayTextWrapper>
                      <SundayWrapper>
                        <SundayText InternalTheme style={{ color: props?.dynamicTextDescriptionColor || "" }}> {closedDays} </SundayText>
                        {closedDays && (
                          <SundayTextValue InternalTheme style={{ color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}90` : "" }}>{t("Closed")}</SundayTextValue>
                        )}
                        {/* {closedDays && <p>{`CLOSED: ${closedDays}`}</p>} */}
                      </SundayWrapper>
                    </MondaySatWrapper>
                  </OpeningHoursContainer>
                </OrderCardWrapperContainer>
              )}

              {activeTab === "Gallery" && (
                <NewTabGallaryWrapperContainer>
                  <NewTabGallaryWrapper>
                    {/* <GalleryWrapper>
                    <GalleryImageWrapper>
                    <GalleryImage src={props?.galleryData}/>
                    </GalleryImageWrapper>
                    <GalleryImageWrapper>
                    <GalleryImage src={GalleryIconImage2}/>
                    </GalleryImageWrapper>
                    <GalleryImageWrapper>
                    <GalleryImage src={GalleryIconImage3}/>
                    </GalleryImageWrapper>
                    <GalleryImageWrapper>
                    <GalleryImage src={GalleryIconImage}/>
                    </GalleryImageWrapper>
                    <GalleryImageWrapper>
                    <GalleryImage src={GalleryIconImage2}/>
                    </GalleryImageWrapper>
                    <GalleryImageWrapper>
                    <GalleryImage src={GalleryIconImage}/>
                    </GalleryImageWrapper>
                   
                  </GalleryWrapper> */}
                    <GalleryWrapper>
                      {props?.galleryData.map((imageData, index) => (
                        <GalleryImageWrapper key={index}>
                          <GalleryImage src={`${environmentVariables?.s3UrlBackendUrl}gallery/${imageData.image}`} />
                        </GalleryImageWrapper>
                      ))}
                    </GalleryWrapper>
                  </NewTabGallaryWrapper>
                </NewTabGallaryWrapperContainer>
              )}

              {activeTab === "Socialmedia" && (
                <TabSocialContainer>
                  {/* <TabSocialIConWrapper InternalTheme>
                  <SocialmediaIconn src={FacebookimgIcon}/>    
                  </TabSocialIConWrapper>
                  <TabSocialIConWrapper InternalTheme>
                  <SocialmediaIconn src={YoutubeimgIcon}/>    
                  </TabSocialIConWrapper> */}
                  {props.socialMediaData.map((socialMedia, index) => (
                    <TabSocialIConWrapper key={index} InternalTheme style={{ background: props?.dynamicTextHeadingColor ? `${props?.dynamicTextHeadingColor}40` : "" }}>
                      <SocialmediaIconn />
                      <i
                        className={`fa-${socialMedia?.nameOfSocial.toLowerCase() === 'star' ? 'solid' : 'brands'} fa-${getBrandNameForSocial(
                          socialMedia?.nameOfSocial.toLowerCase()
                        )}`}
                        style={{ fontSize: '34px', color: props?.dynamicTextHeadingColor || "" }}
                      ></i>
                    </TabSocialIConWrapper>
                  ))}

                </TabSocialContainer>
              )}

              {activeTab === "Contactinfo" && (
                <OrderCardWrapperContainer>
                  <OpeningHoursContainer style={{ background: props?.dynamicBackgroundColor || "" }}>
                    <AboutTextWrapper>
                      <AboutText InternalTheme style={{color: props?.dynamicTextDescriptionColor || ""}}> {props?.contactHeading || t("Contact_info")} </AboutText>
                      <TabContactPhoneWrapper InternalTheme>
                        <ContactIconWrapper InternalTheme style={{ background: props?.dynamicMainBackgroundColor || "" }}>
                          <ContactTabIcon src={PhoneContact} />
                        </ContactIconWrapper>
                        <ContactTabValue InternalTheme style={{ color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}90` : "" }}>
                          {props?.contactInfo?.contactInfoPhone}
                        </ContactTabValue>
                        <ContactTabValue InternalTheme style={{ color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}90` : "" }}>
                          {props?.contactInfo?.optionalContactInfoPhone?.split("-")?.[1]?.length > 0 && <span style={{ color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}90` : "" }}> , {props?.contactInfo?.optionalContactInfoPhone} </span>}

                          {/* {props?.contactInfo?.optionalContactInfoPhone} */}
                        </ContactTabValue>
                      </TabContactPhoneWrapper>

                      {props?.contactInfo?.contactInfoWebUrl &&
                        <TabContactPhoneWrapper InternalTheme>
                          <ContactIconWrapper InternalTheme style={{ background: props?.dynamicMainBackgroundColor || "" }}>
                            <ContactTabIcon src={WebsiteIcon} />
                          </ContactIconWrapper>
                          <ContactTabValue InternalTheme style={{ color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}90` : "" }}>
                            {props?.contactInfo?.contactInfoWebUrl}
                          </ContactTabValue>
                        </TabContactPhoneWrapper>
                      }
                      <TabContactPhoneWrapper InternalTheme>
                        <ContactIconWrapper InternalTheme style={{ background: props?.dynamicMainBackgroundColor || "" }}>
                          <ContactTabIcon src={MailIDIcon} />
                        </ContactIconWrapper>
                        <ContactTabValue InternalTheme style={{ color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}90` : "" }}>
                          {props?.contactInfo?.contactInfoEmail}
                        </ContactTabValue>

                      </TabContactPhoneWrapper>
                      {props?.contactInfo?.contactInfoAddress &&
                        <TabContactPhoneWrapper InternalTheme>
                          <ContactIconWrapper InternalTheme style={{ background: props?.dynamicMainBackgroundColor || "" }}>
                            <ContactTabIcon src={AddressIcon} />
                          </ContactIconWrapper>
                          <ContactTabValue InternalTheme style={{ color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}90` : "" }}>
                            {props?.contactInfo?.contactInfoAddress}
                          </ContactTabValue>
                          {props?.contactInfo?.contactInfoOptAddress &&
                            <ContactTabValue InternalTheme style={{ color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}90` : "" }}>
                              <p style={{ color: props?.dynamicTextDescriptionColor || "gray", fontSize: "13px", fontWeight: 600 }}>{t("Alternative_address")}</p>
                              {props?.contactInfo?.contactInfoOptAddress}
                            </ContactTabValue>}

                        </TabContactPhoneWrapper>
                      }
                    </AboutTextWrapper>
                  </OpeningHoursContainer>
                </OrderCardWrapperContainer>
              )}

              {activeTab === "about" && (
                <OrderCardWrapperContainer>
                  <OpeningHoursContainer style={{ background: props?.dynamicBackgroundColor || "" }}>
                    <AboutTextWrapper>
                      <AboutText style={{color: props?.dynamicTextDescriptionColor || ""}}>{props?.descriptionHeading || t("About")}</AboutText>
                      <AboutParagraph style={{color: props?.dynamicTextDescriptionColor ? `${props?.dynamicTextDescriptionColor}90` : ""}} 
                      dangerouslySetInnerHTML={{
                        __html: (props.description ||
                          t("Restaurant_about")).replace(/(?:\r\n|\r|\n)/g, '<br>'),
                      }}>
                        
                      </AboutParagraph>
                    </AboutTextWrapper>

                  </OpeningHoursContainer>
                </OrderCardWrapperContainer>
              )}







              {/* <article className="imgsechere sec">
                <img
                  src={bannerImage}
                  className="mx-auto d-block img-fluid coverimghere "
                  style={{width:"100%"}}
                />
              </article> */}
              {/* <div className="logoTextContainer">
               <div>
               <img
                src={logoImage}
                className="mx-auto d-block img-fluid logo-profile"
              />
              </div>
               <article className="personalinfo sec">
                <h3 className="username">Anastasia Anastasia</h3>
                <p className="titleuser">Barber, Looks Salon</p>
              </article>
              </div> */}
              {/* <article className="makeappitmneets sec"> 
                <div className="contact-form">
                  <div class="col-12 mb-3" style={{position:"relative"}}>
                    <input
                      ref={calendarInputRef}
                      type="date"
                      class="form-control clear_string"
                      placeholder="Date"
                      name="currentDate"
                    />
                        <div className="calanderIcon" onClick={openCalendar}><img src={environmentVariables?.s3UrlDarkCalenderIconDays} alt="Calendar Icon" /></div>
                  </div>
                  <div class="col-12 mb-3">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      // onChange={(e) => setSelectedTime(e.target.value)}
                    >
                      <option value="Select time">Select time</option>
                      <option>{`2:00 PM to 5:00 PM`}</option>
                    </select>
                  </div>
                  <div class="col-12 mb-3">
                    <button
                      className="btn btnmakeappitmnets"
                      data-bs-toggle="modal"
                      data-bs-target="#appotimnets"
                    >
                      Make An Appointment
                    </button>
                  </div>
                </div>
              </article> */}
              {/* <article className="abouttemplate sec">
                <h3 class="sectitle">About me</h3>
                <p className="para">
                  {` Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.`}
                </p>
              </article> */}
              {/* <article class="gallery sec sec-common">
                <h3 class="sectitle">Gallery </h3>
                <GalleryCarousel slides={galleryArray} />
              </article> */}
              {/* <article className="social-links-sec sec">
                <h3 class="sectitle">Social Media</h3>
                <ul class="socil-link socil-link-inner">
                  <li>
                    <a>
                     
                      <img loading="lazy" src={FacebookIcon} />
                    </a>
                  </li>
                  <li>
                    <a>
                    
                      <img loading="lazy" src={InstagramIcon} />
                    </a>
                  </li>
                 
                  <li>
                    <a>
                    
                      <img loading="lazy" src={TweeterIcon} />
                    </a>
                  </li>
                  <li>
                    <a>
                     
                      <img loading="lazy" src={WhatsappIcon} />
                    </a>
                  </li>
                  <li>
                    <a>
                    
                      <img loading="lazy" src={LinedinIcon} />
                    </a>
                  </li>
                </ul>
              </article> */}
              {/* <article className="businessHours sec">
                <h3 class="sectitle">Working Hours</h3>

                <ul class="timelist">
                  <li>
                    <div className="day">Monday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Tuesday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Wednesday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Thursday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Friday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Saturday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                </ul>
              </article> */}
              {/* <article className="conatctform sec">
                <h3 class="sectitle">Contact Form</h3>
                <div class="contact-form">
                  <form>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control clear_string"
                        placeholder="Full Name"
                        name="last_name"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        name="email"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control clear_string"
                        maxlength="10"
                        minlength="10"
                        placeholder="Mobile Number"
                        name="mobile_number"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <textarea
                        class="form-control clear_string"
                        rows="3"
                        placeholder="Message*"
                        required=""
                        name="message"
                      ></textarea>
                    </div>

                    <div class="col-12 mb-3">
                      <button type="submit" class="btn btn-submitform">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </article> */}
              {/* <article className="servicesName sec">
                <h3 class="sectitle">Services </h3>
                <div className="mainservicebox">
                  <ul className="servesbox">
                    <li>Hair Cutting</li>
                    <li>Hair Colors</li>
                    <li>Hair Spa</li>
                    <li>Hair Treatments</li>
                  </ul>
                </div>
              </article> */}
              {/* <article class="testimoniyalbox sec">
                <h3 class="sectitle testimonial">Testimonial </h3>

                <div>
                  <TestimonialCarousel
                    testimonials={testimonialArray}
                    currentElem={currentIndex}
                  />
                  <div className="carousel-container">
                    <button
                      onClick={() =>
                        currentIndex == 0
                          ? setCurrentIndex(testimonialArray.length - 1)
                          : setCurrentIndex((prev) => prev - 1)
                      }
                      className="carousel-btn"
                    >
                      <i class="fa fa-angle-left"></i>
                    </button>
                    <button
                      onClick={() => {
                        currentIndex == testimonialArray.length - 1
                          ? setCurrentIndex(0)
                          : setCurrentIndex((prev) => prev + 1);
                      }}
                      className="carousel-btn"
                    >
                      <i class="fa fa-angle-right"></i>
                    </button>
                  </div>
                </div>
              </article> */}
              {/* <article class="contact sec">
                <h3 class="sectitle">Contact</h3>
                <ul class="conlink">
                  <li>
                    <a href="">
                      <i class="fa-solid fa-phone"></i>
                      <span>{`987654321`}</span>
                    </a>
                  </li>

                  <li>
                    <a href="">
                      <i class="fa-solid fa-envelope"></i>
                      <span>{` christian@gmail.com`}</span>
                    </a>
                  </li>

                  <li>
                    <a href="">
                      <i class="fa-solid fa-map"></i>
                      <span>{`No 17A / 22, Ajmal Khan Road,`}</span>
                    </a>
                  </li>
                </ul>
              </article> */}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Theme14;
