import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getLanguage } from "../../utils/utility";

function LanguageSelector() {
    const { i18n } = useTranslation();
    let choseLang = localStorage.getItem("langchosed");
    const [langNew, setlangNew] = useState( getLanguage(choseLang) || "EN");
    
    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem("langchosed", lang);
        if (lang == "en") { setlangNew("EN") }
        else
            setlangNew("العربية")
    };


    
    return (
        <>
            <div class="ext-lang-selector">
                <div class="nav-wrapper">
                    <div class="sl-nav">
                        <ul>
                            <li><b>{langNew}</b> <i class="fa fa-angle-down" aria-hidden="true"></i>
                                <div class="triangle"></div>
                                <ul>
                                    <li onClick={() => changeLanguage("en")} aria-label="Switch to English"><i class="sl-flag flag-in"><div id="germany"></div></i> <span className={langNew == 'EN'?'active':''} >English</span></li>
                                    <li onClick={() => changeLanguage("ar")} aria-label="Switch to Arabic"><i class="sl-flag flag-ind"><div id="arabic"></div></i> <span className={langNew == 'العربية'?'active':''}>العربية</span></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
export default LanguageSelector;
//1212
