import React, { useEffect, useState } from "react";
import { environmentVariables } from "./config/env.config";
import axios from "axios";
import "./App.css";
import './Popupdesign.css';
import share from './Images/share2.png';
import more from './Images/more.png';
import {useTranslation} from "react-i18next";
// Modal component for installation
const InstallModal = ({ show, onClose, onInstall, isFirefox, isIOS, pwaData, isMacOS, isIPAD }) => {
   const { t } = useTranslation();
  if (!show) {
    return null;
  }

  return (
    !!pwaData && (
      <div>
        {!isFirefox && !isIOS && !isMacOS && !isIPAD && (
          <div className="modal-overlay">
            <div className="pwapopup">
              <div className="modal-content">
                <div className="popup-header">
                  <h3>{t("Install_app")}</h3>
                  <span className="close-btn" onClick={onClose}>&times;</span>
                </div>
                <div className="app-info">
                  <div className="app-icon">
                    <img src={pwaData?.icons[0]?.src} alt="App Icon" />
                  </div>
                  <div className="app-details">
                    <h4>{pwaData?.name}</h4>
                    <p>{pwaData?.startUrl?.length > 30 ? `${pwaData.startUrl.substring(0, 50)}...` : pwaData.startUrl}</p>
                  </div>
                </div>
                <p className="downloaddesc">{t("Install_the_businessbay_app")}</p>
                <div className="btndiv">
                  <button className="installbtn" onClick={onInstall}>{t("Install")}</button>
                </div>
              </div>
            </div>
          </div>
        )}
        {isMacOS && (
          <div className="modal-overlay">
            <div className="pwapopupios">
              <div className="modal-contentios">
                <div className="popup-header">
                  <h3>{t("Install_app")}</h3>
                  <span className="close-btn" onClick={onClose}>&times;</span>
                </div>
                <div className="app-info">
                  <div className="app-icon">
                    <img src={pwaData?.icons[0]?.src} alt="App Icon" />
                  </div>
                  <div className="app-details">
                    <h4>{pwaData?.name}</h4>
                    <p>{pwaData?.startUrl?.length > 30 ? `${pwaData.startUrl.substring(0, 50)}...` : pwaData.startUrl}</p>
                  </div>
                </div>
                <ol className="instructions">
                  <li>1. {t("Click_on")} <span className="icon"><img src={share} alt="Share" /></span> {t("Tab_bar")}</li>
                  <li>2. {t("Gofile_add_dock")}.</li>
                </ol>
              </div>
            </div>
          </div>
        )}
        {(isIOS || isIPAD) && (
          <div className="modal-overlay">
            <div className="pwapopupios">
              <div className="modal-contentios">
                <div className="popup-header">
                  <h3>{t("Install_app")}</h3>
                  <span className="close-btn" onClick={onClose}>&times;</span>
                </div>
                <div className="app-info">
                  <div className="app-icon">
                    <img src={pwaData?.icons[0]?.src} alt="App Icon" />
                  </div>
                  <div className="app-details">
                    <h4>{pwaData?.name}</h4>
                    <p>{pwaData?.startUrl?.length > 30 ? `${pwaData.startUrl.substring(0, 50)}...` : pwaData.startUrl}</p>
                  </div>
                </div>
                <ol className="instructions">
                  <li>1. {t("Tap_on")} <span className="icon"><img src={share} alt="Share" /></span> {t("Tab_bar")}</li>
                  <li>2. {t("Scroll_add")}<span className="icon"><img src={more} alt="More" /></span></li>
                  <li>3. {t("Look")} <span><img src={pwaData?.icons[0]?.src} alt="App Icon" width="16" className="icon2"/></span> {t("Icon_homescreen")}</li>
                </ol>
              </div>
            </div>
          </div>
        )} 
      </div>
    )
  );
};

const PwaDetails = (props) => {
  const { currentSlug, setShowPwaDetails, setPwaInstall } = props;
  const [installPrompt, setInstallPrompt] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isInstalled, setIsInstalled] = useState(false);
  const [isFirefox, setIsFirefox] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [isIPad, setIsIPad] = useState(false);
  const [isMacOS, setIsMacOS] = useState(false);
  const [pwaData, setPwaData] = useState(null);

  useEffect(() => {
    const createDynamicManifest = async () => {
      let data = JSON.stringify({
        pwaUrl: props?.currentSlug,
      });

      let config = {
        method: "post",
        url: `${environmentVariables?.apiUrl}api/v1/business/pwa-details`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          if (!!response.data) {
            let pwaDetails = response?.data;
            setPwaData(pwaDetails);
            document.title = pwaDetails?.name || "Businessbay";

            const manifest = {
              name: "Business Bay",
              short_name: "Businessbay",
              start_url: "/",
              display: "standalone",
              background_color: "#ffffff",
              theme_color: "#000000",
              icons: [
                {
                  src: "/pwa-icon/logo76.png",
                  sizes: "76x76",
                  type: "image/png",
                },
                {
                  src: "/pwa-icon/logo120.png",
                  sizes: "120x120",
                  type: "image/png",
                },
                {
                  src: "/pwa-icon/logo152.png",
                  sizes: "152x152",
                  type: "image/png",
                },
                {
                  src: "/pwa-icon/logo180.png",
                  sizes: "180x180",
                  type: "image/png",
                },
                {
                  src: "/pwa-icon/logo192.png",
                  sizes: "192x192",
                  type: "image/png",
                },
                {
                  src: "/pwa-icon/logo512.png",
                  sizes: "512x512",
                  type: "image/png",
                },
              ],
            };
            let iconsData = pwaDetails?.icons;
            const finalManifest = {
              ...manifest,
              name: pwaDetails?.name,
              short_name: pwaDetails?.name,
              start_url: pwaDetails?.startUrl,
              icons: iconsData,
            };

            const stringManifest = JSON.stringify(finalManifest);
            const blob = new Blob([stringManifest], { type: "application/json" });
            const manifestURL = URL.createObjectURL(blob);
            const manifestLink = document.querySelector('link[rel="manifest"]');
            manifestLink.href = manifestURL;

            // Set apple icon dynamically
            if (iconsData && iconsData.length > 0) {              
              const appleIcon76 = document.querySelector('link[rel="apple-touch-icon"][sizes="76x76"]');
              if (appleIcon76) appleIcon76.href = iconsData[0]?.src || "/pwa-icon/logo76.png";
            
              const appleIcon120 = document.querySelector('link[rel="apple-touch-icon"][sizes="120x120"]');
              if (appleIcon120) appleIcon120.href = iconsData[1]?.src || "/pwa-icon/logo120.png";
            
              const appleIcon152 = document.querySelector('link[rel="apple-touch-icon"][rel="apple-touch-icon"][sizes="152x152"]');
              if (appleIcon152) appleIcon152.href = iconsData[2]?.src || "/pwa-icon/logo152.png";
            
              const appleIcon180 = document.querySelector('link[rel="apple-touch-icon"][sizes="180x180"]');
              if (appleIcon180) appleIcon180.href = iconsData[3]?.src || "/pwa-icon/logo180.png";
            
              const appleIcon192 = document.querySelector('link[rel="apple-touch-icon"][sizes="192x192"]');
              if (appleIcon192) appleIcon192.href = iconsData[4]?.src || "/pwa-icon/logo192.png";

              const appleIcon512 = document.querySelector('link[rel="apple-touch-icon"][sizes="512x512"]');
              if (appleIcon512) appleIcon512.href = iconsData[5]?.src || "/pwa-icon/logo512.png";
            }

            const appleMobileWebAppTitle = document.querySelector(
              'meta[name="apple-mobile-web-app-title"]'
            );
            if (appleMobileWebAppTitle) appleMobileWebAppTitle.content = pwaDetails?.name;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const delay = 1000;
    const timeoutId = setTimeout(() => {
      createDynamicManifest();
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [props.currentSlug]);

  useEffect(() => {
    const isFirefoxBrowser = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
    setIsFirefox(isFirefoxBrowser);
  
    const isIOSDevice = /iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase());
    const isIPad = navigator.userAgent.match(/iPad/i) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    setIsIOS(isIOSDevice);
    setIsIPad(isIPad);
    setIsMacOS(navigator.platform.toUpperCase().indexOf('MAC') >= 0);
  
    // Function to check if app is in standalone mode
    const checkIfStandalone = () => {
      // For iOS/iPadOS
      if (window.navigator.standalone) {
        props?.setIsInstalled(true);
        localStorage.setItem("pwaInstalled", true);
      }
      // For other platforms
      console.log("window-matchMedia===>>>", window.matchMedia('(display-mode: standalone)').matches);
      if (window.matchMedia('(display-mode: standalone)').matches) {
        props?.setIsInstalled(true);
        localStorage.setItem("pwaInstalled", true);
      }
    };
  
    // Call the check on initial load
    checkIfStandalone();
  
    // Listen for changes in display mode (for Android/desktop)
    const mediaQueryList = window.matchMedia('(display-mode: standalone)');
    const handleDisplayModeChange = (e) => {
      console.log("eventforcheckisinstalled===>>>", e.matches);      
      if (e.matches) {
        props?.setIsInstalled(true);
        localStorage.setItem("pwaInstalled", true);
      } else {
        props?.setIsInstalled(false);
      }
    };
  
    mediaQueryList.addEventListener('change', handleDisplayModeChange);
  
    return () => {
      mediaQueryList.removeEventListener('change', handleDisplayModeChange);
    };
  }, []);
  
  

  useEffect(() => {
    console.log("isInstalled===>>>", props?.isInstalled);
    
    if (props?.isInstalled) {
      setIsModalOpen(false); // Close the modal if the app is installed
    } else if (isFirefox || isIOS || isIPad || isMacOS) {
      setIsModalOpen(true); // Show modal for installation on relevant platforms
    } else {
      const handler = (e) => {
        e.preventDefault();
        setInstallPrompt(e);
        setIsModalOpen(true);
      };
      window.addEventListener("beforeinstallprompt", handler);
      return () => {
        window.removeEventListener("beforeinstallprompt", handler);
      };
    }
  }, [props?.isInstalled, isFirefox, isIOS, isIPad, isMacOS]);
  
  useEffect(() => {
    // Function to check if app is in standalone mode
    const checkIfStandalone = () => {
      if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone) {
        props?.setIsInstalled(true);
        localStorage.setItem("pwaInstalled", true);
      }
    };
  
    // Call the check on initial load
    checkIfStandalone();
  
    // Listen for changes in display mode (for Android/desktop)
    const mediaQueryList = window.matchMedia('(display-mode: standalone)');
    const handleDisplayModeChange = (e) => {
      console.log("Display mode changed:", e.matches);
      props?.setIsInstalled(e.matches);
      if(e.matches){
        localStorage.setItem("pwaInstalled", true);
      }
    };
    
    mediaQueryList.addEventListener('change', handleDisplayModeChange);
  
    // Listen for app installation event
    const handleAppInstalled = () => {
      console.log("PWA has been installed!");
      props?.setIsInstalled(true);
      localStorage.setItem("pwaInstalled", true);
    };
    
    window.addEventListener('appinstalled', handleAppInstalled);
  
    return () => {
      mediaQueryList.removeEventListener('change', handleDisplayModeChange);
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, []);
  
  const handleInstallClick = () => {
    console.log("installPrompt====>>>>", installPrompt);
    
    if (installPrompt) {
      installPrompt.prompt();
      installPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        setInstallPrompt(null);
        setIsModalOpen(false);
      });
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (typeof setShowPwaDetails === "function") {
      setShowPwaDetails(false);
    // props.setShowPwaDetails(false);
    }
    localStorage.setItem("PWA_install_key", props?.currentSlug);
  };

  return (
    <div className="App-new">
      <InstallModal
        show={isModalOpen}
        onClose={handleCloseModal}
        onInstall={handleInstallClick}
        pwaData={pwaData}
        isFirefox={isFirefox}
        isIOS={isIOS}
        isMacOS={isMacOS}
        isIPAD={isIPad}
      />
    </div>
  );
};

export default PwaDetails;
