import React from "react";
import "./Theme10.css";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getImage } from "../../../Components/TestimonialGetImage/TestimonialGetImage";

const TestimonialCarousel = ({ testimonials, currentElem, handleTouchStart, handleTouchMove, handleTouchEnd }) => {
  const testimonialStyle = {
    transform: `translateX(-${(currentElem * 100) / testimonials?.length}%)`,
    transition: "transform 0.5s ease-in-out",
    // width: `${testimonials * 100}%`,
  };
  //   console.log(testimonials, currentElem, "inside carousel");
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    arrows: true,
  };
  return (
    <>
      <div className="testimonials-container">
        <Slider {...settings}>
          {testimonials.map((val, index) => {
            return (
              <div class="testibox" key={index}>
                <div className="testimonialcontent">
                  <div className="testiimg">
                    <img
                      src={
                        getImage(val)
                      }
                      class="mx-auto d-block img-fluid clint-img2"
                      alt="Testimonial Image"
                    />
                  </div>
                  <div className="clintname">
                    <b className="testimonialname">
                      {val?.name != "undefined" ? val?.name : ""}
                    </b>
                    {/* <div className="rating">
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                    </div> */}
                    <p className="testimonialdescription">
                      {val?.description != "undefined" ? val?.description : ""}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

export default TestimonialCarousel;

