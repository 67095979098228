import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { userContext } from "../../context/userContext";
import { useTranslation } from "react-i18next";

function PageName(props) {
  const { userData, setUserData } = useContext(userContext);
    const { t } = useTranslation();
  
  return (
    <>
      {userData && userData?.user_type !== "Influencer" ? (
        <div class="app-title ">
          <div>
            {/* <h1>
        {props?.icon} {props?.PageName}
      </h1> */}
          </div>
          <ul class="app-breadcrumb breadcrumb">
            <li class="breadcrumb-item"> {props?.PageName}</li>
            <li class="breadcrumb-item">
              {userData?.user_type == "super_admin" ? (
                <Link to="/super_dashboard">Home</Link>
              ) : (
                <Link to="/dashboard">{t("Home")}</Link>
              )}
            </li>
          </ul>
        </div>
      ) : (
        <div style={{ display: "none" }}></div>
      )}
    </>
  );
}

export default PageName;
