import React, { useEffect, useState } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const FormikErrorStyle = styled.div`
font-size:11px;
color: #ff3333;
padding-bottom: 5px;
`;

const ResumeachievementForm = ({
  formCount,
  servicesData,
  setIsAddServiceDisable,
  setServicesData,
  removeForm,
  index,
}) => {
  const [data, setData] = useState({
    formCount: formCount,
    banner: null,
    skillname: "",
    description: "",
    buttonLink: "",
    buttonName: "",
    bannerImage: null,
    isImageUpload: true,
  });
  const {t} = useTranslation();
  const [tempFormCount, setTempFormCount] = useState(0);
  const [addBtnDisable, setAddBtnDisable] = useState(false);
  const [image, setImage] = useState(null);
  const [isShowAdd, setIsShowAdd] = useState(true);

  const validationSchema = Yup.object({
    // name: Yup.string().matches(/^[a-zA-Z]+([a-zA-Z' -]*[a-zA-Z])*$/, 'Only alphabets are allowed').min(2, 'Minimum 2 characters'),
    skillname: Yup.string().matches(/^[a-zA-Z0-9]+([a-zA-Z0-9' -]*[a-zA-Z0-9])*$/, t('Only_alphabets_Character_are_allowed')).required(t("Achievement_name_is_required")).min(2, t('Minimum_2_characters_required')).max(45, t("Maximum_45_characters")),
  });

  const formik = useFormik({
    initialValues: {
      skillname: data.skillname || '',
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleAddService(formik.values.skillname);
    },
  });


  useEffect(() => {
    let currentForm = servicesData[index];
    setTempFormCount(formCount);
    setData({
      formCount: currentForm?.formCount,
      banner: currentForm?.image, 
      skillname: currentForm?.skillname,
      description: currentForm?.description,
      buttonLink: currentForm?.buttonLink,
      buttonName: currentForm?.buttonName,
      bannerImage: currentForm?.bannerImage,
      isImageUpload: currentForm?.isImageUpload,
    });

  if (currentForm?.skillname) {
    setAddBtnDisable(true);
  } else {
    setAddBtnDisable(false);
  }
    data["skillname"] ? setIsShowAdd(false) : setIsShowAdd(true);
  }, [servicesData]);

  useEffect(() => {
    setTempFormCount(data?.formCount);
  }, [data]);

  const handleAddService = (nameValue) => {
    setAddBtnDisable(true);
    setIsAddServiceDisable(false);
    const newServicesData = [...servicesData];
    newServicesData.forEach((val) => {
      if (val.formCount === tempFormCount) {
        val.banner = image;
        val.skillname = nameValue;
        val.description = data?.description;
        val.buttonLink = data?.buttonLink;
        val.buttonName = data?.buttonName;
        val.bannerImage = data?.bannerImage;
        val.isImageUpload = data?.isImageUpload;
      }
    });
    setData(newServicesData);
    setServicesData(newServicesData);
  };

  return (
    <div key={`form-${formCount}`} className="col-12" id="AddNewStyleFormfieldpad">
      <div className="serviceboxedit">
        <i
          class="fa-solid fa-circle-xmark crosseditservice crossIconAdddetails"
          onClick={() => removeForm(index)}
        ></i>
        
        <div>
          {data["isImageUpload"] ? (
            <div>
              <input
              name="skillname"
                disabled={addBtnDisable}
                class="form-control"
                style={{ padding: "14px 25px", marginBottom: "10px" }}
                type="text"
                placeholder="Add Achievements"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.skillname}
              />
              {formik.touched.skillname && formik.errors.skillname ? (
                <FormikErrorStyle>{formik.errors.skillname}</FormikErrorStyle>
              ) : null}
            </div>
          ) : (
            <div className="servicenameedit" id="ServicesEditformstyle">{data["skillname"] || ""}</div>
          )}
        </div>
        {isShowAdd && !addBtnDisable ? (
          <button
            type="button"
            onClick={() => {
              formik.handleSubmit();
              handleAddService(formik.values.skillname);
            }}
            className="btn btn-cu addbuttoneditform"
          >
            Add
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ResumeachievementForm;
