import { environmentVariables } from "../../../config/env.config";


export const getImage = (val) => {
    if (val?.imageView) {
      return `${val?.imageView}`;
    }
    if (val?.image == "null") {
      return `${environmentVariables?.s3TestimoinalDefaultImage}`;
    }
    if (val?.image && val?.image != null) {
      return `${environmentVariables?.s3UrlBackendUrl}${val?.image}`;
    }
  };