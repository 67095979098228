import React, { useContext, useState, useRef } from "react";
import { userContext } from "../../../../context/userContext";
import { environmentVariables } from "../../../../config/env.config";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import "./Theme15.css";
import axios from "axios";

import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';

import TestimonialCarousel from "./TestimonialCarousel";
import GalleryCarousel from "./GalleryCarousel";
import { getServicesArray } from "../../../../utils/utility";
import { ThemeColorContext } from "../../../../context/ThemeColorContext";

// import "./Template10.css";
const Theme15 = (props) => {
  const { t } = useTranslation();

  const { userData } = useContext(userContext);
  const [selectedDate, setSelectedDate] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [appointmentData, setAppointmentData] = useState([]);
  const touchStartX = useRef(0);
  const calendarInputRef = useRef(null);
  // const {  props?.dynamicMainBackgroundColor,  props?.dynamicBackgroundColor,  props?.dynamicTextDescriptionColor,  props?.dynamicTextHeadingColor, props?.dynamicCtaTextColor,  props?.dynamicCtaColor } = useContext(ThemeColorContext)
  const handleChangeAppointmentDate = (dateVal) => {
    setSelectedDate(dateVal);

    const dateObject = new Date(dateVal);

    const dayIndex = dateObject.getDay();

    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    if (props?.appointmentArray && props?.appointmentArray?.length != 0) {
      setAppointmentData(
        props?.appointmentArray[`${daysOfWeek[dayIndex].toLowerCase()}`]
      );
    } else {
      try {
        const parsedContent = JSON.parse(
          JSON.parse(props?.businessObj?.appointmentObj?.content)
        );
        setAppointmentData(
          parsedContent[`${daysOfWeek[dayIndex].toLowerCase()}`]
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    const touchEndX = e.touches[0].clientX;
    const deltaX = touchEndX - touchStartX.current;

    if (Math.abs(deltaX) > 50) {
      // You can adjust this threshold to control how much swipe is needed to trigger the slide
      setCurrentIndex((prev) =>
        deltaX > 0
          ? Math.max(prev - 1, 0)
          : Math.min(prev + 1, props.testimonialArray.length - 1)
      );
    }
  };
  const openCalendar = () => {

    if (calendarInputRef.current) {
      calendarInputRef.current.showPicker();

    }
  };

  const handleTouchEnd = () => {
    touchStartX.current = 0;
  };

  useEffect(() => {
    getBlockOrder();
  }, [props?.businessObj, props.businessId, props.orderOfBlocks]);
  const getBrandNameForSocial = (brand) => {
    // console.log(brand);
    if (brand == "gmail") {
      return "google";
    } else return `${brand}`;
  };
  const getBlockOrder = async () => {
    // const handleGetBlock = async () => {
    if (props.orderOfBlocks) {
      let orders = {};
      props.orderOfBlocks.map((val) => {
        if (val.content == "Description") {
          orders["description"] = val?.position;
        } else if (val.content == "Contact Info") {
          orders["contact_info"] = val?.position;
        } else if (val.content == "Appointment") {
          orders["appointment"] = val?.position;
        } else if (val.content == "Service") {
          orders["service"] = val?.position;
        } else if (val.content == "Gallery") {
          orders["gallery"] = val.position;
        } else if (val.content == "Contact Form") {
          orders["contact_form"] = val.position;
        } else if (val.content == "Testimonials") {
          orders["testimonials"] = val.position;
        } else if (val.content == "Social") {
          orders["social"] = val.position;
        } else if (val.content == "Business Hour") {
          orders["business_hour"] = val.position;
        } else if (val.content == "Google Review") {
          orders["google_review"] = val.position;
        }
      });
      props.setOrder(orders);
    } else {
      try {
        let id = props?.businessObj?.id || props.businessId;
        if (props?.businessObj?.id) {
          let { data: get } = await axios.get(
            `${environmentVariables?.apiUrl}api/v1/business/FetchBlock?business_id=${id}`,
            {
              headers: {
                _token: userData?.token,
              },
              // withCredentials: true,
            }
          );
          // console.log(get?.data?.order, "order data");
          props.setOrder(get?.data?.order);
        }

        // console.log(get.data.order,"Getetgwef")
      } catch (err) {
        console.log(err, "error in template");
      }
    }
    // };
  };
  const getJSXElement = (elemName) => {
    // console.log(props?.appointmentArray, "elemNameelemName");
    if (elemName == "business_hour") {
      return (
        props?.onState?.businessHours && (
        
          <article className="businesshour sec">
            <h3 class="sectitle business" style={{color: props?.dynamicTextHeadingColor || "#FFA438" }}>{props?.businessHourHeading || t("Working_hours")}</h3>           

             <ul class="timelist">
              {props?.businessHourObject?.isMonday &&
                props?.businessHourObject?.modayStartDate &&
                props?.businessHourObject?.modayEndDate && (
                  <li style={{color:  props?.dynamicTextHeadingColor? `${ props?.dynamicTextHeadingColor}B3`:''}}>
                    <div className="day colordescription">{t("Monday")}</div>
                    <div className="colon">:</div>
                    <div className="time">
                      {" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.modayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.modayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              {props?.businessHourObject?.isTuesday &&
                props?.businessHourObject?.tuesdayStartDate &&
                props?.businessHourObject?.tuesdayEndDate && (
                  <li style={{color:  props?.dynamicTextHeadingColor? `${ props?.dynamicTextHeadingColor}B3`:''}}>
                    <div className="day colordescription">{t("Tuesday")}</div>
                    <div className="colon">:</div>
                    <div className="time">
                      {" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.tuesdayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.tuesdayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              {props?.businessHourObject?.isWednesDay &&
                props?.businessHourObject?.wednesdayStartDate &&
                props?.businessHourObject?.wednesdayEndDate && (
                  <li style={{color:  props?.dynamicTextHeadingColor? `${ props?.dynamicTextHeadingColor}B3`:''}}>
                    <div className="day colordescription">{t("Wednesday")}</div>
                    <div className="colon">:</div>
                    <div className="time">
                      {" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.wednesdayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.wednesdayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              {props?.businessHourObject?.isthrusdayDay &&
                props?.businessHourObject?.thrusdayStartDate &&
                props?.businessHourObject?.thrusdayEndDate && (
                  <li style={{color:  props?.dynamicTextHeadingColor? `${ props?.dynamicTextHeadingColor}B3`:''}}>
                    <div className="day colordescription">{t("Thursday")}</div>
                    <div className="colon">:</div>
                    <div className="time">
                      {" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.thrusdayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.thrusdayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              {props?.businessHourObject?.isfridayDay &&
                props?.businessHourObject?.fridayStartDate &&
                props?.businessHourObject?.fridayEndDate && (
                  <li style={{color:  props?.dynamicTextHeadingColor? `${ props?.dynamicTextHeadingColor}B3`:''}}>
                    <div className="day colordescription">{t("Friday")}</div>
                    <div className="colon">:</div>
                    <div className="time">
                      {" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.fridayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.fridayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              {props?.businessHourObject?.issaturdayDay &&
                props?.businessHourObject?.SaturdayStartDate &&
                props?.businessHourObject?.SaturdayEndDate && (
                  <li style={{color:  props?.dynamicTextHeadingColor? `${ props?.dynamicTextHeadingColor}B3`:''}}>
                    <div className="day colordescription">{t("Saturday")}</div>
                    <div className="colon">:</div>
                    <div className="time">
                      {" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.SaturdayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.SaturdayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              {props?.businessHourObject?.issundayDay &&
                props?.businessHourObject?.SundayStartDate &&
                props?.businessHourObject?.SundayEndDate && (
                  <li style={{color:  props?.dynamicTextHeadingColor? `${ props?.dynamicTextHeadingColor}B3`:''}}>
                    <div className="day colordescription">{t("Sunday")}</div>
                    <div className="colon">:</div>
                    <div className="time">
                      {" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.SundayStartDate
                        )}
                      </span>{" "}
                      -{" "}
                      <span className="colordescription">
                        {props.changeTimeFormat(
                          props?.businessHourObject?.SundayEndDate
                        )}
                      </span>
                    </div>
                  </li>
                )}
              </ul>
          </article>
        )
      );
    } else if (elemName == "appointment") {
      return (
        props?.onState?.appointment && (
          // <article class="contact sec colorback">
          //   <h3 class="sectitle">Appoinment</h3>
          //   <select class="form-select" aria-label="Default select example">
          //     <option selected>Select time</option>
          //     {props?.appointmentArray?.map((val) => (
          //       <>
          //         <option value="1">
          //           {props.changeTimeFormat(val?.startDate)} -{" "}
          //           {props.changeTimeFormat(val?.endDate)}{" "}
          //         </option>
          //       </>
          //     ))}
          //   </select>
          // </article>
          <article className="makeappitmneets sec colorback">
            <h3 className="sectitle makeappoint" style={{color: props?.dynamicTextHeadingColor || "#181817" }}> {props?.appointmentsHeading || t("Book_appointment")}</h3>
           <div className="contact-form">
              <div class="col-12 mb-3" style={{position:"relative"}}>
                {/* <input
                  type="date"
                  ref={calendarInputRef}
                  class="form-control clear_string colorctaborder"
                  placeholder="Date"
                  name="currentDate"
                  value={selectedDate}
                  onChange={(e) => handleChangeAppointmentDate(e.target.value)}
                />
                <div className="calanderIcon firefox-hide" onClick={openCalendar}><img src={environmentVariables?.s3UrlDarkCalenderIconDays} alt="Calendar Icon" /></div> */}

                <DatePicker
                  selected={selectedDate}
                  ref={calendarInputRef}
                  minDate={new Date()}
                  placeholderText={t("Date")}
                  onChange={
                    (date) =>
                      handleChangeAppointmentDate(date)
                  }
                  className="form-control"
                  popperPlacement="bottom-end"
                />
               <div className="calanderIcon firefox-hide" onClick={() => calendarInputRef.current.setOpen(true)}><img src={environmentVariables?.s3UrlDarkCalenderIconDays} alt="Calendar Icon" /></div>

              </div>
              <div class="col-12 mb-3">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  // onChange={(e) => setSelectedTime(e.target.value)}
                >
                  <option value={`${t("Select_time")}`}>{t("Select_time")}</option>
                  {appointmentData?.map((val) => (
                    <option
                      key={val?.startDate}
                      value={`${val?.startDate} - ${val?.endDate}`}
                    >
                      {`${val?.startDate} - ${val?.endDate}`}
                    </option>
                  ))}
                </select>
              </div>
              <div class="col-12 mb-3">
                <button className="btn btnmakeappitmnets colorcta" style={{backgroundColor: props?.dynamicCtaColor,color: props?.dynamicCtaTextColor || "#fff"}}>
                 {t("Make_an_appointment")}
                </button>
              </div>
            </div>
          </article>
        )
      );
    } else if (elemName == "contact_info") {
      return (
        props?.onState?.contactInfo && (
          <article class="contact sec colorback">
            <h3 class="sectitle contactinfo" style={{color: props?.dynamicTextHeadingColor || "#FFA438" }}>{props?.contactHeading || t("Contact_info")}</h3>
            <div class="conlink">
              <div className="contact-us-grid">
                <div className="contact-info-container">
                  <div className="contact-info-details">
                    <div className="contact-info-wrapper">
                      <div className="contact-info-icon">
                        <i className="fa fa-solid fa-phone-volume" style={{color: props?.dynamicTextHeadingColor}}></i>
                      </div>
                      <a>
                        <span className="contactinfotext" style={{color: props?.dynamicTextHeadingColor ? `${ props?.dynamicTextHeadingColor}B3`:''}}>
                          {props?.contactInfo?.contactInfoPhone}
                        </span>
                   {props?.contactInfo?.optionalContactInfoPhone?.split("-")?.[1]?.length > 0  &&     <span className="contactinfotext" style={{color: props?.dynamicTextHeadingColor ? `${ props?.dynamicTextHeadingColor}B3`:''}}> , { props?.contactInfo?.optionalContactInfoPhone} </span>}
                      </a>
                    </div>
                  </div>
                </div>

                <div className="vertical-line"></div>
                <div className="contact-info-container">
                  <div className="contact-info-details">
                    <div className="contact-info-wrapper">
                      <div className="contact-info-icon">
                        <i className="fa fa-solid fa-envelope" style={{color: props?.dynamicTextHeadingColor}}></i>
                      </div>
                      <a>
                        <span className="contactinfotext" style={{color:  props?.dynamicTextHeadingColor ? `${ props?.dynamicTextHeadingColor}B3` :''}}>
                          {props?.contactInfo?.contactInfoEmail}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="vertical-line"></div>
                {props?.contactInfo?.contactInfoAddress && 
                <div className="contact-info-container">
                  <div className="contact-info-details">
                    <div className="contact-info-wrapper">
                      <div className="contact-info-icon" style={{marginRight:"0"}}>
                        <i className="fa fa-solid fa-location-dot" style={{color: props?.dynamicTextHeadingColor}}></i>
                      </div>
                      <a>
                        <span className="contactinfotext" style={{color:  props?.dynamicTextHeadingColor ? `${ props?.dynamicTextHeadingColor}B3` :''}}>
                          {props?.contactInfo?.contactInfoAddress}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
    }
                {props?.contactInfo?.contactInfoOptAddress && 
                  <div className="contact-info-container">
                  <div className="contact-info-details">
                    <p style={{color: props?.dynamicTextHeadingColor || "#FFA438",fontWeight:"500" ,fontSize:"14px"}}>{t("Alternative_address")}</p> 
                    <div className="contact-info-wrapper">
                      <div className="contact-info-icon" style={{marginRight:"0"}}>
                        {/* <i className="fa fa-solid fa-location-dot"></i> */}
                      </div>
                      <a>
                        <span className="contactinfotext" style={{color:  props?.dynamicTextHeadingColor ? `${ props?.dynamicTextHeadingColor}B3` :''}}>
                          {props?.contactInfo?.contactInfoOptAddress}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                  }

                <div className="vertical-line"></div>
                {props?.contactInfo?.contactInfoWebUrl &&
<div className="contact-info-container">
  <div className="contact-info-details">
    <div className="contact-info-wrapper">
      <div className="contact-info-icon" style={{marginRight:"0"}}>
        <i className="fa fa-globe" style={{color: props?.dynamicTextHeadingColor}}></i>
      </div>
      <a>
        <span className="contactinfotext" style={{color:  props?.dynamicTextHeadingColor ? `${ props?.dynamicTextHeadingColor}B3` :''}}>
          {props?.contactInfo?.contactInfoWebUrl}
        </span>
      </a>
    </div>
  </div>
</div>
    }
              </div>
              {/* <li>
                <a href="">
                <i class="fa-solid fa-envelope colordescription"></i>
                  <span className="colordescription">
                   
                    {props?.contactInfo?.contactInfoWebUrl ||
                      `www.Shreyassinghal.com`}
                  </span>
                </a>
              </li> */}
            </div>
          </article>
        )
      );
    } else if (elemName == "custom_html") {
      return <></>;
    } else if (elemName == "google_review") {
      return (
        props?.onState?.googlereview == true && (
          ''
          // <article class="abouttemplate sec colorback">
          //   <h3 class="sectitle" style={{ color: "#000" }}>Google review</h3>
          //   <ul class="conlink">
          //     <li>
          //       <p style={{ wordBreak: "break-all", maxHeight: "77px", overflow: "hidden", fontSize: "16px", padding: "10px" }}>
          //         {props?.googlereview !== undefined && props?.googlereview}
          //       </p>
          //     </li>
          //   </ul>
          // </article>
        )
      );
    }

    else if (elemName == "contact_form") {
      return (
        props?.onState?.contactForm && (
          <article className="conatctform sec colormainback">
            <h3 class="sectitle contact" style={{color: props?.dynamicTextHeadingColor || "#FFA438" }}>{props?.contactformHeading || t("Contact_us")}</h3>
            <div class="contact-form">
              <form>
                <div class="col-12 mb-2">
                  <input
                    type="text"
                    class="form-control clear_string colorctaborder"
                    placeholder={t("Full_name")}
                    name="last_name"
                    value=""
                    required=""
                    style={{border:  props?.dynamicTextHeadingColor ? `1px solid ${ props?.dynamicTextHeadingColor}` : ''}}
                  />
                </div>
                <div class="col-12 mb-2">
                  <input
                    type="text"
                    class="form-control colorctaborder"
                    placeholder={t("Email")}
                    name="email"
                    value=""
                    required=""
                    style={{border:  props?.dynamicTextHeadingColor ? `1px solid ${ props?.dynamicTextHeadingColor}` : ''}}
                  />
                </div>
                <div class="col-12 mb-2">
                  <input
                    type="text"
                    class="form-control clear_string colorctaborder"
                    maxlength="10"
                    minlength="10"
                    placeholder={t("Mobile_number")}
                    name="mobile_number"
                    value=""
                    required=""
                    style={{border:  props?.dynamicTextHeadingColor ? `1px solid ${ props?.dynamicTextHeadingColor}` : ''}}
                  />
                </div>

                <div class="col-12 mb-2">
                  <input
                    type="text"
                    class="form-control clear_string colorctaborder"
                    maxlength="10"
                    minlength="10"
                    placeholder={t("subject")}
                    name="subject"
                    value=""
                    required=""
                    style={{border:  props?.dynamicTextHeadingColor ? `1px solid ${ props?.dynamicTextHeadingColor}` : ''}}
                  />
                </div>

                <div class="col-12 mb-2">
                  <textarea
                    class="form-control clear_string colorctaborder"
                    rows="3"
                    placeholder={`${t("Message")}*`}
                    required=""
                    name="message"
                    style={{border:  props?.dynamicTextHeadingColor ? `1px solid ${ props?.dynamicTextHeadingColor}` : ''}}
                  ></textarea>
                </div>

                <div class="col-12 mb-2">
                  <button type="button" class="btn btn-submitform colordescription colorcta" style={{backgroundColor: props?.dynamicCtaColor,color: props?.dynamicCtaTextColor}}>
                    {t("Submit")}
                  </button>
                </div>
              </form>
            </div>
          </article>
        )
      );
    } else if (elemName == "description") {
      return (
        <article className="abouttemplate sec">
          <h3 class="sectitle about" style={{color: props?.dynamicTextHeadingColor || "#FFA438" }}>{props.descriptionHeading ? props.descriptionHeading : t("About_us")} </h3>
          <p className="aboutus-desc" style={{color:  props?.dynamicTextHeadingColor ? `${ props?.dynamicTextHeadingColor}B3` : ''}}
           dangerouslySetInnerHTML={{
            __html: (props.description ||
              `As a professional chef, I offer culinary services that transform meals into memorable experiences. With a focus on quality ingredients and innovative techniques, I deliver gourmet dishes for private events, restaurants, and more.`).replace(/(?:\r\n|\r|\n)/g, '<br>'),
          }}>
            
          </p>
        </article>
      );
    } else if (elemName == "gallery") {
      return (
        props?.onState?.gallery && (
          <article class="gallery sec sec-common colormainback">
            <h3 class="sectitle" style={{color: props?.dynamicTextHeadingColor || "#FFA438" }}>{props?.galleryHeading || t("Gallery")} </h3>
            {props?.galleryData && (
              <>
                <GalleryCarousel
                  slides={props?.galleryData}
                  video={props?.gallaryObject?.gallary_video}
                  colordata={props}
                />
              </>
            )}
            {/* {props.existingGalleryImages ? (
              <GalleryCarousel slides={props?.existingGalleryImages} />
            ) : (
              props?.gallaryObject?.gallaryArray && (
                <GalleryCarousel slides={props?.gallaryObject?.gallaryArray} />
              )
            )} */}
            {/* {props.galleryView && (
              <GalleryCarousel slides={props?.galleryView} staticImage={true} />
            )} */}
            {/* {props?.gallaryObject?.gallary_video && (
              <video className="mx-auto d-block img-fluid" controls>
                <source
                  src={
                    props?.gallaryObject?.gallary_video &&
                    `${environmentVariables?.apiUrl}uploads/${props?.gallaryObject?.gallary_video}`
                  }
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            )} */}
          </article>
        )
      );
    } else if (elemName == "more") {
      return <></>;
    } else if (elemName == "service") {
      return (
        props?.onState?.services && (
          <>
            <article className="servicesName sec colormainback">
              <h3 class="sectitle service" style={{color: props?.dynamicTextHeadingColor || "#FFA438" }}>{props?.serviceHeading || t("Services")} </h3>
              <div className="mainservicebox">              
                {props?.servicesArray && (
                  <ul className="servesbox">
                    {getServicesArray(props?.servicesArray).map(
                      (val) =>
                        val?.name != null && (
                          <li style={{color:  props?.dynamicTextHeadingColor ?`${ props?.dynamicTextHeadingColor}B3`:''}} key={val?.name}> {val?.name}</li>
                        )
                    )}
                  </ul>
                )}
              </div>
            </article>
            {/* <img loading="lazy" src={theme6strip} className="stripservice" /> */}
          </>
        )
      );
    } else if (elemName == "social") {
      return (
        props?.onState?.socials && (
          <article className="social-links-sec sec colorback">
            <h3 class="sectitle social" style={{color: props?.dynamicTextHeadingColor || "#FFA438" }}>{props?.socialHeading || t("Social_media")}</h3>
            <ul class="socil-link">
              {props?.socialMediaData?.map((val) => (
                <li>
                  <a style={{backgroundColor: props?.dynamicTextHeadingColor ? `${ props?.dynamicTextHeadingColor}40`: ''}}>
                    {/* <i
                      class={`fa-brands fa-${getBrandNameForSocial(
                        val?.nameOfSocial.toLowerCase()
                      )}`}
                    ></i> */}
                    <i
                      className={`fa-${val?.nameOfSocial.toLowerCase() === 'star' ? 'solid' : 'brands'} fa-${getBrandNameForSocial(
                        val?.nameOfSocial.toLowerCase()
                      )}`}
                      style={{color: props?.dynamicTextHeadingColor}}
                    ></i>
                  </a>
                </li>
              ))}
            </ul>
          </article>
        )
      );
    } else if (elemName == "testimonials") {
      return (
        props?.onState?.testimonials && (
          ''
          // <article class="testimoniyalbox sec colormainback">
          //   <h3 class="sectitle testimonial">Testimonials </h3>

          //   <div>
          //     <TestimonialCarousel
          //       testimonials={props?.testimonialArray}
          //       currentElem={currentIndex}
          //       handleTouchStart={handleTouchStart}
          //       handleTouchMove={handleTouchMove}
          //       handleTouchEnd={handleTouchEnd}
          //     />
          //     <div className="carousel-container">
          //       <button
          //         onClick={() =>
          //           currentIndex == 0
          //             ? setCurrentIndex(props.testimonialArray.length - 1)
          //             : setCurrentIndex((prev) => prev - 1)
          //         }
          //         className="carousel-btn colorcta colordescription"
          //       >
          //         <i class="fa fa-angle-left"></i>
          //       </button>
          //       <button
          //         onClick={() => {
          //           currentIndex == props.testimonialArray.length - 1
          //             ? setCurrentIndex(0)
          //             : setCurrentIndex((prev) => prev + 1);
          //         }}
          //         className="carousel-btn colorcta colordescription"
          //       >
          //         <i class="fa fa-angle-right"></i>
          //       </button>
          //     </div>
          //   </div>
          // </article>
        )
      );
    }else if (elemName == "alternateUrls") {
          return (
           props?.onState?.alternateurl && (
            <>
            <article className="servicesName sec colormainback">
              <h3 className="sectitle service colorheading" style={{color: props?.dynamicTextHeadingColor || "#FFA438" }}>{props?.alternateUrlsHeading || t("Useful_links")}</h3>
              <div className="mainservicebox">
                {props?.alternateUrlArray && (
                  <ul className="servesbox">
                    {getServicesArray(props?.alternateUrlArray).map((val) => {
                      const url = val?.description?.startsWith("http")
                        ? val?.description
                        : `https://${val?.description}`;
                      return (
                        val?.name &&
                        val?.description && (
                          <li key={val?.name}>
                            <a style={{color:  props?.dynamicTextHeadingColor ? `${ props?.dynamicTextHeadingColor}B3`:`'#FFA438'B3`}} href={url} target="_blank" rel="noopener noreferrer">
                              {val?.name}
                            </a>
                          </li>
                        )
                      );
                    })}
                  </ul>
                )}
              </div>
            </article>
            </>
           )
          )
        }
  };

  const getBlocksOrder = () => {
    let BlocksArray = [];
    for (const key in props.order) {
      BlocksArray[props.order[key]] = getJSXElement(key);
    }
    return BlocksArray;
  };
  return (
    <div>
      <div class="main-temaplt" id="temp15">
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ width: "420px", backgroundColor: props?.dynamicMainBackgroundColor }} className="col-12 wtemplate colormainback">
              {/* <article className="imgsechere sec">
                <img
                  src={
                    props?.bannerImageUrl
                      ? props.getImage(props.bannerImageUrl)
                      : null
                  }
                  className="mx-auto d-block img-fluid coverimghere "
                />
                <img
                  src={
                    props?.logoImageUrl
                      ? props.getImage(props?.logoImageUrl)
                      : null
                  }
                  className="mx-auto d-block img-fluid logo-profile"
                />
              </article>

              <article className="personalinfo sec">
                <h3 className="username">{props.title || "Name"}</h3>
                <p className="titleuser">
                  {props.designation || "Designation"}
                </p>
                <p className="companeyname">
                  {props.subTitle || "Company Name"}
                </p>
              </article> */}

              <article class="personalinfo sec colormainback" style={{backgroundColor:  props?.dynamicBackgroundColor}}>    
                <div className="bannerimg-info">          
                  <img
                    src={ props?.bannerImageUrl  ? props.getImage(props.bannerImageUrl) : environmentVariables?.s3BannerUrlTheme15 }
                    className="banner"
                    style={{minHeight:"480px" , maxHeight:"480px"}}
                  />
                </div>
                <div class="innerinfo">
                <img
                  src={ props?.logoImageUrl ? props.getImage(props?.logoImageUrl) : environmentVariables?.s3UrllogoUrlTheme15 }
            className="mx-auto d-block img-fluid logo-profile" style={{ border:  props?.dynamicBackgroundColor ? `10px solid ${ props?.dynamicBackgroundColor}` : "10px solid #ffa438" }}
 />
                  <h3 className="username" style={{color: props?.dynamicTextDescriptionColor}}>{props.subTitle || "Name"}</h3>
                  <p className="titleuser" style={{color: props?.dynamicTextDescriptionColor}}>
                    {props.designation || "Designation"}
                  </p>
                  {props?.onState && props?.onState?.googlereview && (

                  <div className="reviewratingcontainer">
                <p className="reviewrating" style={{color: props?.dynamicTextDescriptionColor}}>
                  {/* <StarRating value={props?.rating} totalStars={5} starSize="16px"  /> */}
                  <img loading="lazy" src={environmentVariables?.s3Star1} style={{width:"20px", paddingRight:"5px"}}/>
                <span className="ratingspan" style={{color: props?.dynamicTextDescriptionColor}}> {props.rating }/5</span> | {props.review } {t("Reviews")}
                  </p>
                </div>
                  )}
                  {/* <p className="companeyname">{rs.sub_title || "Company Name"}</p> */}
                  {/* <!-- <p class='companeyname'>Company</p> --> */}
                </div>
              </article>


              {getBlocksOrder().map((val) => (val == undefined ? <></> : val))}

              {/* <div class="fixedd">
                <article class="fixedfooter">
                  <div class="footerleft">
                    <div
                      class="qrcodeshaere"
                      data-bs-toggle="modal"
                      data-bs-target="#appotiments"
                    >
                      <i class="fa-solid fa-qrcode"></i>
                    </div>
                  </div>
                  <div class="addtocinatct">+Add To Contact</div>
                </article>
              </div> */}
              <div
                class="modal fade"
                id="appotiments"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Modal title
                      </h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <article class="personalinfo modal-info ">
                        <canvas
                          height="160"
                          width="160"
                          style={{ height: "128px", width: "128px" }}
                        ></canvas>
                        <h3 class="username">Subhan</h3>
                        <p class="titleuser colordescription">Software Developer</p>
                        <p class="companeyname">Xenophobe Tech</p>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        //   ref={createUserRef}
        id="appotimnets"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                Make Appointment
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form className="row">
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control clear_string colorctaborder"
                    placeholder="Full Name"
                  //   value={appointmentName}
                  //   onChange={(e) =>
                  //     setAppointmentName(e.target.value)
                  //   }
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    eror
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control clear_string colorctaborder"
                    placeholder="Email"
                  //   value={appointmentEmail}
                  //   onChange={(e) =>
                  //     setAppointmentEmail(e.target.value)
                  //   }
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",
                      bottom: "70px",
                    }}
                  >
                    'errormsg'
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control clear_string colorctaborder"
                    placeholder="Mobile No."
                  //   value={appointmentContactNumber}
                  //   onChange={(e) =>
                  //     setAppointmentContactNumber(e.target.value)
                  //   }
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    'errormsgni'
                  </div>
                </div>
                <div class="mb-2 mt-4 col-md-12">
                  <button
                    //   onClick={handleSubmits}
                    class="btn btn-primary me-3"
                    type="button"
                  >
                    <i class="bi bi-check-circle-fill ms-2 "></i>
                    Submit
                  </button>
                </div>
              </form>
            </div>
            {/* <ToastContainer /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theme15;
