import React, { useRef } from "react";
// import "./TemplatComman.css";
import bannerImage from "../../../../Images/banner1template.jpg";
import logoImage from "../../../../Images/Googlereviewlogo.png";
import GoogleImage from "../../../../Images/GoogleIcon.png";
import GoogleStar from "../../../../Images/Googlereview.png";
import GoogleQrcode from "../../../../Images/GoogleQrcode.png";

import testimonialImage from "../../../../Images/avatar.png";
import "./Theme12.css";
import { useState } from "react";
import TestimonialCarousel from "./TestimonialCarousel";
import CalanderIconn from "../../../../Images/DarkcalendarIcondays.png";
import GalleryCarousel from "./GalleryCarousel";
const Theme12Template = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const galleryArray = [
    {
      id: 67,
      business_id: 10,
      image: "1701333567360Rectangle546.jpg",
      created_by: 72,
      created_at: "2023-11-30T08:39:27.000Z",
      updated_at: "2023-11-30T08:39:27.000Z",
    },
    {
      id: 68,
      business_id: 10,
      image: "1701333569511Rectangle547.jpg",
      created_by: 72,
      created_at: "2023-11-30T08:39:29.000Z",
      updated_at: "2023-11-30T08:39:29.000Z",
    },
    {
      id: 69,
      business_id: 10,
      image: "1701333572785Rectangle548.jpg",
      created_by: 72,
      created_at: "2023-11-30T08:39:32.000Z",
      updated_at: "2023-11-30T08:39:32.000Z",
    },
  ];

  const calendarInputRef = useRef(null);
  const testimonialArray = [
    {
      name: "Ernest Reinger",
      description:
        "I absolutely love the Constriction Services! They have exceeded my expectations in every way.",
      formCount: "0",
      image: "null",
    },
    {
      name: "Ernest Reinger",
      description:
        "I absolutely love the Constriction Services! They have exceeded my expectations in every way.",
      formCount: "1",
      image: "null",
    },
    {
      name: "Ernest Reinger",
      description:
        "I absolutely love the Constriction Services! They have exceeded my expectations in every way.",
      formCount: "2",
      image: "null",
    },
  ];

  const openCalendar = () => {
   
    if (calendarInputRef.current) {
      calendarInputRef.current.showPicker();
     
    }
  };
  return (
    <div>
      <div class="main-temaplt" id="temp12">
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ width: "420px" }} className="col-12 wtemplate p-0">
              <article className="imgsechere sec" style={{margin:"0"}}>
                {/* <img
                  src={bannerImage}
                  className="mx-auto d-block img-fluid coverimghere "
                /> */}
                <img
                  src={logoImage}
                  className="mx-auto d-block img-fluid logo-profile"
                />
              </article>
              <article className="personalinfo sec">
                <h3 className="username">Dennis Patrick</h3>
                <p className="titleuser">UI/UX developer</p>
                <p className="userDesignation">BastionEx</p>
              </article>
            
              <article className="personalinfo sec">
                <div className="HorizontalLine"></div>
              </article>

              <article className="personalinfo sec googleContainericon">
                <h3 className="ReviewUser">Review us on</h3>
                <img loading="lazy" className="googleimage" src={GoogleImage} />
                <img loading="lazy" className="googlestar" src={GoogleStar} />
              </article>

              <article className="personalinfo sec">
                <h6 className="ScanQrcode">Scan this QR code to make our day by leaving us a review on</h6>
              </article>

              <article className="personalinfo sec googleContainericon" >
                <img loading="lazy" className="googlestar" src={GoogleQrcode} />
              </article>

              <article className="personalinfo sec">
                <h6 className="Googlewebsite"> www.businessbay.io/dennis </h6>
              </article>
             
              {/* <article className="abouttemplate sec">
                <h3 class="sectitle">About me</h3>
                <p>
                  {` Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.`}
                </p>
              </article> */}

              {/* <article class="contact sec">
                <h3 class="sectitle">Contact Info</h3>
                <ul class="conlink">
                  <li>
                    <a href="">
                      <i class="fa-solid fa-phone"></i>
                      <span>{`987654321`}</span>
                    </a>
                  </li>

                  <li>
                    <a href="">
                      <i class="fa-solid fa-envelope"></i>
                      <span>{` christian@gmail.com`}</span>
                    </a>
                  </li>

                  <li>
                    <a href="">
                      <i class="fa-solid fa-location-dot"></i>
                      <span>{`UAE- Dubai - Al meydan road `}</span>
                    </a>
                  </li>
                </ul>
              </article>
              <article className="servicesName sec">
                <h3 class="sectitle">Services </h3>
                <div className="mainservicebox">
                  <ul className="servesbox">
                    <li>Smart Contract Development</li>
                    <li>Blockchain Integration</li>
                  </ul>
                </div>
              </article>
              <article class="testimoniyalbox sec">
                <h3 class="sectitle testimonial">Testimonial </h3>
                <div>
                  <TestimonialCarousel
                    testimonials={testimonialArray}
                    currentElem={currentIndex}
                  />
                  <div className="carousel-container">
                    <button
                      onClick={() =>
                        currentIndex == 0
                          ? setCurrentIndex(testimonialArray.length - 1)
                          : setCurrentIndex((prev) => prev - 1)
                      }
                      className="carousel-btn"
                    >
                      <i class="fa fa-angle-left"></i>
                    </button>
                    <button
                      onClick={() => {
                        currentIndex == testimonialArray.length - 1
                          ? setCurrentIndex(0)
                          : setCurrentIndex((prev) => prev + 1);
                      }}
                      className="carousel-btn"
                    >
                      <i class="fa fa-angle-right"></i>
                    </button>
                  </div>
                </div>
              </article>
              <article className="conatctform sec">
                <h3 class="sectitle">Contact</h3>
                <div class="contact-form">
                  <form>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control clear_string"
                        placeholder="Full Name"
                        name="last_name"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        name="email"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control clear_string"
                        maxlength="10"
                        minlength="10"
                        placeholder="Mobile Number"
                        name="mobile_number"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <textarea
                        class="form-control clear_string"
                        rows="3"
                        placeholder="Message*"
                        required=""
                        name="message"
                      ></textarea>
                    </div>

                    <div class="col-12 mb-3">
                      <button type="submit" class="btn btn-submitform">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </article>
              <article className="businessHours sec">
                <h3 class="sectitle">Business Hours</h3>

                <ul class="timelist">
                  <li>
                    <div className="day">Monday</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Tuesday</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Wednesday</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Thursday</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Friday</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Saturday</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                </ul>
              </article>
              <article className="makeappitmneets sec">
                <h3 class="sectitle">Make Appointment</h3>
                <div className="contact-form">
                  <div class="col-12 mb-3" style={{position:"relative"}}>
                    <input
                      type="date"
                      ref={calendarInputRef}
                      class="form-control clear_string"
                      placeholder="Date"
                      name="currentDate"
                      // value={selectedDate}
                      // onChange={(e) => setSelectedDate(e.target.value)}
                    />
                     <div className="calanderIcon" onClick={openCalendar}><img loading="lazy" src={CalanderIconn} alt="Calendar Icon" /></div>
                  </div>
                  <div class="col-12 mb-3">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      // onChange={(e) => setSelectedTime(e.target.value)}
                    >
                      <option value="Select time">Select time</option>
                      <option>{`2:00 PM - 5:00 PM`}</option>
                    </select>
                  </div>
                  <div class="col-12 mb-3">
                    <button
                      className="btn btnmakeappitmnets"
                      data-bs-toggle="modal"
                      data-bs-target="#appotimnets"
                    >
                      Make An Appointment
                    </button>
                  </div>
                </div>
              </article>
              <article class="gallery sec sec-common">
                <h3 class="sectitle">Gallery </h3>
                <GalleryCarousel slides={galleryArray} />
              </article>
              <article className="social-links-sec sec">
                <h3 class="sectitle">Social Links</h3>
                <ul class="socil-link">
                  <li>
                    <a>
                      <i class={`fa-brands fa-facebook`}></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      <i class={`fa-brands fa-twitter`}></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      <i class={`fa-brands fa-google`}></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      <i class={`fa-brands fa-whatsapp`}></i>
                    </a>
                  </li>
                  <li>
                    <a>
                      <i class={`fa-brands fa-youtube`}></i>
                    </a>
                  </li>
                </ul>
              </article> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theme12Template;
