import React, { useContext, useEffect, useState } from "react";
import PageName from "../Utils/PageName";
import { Link } from "react-router-dom";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";
import {useTranslation} from "react-i18next";

import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { businessContext } from "../../context/businessContext";
import CircularLoader from "../Components/CircularLoader/CircularLoader";

function Planrequest_admin() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);
  // console.log("DDDDDDDDD", userData?.token)
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
  const config = {
    headers: {
      _token: userData?.token,
    },
  };

  axios
    .get(`${baseUrl}api/v1/plan/planRequest`, config)
    .then((response) => {
      // console.log(response?.data?.result, "fetched plan details");
      setData(response?.data?.result);
      setIsLoading(false);
    })
    .catch((error) => {
      console.log(error);
      setIsLoading(false);
    });
  }, []);



  const columns = [
    {
      name: "USER NAME",
      selector: "uname",
      sortable: true
    },
    {
      name: "PLAN NAME",
      selector: "pname",
      sortable: true
    },
    {
      name: "BUSINESS",
      selector: "business",
      sortable: true
    },
    {
      name: "DURATION",
      selector: "duration",
      sortable: true
    },
    {
      name: "DATE",
      selector: "created_at",
      sortable: true
    },
    {
      name: "Action",
      sortable: false,
      selector: "null",
      cell: (d) => [
        <i

          className="fas fa-file-invoice fs-5"
        ></i>,
        <i

          className="fas fa-briefcase fs-5"
        ></i>,
        <i
          key={d.id}
          data-bs-toggle="modal"
          data-bs-target="#planselect"
          onClick={() => fetchData(d.id)}
          className="first fas fa-pen"
        ></i>,


      ]
    }
  ]


  const tableData = {
    columns,
    data
  };
 const paginationOptions = {
    rowsPerPageText: t("Rows_per_page"),
    rangeSeparatorText: t("Of"),
    selectAllRowsItem: true,
    selectAllRowsItemText: t("All"),
  };
  
  // const RequestCard = () => {
  return (
    <>
      <PageName PageName="Plan Request" />
      <article>


        <div className="main">
          {isLoading ? (
            <CircularLoader size={40} />
          ) : (
            <DataTableExtensions {...tableData} filterPlaceholder="Search Planrequest">
              <DataTable
                columns={columns}
                data={data}
                noHeader
                defaultSortField="id"
                sortIcon={<SortIcon />}
                defaultSortAsc={true}
                pagination
                highlightOnHover
                dense
                 paginationComponentOptions={{
                        ...paginationOptions,
                      }}
                noDataComponent={<div style={{padding:"24px"}}>{t("No_records_found")}</div>}
              />
            </DataTableExtensions>
          )}
        </div>


        {/* <div class="row">
          <div class="col-md-12">
            <div class="tile">
              <div class="tile-body">
                <div class="table-responsive">
                  <table class="table ">
                    <thead>
                      <tr>
                        <th>USER NAME</th>
                        <th>PLAN NAME</th>
                        <th>BUSINESS</th>
                        <th>DURATION</th>
                        <th>DATE</th>
                        <th>ACTION</th>
                      </tr> 							
                    </thead>
                    <tbody>
    {loading ? (
      <>
      {data?.map((item) => (
        <tr key={item.id}>
          <td>{item.uname}</td>
          <td>{item.pname}</td>
          <td>{item.business}</td>
          <td>{item.duration}</td>
          <td>{item.created_at 	}</td>
          {/* <td><span class="badge rounded p-2 m-1 px-3 bg-success">{item.status}</span></td> 
          <td>
                          <div class="btn-group">
                             
                            
                            <Link to="/">
                              <button class="btn btn-danger" href="#">
                                <i class="bi bi-trash fs-5"></i>
                              </button>
                            </Link>
                          </div>
                        </td>
        </tr>
      ))}
  </>
      ) : (
        <p>Loading...</p>
        
      )}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </article>
    </>
  );
};

export default Planrequest_admin;
