import React, { useContext, useEffect, useState } from "react";
import PageName from "../Utils/PageName";
import { Link } from "react-router-dom";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";

import {useTranslation} from "react-i18next";

import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { businessContext } from "../../context/businessContext";
import CircularLoader from "../Components/CircularLoader/CircularLoader";

function Order_admin() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);
  // console.log("AAAAAAAAd", userData)
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    axios
      .get(`${baseUrl}api/v1/admin/orderList?page=1&limit=5`, {
        headers: {
          _token: userData?.token
        },
        // withCredentials: true,
      })
      .then((response) => {
        // console.log(response, "@@@@@@@@@@CCCCCCCC");
        setData(response?.data?.result);
        setIsLoading(false)

      })
      .catch((error) => {
        // console.log({ "msg": "fsfs", err: error });
        setIsLoading(false)

      });
  }, []);




  const columns = [
    {
      name: "ORDER ID",
      selector: "order_id",
      sortable: true
    },
    {
      name: "DATE",
      // selector: "created_at",
      selector: (row) => new Date(row?.created_at).toLocaleDateString(),
      sortable: true
    },
    {
      name: "NAME",
      selector: "name",
      sortable: true,
      // cell: (d) => {
      //   const capitalizedName = d.name.charAt(0).toUpperCase() + d.name.slice(1);
      //   return capitalizedName;
      // },
    },
    {
      name: "PLAN NAME",
      selector: "plan_name",
      sortable: true
    },
    {
      name: "PRICE",
      selector: "price",
      sortable: true
    },
    {
      name: "PAYMENT TYPE",
      selector: "payment_type",
      sortable: true
    },
    {
      name: "STATUS",
      selector: "payment_status",
      sortable: true,
      cell: (d) => {
        const capitalizedName = d.payment_status.charAt(0).toUpperCase() + d.payment_status.slice(1);
        return capitalizedName;
      },
    },
    {
      name: "COUPON",
      selector: "coupon",
      sortable: true
    },
    {
      name: "INVOICE",
      selector: "invoice",
      sortable: true
    },
    {
      name: "Action",
      sortable: false,
      selector: "null",
      cell: (d) => [

        <i
          key={d.id}
          className="fas fa-trash-alt"
        ></i>,


      ]
    }
  ]

  const customStyles = {
    
    headRow: {
      style: {
        display: "flex",
        alignItems: "stretch",
        width: "100%",
        backgroundColor: "#E9E9E9",
        minHeight: "52px",
        borderRadius: "2px 2px 0px 0px",
        padding: "8px 25px",
        border: "none",
       
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "52px",
        fontSize:"15px",
      
      },
    },
    rows: {
      style: {
        padding: "20px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "48px ",
      },
    },
  };


  const tableData = {
    columns,
    data
  };
 const paginationOptions = {
    rowsPerPageText: t("Rows_per_page"),
    rangeSeparatorText: t("Of"),
    selectAllRowsItem: true,
    selectAllRowsItemText: t("All"),
  };
  
  // const RequestCard = () => {
  return (
    <>
      <PageName PageName="Order" />

      <article>

        <div className="main">
          {isLoading ? (
            <CircularLoader size={40} />
          ) : (
            <DataTableExtensions 
            {...tableData} 
            filterPlaceholder="Search order"
            customStyles={customStyles}
            >
              <DataTable
                columns={columns}
                data={data}
                noHeader
                defaultSortField="id"
                sortIcon={<SortIcon />}
                defaultSortAsc={true}
                pagination
                highlightOnHover
                dense
                 paginationComponentOptions={{
                        ...paginationOptions,
                      }}
                customStyles={customStyles}
                noDataComponent={<div style={{padding:"24px"}}>{t("No_records_found")}</div>}
              />
            </DataTableExtensions>
          )}
        </div>

        {/* <div class="row">
          <div class="col-md-12">
            <div class="tile">
              <div class="tile-body">
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                      <th>ORDER ID</th>
                        <th>DATE</th>
                        <th>NAME</th>
                        <th>PLAN NAME</th>
                        <th>PRICE</th>					
                        <th>PAYMENT TYPE</th>									
                        <th>STATUS</th>					
                        <th>COUPON</th>					
                        <th>INVOICE</th>					
                        <th>ACTION</th>	
                      </tr> 							
                    </thead>
                    <tbody>
    {loading ? (
      <>
      {data?.map((item) => (
        <tr key={item.id}>
          <td>{item.order_id}</td>
          <td>{item.created_at}</td>
          <td>{item.name}</td>
          <td>{item.plan_name}</td>
          <td>{item.price 	}</td>
          <td>{item.payment_type 	}</td>
          <td>{item.payment_status 	}</td>
          <td>{item.coupon 	}</td>
          <td>{item.invoice 	}</td>
          {/* <td><span class="badge rounded p-2 m-1 px-3 bg-success">{item.status}</span></td> 
          <td>
                          <div class="btn-group">
                             
                            
                            <Link to="/">
                              <button class="btn btn-danger" href="#">
                                <i class="bi bi-trash fs-5"></i>
                              </button>
                            </Link>
                          </div>
                        </td>
        </tr>
      ))}
  </>
      ) : (
        <p>Loading...</p>
        
      )}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </article>
    </>
  );
};

export default Order_admin;
