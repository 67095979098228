import React, { useContext, useEffect, useRef, useState } from "react";
import PageName from "../Utils/PageName";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { businessObjContext } from "../../context/businessObjContext";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";


const Boxstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  zIndex: 999,
  borderRadius: "15px",
  border: "none",
  outline: "none",
  focus: "none",
  boxShadow:
    " rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;",
  padding: "3rem 5rem",
  "@media (max-width: 650px)": {
    width: "425px",
    padding: "2rem",
  },
  "@media (max-width: 430px)": {
    width: "380px",
    padding: "2rem",
  },
  "@media (max-width: 390px)": {
    width: "350px",
    padding: "2rem",
  },
};

const Container = styled.div`
  position: relative;
`;
const Headingwrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const Heading = styled.div`
  font-size: 24px;
  font-weight: bold;
`;
const ContantWrapper = styled.div`
  margin: 10px 0;
`;
const ContantHeading = styled.div`
  font-size: 18px;
  padding: 5px 3px;
  font-weight: bold;
`;
const Contant = styled.div`
  border: 1px solid #d8d8d8f0;
  border-radius: 5px;
  font-size: 16px;
  padding: 18px 10px;
  color: gray;
`;

const CrossIconWrapper = styled.div`
  position: absolute;
  right: -55px;
  top: -45px;
  font-size: 35px;
  @media (max-width: 650px) {
    position: absolute;
    right: -17px;
    top: -35px;
    font-size: 35px;
  }
`;
const Icon = styled.div`
  cursor: pointer;
`;

function InfluencerHire() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);
  const [showViewPopup, setShowViewPopup] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const { businessObjData, setBusinessObjData } =
    useContext(businessObjContext);
  
  const handleCloseViewPopup = () => {
    setShowViewPopup(false);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [tempData, setTempData] = useState([]);
  const [activeBusinessName, setActiveBusinessName] = useState("");
  const fromDate = useRef(null);
  const toDate = useRef(null);
  const tableExtensions = {
    export: false,
    print: false,
  };
  const [action] = useState({ fromUser: false });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paginationTotalRows, setPaginationTotalRows] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [showPopUp, setShowPopUp] = useState("");
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState([]);

  const Boxstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    zIndex: 999,
    borderRadius: "15px",
    border: "none",
    outline: "none",
    focus: "none",
    boxShadow:
      " rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;",
    padding: "3rem 5rem",
    "@media (max-width: 650px)": {
      width: "425px",
      padding: "2rem",
    },
    "@media (max-width: 430px)": {
      width: "380px",
      padding: "2rem",
    },
    "@media (max-width: 390px)": {
      width: "350px",
      padding: "2rem",
    },
  };

  const customStyles = {
    dataTableExtensions: {
      backgroundColor: "lightgray",
      padding: "10px",
    },
    headRow: {
      style: {
        backgroundColor: "#e9e9e9",
        borderRadius: "2px 2px 0 0",
        paddingLeft: "25px",
        border: "none",
        textTransform: "capitalize",
        position: "sticky",
        left: 0,
        top: 0,
        zIndex: 1,
        boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "52px",
      },
    },
    rows: {
      style: {
        padding: "6px 0px 6px 25px !important",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "58px !important",
      },
    },
    businessColumn: {
      position: "sticky",
      left: "0",
      zIndex: "1",
      backgroundColor: "#fff",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  };

  const fetchContact = (id) => {
    try {
      axios
        .get(
          `${baseUrl}api/v1/influencerhireme/getHiremedata?business_id=${id}`,
          {
            headers: {
              _token: userData?.token,
            },
            withCredentials: true,
          }
        )
        .then((response) => {
          const arr = response?.data?.data;
          console.log("arr===>>>", arr);          
          setShowPopUp(true);
          setTempData(arr);
          setData(arr || []);
          setIsLoading(false);
          setPaginationTotalRows(response?.data?.data?.length || 0);
          console.log("API Response:", response.data);
        })
        .catch((error) => {
          console.error("Error fetching contact data:", error);
          setIsLoading(false);
        });
    } catch (error) {
      console.error("Error in fetchContact:", error);
      setIsLoading(false);
    }
  };

  const handleOnSelectedRowsChange = useCallback(
    ({ selectedRows }) => {
      if (!action.fromUser) return;
      selectedRowsPerPage[currentPage] = selectedRows;
    },
    [currentPage, selectedRowsPerPage, action.fromUser]
  );

  const handlePageChange = (page) => {
    const nextPage = page;
    // fetchcontact(nextPage);
    // fetchBusinessData(page);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); 
    setSelectedRowsPerPage([]);
  };

  useEffect(() => {
    // fetchcontact();
  }, [businessObjData, rowsPerPage]);

  useEffect(() => {
    if (businessObjData?.id) {
      fetchContact(businessObjData.id);
    }
  }, [businessObjData?.id]);

  const handleViewContact = (contact) => {
    setSelectedContact(contact);
    setShowViewPopup(true);
  };

  const customStyles2 = {
    dataTableExtensions: {
      backgroundColor: "lightgray",
      padding: "10px",
    },

    tooltip_element: {
      position: "relative",
      display: "inline-block",
    },
    tooltip_element_span: {
      display: "none",
      position: "absolute",
      backgroundColor: "#f9f9f9",
      color: "#000",
      padding: "5px",
      border: "1px solid #ccc",
      zIndex: "1",
    },
    "tooltip_element:hover span": {
      display: "block",
    },
  };

  const columns = [
    // {
    //   name: "Business Name",
    //   selector: "business_id",
    //   sortable: true,
    //   cell: (row) =>
    //     row?.businesses?.map((business) => business.title).join(", ") || "__",
    //   fixed: "left",
    // },
     {
      name: `${t("Name")}`,
      selector: (row) => row?.name || "--",
      sortable: true,
      cell: (d) => {
        const capitalizedName =
          d?.name?.charAt(0).toUpperCase() + d?.name?.slice(1);
        return capitalizedName;
      },
    },
    {
      name: `${t("Date")}`,
      selector: (row) =>
        row?.created_at ? new Date(row?.created_at).toLocaleDateString() : "",
      sortable: true,
    },
   

    {
      name: `${t("Email")}`,
      selector: (row) => row?.email || "--",
      sortable: true,
      cell: (row) => (
        <div style={{ textTransform: "lowercase" }}>{row.email}</div>
      ),
    },
    {
      name: `${t("Mobile")}`,
      selector: (row) => row?.phone || "--",
      sortable: true,
    },
    {
      name: `${t("Message")}`,
      selector: "message",
      sortable: true,
      cell: (d) => (
        <span style={customStyles2?.tooltip_element}>
          <span className="toolTipMessage" title={d?.message}>
            {d?.message?.slice(0, 20)} {d?.message?.length > 20 && "..."}{" "}
          </span>
        </span>
      ),
    },
    {
      name: `${t("Action")}`,
      sortable: false,
      cell: (d) => (
        <>
          <i
            className="first fas fa-eye"
            onClick={() => handleViewContact(d)} // Handle view contact click
            style={{ cursor: "pointer", marginRight: "10px" }}
          ></i>
        </>
      ),
    },
  ];

  const [selectedDateFrom, setSelectedDateFrom] = useState("");
  const [selectedDateTo, setSelectedDateTo] = useState("");

  useEffect(() => {
    if (selectedDateTo != "" && selectedDateFrom != "") {
      if (selectedDateTo < selectedDateFrom) {
        toast.error(t("Invalid_date_selected"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        return;
      }
    }

    let filteredData = tempData;

    if (activeBusinessName && activeBusinessName !== "All Business") {
      filteredData = filteredData.filter(
        (el) => el.title === activeBusinessName
      );
    }

    if (selectedDateFrom && selectedDateTo) {
      const endOfDay = new Date(selectedDateTo);
      endOfDay.setHours(23, 59, 59, 999);

      filteredData = filteredData?.filter((el) => {
        const date = new Date(el.created_at);
        return date >= selectedDateFrom && date <= endOfDay;
      });
    }

    setData(filteredData);
  }, [activeBusinessName, selectedDateFrom, selectedDateTo]);

  const tableData = {
    columns,
    data,
  };
 const paginationOptions = {
    rowsPerPageText: t("Rows_per_page"),
    rangeSeparatorText: t("Of"),
    selectAllRowsItem: true,
    selectAllRowsItemText: t("All"),
  };
  
  return (
    <>
      <PageName PageName="Contacts" />

      <article>
        <div className="main">
          {isLoading ? (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "55%",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CircularLoader size={40} />
            </div>
          ) : (
            <>
              <div
                className=""
                style={{
                  position: "relative",
                  top: "-64px",
                  width: "70%",
                  left: "20px",
                }}
              ></div>
              <div className="scrollable-columns">
                <div className="sticky-header-container">
                  <DataTableExtensions
                    {...tableData}
                    {...tableExtensions}
                    customStyles={customStyles}
                    filterPlaceholder="Search Contact"
                  >
                    <DataTable
                      columns={columns}
                      data={data}
                      noHeader
                      defaultSortField="id"
                      sortIcon={<SortIcon />}
                      defaultSortAsc={true}
                      pagination
                      highlightOnHover
                      paginationServer
                      paginationTotalRows={paginationTotalRows}
                      paginationPerPage={paginationPerPage}
                      paginationRowsPerPageOptions={[
                        10, 20, 50, 100, 300, 1000, 10000,
                      ]} // Customize as needed
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      onSelectedRowsChange={handleOnSelectedRowsChange}
                      dense
                       paginationComponentOptions={{
                        ...paginationOptions,
                      }}
                      customStyles={customStyles}
                      noDataComponent={<div style={{padding:"24px"}}>{t("No_records_found")}</div>}
                    />
                  </DataTableExtensions>
                </div>
              </div>
            </>
          )}
        </div>
      </article>

      <Modal
        open={showViewPopup}
        onClose={handleCloseViewPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Boxstyle}>
          {selectedContact && (
            <Container>
              <CrossIconWrapper>
                <Icon onClick={handleCloseViewPopup}>x</Icon>
              </CrossIconWrapper>
              <Headingwrapper>
                <Heading> {t("Contact_details")}</Heading>
              </Headingwrapper>
              <ContantWrapper>
                <ContantHeading>{t("Name")}</ContantHeading>
                <Contant> {selectedContact.name} </Contant>
              </ContantWrapper>
              <ContantWrapper>
                <ContantHeading>{t("Email")} </ContantHeading>
                <Contant> {selectedContact.email} </Contant>
              </ContantWrapper>
              <ContantWrapper>
                <ContantHeading>{t("Phone")}</ContantHeading>
                <Contant> {selectedContact.phone} </Contant>
              </ContantWrapper>
              <ContantWrapper>
                <ContantHeading>{t("Message")}</ContantHeading>
                <Contant> {selectedContact.message} </Contant>
              </ContantWrapper>
            </Container>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default InfluencerHire;
