import React from "react";
import "./Theme11.css";
const TestimonialCarousel = ({
  testimonials,
  currentElem,
  handleTouchStart,
  handleTouchMove,
  handleTouchEnd,
  colorData
}) => {
  const testimonialStyle = {
    transform: `translateX(-${(currentElem * 100) / testimonials?.length}%)`,
    transition: "transform 0.5s ease-in-out",
    width: `${testimonials * 100}%`,
  };
  //   console.log(testimonials, currentElem, "inside carousel");
  return (
    <div
      className="testimonials-container"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="testimonials" style={testimonialStyle}>
        {testimonials.map((val, index) => {
          return (
            <div class="testibox" key={index} style={{width:""}}>
              <div className="testimonialcontent">               
                <div className="clintname">                  
                  <p className="testimonialdescription" style={{color: colorData?.dynamicTextDescriptionColor}}>
                    {val?.description != "undefined" ? val?.description : ""}
                  </p>
                  <b className="testimonialname" style={{color: colorData?.dynamicTextDescriptionColor}}>
                    {val?.name != "undefined" ? val?.name : ""}
                  </b>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TestimonialCarousel;
