import React, { useState,useContext } from 'react';
import Camera from "./../../../../Images/camcollection.png";
import Sink from "./../../../../Images/sinkcollection.png";
import Shoes from "./../../../../Images/shoescollection.png";
import Headphone from "./../../../../Images/headphonecollection.png";
import PhoneInput from "react-phone-input-2";
import Drawer from "react-bottom-drawer";
import BottomDrawer from './BottomDrawer';
import { environmentVariables } from "../../../../config/env.config";
import { currencyContext } from "../../../../context/currencyContext.js";
import { useTranslation } from "react-i18next";

const AllCollection = (props) => {
  const { t } = useTranslation();
    const data = props?.products
    const [open, setOpen] = useState(false);
    const [isVisible, setIsVisible] = React.useState(true);
    const openDrawer = React.useCallback(() => setIsVisible(true), []);
    const closeDrawer = React.useCallback(() => setIsVisible(false), []);
    const { currencyObjData } = useContext(currencyContext);

    const imageMap = {
        Camera: Camera,
        Sink: Sink,
        Shoes: Shoes,
        Headphone: Headphone,
    };

  return (


<div>
<div className='py-3 px-0 grid grid-cols-2 gap-2'>
    {data?.map((item, index) => (
        <div key={index} className='miniExtCard' style={{backgroundColor:props?.colordata?.dynamicBackgroundColor}}>
            <img loading="lazy" src={item?.imageView ? item.imageView : `${environmentVariables?.s3UrlBackendUrl}${item?.image}`} className='object-cover prodimg' alt={item?.name} />
            <div className="text-start font-normal text-base px-2 pt-1 font-Inter minimarketname" style={{color:props?.colordata?.dynamicTextDescriptionColor}}>{item?.name || item?.product_name}</div>
            <div className="flex justify-between items-center mt-1 px-1 pb-2">
                {/* { item?.product_category !== 'enquiry' && ( <div className="text-lg font-bold text-black text-sm">{currencyObjData?.symbol}{item?.price || item?.product_price}</div>)} */}
                <div className=' font-Inter'>
                    <button    className={`bg-coffee text-sm font-semibold font-regular px-2 py-2 ${props?.colordata?.dynamicCtaTextColor? '' :'text-white'} 
                            `} style={{borderRadius:"50px", backgroundColor:props?.colordata?.dynamicCtaColor,color:props?.colordata?.dynamicCtaTextColor}}>
                                {item?.product_category === 'enquiry' ? t('Enquire_now') : t('Buy_now')}
                    </button>

                </div>
                {item?.product_category !== 'enquiry' && <p style={{color:props?.colordata?.dynamicTextDescriptionColor, margin:"0",fontSize:"16px" , fontFamily:"Inter"}}>{currencyObjData?.symbol}{(item?.price || item?.product_price) ? Math.trunc(Number(item?.product_price) * 100) / 100 : null}</p>}

            </div>
        </div>
    ))}
</div>
</div>
);
};


export default AllCollection