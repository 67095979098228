// Captcha.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import "./Captcha.css";
import RefreshIcon from "./../Images/refreshcaptcha.png";
// import RefreshIcon from "./../Images/"
import { environmentVariables } from "../config/env.config";

function Captcha({ onCaptchaSolved }) {
  const { t } = useTranslation();
  const [captcha, setCaptcha] = useState({ id:"", question: '', answer: 0 });
  const [userAnswer, setUserAnswer] = useState('');
  const [invalidCaptchaErr, setInvalidCaptchaErr] = useState("");

  useEffect(() => {
    fetchCaptcha();
  }, []);

  const fetchCaptcha = async () => {
    const response = await axios.get(`${environmentVariables?.apiUrl}api/v1/business/fetchCaptcha`);
    const captcha = response.data;
    localStorage.setItem(
      "captcha",
      JSON.stringify({ id: captcha.id, question: captcha.question })
    );

    setCaptcha({id:captcha?.id, question: captcha.question, answer:0}); // Display the question
    setUserAnswer('');
    onCaptchaSolved('');
  };

  const handleChange = (e) => {
    setUserAnswer(e.target.value);
    onCaptchaSolved(e.target.value);
  };

  const handleKeyPress = (e) => {
    // Allow only numeric input
    if (e.key && !/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div>
<p className='heading' style={{ display: "flex", justifyContent: "space-between", direction: "ltr", alignItems: "center" }}>
  <span>{t("What_is")}</span>
  <span style={{ direction: "ltr", unicodeBidi: "isolate", textAlign: "right" }}>
    {captcha?.question}
  </span>
</p>

         <div className='inputbuttoncontainer'>
      <input
        type="text"
        value={userAnswer}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        placeholder={t("Input_your_answer")}
          className='captchainput'
          maxLength={2}
      />
      {/* <button onClick={fetchCaptcha}>Refresh CAPTCHA</button> */}
      <button type="button" className='refresscaptchabtn' onClick={fetchCaptcha}>
        <img className='refreshicon' src={RefreshIcon} alt="Refresh CAPTCHA" /> {t("Refresh")}
      </button>
      </div>
    </div>
  );
}

export default Captcha;
