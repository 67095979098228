import React, { useState, useEffect, useRef } from "react";
import { environmentVariables } from "../../../config/env.config";
import testimonialdefault from "../../../Images/testimonialdefault.png";
import avatarImage from "../../../Images/avatar.png";
import "./../Updatedcss/BusinessChangeBlockUpdatedStyle.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const FormikErrorStyle = styled.div`
  font-size: 11px;
  color: #ff3333;
`;

const ResumetestimonialForm = ({
  formCount,
  testimonialData,
  setIsAddTestimonialDisable,
  setTestimonialData,
  index,
  removeForm,
  setFormEditable,
  formEditable,
}) => {
  const fileInputRef = useRef();
  const openFileInput = () => {
    fileInputRef.current.click();
  };
    const {t} = useTranslation();

  const baseUrl = environmentVariables?.apiUrl;
  const [image, setImage] = useState(null);
  const [addBtnDisable, setAddBtnDisable] = useState(false);

  const [data, setData] = useState({
    formCount: formCount,
    image: null,
    projectname: "",
    projectlink: "",
    projectdescription: "",
    startDate: "",
    endDate: "",
    imageView: null,
    isImageUpload: true,
    currentlyWorking: false,
  });

  const validationSchema = Yup.object({
    projectname: Yup.string()
      .matches(/^[a-zA-Z0-9' -]+$/, t("Only_alphabets_Numbers_are_allowed"))
      .required(t("Project_name_required"))
      .min(2, t("Minimum_2_characters_required"))
      .max(45, t("Maximum_45_characters")),
    projectdescription: Yup.string()
      // .matches(/^[a-zA-Z' -]+$/, "Only accept alphabets")
      .matches(/^[a-zA-Z', .-]+$/, t("Only_accept_alphabets"))
      .min(10, t("Minimum_10_characters"))
      .max(400, t("Maximum_400_characters")),
    projectlink: Yup.string(),
    // .required("Project link is required")
    startDate: Yup.string().required(t("Start_date_required")),
    // endDate: Yup.string().when('currentlyWorking', {
    //   is: false,
    //   then: Yup.string().required('End date is required'),
    //   otherwise: Yup.string().notRequired()
    // }),
    // currentlyWorking: Yup.boolean()
  });

  const formik = useFormik({
    initialValues: {
      projectname: data.projectname || "",
      projectdescription: data.projectdescription || "",
      projectlink: data.projectlink || "",
      startDate: data.startDate || "",
      endDate: data.endDate || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleAddService(values);
    },
  });

  useEffect(() => {
    if (
      formik.values.projectname &&
      formik.values.startDate &&
      formik.values.endDate &&
      formik.values.projectdescription &&
      formik.projectlink
    ) {
      handleAddService(formik.values);
    }
  }, [formik.values]);

  const handleAddService = (values) => {
    // console.log(values, "valuessssssssss", formCount, formEditable);
    if (formEditable == 0 || formEditable) {
      formCount = formEditable;
      values.projectlink = data?.projectlink;
    }
    // console.log(formCount, "formCount--formCount", values);
    setAddBtnDisable(true);
    setIsAddTestimonialDisable(false);
    setFormEditable(null);
    const newTestimonialData = testimonialData.map((val, index) => {
      if (val.formCount == formCount) {
        console.log(val, "valalal");
        val.projectname = values?.projectname;
        val.projectlink = values?.projectlink;
        val.projectdescription = data?.projectdescription;
        val.image = image;
        val.imageView = data?.imageView;
        val.isImageUpload = false // data?.isImageUpload;
        val.startDate = values?.startDate;
        val.endDate = values?.endDate;
        val.currentlyWorking = data?.currentlyWorking;
        return val;
      }
      return val;
    });
    // const newTestimonialData = testimonialData.map((val,index) => {
    //   if (index ==data?.formCount) {
    //     val.name = data?.name;
    //     val.description = data?.description;
    //     val.image = image||data?.image;
    //     val.imageView = data?.image;
    //     return val;
    //   }
    //   return val;
    // });
    // console.log(newTestimonialData, "newTestimonialData@!#");
    setTestimonialData(newTestimonialData);
  };

  const handleEditService = (index) => {
    // console.log(
    //   !formik.isValid || !formik.dirty || !data.projectdescription,
    //   "ASDASDADADS"
    // );
    setAddBtnDisable(false);
    // setIsAddTestimonialDisable(true);//add new project  here
    let currentForm = testimonialData[index];
    // console.log(index, "inexxxxxxxxxxxxxx");
    setFormEditable(index);
    setData({
      name: currentForm?.projectname,
      projectname: currentForm?.projectname,
      projectlink: currentForm?.projectlink,
      projectdescription: currentForm?.projectdescription,
      image: currentForm?.image,
      imageView: currentForm?.imageView,
      formCount: index,
      isImageUpload: true,
      startDate: currentForm?.startDate,
      endDate: currentForm?.endDate,
      currentlyWorking: currentForm?.currentlyWorking || false,
    });
  };

  useEffect(() => {
    // console.log(testimonialData, "234testimonialData23");
    let currentForm = testimonialData[index];
    // console.log(currentForm,"currentForm21")
    setData({
      projectname: currentForm?.projectname,
      projectlink: currentForm?.projectlink,
      projectdescription: currentForm?.projectdescription,
      image: currentForm?.image,
      imageView: currentForm?.imageView,
      formCount: index,
      isImageUpload: currentForm?.isImageUpload,
      startDate: currentForm?.startDate,
      endDate: currentForm?.endDate,
      currentlyWorking: currentForm?.currentlyWorking || false,
    });
  }, [testimonialData]);

  return (
    <div
      key={`form-${formCount}`}
      className="col-12"
      id="AddNewStyleFormfieldpad"
    >
      <div className="serviceboxedittesti MobileviewServices">
        <i
          className="fa-solid fa-circle-xmark crossedit"
          onClick={() => removeForm(index)}
        >
          {" "}
        </i>

        <div className="AddDetailsTextimonialphnview" id="ResumeProjectdetails">
          {/* <span onClick={() => handleEditService(index)}>X</span> */}
          <span onClick={() => handleEditService(index)} style={{padding:"2px 15px 10px 0px", cursor:"pointer", textAlign:"end", display:"flex", justifyContent:"flex-end", width:"100%"}}><i class="fa-solid fa-pen-to-square"></i></span>
          <div
            className=""
            style={{ fontSize: "16px", fontWeight: "500", width: "100%" }}
          >
            {/* <label className="form-label">Name</label> */}
            {data["isImageUpload"] && !addBtnDisable ? (
              <div>
                <input
                  {...formik.getFieldProps("projectname")}
                  className="form-control"
                  type="text"
                  placeholder="Project Name"
                  onChange={(e) => {
                    setData({ ...data, projectname: e.target.value });
                    formik.setFieldValue("projectname", e.target.value);
                  }}
                  value={
                    data["projectname"] == "undefined"
                      ? ""
                      : data["projectname"]
                  }
                />
                {formik.touched.projectname && formik.errors.projectname ? (
                  <FormikErrorStyle id="ResumeProjectdetails">
                    {formik.errors.projectname}
                  </FormikErrorStyle>
                ) : null}
              </div>
            ) : (
              <div>
                {data["projectname"] == "undefined" ? "" : data["projectname"]}
              </div>
            )}
          </div>

          <div
            className=""
            style={{ fontSize: "16px", fontWeight: "500", width: "100%" }}
          >
            {/* <label className="form-label">Name</label> */}
            {data["isImageUpload"] && !addBtnDisable ? (
              <div>
                <input
                  {...formik.getFieldProps("projectlink")}
                  className="form-control"
                  type="text"
                  placeholder="Project link"
                  onChange={(e) => {
                    setData({ ...data, projectlink: e.target.value });
                    formik.setFieldValue("projectlink", e.target.value);
                  }}
                  value={
                    data["projectlink"] == "undefined"
                      ? ""
                      : data["projectlink"]
                  }
                />
                {formik.touched.projectlink && formik.errors.projectlink ? (
                  <FormikErrorStyle>
                    {formik.errors.projectlink}
                  </FormikErrorStyle>
                ) : null}
              </div>
            ) : (
              <div>
                {data["projectlink"] == "undefined" ? "" : data["projectlink"]}
              </div>
              // <div>https://www.google.com/</div>
            )}
          </div>
          <div
            className=""
            style={{
              fontSize: "14px",
              fontWeight: "400",
              width: "100%",
              wordBreak: "break-all",
            }}
          >
            {/* <label className="form-label">Description:</label> */}

            {data["isImageUpload"] && !addBtnDisable ? (
              <textarea
                {...formik.getFieldProps("projectdescription")}
                className="form-control "
                rows="4"
                placeholder="Project Description"
                onChange={(e) => {
                  setData({ ...data, projectdescription: e.target.value });
                  formik.setFieldValue("projectdescription", e.target.value);
                }}
                value={
                  data["projectdescription"] == "undefined"
                    ? ""
                    : data["projectdescription"]
                }
              ></textarea>
            ) : data["projectdescription"] == "undefined" ? (
              ""
            ) : (
              data["projectdescription"]
            )}
            {formik.touched.projectdescription &&
            formik.errors.projectdescription ? (
              <FormikErrorStyle id="ResumeProjectdetails">
                {formik.errors.projectdescription}
              </FormikErrorStyle>
            ) : null}
          </div>

          <div
            className=""
            style={{ fontSize: "16px", fontWeight: "500", width: "100%" }}
          >
            {data["isImageUpload"] && !addBtnDisable ? (
              <div>
                <input
                  {...formik.getFieldProps("startDate")}
                  className="form-control"
                  type="date"
                  placeholder="Start Date"
                  // onChange={(e) =>
                  //   setData({ ...data, startDate: e.target.value })
                  // }
                  onChange={(e) => {
                    setData({ ...data, startDate: e.target.value });
                    formik.setFieldValue("startDate", e.target.value);
                  }}
                  value={
                    data["startDate"] == "undefined" ? "" : data["startDate"]
                  }
                />
                {formik.touched.startDate && formik.errors.startDate ? (
                  <FormikErrorStyle>{formik.errors.startDate}</FormikErrorStyle>
                ) : null}
              </div>
            ) : (
              <div>
                {data["startDate"] == "undefined" ? "" : data["startDate"]}
              </div>
            )}
          </div>

          <div
            className=""
            style={{ fontSize: "16px", fontWeight: "500", width: "100%" }}
          >
            {data["isImageUpload"] && !addBtnDisable ? (
              <div>
                <input
                  {...formik.getFieldProps("endDate")}
                  className="form-control"
                  type="date"
                  placeholder="End Date"
                  disabled={data.currentlyWorking}
                  onChange={(e) => {
                    setData({ ...data, endDate: e.target.value });
                    formik.setFieldValue("endDate", e.target.value);
                  }}
                  value={data["endDate"] == "undefined" ? "" : data["endDate"]}
                />
                {formik.touched.endDate && formik.errors.endDate ? (
                  <FormikErrorStyle>{formik.errors.endDate}</FormikErrorStyle>
                ) : null}
              </div>
            ) : (
              <div>{data["endDate"] == "undefined" ? "" : data["endDate"]}</div>
            )}
          </div>
          <div
            className=""
            style={{ fontSize: "16px", fontWeight: "500", width: "100%" }}
          >
            <input
              type="checkbox"
              checked={data.currentlyWorking}
              disabled={!data["isImageUpload"]}
              onChange={(e) => {
                setData({ ...data, currentlyWorking: e.target.checked });
                formik.setFieldValue("currentlyWorking", e.target.value);
              }}
            />
            <label style={{ marginLeft: "5px" }}>Currently Working</label>
          </div>

          {data["isImageUpload"] && !addBtnDisable ? (
            <button
              style={{ marginTop: "15px", marginBottom: "10px" }}
              type="button"
              // disabled={addBtnDisable}
              // onClick={handleAddService}
              disabled={
                formEditable != null
                  ? false
                  : !formik.isValid || !formik.dirty || !data.projectdescription
                // || !data["imageView"]
              }
              onClick={() => {
                formik.handleSubmit();
                handleAddService({
                  projectname: data.projectname,
                  projectdescription: data.projectdescription,
                  image: image,
                  imageView: data.imageView,
                  isImageUpload: data.isImageUpload,
                  startDate: data.startDate,
                  endDate: data.endDate,
                  currentlyWorking: data.currentlyWorking,
                });
              }}
              className="btn btn-cu addbuttoneditform editButtonTextimonial"
            >
              Add
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResumetestimonialForm;
