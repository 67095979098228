import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from "react-i18next";

const Chartte = (props) => {
  const [data, setData] = useState([1,2,3,4,5]);
  let bstatus = [];
    const { t } = useTranslation();
  useEffect(() => {
    if (props?.data?.statusPhycard) {
      if (props?.data?.statusPhycard !== null && typeof props?.data?.statusPhycard === 'object' && Object.keys(props?.data?.statusPhycard).length > 0) {
        props?.data?.statusPhycard.forEach(entry => {
            bstatus[entry?.status] = entry?.total_count;
        });
        bstatus = bstatus?.filter(value => value);
        setData({bstatus:bstatus});
     }
    }
  }, [props]);
  const options = {
    series: data?.bstatus ||[1,2,3,4,5],
    chart: {
      type: 'donut',
      width: 50,
      height: 50,
    },
    labels:[t('Accepted'),t('Printed'),t('Dispatched'),t('Canceled'),t('Completed')],
  };
  return (
    <div id="chart44" >
      {data?.bstatus && data?.bstatus.length > 0 ? (
        <ReactApexChart options={options} series={options?.series} type="donut" style={{ height: '100px' }} />
      ) : (
        <> <div><br/> <br/><h6>{t("No_records_found")}</h6></div>
        </>
      )}
    </div>
  );
};
export default Chartte;
//412 20:05