import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { useState, useContext,useEffect } from "react";
import Swal from "sweetalert2";
import "./CheckoutForm.css";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import { json, useNavigate } from "react-router";
import { environmentVariables } from "../../config/env.config";
import { userContext } from "../../context/userContext";
import axios from "axios";
import successright from '../../Images/check.png'
import { currencyContext } from "../../context/currencyContext.js";
import { useTranslation } from "react-i18next";

const CheckoutForm = ({ cardId, clientSecret ,orderId,cardType,business_id,deliveryInstructions,print_business_name}) => {
   const { t } = useTranslation();

  const [isSubmit, setIsSubmit] = useState(false);
  const { userData } = useContext(userContext);
  const navigate = useNavigate();
  const [paymentPopUp, setPaymentPopUp] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(null);
  const [paymentIntent1, setPaymentIntent] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const [DeliveryDate, setDeliveryDate] = useState(null);
  const baseUrl = environmentVariables?.apiUrl;
  const { currencyObjData, setCurrencyObjData } = useContext(currencyContext);



  const handleClosePopUp = () => {
    setPaymentPopUp(false);
    if (paymentSuccess) {
      navigate("/requestcard");
    } else {
      navigate("/create-card");
    }
  };

  async function fetchConfig() {
    try {
      const response = await axios.get(
        `${baseUrl}api/v1/admin/setting/fetchConfig`,
        {
          params: {
            cnf_type: "env",
            name:['physical_card_price','Delivery_Date']
          },
        }
        );
        // console.log("1111111~~~~~")
      if (response.status === 200) {
        // console.log(response?.data?.result?.Delivery_Date, "2222222222222");
        setDeliveryDate(response?.data?.result?.Delivery_Date);
      }
    } catch (error) {
      console.error({ "Error:": error, msg: "ErrorLanding" });
    }
  }

  const handleSubmit = async (event) => {
    setIsSubmit(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    fetchConfig()

  

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${environmentVariables?.apiUrl}requestCard`,
        },
        redirect: "if_required",
      });
      // console.log(paymentIntent, "payment intent");
      if (error) {
        console.error(error);
        // handleError();
        setIsSubmit(false);
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (
        paymentIntent &&
        paymentIntent.status === "succeeded" &&
        paymentIntent?.id
      ) {
        // console.log(orderId,"ord rderIdo de")
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${environmentVariables?.apiUrl}api/v1/phy_card/phyPaymentOrder`,
          headers: {
            _token: userData?.token,
            "Content-Type": "application/json",
          },
          withCredentials: true,
          data: {
            order_id: Date.now(), // orderId,
            card_number: "1234",
            card_exp_month: "12",
            card_name: "Physical Card",
            card_req_id: cardId,
            price: paymentIntent?.amount / 100,
            price_currency: paymentIntent?.currency,
            txn_id: paymentIntent?.id,
            payment_status: "success",
            receipt: "receipt_info",
            store_id: 1,
            payment_type: "Stripe",
            qty_physical_card: 1,
            payment_data: JSON.stringify(paymentIntent),
            DeliveryDate: DeliveryDate,
            CardType:cardType,
            business_id:business_id,
            deliveryInstructions:deliveryInstructions,
            print_business_name:print_business_name
          },
        };

        axios
          .request(config)
          .then((response) => {
            // console.log("Payment succeeded");
            setIsSubmit(false);
            setPaymentIntent(paymentIntent);
            setPaymentPopUp(true);
            setPaymentSuccess(true);
          })
          .catch((error) => {
             setIsSubmit(false);
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          });
      } else {
        // console.log("Payment failed");
        // handleOther();

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${environmentVariables?.apiUrl}api/v1/phy_card/phyPaymentOrder`,
          headers: {
            _token: userData?.token,
            "Content-Type": "application/json",
          },
          withCredentials: true,
          data: {
            order_id: Date.now(),
            // order_id: orderId,
            card_number: "1234",
            card_exp_month: "12",
            card_name: "Physical Card",
            card_req_id: cardId,
            price: paymentIntent?.amount / 100,
            price_currency: paymentIntent?.currency,
            txn_id: paymentIntent?.id,
            payment_status: "failed",
            receipt: "receipt_info",
            store_id: 1,
            payment_type: "Stripe",
            qty_physical_card: 1,
            payment_date: JSON.stringify(paymentIntent),
            DeliveryDate: DeliveryDate,
            CardType:cardType,
            deliveryInstructions:deliveryInstructions,
            print_business_name:print_business_name

          },
        };

        axios
          .request(config)
          .then((response) => {
            setIsSubmit(false);
            setPaymentIntent(paymentIntent);
            setPaymentPopUp(true);
            setPaymentSuccess(false);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          });
      }
    } catch (error) {
      console.error(error);
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmit(false);
    }

    // const result = await stripe.confirmPayment({
    //   //`Elements` instance that was used to create the Payment Element
    //   elements,
    //   confirmParams: {
    //     // return_url: "",
    //     redirect: "if_required",
    //   },
    // });
    // console.log(
    //   result,
    //   "----------------------------------------------------->"
    // );
    // if (result.error) {
    //   // Show error to your customer (for example, payment details incomplete)
    //   console.log(result?.error?.message);
    //   toast.error(result?.error?.message, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // } else {
    //   console.log(result, "result");
    //   toast.success("Payment successful", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
  };


  useEffect(()=>{
    fetchConfig()
      },[fetchConfig()])

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <button
          type="submit"
          className="btn try_theme_btn mt-3 mx-auto d-block"
          disabled={!stripe}
        >
          {isSubmit ? <CircularLoader size={20} /> : "Submit"}
        </button>
      </form>
      {paymentPopUp ? (
        <>
          {" "}
          <div className="popup-outer">
            <div className="popup-overlay"></div>
            <div className="popup-inner">
              <div className="fs-1 payment_heaader">
                {paymentSuccess ? "Payment Successful" : "Payment Failed"}
              </div>
              <img loading="lazy" src={successright} class=""/>
              <div className="inner-box mt-4">
                <h1 className="name">{t("Payment_details")}</h1>
                <div className="orderbox">
                  <ul className="list-group mb-3">
                    <li className="list-group-item d-flex justify-content-between">
                      <span>{t("Receiver")}</span>
                      <strong>{t("Businessbay")}</strong>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                      <span>{t("Payment_method_type")}</span>
                      <strong>{paymentIntent1?.payment_method_types[0]}</strong>
                    </li>

                    <li className="list-group-item d-flex justify-content-between">
                      <span> {t("Amount_paid")}</span>
                      <strong>{currencyObjData?.symbol} {parseInt(paymentIntent1?.amount / 100)}</strong>
                    </li>

                    
                    <li className="list-group-item d-flex justify-content-between">
                      <span>{t("Transaction_id")}</span>
                      <strong>{paymentIntent1?.id}</strong>
                    </li>
                  </ul>
                  <button
                    type="submit"
                    className="btn try_theme_btn mx-auto d-block submitclose"
                    onClick={handleClosePopUp}
                  >
                    {t("Close")}
                  </button>
                </div>
                <div className="order-box">
                  {/* Your payment details here */}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {/* <div className="popup-outer">
        <div className="popup-inner">
          <div className="iinerboxxx mt-4">
            <h1 className="name">Payment Details</h1>

            <div className="orderbox">
              <ul className="list-group mb-3">
                <li className="list-group-item d-flex justify-content-between">
                  <span>Receiver</span>
                  <strong>Businessbay</strong>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <span> Amount</span>
                  <strong>299</strong>
                </li>

                <li className="list-group-item d-flex justify-content-between">
                  <span>Payment Method Type</span>
                  <strong>Card</strong>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <span>Transaction Id</span>
                  <strong>89127981723</strong>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <button
          type="submit"
          class="btn try_theme_btn mt-3  mx-auto d-block"
          // class="btn try_theme_btn mt-3 w-50 mx-auto d-block"
          disabled={!stripe}
        >
          {isSubmit ? <CircularLoader size={20} /> : "Submit"}
        </button>
      </form> */}
    </>
  );
};

export default CheckoutForm;
//1112