import React from "react";
import { getServicesArray } from "../../../../utils/utility";

function SubAlternateUrls(props) {
  let rs = props?.contdata;
  let colordata = props?.colordata
  

  return (
    <>
      <article className="servicesName">
        <div className="mainservicebox">
          {rs && (
            <ul className="servesbox">
              {getServicesArray(rs).map((val) => {
                const url = val?.description?.startsWith("http")
                  ? val?.description
                  : `https://${val?.description}`;
                return (
                  val?.name &&
                  val?.description && (
                    <li key={val?.name} className="service-list" >
                      <a href={url} target="_blank" rel="noopener noreferrer" style={{ color:`${colordata?.dynamicTextHeadingColor}` }}>
                      <i class="fa-solid fa-arrow-right" style={{color:colordata?.dynamicCtaColor}}></i>{val?.name}
                      </a>
                    </li>
                  )
                );
              })}
            </ul>
          )}
        </div>
      </article>
    </>
  );
}
export default SubAlternateUrls;
