import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { NewTabMenuHeadingWrapper, NewTabMenuText, NewTabMenuWrapper, NewTabOrderWrapper, OrderCardWrapper, OrderCardWrapperContainer, OrderImage, OrderImageWrapper, OrderNameWrapper, OrderParagraph, OrderStarImageWrapper, OrderTextWrapper, Ordername, OrderstarImage } from './Theme14ExtStyleComponent'
import OrderImg from "../../../../Images/Restaurant/Reviewfirstimg.png";
import OrderreviewIcon from "../../../../Images/Restaurant/reviewstar.png";
import { environmentVariables } from "../../../../config/env.config";
import {useTranslation} from "react-i18next";

const fetchReviews = async (businessId) => {
  const { t } = useTranslation();
  // console.log("in fetchReviews reviewtab 14");
  try {
    const response = await axios.get(`${environmentVariables.apiUrl}api/v1/business/fetchreviews?businessId=${businessId}`);
    if (response.data.reviews && response.data.averageRatings) {
      const reviews = response.data.reviews;
      const averageRatingsArray = reviews.map(review => {
        if (review.Reviewcontent && review.Reviewcontent.length > 0) {
          // console.log("First rating for review with id", review.id, ":", review.Reviewcontent[0].rating);
          return review.Reviewcontent[0].rating;
        }
        return null; // If no rating found
      });
      return { reviews, averageRatings: averageRatingsArray };
    } else {
      console.error('No reviews or average ratings data found in response:', response.data);
      return { reviews: [], averageRatings: [] };
    }
  } catch (error) {
    console.error('Error fetching reviews:', error);
    return { reviews: [], averageRatings: [] };
  }
};

const TabReviewrating = (props) => {

  const [reviews, setReviews] = useState([]);
  const [averageRatings, setAverageRatings] = useState([]);
  
  
  useEffect(() => {
    fetchReviews(props.rs.id).then(({ reviews, averageRatings }) => {
      setReviews(reviews);
      setAverageRatings(averageRatings);
    });
  }, [props.rs.id]);

  const renderStars = (rating) => {
    const filledStar = "★"; // Filled star Unicode character
    const emptyStar = "☆"; // Empty star Unicode character
    const starCount = Math.round(rating); // Round the rating to the nearest integer
    let stars = "";

    // Add filled stars
    for (let i = 0; i < starCount; i++) {
      stars += filledStar;
    }

    // Add empty stars (if needed)
    for (let i = starCount; i < 5; i++) {
      stars += emptyStar;
    }

    return stars;
  };

  return (
    <NewTabOrderWrapper>
      <NewTabMenuHeadingWrapper>
        <NewTabMenuText> {t("Review_rating")} </NewTabMenuText>
      </NewTabMenuHeadingWrapper>
      <OrderCardWrapperContainer>
      {reviews.length === 0 ? (
          <div style={{
            fontFamily: "Plus Jakarta Sans",
            fontWeight: 400,
            fontSize: "20px",
            color: "#101010",
            padding: "0 20px"
          }}>
           {t("No_review_rating")}.
          </div>
        ) : (
        reviews.map((review, index) => (
          <OrderCardWrapper key={index}>
            
            <OrderImageWrapper>
              {/* <OrderImage src={OrderImg} /> */}
              <OrderTextWrapper>
                <OrderNameWrapper>
                  <Ordername>{review.username}</Ordername>
                </OrderNameWrapper>
                <OrderStarImageWrapper>
                  {averageRatings[index] !== null && (
                    <OrderParagraph>
                      {renderStars(averageRatings[index])}
                    </OrderParagraph>
                  )}
                </OrderStarImageWrapper>
                <OrderParagraph>{review.review}</OrderParagraph>


              </OrderTextWrapper>
            </OrderImageWrapper>
          </OrderCardWrapper>
        ))
      )}
      </OrderCardWrapperContainer>

    </ NewTabOrderWrapper>
  )
}

export { TabReviewrating, fetchReviews };