import { environmentVariables } from "../../../config/env.config";

export const StarRating = ({ value, totalStars, starSize, themeNo }) => {
  const starElements = [];
  const fullStars = Math.floor(value);
  const halfStar = value % 1 >= 0.5 && value % 1 < 1;
  for (let i = 1; i <= totalStars; i++) {
    let imgSrc;

    if (i <= fullStars) {
      if ([15, 29, 40, 44, 52, 56]?.includes(themeNo)) {
        imgSrc = environmentVariables?.s3NewStar1;
      } else {
        // if (themeNo == "47ext") {
          // imgSrc = environmentVariables?.s3Star2;
        // } else {
          imgSrc = environmentVariables?.s3Star1;
        // }
      }
    } else if (i === fullStars + 1 && halfStar) {
      imgSrc = environmentVariables?.s3Star3;
    } else {
      // if (themeNo == "47ext") {
        // imgSrc = environmentVariables?.s3Star1;
      // } else {
        imgSrc = environmentVariables?.s3Star2;
      // }
    }

    const imgStyle = {
      width: starSize,
      height: starSize,
      marginRight: "2px",
    };

    starElements.push(
      <img loading="lazy" key={i} src={imgSrc} alt={`Star ${i}`} style={imgStyle} />
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          ...(themeNo == 53 && { justifyContent: "center" }),
        }}
      >
        {starElements}
      </div>
    </div>
  );
};

export const StarRating28Internal = ({
  value,
  totalStars,
  starSize,
  themeNo,
}) => {
  const starElements = [];
  const fullStars = Math.floor(value);
  const halfStar = value % 1 >= 0.5 && value % 1 < 1;

  for (let i = 1; i <= totalStars; i++) {
    let imgSrc;

    if (i <= fullStars) {
      imgSrc = environmentVariables?.s3Star1;
    } else if (i === fullStars + 1 && halfStar) {
      imgSrc = environmentVariables?.s3Star3;
    } else {
      imgSrc = environmentVariables?.s3Star2;
    }

    const imgStyle = {
      width: starSize,
      height: starSize,
      marginRight: "2px",
    };

    starElements.push(
      <img loading="lazy" key={i} src={imgSrc} alt={`Star ${i}`} style={imgStyle} />
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {starElements}
        <span className="ratingspan"> {value}</span>/5{" "}
      </div>
    </div>
  );
};
export const StarRating28External = ({
  value,
  totalStars,
  starSize,
  themeNo,
}) => {
  const starElements = [];
  const fullStars = Math.floor(value);
  const halfStar = value % 1 >= 0.5 && value % 1 < 1;

  for (let i = 1; i <= totalStars; i++) {
    let imgSrc;

    if (i <= fullStars) {
      imgSrc = environmentVariables?.s3Star1;
    } else if (i === fullStars + 1 && halfStar) {
      imgSrc = environmentVariables?.s3Star3;
    } else {
      imgSrc = environmentVariables?.s3Star2;
    }

    const imgStyle = {
      width: starSize,
      height: starSize,
      marginRight: "2px",
    };

    starElements.push(
      <img loading="lazy" key={i} src={imgSrc} alt={`Star ${i}`} style={imgStyle} />
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "15px",
        }}
      >
        {starElements}
        <span className="ratingspan colorheading"> {value}</span>/5{" "}
      </div>
    </div>
  );
};

export const StarRating34Internal = ({
  value,
  totalStars,
  starSize,
  themeNo,
  dynamicTextHeadingColor,
}) => {
  const starElements = [];
  const fullStars = Math.floor(value);
  const halfStar = value % 1 >= 0.5 && value % 1 < 1;

  for (let i = 1; i <= totalStars; i++) {
    let imgSrc;

    if (i <= fullStars) {
      imgSrc = environmentVariables?.s3Star1;
    } else if (i === fullStars + 1 && halfStar) {
      imgSrc = environmentVariables?.s3Star3;
    } else {
      imgSrc = environmentVariables?.s3Star2;
    }

    const imgStyle = {
      width: starSize,
      height: starSize,
      marginRight: "2px",
    };

    starElements.push(
      <img loading="lazy" key={i} src={imgSrc} alt={`Star ${i}`} style={imgStyle} />
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {starElements}{" "}
        <span
          className="ratingspan"
          style={{ color: dynamicTextHeadingColor || "" }}
        >
          {" "}
          {value}
        </span>
        /5{" "}
      </div>
    </div>
  );
};
export const StarRating36Internal = ({
  value,
  totalStars,
  starSize,
  themeNo,
  dynamicTextDescriptionColor,
}) => {
  const starElements = [];
  const fullStars = Math.floor(value);
  const halfStar = value % 1 >= 0.5 && value % 1 < 1;

  for (let i = 1; i <= totalStars; i++) {
    let imgSrc;

    if (i <= fullStars) {
      imgSrc = environmentVariables?.s3Star1;
    } else if (i === fullStars + 1 && halfStar) {
      imgSrc = environmentVariables?.s3Star3;
    } else {
      imgSrc = environmentVariables?.s3Star2;
    }

    const imgStyle = {
      width: starSize,
      height: starSize,
      marginRight: "2px",
    };

    starElements.push(
      <img loading="lazy" key={i} src={imgSrc} alt={`Star ${i}`} style={imgStyle} />
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {starElements}{" "}
        <span
          className="ratingspan"
          style={{ color: dynamicTextDescriptionColor || "" }}
        >
          {" "}
          {value}
        </span>
        /5{" "}
      </div>
    </div>
  );
};

export const StarRating34External = ({
  value,
  totalStars,
  starSize,
  themeNo,
  dynamicTextHeadingColor,
}) => {
  const starElements = [];
  const fullStars = Math.floor(value);
  const halfStar = value % 1 >= 0.5 && value % 1 < 1;

  for (let i = 1; i <= totalStars; i++) {
    let imgSrc;

    if (i <= fullStars) {
      imgSrc = environmentVariables?.s3Star1;
    } else if (i === fullStars + 1 && halfStar) {
      imgSrc = environmentVariables?.s3Star3;
    } else {
      imgSrc = environmentVariables?.s3Star2;
    }

    const imgStyle = {
      width: starSize,
      height: starSize,
      marginRight: "2px",
    };

    starElements.push(
      <img loading="lazy" key={i} src={imgSrc} alt={`Star ${i}`} style={imgStyle} />
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "15px",
        }}
      >
        {starElements}
        <span
          className="ratingspan colorheading"
          style={{ color: dynamicTextHeadingColor || "" }}
        >
          {" "}
          {value}
        </span>
        /5{" "}
      </div>
    </div>
  );
};

export const StarRating4849 = ({ value, totalStars, starSize, starColor }) => {
  const starElements = [];
  const fullStars = Math.floor(value);
  const halfStar = value % 1 >= 0.5 && value % 1 < 1;

  for (let i = 1; i <= totalStars; i++) {
    let fillColor = starColor || "#e7b91c"; // Default to yellow

    if (i <= fullStars) {
      // Full star
      starElements.push(
        <svg
          key={i}
          width={starSize}
          height={starSize}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          fill={fillColor}
          style={{ marginRight: "2px" }}
        >
          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
        </svg>
      );
    } else if (i === fullStars + 1 && halfStar) {
      // Half star
      starElements.push(
        <svg
          key={i}
          width={starSize}
          height={starSize}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginRight: "2px" }}
        >
          <defs>
            <linearGradient id={`half-fill-${i}`}>
              <stop offset="50%" stopColor={fillColor} />
              <stop offset="50%" stopColor="#ccc" />
            </linearGradient>
          </defs>
          <path
            d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
            fill={`url(#half-fill-${i})`}
          />
        </svg>
      );
    } else {
      // Empty star
      starElements.push(
        <svg
          key={i}
          width={starSize}
          height={starSize}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          fill="#ccc"
          style={{ marginRight: "2px" }}
        >
          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
        </svg>
      );
    }
  }

  return <div style={{ display: "flex" }}>{starElements}</div>;
};

export const StarRating44 = ({
  value,
  totalStars,
  starSize,
  dynamicTextHeadingColor,
}) => {
  const starElements = [];
  const fullStars = Math.floor(value);
  const halfStar = value % 1 >= 0.5 && value % 1 < 1;

  for (let i = 1; i <= totalStars; i++) {
    let imgSrc;

    if (i <= fullStars) {
      imgSrc = environmentVariables?.s3Star1;
    } else if (i === fullStars + 1 && halfStar) {
      imgSrc = environmentVariables?.s3Star3;
    } else {
      imgSrc = environmentVariables?.s3Star2;
    }

    const imgStyle = {
      width: starSize,
      height: starSize,
      marginRight: "2px",
    };

    starElements.push(
      <img loading="lazy" key={i} src={imgSrc} alt={`Star ${i}`} style={imgStyle} />
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          color: dynamicTextHeadingColor || "#0b7939",
        }}
      >
        {starElements}
      </div>
    </div>
  );
};

export const StarRating43Internal = ({ value, totalStars, starSize }) => {
  const starElements = [];
  const fullStars = Math.floor(value);
  const halfStar = value % 1 >= 0.5 && value % 1 < 1;

  for (let i = 1; i <= totalStars; i++) {
    let imgSrc;

    if (i <= fullStars) {
      imgSrc = environmentVariables?.s3Star1;
    } else if (i === fullStars + 1 && halfStar) {
      imgSrc = environmentVariables?.s3Star3;
    } else {
      imgSrc = environmentVariables?.s3Star2;
    }

    const imgStyle = {
      width: starSize,
      height: starSize,
      marginRight: "2px",
    };

    starElements.push(
      <img loading="lazy" key={i} src={imgSrc} alt={`Star ${i}`} style={imgStyle} />
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {starElements} <span className="ratingspan"> {value}</span>/5{" "}
      </div>
    </div>
  );
};

export const StarRating43External = ({
  value,
  totalStars,
  starSize,
  dynamicTextHeadingColor,
}) => {
  const starElements = [];
  const fullStars = Math.floor(value);
  const halfStar = value % 1 >= 0.5 && value % 1 < 1;

  for (let i = 1; i <= totalStars; i++) {
    let imgSrc;

    if (i <= fullStars) {
      imgSrc = environmentVariables?.s3Star1;
    } else if (i === fullStars + 1 && halfStar) {
      imgSrc = environmentVariables?.s3Star3;
    } else {
      imgSrc = environmentVariables?.s3Star2;
    }

    const imgStyle = {
      width: starSize,
      height: starSize,
      marginRight: "2px",
    };

    starElements.push(
      <img loading="lazy" key={i} src={imgSrc} alt={`Star ${i}`} style={imgStyle} />
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "15px",
        }}
      >
        {starElements}
        <span
          className="ratingspan"
          style={{ color: dynamicTextHeadingColor || "" }}
        >
          {" "}
          {value}
        </span>
        /5{" "}
      </div>
    </div>
  );
};

export const StarRating36External = ({
  value,
  totalStars,
  starSize,
  dynamicTextDescriptionColor,
}) => {
  const starElements = [];
  const fullStars = Math.floor(value);
  const halfStar = value % 1 >= 0.5 && value % 1 < 1;

  for (let i = 1; i <= totalStars; i++) {
    let imgSrc;

    if (i <= fullStars) {
      imgSrc = environmentVariables?.s3Star1;
    } else if (i === fullStars + 1 && halfStar) {
      imgSrc = environmentVariables?.s3Star3;
    } else {
      imgSrc = environmentVariables?.s3Star2;
    }

    const imgStyle = {
      width: starSize,
      height: starSize,
      marginRight: "2px",
    };

    starElements.push(
      <img loading="lazy" key={i} src={imgSrc} alt={`Star ${i}`} style={imgStyle} />
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "15px",
        }}
      >
        {starElements}
        <span
          className="ratingspan colorheading"
          style={{ color: dynamicTextDescriptionColor }}
        >
          {" "}
          {value}
        </span>
        /5{" "}
      </div>
    </div>
  );
};
