import React from 'react'
import { AboutParagraph, AboutTabWrapper, AboutText, AboutTextWrapper, OpeningHoursContainer, OrderCardWrapperContainer, OrderCardWrapperContainerABOUT } from './Theme14ExtStyleComponent'
import { TabAlternateUrls } from "./TabAlternateUrls";

const TabAbout = (props) => {
  let colordata = props?.colordata;
  const rs = props?.rs;
  return (
    <OrderCardWrapperContainerABOUT style={{height:"65vh", marginBottom:"50px"}}>
    <OpeningHoursContainer style={{background: colordata?.dynamicBackgroundColor || ""}}>
    <AboutTextWrapper>
      {/* <AboutText>About</AboutText> */}
      <AboutParagraph dangerouslySetInnerHTML={{ 
        __html: props?.rs?.description?.replace(/(?:\r\n|\r|\n)/g, '<br>') 
      }} style={{color: colordata?.dynamicTextDescriptionColor || ""}}>
      </AboutParagraph>
    </AboutTextWrapper>
    </OpeningHoursContainer>
   { rs?.alternatUrlObj?.is_enabled == 1 &&(<div className='url_container'>
    <TabAlternateUrls rs={rs}  colordata={colordata}/>
    </div>)}
     </OrderCardWrapperContainerABOUT>
     
  )
}

export default TabAbout