import axios from "axios";
import React, { useContext, useEffect, useState, useRef } from "react";
import { shippingAddressValidation } from "../../common/shippingAddressValidation";
import { environmentVariables } from "../../config/env.config";
import { Formik, useFormik } from "formik";
import { userContext } from "../../context/userContext";
import { toast } from "react-toastify";
import { businessContext } from "../../context/businessContext";
import { useNavigate } from "react-router";

import { load } from "@cashfreepayments/cashfree-js";
import { useLocation, useParams } from "react-router-dom";
import CircularLoader from "../Components/CircularLoader/CircularLoader.js";
import { currencyContext } from "../../context/currencyContext.js";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import logoWhite from "../../Images/logo-white.png";
import Crown from "../../Images/crown.png";
import Swal from "sweetalert2";
import PageName from "../Utils/PageName";
import { businessObjContext } from "../../context/businessObjContext";
import "./OrderNowUpdatedStyle.css";
import ViewCard from "./Physical Card/ViewCard.js";
import ViewCardDownload from "./Physical Card/ViewCardDownload.js";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import DpoIcon from "../../Images/DPOlogo.webp";
import { useTranslation } from "react-i18next";

const customStyles = {
  dataTableExtensions: {
    backgroundColor: "lightgray",
    padding: "10px",
  },
  headRow: {
    style: {
      backgroundColor: "#e9e9e9",
      borderRadius: "2px 2px 0 0",
      paddingLeft: "25px",
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#525252",
      fontSize: "16px",
      minHeight: "52px",
    },
  },
  rows: {
    style: {
      padding: "6px 0px 6px 25px",
      fontSize: "14px",
      textTransform: "capitalize",
      minHeight: "58px !important",
    },
  },
};

function OrderNow() {
  const { t } = useTranslation();  
  const baseUrl = environmentVariables?.apiUrl;
  const [isSubmit, setIsSubmit] = useState(false);
  const [isPaymentBtnClicked, setIsPaymentBtnClicked] = useState(false);
  const [isLoadingAdd, setIsLoadingAdd] = useState(false);
  const [isLoadingPlanSummary, setIsLoadingPlanSummary] = useState(false);
  const { userData } = useContext(userContext);
  const [discountData, setDiscountData] = useState(null);
  const [discountCode, setDiscountCode] = useState("");
  const [cashfree, setCashfree] = useState(null);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [cardTotalAmount, setCardTotalAmount] = useState("");
  const [DeliveryDate, setDeliveryDate] = useState(null);
  const [cardAmountAfterDiscount, setCardAmountAfterDiscount] = useState("");
  const [paymentMode, setPaymentMode] = useState(null);
  const [addressData, setAddressData] = useState(null);
  const [addressEditData, setAddressEditData] = useState(null);
  const [AddressType, setAddressType] = useState("");
  const [error, setError] = useState("");
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [thumbnailImage, setThumbnailImage] = useState(null);

  const [planSummary, setPlanSummary] = useState({
    totalPvcCount: 0,
    usedPvcPlan: 0,
    totalMetalCount: 0,
    usedMetalPlan: 0,
  });

  
  const [planDetails, setPlanDetails] = useState([]);
  const navigate = useNavigate();
  const { businessData } = useContext(businessContext);
  const [cardId, setCardId] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const { currencyObjData, setCurrencyObjData } = useContext(currencyContext);
  const [pvcBooked, setPvcBooked] = useState(null);
  const [metalBooked, setMetalBooked] = useState(null);
  const [deliveryInstructions, setDeliveryInstructions] = useState("");
  const [showOptions, setShowOptions] = useState(null);
  const dropdownRef = useRef();
  const { businessObjData, setBusinessObjData } =
    useContext(businessObjContext);
  const [isCouponApplied, setIsCouponApplied] = useState(false);

  let initializeSDK = async function () {
    let cashfree1 = await load({
      mode: "sandbox",
    });
    setCashfree(cashfree1);
  };
  useEffect(() => {
    initializeSDK();
  }, []);
  //for razorpay
  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  async function fetchConfig(currencyName) {
    try {
      const response = await axios.get(
        `${baseUrl}api/v1/admin/setting/fetchConfig`,
        {
          params: {
            cnf_type: "env",
            name: [
              "physical_card_price",
              "Delivery_Date",
              "Physical_card_price_Inr",
              "Physical_card_price_USA",
              "Physical_card_price_UAE",
            ],
          },
        }
      );
      if (response.status === 200) {
        switch (currencyName.toUpperCase()) {
          case "INR":
            setCardTotalAmount(
              response?.data?.result?.Physical_card_price_Inr ||
                response?.data?.result?.physical_card_price ||
                299
            );
            setCardAmountAfterDiscount(
              response?.data?.result?.Physical_card_price_Inr ||
                response?.data?.result?.physical_card_price ||
                299
            );
            break;
          case "USD":
            setCardTotalAmount(response?.data?.result?.Physical_card_price_USA);
            setCardAmountAfterDiscount(
              response?.data?.result?.Physical_card_price_USA
            );
            break;
          case "AED":
            setCardTotalAmount(response?.data?.result?.Physical_card_price_UAE);
            setCardAmountAfterDiscount(response?.data?.result?.Physical_card_price_UAE);
            break;
          default:
            // Set a default value or handle other currencies if needed
            setCardTotalAmount(response?.data?.result?.physical_card_price);
            setCardAmountAfterDiscount(response?.data?.result?.physical_card_price);
            break;
        }
        // setCardTotalAmount(response?.data?.result?.physical_card_price);
        setDeliveryDate(response?.data?.result?.Delivery_Date);
        // setCardAmountAfterDiscount(response?.data?.result?.physical_card_price);
      }
    } catch (error) {
      console.error({ "Error:": error, msg: "ErrorLanding" });
    }
  }

  useEffect(() => {
    if (currencyObjData) {
      const currencyName = currencyObjData?.name || "INR";
      fetchConfig(currencyName);
    }
  }, [currencyObjData]);

  useEffect(() => {
    setDisableButton(true);
  }, []);

  const location = useLocation();
  const { card } = location.state || {};
  // console.log(card, title, desig, businessId, logoimg,cardname,frontimg,cardid,islogo ,"!@Wsfr423f");
  const params2 = useParams();
  const params = new URLSearchParams(location.search);
  // console.log(params.get("islogo"), "tyagityagityagi");

  let SelectedTitle = params.get("title");
  let SelectedDesignation = params.get("desig");
  let Selectedid = params.get("businessId");
  let logoimg = params.get("logoimg");
  let cardname = params.get("cardname");
  let frontimg = params.get("frontimg");
  let cardid = params.get("cardid");
  let islogo = params.get("islogo");
  // let SelectedTitle = location.state && location.state.title;
  // let SelectedDesignation = location.state && location.state.desig;
  // let Selectedid = location.state && location.state.businessId;
  // let logoimg = location.state && location.state.logoimg;
  // let cardname = location.state && location.state.cardname;
  // let frontimg = location.state && location.state.frontimg;
  // let cardid = location.state && location.state.cardid;
  // let islogo = location.state && location.state.islogo;

  const [selectBusinessTitle, setSelectBusinessTitle] = useState("");
  let fetchLocalObbj = localStorage.getItem("physicalCardTempObj");
  useEffect(() => {
    setSelectBusinessTitle(SelectedTitle);
  }, [SelectedTitle]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (
      params.get("card") == null ||
      params.get("card") == "" ||
      params.get("card") == undefined
    ) {
      toast.error(t("Card_not_found") || error?.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setTimeout(() => {
        navigate("/create-card");
      }, 6000);
    } else {
      setCardId(params.get("card"));
    }
  }, []);

  useEffect(() => {
    // console.log(cardId, "params");
  }, [cardId]);

  useEffect(() => {
    // console.log(cardId, "112 params");
    getPhysicalCard();
    getDiscountInfo();
  }, [cardId]);
  const getDiscountInfo = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/coupon/getAllActiveCoupons`,
      headers: {
        _token: userData?.token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setDiscountData(response?.data?.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getPhysicalCard = () => {
    if (cardId) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${environmentVariables?.apiUrl}api/v1/phy_card/getPhysicalCard`,
      };

      axios
        .request(config)
        .then((response) => {
          const selectedCard = response?.data?.data?.filter(
            (val) => val.id == cardId
          );
          setThumbnailImage(
            `${environmentVariables?.apiUrl}${selectedCard[0]?.card_name}/${selectedCard[0]?.thumbnail_image}`
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleProceedNext = (cardTypeStr) => {
    setIsPaymentBtnClicked(true);

    if (!selectedAddress) {
      document.querySelector(".NewAddress").click();
      setIsPaymentBtnClicked(false);
      return;
    }

    let data = {
      card_req_id: parseInt(cardId),
      flexRadioDefault: selectedAddress,
      PaymentType: "Stripe",
      currency: currencyObjData?.name,
      price: cardTotalAmount,
      cardType: cardTypeStr,
      DeliveryDate: DeliveryDate,
      deliveryInstructions: deliveryInstructions,
      business_id: Selectedid,
      business_name: SelectedTitle || selectBusinessTitle,
      selectBusinessTitle,
      logourl: logoimg,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/user/create-payment-intent`,
      data: {
        amount: parseInt(cardAmountAfterDiscount * 100),
        currency: currencyObjData?.name,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response?.data?.clientSecret) {
          navigate("/payment", {
            state: {
              data: response?.data?.clientSecret,
              amount: cardAmountAfterDiscount || cardTotalAmount,
              cardId: cardId,
              cardType: cardTypeStr,
              business_id: Selectedid,
              deliveryInstructions: deliveryInstructions,
              print_business_name: SelectedTitle || selectBusinessTitle,
              selectBusinessTitle,
              logourl: logoimg,
              SelectedDesignation,
            },
          });
          setIsPaymentBtnClicked(false);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || error?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsPaymentBtnClicked(false);
      });
  };

  const handleProceedDPO = async (cardTypeStr) => {
    try {
      setIsPaymentBtnClicked(true);
      const response = await fetch(
        `${environmentVariables?.apiUrl}api/v1/payment/getTransToken`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // Provide any necessary data for the transaction
            amount: cardAmountAfterDiscount || cardTotalAmount,
            currency: currencyObjData?.name,
            DeliveryDate: DeliveryDate,
            deliveryInstructions: deliveryInstructions,
            business_id: Selectedid,
            print_business_name: SelectedTitle || selectBusinessTitle,
            selectBusinessTitle,
            cardType: cardTypeStr,
            cardId: cardId,
            logourl: logoimg,
          }),
        }
      );

      const data = await response.json();

      // Handle the response from the backend
      if (response.ok) {
        // Payment initiated successfully
        console.log("Payment initiated:", data);
        if (
          data.success &&
          data.result &&
          data.result.API3G &&
          data.result.API3G.TransToken &&
          data.result.API3G.TransRef
        ) {
          // Redirect to the payment URL with the received token
          // window.location.href = `https://secure.3gdirectpay.com/payv3.php?ID=${data.result.API3G.TransToken._text}`;
          const paymentPlatformUrl = `https://secure.3gdirectpay.com/payv3.php?ID=${data.result.API3G.TransToken._text}`;
          // Add more data as needed

          // Navigate to the payment platform
          window.location.href = paymentPlatformUrl;
          setIsPaymentBtnClicked(false);
        } else {
          console.error("Missing data for redirect");
          setIsPaymentBtnClicked(false);
        }
      } else {
        // Payment initiation failed
        console.error("Error initiating payment:", data.message);
        setIsPaymentBtnClicked(false);
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
      setIsPaymentBtnClicked(false);
    }

    setIsPaymentBtnClicked(false);
  };

  //function for choosing payment gateway for india randomly
  const handleProceedPaymentGatewayIndia=async (cardTypeStr)=>{
    axios.get(`${environmentVariables?.apiUrl}api/v1/pg/method`).then(response=>{
      if(response?.data?.success==true){
        if(response?.data?.data=='razorpay'){
          handleProceedRazorPay(cardTypeStr)

        }else if(response?.data?.data=='cashfree'){
          handleProceedCashFree(cardTypeStr);

        }else{
          handleProceedCashFree(cardTypeStr);

        }
      }else{
        toast.error(response?.data?.message)
      }
    }).catch(err=>{
      console.log(err);
      toast.error(err?.response?.data?.message||err?.message)
    })
  }
  //function for Cashfree Payment gateway
  const handleProceedCashFree=async (cardTypeStr)=>{
    const data = {
      qty_physical_card: 1,
      store_id: 1,
      payment_type: "Razor Pay",
      card_number: "1234",
      card_exp_month: "12",
      card_name: "Physical Card",
      
      price: cardAmountAfterDiscount || cardTotalAmount,
      price_currency: currencyObjData?.name,
      DeliveryDate: DeliveryDate,
      card_req_id: cardId,
      payment_status: "pending",
      CardType: selectedOption,
      business_id: Selectedid,
      deliveryInstructions: deliveryInstructions,
      print_business_name: SelectedTitle || selectBusinessTitle,
      
      flexRadioDefault: selectedAddress,
      SelectedDesignation: SelectedDesignation,
      logourl: logoimg,
    };
    const config = {
      method: "post",
      url: `${environmentVariables?.apiUrl}api/v1/pg/cashfree/CreateOrder`,
      withCredentials: true,
      headers: {
        _token: userData?.token,
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        // console.log(response,'cashfreeresponseaftercreateorder')
        const doPayment = async () => {
          let checkoutOptions = {
            paymentSessionId: response?.data?.data?.payment_session_id,
            redirectTarget: "_self",
          };
          cashfree.checkout(checkoutOptions);
        };
        doPayment();
        setIsPaymentBtnClicked(false);
      })
      .catch((error) => {
        console.log(error);
        setIsPaymentBtnClicked(false);
        toast.error(error?.response?.data?.message || error?.message);
      });
  }
  //for razorpay payment
  const handleProceedRazorPay = async (cardTypeStr) => {
    setIsPaymentBtnClicked(true);
    const resRazorPay = await loadRazorpayScript();
    if (!resRazorPay) {
      toast.error(t("Razorpay_sdk_failed_to_load"));
      setIsPaymentBtnClicked(false);
    }
    const data = {
      qty_physical_card: 1,
      store_id: 1,
      payment_type: "Razor Pay",
      card_number: "1234",
      card_exp_month: "12",
      card_name: "Physical Card",
      
      price: cardAmountAfterDiscount || cardTotalAmount,
      price_currency: currencyObjData?.name,
      DeliveryDate: DeliveryDate,
      card_req_id: cardId,
      payment_status: "pending",
      CardType: selectedOption,
      business_id: Selectedid,
      deliveryInstructions: deliveryInstructions,
      print_business_name: SelectedTitle || selectBusinessTitle,
      
      flexRadioDefault: selectedAddress,
      SelectedDesignation: SelectedDesignation,
      logourl: logoimg,
    };
    const config = {
      method: "post",
      url: `${environmentVariables?.apiUrl}api/v1/pg/razorpay/CreateOrder`,
      withCredentials: true,
      headers: {
        _token: userData?.token,
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        const options = {
          key: process.env.RAZORPAY_ID, // Enter the Key ID generated from the Dashboard
          amount: parseFloat(
            Number(response?.data?.data?.amount) / 100
          ).toFixed(2),
          currency: response?.data?.data?.currency,
          name: "Businessbay",
          description: "Your Transaction",
          order_id: response?.data?.data?.id,
          handler: (response) => {
            console.log(response, "//////////////");
            window.location.href = `/paymentPhysical?status=success&transId=${response.razorpay_payment_id}&orderid=${response?.razorpay_order_id}&amount=${cardAmountAfterDiscount || cardTotalAmount}`;
            // Optionally, handle the response here (e.g., store the payment info in your backend)
          },
          theme: {
            color: "#3399cc",
          },
          modal: {
            ondismiss: () => {
              console.log("failure");

              toast.error(t("Payment_failed"));

              // Redirect to failure page
              // window.location.href = "/redirect?status=failure";
            },
          },
        };
        const rzp = new window.Razorpay(options);
        rzp.on("payment.failed", function (response) {
          setIsPaymentBtnClicked(false);
          
          window.location.href = `/paymentPhysical?status=failure&payment_id=${response.error.metadata.payment_id}&orderid=${response?.error?.metadata?.order_id}`;
        });
        rzp.open();
        setIsPaymentBtnClicked(false);
      })
      .catch((error) => {
        console.log(error);
        setIsPaymentBtnClicked(false);
        toast.error(error?.response?.data?.message || error?.message);
      });
  };

  const handletoBuy = async (cardType) => {
    setIsPaymentBtnClicked(true);

    if (!selectedAddress) {
      document.querySelector(".NewAddress").click();
      setIsPaymentBtnClicked(false);
      return;
    }
    try {
      const response = await axios.post(
        `${environmentVariables?.apiUrl}api/v1/phy_card/card_request`,
        {
          card_req_id: parseInt(cardId),
          flexRadioDefault: selectedAddress,
          PaymentType: "By Plan",
          currency: currencyObjData?.name,
          price: cardTotalAmount,
          paymentStatus: "Success",
          cardType: cardType,
          DeliveryDate: DeliveryDate,
          deliveryInstructions: deliveryInstructions,
          business_id: Selectedid,
          business_name: SelectedTitle || selectBusinessTitle,
          logourl: logoimg,
          SelectedDesignation,
        },
        {
          headers: {
            _token: userData?.token,
          },
        }
      );
      setIsPaymentBtnClicked(false);

      if (response.data && response.data.status === "success") {
        toast.success(t("Order_successful_create"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        navigate("/requestCard");
        getPlanSummary(1);
      } else {
        toast.error(
          t("Error_creating_order"),
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          }
        );
        console.error("Error creating order:", response.data.message);
      }
    } catch (error) {
      toast.error(t("Designation_required"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsPaymentBtnClicked(false);
      console.error("Error:", error.message);
    }
  };

  const getPlanSummary = (init = 0) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/phy_card/user_plan`,
      headers: {
        _token: userData?.token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setPlanSummary(response?.data?.userData);
        setPlanDetails(response?.data?.finalArray || []);
        init === 0 &&
          setPvcBooked(
            response?.data?.userData?.userPlan?.no_of_pvc_card -
              response.data?.userData?.usedPvcPlan
          );
        init === 0 &&
          setMetalBooked(
            response?.data?.userData?.userPlan?.no_of_metal_card -
              response.data?.userData?.usedMetalPlan
          );
        setIsLoadingPlanSummary(false);
      })
      .catch((error) => {
        setIsLoadingPlanSummary(false);
      });
  };

  const handleApplyCoupon = () => {
    const selectedDiscount = discountData.filter(
      (val) => val.code == discountCode
    );
    if (selectedDiscount.length == 0) {
      setError("No Promo Code Found");
    } else {
      handleSelectDiscount(selectedDiscount[0]?.id);
      setError("");
    }
  };

  const handleRemoveCoupon = () => {
    setIsCouponApplied(false);
    setDiscountCode("");
    setDiscountAmount(0);
    setCardAmountAfterDiscount(cardTotalAmount);
  };

  const handleSelectDiscount = (id) => {
    const selectedObj = discountData.find((val) => val.id === id);

    if (selectedObj) {
      // Check if card total amount is greater than or equal to the coupon limit
      if (cardTotalAmount < selectedObj.limit) {
        console.error(
          `Card total amount is less than the coupon limit for ID: ${id}`
        );
        // You can handle this case as per your requirements, e.g., show an error message
        toast.error(
          `Oops! Your cart total should be at least ${selectedObj?.limit} to apply this coupon. Add more items to enjoy the discount!`,
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          }
        );
        return;
      }

      setSelectedDiscount(id);
      setDiscountCode(selectedObj?.code);
      setIsCouponApplied(true);

      if (selectedObj?.dis_type === "percentage") {
        let disamount = parseFloat(
          (selectedObj?.discount / 100) * cardTotalAmount
        ).toFixed(2);
        let afterDiscount = parseFloat(cardTotalAmount - disamount).toFixed(2);

        setDiscountAmount(disamount);
        setCardAmountAfterDiscount(afterDiscount);
      } else if (selectedObj?.dis_type === "flat") {
        // let disamount = parseFloat(selectedObj?.discount).toFixed(2);
        const discount = selectedObj?.discount;
        const currentCurrencyValue = currencyObjData?.current_value;
        let disamount = (discount * currentCurrencyValue).toFixed(2);
        let afterDiscount = parseFloat(cardTotalAmount - disamount).toFixed(2);

        setDiscountAmount(disamount);
        setCardAmountAfterDiscount(afterDiscount);
      }
    } else {
      // Handle the case where no discount is found
      console.error(`No discount found for ID: ${id}`);
    }
  };

  const getAddressList = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}api/v1/shipping/shippingFetch`,
      headers: {
        _token: userData?.token,
      },
      // withCredentials: true,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response?.data?.result_count, "response?.data?.result_countresponse?.data?.result_count")
        if (response?.data?.result_count == 0) {
          // Trigger the Bootstrap modal
          // console.log("Trigger the Bootstrap modalTrigger the Bootstrap modal")
          document.querySelector(".NewAddress").click();
        }
        setAddressData(response?.data?.result?.shippingData);
        setSelectedAddress(response?.data?.result?.shippingData[0]?.id);
        setIsLoadingAdd(false);
      })
      .catch((error) => {
        setIsLoadingAdd(false);
        console.log(error);
      });
  };
  useEffect(() => {
    setIsLoadingAdd(true);
    setIsLoadingPlanSummary(true);
    getAddressList();
    getPlanSummary(0);
  }, []);
  const initialValuesShippingAddress = {
    address_type: "",
    fullname: "",
    mobile1: "",
    address1: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    landmark: "",
  };
  const handleEditAddress = (id) => {
    const editedAddress = addressData.filter((val) => val?.id == id);
    setAddressEditData(editedAddress[0]);
    setAddressType(editedAddress[0]?.address_type);
    formikEdit.setValues({
      address_type: editedAddress[0]?.address_type,
      fullname: editedAddress[0]?.fullname,
      mobile1: editedAddress[0]?.mobile1,
      address1: editedAddress[0]?.address1,
      address2: editedAddress[0]?.address2,
      country: editedAddress[0]?.country,
      state: editedAddress[0]?.state,
      city: editedAddress[0]?.city,
      pincode: editedAddress[0]?.pincode,
      landmark: editedAddress[0]?.landmark,
    });
  };

  const handleDeleteAddress = (id) => {
    Swal.fire({
      title: t("Are_you_sure"),
      text: t("You_wont_be_able_to_revert_this"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yes_delete_it"),
      cancelButtonText: t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        let config = {
          method: "delete",
          url: `${environmentVariables?.apiUrl}api/v1/shipping/deleteShippingAddress/${id}`,
          headers: {
            "Content-Type": "application/json",
            _token: userData?.token,
          },
          withCredentials: true,
        };

        axios
          .request(config)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Shipping address has been deleted.",
            });
            getAddressList();
            // You may want to update your component's state or trigger a re-fetch of address data
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: error?.response?.data?.message || error?.message,
            });
          });
      }
    });
  };

  const handleChangeAddressType = (event) => {
    setAddressType(event.target.value);
    const selectedValue = event.target.value;
    if (event.target.value === "Home") {
      formik.setFieldValue("address_type", "Home");
    } else if (event.target.value === "Work") {
      formik.setFieldValue("address_type", "Work");
    } else {
      formik.setFieldValue("address_type", null);
    }
    setDisableButton(selectedValue === "-1");

    // If using formikEdit
    if (formikEdit) {
      if (event.target.value === "Home") {
        formikEdit.setFieldValue("address_type", "Home");
      } else if (event.target.value === "Work") {
        formikEdit.setFieldValue("address_type", "Work");
      } else {
        formikEdit.setFieldValue("address_type", null);
      }
    }
  };
  const [disableButton, setDisableButton] = useState(true);

  const resetFormik = () => {
    formik.resetForm();
    formik.resetForm({
      values: {
        ...formik.initialValues,
        address_type: "",
      },
    });
  };

  let formik = useFormik({
    initialValues: initialValuesShippingAddress,
    validationSchema: shippingAddressValidation(),
    onSubmit: async () => {
      setIsSubmit(true);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${environmentVariables?.apiUrl}api/v1/shipping/shippingMake`,
        headers: {
          _token: userData?.token,
        },
        data: values,
      };

      axios
        .request(config)
        .then((response) => {
          toast.success(t("Shipping_address_saved"), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });

          setIsSubmit(false);
          getAddressList();
        })
        .catch((err) => {
          // console.log(err?.response?.data?.message,"eee")
          toast.error(
            err?.response?.data?.message?.message ||
              err?.response?.data?.message,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
          setIsSubmit(false);
        });
    },
  });
  let formikEdit = useFormik({
    initialValues: initialValuesShippingAddress,
    validationSchema: shippingAddressValidation(),
    onSubmit: async () => {
      setIsSubmit(true);

      let data = JSON.stringify(formikEdit.values);

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${environmentVariables?.apiUrl}api/v1/shipping/editShippingAddress/${addressEditData?.id}`,
        headers: {
          _token: userData?.token,
          "Content-Type": "application/json",
        },
        // withCredentials: true,
        data: data,
      };
      // console.log(data, "&&&&afw");
      axios
        .request(config)
        .then((response) => {
          toast.success(t("Shipping_address_updated"), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsSubmit(false);
          getAddressList();

          if (typeof bootstrap !== "undefined" && bootstrap.Modal) {
            const modal = new bootstrap.Modal(createUserRef.current);
            modal.hide(); // Close the modal
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsSubmit(false);
        });
    },
  });
  const { values, errors, handleSubmit } = formik;

  const [selectedOption, setSelectedOption] = useState("pvc");

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const toggleClass = () => {
    setIsActive(!isActive);
  };

  const columns = [
    {
      name: "PlanName",
      selector: "name",
      sortable: true,
    },
    {
      name: "Duration",
      selector: "duration",
      sortable: true,
    },
    {
      name: "PVC Cards",
      selector: "no_of_pvc_card",
      sortable: true,
    },
    {
      name: "Metal Cards",
      selector: "no_of_metal_card",
      sortable: true,
    },
    {
      name: "Plan Expiry Date",
      // selector: "expiry_date",
      selector: (row) => new Date(row?.expiry_date).toLocaleDateString(),
      sortable: true,
    },
  ];

  const tableData = {
    columns,
    data: planDetails,
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Cleanup the event listener when the component is unmounted
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    // console.log(SelectedTitle, SelectedDesignation, Selectedid, "Selectedid 1111111")

    if (fetchLocalObbj && fetchLocalObbj.length) {
      let get = JSON.parse(fetchLocalObbj);
      setTimeout(() => {
        if (location?.pathname?.includes("ordernow")) {
          SelectedTitle = get.SelectedTitle;
          SelectedDesignation = get.SelectedDesignation;
          Selectedid = get.Selectedid;
          // console.log(SelectedTitle, SelectedDesignation, Selectedid, "Selectedid 2222222")
          //     navigate(`/ordernow?card=${get?.SelectedTab}
          // &title=${get?.SelectedTitle}&desig=${get?.SelectedDesignation}
          // &businessId=${get?.Selectedid}
          // `);
        }
        // setBusinessNameObj(get);
        // localStorage.removeItem("PhysicalCardBusinessObj");
      });
    }
  }, []);

  const ViewCardShort = () => {
    const [fontFile, setFontFile] = useState("");
    const [fontFile2, setFontFile2] = useState("");
    const [styleFile, setStyleFile] = useState("");
    // console.log(fontFile, "fontFile")
    // console.log(fontFile2, "fontFile2")

    const getFont = (cardId) => {
      // console.log(cardId, "cardid")
      axios
        .get(
          `${environmentVariables?.apiUrl}api/v1/phy_card/get_fonts/${cardid}`
        )
        .then((res) => {
          setFontFile(res?.data?.fontFiles[0]?.data);
          setFontFile2(res?.data?.fontFiles[1]?.data);
        })
        .catch((err) => {});
    };

    useEffect(() => {
      getFont();
    });

    const [data_props, setData_props] = useState({
      physicalCardLoading: false,
      card_id: 3,
      activeCardData: {
        id: 3,
        front_image: `${environmentVariables?.apiUrl}${cardname}/front_image_Front.png`,
      },
      name: "fff",
      degination: "fsdfas",
      weburl: "www.xyz.com",
      card_logo: null,
      logo: "https://vmycards.com/storage/card_logo/logo_16969236651843057845.png",
    });
    const getLogoImageForPhysicalCards = () => {
      if (cardid === 71) {
        return (
          <img
            src={`${environmentVariables.apiUrl}uploads/LogoPhy/defaultLogo2.png`}
            className="mx-auto d-block img-fluid smallselectitleimg"
            style={{ width: "15px", height: "15px" }}
          />
        );
      } else if (cardid === 72) {
        return (
          <img
            src={`${environmentVariables.apiUrl}uploads/LogoPhy/defaultLogo1.png`}
            className="mx-auto d-block img-fluid smallselectitleimg"
            style={{ width: "15px", height: "15px" }}
          />
        );
      } else if (cardid === 73) {
        return (
          <img
            src={`${environmentVariables.apiUrl}uploads/LogoPhy/defaultLogo3.png`}
            className="mx-auto d-block img-fluid smallselectitleimg"
            style={{ width: "15px", height: "15px" }}
          />
        );
      } else {
        return (
          <img
            src={`${environmentVariables.apiUrl}uploads/LogoPhy/defaultLogo2.png`}
            className="mx-auto d-block img-fluid smallselectitleimg"
            style={{ width: "15px", height: "15px" }}
          />
        );
      }
      {
        props.card_id === 71 ? (
          <img
            src={`${environmentVariables.apiUrl}uploads/LogoPhy/defaultLogo2.png`}
            className="mx-auto d-block img-fluid ViewCardLogoStyle"
            // style={{ width: "30px", height: "30px" }}
          />
        ) : (
          <img
            src={`${environmentVariables.apiUrl}uploads/LogoPhy/defaultLogo1.png`}
            className="mx-auto d-block img-fluid ViewCardLogoStyle"
            // style={{ width: "30px", height: "30px" }}
          />
        );
      }
    };

    const myFonts = (fontFile2) => {
      const css2 = `
    @font-face {
      font-family: 'dynamic-font2';
      src: url(data:application/font-ttf;base64,${fontFile2}) format('truetype');
    }
  `;

      const blob2 = new Blob([css2], { type: "text/css" });
      const url2 = URL.createObjectURL(blob2);

      const link1 = document.createElement("link");
      link1.rel = "stylesheet";
      link1.href = url2; // Fix: use url2 here
      document.head.appendChild(link1);
    };

    const myStyleFile = (styleFile) => {
      // console.log(styleFile, "L182")

      const link = document.createElement("link");
      link.href = styleFile;
      link.rel = "stylesheet";
      // link.onload = () => {
      //   setIsCardLoaded(true);
      // };
      const style = document.createElement("style");
      style.innerHTML = `
  @font-face {
    font-family: 'dynamic-font';
    src: url('${fontFile}') format('truetype');
  }
  `;
      document.head.appendChild(link);
      document.head.appendChild(style);

      return () => {
        // console.log("L199")
        document.head.removeChild(link);
      };
    };

    useEffect(() => {
      myFonts(fontFile2);
      myFonts(fontFile);
      // myStyleFile(`${environmentVariables?.apiUrl}${cardname}/style_file_style.css`)
    }, []);

    const frontimgs = `${environmentVariables?.apiUrl}${cardname}/${frontimg}`;

    // console.log(cardid,"cardid!!!!!")

  
    return (
      <>
        <article id={data_props.card_id}>
          <div className="row justify-content-center">
            <div className="col-md-">
              <div className="cardviewiinerSmallView">
                <div className="flip-card">
                  <div className="flip-card-inner1">
                    <div className="flip-card-front1">
                      <div className="pos-r">
                        <img
                          src={frontimgs}
                          className="mx-auto d-block img-fluid sssd"
                          loading="lazy"
                        />
                        <div className="caption-front">
                          {islogo === "1" ? (
                            <>
                              {logoimg == "null" ? (
                                // (
                                //   <img
                                //     src={`${environmentVariables.apiUrl}uploads/LogoPhy/default1.png`}

                                //     className="mx-auto d-block img-fluid smallselectitle"
                                //     style={{ width: "30px", height: "30px" }}
                                //   />
                                // )
                                <>
                                {getLogoImageForPhysicalCards()}
                                </>
                              ) : (
                                <img
                                  src={`${environmentVariables.apiUrl}uploads/LogoPhy/${logoimg}`}
                                  className="mx-auto d-block img-fluid smallselectitleimg"
                                  style={{ width: "15px", height: "15px" }}
                                />
                              )}
                            </>
                          ) : null}
                          <h1
                            style={{ fontFamily: "dynamic-font2" }}
                            className="u-name Physical_card_one smallselectitle"
                          >
                            {/* {SelectedTitle} */}
                          </h1>
                          <p
                            style={{ fontFamily: "dynamic-font2" }}
                            className="u-deg Physical_card_one_backend smallseleteddesignation"
                          >
                            {/* {SelectedDesignation} */}
                          </p>
                          <p
                            style={{ fontFamily: "dynamic-font" }}
                            className="u-weblink pos-ab"
                          ></p>
                        </div>
                      </div>
                    </div>

                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </>
    );
  };
  const paginationOptions = {
    rowsPerPageText: t("Rows_per_page"),
    rangeSeparatorText: t("Of"),
    selectAllRowsItem: true,
    selectAllRowsItemText: t("All"),
  };
  return (
    <>
      <PageName PageName={t("Order_now")} />

      <div className="row ">
        <div className="col-12 col-lg-7 OrdernowAddress_container">
          <div className="iinersec">
            <div className="row mb-4">
              <div className="col-12 col-lg-12">
                <div className="iinerboxxx">
                  <div className="innernamehere">
                    <h1 className="name">{t("Choose_address")}</h1>
                    <div className="btn-sec addresssss">
                      <span>{t("Add_address")}</span>
                      <button
                        type="button"
                        className="btn btn-submitformaddress mb-3 NewAddress"
                        data-bs-toggle="modal"
                        data-bs-target="#addressform"
                        onClick={resetFormik}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  {isLoadingAdd ? (
                    <CircularLoader size={60} />
                  ) : (
                    <>
                      <ul className="addresss">
                        {addressData &&
                          addressData.map((val, index) => (
                            <li className="ChooseAddress">
                              <div className="form-check ver-center">
                                <input
                                  className="form-check-input2"
                                  type="radio"
                                  name=""
                                  id="cc"
                                  checked={val?.id === selectedAddress}
                                  onClick={() => setSelectedAddress(val?.id)}
                                />
                                <label
                                  className="form-check-label"
                                  for={val?.id}
                                >
                                  <span className="order-place">
                                    {`${val?.address_type}
                               `}
                                  </span>
                                  <br />
                                  <span className="nameinfo">
                                    {`
                                      ${val?.fullname},
                                      ${val?.address1}, ${val?.address2},
                                      ${val?.city}-${val?.pincode}, ${val?.state} ${val?.country}, 
                                    `}
                                    <br />
                                    {`Mobile No. - ${val?.mobile1}`}
                                  </span>
                                </label>
                              </div>
                              <div className="ellipsis-container Address_edit_points">
                                <button
                                  className="btneditaddress"
                                  onClick={() => {
                                    setShowOptions(
                                      showOptions === val.id ? null : val.id
                                    );
                                  }}
                                >
                                  <i className="fa-solid fa-ellipsis"></i>
                                </button>
                                {showOptions === val.id && (
                                  <div
                                    className="options-container"
                                    ref={dropdownRef}
                                  >
                                    <div
                                      className="btneditaddress editdelete"
                                      data-bs-toggle="modal"
                                      data-bs-target="#addresseditform"
                                      onClick={(e) =>
                                        handleEditAddress(val?.id)
                                      }
                                    >
                                      {t("Edit")}
                                    </div>
                                    <div
                                      className="btndeleteaddress editdelete"
                                      onClick={(e) =>
                                        handleDeleteAddress(val?.id)
                                      }
                                    >
                                      {t("Delete")}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </li>
                          ))}
                      </ul>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-5 OrdernowSummery_container">
          <h1 className="name">{t("Order_summary")}</h1>
          {(selectedOption === "pvc" && pvcBooked > 0) ||
          (selectedOption === "metal" && metalBooked > 0) ? (
            <div className="iinerboxxx_right mt-4 OrderSummery_rightContainer">
              <div className="orderbox">
                <ul className="list-group mb-3">
                  <div class="name_image_wrapper">
                    <div class="name_image_container">
                      <div class="OrderNow_Designation">{SelectedTitle} </div>

                      {SelectedDesignation !== "null" && (
                        <div class="Ordernow_title">{SelectedDesignation}</div>
                      )}
                    </div>
                    <div>
                      <li className="list-group-item d-flex justify-content-between 5h-sm py-3">
                        <div className="d-flex" style={{ padding: "0" }}>
                          {/* <img
                            style={{ width: "100px" }}
                            src={
                              thumbnailImage ||
                              "https://dummyimage.com/70x50/000/fff"
                            }
                            className="img-fluid "
                          /> */}

                          <ViewCardShort />
                        </div>
                      </li>
                    </div>
                  </div>
                  <li className="list-group-item" style={{ border: "none" }}>
                    <div className="card_types">
                      <h3 className="select_card">{t("Select_card_type")}</h3>
                      <label className="card_lable1">
                        <input
                          type="radio"
                          value="pvc"
                          checked={selectedOption === "pvc"}
                          onChange={handleRadioChange}
                          className="card_input"
                        />
                        {t("PVC_card_balance")} :{" "}
                        {`${
                          planSummary?.userPlan?.no_of_pvc_card -
                            planSummary?.usedPvcPlan >
                          0
                            ? planSummary?.userPlan?.no_of_pvc_card -
                              planSummary?.usedPvcPlan
                            : 0
                        }/  ${planSummary?.userPlan?.no_of_pvc_card} `}
                      </label>

                      <label className="card_lable2">
                        <input
                          type="radio"
                          value="metal"
                          checked={selectedOption == "metal"}
                          onChange={handleRadioChange}
                          className="card_input"
                        />
                        {t("Metal_card_balance")} :{" "}
                        {`${
                          planSummary?.userPlan?.no_of_metal_card -
                            planSummary?.usedMetalPlan >
                          0
                            ? planSummary?.userPlan?.no_of_metal_card -
                              planSummary?.usedMetalPlan
                            : 0
                        }/${planSummary?.userPlan?.no_of_metal_card}`}
                      </label>
                    </div>
                  </li>

                  <li className="list-group-item alight_center">
                    <div className="premium_main">
                      <h2 className="premium_with_img BenifitEnterprices_wrapper">
                      {t("Benefits_unlocked_with")}
                        <div>
                          {" "}
                          <img loading="lazy" src={Crown} class="CrownImage_division" />
                          <span> {`${planSummary?.userPlan?.name}`}</span>
                        </div>
                      </h2>
                      <div className="premium_small">
                        <h3 className="premium_small1">
                          {selectedOption === "pvc"
                            ? `${t("PVC_card")}`
                            : selectedOption === "metal"
                            ? `${t("Metal_card")}`
                            : ""}
                            {" " + t("Worth") + " "}
                          {`${currencyObjData?.symbol}${cardTotalAmount}`}
                        </h3>

                        <h3 className="premium_small2">{t("Free")}</h3>
                      </div>
                    </div>
                  </li>

                  <li className="list-group-item mt-10">
                    <div className="col">
                      <label className="select_card pad_left10">
                      {t("Delivery_instructions")} ({t("Optional")})
                      </label>
                      <textarea
                        className="form-control order_instructions new_area"
                        placeholder={`${t("Enter")} ${t("Delivery_instructions")}`}
                        aria-label="Name"
                        value={deliveryInstructions}
                        onChange={(e) =>
                          setDeliveryInstructions(e.target.value)
                        }
                      ></textarea>
                    </div>
                  </li>

                  <li className="list-group-item d-flex justify-content-between pad_lr10">
                    <span>{t("Card")} {t("Amount")}</span>
                    <strong>{`${currencyObjData?.symbol} ${cardTotalAmount}`}</strong>
                  </li>

                  <li className="list-group-item d-flex justify-content-between pad_lr10">
                    <span>{t("Discount")}</span>
                    <strong>{`- ${currencyObjData?.symbol} ${cardTotalAmount}`}</strong>
                  </li>

                  <li className="list-group-item d-flex justify-content-between pad_lr10">
                    <span>{t("Amount_to_be_paid")}</span>
                    <strong>{`${currencyObjData?.symbol} 0 `}</strong>
                  </li>

                  <li className="d-none list-group-item pad_lr10">
                    <div
                      className={`d-none  ${isActive ? "Showdropdown" : ""}`}
                    >
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control promo"
                          placeholder={t("Enter_Promo_code")}
                          value={discountCode}
                          onChange={(e) => setDiscountCode(e.target.value)}
                        />
                        <button
                          onClick={handleApplyCoupon}
                          type="submit"
                          class="btn btn-secondary"
                          id="Remove_Apply_physicalcard_Button"
                        >
                          {t("Apply")}
                        </button>
                      </div>
                      <p style={{ color: "red" }}>{error}</p>

                      <ul className="copunlist">
                        {discountData &&
                          discountData.map((val) => (
                            <li>
                              <div className="">
                                <span>{val?.name}</span>
                              </div>
                              <div
                                onClick={() => handleSelectDiscount(val?.id)}
                                className=""
                              >
                                <button className="btn btn-sm btn-info">
                                {t("Apply")}
                                </button>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </li>
                </ul>

                <div className="input-group">
                  {selectedOption === "pvc" &&
                    planSummary?.userPlan?.no_of_pvc_card -
                      planSummary?.usedPvcPlan >
                      0 && (
                      <button
                        className="w-100 save-cta order-cta"
                        onClick={() => handletoBuy(selectedOption)}
                      >
                        {isPaymentBtnClicked ? <CircularLoader size={20} /> : t("Place_order")}
                      </button>
                    )}
                </div>
                <div className="input-group">
                  {selectedOption === "metal" &&
                    planSummary?.userPlan?.no_of_metal_card -
                      planSummary?.usedMetalPlan >
                      0 && (
                      <button
                        className="w-100 save-cta order-cta"
                        onClick={() => handletoBuy(selectedOption)}
                      >
                        {isPaymentBtnClicked ? (
                          <CircularLoader size={20} />
                        ) : (
                          t("Place_order")
                        )}
                      </button>
                    )}
                </div>
              </div>
            </div>
          ) : (selectedOption === "pvc" && pvcBooked <= 0) ||
            (selectedOption === "metal" && metalBooked <= 0) ? (
            <div className="iinerboxxx_right mt-4 OrderSummery_rightContainer">
              <div className="orderbox">
                <ul className="list-group mb-3">
                  <div class="name_image_wrapper">
                    <div class="name_image_container">
                      <div class="OrderNow_Designation">{SelectedTitle} </div>
                      {SelectedDesignation !== "null" && (
                        <div class="Ordernow_title">{SelectedDesignation}</div>
                      )}
                    </div>
                    <div>
                      <li className="list-group-item d-flex justify-content-between 5h-sm py-3">
                        <div className="d-flex" style={{ padding: "0" }}>
                          {/* <img
                            style={{ width: "100px" }}
                            src={
                              thumbnailImage ||
                              "https://dummyimage.com/70x50/000/fff"
                            }
                            className="img-fluid "
                          /> */}
                          <ViewCardShort />
                        </div>
                      </li>
                    </div>
                  </div>
                  <li className="list-group-item" style={{ border: "none" }}>
                    <div className="card_types">
                      <h3 className="select_card">{t("Select_card_type")}</h3>

                      <label className="card_lable1">
                        <input
                          type="radio"
                          value="pvc"
                          checked={selectedOption === "pvc"}
                          onChange={handleRadioChange}
                          className="card_input"
                        />
                        {t("PVC_card_balance")} :{" "}
                        {`${
                          planSummary?.userPlan?.no_of_pvc_card -
                            planSummary?.usedPvcPlan >
                          0
                            ? planSummary?.userPlan?.no_of_pvc_card -
                              planSummary?.usedPvcPlan
                            : 0
                        }/${(planSummary?.userPlan != undefined)?planSummary?.userPlan?.no_of_pvc_card:0}`}
                      </label>

                      <label className="card_lable2">
                        <input
                          type="radio"
                          value="metal"
                          checked={selectedOption === "metal"}
                          onChange={handleRadioChange}
                          className="card_input"
                        />
                        {t("Metal_card_balance")} :{" "}
                        {`${
                          planSummary?.userPlan?.no_of_metal_card -
                            planSummary?.usedMetalPlan >
                          0
                            ? planSummary?.userPlan?.no_of_metal_card -
                              planSummary?.usedMetalPlan
                            : 0
                        }/${(planSummary?.userPlan != undefined)?planSummary?.userPlan.no_of_metal_card:0}`}
                      </label>
                    </div>
                  </li>

                  <li className="list-group-item">
                    <div className="col">
                      <label className="select_card pad_left10">
                        {t("Delivery_instructions")} ({t("Optional")})
                      </label>
                      <textarea
                        className="form-control order_instructions new_area"
                        placeholder={`${t("Enter")} ${t("Delivery_instructions")}`}
                        aria-label="Name"
                        value={deliveryInstructions}
                        onChange={(e) =>
                          setDeliveryInstructions(e.target.value)
                        }
                      ></textarea>
                    </div>
                  </li>

                  <li className="list-group-item">
                    <div className="premium_main_coupon">
                      <h6 className="promcode" onClick={toggleClass}>
                        {" "}
                        {t("Have_a_promo_code")}
                      </h6>

                      <div
                        className={`d-none  ${isActive ? "Showdropdown" : ""}`}
                      >
                        <ul className="copunlist coupenlistcontainer_physicacard">
                          {discountData &&
                            discountData.map((val) => (
                              <li>
                                <div className="coupen_style">
                                  <span
                                    onClick={() =>
                                      handleSelectDiscount(val?.id)
                                    }
                                    class="coupenName_liststyle"
                                  >
                                    {val?.name}
                                  </span>
                                </div>
                              </li>
                            ))}
                        </ul>
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control promo"
                            placeholder={t("Enter_Promo_code")}
                            value={isCouponApplied ? discountCode : ""}
                            onChange={(e) => setDiscountCode(e.target.value)}
                            disabled={isCouponApplied}
                          />
                          {isCouponApplied ? (
                            <button
                              onClick={handleRemoveCoupon}
                              type="button"
                              className="btn btn-danger coupon_cta remobe_couponbutton_style"
                              id="Remove_Apply_physicalcard_Button"
                            >
                              {t("Remove")}
                            </button>
                          ) : (
                            <button
                              onClick={handleApplyCoupon}
                              type="button"
                              className="btn btn-secondary coupon_cta"
                              id="Remove_Apply_physicalcard_Button"
                            >
                              {t("Apply")}
                            </button>
                          )}
                        </div>
                        <p style={{ color: "red" }}>{error}</p>
                      </div>
                    </div>
                  </li>

                  <li className="list-group-item d-flex justify-content-between pad_lr10">
                    <span>{t("Card")} {t("Amount")}</span>
                    <strong>{`${currencyObjData?.symbol} ${cardTotalAmount}`}</strong>
                  </li>

                  <li className="list-group-item d-flex justify-content-between pad_lr10">
                    <span>{t("Discount")}</span>
                    <strong>{`- ${currencyObjData?.symbol} ${discountAmount}`}</strong>
                  </li>
                  <li className="list-group-item d-flex justify-content-between pad_lr10">
                    <span>{t("Amount_to_be_paid")}</span>
                    <strong>{`${currencyObjData?.symbol} ${cardAmountAfterDiscount}`}</strong>
                  </li>

                  <li className="pymnetsechere">
                    <span>
                      <div className="select_card pad_left10">
                        {/* How would you like to pay ? */}
                        {t("Payment")} {t("Mode")}
                      </div>
                    </span>

                    {currencyObjData?.symbol === "₹" ? (
                      <ul className="paymentmodename mt-2">
                        <li>
                          <div class="form-check" id="strip_input_field">
                            <input
                              class="form-check-input_field_styling"
                              type="radio"
                              name="flexRadioDefault2"
                              id="aa"
                              checked={paymentMode == 1}
                              onClick={() => setPaymentMode(1)}
                            />
                            <label class="form-check-label" for="aa">
                             {t("Pay_online")}
                            </label>
                          </div>
                          <div className="paymntmodeimg">
                            <i class="fa-brands fa-razorpay"></i>
                             {/* <i class="fa-brands fa-cc-stripe"></i> */}
                          </div>
                        </li>
                      </ul>
                    ) : (
                      <ul className="paymentmodename mt-2">
                        <li>
                          <div class="form-check" id="strip_input_field">
                            <input
                              class="form-check-input_field_styling"
                              type="radio"
                              name="flexRadioDefault2"
                              id="aa"
                              checked={paymentMode == 2}
                              onClick={() => setPaymentMode(2)}
                            />
                            <label class="form-check-label" for="aa">
                              DPO
                            </label>
                          </div>
                          <div className="paymntmodeimg">
                            {/* <i class="fa-brands fa-cc-stripe"></i> */}
                            <img
                              src={DpoIcon}
                              alt="DPO Icon"
                              style={{ width: "75px" }}
                            />
                          </div>
                        </li>
                      </ul>
                    )}
                  </li>
                </ul>

                <div className="input-group">
                  {selectedOption === "pvc" && (
                    <button
                      disabled={paymentMode === "-1"}
                      className="btn w-100 order_summary save-cta"
                      // onClick={() => handleProceedNext(selectedOption)}
                      onClick={() => {
                        if (paymentMode === 1) {
                          handleProceedPaymentGatewayIndia(selectedOption);
                          
                        } else if (paymentMode === 2) {
                          handleProceedDPO(selectedOption);
                        }
                      }}
                    >
                      {isPaymentBtnClicked ? (
                        <CircularLoader size={20} />
                      ) : (
                        `${t("Continue")}`
                      )}
                    </button>
                  )}
                </div>

                <div className="input-group">
                  {selectedOption === "metal" && (
                    <button
                      disabled={paymentMode === "-1"}
                      className="btn w-100 order_summary save-cta"
                      // onClick={() => handleProceedNext(selectedOption)}
                      onClick={() => {
                        if (paymentMode === 1) {
                          handleProceedPaymentGatewayIndia(selectedOption);
                      
                        } else if (paymentMode === 2) {
                          handleProceedDPO(selectedOption);
                        }
                      }}
                    >
                      {isPaymentBtnClicked ? (
                        <CircularLoader size={20} />
                      ) : (
                        `${t("Continue")}`
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div
        class="modal fade"
        id="plandetails"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                User All Plans
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <DataTableExtensions
                {...tableData}
                customStyles={customStyles}
                filterPlaceholder="Search Order"
              >
                <DataTable
                  columns={columns}
                  data={tableData.data}
                  noHeader
                  defaultSortField="id"
                  sortIcon={<SortIcon />}
                  defaultSortAsc={true}
                  pagination
                  highlightOnHover
                  dense
                  customStyles={customStyles}
                   paginationComponentOptions={{
                        ...paginationOptions,
                      }}
                  noDataComponent={<div style={{padding:"24px"}}>{t("No_records_found")}</div>}
                />
              </DataTableExtensions>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="addressform"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg AddAddress_form">
          <div className="modal-content AddnewAddressModal">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t("Add_addres")} 
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body AddEditForm_inputfield">
              <form className="row g-3">
                <div className="col-md-12">
                  <div className="mb-2 col-md-12 AddAddressformmarissue">
                    <select
                      onChange={handleChangeAddressType}
                      className="form-select AddAddressformPadissue"
                      name="address_type"
                      value={AddressType}
                      required
                    >
                      <option value="">{t("Select")} {t("Address")} {t("Type")}*</option>
                      <option value="Home">{t("Home")}</option>
                      <option value="Work">{t("Work")}</option>
                    </select>
                    {formik.touched.address_type &&
                    formik.errors.address_type ? (
                      <div id="error_msg">{errors.address_type}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6 AddAddressformmarissue">
                  <input
                    type="text"
                    className="form-control clear_string AddAddressformPadissue"
                    placeholder={`${t("Full_name")}*`}
                    name="fullname"
                    value={values.fullname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required=""
                  />
                  {formik.touched.fullname && formik.errors.fullname ? (
                    <div id="error_msg">{t("Full_name_required")}</div>
                  ) : null}
                  <span className="error text-danger d-none">
                    {t("Credentials_not_match")}.
                  </span>
                </div>
                <div className="col-md-6 AddAddressformmarissue">
                  <input
                    type="number"
                    className="form-control AddAddressformPadissue"
                    placeholder={`${t("Mobile")}*`}
                    id="mobile"
                    name="mobile1"
                    value={values.mobile1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.mobile1 && formik.errors.mobile1 ? (
                    <div id="error_msg">{t("Mobile_no_required")}</div>
                  ) : null}
                </div>

                <div className="col-12 AddAddressformmarissue">
                  <input
                    type="text"
                    className="form-control AddAddressformPadissue"
                    id="address1"
                    name="address1"
                    placeholder={`${t("Address_1")}*`}
                    value={values.address1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.address1 && formik.errors.address1 ? (
                    <div id="error_msg">{t("Address_1_required")}</div>
                  ) : null}
                </div>
                <div className="col-12 AddAddressformmarissue">
                  <input
                    type="text"
                    className="form-control AddAddressformPadissue"
                    id="address2"
                    name="address2"
                    placeholder={`${t("Address_2")}*`}
                    value={values.address2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.address2 && formik.errors.address2 ? (
                    <div id="error_msg">{t("Address_2_required")}</div>
                  ) : null}
                </div>
                <div className="col-md-3 AddAddressformmarissue">
                  <input
                    type="text"
                    className="form-control AddAddressformPadissue"
                    id="city"
                    name="country"
                    placeholder={`${t("Country")}*`}
                    value={values.country}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.country && formik.errors.country ? (
                    <div id="error_msg">{t("Country_required")}</div>
                  ) : null}
                </div>
                <div className="col-md-3 AddAddressformmarissue">
                  <input
                    type="text"
                    className="form-control AddAddressformPadissue"
                    id="city"
                    name="state"
                    placeholder={`${t("State")}*`}
                    value={values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.state && formik.errors.state ? (
                    <div id="error_msg">{t("State_required")}</div>
                  ) : null}
                </div>
                <div className="col-md-3 AddAddressformmarissue">
                  <input
                    type="text"
                    className="form-control AddAddressformPadissue"
                    placeholder={`${t("City")}*`}
                    id="state"
                    name="city"
                    value={values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.city && formik.errors.city ? (
                    <div id="error_msg">{t("City_required")}</div>
                  ) : null}
                </div>
                <div className="col-md-3 AddAddressformmarissue">
                  <input
                    type="number"
                    className="form-control AddAddressformPadissue"
                    placeholder={`${t("Zipcode")}*`}
                    id="zipcode"
                    name="pincode"
                    value={values.pincode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.pincode && formik.errors.pincode ? (
                    <div id="error_msg">{t("Pincode_required")}</div>
                  ) : null}
                </div>
                <div className="col-md-12 AddAddressformmarissue">
                  {/* <label for="inputZip" className="form-label">
                    Landmark (optional)
                  </label> */}
                  <input
                    type="text"
                    className="form-control AddAddressformPadissue"
                    placeholder={`${t("Landmark")} (${t("Optional")})`}
                    id="landmark"
                    name="landmark"
                    value={values.landmark}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="col-12 alight_center">
                  <button
                    onClick={handleSubmit}
                    type="submit"
                    className="btn btn-primary save-cta addedit_button"
                    name="save"
                    disabled={isSubmit || disableButton || !formik.isValid}
                    data-bs-dismiss="modal"
                  >
                    {isSubmit ? <CircularLoader size={20} /> : t("Save")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="addresseditform"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg AddAddress_form">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t("Edit_address")}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body AddEditForm_inputfield">
              <form className="row g-3">
                <div className="col-md-12">
                  <div class="mb-2 col-md-12 AddAddressformmarissue">
                    {/* <label for="inputEmail4" className="form-label">
                      Address Type*
                    </label> */}
                    <select
                      onChange={handleChangeAddressType}
                      class="form-select AddAddressformPadissue"
                      name="address_type"
                      value={AddressType}
                    >
                      <option value="">{t("Select")} {t("Address")} {t("Type")}*</option>
                      <option value="Home">{t("Home")}</option>
                      <option value="Work">{t("Work")}</option>
                    </select>
                    {formikEdit.touched.address_type &&
                    formikEdit.errors.address_type ? (
                      <div id="error_msg">{formikEdit.errors.address_type}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6 AddAddressformmarissue">
                  <input
                    type="text"
                    className="form-control clear_string AddAddressformPadissue"
                    placeholder={`${t("Full_name")}*`}
                    name="fullname"
                    value={formikEdit?.values?.fullname}
                    onChange={formikEdit.handleChange}
                    onBlur={formikEdit.handleBlur}
                    required=""
                  />
                  {formikEdit.touched.fullname && formikEdit.errors.fullname ? (
                    <div id="error_msg">{formikEdit.errors.fullname}</div>
                  ) : null}
                  <span className="error text-danger d-none">
                    {t("Credentials_not_match")}.
                  </span>
                </div>
                <div className="col-md-6 AddAddressformmarissue">
                  {/* <label for="inputEmail4" className="form-label">
                    Mobile*
                  </label> */}

                  <input
                    type="number"
                    className="form-control"
                    placeholder={`${t("Mobile")}*`}
                    id="mobile"
                    name="mobile1"
                    value={formikEdit?.values?.mobile1}
                    onChange={formikEdit.handleChange}
                    onBlur={formikEdit.handleBlur}
                  />
                  {formikEdit.touched.mobile1 && formikEdit.errors.mobile1 ? (
                    <div id="error_msg">{formikEdit.errors.mobile1}</div>
                  ) : null}
                </div>
                {/* <div className="col-6">
                  <label for="inputEmail4" className="form-label">Mobile Number</label>

                    <input type="number" className="form-control" placeholder="Mobile Number" name="mobile1" value="" required=""/>
                </div>
                <div className="col-6">
                  <label for="inputEmail4" className="form-label">Alternate Mobile</label>

                    <input type="number" className="form-control clear_string" maxlength="10" minlength="10" placeholder="Alternae Mobile Number" name="mobile2" value=""/>
                </div> */}
                <div className="col-12 AddAddressformmarissue">
                  {/* <label for="inputAddress" className="form-label">
                    Address1 (Flat No/ Building / Appartment)*
                  </label> */}
                  <input
                    type="text"
                    className="form-control AddAddressformPadissue"
                    placeholder={`${t("Address_1")}*`}
                    id="address1"
                    name="address1"
                    value={formikEdit?.values?.address1}
                    onChange={formikEdit.handleChange}
                    onBlur={formikEdit.handleBlur}
                  />
                  {formikEdit.touched.address1 && formikEdit.errors.address1 ? (
                    <div id="error_msg">{formikEdit.errors.address1}</div>
                  ) : null}
                </div>
                <div className="col-12 AddAddressformmarissue">
                  {/* <label for="inputAddress2" className="form-label">
                    Address 2 (Street / Sector/ Area)*
                  </label> */}
                  <input
                    type="text"
                    className="form-control AddAddressformPadissue"
                    placeholder={`${t("Address_2")}*`}
                    id="address2"
                    name="address2"
                    value={formikEdit?.values?.address2}
                    onChange={formikEdit.handleChange}
                    onBlur={formikEdit.handleBlur}
                  />
                  {formikEdit.touched.address2 && formikEdit.errors.address2 ? (
                    <div id="error_msg">{formikEdit.errors.address2}</div>
                  ) : null}
                </div>
                <div className="col-md-3 AddAddressformmarissue">
                  {/* <label for="inputCity" className="form-label">
                    Country*
                  </label> */}
                  <input
                    type="text"
                    className="form-control AddAddressformPadissue"
                    placeholder={`${t("Country")}*`}
                    id="city"
                    name="country"
                    value={formikEdit?.values?.country}
                    onChange={formikEdit.handleChange}
                    onBlur={formikEdit.handleBlur}
                  />
                  {formikEdit.touched.country && formikEdit.errors.country ? (
                    <div id="error_msg">{formikEdit.errors.country}</div>
                  ) : null}
                </div>
                <div className="col-md-3 AddAddressformmarissue">
                  {/* <label for="inputCity" className="form-label">
                    State*
                  </label> */}
                  <input
                    type="text"
                    className="form-control AddAddressformPadissue"
                    placeholder={`${t("State")}*`}
                    id="city"
                    name="state"
                    value={formikEdit?.values?.state}
                    onChange={formikEdit.handleChange}
                    onBlur={formikEdit.handleBlur}
                  />
                  {formikEdit.touched.state && formikEdit.errors.state ? (
                    <div id="error_msg">{formikEdit.errors.state}</div>
                  ) : null}
                </div>
                <div className="col-md-3 AddAddressformmarissue">
                  <input
                    type="text"
                    className="form-control AddAddressformPadissue"
                    placeholder={`${t("City")}*`}
                    id="state"
                    name="city"
                    value={formikEdit?.values?.city}
                    onChange={formikEdit.handleChange}
                    onBlur={formikEdit.handleBlur}
                  />
                  {formikEdit.touched.city && formikEdit.errors.city ? (
                    <div id="error_msg">{formikEdit.errors.city}</div>
                  ) : null}
                </div>
                <div className="col-md-3 AddAddressformmarissue">
                  {/* <label for="inputZip" className="form-label">
                    Zipcode*
                  </label> */}
                  <input
                    type="number"
                    className="form-control AddAddressformPadissue"
                    placeholder={`${t("Zipcode")}*`}
                    id="zipcode"
                    name="pincode"
                    value={formikEdit?.values?.pincode}
                    onChange={formikEdit.handleChange}
                    onBlur={formikEdit.handleBlur}
                  />
                  {formikEdit.touched.pincode && formikEdit.errors.pincode ? (
                    <div id="error_msg">{formikEdit.errors.pincode}</div>
                  ) : null}
                </div>
                <div className="col-md-12 AddAddressformmarissue">
                  {/* <label for="inputZip" className="form-label">
                    Landmark (optional)
                  </label> */}
                  <input
                    type="text"
                    className="form-control AddAddressformPadissue"
                    placeholder={`${t("Landmark")} (${t("Optional")})`}
                    id="landmark"
                    name="landmark"
                    value={formikEdit?.values?.landmark}
                    onChange={formikEdit.handleChange}
                    onBlur={formikEdit.handleBlur}
                  />
                </div>
                <div className="col-12 alight_center">
                  <button
                    onClick={formikEdit.handleSubmit}
                    type="submit"
                    className="btn btn-primary addedit_button"
                    name="save"
                    disabled={isSubmit}
                    data-bs-dismiss="modal"
                  >
                    {isSubmit ? <CircularLoader size={20} /> : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderNow;
//1112
