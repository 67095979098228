import React, { useRef, useState } from "react";
// import "./TemplatComman.css";
import "./Theme20.css";
import Banner from "./../../../../Images/minimarketbg.png"
import LocationIcon from "./../../../../Images/locIcon.png";
import HomeComponent from "./HomeComponent";
import ContactInfo from "./ContactInfo";
import CameraImg from "./../../../../Images/camcollection.png";
import SinkImg from "./../../../../Images/sinkcollection.png";
import HeadphnImg from "./../../../../Images/headphonecollection.png";

import ShoesImg from "./../../../../Images/shoescollection.png";


        


const Theme20Template = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const calendarInputRef = useRef(null);
  const [activeTab, setActiveTab] = useState("home");


 


  const renderContent = () => {
    switch (activeTab) {
      case "home":
        return <div> <HomeComponent /> </div>;
      case "aboutus":
        return <div className="flex text-justify font-Tajawal text-[#101010] font-base text-lg">
          {/* Brown uses drawing as a prerequisite to guide her work. Through the use of repetition, Brown captures images that both attract and confound her.  Though her drawings are not as exhibited as her paintings, both art mediums contain similar aspects in showcasing her erotic view of art through subject matter.  */}
        </div>;
      case "contactinfo":
        return <div> </div>;
      default:
        return null;
    }
  };
 
  return (
    <div>
      <div class="main-temaplt" id="temp20">
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ width: "420px" }} className="col-12 wtemplate p-4">

             <div>
              <div className="relative">
                <img loading="lazy" src={Banner} className="relative w-full max-h-44 object-cover rounded-xl"/>
                <div style={{position:"absolute", bottom:"10px", left:"10px"}}className="absolute bottom-10 left-10">
                  <div className="text-white font-bold text-4xl font-Tajawal break-all">99 Markets</div>
                  <div className="flex items-center justify-start text-center w-5">
                    <img loading="lazy" style={{width:"20px"}} src={LocationIcon} />
                    <div>
                      <address className="mb-0 mx-2 text-sm font-normal text-white font-Tajawal inline break-all">Noida,sec 18</address>
                    </div>
                  </div>
                </div>
              </div>

              <div className="px-4 py-1 mx-4 flex justify-around items-center ">
                  <div>
                    <div className={`my-4 text-lg  cursor-pointer  hover:font-bold hover:text-black hover:transition all ${activeTab === "home" ? "text-black font-bold text-2xl" : ""}`}
                    
                    onClick={() => setActiveTab("home")}>Home</div>
                  </div>

                  <div>
                    <div className={`my-4 text-lg  cursor-pointer  hover:font-bold hover:text-black hover:transition all  ${activeTab === "aboutus" ? "text-black font-bold text-2xl" : ""}`}
                     onClick={() => setActiveTab("aboutus")}>About us</div>
                  </div>

                  <div>
                    <div className={`my-4 text-lg  cursor-pointer  hover:font-bold hover:text-black hover:transition all ${activeTab === "contactinfo" ? "text-black font-bold text-2xl" : ""}`}
                     onClick={() => setActiveTab("contactinfo")}>Contact Info</div>
                  </div>
                
              </div>

              <div className="px-2 py-2">{renderContent()}</div>

              <div>
                    <button
                        className="bg-coffee border-none px-4 py-1 text-base rounded-full text-white"
                        // onClick={() => setActiveTabb("All")}
                        style={{ margin: '0 10px' }}
                    >
                        All
                    </button>
                    <button
                        className="px-4 py-1 border border-darkUnactive rounded-full text-base  text-b45"
                        // onClick={() => setActiveTabb("All")}
                        style={{ margin: '0 10px' }}
                    >
                        Shoes
                    </button>
                    <button
                        className="px-4 py-1 border border-darkUnactive rounded-full text-base  text-b45"
                        // onClick={() => setActiveTabb("All")}
                        style={{ margin: '0 10px' }}
                    >
                        Appliances
                    </button>
                 
                </div>
              <div className='py-4 px-0 grid grid-cols-2 gap-2'>
        <div className='border border-efefef p-2 rounded-2xl'>
            <img loading="lazy" src={CameraImg} className='object-cover prodimg' />
            <div className="text-start font-normal text-base mt-2 px-1 pt-2 font-Tajawal">Luxuri Exclusive camera</div>
            <div className="flex justify-between items-center mt-1 px-1 pb-2">
                {/* <div className="text-lg font-bold text-black text-sm">$ 120.00</div> */}
             
                <div className='bg-coffee rounded'>
                
                <button className="bg-blue-500 text-sm font-semibold text-white font-bold px-2 py-2 rounded">
                  Buy Now
                </button>
                </div>
            </div>
        </div>
        <div className='border border-efefef p-2 rounded-2xl'>
            <img loading="lazy" src={SinkImg} className='object-cover prodimg' />
            <div className="text-start font-normal text-base mt-2 px-1 pt-2 font-Tajawal">Luxuri Exclusive sink</div>
            <div className="flex justify-between items-center mt-1 px-1 pb-2">
                {/* <div className="text-lg font-bold text-black text-sm">$ 920.00</div> */}
             
                <div className='bg-coffee rounded'>
                
                <button className="bg-blue-500 text-sm font-semibold text-white font-bold px-2 py-2 rounded" >
                  Buy Now
                </button>
                </div>
            </div>
        </div>
        <div className='border border-efefef p-2 rounded-2xl'>
            <img loading="lazy" src={HeadphnImg} className='object-cover prodimg' />
            <div className="text-start font-normal text-base mt-2 px-1 pt-2 font-Tajawal">Luxuri Exclusive Headphone</div>
            <div className="flex justify-between items-center mt-1 px-1 pb-2">
                {/* <div className="text-lg font-bold text-black text-sm">$ 220.00</div> */}
             
                <div className='bg-coffee rounded'>
                
                <button className="bg-blue-500 text-sm font-semibold text-white font-bold px-2 py-2 rounded">
                  Buy Now
                </button>
                </div>
            </div>
        </div>
        <div className='border border-efefef p-2 rounded-2xl'>
            <img loading="lazy" src={ShoesImg} className='object-cover prodimg' />
            <div className="text-start font-normal text-base mt-2 px-1 pt-2 font-Tajawal">Luxuri Exclusive Shoes</div>
            <div className="flex justify-between items-center mt-1 px-1 pb-2">
                {/* <div className="text-lg font-bold text-black text-sm">$ 520.00</div> */}
             
                <div className='bg-coffee rounded'>
                
                <button className="bg-blue-500 text-sm font-semibold text-white font-bold px-2 py-2 rounded">
                  Buy Now
                </button>
                </div>
            </div>
        </div>
</div>

             </div>
              {/* <div className=' bg-white px-2 py-4 sticky spacing -bottom-25 w-full text-center'>
                  <button className='bg-coffee text-xl rounded text-white w-full font-bold font-Tajawal pt-3  p-2'>
                    Contact supplier
                  </button>
                </div> */}
            </div>
            </div>
          </div>
        </div>
      </div>
  
  );
};

export default Theme20Template;
