import React, { useState, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import swap from "./../../../../Images/FromtoCabswap.png";
import Drawer from "react-bottom-drawer";
import BottomDrawer from '../theme20/BottomDrawer';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parse } from 'date-fns';

const RoundTTabCom = ({ onEnquirySubmit, colorData }) => {
    const { t } = useTranslation();
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [date, setDate] = useState(null);
    const [time, setTime] = useState("");
    const [returns, setReturn] = useState("");
    const [isVisible, setIsVisible] = useState(false);

    const openDrawer = useCallback(() => {
        setIsVisible(true);
    }, []);
    const closeDrawer = useCallback(() => setIsVisible(false), []);

    const resetForm = () => {
        setFrom("");
        setTo("");
        setDate(null);
        setTime("");
        setReturn("");
    };

    const handleFormSubmit = (formData) => {
        const formattedDate = date ? format(date, 'dd/MM/yyyy') : '';
        const enquiryData = {
            business_id: null,
            from,
            to,
            date: formattedDate,
            time,
            return: returns,
            type: "twoway",
            ...formData,
        };

        onEnquirySubmit(enquiryData, resetForm);
    };

    const handleDateChange = (date) => {
        setDate(date);
    };

    const isFormValid = from && to && date && time && returns; 

    return (
        <div>
             <style>
                {`
                    input[type="time"]::-webkit-calendar-picker-indicator {
                        filter: invert(1);
                        opacity: 0.8;
                    }
                    
                    input[type="time"] {
                        color: ${colorData?.dynamicTextDescriptionColor || "white"};
                        background-color: transparent;
                        border: 1px solid #Bordergray;
                        padding: 8px 12px;
                        border-radius: 8px;
                        outline: none;
                    }
                    
                    input[type="time"]::-moz-focus-inner {
                        border: 0;
                    }
                    
                    input[type="time"]::-moz-number-spin-box,
                    input[type="time"]::-moz-number-spin-button {
                        display: none;
                    }

                    .react-datepicker__input-container input[type="text"],  .w-foureightpercent input[type="time"]{
                    border: ${colorData?.dynamicTextDescriptionColor ? "1px solid" : ""} ;
                    }
                `}
            </style>
            <div className='bg-cabcard py-2 px-3 rounded cab-tabs' style={{ backgroundColor: `${colorData?.dynamicBackgroundColor || "#2b2b2c"}` }}>
                <div className='capitalize font-Poppins pt-2 mb-2 text-xl font-medium' style={{ color: colorData?.dynamicTextDescriptionColor || "#fff" }}>
                    {t("Search_cab")}
                </div>
                <div>
                    <input
                        type='text'
                        placeholder={t("From")}
                        className='outline-none text-base bg-transparent border-1 mt-2 mb-2 p-3 w-hundred rounded-lg border-Bordergray items-center oneWaycab_from'
                        value={from}
                        onChange={(e) => setFrom(e.target.value)}
                        style={{ color: colorData?.dynamicTextDescriptionColor || "#fff", borderColor: colorData?.dynamicTextDescriptionColor || "#595959" }}
                    />
                </div>
                <div>
                    <input
                        type='text'
                        placeholder={t("To")}
                        className='outline-none text-base bg-transparent border-1 mt-2 mb-2 p-3 w-hundred rounded-lg border-Bordergray items-center oneWaycab_from'
                        value={to}
                        onChange={(e) => setTo(e.target.value)}
                        style={{ color: colorData?.dynamicTextDescriptionColor || "#fff", borderColor: colorData?.dynamicTextDescriptionColor || "#595959" }}
                    />
                </div>

                <div>
                    <input
                        type='text'
                        placeholder={t("Return")}
                        className='outline-none text-base bg-transparent border-1 mt-2 mb-2 mt-2 p-3 w-hundred rounded-lg border-Bordergray items-center oneWaycab_from'
                        value={returns}
                        onChange={(e) => setReturn(e.target.value)}
                        style={{ color: colorData?.dynamicTextDescriptionColor || "#fff", borderColor: colorData?.dynamicTextDescriptionColor || "#595959" }}
                    />
                </div>



                <div className='flex justify-between w-hundred'>
                    <div className='w-foureightpercent mr-2' style={{position:"relative", color: colorData?.dynamicTextDescriptionColor || "#fff"}}>
                        <DatePicker
                            selected={date}
                            onChange={handleDateChange}
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date()}
                            placeholderText={t("Select_date")}
                            className='outline-none text-base bg-transparent border-1 mt-2 mb-2 p-3 w-hundred rounded-lg border-Bordergray'
                            style={{ color: colorData?.dynamicTextDescriptionColor || "#fff"}}
                        />
                    </div>
                    <div className='w-foureightpercent mr-2'>
                        <input
                            type='time'
                            placeholder={t("Time")}
                            className='outline-none text-base bg-transparent border-1 mt-2 mb-2 p-3 w-hundred rounded-lg border-Bordergray items-center oneWaycab_from'
                            value={time}
                            onChange={(e) => setTime(e.target.value)}
                            style={{ color: colorData?.dynamicTextDescriptionColor || "#fff" }}
                        />
                    </div>
                </div>

                <div className='w-hundred cursor-pointer my-4 rounded-lg bg-Cabdefaultcolor px-2 py-3 flex justify-center items-center' style={{ backgroundColor: colorData?.dynamicCtaColor || "#EDAE10",color:colorData?.dynamicCtaTextColor || "#fff"}}>
                    <button 
                        className={`font-Poppins font-medium${colorData?.dynamicCtaTextColor? '': 'text-white'}  text-lg font-base ${!isFormValid ? 'opacity-50 cursor-not-allowed' : ''}`}
                        onClick={openDrawer}
                        disabled={!isFormValid}
                    >
                        {t("Enquire_now")}
                    </button>
                </div>
                <Drawer style={{ width: "520px" }}
                    duration={150}
                    hideScrollbars={true}
                    onClose={closeDrawer}
                    isVisible={isVisible}
                    className="drawer">
                    <BottomDrawer closeDrawer={closeDrawer} onSubmit={handleFormSubmit} colordata={colorData} />
                </Drawer>
            </div>
        </div>
    )
}

export default RoundTTabCom