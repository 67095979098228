import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { environmentVariables } from "../config/env.config";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import "./LoginStyle.css"
import Mainlogo from "../Images/logo.png";
import CircularLoader from "../Backend/Components/CircularLoader/CircularLoader";
import Swal from "sweetalert2";
function ForgotPassword() {
  const baseUrl = environmentVariables?.apiUrl;
  const [email, setEmail] = useState("");
  const [isEmailEmpty, setIsEmailEmpty] = useState(true);
  const navigate = useNavigate();
  const recaptchaRef = React.createRef();
  const [isCaptchaVerified, setCaptchaVerified] = useState(false);
  const [captchaValue, setCaptchaValue] = useState();
  const [isLoading, setisLoading] = useState(false);
  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem("user"));
    if (storedUserData) {
      navigate("/dashboard");
      return;
    }
  }, []);
  function onChangeRecaptcha(value) {
    setCaptchaVerified(value !== null);
    setCaptchaValue(value)
  }
  const handleSubmit = () => {
    setisLoading(true)
    setIsEmailEmpty(true);
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailPattern.test(email);
    if (isValid) {
      if (email && captchaValue) { // captcha code
        // if (email) {
        axios
          .post(`${baseUrl}api/v1/user/forgotPassword_withCaptcha`, { email, captchaValue })
          .then((res) => {
            let msg = "The verification link has been sent to your email."
            toast.success(msg, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            setIsEmailEmpty(true);
            setisLoading(false)
            setTimeout(() => {
              navigate('/login')
            }, 2000)
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          })
          .finally(() => {
            setisLoading(false); // Ensure loader is hidden after request
          });
      } else {
        toast.error("Captcha is required", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setTimeout(() => {
          setIsEmailEmpty(false);
        }, 1000)
        setisLoading(false);
      }
    } else {
      toast.error("Email is not Valid", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setTimeout(() => {
        setIsEmailEmpty(false);
      }, 1000)
      setisLoading(false);
    }
  };
  return (
    <>
      <div className="login-23">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-12 bg-color-23">
              <div className="form-section">
                <div className="logo">
                </div>
                <h3>Recover Your Password</h3>
                <div className="login-inner-form">
                  <form action="#" method="GET">
                    <div className="form-group clearfix">
                      <div className="form-box ">
                        <input
                          type="text"
                          className="form-control clear_string login_inputfield"
                          placeholder="Enter Your Email*"
                          name="first_name"
                          value={email}
                          required=""
                          onChange={(e) => {
                            setEmail(e.target.value);
                            if (
                              e.target.value !== "" ||
                              e.target.value !== null
                            ) {
                              setIsEmailEmpty(false);
                            }
                          }}
                        />
                        <i className="fa fa-envelope input_icons" aria-hidden="true"></i>
                      </div>
                    </div>
                    <ReCAPTCHA
                      className="recaptcha_style"
                      ref={recaptchaRef}
                      sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                      onChange={onChangeRecaptcha}
                    />
                    <div className="form-group mb-0">
                      <button
                        // disabled={isEmailEmpty}
                        onClick={handleSubmit}
                        type="button"
                        className={`btn btn-primary btn-theme ${isEmailEmpty ? 'disabled-button' : 'forgot_password_submitbtn'}`}
                      >
                       {isLoading ? <CircularLoader size={20}/> : " Send Password Reset Link"}
                      </button>
                    </div>
                    <ToastContainer />
                  </form>
                </div>
                <p className="text-center">
                  Already a member?
                  <Link to="/login">
                    <b style={{paddingLeft:"5px"}}> Login here</b>
                  </Link>
                </p>
                <h3 style={{ marginTop: "30px", marginBottom: '0' }}>
                  <Link to="/">
                    <img loading="lazy" style={{ width: "120px" }} src={Mainlogo} />
                  </Link>
                </h3>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-12 bg-img">
              <div className="info">
                <div className="waviy">
                  Welcome To <span>Businessbay</span>
                </div>
                <p>
                Experience a 15-day FREE Trial that helps you to connect with people easily and build important relationships.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ForgotPassword;
//314 10:15