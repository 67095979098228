import React, { useContext, useEffect, useState } from "react";
import "./Style.css";
import PageName from "../Utils/PageName";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { environmentVariables } from "../../config/env.config";
import QRCode from "qrcode.react";
import Charttb from "./Charttb";
import Charttc from "./Charttc";
import Chartte from "./Chartte";
import Charttf from "./Charttf";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import { useNavigate } from "react-router-dom";
import "./Updatedcss/UpdatedDashboardstyle.css";
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import { businessObjContext } from "../../context/businessObjContext";
import { useTranslation } from "react-i18next";

function Dashbord() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [cardsData, setCardsData] = useState(null);
  const [planExpireDays, setPlanExpireDays] = useState(0);
  const [showPlanExpireBanner, setShowPlanExpireBanner] = useState(false);
  const { userData } = useContext(userContext);
  const [storedData, setStoredData] = useState(
    localStorage.getItem("businessObj")
  );
  const httpProtocol = environmentVariables?.httpProtocol;
  const fronturl = environmentVariables?.frntUrl;
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const { businessObjData, setBusinessObjData } =
    useContext(businessObjContext);
  let busiObj = localStorage.getItem("businessObj");
  const [appointments, setAppointments] = useState([]);
  // console.log(appointments,"appointments!@#$")
  const [appData, setAppData] = useState([]);
  const [contData, setContData] = useState([]);
  const [chartsRs, setChartsRs] = useState([]);
  const [businessLink, setBusinessLink] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [sevdays, setSevdays] = useState({});
  const [busData, setBusData] = useState({});
  const [graphData, setGraphData] = useState({
    transformedData: [],
    gData2: [],
  });
  const [GreatingMsg, setGreatingMsg] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const [newLeads, setNewLeads] = useState(0);
  const [cardsSold, setCardsSold] = useState(0);
  const [cardsDelivered, setCardsDelivered] = useState(0);
  const [pipelineLeads, setPipelineLeads] = useState(0);
  const [rejectedLeads, setRejectedLeads] = useState(0);
  const [closedLeads, setClosedLeads] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [onlineRevenue, setOnlineRevenue] = useState(0);
  const [offlineRevenue, setOfflineRevenue] = useState(0);
  const [isLoadings, setIsLoadings] = useState(false);
  const [currencySymbol, setcurrencySymbol]= useState(null); 
  const [filters, setFilters] = useState({});
  const [filterType, setFilterType] = useState(""); // Initially set to empty
  const [customDateRange, setCustomDateRange] = useState([null, null]); // For custom date range
  const [startDate, endDate] = customDateRange;
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  async function getGreatingMsg() {
    try {
      const response = await axios.get(
        `${baseUrl}api/v1/admin/setting/fetchConfig`,
        {
          params: {
            cnf_type: "Web",
            name: ["Greeting"],
          },
        }
      );
      if (response.status === 200) {
        setGreatingMsg(response?.data?.result?.Greeting);
      }
    } catch (error) {
      console.error({ "Error:": error, msg: "ErrorLanding" });
    }
  }
  const baseUrl = environmentVariables?.apiUrl;
  const crmDubaiApi=environmentVariables?.crmDubaiApi;
  const getGraphData = () => {
    axios
      .get(`${baseUrl}api/v1/business/fetchGraphData`, {
        params: { UserId: userData?.id },
        headers: {
          _token: userData?.token,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          // console.log(res?.data,"res?.datares?.data")
          setSevdays(res?.data);
          setBusData(res?.data?.totalBusiness?.rs)
          if (res?.data?.resultA?.sum_of_appointments) {
            setAppData(res.data.resultA.sum_of_appointments);
          }
          if (res?.data?.resultC?.sum_of_contacts) {
            setContData(res.data.resultC.sum_of_contacts);
          }
          if (res?.data?.resultViewr) {
            setChartsRs(res.data?.resultViewr);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.error("Resource not found:", error);
        } else {
          console.error("error:", error);
        }
      });
  };

  let data1 = graphData?.transformedData;
  let data2 = [["Language", "Speakers (in millions)"]];
  data2.push(...graphData.gData2);
  const setBusinessDataLink = (buzzData)=>{
    const businessData = JSON.parse(buzzData);
    let redirectUrl = (businessData?.slug_subdomain != null)? httpProtocol + businessData?.slug_subdomain:(businessData?.slug !=null)? fronturl+ businessData?.slug:"/";
    setBusinessLink(`${redirectUrl}`);
    setBusinessName(businessData?.title);
  }
  useEffect(() => {
    getGraphData();
    getGreatingMsg();
    if (storedData != "undefined" && storedData != null) {
      setBusinessDataLink(storedData);
    }else{      
      setBusinessDataLink(busiObj);
    }
  }, [storedData, busiObj]);

  const handleCopyClick = () => {
    const linkToCopy = window.location.href;
    navigator.clipboard
      .writeText(businessLink)
      .then((res) => {
        // setCopied(true);
      })
      .catch((error) => {
        console.error("Failed to copy link: ", error);
      });
    toast.info(t("Link_copy"), {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  };
  const apiUrl = environmentVariables?.apiUrl; 

  useEffect(() => {
    let config = {
      method: "get",
      url: `${apiUrl}api/v1/user/getDashboardData`,
      headers: {
        _token: userData?.token,
      },
    };

    axios
      .request(config)
      .then((response) => {
      console.log(response?.data?.data,'ddddddddddddddd')
        setCardsData(response?.data?.data);
      })
      .catch((error) => {
        if (error?.response?.status) {
          localStorage.clear();
          window.location.href = "/";
          window.location.reload();
        }
      });
  }, []);

  const fetchApppointent = (page=1) => {
    // console.log("in fetch appoitment")
    try {
      let obj = {
        business_id: businessObjData?.id,
      };
      axios
        .post(`${baseUrl}api/v1/appointment/FetchAppointments_withpagination?page=${page}&limit=${rowsPerPage}&showcount=${userData?.appointments}`,{ ...obj}, {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((response) => {
          let arr = response?.data?.data?.records;
          arr = arr.sort(
            (x, y) => new Date(y?.created_at) - new Date(x?.created_at)
          );
          // console.log(arr,"@#$arr")
          setAppointments(arr);
        })
        .catch((error) => {
        });
    } catch (err) {
      console.log(err, "erroror");
    }
  };


  useEffect(() => {
    fetchApppointent();
  }, [rowsPerPage]);


  // const CalendarComponent = ({ appointments }) => {
    // Transform appointment data into the format expected by FullCalendar
    const events = appointments.map(appointment => ({
      title: appointment.businessTitle, // Use businessTitle as the event title
      start: appointment.date, // Use date as the event start time
      // You can optionally include other properties such as 'end' here if needed
      extendedProps: {
        // Pass the entire appointment object as extendedProps
        appointment,
      },
  
    }));

    const eventContent = ({ event }) => {
      const { businessTitle, time, name, } = event.extendedProps.appointment;
      return (
        <div style={{padding:"5px 0"}}>
          <p style={{fontSize:"14px", fontWeight:"bold"}}>{businessTitle}</p>
            <p style={{fontSize:"13px"}}> {time}</p>
            <p style={{fontSize:"13px"}}>{name}</p>
        </div>
      );
    };
  
    const downloadQR = () => {
      const canvas = document.getElementById("businessLinkQr");
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = businessName + ".png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };

    useEffect(() => {
      getExpirationDetails();
    }, []);

    const getExpirationDetails = ()=>{
      let config = {
        method: "get",
        url: `${apiUrl}api/v1/user/getPlanExpirationDetails`,
        headers: {
          _token: userData?.token,
        },
      };
  
      axios
        .request(config)
        .then((response) => {
          if(response?.data?.success){
            setPlanExpireDays(response?.data?.planExpiryDays);
            setShowPlanExpireBanner(response?.data?.allowToShowExpiryBanner);
          }
        })
        .catch((error) => {
          console.log("error====>>>", error.message);
          
        });
    }
    const handlePlanClick = ()=>{
      navigate('/plans');
    }

    const renderEnquiryComponent = () => {
      if (!busData || !Array.isArray(busData) || busData.length === 0) {
        return null; // Return nothing if busData is null, undefined, or not an array
      }
    
      const firstMatchingBusiness = busData.find((business) => 
        business.theme_color === 'theme14' || 
        business.theme_color === 'theme20' || 
        business.theme_color === 'theme22'
      );
    
      if (!firstMatchingBusiness) return null;
    
      switch (firstMatchingBusiness.theme_color) {
        case 'theme14':
          return (
            <div className="col-md-4">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <h3>{t("Restaurant_enquiry")}</h3>
                <h4>{sevdays?.totalReastaurantEnquiry || 0}</h4>
              </div>
            </div>
          );
    
        case 'theme20':
          return (
            <div className="col-md-4">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <h3>{t("Mini_marketplace_enquiry")}</h3>
                <h4>{sevdays?.totalMiniMarketplaceEnquiry || 0}</h4>
              </div>
            </div>
          );
    
        case 'theme22':
          return (
            <div className="col-md-4">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <h3>{t("Cab_enquiry")}</h3>
                <h4>{sevdays?.totalCabEnquiry || 0}</h4>
              </div>
            </div>
          );
    
        default:
          return null;
      }
    };

    //filter
    // const filterNames = {
    //   current_date: "Today",
    //   last_week: "Last Week",
    //   last_month: "Last Month",
    //   last_year: "Last Year",
    // };

    // const handleFilterChange = (type) => {
    //   setFilterType(type);
    //   handleSubmit(type);
    //   setCustomDateRange([null, null]); // Reset custom date range when switching filters
    //   setIsDatePickerVisible(false); // Hide the date picker on filter change
    // };

    // const handleCustomDateChange = (dates) => {
    //   setCustomDateRange(dates);
    // };

    // const handleSubmit = (type) => {
    //   let start_date = "";
    //   let end_date = "";
    //   const today = new Date();

    //   switch (type) {
    //     case "current_date":
    //       start_date = today.toISOString().split("T")[0];
    //       end_date = start_date;
    //       break;

    //     case "last_week":
    //       const lastWeekStart = new Date();
    //       lastWeekStart.setDate(today.getDate() - 7); // 7 days back
    //       start_date = lastWeekStart.toISOString().split("T")[0];
    //       end_date = today.toISOString().split("T")[0];
    //       break;

    //     case "last_month":
    //       const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    //       const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0); // Last day of the previous month
    //       start_date = lastMonthStart.toISOString().split("T")[0];
    //       end_date = lastMonthEnd.toISOString().split("T")[0];
    //       break;

    //     case "last_year":
    //       const lastYearStart = new Date(today.getFullYear() - 1, 0, 1); // Start of the previous year
    //       const lastYearEnd = new Date(today.getFullYear() - 1, 11, 31); // End of the previous year
    //       start_date = lastYearStart.toISOString().split("T")[0];
    //       end_date = lastYearEnd.toISOString().split("T")[0];
    //       break;

    //     case "custom":
    //       if (startDate && endDate) {
    //         start_date = startDate.toISOString().split("T")[0];
    //         end_date = endDate.toISOString().split("T")[0];
    //       } else {
    //         alert("Please select both start and end dates for the custom filter.");
    //         return;
    //       }
    //       break;

    //     default:
    //       alert("Please select a valid filter type.");
    //       return;
    //   }

    //   crmData(start_date, end_date); // Send dates to fetch data
    // };

    //refresh
    // const handleRefresh = () => {
    //   if (isLoadings) return;
    //   setFilterType("");
    //   setIsLoadings(true);
    //   setFilters({});

    //   try {
    //     setTimeout(() => {
    //       const response = crmData();
    //       console.log(response);
    //     }, 1000);
    //     console.log(response.data);
    //   } catch (error) {
    //     console.error("Error refreshing data:", error.message);
    //   }
    //   setTimeout(() => {
    //     setIsLoadings(false);
    //   }, 1000);
    // };

    // //crm
    // const checkUserType = () => {
    //   const { user_type, email } = userData;

    //   switch (user_type) {
    //     case "super_admin":
    //       return "";
    //     case "Sales Executive":
    //       return email;
    //     case "Team Leader":
    //       return usersData.map(item => item.email).join(",") || null;
    //     default:
    //       return null;
    //   }
    // }

    // const crmData = (start_date, end_date) => {
    //   let email = checkUserType();
    //   if (email == null) return;

    //   let data = JSON.stringify({ start_date, end_date, email });

    //   let config = {
    //     method: "post",
    //     maxBodyLength: Infinity,
    //     // url: "https://crmdubai.businessbay.io/api/LeadController/totalLeadsSummary",
    //     url: `${environmentVariables?.crmDubaiApi}/api/LeadController/totalLeadsSummary`,
    //     headers: {
    //       token: "7d68e2c38dfb38785e7a",
    //       "Content-Type": "application/json",
    //     },
    //     data: data,
    //   };

    //   axios
    //     .request(config)
    //     .then((response) => {
    //       const data = response?.data;          
    //       let cSymbol = getCurrencyDataByCurrency(userData?.pay_currency);
    //       setcurrencySymbol(cSymbol);
    //       if (data.success) {
    //         setTotalLeads(data?.data?.total_leads || 0);
    //         setNewLeads(data?.data?.new_leads || 0);
    //         setCardsSold(data?.data?.card_ordered || 0);
    //         setCardsDelivered(data?.data?.card_delivered || 0);

    //         const pipelineLeads = (
    //           Number(data?.data?.whatsap_msg_Leads || 0) +
    //           Number(data?.data?.answered_leads || 0) +
    //           Number(data?.data?.call_leads || 0) +
    //           Number(data?.data?.follow_up_leads || 0) +
    //           Number(data?.data?.intro_call_leads || 0) +
    //           Number(data?.data?.prospect_leads || 0) +
    //           Number(data?.data?.customer_leads || 0)
    //         );

    //         const totalRevenue = (
    //           Number(data?.data?.online_revenue || 0) +
    //           Number(data?.data?.offline_revenue || 0)
    //         );

    //         const rejectedLeads = (
    //           Number(data?.data?.failed_leads || 0) +
    //           Number(data?.data?.rejected_leads || 0)
    //         );

    //         setPipelineLeads(pipelineLeads);
    //         setTotalRevenue(totalRevenue);
    //         setRejectedLeads(rejectedLeads);
    //         setClosedLeads(data?.data?.close_leads || 0);
    //         setOnlineRevenue(data?.data?.online_revenue || 0);
    //         setOfflineRevenue(data?.data?.offline_revenue || 0);
    //       } else {
    //         setTotalLeads(0);
    //         setNewLeads(0);
    //         setCardsSold(0);
    //         setCardsDelivered(0);
    //         setRejectedLeads(0);
    //         setClosedLeads(0);
    //         setPipelineLeads(0);
    //         setTotalRevenue(0);
    //         setOnlineRevenue(0);
    //         setOfflineRevenue(0);
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }; 

    // useEffect(() => {
    //   if(userData?.user_type == "super_admin"){
    //     crmData();
    //   }
    // }, [usersData]);

  return (
    <>
      <PageName PageName={t("Dashboard")} />
      {!!showPlanExpireBanner && userData?.user_type != "super_admin" &&
        <div className="row">
          <div className="col-lg-12">
            <div className="planexpiry-banner">
              {planExpireDays > 1 ?
              <p>{`Your plan will expire in ${planExpireDays}${(planExpireDays>1)?'days':'day'}. Please renew it.`}</p>
              : (planExpireDays == 1)?<p>{`Your plan will expire today. Please renew it.`}</p>:<p>{`Your plan has been expired. Please renew it.`}</p>}
                <div className="expirebtn cursor-pointer" onClick={handlePlanClick}>Upgrade plan</div>
            </div>
          </div>
        </div>
      }
      <div className="row Greeting_total_wrapper">
        <div className="col-md-5" id="grettingWrapper">
          <div className="tile  welcom ">
            <div>
              <h6>
                <b>{userData?.name}</b>
              </h6>

              <p>{GreatingMsg}</p>
              {/* <div style={{marginTop:"10px" ,fontSize:"16px", cursor:"pointer"}}>
                <a href="https://www.youtube.com/watch?v=sg_ZoHZ-hEk" target="_blank">How it works <i class="fa-solid fa-up-right-from-square"></i></a>
                </div> */}
                <div  onClick={() => navigate("/faqs")} style={{marginTop:"10px" ,fontSize:"14px", cursor:"pointer", color:"#413e3c"}}>
                {t("HIW")} <i class="fa-solid fa-up-right-from-square"></i>
                </div>
              {businessLink?.includes("undefined") ? (
                ""
              ) : (
                <button className="copybtn" onClick={handleCopyClick}>
                  {t("Bs_link")} <i className="fa-solid fa-copy"></i>
                </button>
              )}
            </div>
            <div className="QRcode_wrapper">
              {businessLink?.includes("undefined")?  
            "": 
              <> 
                <QRCode
                value={businessLink}
                bgColor={"#ffffff"}
                fgColor={"#372f2f"}
                includeMargin={true}
                level={"M"}
                style={{
                  miheight: "100px",
                  minWidth: "100px",
                  margin: "auto",
                  display: "block",
                }}
                id="businessLinkQr"
                />
                <a onClick={downloadQR} 
                className="download-qr-code"> {t("Downlod_qr")} </a>
              </>
            }
            </div>
          </div>
        </div>
        <div className="col-md-7" id="total_wrapper_container">
          <div className="row">
            <div className="col-md-4">
              <div className="tile bbox Total_Titlebox_container">
                <i className="fa-solid fa-business-time"></i>
                <h3>{t("Total_business")}</h3>
                <h4>{sevdays?.theBusinesses?.[0]?.total_count || 0}</h4>
              </div>
            </div>
            <div className="col-md-4">
              <div className="tile bbox Total_Titlebox_container">
                <i className=" fa-regular fa-handshake"></i>
                <h3>{t("Total_appointments")}</h3>
                <h4>{sevdays?.resultA?.total || 0}</h4>
              </div>
            </div>
            <div className="col-md-4">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <h3>{t("Total_staff")}</h3>
                <h4>{sevdays?.totalStaff?.total || 0}</h4>
              </div>
            </div>
            {sevdays?.totalEnquiries>0 &&
              <div className="col-md-4">
                <div className="tile bbox ">
                  <i className="fa-solid fa-users"></i>
                  <h3>{t("Total_enquiry")}</h3>
                  <h4>{sevdays?.totalEnquiries || 0}</h4>
                </div>
              </div>
            }
            {sevdays?.totalMarketplaceEnquiry > 0 &&
            <div className="col-md-4">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <h3>{t("Marketplace_enquiry")}</h3>
                <h4>{sevdays?.totalMarketplaceEnquiry || 0}</h4>
              </div>
            </div>
            }
    {renderEnquiryComponent()}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="Dashboard_appointment_visitors_wrapper">
          <div className="col-12 col-lg-6 Appointment_wrapper">
            <div className="tile border-left">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5>{t("Appointments")} </h5>
                <span>{t("Last_days")}</span>
              </div>
              {sevdays &&
              typeof sevdays === "object" &&
              Object.keys(sevdays).length > 0 ? (
                <Charttc data={sevdays} />
              ) : null}
            </div>
          </div>

        <div className="col-12 col-lg-6 Visitors_wrapper">
          <div className="tile border-left">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h5>{t("Visitor")} </h5>
              <span></span>
            </div>
            {sevdays &&
            typeof sevdays === "object" &&
            Object.keys(sevdays).length > 0 ? (
              <Charttb data={sevdays} />
            ) : null}
          </div>
        </div>
      </div>

      <div className="physicalcard_recentbusiness_wrapper">
        <div className="col-12 col-lg-8 recentbusines_card_wrapper">
          <div className="tile border-left ">
            <div style={{ display: "flex",alignItems:"center", justifyContent: "space-between"}}>
              <h5>{t("Recent_business")}</h5>
              <span></span>
              <div className="contact_filter_buttonwrapper1" style={{marginTop:"0px"}}>
                <button 
                  className="contact_filter_buttonstyle1"
                  onClick={() => navigate("/businesslist")}
                >
                  {t("View_all")}
                </button>
              </div>
            </div>
            {sevdays &&
            typeof sevdays === "object" &&
            Object.keys(sevdays).length > 0 ? (
              <Charttf data={sevdays} />
            ) : null}
          </div>
        </div>

        <div className="col-12 col-lg-4 kheight physical_card_wrapperAdmin">
          <div className="tile border-left ">
            <h5>{t("Physical_card")}</h5>
            {sevdays &&
            typeof sevdays === "object" &&
            Object.keys(sevdays).length > 0 ? (
              <Chartte data={sevdays} />
            ) : null}
          </div>
        </div>
        </div>

        <div className="col-md-12">

        {/* <div className="calendar-container">
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
         events={events}
         eventContent={eventContent}
      />
    </div> */}
    
    </div>
      </div>    
    </>
  );
}
export default Dashbord;
//1112