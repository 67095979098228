import React from 'react'
import { MondaySatWrapper, MondayText, MondayTextWrapper, MondayTextvalue, NewTabMenuHeadingWrapper, OpeningHours, OpeningHoursContainer, OrderCardWrapperContainer, OrderCardWrapperContainerOH, SundayText, SundayTextValue, SundayWrapper } from './Theme14ExtStyleComponent'
import {useTranslation} from "react-i18next";
const TabOpeningHours = (props) => {
  const { t } = useTranslation();
  let rs = props?.rs?.businessHourObj?.content;
  let colordata = props?.colordata;
  const businessHoursData = JSON.parse(rs);

  const getCorrectDay = (day) => {
    const dayMappings = {
      "Monday": t("Monday"),
      "Tuesday": t("Tuesday"),
      "WednesDay": t("Wednesday"),
      "thrusdayDay": t("Thursday"),
      "fridayDay": t("Friday"),
      "saturdayDay": t("Saturday"),
      "sundayDay": t("Sunday"),
    };

    return dayMappings[day] || day;
  };

  const convertTo12HourFormat = (time) => {
    let [hour, minute] = time.split(':').map(Number);
    let period = 'AM';

    if (hour >= 12) {
      period = 'PM';
      if (hour > 12) {
        hour -= 12;
      }
    }

    if (hour === 0) {
      hour = 12;
    }

    return `${hour}:${minute < 10 ? '0' : ''}${minute} ${period}`;
  };

  const getOpeningHours = (businessHoursData) => {

    const daysOfWeek = ["Monday", "Tuesday", "WednesDay", "thrusdayDay", "fridayDay", "saturdayDay", "sundayDay"];
    let startDay = null;
    let endDay = null;
    let closedDays = [];

    // Find the first and last true values in businessHourObject
    for (let day of daysOfWeek) {
      if (businessHoursData[`is${day}`]) {
        if (!startDay) startDay = day;
        endDay = day;
      } else {
        closedDays.push(day);
      }
    }

    // Replace misspelled days with correct spellings
    startDay = getCorrectDay(startDay);
    endDay = getCorrectDay(endDay);

    // Construct opening hours string
    let openingHours = "";
    if (startDay && endDay) {
      openingHours = startDay === endDay ? startDay : `${startDay} - ${endDay}`;
    } else if (startDay) {
      openingHours = startDay;
    }

    let closedDaysString = "";
    if (closedDays.length > 0) {
      closedDaysString = closedDays.map(day => getCorrectDay(day)).join(", ");
    }

    return { openingHours, closedDays: closedDaysString };
  };

  const { openingHours, closedDays } = getOpeningHours(businessHoursData);
  const formatTimeRange = (startTime, endTime) => {
    return `${convertTo12HourFormat(startTime)} to ${convertTo12HourFormat(endTime)}`;
  };
  return (
    <OrderCardWrapperContainerOH>
      <OpeningHoursContainer style={{ backgroundColor: colordata?.dynamicBackgroundColor || "" }}>
        {/* <OpeningHours>Opening Hours</OpeningHours> */}
        <MondaySatWrapper>
          <MondayTextWrapper> <MondayText style={{color: colordata?.dynamicTextDescriptionColor || ""}}>{openingHours}</MondayText></MondayTextWrapper>
          <MondayTextWrapper> <MondayTextvalue style={{color: `${colordata?.dynamicTextDescriptionColor}90` || ""}} >
            {/* {businessHoursData?.isMonday ? `${businessHoursData?.modayStartDate} to ${businessHoursData?.modayEndDate}` : 'CLOSED'}  */}
            {businessHoursData?.isMonday ? formatTimeRange(businessHoursData?.modayStartDate, businessHoursData?.modayEndDate) : '10:00 to 18:00'}
          </MondayTextvalue></MondayTextWrapper>
          <SundayWrapper>
            <SundayText style={{color: colordata?.dynamicTextDescriptionColor || ""}}> {closedDays} </SundayText>
            {/* <SundayTextValue >CLOSED</SundayTextValue> */}
            {closedDays && (
                        <SundayTextValue  style={{color: `${colordata?.dynamicTextDescriptionColor}90` || ""}}>{t("Closed")}</SundayTextValue>
                        )}
          </SundayWrapper>
        </MondaySatWrapper>
      </OpeningHoursContainer>
    </OrderCardWrapperContainerOH>
  )
}

export default TabOpeningHours