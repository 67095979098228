import * as yup from "yup";
import { object, string, ref } from "yup";
import { useTranslation } from "react-i18next";
const getCharacterValidationError = (str) => {
  const { t } = useTranslation();
  return `${t("Password_atleast_one")} ${str} ${t("Character")}`;
};
export const registerValidationCustomer = (t)=> {
  return yup.object().shape({
  name: yup
    .string()
    .required(t("Full_name_required"))
    .min(3, t("Name_greater_than_3"))
    .max(45)
    .matches(/^[A-Za-z\s]+$/,t("Full_name_not_contain_special_characters")),
  email: yup.string().required(t("Email_required")).email(t("Please_enter_valid_email")),
  contact: yup.string().required(t("Mobile_no_required")),
  password: string()
    .required(t("Please_enter_a_password"))
    .min(8, t("Password_at_least_8_characters"))
    .matches(/[0-9]/, getCharacterValidationError(t("Digit")))
    .matches(/[a-z]/, getCharacterValidationError(t("Lowercase")))
    .matches(/[A-Z]/, getCharacterValidationError(t("Uppercase"))),
  confirmPassword: string()
    .required(t("Retype_password"))
    .oneOf([ref("password")], t("Passwords_does_not_match")),
})};

export const OTPSchema = (t)=> {return yup.object({
  email: yup.string()
    .email(t("Invalid_email_address"))
    .required(t("Email_required")),
})};
//212 15:59
