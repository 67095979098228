import React, { useContext, useEffect, useState, useCallback } from "react";
import "./Style.css";
import PageName from "../Utils/PageName";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { environmentVariables } from "../../config/env.config";
import "./Dstyles.css";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { useNavigate } from "react-router-dom";
import { fetchCountries } from "../../utils/utility";
import {useTranslation} from "react-i18next";

function Influencers() {
  const { t } = useTranslation();
  const apiUrl = environmentVariables?.apiUrl;
  const { userData } = useContext(userContext);
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paginationTotalRows, setPaginationTotalRows] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState([]);
  const [action] = useState({ fromUser: false });
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const fetchInfluencers = (
    page = 1,
    selectedCountryCode = "",
    search = ""
  ) => {
    const params = {
      ...(selectedCountryCode && { country_code: selectedCountryCode }), // Add country_code if provided
      ...(search && { search: search }), // Add search_query if provided
      page, // Pass the current page
      limit: paginationPerPage, // Ensure the limit (rows per page) is passed
    };

    axios
      .get(`${apiUrl}api/v1/influencer/getAllInfluencerAdmin`, {
        headers: {
          _token: userData?.token,
        },
        params,
      })
      .then((res) => {
        if (res?.status === 200) {
          setPaginationTotalRows(res?.data?.total); // Total number of rows/pages
          setData(res?.data?.result?.influencers); // Current page's data
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.error("Resource not found:", error);
        } else {
          console.error("error:", error);
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      const countryData = await fetchCountries();
      setCountries(countryData);
    };

    fetchData();
    fetchInfluencers(currentPage, selectedCountryCode, searchQuery);
  }, []);

  const tableExtensions = { export: false, print: false };

  const customStyles = {
    dataTableExtensions: {
      backgroundColor: "lightgray",
      padding: "10px",
    },
    headRow: {
      style: {
      backgroundColor: "#F8FBFF",
      borderRadius: "2px 2px 0 0",
      paddingLeft: "25px",
      height: "70px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.1)",
      overflow: "hidden",
      },
    },
    headCells: {
      style: {
        color: "#525252",
        minHeight: "64px",
        fontFamily: "DM Sans",
        fontSize: "16px",
        fontWeight: "600",
      },
    },
    rows: {
      style: {
        padding: "6px 0px 6px 25px",
        fontSize: "14px",
        minHeight: "70px !important",
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        color: "#000",
      },
    },
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      cell: (row) => (
        <span style={{ textTransform: "lowercase" }}>{row.email}</span>
      ),
    },
    {
      name: "Contact",
      selector: (row) => {
        const countryCode = row.country_code || "";
        const contact = row.contact || "";
        return countryCode && contact ? `${countryCode} ${contact}` : contact;
      },
      sortable: true,
    },
    {
      name: "Business Name",
      selector: (row) =>
        row.businesses && row.businesses.length > 0
          ? row.businesses[0].title
          : "No Business",
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      cell: (d) => (
        <i
          key={`view_${d.id}`}
          onClick={() => navigate(`/InfluencersDetails?id=${d.id}`)}
          className="first fas fa-eye"
          style={{ cursor: "pointer" }}
        ></i>
      ),
    },
  ];

  const tableData = {
    columns,
    data,
  };

  const handleOnSelectedRowsChange = useCallback(
    ({ selectedRows }) => {
      if (!action.fromUser) return; //the component always trigger this with 0 selected rows when it renders a page, what would clear the selection

      selectedRowsPerPage[currentPage] = selectedRows; //there is no way to tell if a row was DEselected, so I had to control the selected rows per page,
      //the array would get an index to control each page
      console.log(JSON.stringify(selectedRowsPerPage));
    },
    [currentPage, selectedRowsPerPage, action.fromUser]
  );

  const handlePageChange = (page) => {
    setCurrentPage(page); // Update the current page
    fetchInfluencers(page, selectedCountryCode, searchQuery);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    setPaginationPerPage(newRowsPerPage); // Update rows per page
    setCurrentPage(1); // Reset to the first page
  };

  useEffect(() => {
    fetchInfluencers(1, selectedCountryCode, searchQuery);
  }, [paginationPerPage]);

  const handleCountryChange = (e) => {
    const selectedCode = e.target.value;
    setSelectedCountryCode(selectedCode);
    fetchInfluencers(1, selectedCode, searchQuery);
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    fetchInfluencers(1, selectedCountryCode, query);
  };
   const paginationOptions = {
    rowsPerPageText: t("Rows_per_page"),
    rangeSeparatorText: t("Of"),
    selectAllRowsItem: true,
    selectAllRowsItemText: t("All"),
  };
  

  return (
    <>
      <PageName PageName="Influencers" />
      <div className="row Greeting_total_wrapper">
        <div className="col-md-12" id="total_wrapper_container">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "20px",
              alignItems: "center",
              borderRadius: "10px 10px 0 0 ",
              padding: "20px",
              marginBottom: "-5px",
              background: "#fff",
            }}
          >
            <input
              className=" customsearchbyword searchUser"
              style={{ width: "300px", lineHeight: "30px" }}
              type="text"
              placeholder="Search Username/Email"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <div className="user-select-items">
              <select
                className="form-select"
                style={{ width: "300px" }}
                name="country"
                value={selectedCountryCode}
                onChange={handleCountryChange}
              >
                <option value="">Select Country</option>
                {!!countries &&
                  countries?.length > 0 &&
                  countries?.map(
                    (country) =>
                      !!country?.id && (
                        <option key={country?.name} value={country?.phone_code}>
                          {country?.name?.charAt(0)?.toUpperCase() +
                            country?.name?.slice(1)}
                        </option>
                      )
                  )}
              </select>
            </div>
          </div>

          <div className=""></div>

          {/* <div className="row">
            <div class="col-md-3">
              <div class="tile bbox Total_Titlebox_container">
                <i class="fa-solid fa-business-time"></i>
                <h3>Total Orders Placed</h3>
                <h4>{influencerData?.totalOrders || 0}</h4>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tile bbox Total_Titlebox_container">
                <i class="fa-solid fa-business-time"></i>
                <h3>Total Pending Orders</h3>
                <h4>{influencerData?.totalPendingOrders || 0}</h4>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tile bbox Total_Titlebox_container">
                <i class=" fa-regular fa-handshake"></i>
                <h3>Total Delivered Orders</h3>
                <h4>{influencerData?.totalDeliveredOrders  || 0}</h4>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tile bbox">
                <i class="fa-solid fa-users"></i>
                <h3>Total Sales</h3>
                <h4>
                {selectedCountryCode === "971" 
                  ? `${(Number(influencerData?.totalSales) || 0).toFixed(2)}د.إ` 
                  : `₹${(Number(influencerData?.totalSales) || 0).toFixed(2)}`}
              </h4>
              </div>
            </div>

          </div> */}
        </div>
      </div>

      <div id="specific-table">
        <DataTableExtensions
          {...tableData}
          {...tableExtensions}
          customStyles={customStyles}
          filterPlaceholder="Search Physical Card"
        >
          <DataTable
            columns={columns}
            data={data}
            noHeader
            defaultSortField="id"
            sortIcon={<SortIcon />}
            defaultSortAsc={true}
            pagination
            highlightOnHover
            paginationServer
            paginationTotalRows={paginationTotalRows}
            paginationPerPage={paginationPerPage}
            paginationRowsPerPageOptions={[10, 20, 50, 100, 300, 1000, 10000]}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onSelectedRowsChange={handleOnSelectedRowsChange}
            dense
             paginationComponentOptions={{
                        ...paginationOptions,
                      }}
            customStyles={customStyles}
            noDataComponent={<div style={{padding:"24px"}}>{t("No_records_found")}</div>}
          />
        </DataTableExtensions>
      </div>
    </>
  );
}
export default Influencers;
