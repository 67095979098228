import React, { useState } from "react";
import styled from "styled-components";
import locationicon from "../../../../Images/target.png";
import Dishimg from "../../../../Images/DishReview.png";
import ReviewstarIcon from "../../../../Images/resreviewstar.png";
import {useTranslation} from "react-i18next";

import {
  ConfirmOrder,
  ConfirmOrderWrapper,
  CustomerDetailText,
  CustomerDetailsWrapper,
  DrawerCloseButton,
  DrawerCloseButtonWrapper,
  InputTextField,
  InputTextFieldWrapper,
  InputType,
  OrderDateIDWrapper,
  RevieCommentWrapper,
  ReviewDishCOntent,
  ReviewDishContentContainer,
  ReviewDishContentWrapper,
  ReviewDishImageWrapper,
  ReviewDishImg,
  ReviewOrderDateWrapper,
  ReviewOrderKey,
  ReviewOrderValue,
  Reviewstar,
  Reviewstarwrapper,
  ReviewTabCustomerDetailsWrapper,
  TypeCommentWrapper,
  TypeText,
} from "./Theme14ExtStyleComponent";
import { environmentVariables } from "../../../../config/env.config";
import axios from "axios";

const Errormsg = styled.div`
  color: red;
  margin: 0 10px;
  font-size: 12px;
`;
const Star = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
const baseUrl = environmentVariables?.apiUrl;
const StarRating = ({ rating, onRate }) => {
  const starArray = [1, 2, 3, 4, 5];
  
  return (
    <div>
      {starArray.map((star, index) => (
        <Star
          key={index}
          src={ReviewstarIcon}
          onClick={() => onRate(star)}
          style={{ opacity: rating >= star ? 1 : 0.5 }}
        />
      ))}
    </div>
  );
};

const CustomerReview = ({ closeReviewDrawer, onSubmit, userReview, fetchReviewsFromTabReviewrating, toast }) => {
  const { t } = useTranslation();
  const [ratings, setRatings] = useState({});
  const [comment, setComment] = useState("");

  const handleRate = (itemId, rating) => {
    setRatings({ ...ratings, [itemId]: rating });
  };



const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const reviewContent = Object.keys(ratings).map((itemId) => ({
      name: itemId,
      rating: ratings[itemId],
    }));

    const response = await axios.post(
      `${baseUrl}api/v1/business/addreview`,
      {
        orderId: userReview?.rows[0]?.orderId,
        businessId: userReview?.rows[0]?.businessId,
        userId: userReview?.rows[0]?.userId,
        username: userReview?.rows[0]?.username,
        BusinessName: userReview?.rows[0]?.BusinessName,
        phone: userReview?.rows[0]?.phone,
        Reviewcontent: JSON.stringify(reviewContent),
        rating: comment
      }
    );
    const successMessage = t("Review_added_successfully") || t("Review_added");
    toast.success(successMessage, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
    setRatings({});
    closeReviewDrawer();
    fetchReviewsFromTabReviewrating(userReview?.rows[0]?.businessId);
  } catch (error) {
    if (error?.response?.status === 400) {
      console.error(error?.response?.data?.message,"falseeeeeeeee");
      const errorMessage = error?.response?.data?.message || t("Bad_request_missing_field");
      toast.error(errorMessage,{
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      const errorMessage = error?.response?.data?.message || t("Error_occured_adding_review");
      toast.error(errorMessage,{
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  }
};

  return (
    <>
    

    <ReviewTabCustomerDetailsWrapper>
      <form onSubmit={handleSubmit}>
        <CustomerDetailsWrapper>
          <CustomerDetailText Custoomer>{t("Review_our_services")}</CustomerDetailText>
        </CustomerDetailsWrapper>

        <OrderDateIDWrapper>
          <ReviewOrderDateWrapper>
            <ReviewOrderKey Orderss>{t("Order_date")}</ReviewOrderKey>
            <ReviewOrderValue Orderss>
              {userReview?.rows[0]?.updatedAt.substring(0, 10)}
            </ReviewOrderValue>
          </ReviewOrderDateWrapper>
          <ReviewOrderDateWrapper>
            <ReviewOrderKey Orderss>{t("Order_id")},</ReviewOrderKey>
            <ReviewOrderValue Orderss>
              {userReview?.rows[0]?.orderId &&
                `${userReview.rows[0].orderId.substring(0, 10)}`}
            </ReviewOrderValue>
          </ReviewOrderDateWrapper>
        </OrderDateIDWrapper>

        <OrderDateIDWrapper>
          {userReview?.rows[0]?.items &&
            JSON.parse(userReview.rows[0].items).map((item, index) => (
              <ReviewDishContentContainer key={index} ReviewDishContentPhone>
                <ReviewDishImageWrapper>
                  <ReviewDishImg
                    src={`${environmentVariables.apiUrl}uploads/${item.image}`}
                  />
                </ReviewDishImageWrapper>
                <ReviewDishContentWrapper>
                  {/* <ReviewDishCOntent>{item.name}</ReviewDishCOntent> */}
                
                  <ReviewDishContentWrapper ReviewDishpaddingphone>
                  <ReviewDishCOntent>{item.name}</ReviewDishCOntent>
                  <StarRating
                    rating={ratings[item.name] || 0}
                    onRate={(rating) => handleRate(item.name, rating)}
                  />
                </ReviewDishContentWrapper>
                </ReviewDishContentWrapper>
                <ReviewDishCOntent Delevered>
                    {userReview?.rows[0]?.createdAt.substring(0, 10)}
                  </ReviewDishCOntent>
              </ReviewDishContentContainer>
            ))}
          <Reviewstarwrapper>
          
          </Reviewstarwrapper>
        </OrderDateIDWrapper>

        <RevieCommentWrapper>
          <TypeCommentWrapper>
            <TypeText>{t("Type_comment")}</TypeText>
          </TypeCommentWrapper>
          <InputType
            type="text"
            placeholder={t("Type_comment_op")}
            rows="5"
            cols="10"
             value={comment}
              onChange={(e) => setComment(e.target.value)}
          />
        </RevieCommentWrapper>

        <ConfirmOrderWrapper>
          <button
            type="submit"
            style={{
              background: "transparent",
              border: "none",
              fontWeight: "700",
              color: "#fff",
              fontSize: "24px",
            }}
            
          >
         {t("Submit")}
          </button>
        </ConfirmOrderWrapper>
        <DrawerCloseButtonWrapper onClick={closeReviewDrawer}>
          <DrawerCloseButton>x</DrawerCloseButton>
        </DrawerCloseButtonWrapper>
      
      </form>

    </ReviewTabCustomerDetailsWrapper>
    </>
  );
};

export default CustomerReview;
