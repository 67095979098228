import React, { useState, useEffect, useContext, Suspense } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { environmentVariables } from "../../config/env.config";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import { toast } from "react-toastify";
import Icon from "../../Images/Businessbayinactivepng.png";
import { userContext } from "../../context/userContext";
import LandingPage from "../../frontend/LandingPage";

// Import themes
// import Theme1Ext from "./themes/theme1/Theme1Ext";
// import Theme2Ext from "./themes/theme2/Theme2Ext";
// import Theme3Ext from "./themes/theme3/Theme3Ext";
// import Theme4Ext from "./themes/theme4/Theme4Ext";
// import Theme5Ext from "./themes/theme5/Theme5Ext";
// import Theme6Ext from "./themes/theme6/Theme6Ext";
// import Theme7Ext from "./themes/theme7/Theme7Ext";
// import Theme8Ext from "./themes/theme8/Theme8Ext";
// import Theme9Ext from "./themes/theme9/Theme9Ext";
// import Theme10Ext from "./themes/theme10/Theme10Ext";
// import Theme11Ext from "./themes/theme11/Theme11Ext";
// import Theme12Ext from "./themes/theme12/Theme12Ext";
// import Theme14Ext from "./themes/theme14/Theme14Ext";
// import Theme15Ext from "./themes/theme15/Theme15Ext";
// import Theme16Ext from "./themes/theme16/Theme16Ext";
// import Theme17Ext from "./themes/theme17/Theme17Ext";
// import Theme18Ext from "./themes/theme18/Theme18Ext";
// import Theme20Ext from "./themes/theme20/Theme20Ext";
// import Theme22Ext from "./themes/theme22/Theme22Ext";
// import Theme27Ext from "./themes/theme27/Theme27Ext";
// import Theme28Ext from "./themes/theme28/Theme28Ext";
// import Theme29Ext from "./themes/theme29/Theme29Ext";
// import Theme33Ext from "./themes/theme33/Theme33Ext";
// import Theme32Ext from "./themes/theme32/Theme32Ext";
// import Theme31Ext from "./themes/theme31/Theme31Ext";
// import Theme30Ext from "./themes/theme30/Theme30Ext";
// import Theme34Ext from "./themes/theme34/Theme34Ext";
// import Theme35Ext from "./themes/theme35/Theme35Ext";
// import Theme36Ext from "./themes/theme36/Theme36Ext";
// import Theme37Ext from "./themes/theme37/Theme37Ext";
// import Theme38Ext from "./themes/theme38/Theme38Ext";
// import Theme39Ext from "./themes/theme39/Theme39Ext";
// import Theme40Ext from "./themes/theme40/Theme40Ext";
// import Theme41Ext from "./themes/theme41/Theme41Ext";
// import Theme42Ext from "./themes/theme42/Theme42Ext";
// import Theme43Ext from "./themes/theme43/Theme43Ext";
// import Theme44Ext from "./themes/theme44/Theme44Ext";
// import Theme45Ext from "./themes/theme45/Theme45Ext";
// import Theme46Ext from "./themes/theme46/Theme46Ext";
// import Theme47Ext from "./themes/theme47/Theme47Ext";
// import Theme48Ext from "./themes/theme48/Theme48Ext";
// import Theme49Ext from "./themes/theme49/Theme49Ext";
// import Theme50Ext from "./themes/theme50/Theme50Ext";
// import Theme51Ext from "./themes/theme51/Theme51Ext";
// import Theme52Ext from "./themes/theme52/Theme52Ext";
// import Theme53Ext from "./themes/theme53/Theme53Ext";
// import Theme54Ext from "./themes/theme54/Theme54Ext";
// import Theme55Ext from "./themes/theme55/Theme55Ext";
// import Theme56Ext from "./themes/theme56/Theme56Ext";

import { changeThemeColor, changeThemeColorCTA, changeThemeColorDesc, changeThemeColorMainBackground, changeThemeColorText, changeThemeColorCTAText } from "../../common/changeThemeColor";
import { Helmet } from "react-helmet";


const Template2 = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = props?.Slug || location?.pathname?.split("/")[1];
  const baseUrl = environmentVariables?.apiUrl;
  const frntUrl = environmentVariables?.frntUrl;
  
  const { userData } = useContext(userContext);
  const [themeName, setThemeName] = useState(null);
  const [businessData, setBusinessData] = useState(null);
  const [currencySymbol,setCurrencySymbol]=useState('₹');
  const [isLoading, setIsLoading] = useState(true);
  const [businessId, setBusinessId] = useState(null);
 const [dynamicMainBackgroundColor, setDynamicMainBackgroundColor] = useState("");
  const [dynamicBackgroundColor, setDynamicBackgroundColor] = useState("");
  const [dynamicTextHeadingColor, setDynamicTextHeadingColor] = useState("");
  const [dynamicTextDescriptionColor, setDynamicTextDescriptionColor] = useState("");
  const [dynamicCtaColor, setdynamicCtaColor] = useState("");
  const [dynamicCtaTextColor, setDynamicCtaTextColor] = useState("");

  const loadThemeComponent=(themeName)=>{
    if (!!themeName && themeName != 'home') {
      switch (themeName) {
        case "theme56":
          return React.lazy(()=>import('./themes/theme56/Theme56Ext.js'))
        case "theme55":
          return React.lazy(()=>import('./themes/theme55/Theme55Ext.js'))
        case "theme54":
          return React.lazy(()=>import('./themes/theme54/Theme54Ext.js'))
        case "theme53":
          return React.lazy(()=>import('./themes/theme53/Theme53Ext.js'))
        case "theme52":
          return React.lazy(()=>import('./themes/theme52/Theme52Ext.js'))
        case "theme51":
          return React.lazy(()=>import('./themes/theme51/Theme51Ext.js'))
        case "theme50":
          return React.lazy(()=>import('./themes/theme50/Theme50Ext.js'))
        case "theme49":
          return React.lazy(()=>import('./themes/theme49/Theme49Ext.js'))
        case "theme48":
          return React.lazy(()=>import('./themes/theme48/Theme48Ext.js'))
        case "theme47":
          return React.lazy(()=>import('./themes/theme47/Theme47Ext.js'))
        case "theme46":
          return React.lazy(()=>import('./themes/theme46/Theme46Ext.js'))
        case "theme45":
          return React.lazy(()=>import('./themes/theme45/Theme45Ext.js'))
        case "theme44":
          return React.lazy(()=>import('./themes/theme44/Theme44Ext.js'))
        case "theme43":
          return React.lazy(()=>import('./themes/theme43/Theme43Ext.js'))
        case "theme42":
          return React.lazy(()=>import('./themes/theme42/Theme42Ext.js'))
        case "theme41":
          return React.lazy(()=>import('./themes/theme41/Theme41Ext.js'))
        case "theme40":
          return React.lazy(()=>import('./themes/theme40/Theme40Ext.js'))
        case "theme39":
          return React.lazy(()=>import('./themes/theme39/Theme39Ext.js'))
        case "theme38":
          return React.lazy(()=>import('./themes/theme38/Theme38Ext.js'))
        case "theme37":
          return React.lazy(()=>import('./themes/theme37/Theme37Ext.js'))
        case "theme36":
          return React.lazy(()=>import('./themes/theme36/Theme36Ext.js'))
        case "theme35":
          return React.lazy(()=>import('./themes/theme35/Theme35Ext.js'))
        case "theme34":
          return React.lazy(()=>import('./themes/theme34/Theme34Ext.js'))
        case "theme33":
          return React.lazy(()=>import('./themes/theme33/Theme33Ext.js'))
        case "theme32":
          return React.lazy(()=>import('./themes/theme32/Theme32Ext.js'))
        case "theme31":
          return React.lazy(()=>import('./themes/theme31/Theme31Ext.js'))
        case "theme30":
          return React.lazy(()=>import('./themes/theme30/Theme30Ext.js'))
        case "theme29":
          return React.lazy(()=>import('./themes/theme29/Theme29Ext.js'))
        case "theme28":
          return React.lazy(()=>import('./themes/theme28/Theme28Ext.js'))
        case "theme27":
          return React.lazy(()=>import('./themes/theme27/Theme27Ext.js'))
        case "theme22":
          return React.lazy(()=>import('./themes/theme22/Theme22Ext.js'))
        case "theme20":
          return React.lazy(()=>import('./themes/theme20/Theme20Ext.js'))
        case "theme18":
          return React.lazy(()=>import('./themes/theme18/Theme18Ext.js'))
        case "theme17":
          return React.lazy(()=>import('./themes/theme17/Theme17Ext.js'))
        case "theme16":
          return React.lazy(()=>import('./themes/theme16/Theme16Ext.js'))
        case "theme14":
          return React.lazy(()=>import('./themes/theme14/Theme14Ext.js'))
        case "theme12":
          return React.lazy(()=>import('./themes/theme12/Theme12Ext.js'))
        case "theme15":
          return React.lazy(()=>import('./themes/theme15/Theme15Ext.js'))
        case "theme11":
          return React.lazy(()=>import('./themes/theme11/Theme11Ext.js'))
        case "theme10":
          return React.lazy(()=>import('./themes/theme10/Theme10Ext.js'))
        case "theme9":
          return React.lazy(()=>import('./themes/theme9/Theme9Ext.js'))
        case "theme8":
          return React.lazy(()=>import('./themes/theme8/Theme8Ext.js'))
        case "theme7":
          return React.lazy(()=>import('./themes/theme7/Theme7Ext.js'))
        case "theme6":
          return React.lazy(()=>import('./themes/theme1/Theme1Ext.js'))
        case "theme5":
          return React.lazy(()=>import('./themes/theme2/Theme2Ext.js'))
        case "theme3":
          return React.lazy(()=>import('./themes/theme3/Theme3Ext.js'))
        case "theme4":
          return React.lazy(()=>import('./themes/theme4/Theme4Ext.js'))
        case "theme2":
          return React.lazy(()=>import('./themes/theme5/Theme5Ext.js'))
        case "theme1":
          return React.lazy(()=>import('./themes/theme6/Theme6Ext.js'))
        case "inactive":
          return React.lazy(()=>import('../Components/LoadOptionalThemes/Inactive.js'))
        case "Bad Request":
          return React.lazy(()=>import('../Components/LoadOptionalThemes/BadRequest.js'))
          case "redirecting":
          return React.lazy(()=>import('../Components/LoadOptionalThemes/Redirecting.js'))
          case "planexpired":
          return React.lazy(()=>import('../Components/LoadOptionalThemes/PlanExpired.js'))
        default:
          return React.lazy(()=>import('../Components/LoadOptionalThemes/UnknownTheme.js'))
      }
    } else if(themeName == 'home'){
      return "home"
    } else {
      return "weakinternet"
    }
  }


  useEffect(() => {
      changeThemeColor(dynamicBackgroundColor);
    }, [dynamicBackgroundColor]);
    useEffect(() => {
      changeThemeColorMainBackground(dynamicMainBackgroundColor);
    }, [dynamicMainBackgroundColor]);
    useEffect(() => {
      changeThemeColorDesc(dynamicTextDescriptionColor);
    }, [dynamicTextDescriptionColor]);
    useEffect(() => {
      changeThemeColorText(dynamicTextHeadingColor);
    }, [dynamicTextHeadingColor]);
    useEffect(() => {
      changeThemeColorCTA(dynamicCtaColor);
    }, [dynamicCtaColor]);
      useEffect(() => {
    changeThemeColorCTAText(dynamicCtaTextColor);
  }, [dynamicCtaColor]);

    const [metaTag, setMetaTag] = useState("");
  
  const countViewerBySlug = async () => {
    try {
      const data = { business_id: businessId, slug: params };
      const response = await axios.put(
        `${baseUrl}api/v1/business/countViewerBySlug`,
        data
      );
      if (response.status !== 200) {
        console.error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };
  const checkSubDomain = (slugData)=>{
    let reservedSubdomain = ['www', 'uat', 'app', 'test', 'dashboard', 'appoinments','super_dashboard','businesslist','Roles','user','requestCard_admin','PlansSuperAdmin','Coupons_admin','Events_admin','Plan_enquiry','bulk_upload','userUploadList','Setting_admin','plans','contact','MinimarketEnquiry','send-notification','restaurantorders','profile','create-card'];
    return reservedSubdomain.includes(slugData);
  }
  const getPlanName = async () => {
    setIsLoading(true);
    try {    
      if(!checkSubDomain(params)){
        const res = await axios.get(
          `${baseUrl}api/v1/business/fetchBusinessBySlug`,
          {
            params: { slug: params },
          }
        );
         if(res?.data?.data){
           setDynamicMainBackgroundColor(res.data.data.dynamicMainBackgroundColor)
           setDynamicTextHeadingColor(res.data.data.dynamicTextHeadingColor)
           setDynamicBackgroundColor(res.data.data.dynamicBackgroundColor)
           setDynamicTextDescriptionColor(res.data.data.dynamicTextDescriptionColor)
           setdynamicCtaColor(res.data.data.dynamicCtaColor)
           setDynamicCtaTextColor(res.data.data.dynamicCtaTextColor)
          }
        if(!res?.data?.success){
          console.log("1111 setThemeName1===>>>", themeName);
           if(res?.data?.planExpired==true){
            console.log(res?.data?.planExpired,"1111 efweuiyfweiu");
            
            setThemeName("planexpired");
            return;
          }
          if(!!userData?.id){
            navigate('/dashboard');
          }
           
          else{
            setThemeName("redirecting");
            setTimeout(()=>{
              window.location.href = frntUrl;
            },1500)
          }
        }else{
          setBusinessData(res?.data?.data);
          setCurrencySymbol(res?.data?.currencySymbol);
          if (res?.data?.data?.status === "active") {
            setThemeName(res?.data?.data?.theme_color);
          } else {
            setThemeName("inactive");
          }
          setIsLoading(false);
          setBusinessId(res?.data?.data?.id);
        }
      }else{
        if(!!userData?.id){
          navigate('/dashboard');
        }else{
          setThemeName("home"); 
          navigate('/');
        }
      }
    } catch (err) {
      setIsLoading(false);
      console.error("Error fetching business data:", err?.response);
      if (err?.response?.status === 400) {
        setThemeName("inactive");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 6000);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPlanName();
  }, []);
  
  const getUpdatedKeyWords = (keywords)=>{
    return keywords.map((item) => item.name).join(", ");
  }
  
  
 useEffect(() => {   
   if(businessData?.metaKeywordObj && businessData.metaKeywordObj[0]?.keywords){
    const Metakeywords = JSON.parse(businessData?.metaKeywordObj[0]?.keywords);
    const updateKeywords = typeof Metakeywords == 'string'? getUpdatedKeyWords(JSON.parse(Metakeywords)): getUpdatedKeyWords(Metakeywords); // Create a string with comma-separated names
    // console.log(updateKeywords, "Meta keywords are being set correctly");
    setMetaTag(updateKeywords); 
  }
  }, [businessData]);


  useEffect(() => {
    if (businessId) countViewerBySlug();
  }, [businessId]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          alignItems: "center",
        }}
      >
        <CircularLoader />
      </div>
    );
  }
  const handleReload = () => {
    window.location.reload();
  };

  const getMetaKeywords = () => {
    return(
      <Helmet>
        {businessData?.metaTitle ? <title> {businessData?.metaTitle}</title>:""}
        <meta charSet="utf-8" />
        <link rel="canonical" href={businessData?.canonicalTag || ""} />
        <meta name="description" content={businessData?.metaDescription || ""} />
        <meta name="keywords" content={metaTag} />
      </Helmet>
    )
  }
  const renderTheme = () => { 
    const ThemeComponent=loadThemeComponent(themeName);
    if (!!themeName && themeName != 'home') {
      return ThemeComponent?<>{getMetaKeywords()}<Suspense fallback={<div>Loading Theme...</div>}><ThemeComponent rs={businessData} id={businessId} dynamicMainBackgroundColor={dynamicMainBackgroundColor} dynamicBackgroundColor={dynamicBackgroundColor} dynamicTextHeadingColor={dynamicTextHeadingColor}  dynamicTextDescriptionColor={dynamicTextDescriptionColor} dynamicCtaColor={dynamicCtaColor} dynamicCtaTextColor={dynamicCtaTextColor} /></Suspense></>:<><div>Unknown Theme</div></>;
    } else if(themeName == 'home'){
      return <LandingPage />
    } else {
      return (
        <div
          className="bad-request"
          style={{
            textAlign: "center",
            marginBottom: "80px",
            backgroundColor: "white",
            padding: "20px",
          }}
        >
          <div>
            <h1>Your internet connection is weak.</h1>
            <a onClick={handleReload}>Click here to reload.</a>
          </div>
        </div>
      );
    }
  };
  return renderTheme(); 
};

export default Template2;