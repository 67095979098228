import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { environmentVariables } from "../../../config/env.config";
import React, { useContext, useEffect, useState } from "react";
import { userContext } from "../../../context/userContext";
import { businessObjContext } from "../../../context/businessObjContext";
import "./../Updatedcss/BusinessChangeBlockUpdatedStyle.css";
import { useTranslation } from "react-i18next";

function NewGallerycreate({
  setExistingGalleryImages,
  existingGalleryImages,
  setGallaryObject,
  gallaryObject,
  isGallaryEnable,
  galleryView,
  setGalleryView,
  galleryData,
  setGalleryData,
  businessId,
}) {
  const { userData, setUserData } = useContext(userContext);
  const baseUrl = environmentVariables?.apiUrl;
  const [imageLink, setImageLink] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const { t } = useTranslation();
  

  const [bannerImageUrl, setBannerImageUrl] = useState("");
  const [bannerData, setBannerData] = useState("");
  const [videoData, setVideoData] = useState("");
  // let getLocalStorageDataGet = localStorage.getItem("businessObj");
  const { businessObjData } = useContext(businessObjContext);
  useEffect(() => {
    let getLocalStorageData = "";
    if (businessObjData && businessObjData?.gallaryObj) {
      getLocalStorageData = JSON.parse(businessObjData?.gallaryObj?.content);
    }
    // console.log(getLocalStorageData, "21 getLoca businessObj", businessObjData);

    if (
      getLocalStorageData ||
      getLocalStorageData?.customImageLink ||
      getLocalStorageData?.customVideoLink ||
      getLocalStorageData?.gallaryStr ||
      getLocalStorageData?.gallary_video
    ) {
      setImageLink(getLocalStorageData?.customImageLink || "");
      setVideoLink(getLocalStorageData?.customVideoLink || "");
      setBannerImageUrl(getLocalStorageData?.gallaryStr || "");
      setVideoData(getLocalStorageData?.gallary_video || "");
    } else {
      setImageLink("");
      setVideoLink("");
      setBannerImageUrl("");
      setVideoData("");
    }
  }, [businessObjData]);
  const removeForm = (index) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/business/deleteImageGallery/${index}/${businessId}`,
      headers: {
        _token: userData?.token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setGalleryData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getGalleryImages = () => {};
  const removeExisting = (index) => {
    const newArr = [...existingGalleryImages];
    newArr.splice(index, 1);
    setExistingGalleryImages(newArr);
  };
  const handleBannerChange = (e) => {
    if (e.target.files.length > 0) {
      // Check if files are selected
      if (Array.from(e.target.files).some(file => file.size > 512000)) {
        toast.error("Maximum Image size should be less than 500kb!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        e.target.value = null; // Clear the file input
      } else {
        setImageLoading(true);
        let data = new FormData();
        
        // Append multiple files to the FormData object
        Array.from(e.target.files).forEach((file) => {
          data.append("gallery", file); // appending each file
        });
        
        data.append("isGallaryEnable", "1");
        data.append("businessId", businessId);
  
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${environmentVariables?.apiUrl}api/v1/business/uploadGallery`,
          headers: {
            _token: userData?.token,
          },
          data: data,
        };
  
        axios
          .request(config)
          .then((response) => {
            setImageLoading(false);
            setGalleryData(response?.data?.data); // Store the uploaded gallery data
          })
          .catch((error) => {
            setImageLoading(false);
            console.log(error);
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          });
      }
    } else {
      toast.error("Please Select an Image to Upload!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };
  
  // const handleBannerChange = (e) => {
  //   setGalleryData([...galleryData, e.target.files[0]]);
  //   try {
  //     const file = e.target.files[0];
  //     setBannerData(e.target.files[0]);
  //     // Use the FileReader API to display the selected image
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setBannerImageUrl(reader.result);
  //       setGalleryView([...galleryView, reader.result]);
  //     };
  //     reader.readAsDataURL(file);
  //   } catch (err) {
  //     console.log(err, "error");
  //   }
  // };

  // useEffect(() => {
  //   let obj = {};
  //   if (imageLink != "") {
  //     obj.gallaryImage = imageLink;
  //   }
  //   if (videoLink != "" && videoLink != "undefined") {
  //     obj.videoLink = videoLink;
  //   }
  //   if (bannerData != "") {
  //     obj.gallary = galleryData;
  //   }
  //   if (videoData != "") {
  //     obj.gallary_video = videoData;
  //   }
  //   // console.log(obj,"@!@#@#@%#",videoLink)
  //   setGallaryObject(obj);
  // }, [imageLink, videoLink, bannerImageUrl, bannerData, videoData]);

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    setVideoData(file);
  };

  return (
    <>
      {/* <div class="mb-3">
        <label class="form-label">Image Link</label>
        <input
          class="form-control"
          type="text"
          value={imageLink == "undefined" ? "" : imageLink}
          onChange={(e) => setImageLink(e.target.value)}
          placeholder="Image Link"
        />
      </div> */}

      <div class="mb-3">
        <label class="form-label"> {t("Choose_image")}</label> (200 x 200)
        <input
          className="form-control"
          disabled={imageLoading}
          type="file"
          multiple
          name="banner" // Set the name to match the Multer field name
          onChange={handleBannerChange}
          accept=".png, .jpg, .jpeg"
        />
        {/* <div className="boxbanner"> */}
        {/* <div className="bannerbox"> */}
        {/* <img
              src={
                bannerImageUrl?.includes("base64")
                  ? bannerImageUrl
                  : `${baseUrl}uploads/${bannerImageUrl}`
              }
              className="mx-auto d-block img-fluid"
            ></img> */}
        <p>
          {/* <strong>Size (200 x 200)</strong> */}
        </p>
        {/* </div> */}

        {/* </div> */}
      </div>
      {/* {existingGalleryImages &&
        existingGalleryImages.map((val, index) => (
          <div key={`form`} className="col-12 col-lg-6">
            <div className="servicebox tile">
              <i
                className="fa-solid fa-circle-xmark"
                onClick={() => removeExisting(index)}
              >
                {" "}
              </i>

              <div className="boxbanner">
                <label className="form-label">Banner:</label>
                <div className="bannerbox">
                  <img
                    src={`${environmentVariables?.apiUrl}uploads/${val}`}
                    className="mx-auto d-block img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        ))} */}
      {galleryData &&
        galleryData.map((val, index) => (
          <div key={`form`} className="col-12 col-lg-2">
            <div className="serviceboxedit">
              <i
                className="fa-solid fa-circle-xmark crossedit"
                onClick={() => removeForm(val?.id)}
              >
                {" "}
              </i>

              {/* <div className="bannerboxedit"> */}
              {/* <label className="form-label">Banner:</label> */}
              <div className="bannerboxedit gallarysizepad">
                <img
                  src={`${environmentVariables?.s3UrlBackendUrl}gallery/${val?.image}`}
                  className="mx-auto d-block img-fluid"
                />
              </div>
              {/* </div> */}
            </div>
          </div>
        ))}
      {imageLoading ? <div className="skeleton-placeholder"></div> : <></>}
    </>
  );
}

export default NewGallerycreate;

// <div className="mb-3">
// <label className="form-label">Choose Video</label>
// <input
//   className="form-control"
//   type="file"
//   // value={videoData}
//   name="gallary_video" // Set the name to match the Multer field name
//   onChange={handleVideoChange}
// />
// {/* {JSON.stringify(videoData) && JSON.stringify(videoData).length && <p>Selected Video: {JSON.stringify(videoData)}</p>} */}
// {/* {JSON.stringify(videoData)?JSON.stringify(videoData):""} */}
// {JSON.stringify(videoData)?.length > 2 && JSON.stringify(videoData)}
// </div>

// <div class="mb-3">
//   <label class="form-label"> Video Link</label>
//   <input
//     class="form-control"
//     type="text"
//     placeholder="Vedio Link"
//     value={videoLink == "undefined" ? "" : videoLink}
//     onChange={(e) => setVideoLink(e.target.value)}
//   />
// </div>
