import React, { useContext, useState, useRef } from "react";
import { userContext } from "../../../../context/userContext";
import { environmentVariables } from "../../../../config/env.config";
import { useEffect } from "react";
import "./Theme20.css";
import Banner from "./../../../../Images/minimarketbg.png";
import LocationIcon from "./../../../../Images/locIcon.png";
import HomeComponent from "./HomeComponent";
import ContactInfo from "./ContactInfo";
import styled from "styled-components";
import { getContactInfo } from "../../../../utils/utility.js";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Theme20 = (props) => {
  const { t } = useTranslation();
  const { userData } = useContext(userContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const calendarInputRef = useRef(null);
  const [activeTab, setActiveTab] = useState("home");

  useEffect(() => {
    if (props?.onState?.miniproduct == 1) {
      setActiveTab("home");
    } else {
      setActiveTab("aboutus");
    }
  }, [props?.onState?.miniproduct]);

  const renderContent = () => {
    switch (activeTab) {
      case "home":
        return <div> <HomeComponent productdata={props} colordata={props} /> </div>;
      case "aboutus":
        return <div style={{ color: props?.dynamicTextHeadingColor ? `${props?.dynamicTextDescriptionColor}` : "" }} className="flex text-justify font-Tajawal text-[#101010] font-base text-lg"
          dangerouslySetInnerHTML={{
            __html: (props.description || `Our mini marketplace offers a diverse range of products at competitive prices. From groceries to daily essentials, we provide a convenient shopping experience with quality goods for our community.`).replace(/(?:\r\n|\r|\n)/g, '<br>'),
          }}>

        </div>;
      case "contactinfo":
        return <div><ContactInfo contdata={props} colordata={props} /></div>;
      default:
        return null;
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    getBlockOrder();
  }, [props?.businessObj, props.businessId, props.orderOfBlocks]);
  const getBrandNameForSocial = (brand) => {
    if (brand == "gmail") {
      return "google";
    } else return `${brand}`;
  };
  const getBlockOrder = async () => {
    // const handleGetBlock = async () => {
    if (props.orderOfBlocks) {
      let orders = {};
      props.orderOfBlocks.map((val) => {
        if (val.content == "Description") {
          orders["description"] = val?.position;
        } else if (val.content == "Contact Info") {
          orders["contact_info"] = val?.position;
        } else if (val.content == "Appointment") {
          orders["appointment"] = val?.position;
        } else if (val.content == "Service") {
          orders["service"] = val?.position;
        } else if (val.content == "Gallery") {
          orders["gallery"] = val.position;
        } else if (val.content == "Contact Form") {
          orders["contact_form"] = val.position;
        } else if (val.content == "Testimonials") {
          orders["testimonials"] = val.position;
        } else if (val.content == "Social") {
          orders["social"] = val.position;
        } else if (val.content == "Business Hour") {
          orders["business_hour"] = val.position;
        }
      });
      props.setOrder(orders);
    } else {
      try {
        let id = props?.businessObj?.id || props.businessId;
        if (props?.businessObj?.id) {
          let { data: get } = await axios.get(
            `${environmentVariables?.apiUrl}api/v1/business/FetchBlock?business_id=${id}`,
            {
              headers: {
                _token: userData?.token,
              },
              // withCredentials: true,
            }
          );
          // console.log(get?.data?.order, "order data");
          props.setOrder(get?.data?.order);
        }

        // console.log(get.data.order,"Getetgwef")
      } catch (err) {
        console.log(err, "error in template");
      }
    }
    // };
  };


  const getCorrectDay = (day) => {
    const dayMappings = {
      "WednesDay": "Wednesday",
      "thrusdayDay": "Thursday",
      "fridayDay": "Friday",
      "saturdayDay": "Saturday",
      "sundayDay": "Sunday"
    };

    return dayMappings[day] || day;
  };

  const getOpeningHours = (businessHourObject) => {
    let daysOfWeek = ["Monday", "Tuesday", "WednesDay", "thrusdayDay", "fridayDay", "saturdayDay", "sundayDay"];
    let startDay = null;
    let endDay = null;
    let closedDays = [];

    // Find the first and last true values in businessHourObject
    for (let day of daysOfWeek) {
      if (businessHourObject[`is${day}`]) {
        if (!startDay) startDay = day;
        endDay = day;
      } else {
        closedDays.push(day);
      }
    }

    // Replace misspelled days with correct spellings
    startDay = getCorrectDay(startDay);
    endDay = getCorrectDay(endDay);

    // Construct opening hours string
    let openingHours = "";
    if (startDay && endDay) {
      openingHours = startDay === endDay ? startDay : `${startDay} - ${endDay}`;
    } else if (startDay) {
      openingHours = startDay;
    }

    let closedDaysString = "";
    if (closedDays.length > 0) {
      closedDaysString = closedDays.map(day => getCorrectDay(day)).join(", ");
    }

    return { openingHours, closedDays: closedDaysString };
  };

  const { openingHours, closedDays } = getOpeningHours(props.businessHourObject);

  return (
    <div>
     <style>
    {`
      #temp20 .miniMenuActive {
        color: ${props?.dynamicCtaColor ||'#1B776F'};
        border-bottom: 2px solid ${props?.dynamicCtaColor || '#1B776F'} !important;
        margin-bottom: -2px;
        font-weight:600
      }

      #temp20 .miniMenuInactive {
        color: ${props?.dynamicTextHeadingColor  };
        margin-bottom: -2px;

      }

      #temp20 .miniMenuInactive:hover {
        color: ${props?.dynamicCtaColor || '#1B776F'};
        border-bottom: 2px solid ${props?.dynamicCtaColor} !important;
      }
    `}
  </style>


      <div class="main-temaplt" id="temp20">
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ width: "420px", maxHeight: "none", background: props?.dynamicMainBackgroundColor }} className="col-12 wtemplate p-4" >

              <div>
                <div className="minimktheader">
                  {props?.isShowAddress ? (
                    <div className="flex justify-start w-5" style={{ alignItems: "flex-start", color: `${props?.dynamicTextHeadingColor}B3` }}>

                      <i class="fa-solid fa-location-dot" style={{ fontSize: "18px", color: `${props?.dynamicTextHeadingColor}` || "#212429" }}></i>
                      <div>
                        <address
                          className={`flex justify-start items-start pr-2 mb-0 mx-2 text-sm font-normal  inline minimarketaddres`}
                          style={{color: `${props.dynamicTextHeadingColor || '#212429'}`}}
                        >
                          {getContactInfo(props?.contactInfo)}
                        </address>

                      </div>
                    </div>
                  ) : null}

                </div>

               

                <div className="relative">
                  <div
                    className="absolute top-0 left-0 w-full h-full bg-black opacity-30 rounded-xl"
                    style={{ zIndex: 1, height: "100%", minHeight: "145px", width: "100%" }}
                  />
                  <img loading="lazy" src={
                    props?.bannerImageUrl
                      ? props.getImage(props?.bannerImageUrl)
                      : environmentVariables?.s3BannerUrlTheme20
                  } className="relative w-hundred object-cover rounded-xl" style={{ maxHeight: "160px", minHeight: "160px" }} />
                  <div  style={{ position: "absolute", top: "50%", left: "50%", zIndex: 2, transform:"translate(-50%,-50%)", width:"90%", textAlign:"center" }}>
                    {
                      props?.isShowBusinessName ? (
                        <div
                          className={`font-bold text-4xl font-Tajawal break-all minimarketheading ${props?.dynamicTextHeadingColor ? '' : 'text-white'
                            }`}
                          style={props?.dynamicTextHeadingColor ? { color: props.dynamicTextHeadingColor } : {}}
                        >
                          {props.title}
                        </div>
                      ) : null
                    }

                  </div>
                </div>
                <div className="p-0  mx-0 mb-3 flex justify-between items-center " style={{ color: `${props?.dynamicTextHeadingColor || '#CCCCCC'}` }}>
                  {props?.onState?.miniproduct && (
                    <div>
                      <div
                        style={{ width: "65px"}} className={` mt-3 pb-1 px-2  text-lg cursor-pointer text-center hover:font-bold  hover:transition all ${activeTab === "home"
                          ? `miniMenuActive`
                          : 'miniMenuInactive'
                          }`}
                        onClick={() => setActiveTab("home")}
                      >
                        {t("Home")}
                      </div>
                    </div>
                  )}
                  <div>
                    <div
                      style={{ width: "90px"}} className={`mt-3 pb-1  text-lg cursor-pointer text-center hover:font-bold hover:text-coffee hover:transition all ${activeTab === "aboutus"
                        ? `miniMenuActive`
                          : 'miniMenuInactive'
                        }`}
                      onClick={() => setActiveTab("aboutus")}
                    >
                      {props.descriptionHeading ? props.descriptionHeading :t("About_us")}
                    </div>
                  </div>
                  {(props?.onState?.contactInfo || props?.onState?.socials) && (
                    <div>
                      <div
                        style={{ width: "110px"}} className={`mt-3 pb-1 text-lg text-center cursor-pointer hover:font-bold hover:text-coffee hover:transition all ${activeTab === "contactinfo"
                          ? `miniMenuActive`
                          : 'miniMenuInactive'
                          }`}
                          onClick={() => setActiveTab("contactinfo")}
                      >
                        {props?.contactHeading || t("Contact_info")}
                      </div>
                    </div>
                  )}
                </div>


                <div className="px-2 py-2">{renderContent()}</div>

              </div>
              {/* <div className=' bg-white px-2 py-4 sticky spacing -bottom-25 w-full text-center'>
                <button className='bg-coffee text-xl rounded text-white w-full font-bold font-Tajawal pt-3  p-2'>
                  Contact supplier
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theme20;
