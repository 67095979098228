import React, { useContext, useEffect, useRef, useState } from "react";
import PageName from "../Utils/PageName";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import { toast } from "react-toastify";
import ViewCard from "./Physical Card/ViewCard";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router";
import "./UpdateRequestcardstyle.css";
import { red } from "@mui/material/colors";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import FilterIcon from "../../../src/Images/FilterIcon.png";
import { useTranslation } from "react-i18next";

function RequestCard() {
  const {t} = useTranslation()
  const [data, setData] = useState([]);
  const [deliveryInstructions, setDeliveryInstructions] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [physicalCardId, setPhysicalCardId] = useState(null);
  const [physicalCardData, setPhysicalCardData] = useState(null);
  const [physicalCardLoading, setPhysicalCardLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData } = useContext(userContext);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [businessData, setBusinessData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [activeBusinessName, setActiveBusinessName] = useState("All Business");
  const toDate = useRef(null);
  const fromDate = useRef(null);
  const tableExtensions = { export: false, print: false };
  const navigate=useNavigate()
  const Boxstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    // height: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    zIndex: 999,
    borderRadius: "15px",
    border: "none",
    outline: "none",
    focus: "none",
    boxShadow:
      " rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;",
    padding: "3rem 5rem",
    "@media (max-width: 650px)": {
      width: "425px",
      padding: "2rem",
    },
    "@media (max-width: 430px)": {
      width: "380px",
      padding: "2rem",
    },
    "@media (max-width: 390px)": {
      width: "350px",
      padding: "2rem",
    },
  };

  const customStyles = {
    dataTableExtensions: {
      backgroundColor: "lightgray",
      padding: "10px",
    },
    headRow: {
      style: {
        backgroundColor: "#e9e9e9",
        borderRadius: "2px 2px 0 0",
        paddingLeft: "25px",
        border: "none",
        textTransform: "capitalize",
        position: "sticky",
        left: 0,
        top: 0,
        zIndex: 1,
        boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "55px",
      },
    },
    rows: {
      style: {
        padding: "6px 0px 6px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "58px !important",
      },
    },
    businessColumn: {
      position: "sticky",
      left: "0",
      zIndex: "1",
      backgroundColor: "#fff",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  };

  const openEditModal = (rowData) => {
    setOrderId(rowData?.order_id);
    setSelectedOrder(rowData);
    setDeliveryInstructions(rowData?.delivery_instructions);
  };

  const handleSubmitEdit = (e) => {
    e.preventDefault();
    const updatedOrders = data.map((order) =>
      order.order_id === orderId
        ? { ...order, delivery_instructions: deliveryInstructions }
        : order
    );
    setData(updatedOrders);

    axios
      .put(
        `${environmentVariables?.apiUrl}api/v1/phy_card/update_delivery_instructions/${orderId}`,
        {
          delivery_instructions: deliveryInstructions,
        },
        {
          headers: {
            _token: userData?.token,
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {
        toast.success(t("Delivery_status_updated"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });

        if (typeof bootstrap !== "undefined" && bootstrap.Modal) {
          const modalElement = document.getElementById("planselect");
          const modal = new bootstrap.Modal(modalElement);
          modal.hide();
        }

        getPhysicalCardOrders();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        getPhysicalCardOrders();
      });
  };

  const getPhysicalCardOrders = () => {
    axios
      .get(`${baseUrl}api/v1/phy_card/getphysicalcardorders`, {
        headers: {
          _token: userData?.token,
        },
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response?.data?.data, "(response?.data?.data");
        if (response?.data?.data?.length > 0) {
          let newArr = [];
          response?.data?.data?.forEach((val) => {
            let obj = new Object(val);
            obj.created_at = val?.created_at?.split("T")[0];
            newArr.push(obj);
          });
          let tempArr = response?.data?.data?.map((el) => el?.businessTitle);
          let tempUniqueArr = new Set(tempArr);
          tempUniqueArr = [...tempUniqueArr];
          tempUniqueArr = tempUniqueArr;
          setBusinessData(tempUniqueArr);
          setTempData(response?.data?.data);

          setData(newArr);
        } else {
          setData(response?.data?.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  const checkOrderPaymentStatus=()=>{
    
  }
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const orderId = params.get("orderid");
    const status = params.get("status");
    if(status=="failed"){
      toast.error(t("Payment_failed"))
      navigate("/requestCard");
      
    }else if(status=="success"){

      checkOrderPaymentStatus(orderId)
    }
    getPhysicalCardOrders();
  }, []);

  const handleModalClose = () => {
    setDeliveryInstructions(null);
  };

  const columns = [
    {
      name: t("Business"),
      selector: (row) => row?.print_business_name,
      sortable: true,
      fixed: "left",
      // style: customStyles.businessColumn,
    },
    {
      name: t("Order_id"),
      selector: (row) => row?.order_id,
      sortable: true,
    },
    {
      name: t("Tx_id"),
      selector: (row) => (row?.txn_id != 0 ? row?.txn_id : "--"),
      sortable: true,
    },
    {
      name: t("Order_date"),
      selector: (row) => row?.created_at,
      sortable: true,
    },
    {
      name: t("Card_type"),
      selector: (row) => row?.card_type,
      sortable: true,
    },

    {
      name: t("Delivery_instructions"),
      selector: (row) =>
        row?.delivery_instructions != 0 ? row?.delivery_instructions : "--",
      sortable: true,
    },
    {
      name: t("Delivery_date"),
      selector: (row) => row?.delivery_date,
      sortable: true,
    },

    {
      name: t("Order_status"),
      selector: (row) => {
        if (row?.status === 4) {
          return t("Canceled");
        } else if (row?.status === 5) {
          return t("Completed");
        } else if (row?.status === 3) {
          return t("Dispatched");
        } else if (row?.status === 2) {
          return t("Printed");
        } else {
          return t("Accepted");
        }
      },
      sortable: true,
    },
    {
      name: t("Payment_status"),
      selector: (row) => {
       return row?.payment_status
      },
      sortable: true,
    },
    {
      name: t("Action"),
      sortable: false,
      selector: "null",
      cell: (row) => (
        <div
          data-bs-toggle="modal"
          data-bs-target="#planselect"
          onClick={() => openEditModal(row)}
        >
          <i className="first fas fa-pen"></i>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    data,
  };

  const handleChangeBusinessData = (e) => {
    setActiveBusinessName(e.target.value);
  };
  const [selectedDateFrom, setSelectedDateFrom] = useState("");
  const [selectedDateTo, setSelectedDateTo] = useState("");
  const [cardType, setCardType] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  const handleFilterOpen = () => {
    setShowFilter(!showFilter);
  };

  const handleFilterClose = () => {
    setShowFilter(false);
  };

  useEffect(() => {
    // console.log(cardType, "cardTypecardType");
  }, [cardType]);
  useEffect(() => {
    if (selectedDateTo != "" && selectedDateFrom != "") {
      if (selectedDateTo < selectedDateFrom) {
        toast.error(t("Invalid_date_select"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        return;
      }
    }
    let filteredData = tempData;
    if (
      activeBusinessName === "All Business" &&
      !selectedDateFrom &&
      !selectedDateTo
    ) {
      setData(tempData);
    } else {
      if (activeBusinessName !== "All Business") {
        filteredData = filteredData?.filter(
          (el) => el.businessTitle === activeBusinessName
        );
      }
      if (selectedDateFrom && selectedDateTo) {
        filteredData = filteredData?.filter((el) => {
          const date = new Date(el.created_at);
          return date >= selectedDateFrom && date <= selectedDateTo;
        });
      }
    }
    if (cardType != "") {
      filteredData = filteredData.filter((el) => el.card_type == cardType);
    }
    setData(filteredData);
  }, [activeBusinessName, selectedDateFrom, selectedDateTo, cardType]);

  const handleReset = () => {
    setActiveBusinessName("All Business");
    setSelectedDateFrom("");
    setSelectedDateTo("");
    setCardType("");
    setData([...tempData]);
  };

   const paginationOptions = {
    rowsPerPageText: t("Rows_per_page"),
    rangeSeparatorText: t("Of"),
    selectAllRowsItem: true,
    selectAllRowsItemText: t("All"),
  };
  
  return (
    <>
      <PageName PageName={t("Physical_card_order")} />

      <article>
        <div class="row">
          <div class="col-md-12">
            <div className="main">
              {isLoading ? (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "55%",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <CircularLoader size={30} />
                </div>
              ) : (
                <>
                  <div className="filter-button-wrapper requestcardfilterbuttonWrapper">
                    <span
                      class="FilterIconTextWrapper"
                      onClick={handleFilterOpen}
                    >
                      {" "}
                      <div className="FilterIcon">
                    {" "}
                    <img loading="lazy" src={FilterIcon} width={28}/> 
                    <div className="filtertext">{t("Filters")}</div>
                  </div>
                      {/* <i class="fa fa-filter" aria-hidden="true"></i> */}
                    </span>
                    <button
                      className="btn btn-primary requestcardfilterbuttonstyle"
                      onClick={handleFilterOpen}
                      id="FilterIconText"
                    >
                      {t("Filter")}
                    </button>
                  </div>
                  <Modal
                    open={showFilter}
                    onClose={handleFilterClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={Boxstyle}>
                      {showFilter && (
                        <div class="Contact_filter_fieldbutton_wrapper ModalPopupContainer">
                          <div class="Contact_filter_field_wrapper ModalPopupWrapper">
                            <div
                              class="col-md-2 Contact_filter_Inputfield"
                              id="ModalSelectfield"
                            >
                              <select
                                value={activeBusinessName}
                                onChange={(e) => handleChangeBusinessData(e)}
                                class="form-select"
                              >
                                {" "}
                                <option value={"All Business"}>
                                  All Business
                                </option>
                                {businessData &&
                                  businessData.map((val, index) => (
                                    <option key={index} value={val}>
                                      {val}{" "}
                                    </option>
                                  ))}
                              </select>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "red",
                                  bottom: "70px",
                                }}
                              ></div>
                            </div>

                            <div
                              class="col-md-2 Contact_filter_Inputfield "
                              id="ModalSelectfield"
                            >
                              <select
                                value={cardType}
                                onChange={(e) => setCardType(e.target.value)}
                                class="form-select"
                              >
                                <option value="">{t("Select_card_type")}</option>
                                <option value="pvc">{t("PVC_card")}</option>
                                <option value="metal">{t("Metal_card")}</option>
                              </select>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "red",
                                  bottom: "70px",
                                }}
                              ></div>
                            </div>

                            <div
                              class="col-md-2 Contact_filter_Inputfield "
                              id="ModalSelectfield"
                            >
                              {/* <h5 className="mb-3">From Date </h5> */}
                              <div className="dateiconmain">
                                <DatePicker
                                  selected={selectedDateFrom}
                                  ref={fromDate}
                                  placeholderText={t("From_date")}
                                  onChange={(date) => setSelectedDateFrom(date)}
                                  className="form-control"
                                  popperPlacement="bottom-end"
                                />
                                <div
                                  class="contact_filter_toDate_calander_icon"
                                  onClick={() => fromDate.current.setOpen(true)}
                                >
                                  <i class="fa-regular fa-calendar"></i>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-md-2 Contact_filter_Inputfield ModalSelectfield"
                              id="ModalSelectfield"
                            >
                              <div className="dateiconmain">
                                <DatePicker
                                  placeholderText={t("To_date")}
                                  selected={selectedDateTo}
                                  ref={toDate}
                                  onChange={(date) => setSelectedDateTo(date)}
                                  className="form-control"
                                  popperPlacement="bottom-end"
                                />

                                <div
                                  class="contact_filter_toDate_calander_icon"
                                  onClick={() => toDate.current.setOpen(true)}
                                >
                                  <i class="fa-regular fa-calendar"></i>
                                </div>
                              </div>{" "}
                            </div>

                            <div class="contact_filter_buttonwrapper">
                              <button
                                class="contact_filter_buttonstyle"
                                onClick={handleFilterClose}
                              >
                                {t("Apply")}
                              </button>
                              <button
                                class="contact_filter_buttonstyle"
                                onClick={() => handleReset()}
                              >
                                {t("Reset")}{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </Box>
                  </Modal>

                  {/* <DataTableExtensions
                    {...tableData}
                    {...tableExtensions}
                    customStyles={customStyles}
                    filterPlaceholder="Search"
                  >
                    <DataTable
                      columns={columns}
                      data={data}
                      noHeader
                      defaultSortField="id"
                      sortIcon={<SortIcon />}
                      defaultSortAsc={true}
                      pagination
                      highlightOnHover
                      dense
                      customStyles={customStyles}
                    />
                  </DataTableExtensions> */}

                  <div className="scrollable-columns">
                    <div className="sticky-header-container">
                      <DataTableExtensions
                        {...tableData}
                        {...tableExtensions}
                        customStyles={customStyles}
                        filterPlaceholder="Search"
                      >
                        <DataTable
                          columns={columns}
                          data={data}
                          noHeader
                          defaultSortField="id"
                          sortIcon={<SortIcon />}
                          defaultSortAsc={true}
                          pagination
                          highlightOnHover
                          dense
                           paginationComponentOptions={{
                        ...paginationOptions,
                      }}

                          customStyles={customStyles}
                          noDataComponent={<div style={{padding:"24px"}}>{t("No_records_found")}</div>}
                        />
                      </DataTableExtensions>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            class="modal fade"
            id="planselect"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog  UpdatedeliveryInstructionmarginissue">
              <div class="modal-content requestcard_updateaction_form">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {t("Update_delivery_instructions")}
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleModalClose}
                  ></button>
                </div>
                <div class="modal-body UpdatedeliveryInstructionform">
                  <form>
                    <div className="row g-3 mb-3">
                      <div className="col">
                        <textarea
                          className="form-control"
                          placeholder={t("Enter_delivery_instructions")}
                          aria-label="Name"
                          value={deliveryInstructions}
                          onChange={(e) =>
                            setDeliveryInstructions(e.target.value)
                          }
                        ></textarea>
                      </div>
                    </div>
                    <div className="flex-center ">
                      <button
                        onClick={(e) => handleSubmitEdit(e)}
                        type="click"
                        className="btn btn-primary mx-4 save-cta"
                        data-bs-dismiss="modal"
                      >
                        {t("Save_changes")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal fade"
            id="physicalcard"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content ">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Physical Card
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  {physicalCardLoading ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "55%",
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <CircularLoader size={40} />
                    </div>
                  ) : (
                    <ViewCard
                      card_id={physicalCardId}
                      activeCardData={physicalCardData}
                      name={userData?.name}
                      degination="Developer"
                      logo="https://vmycards.com/storage/card_logo/logo_16969236651843057845.png"
                    />
                  )}
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default RequestCard;
