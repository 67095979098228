const envvars = process.env;

export const environmentVariables = {
  apiUrl: envvars.REACT_APP_API_BACKEND,
  frntUrl:envvars.REACT_APP_FRNT_URL,
  crmDubaiApi:envvars.REACT_APP_API_CRM_DUBAI,
  homeLoc:envvars.REACT_APP_HOME_URL,
  httpProtocol:envvars.REACT_APP_HTTP_PROTOCOL,
  s3Url:envvars.REACT_APP_S3_URL_STATIC_IMAGES,
  s3UrlBackendUrl:envvars.REACT_APP_S3_URL_BACKEND_IMAGES,
  s3UrllogoUrl:envvars.REACT_APP_S3_URL_LOGO_IMAGES,
  calendarIcondays:envvars.REACT_APP_S3_URL_CALENDERICONDAYS,
  calendarIcondaysnew:envvars.REACT_APP_S3_URL_CALENDERICONDAYS_NEW,

  s3UrllogoUrlTheme4:envvars.REACT_APP_S3_URL_LOGO_IMAGES_THEME4,
  s3UrllogoUrlTheme7:envvars.REACT_APP_S3_URL_LOGO_IMAGES_THEME7,
  s3UrllogoUrlTheme8:envvars.REACT_APP_S3_URL_LOGO_IMAGES_THEME8,
  s3UrllogoUrlTheme9:envvars.REACT_APP_S3_URL_LOGO_IMAGES_THEME9,
  s3UrllogoUrlTheme11:envvars.REACT_APP_S3_URL_LOGO_IMAGES_THEME11,
  s3UrllogoUrlTheme15:envvars.REACT_APP_S3_URL_LOGO_IMAGES_THEME15,
  s3UrllogoUrlTheme17:envvars.REACT_APP_S3_URL_LOGO_IMAGES_THEME17,
  s3UrllogoUrlTheme27:envvars.REACT_APP_S3_URL_LOGO_IMAGES_THEME27,
  s3UrllogoUrlTheme28:envvars.REACT_APP_S3_URL_LOGO_IMAGES_THEME28,
  s3UrllogoUrlTheme29:envvars.REACT_APP_S3_URL_LOGO_IMAGES_THEME29,
  s3UrllogoUrlTheme30:envvars.REACT_APP_S3_URL_LOGO_IMAGES_THEME30,
  s3UrllogoUrlTheme32:envvars.REACT_APP_S3_URL_LOGO_IMAGES_THEME32,
  s3UrllogoUrlTheme33:envvars.REACT_APP_S3_URL_LOGO_IMAGES_THEME33,
  s3UrllogoUrlTheme34:envvars.REACT_APP_S3_URL_LOGO_IMAGES_THEME34,
  s3UrllogoUrlTheme37:envvars.REACT_APP_S3_URL_LOGO_IMAGES_THEME37,
  s3UrllogoUrlTheme38:envvars.REACT_APP_S3_URL_LOGO_IMAGES_THEME38,


  s3BannerUrlTheme1:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME1,
  s3BannerUrlTheme3:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME3,
  s3BannerUrlTheme7:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME7,
  s3BannerUrlTheme8:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME8,
  s3BannerUrlTheme9:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME9,
  s3BannerUrlTheme10:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME10,
  s3BannerUrlTheme11:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME11,
  s3BannerUrlTheme14:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME14,
  s3BannerUrlTheme15:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME15,
  s3BannerUrlTheme16:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME16,
  s3BannerUrlTheme18:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME18,
  s3BannerUrlTheme20:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME20,
  s3BannerUrlTheme22:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME22,
  s3BannerUrlTheme27:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME27,
  s3BannerUrlTheme28:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME28,
  s3BannerUrlTheme29:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME29,
  s3BannerUrlTheme32:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME32,
  s3BannerUrlTheme33:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME33,
  s3BannerUrlTheme34:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME34,
  s3BannerUrlTheme35:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME35,
  s3BannerUrlTheme36:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME36,
  s3BannerUrlTheme38:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME38,
  s3BannerUrlTheme40:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME40,
  s3BannerUrlTheme43:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME43,
  s3BannerUrlTheme48:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME48,
  s3BannerUrlTheme53:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME53,

  s3NewStar1:envvars.REACT_APP_NEWSTAR1,
  s3Star1:envvars.REACT_APP_STAR1,
  s3Star2:envvars.REACT_APP_STAR2,
  s3Star3:envvars.REACT_APP_STAR3,


  s3TestimoinalDefaultImage:envvars.REACT_APP_S3_TESTIMONIAL_DEFAULT_IMAGE,
  s3TestimoinalAvatarImage:envvars.REACT_APP_S3_TESTIMONIAL_AVATAR_IMAGE,

  s3BannerUrlThemeExt1:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME_EXT1,
  s3BannerUrlThemeExt16:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME_EXT16,
  s3BannerUrlThemeExt55:envvars.REACT_APP_S3_URL_BANNER_IMAGES_THEME_EXT55,


  s3UrllogoUrlThemeExt16:envvars.REACT_APP_S3_URL_LOGO_IMAGES_THEME_EXT16,
  s3UrllogoUrlThemeExt22:envvars.REACT_APP_S3_URL_LOGO_IMAGES_THEME_EXT22,


  s3UrlDarkCalenderIconDays:envvars.REACT_APP_S3_DARK_CALENDER_ICON_DAYS,
  s3UrlTheme18CalenderIconDays:envvars.REACT_APP_S3_DARK_THEME18_CALENDER_ICON_DAYS,
  s3UrlTheme27CalenderIconDays:envvars.REACT_APP_S3_DARK_THEME27_CALENDER_ICON_DAYS,
  s3UrlTheme36CalenderIconDays:envvars.REACT_APP_S3_DARK_THEME36_CALENDER_ICON_DAYS,
};
