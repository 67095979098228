import React, { useContext, useEffect, useRef, useState } from "react";
import PageName from "../Utils/PageName";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { environmentVariables } from "../../config/env.config";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import styled from "styled-components";
import "./UpdateRequestcardstyle.css";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import FilterIcon from "../../../src/Images/FilterIcon.png";
import NewFilterIcon from "../../../src/Images/newFilter.svg";
import DownloadExcel from "../../../src/Images/downloadExcel.svg";
import { useCallback, useMemo } from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { v4 as uuidv4 } from "uuid";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import parsePhoneNumberFromString from "libphonenumber-js";
import CreateUser from "./CreateUser";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {useTranslation} from "react-i18next";

import "react-datepicker/dist/react-datepicker.css";
import { GetFormattedDate } from "../Utils/GetFormattedDate";
const AddUserText = styled.div`
  @media (max-width: 420px) {
    display: none !important;
  }
`;

const Boxstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  // height: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  zIndex: 999,
  borderRadius: "15px",
  border: "none",
  outline: "none",
  focus: "none",
  boxShadow:
    " rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;",
  padding: "3rem 5rem",
  "@media (max-width: 650px)": {
    width: "425px",
    padding: "2rem",
  },
  "@media (max-width: 430px)": {
    width: "380px",
    padding: "2rem",
  },
  "@media (max-width: 390px)": {
    width: "350px",
    padding: "2rem",
  },
};

const customStyles = {
  dataTableExtensions: {
    backgroundColor: "lightgray",
    padding: "10px",
  },
  headRow: {
    style: {
      backgroundColor: "#F8FBFF",
      borderRadius: "2px 2px 0 0",
      paddingLeft: "25px",

      textTransform: "capitalize",
      position: "sticky",
      left: 0,
      top: 0,
      boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.1)",
      height: "70px",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
  headCells: {
    style: {
      color: "#525252",
      minHeight: "64px",
      fontFamily: "DM Sans",
      fontSize: "16px",
      fontWeight: "600",
    },
  },
  rows: {
    style: {
      padding: "6px 0px 6px 25px",
      fontSize: "14px",
      minHeight: "70px !important",
      fontFamily: "DM Sans",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      color: "#000",
    },
  },
  UserNameColumn: {
    position: "sticky",
    left: "0",
    // zIndex: "1",
    backgroundColor: "#fff",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
};

function User() {
  const navigate = useNavigate();
  const { userData } = useContext(userContext);
  const { t } = useTranslation();
  const createUserRef = useRef(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isvarify, setIsVarify] = useState(false);
  const [campaign_name, setCampaignName] = useState("");
  const [campaignCount, setCampaignCount] = useState(0);
  const [plan_expire_date, setplanExpireDate] = useState("");
  const baseUrl = environmentVariables?.apiUrl;
  const fronturl = environmentVariables?.frntUrl;
  const [action] = useState({ fromUser: false });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paginationTotalRows, setPaginationTotalRows] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState([]);
  const showLastLoginDateColumn = userData.user_type == "super_admin";
  const [customDateRange, setCustomDateRange] = useState([null, null]);
  const [startDate, endDate] = customDateRange;
  const [startDateQuery, setStartDateQuery] = useState("");
  const [endDateQuery, setEndDateQuery] = useState("");
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [userCreateData, setUserCreateData] = useState({
    name: "",
    email: "",
    password: "",
    contact: "",
    type: "",
    company_name: "",
    company_employee: "",
    department: "",
    roleId: 0,
    agent_code: "",
  });
  const [roleData, setRoleData] = useState(null);
  const [usersData, setUsersData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchOption, setSearchOption] = useState("-1");
  const [usersDataSearch, setUsersDataSearch] = useState(null);
  const [usersDataApi, setUsersDataApi] = useState(null);
  const [userId, setUserId] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [errorMsgName, setErrorMsgName] = useState(null);
  const [errorMsgEmail, setErrorMsgEmail] = useState(null);
  const [errorMsgPassword, setErrorMsgPassword] = useState(null);
  const [errorMsgRole, setErrorMsgRole] = useState(null);
  const [userListData, setUserListData] = useState([]);
  const [isLoader, setIsloader] = useState(true);
  const [sortOrder, setSortOrder] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [agentData, setAgentData] = useState([]);
  const [activeAgent, setActiveAgent] = useState(null);

  const [showCreateModal, setShowCreateModal] = useState(false);

  const tableExtensions = {
    export: false,
    print: false,
  };
  // const handleChangeUserData = (e, type) => {
  //   setErrorMsgName("");
  //   setUserCreateData({ ...userCreateData, [`${type}`]: e.target.value });
  // };
  const handleChangeUserData = (e, type) => {
    setErrorMsgName("");
    if (type === "contact") {
      setUserCreateData({ ...userCreateData, [type]: e });
    } else {
      setUserCreateData({ ...userCreateData, [type]: e.target.value });
    }
  };
  const handleCustomDateChange = (dates) => {
    setCustomDateRange(dates);
  };
  const [exportstart_date,setexportstart_date]=useState('');
  const [exportend_date,setexportend_date]=useState('');

  const handleSubmit = (type) => {
    let start_date = "";
    let end_date = "";
    const today = new Date();

    switch (type) {
      case "custom":
        if (startDate && endDate) {
          setStartDateQuery(startDate.toLocaleDateString("en-CA"));
          setEndDateQuery(endDate.toLocaleDateString("en-CA"));
          start_date = startDate.toLocaleDateString("en-CA");
          end_date = endDate.toLocaleDateString("en-CA");
          setexportstart_date(start_date);
          setexportend_date(end_date);
        } else {
          alert("Please select both start and end dates for the custom filter.");
          return;
        }
        break;

      case "reset": {
        start_date = "";
        end_date = "";
        setStartDateQuery("");
        setEndDateQuery("");
        break;
      }

      default:
        alert("Please select a valid filter type.");
        return;
    }

    getUsersData(1, start_date, end_date);
  };

  const [role, setRole] = useState([]);

  const handleEditUser = (id) => {
    if (usersData?.length > 0) {
      const editUserData = usersData.filter((val) => val?.id == id);
      setUserId(id);

      const userType =
        editUserData[0]?.type?.toLowerCase() === "individual"
          ? "individual"
          : "company";
      setUserCreateData({
        name: editUserData[0]?.name,
        email: editUserData[0]?.email,
        password: "",
        contact: `${editUserData[0]?.country_code || "+91"} ${editUserData[0]?.contact || ""
          }`,
        type: userType,
        company_name: editUserData[0]?.company_name,
        company_employee: editUserData[0]?.company_employee,
        department: editUserData[0]?.department,
        agent_code: editUserData[0]?.agent_code || "",
      });
      setShowCreateModal(false);
    } else {
      alert("Business Owners not available");
    }
  };

  const handleDeleteUser = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let data = JSON.stringify({
          userId: id,
        });

        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `${environmentVariables?.apiUrl}api/v1/user/deleteUser`,
          headers: {
            _token: userData?.token,
            "Content-Type": "application/json",
          },
          // withCredentials: true,
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            Swal.fire("Deleted!", "Selected user has been deleted.", "success");
            getUsersData();
          })
          .catch((error) => {
            Swal.fire(
              "Error!",
              error?.response?.data?.message || error?.message,
              "error"
            );
          });
      }
    });
  };

  const isValidName = (name) => {
    const nameRegex = /^[A-Za-z -]{3,}$/;
    return nameRegex.test(name);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidContact = (contact, country) => {
    // const digits = contact.replace(/^\+\d+\s/, "").replace(/\D/g, "");
    // return digits.length >= 8 && digits.length <= 12;
    const phoneNumber = parsePhoneNumberFromString(
      contact,
      country?.iso2?.toUpperCase()
    );
    return phoneNumber?.isValid() || false;
  };

  const isValidCompanyName = (companyName) => {
    if (userCreateData.type === "Company") {
      if (!companyName) return false; // Check for null or undefined
      const companyNameRegex = /^[A-Za-z0-9 -]{3,}$/;
      return companyNameRegex.test(companyName);
    }
    return true;
  };

  const isValidCompanyEmployee = (companyEmployee) => {
    if (userCreateData.type === "Company") {
      if (!companyEmployee) return false; // Check for null or undefined
      const companyEmployeeRegex = /^[0-9]{1,}$/;
      return companyEmployeeRegex.test(companyEmployee);
    }
    return true;
  };

  const isValidDepartment = (department) => {
    if (userCreateData.type === "Company") {
      if (!department) return false; // Check for null or undefined
      const departmentRegex = /^[A-Za-z0-9 -]{3,}$/;
      return departmentRegex.test(department);
    }
    return true;
  };

  const handleEditSubmit = () => {
    console.log(userCreateData)
    if (userCreateData.name == "" || userCreateData.name?.trim() == "") {
      toast.error(t("Name_is_mandatory"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!isValidName(userCreateData.name)) {
      if (userCreateData.name?.trim().length < 3) {
        // setErrorMsgName("Name should be at least 3 characters long");
        toast.error(t("Name_should_be_at_least_3_characters_long"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        return;
      } else {
        toast.error(t("Name_can_only_contain_alphabets_hyphens_and_spaces"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        // setErrorMsgName("Name can only contain alphabets, hyphens, and spaces");
        return;
      }
    }
    if (!isValidEmail(userCreateData.email)) {
      toast.error(t("Invalid_email"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (!isValidContact(userCreateData.contact)) {
      toast.error(t("Invalid_number"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    userCreateData.type =
      userCreateData.type.charAt(0).toUpperCase() +
      userCreateData.type.slice(1);
    if (userCreateData.type === "Select Type" || !userCreateData.type) {
      toast.error(t("Please_select_type"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (userCreateData.type === "Company") {
      if (!isValidCompanyName(userCreateData.company_name)) {
        toast.error(t("Invalid_company_name"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        return;
      }
      if (!isValidCompanyEmployee(userCreateData.company_employee)) {
        toast.error(t("Invalid_company_employee"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        return;
      }
      if (!isValidDepartment(userCreateData.department)) {
        toast.error(t("Invalid_department"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        return;
      }
    }
    let data = {};
    const normalizedType = userCreateData.type.toLowerCase();
    if (normalizedType === "individual") {
      data = {
        name: userCreateData["name"],
        email: userCreateData["email"],
        contact: userCreateData["contact"],
        type: "individual",
        userId: userId.toString(),
        agent_code: userCreateData["agent_code"] || "",
      };
    } else {
      data = {
        name: userCreateData["name"],
        email: userCreateData["email"],
        contact: userCreateData["contact"],
        type: "company",
        company_name: userCreateData["company_name"],
        company_employee: userCreateData["company_employee"],
        department: userCreateData["department"],
        userId: userId.toString(),
        agent_code: userCreateData["agent_code"] || "",
      };
    }
    setIsSubmit(true);
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/user/updateBusinessOwnerUser`,
      headers: {
        _token: userData?.token,
        "Content-Type": "application/json",
      },
      // withCredentials: true,
      data: JSON.stringify(data),
    };

    axios
      .request(config)
      .then((response) => {
        toast.success(t("User_updated"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        getUsersData();
        setIsSubmit(false);

        if (typeof bootstrap !== "undefined" && bootstrap.Modal) {
          const modal = new bootstrap.Modal(createUserRef.current);
          modal.hide(); // Close the modal
        }
      })
      .catch((error) => {
        setIsSubmit(false);
        toast.error(error?.response?.data?.message || error?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        if (!error?.response?.data?.message) {
          if (typeof bootstrap !== "undefined" && bootstrap.Modal) {
            const modal = new bootstrap.Modal(createUserRef.current);
            modal.hide(); // Close the modal
          }
        }
      });
  };
  const clearData = () => {
    setUserCreateData({
      name: "",
      email: "",
      password: "",
      contact: "",
      type: "",
      company_name: "",
      company_employee: "",
      department: "",
      agent_code: "",
    });
    setShowCreateModal(true);
  };

  const getRoleData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      // url: `${environmentVariables?.apiUrl}api/v1/role/rolesPermissionList?role=bo`,
      url: `${environmentVariables?.apiUrl}api/v1/user/getseperaterole?type=bo`,
      headers: {
        _token: userData?.token,
      },
      // withCredentials: true,
    };

    axios
      .request(config)
      .then((response) => {
        setRoleData(response?.data?.data);
        setRole(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status) {
        }
      });
  };
  const [tempData, setTempData] = useState([]);
  const [allParentName, setAllParentName] = useState([]);
  const [pagelimitdata, setPagelimitdata] = useState(null);

  const getUsersData = (page = 1, start_date = "", end_date = "") => {
    let config;
    let paramid;
    if (
      userData?.user_type === "Team Leader" ||
      userData?.user_type === "Sales Executive"
    ) {
      // API for Team Leader to fetch business owner data
      if (userData?.user_type === "Team Leader") {
        paramid = "tlid";
      } else {
        paramid = "staffid";
      }
    }
    if (
      userData?.user_type === "Team Leader" ||
      userData?.user_type === "Sales Executive"
    ) {
      // API for Team Leader to fetch business owner data
      config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${environmentVariables?.apiUrl
          }api/v1/user/getTlbusowmer?${paramid}=${userData?.id
          }&page=${page}&limit=${rowsPerPage}&role=${activeRoleName}&sortOrder=${sortOrder}&sortColumn=${sortColumn}&search=${searchQuery}&start_date=${start_date || startDateQuery
          }&end_date=${end_date || endDateQuery}`,
        headers: {
          _token: userData?.token,
        },
      };
    } else if (userData?.user_type === "super_admin") {
      config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${environmentVariables?.apiUrl
          }api/v1/user/getbussinessownerdata?page=${page}&limit=${rowsPerPage}&agent_code=${activeAgent?.value
          }&role=${activeRoleName}&sortOrder=${sortOrder}&sortColumn=${sortColumn}&search=${searchQuery}&start_date=${start_date || startDateQuery
          }&end_date=${end_date || endDateQuery
          }&campaign_name=${campaign_name}&plan_expire_date=${plan_expire_date}`,
        headers: {
          _token: userData?.token,
        },
      };
    } else {
      console.error("User type is not supported for this operation.");
      return;
    }

    axios
      .request(config)
      .then((response) => {
        setIsloader(false);
        setIsLoading(false);
        // Extracting business owner data
        const businessOwners = response?.data?.data?.users;
        setCampaignCount(response?.data?.data?.totalCampaignsCount);
        setPagelimitdata(response?.data?.data?.pagination.totalRecords);
        if (!businessOwners) {
          console.error("No businessowner data found.");
          return;
        }
        setUsersData(businessOwners);

        let temp = [];

        if (userData?.user_type === "super_admin") {
          // Super admin-specific data processing
          temp = businessOwners.map((el) => el.roleObj?.name);
          setUserListData(businessOwners);
        } else {
          // Team Leader-specific data processing
          temp = businessOwners.map((el) => el.rname);
        }

        let temp2 = new Set(temp);
        temp2 = [...temp2].filter((el) => el != null);

        setTempData(businessOwners);
        setUsersDataSearch(businessOwners);
        setUsersDataApi(businessOwners);

        // Processing parent user data if needed
        let allParentUserData = businessOwners.map((el) => {
          const hasParentUser =
            el?.parentUser &&
            Object.keys(el.parentUser).length !== 0 &&
            el.parentUser.constructor === Object;
          return hasParentUser ? el?.parentUser?.name : "";
        });

        allParentUserData = [...new Set(allParentUserData)].filter(
          (el) => el != null && el !== ""
        );
        setAllParentName(allParentUserData);

        // Setting pagination total rows from the response pagination object
        setPaginationTotalRows(
          response?.data?.data?.pagination?.totalRecords || 0
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsloader(false);
        setIsLoading(false);
      });
  };

  //Agent Data Logic and Dropdown
  const getAgentData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/user/getAgentData`,
      headers: {
        _token: userData?.token,
      },
    };
    axios
      .request(config)
      .then((response) => {
        setAgentData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const agentOptions = agentData.map((agent) => ({
    value: agent?.agent_code,
    label: `${agent?.name} (${agent?.agent_code})`,
  }));

  useEffect(() => {
    getAgentData();
  }, []);

  useEffect(() => {
    getUsersData(searchQuery);
  }, [activeAgent]);

  const getUsersDataForExport = (page, exportType = "") => {
    return new Promise((resolve, reject) => {
      try {
        let paramid =
          userData?.user_type === "Team Leader" ? "tlid" : "staffid";
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${environmentVariables?.apiUrl}api/v1/user/getAlluser_withbussinessowner?${paramid}=${userData?.id}&export=${exportType}page=${page}&limit=${rowsPerPage}&agent_code=${activeAgent?.value}&role=${activeRoleName}&sortOrder=${sortOrder}&sortColumn=${sortColumn}&search=${searchQuery}&start_date=${startDateQuery}&end_date=${endDateQuery}&campaign_name=${campaign_name}&plan_expire_date=${plan_expire_date}`,
          headers: {
            _token: userData?.token,
          },
          // withCredentials: true,
        };
        axios
          .request(config)
          .then((response) => {
            setIsloader(false);
            let temp3 = response?.data?.data?.users;
            resolve(temp3);
            setexportend_date('');
            setexportstart_date('');
          })
          .catch((error) => {
            console.log(error);
            resolve(false);
          });
      } catch (error) {
        console.log("error====>>>", error);
        resolve(false);
      }
    });
  };

  const handleOnSelectedRowsChange = useCallback(
    ({ selectedRows }) => {
      if (!action.fromUser) return; //the component always trigger this with 0 selected rows when it renders a page, what would clear the selection

      selectedRowsPerPage[currentPage] = selectedRows; //there is no way to tell if a row was DEselected, so I had to control the selected rows per page,
      //the array would get an index to control each page
      console.log(JSON.stringify(selectedRowsPerPage));
    },
    [currentPage, selectedRowsPerPage, action.fromUser]
  );

  const handlePageChange = (page) => {
    const nextPage = page;
    getUsersData(nextPage, searchQuery);
    setCurrentPage(page);
    setShowCreateModal(false);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
    setSelectedRowsPerPage([]);

    getUsersData(1, searchQuery);
  };

  const handleVerify = async (userId) => {
    try {
      setIsVarify(true);
      const response = await fetch(
        `${environmentVariables?.apiUrl}api/v1/user/verifyUser`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            _token: userData?.token,
          },
          body: JSON.stringify({ userId: userId }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to verify user");
      }

      const responseData = await response.json();
      setIsVarify(false);
      getUsersData(currentPage, searchQuery);
      // console.log(responseData.message);
    } catch (error) {
      console.error("Error verifying user:", error.message);
      setIsVarify(false);
    }
  };

  const exportToExcel = async () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    // Check if userListData is defined before using it
    if (!userListData) {
      console.error("userListData is not defined");
      return;
    }
    let usersAllData = await getUsersDataForExport(1, "export");
    console.log(usersAllData.length, `userdata length <--------------`);
    const formattedData =
      usersAllData &&
      usersAllData?.map((item) => ({
        Name: item?.name,
        Email: item?.email,
        contact: `${item?.country_code || "+91"} ${item?.contact || ""}`,
        createdDate: new Date(item?.createdAt).toLocaleDateString("en-GB"),
        EmailVerified: item?.email_verified_at ? "Yes" : "No",
        Agent_code: item?.agent_code || "---",
        // Check if businesses array exists before mapping over it
        // Business: item?.businesses ? item.businesses.map(business => business.slug).join(", ") : ""
        Business:
          item?.businesses && item.businesses.length > 0
            ? item.businesses
              .map((business) =>
                business.slug ? `${fronturl}${business.slug}` : "No Business"
              )
              .join(", ")
            : "No Business",
      }));
    const uid = uuidv4(); // Generate a unique identifier
    const timestamp = new Date()
      .toLocaleDateString()
      .replace(/:/g, "-")
      .split(".")[0]; // Format timestamp
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileName = `BUSINESS_OWNER_DATA`;
    saveAs(data, fileName);
  };

  useEffect(() => {
    getRoleData();
    getUsersData(searchQuery);
  }, [rowsPerPage, searchQuery]);

  useEffect(() => {
    setSearchQuery("");
    setActiveRoleName("");
    getUsersData();
  }, [campaign_name]);

  useEffect(() => {
    setSearchQuery("");
    setActiveRoleName("");
    setCampaignName("");
    getUsersData();
  }, [plan_expire_date]);

  useEffect(() => {
    getUsersData();
  }, [startDateQuery, endDateQuery])

  const handleToggleStatus = (userId, currentStatus) => {
    const newStatus = currentStatus == "0" ? "1" : "0";
    axios
      .put(
        `${baseUrl}api/v1/user/updateStatusOfUser/${userId}`,
        { status: newStatus },
        {
          headers: {
            _token: userData?.token,
          },
        }
      )
      .then((response) => {
        toast.success(t("User_status_updated_successfully"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        getUsersData(searchQuery);
      })

      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        console.error("Error updating user status:", error);
      });
  };
  const paginationOptions = {
    rowsPerPageText: t("Rows_per_page"),
    rangeSeparatorText: t("Of"),
    selectAllRowsItem: true,
    selectAllRowsItemText: t("All"),
  };
  
  const handleSort = (column, sortDirection) => {
    const key = column.sortCoulmn;
    const direction = sortDirection === "asc" ? "asc" : "desc";
    setSortColumn(key);
    setSortOrder(direction);
    setShowCreateModal(false);
  };
  useEffect(() => {
    getUsersData(1, searchQuery);
  }, [sortColumn, sortOrder]);

  const columns = [
    {
      name: t("Name"),
      selector: (row) => row?.name,
      sortCoulmn: "name",
      sortable: true,
      cell: (d) => {
        const capitalizedName =
          d?.name?.charAt(0)?.toUpperCase() + d?.name?.slice(1);
        return capitalizedName;
      },
      color: "red",
      fixed: "left",
      // style: customStyles.UserNameColumn,
      width: "170px",
    },
    {
      name: t("Email"),
      selector: (row) => row?.email,
      sortCoulmn: "email",
      sortable: true,
      cell: (row) => <div style={{ width: "1000px" }}>{row?.email}</div>,
      width: "250px",
    },
    // {
    //   name: "Business Type",
    //   selector: (row) => row?.type,
    //   sortCoulmn:"type",
    //   sortable: true,
    //   cell: (row) => <div>{row?.type}</div>,
    //   width: "150px",
    // },
    {
      name: t("Created_by"),
      selector: (row) => row?.agent_code,
      sortCoulmn: "agent_code",
      sortable: true,
      cell: (row) => (
        <>
          {" "}
          {row?.agent_code ? (
            <div>
              {row?.agent_name}{" "}
              <span>({row?.agent_code ? row?.agent_code : "---"})</span>
            </div>
          ) : (
            "---"
          )}
        </>
      ),
      width: "175px",
    },
    {
      name: t("Contact"),
      sortable: true,
      sortCoulmn: "contact",
      selector: (row) => `${row?.country_code || ""} ${row?.contact || ""}`,
      width: "150px",
    },
    {
      name: t("Plan"),
      sortable: true,
      sortCoulmn: "Plan",
      selector: (row) => row?.planObj?.name || "--",
      width: "150px",
    },
    {
      name: t("Plan_purchase_date"),
      sortable: true,
      sortCoulmn: "Plan",
      selector: (row) => { return GetFormattedDate(row?.planDetails?.start_date); },
      width: "250px",
    },
    {
      name: t("Plan_expiry_date"),
      sortable: true,
      sortCoulmn: "Plan",
      selector: (row) => {
        if (!row?.plan_expire_date) return "--";

        const expiryDate = new Date(row.plan_expire_date);
        const today = new Date();

        expiryDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);

        const timeDiff = expiryDate - today;
        const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

        const formattedDate = (GetFormattedDate(expiryDate));

        return daysLeft >= 0
          ? `${formattedDate}  ${daysLeft == 0 ? '(Today)' : daysLeft == 1 ? `(${daysLeft} day left)` : `(${daysLeft} days left)`}`
          : `${formattedDate} (Expired)`;

      },
      width: "250px",
    },
    {
      name: t("Registration_date"),
      // selector: (row) => new Date(row?.createdAt).toLocaleDateString(),
      selector: (row) => {
        return moment(row?.createdAt).format("DD/MM/YYYY");
      },
      sortCoulmn: "createdAt",
      sortable: true,
      width: "185px",
    },
    {
      name: t("Utm_source"),
      sortable: true,
      sortCoulmn: "utm_source",
      selector: (row) => row?.utm_source,
      cell: (d) => {
        return d?.utm_source || "--";
      },
      width: "175px",
    },
    {
      name: t("Utm_medium"),
      sortable: true,
      sortCoulmn: "utm_medium",
      selector: (row) => row?.utm_medium,
      cell: (d) => {
        return d?.utm_medium || "--";
      },
      width: "175px",
    },
    {
      name: t("Utm_campaign"),
      sortable: true,
      sortCoulmn: "utm_campaign",
      selector: (row) => row?.utm_campaign,
      cell: (d) => {
        return d?.utm_campaign || "--";
      },
      width: "175px",
    },
    {
      name: t("Utm_content"),
      sortable: true,
      sortCoulmn: "utm_content",
      selector: (row) => row?.utm_content,
      cell: (d) => {
        return d?.utm_content || "--";
      },
      width: "175px",
    },
    {
      name: t("Email_verified_head"),
      selector: (row) => row?.email_verified_at,
      sortCoulmn: "email_verified_at",
      sortable: true,
      omit: !showLastLoginDateColumn,
      cell: (row) => {
        const [isLoading, setIsLoading] = useState(false);
        if (!row.email_verified_at) {
          return (
            <div style={{ color: "red" }}>
              <button
                style={{
                  borderRadius: "5px",
                  backgroundColor: "red",
                  color: "white",
                  padding: "5px 10px",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsLoading(true);
                  handleVerify(row.id).finally(() => {
                    setIsLoading(false);
                  });
                }}
              >
                {isLoading ? <CircularLoader size={20} /> : "Verify"}
              </button>
            </div>
          );
        } else {
          return (
            <div style={{ color: "green" }}>
              <span>Verified</span>
            </div>
          );
        }
      },
      width: "175px",
      hidden: !userData || userData.user_type !== "super_admin",
    },
    {
      name: t("Action"),
      sortable: false,
      cell: (d) => (
        <>
          {userData?.user_type == "super_admin" ? (
            <>
              <i
                style={{ width: "30px", marginRight: "15px" }}
                key={d.id}
                data-bs-toggle="modal"
                data-bs-target="#edituser"
                onClick={() => handleEditUser(d.id)}
                className="first fas fa-pen"
              ></i>

              <i
                style={{ width: "50px" }}
                onClick={() => handleDeleteUser(d.id)}
                className="fas fa-trash-alt"
              ></i>
            </>
          ) : (
            <>
              <i
                style={{ width: "30px", marginRight: "15px" }}
                key={d.id}
                data-bs-toggle="modal"
                data-bs-target="#edituser"
                onClick={() => handleEditUser(d.id)}
                className="first fas fa-pen"
              // style={{  marginRight: '15px' }}
              ></i>
              {userData?.user_type === "Team Leader" ||
                userData?.user_type === "Sales Executive" ? (
                ""
              ) : (
                <i
                  style={{ width: "50px" }}
                  onClick={() => handleDeleteUser(d.id)}
                  className="fas fa-trash-alt"
                ></i>
              )}
            </>
          )}

          <div
            className="form-check form-switch"
            style={{ marginLeft: "15px" }}
          >
            <input
              className="form-check-input"
              // type="checkbox"
              type="checkbox"
              checked={d?.isDeleted == "0" ? true : false}
              id={`flexSwitchCheckDefault_${d.id}`}
              onClick={() => {
                // console.log(userData?.user_type,"2wss")
                handleToggleStatus(d.id, d.isDeleted);
              }}
              style={{ marginLeft: "-20px" }}
            />
          </div>
        </>
      ),
      minWidth: "1px",
      width: "190px",
    },
  ];

  const tableData = {
    columns,
    data: usersData,
  };
  const [activeRoleName, setActiveRoleName] = useState("");
  const [activeParentName, setActiveParentName] = useState("");
  const [selectedDateFrom, setSelectedDateFrom] = useState("");
  const [selectedDateTo, setSelectedDateTo] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  const handleChangeRoleData = (e) => {
    setActiveRoleName(e.target.value);
  };

  const handleFilterOpen = () => {
    setShowFilter(!showFilter);
    setShowCreateModal(false);
  };

  const handleFilterClose = () => {
    setShowFilter(false);
  };

  useEffect(() => {
    let filteredData = tempData;
    if (activeRoleName == "All Role") {
    } else {
      if (activeRoleName != "" && activeRoleName != "All Role") {
        if (userData && userData.user_type == "super_admin") {
          getUsersData(1, searchQuery);
        } else {
          getUsersData(1, searchQuery);
        }
      }
    }
    if (activeParentName != "All" && activeParentName != "") {
      filteredData = filteredData.filter(
        (el) => el?.parentUser?.name == activeParentName
      );
    }
    setUsersData(filteredData);
  }, [activeRoleName, selectedDateFrom, selectedDateTo, activeParentName]);

  const handleReset = () => {
    setActiveRoleName("All Role");
    setUsersData(tempData);
    setActiveParentName("");
  };

  const handleCreateUser = (status) => {
    if (status) {
      getUsersData();
      setShowCreateModal(false);
    }
  };

  const handleSearchInputChange = (event) => {
    // Update the search query state when the user types in the search input
    setCampaignName("");
    setplanExpireDate("");
    setSearchQuery(event.target.value);
  };
  const handleCompaignName = (e) => {
    setCampaignName(e.target.value);
  };

  const handlePlanExpire = (e) => {
    setplanExpireDate(e.target.value);
  };

  return (
    <>
      <PageName PageName={t("Business_owners")} />

      <article>
        <div className="row  businessOwner">
          <div class="col-md-12">
            <>
              <div className="campaigntop">
                {userData.user_type === "super_admin" && (
                  <div
                    // className="tile bbox Total_Titlebox_container"
                    style={{
                      // minHeight: "10px",
                      // minWidth: "120px",
                      margin: "0 15px",
                      display: "flex",
                      gap: "10px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {/* <i className="fa-solid fa-business-time"></i> */}
                    <h3 style={{ fontSize: "18px", fontWeight: "400" }}>
                      Total Campaign Leads :{" "}
                    </h3>
                    <h5 className="tlcounts">{campaignCount}</h5>
                  </div>
                )}

                <a
                  class="icon-btn addrolemain_roleAdduser "
                  onClick={() => clearData()}
                >
                  <AddUserText className="AddNewPlanClass">
                    {" "}
                    {t("Add_business_owner")}
                  </AddUserText>
                  <span className="addrole_role">+</span>
                </a>
              </div>
            </>

            <div
              style={{
                // overflowX: "scroll",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "10px 10px 0 0 ",
                padding: "20px",
                marginBottom: "-16px",
                background: "#fff",
                gap: "20px",
              }}
            >
              {userData.user_type === "super_admin" && (
                <div className="user-select-items expiry-select">
                  {/* <select
                    value={campaign_name}
                    onChange={(e) => handleCompaignName(e)}
                    class="form-select"
                    style={{ width: "300px" }}
                  >
                    {" "}
                    <option value={""}>All Users</option>
                    <option value={"campaign1"}>Campaign 1</option>
                    <option value={"campaign2"}>Campaign 2</option>
                  </select> */}

                  <select
                    value={plan_expire_date}
                    onChange={(e) => handlePlanExpire(e)}
                    class="form-select"
                    style={{ width: "300px" }}
                  >
                    {" "}
                    <option value={""}>Expiry Filter</option>
                    <option value={"today"}>Today</option>
                    <option value={"week"}>Weekly</option>
                    <option value={"monthly"}>Monthly</option>
                  </select>

                  <div className="user-select-items" style={{zIndex:"1"}}>
                  <Select
                    isClearable={true}
                    placeholder="Select Agents"
                    defaultValue=""
                    options={agentOptions}
                    onChange={setActiveAgent}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        padding: "2px 10px",
                        borderRadius: "5px",
                       border:"1px solid #EAECF0",
                        width:"200px"
                      }),
                    }}
                  />
                </div>
                </div>
              )}




              <p style={{ marginTop: "0", display: "flex", justifyContent: "space-between", width: "100%" }} className="addusermainright">
                <input
                  style={{zIndex:"0", fontSize:"14px"}}
                  className=" customsearchbyword searchUser"
                  type="text"
                  value={searchQuery}
                  onChange={(e) => handleSearchInputChange(e)}
                  placeholder={t("Search_business_owners_email")}
                />

                <div style={{ display: "flex" }}>
                  <div style={{ position: "relative", zIndex: "10" }}>
                    <button
                      className="btn btn-primary d-flex gap-2 user-filter"
                      onClick={() => {
                        setIsDatePickerVisible(true);
                        setFilterType("custom");
                      }}
                    >
                      <img src={NewFilterIcon} />
                      {t("Filter")}
                    </button>

                    {/* Custom Date Picker */}
                    {isDatePickerVisible && filterType === "custom" && (
                      <div
                        style={{
                          position: "absolute",
                          top: "-2px",
                          right: "0",
                          zIndex: "2",
                        }}
                      >
                        <DatePicker
                          selectsRange
                          startDate={startDate}
                          endDate={endDate}
                          onChange={handleCustomDateChange}
                          dateFormat="yyyy-MM-dd"
                          placeholderText="Select a custom date range"
                          inline
                          style={{
                            padding: "8px",
                            borderRadius: "5px",
                            border: "1px solid #ccc",
                            fontSize: "14px",
                          }}
                        />
                        
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "10px",
                          }}
                        >
                          <button
                          className="btn btn-secondary"
                          onClick={() => setIsDatePickerVisible(false)} // Close the date picker
                          style={{
                            padding: "8px 12px",
                            backgroundColor: "#6c757d",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                        >                          
                          {t("Close")}
                        </button>
                        <button
                          className="btn btn-success"
                          onClick={() => {
                            handleSubmit("custom");
                            setIsDatePickerVisible(false);
                          }} // Apply the date filter and close the date picker
                          style={{
                            padding: "8px 12px",
                            backgroundColor: "#28a745",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                        >
                          {t("Apply")}
                        </button>
                       {(startDate && endDate ) && (
                         <button
                         className="btn btn-success"
                         onClick={() => {
                           handleSubmit("reset");
                           setCustomDateRange([null,null])
                          //  setIsDatePickerVisible(false);
                         }} 
                         style={{
                           padding: "8px 12px",
                           backgroundColor: "skyblue",
                           color: "white",
                           border: "none",
                           borderRadius: "5px",
                           fontSize: "14px",
                           cursor: "pointer",
                         }}
                       >
                         {t("Reset")}
                       </button>
                       )}
                      </div>
                    </div>
                    )}
                  </div>
                  <button
                    className="btn d-flex btn-primary gap-1 bg-transparent border-0 excel-button"
                    onClick={exportToExcel}
                  >
                    {" "}
                    <img src={DownloadExcel} />
                    {t("Export")}
                  </button>
                </div>
              </p>
            </div>
          </div>
        </div>
        {isLoader ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "55%",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <CircularLoader size={40} />
          </div>
        ) : (
          <div className="main">
            {isLoading ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "55%",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <CircularLoader size={40} />
              </div>
            ) : (
              <>
                <div className="scrollable-columns">
                  <div className="sticky-header-container">
                    <DataTableExtensions
                      {...tableData}
                      {...tableExtensions}
                      customStyles={customStyles}
                      filterPlaceholder="Search Business Owner"
                    >
                      {pagelimitdata <= 10 ? (
                        <DataTable
                          columns={columns}
                          data={tableData.data}
                          noHeader
                          defaultSortField="id"
                          sortIcon={<SortIcon />}
                          sortServer
                          onSort={handleSort}
                          highlightOnHover
                          onChangePage={handlePageChange}
                          onChangeRowsPerPage={handleRowsPerPageChange}
                          onSelectedRowsChange={handleOnSelectedRowsChange}
                          dense
                           paginationComponentOptions={{
                        ...paginationOptions,
                      }}
                          customStyles={customStyles}
                          noDataComponent={<div style={{padding:"24px"}}>{t("No_records_found")}</div>}
                        />
                      ) : (
                        <DataTable
                          columns={columns}
                          data={tableData.data}
                          noHeader
                          defaultSortField="id"
                          sortIcon={<SortIcon />}
                          sortServer
                          onSort={handleSort}
                          highlightOnHover
                          pagination
                          paginationServer
                          paginationTotalRows={paginationTotalRows}
                          paginationPerPage={paginationPerPage}
                          paginationRowsPerPageOptions={[
                            10, 20, 50, 100, 300, 1000, 10000,
                          ]}
                          onChangePage={handlePageChange}
                          onChangeRowsPerPage={handleRowsPerPageChange}
                          onSelectedRowsChange={handleOnSelectedRowsChange}
                          dense
                           paginationComponentOptions={{
                        ...paginationOptions,
                      }}
                          customStyles={customStyles}
                          noDataComponent={<div style={{padding:"24px"}}>{t("No_records_found")}</div>}
                        />
                      )}
                    </DataTableExtensions>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </article>
      <CreateUser
        showModal={showCreateModal}
        userCreateData={userCreateData}
        roleData={roleData}
        handleCreateUser={handleCreateUser}
        isBusinessOwner={true}
        onClose={() => setShowCreateModal(false)}
      />

      <div
        class="modal fade"
        id="edituser"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={createUserRef}
      >
        <div class="modal-dialog">
          <div
            class="modal-content userUpdate_action_form"
            id="exampleModalLabelContainer"
          >
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {t("Update_business_owner")}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form class="row">
                <div class="mb-2 col-md-12">
                  <input
                    onChange={(e) => {
                      handleChangeUserData(e, "name");
                    }}
                    value={userCreateData["name"]}
                    class="form-control"
                    type="name"
                    placeholder={t("Name")}
                  />
                </div>
                <div class="mb-2 col-md-12">
                  <input
                    value={userCreateData["email"]}
                    // onChange={(e) => handleChangeUserData(e, "email")}
                    class="form-control"
                    type="email"
                    placeholder={t("Email")}
                  />
                </div>
                {/* <div class="mb-2 col-md-12">
                  <input
                    onChange={(e) => {
                      handleChangeUserData(e, "contact");
                    }}
                    value={userCreateData["contact"]}
                    class="form-control"
                    type="number"
                    placeholder="Contact"
                  />
                </div> */}
                <div className="mb-2 col-md-12">
                  <PhoneInput
                    name="contact"
                    country={"in"}
                    countryCodeEditable={false}
                    enableSearch={true}
                    value={userCreateData["contact"]}
                    onChange={(phone, country, e, formattedValue) => {
                      handleChangeUserData(formattedValue, "contact");
                    }}
                    isValid={(value, country) => isValidContact(value, country)}
                    inputProps={{
                      className: "form-control",
                      placeholder: t("Mobile_number"),
                      required: true,
                    }}
                  />
                </div>
                <div class="mb-2 col-md-12">
                  <select
                    value={userCreateData["type"] || "Select Type"}
                    onChange={(e) => {
                      handleChangeUserData(e, "type");
                    }}
                    class="form-select"
                  >
                    <option value="Select Type">{t("Select_type")}</option>
                    <option value="individual">{t("Individual")}</option>
                    <option value="company">{t("Corporate")}</option>
                  </select>
                </div>
                {userCreateData.type?.toLowerCase() === "company" && (
                  <>
                    <div class="mb-2 col-md-12">
                      <input
                        onChange={(e) => {
                          handleChangeUserData(e, "company_name");
                        }}
                        value={userCreateData["company_name"]}
                        class="form-control"
                        type="text"
                        placeholder={t("Company_name")}
                        min={3}
                        max={100}
                      />
                    </div>
                    <div class="mb-2 col-md-12">
                      <input
                        onChange={(e) => {
                          handleChangeUserData(e, "company_employee");
                        }}
                        value={userCreateData["company_employee"]}
                        class="form-control"
                        type="text"
                        placeholder={t("Company_employee")}
                      />
                    </div>
                    <div class="mb-2 col-md-12">
                      <input
                        onChange={(e) => {
                          handleChangeUserData(e, "department");
                        }}
                        value={userCreateData["department"]}
                        class="form-control"
                        type="text"
                        placeholder={t("Department")}
                        min={3}
                        max={100}
                      />
                    </div>
                  </>
                )}
                <div
                  style={{
                    fontSize: "14px",
                    color: "red",
                    position: "absolute",
                    bottom: "70px",
                  }}
                >
                  {errorMsg}
                </div>
                <div class="mb-2 col-md-12">
                  <input
                    name="agent_code"
                    className="form-control"
                    type="text"
                    placeholder={t("Agent_code")}
                    value={userCreateData["agent_code"]}
                    onChange={(e) => {
                      e.target.value = e.target.value.toUpperCase();
                      handleChangeUserData(e, "agent_code");
                    }}
                    disabled={userData.user_type !== "super_admin"}
                    maxLength={6}
                  />
                </div>
                <div class="mb-2 flex-center col-md-12">
                  <button
                    onClick={handleEditSubmit}
                    class="btn btn-primary me-3 save-cta"
                    type="button"
                    data-bs-dismiss="modal"
                  >
                    {/* <i class="bi bi-check-circle-fill ms-2 "></i> */}
                    {t("Update")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default User;
