import React, { useRef } from "react";
// import "./TemplatComman.css";
import bannerImage from "../../../../Images/sticky.png";
import logoImage from "../../../../Images/theme_11_logo.png";
import portfolio1 from "../../../../Images/theme11-p1.png";
import portfolio2 from "../../../../Images/theme11-p2.png";
import portfolio3 from "../../../../Images/theme11-p3.png";
import portfolio4 from "../../../../Images/theme11-p4.png";

import footerqr from "../../../../Images/theme11-qr.png";
import footerdownload from "../../../../Images/theme11-download.png";
import footeruser from "../../../../Images/theme11-user.png";

import testimonialImage from "../../../../Images/avatar.png";
import "./Theme11.css";
import { useState } from "react";
import TestimonialCarousel from "./TestimonialCarousel";
import CalanderIconn from "../../../../Images/DarkcalendarIcondays.png";
import GalleryCarousel from "./GalleryCarousel";
import styled from "styled-components";


const Theme11Template = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const galleryArray = [
    {
      id: 67,
      business_id: 10,
      image: "1701333567360Rectangle546.jpg",
      created_by: 72,
      created_at: "2023-11-30T08:39:27.000Z",
      updated_at: "2023-11-30T08:39:27.000Z",
    },
    {
      id: 68,
      business_id: 10,
      image: "1701333569511Rectangle547.jpg",
      created_by: 72,
      created_at: "2023-11-30T08:39:29.000Z",
      updated_at: "2023-11-30T08:39:29.000Z",
    },
    {
      id: 69,
      business_id: 10,
      image: "1701333572785Rectangle548.jpg",
      created_by: 72,
      created_at: "2023-11-30T08:39:32.000Z",
      updated_at: "2023-11-30T08:39:32.000Z",
    },
  ];

  const calendarInputRef = useRef(null);
  const testimonialArray = [
    {
      name: "Ernest Reinger",
      description:
        "I absolutely love the Constriction Services! They have exceeded my expectations in every way.",
      formCount: "0",
      image: "null",
    },
    {
      name: "Ernest Reinger",
      description:
        "I absolutely love the Constriction Services! They have exceeded my expectations in every way.",
      formCount: "1",
      image: "null",
    },
    {
      name: "Ernest Reinger",
      description:
        "I absolutely love the Constriction Services! They have exceeded my expectations in every way.",
      formCount: "2",
      image: "null",
    },
  ];

  const openCalendar = () => {

    if (calendarInputRef.current) {
      calendarInputRef.current.showPicker();

    }
  };
  return (
    <div>
      <div class="main-temaplt" id="temp11">
        <div className="container">
          <div className="row justify-content-center">
            <div className="overlay"></div>
            <div  className="col-12 wtemplate p-0"
            style={{
              width: "420px",
              backgroundSize: 'contain',
              background: `#000 url(${bannerImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center top',
            }}
            >
             

                <article className="imgsechere sec" style={{height:"70vh"}}>
                  <article className="personalinfo sec">
                    <img loading="lazy" src={logoImage} className="mx-auto d-block logo-profile" />
                    <h3 className="username">Marvin Steward</h3>
                    <p className="titleuser">Event Planner</p>
                  </article>
                </article>

                <article className="abouttemplate sec">
                  <h3 class="sectitle"> About us </h3>
                  <p>
                    {`Corporate events are the backbone of any company, bringing people together, inspiring for the launch of new products and services. `}
                  </p>
                </article>

                <article className="social-links-sec sec">
                  <ul class="socil-link">
                    <li> <a>  <i class={`fa-brands fa-facebook`}></i> </a>   </li>
                    <li> <a> <i class={`fa-brands fa-twitter`}></i>  </a> </li>
                    <li>  <a>   <i class={`fa-brands fa-instagram`}></i>   </a>  </li>
                    <li> <a>  <i class={`fa-brands fa-linkedin`}></i>  </a>  </li>
                  </ul>
                </article>

                <article class="abouttemplate sec">  
                  <h3 class="sectitle">Portfolio </h3>   

                  <div className="portfolio-content">
                    <div className="p-left">
                      <div className="left-img"> <img loading="lazy" src={portfolio1} /> </div>
                      <div className="left-img">  <img loading="lazy" src={portfolio3} />  </div>
                      <div className="lect-img">  <img loading="lazy" src={portfolio4} />  </div>
                    </div>
                    <div className="p-right">  <img loading="lazy" src={portfolio2} /> </div>
                  </div>
                  { /* <GalleryCarousel slides={galleryArray} /> */}
                </article>

                <article class="testimoniyalbox sec">
                  <h3 class="sectitle testimonial">Testimonial </h3>
                  <div className="testimonials-outer">
                    <TestimonialCarousel
                      testimonials={testimonialArray}
                      currentElem={currentIndex}
                    />
                    <div className="carousel-container">
                      <button
                        onClick={() =>
                          currentIndex == 0
                            ? setCurrentIndex(testimonialArray.length - 1)
                            : setCurrentIndex((prev) => prev - 1)
                        }
                        className="carousel-btn"
                      >
                        <i class="fa fa-angle-left"></i>
                      </button>
                      <button
                        onClick={() => {
                          currentIndex == testimonialArray.length - 1
                            ? setCurrentIndex(0)
                            : setCurrentIndex((prev) => prev + 1);
                        }}
                        className="carousel-btn"
                      >
                        <i class="fa fa-angle-right"></i>
                      </button>
                    </div>
                  </div>
                </article>

                <article className="conatctform sec">
                  <h3 class="sectitle">Contact us</h3>
                  <div class="contact-form">
                    <form>
                      <div class="col-12">
                        <input
                          type="text"
                          class="form-control clear_string"
                          placeholder="Your Name"
                          name="last_name"
                          value=""
                          required=""
                        />
                      </div>
                      <div class="col-12">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Email"
                          name="email"
                          value=""
                          required=""
                        />
                      </div>
                      <div class="col-12">
                        <input
                          type="text"
                          class="form-control clear_string"
                          maxlength="10"
                          minlength="10"
                          placeholder="Venue "
                          name="venue"
                          value=""
                          required=""
                        />
                      </div>
                      <div class="col-12">
                        <input
                          type="date"
                          class="form-control clear_string"
                          maxlength="10"
                          minlength="10"
                          placeholder="Event Date"
                          name="event"
                          value=""
                          required=""
                        />
                      </div>
                      <div class="col-12">
                        <select class="form-select" aria-label="Default select example">
                          <option value="Select Venue">Select Venue</option>
                          <option> Venue 1 </option>
                        </select>
                      </div>

                      <div class="col-12">
                        <button type="submit" class="btn btn-submitform">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </article>

                <article class="contact sec">
                  <ul class="conlink">

                    <li>
                   <span className="social-text"> Email: </span>  <a href="">    <span>{` christian@gmail.com`}</span>  </a>
                    </li>
                    <li>
                   <span className="social-text">  Mobile:  </span>  <a href="">    <span>{`987654321`}</span>    </a>
                    </li>
                  </ul>
                </article>
               
                {/*
                <article className="servicesName sec">
                  <h3 class="sectitle">Services </h3>
                  <div className="mainservicebox">
                    <ul className="servesbox">
                      <li>Smart Contract Development</li>
                      <li>Blockchain Integration</li>
                    </ul>
                  </div>
                      </article> 

                      
                <article className="businessHours sec">
                  <h3 class="sectitle">Business Hours</h3>

                  <ul class="timelist">
                    <li>
                      <div className="day">Monday</div>
                      <div className="time">
                        <span>8:00 AM</span>-<span>5:00 PM</span>
                      </div>
                    </li>
                    <li>
                      <div className="day">Tuesday</div>
                      <div className="time">
                        <span>8:00 AM</span>-<span>5:00 PM</span>
                      </div>
                    </li>
                    <li>
                      <div className="day">Wednesday</div>
                      <div className="time">
                        <span>8:00 AM</span>-<span>5:00 PM</span>
                      </div>
                    </li>
                    <li>
                      <div className="day">Thursday</div>
                      <div className="time">
                        <span>8:00 AM</span>-<span>5:00 PM</span>
                      </div>
                    </li>
                    <li>
                      <div className="day">Friday</div>
                      <div className="time">
                        <span>8:00 AM</span>-<span>5:00 PM</span>
                      </div>
                    </li>
                    <li>
                      <div className="day">Saturday</div>
                      <div className="time">
                        <span>8:00 AM</span>-<span>5:00 PM</span>
                      </div>
                    </li>
                  </ul>
                </article>
               

                <article className="makeappitmneets sec">
                  <h3 class="sectitle">Make Appointment</h3>
                  <div className="contact-form">
                    <div class="col-12 mb-3" style={{ position: "relative" }}>
                      <input
                        type="date"
                        ref={calendarInputRef}
                        class="form-control clear_string"
                        placeholder="Date"
                        name="currentDate"
                      // value={selectedDate}
                      // onChange={(e) => setSelectedDate(e.target.value)}
                      />
                      <div className="calanderIcon" onClick={openCalendar}><img loading="lazy" src={CalanderIconn} alt="Calendar Icon" /></div>
                    </div>
                    <div class="col-12 mb-3">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      // onChange={(e) => setSelectedTime(e.target.value)}
                      >
                        <option value="Select time">Select time</option>
                        <option>{`2:00 PM - 5:00 PM`}</option>
                      </select>
                    </div>
                    <div class="col-12 mb-3">
                      <button
                        className="btn btnmakeappitmnets"
                        data-bs-toggle="modal"
                        data-bs-target="#appotimnets"
                      >
                        Make An Appointment
                      </button>
                    </div>
                  </div>
                </article> */}
                 <article className="footer">
                  <div className="footer-content"> 
                  <img loading="lazy" src={footerqr} />
                  <p> QR </p>
                  </div>
                  <div className="footer-content">
                    <img loading="lazy" src={footerdownload } />
                    <p> Download </p>
                  </div>
                  <div className="footer-content">
                    <img loading="lazy" src={footeruser} />
                    <p>  Add contact  </p>
                  </div>
                </article>              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theme11Template;
