export const changeThemeColor=(color)=>{
    document.documentElement.style.setProperty('--color-background',color);
}
export const changeThemeColorMainBackground=(color)=>{
    document.documentElement.style.setProperty('--color-mainbackground',color);
}
export const changeThemeColorText=(color)=>{
    document.documentElement.style.setProperty('--color-heading',color);
}
export const changeThemeColorDesc=(color)=>{
    document.documentElement.style.setProperty('--color-description',color);
}
export const changeThemeColorCTA=(color)=>{
    document.documentElement.style.setProperty('--color-cta',color);
}
export const changeThemeColorCTAText=(color)=>{
    document.documentElement.style.setProperty('--color-ctaText',color);
}