import React from "react";
import "./Theme51";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getImage } from "../../../Components/TestimonialGetImage/TestimonialGetImage";



const CustomPrevArrow = (props) => {
  const { onClick, colordata } = props;
  return (
    <div
      onClick={onClick}
      className="colorcta colorctaborder"
      style={{
        color: colordata?.dynamicCtaTextColor || "#FFB87E",
        background: colordata?.dynamicCtaColor || "#645244",
        borderColor: colordata?.dynamicCtaColor || "",
        height: "26px",
        width: "26px",
        borderRadius: "50%",
        display: "grid",
        placeContent: "center",
        cursor: "pointer",
        fontSize: "22px",
        position: "absolute",
        right: "50px",
        top: "-35%",
        transform: "translateY(-50%)",
        zIndex: "2",
      }}
    >
      <i className="fa fa-angle-left colordescription"></i>
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { onClick, colordata } = props;
  return (
    <div
      onClick={onClick}
      className="colorcta colorctaborder"
      style={{
        color: colordata?.dynamicCtaTextColor || "#FFB87E",
        background: colordata?.dynamicCtaColor || "#645244",
        borderColor: colordata?.dynamicCtaColor || "",
        height: "26px",
        width: "26px",
        borderRadius: "50%",
        display: "grid",
        placeContent: "center",
        cursor: "pointer",
        fontSize: "22px",
        position: "absolute",
        right: "5px",
        top: "-35%",
        transform: "translateY(-50%)",
        zIndex: "2",
      }}
    >
      <i className="fa fa-angle-right colordescription"></i>
    </div>
  );
};

const TestimonialCarousel = ({ testimonials, currentElem, handleTouchStart, handleTouchMove, handleTouchEnd,colordata }) => {
  const testimonialStyle = {
    transform: `translateX(-${(currentElem * 100) / testimonials?.length}%)`,
    transition: "transform 0.5s ease-in-out",
    // width: `${testimonials * 100}%`,
  };
  //   console.log(testimonials, currentElem, "inside carousel");
  const settings = {
    // dots: false,
    // infinite: true,
    // speed: 500,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    // draggable: true,
    // arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    arrows: true,
    prevArrow: <CustomPrevArrow colordata={colordata} />,
    nextArrow: <CustomNextArrow colordata={colordata} />,
  };
  return (
    <>
      <div className="testimonials-container">
        <Slider {...settings}>
          {testimonials.map((val, index) => {
            return (
              <div class="testibox" key={index}>
                <div className="testimonialcontent">
                  <div className="testiimg">
                    <img
                      src={
                        getImage(val)
                      }
                      class="mx-auto d-block img-fluid clint-img2"
                      alt="Testimonial Image"
                    />
                  </div>
                  <div className="clintname">
                    <b className="testimonialname colordescription" style={{color: colordata?.dynamicTextDescriptionColor ? `${colordata?.dynamicTextDescriptionColor}90` : "" }}>
                      {val?.name != "undefined" ? val?.name : ""}
                    </b>

                    {/* <div className="rating">
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                    </div> */}
                      <p className="testimonialdescription colordescription" style={{color: colordata?.dynamicTextDescriptionColor ? `${colordata?.dynamicTextDescriptionColor}90` : "" }}>
                      {val?.description != "undefined" ? val?.description : ""}
                    </p>
                  </div>

                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

export default TestimonialCarousel;
