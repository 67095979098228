import * as yup from "yup";
import { object, string, ref } from "yup";

export const registerValidationCompany = (t)=> {

const getCharacterValidationError = (str) => {
    return `${t("Password_atleast_one")} ${str} ${t("Character")}`;
  };

return yup.object().shape({
  name: yup
    .string()
    .required(t("Full_name_required"))
    .matches(/^[A-Za-z\s]+$/, t('Only_alphabet_character_allowed_in_full_name'))
    .min(3, t("Name_greater_than_3"))
    .max(45),
  email: yup
  .string()
  .required(t("Email_required"))
  .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/, t('Invalid_email_address_format')).email(),
  company_name: yup
    .string()
    .required(t("Company_name_required"))
    .matches(/^[a-zA-Z0-9 ,.?/()@$'\n\-_&]*$/, `${t("Only")} ', . ? / - _ &' ${t("characters are allowed")}`)
    .min(3, t("Company_name_length_greater_than_3"))
    .max(45),
  company_employee: yup?.number()
  ?.min(10,t("Number_of_employees_must_have_least"))
  .max(100000000, t("Please_confirm_accurate_number_of_employees")),
  department: yup.string()
  .min(3, t("Department_name_length_should_be_greater_then_3"))
  .max(45)
  .matches(/^[A-Za-z\s]+$/, t('Only_alphabetic_characters_are_allowed_in_the_department')),
  contact: yup
    .string()
    .min(10, t("Mobile_number_must_have_at_least_10"))
    .required(t("Mobile_no_required")),
  password: string()
    .required(t("Please_enter_a_password"))
    // check minimum characters
    .min(8, t("Password_at_least_8_characters"))
    // different error messages for different requirements
    .matches(/[0-9]/, getCharacterValidationError(t("Digit")))
    .matches(/[a-z]/, getCharacterValidationError(t("Lowercase")))
    .matches(/[A-Z]/, getCharacterValidationError(t("Uppercase"))),
  confirmPassword: string()
    .required(t("Retype_password"))
    // use oneOf to match one of the values inside the array.
    // use "ref" to get the value of passwrod.
    .oneOf([ref("password")], t("Passwords_does_not_match")),
  type: yup.string().required(t("Please_select_type_of_registeration")),
});
}
//212 15:59
