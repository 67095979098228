import React, { useState } from "react";
import {useLocation, useNavigate } from "react-router-dom";
import Drawer from "react-bottom-drawer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useSelector, useDispatch } from "react-redux";
import './Theme22.css';
import { environmentVariables } from "../../../../config/env.config";
import cabSeat from "../../../../Images/cabSeat.png";
import cabAutomatic from "../../../../Images/cabAutomatic.png";
import cabManual from "../../../../Images/cabManual.png";
import cabPetrol from "../../../../Images/cabPetrol.png";
import cabDiesel from "../../../../Images/cabDiesel.png";
import cabCng from "../../../../Images/cabCng.png";
import cabElectric from "../../../../Images/cabElectric.png";
import cabFrame from "../../../../Images/cabFrame.png";
const CabDetailPage = (props) => {
  const [currentImage, setCurrentImage] = useState(0);
  const backNavigationData = useSelector(state => state.navigation);
    const dispatch = useDispatch();
      const navigate = useNavigate();
      const { colorData } = props;
      const location = useLocation();
      const cab = location.state?.cab; 
      const description = location.state?.description; 
      const fuelTypes = cab?.fuel_type ? cab.fuel_type.split(', ') : [];
// console.log("cab",cab)
      

  const images = [
    {
      src: "https://d21buns5ku92am.cloudfront.net/69358/images/555234-LYNKCO_02_Exterior_01_hires-7e3745-large-1728330344.jpg", // Car 2
      alt: "Car 1",
    },
    {
      src: "https://d21buns5ku92am.cloudfront.net/69358/images/564159-Lynk%20%26%20Co%2001_rear_45_high_onyxblack-63f3c5-large-1731924179.jpg", // Car 3
      alt: "Car 2",
    },
    {
      src: "https://d21buns5ku92am.cloudfront.net/69358/images/555234-LYNKCO_02_Exterior_01_hires-7e3745-large-1728330344.jpg", // Car 2
      alt: "Car 3",
    },
  ];

  const handleBackArrowClick = () => {
    // console.log("backNavigationData?.navigationKey",backNavigationData?.navigationKey)
    if (backNavigationData) {
      if (backNavigationData?.navigationKey == "Cab_List_Page") {
        dispatch({
          type: "SET_SHOW_CART",
          payload: { status: false }
        });
        navigate(-1);
        
      }else {
        navigate(-1);
      }
    }
  };

  const galleryImages = cab?.gallery ? JSON.parse(cab.gallery) : [];
  return (
    <div id="temp22">
      <div className="main-temaplt" id="temp22">
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-12 wtemplate py-4"
              style={{ minHeight: "100vh", background:"#000", color:"#fff" }}
            >
              <h1 style={{ textAlign: "center" }}>{cab?.product_name}</h1>
              {/*  */}
              <div style={{overflow:"scroll",padding:'1rem 0 6rem 0'}}>
                <div className="relative">
             
               { galleryImages.length !== 0 ?  <Carousel
                    selectedItem={currentImage}
                    onChange={(index) => setCurrentImage(index)}
                    infiniteLoop
                    showThumbs={true} 
                    showStatus={true} 
                    showIndicators={false} 
                    emulateTouch
                    dynamicHeight={false}
                    swipeable
                  >
                 {galleryImages.map((image, index) => (
            <div key={index} style={{maxHeight:"350px"}}>
              <img
                className="object-cover"
                src={`${environmentVariables?.s3UrlBackendUrl}${image}`} 
                alt={`Gallery Image ${index + 1}`}
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          ))}
                  </Carousel> : <img src={cabFrame} style={{width:"100%",height:"auto"}}/>}
                </div>
                <div
                  style={{fontSize:"20px",position:"absolute",top:"21px",left:"21px"}}>
                  <i class="fa-solid fa-arrow-left" onClick={handleBackArrowClick} style={{ cursor: "pointer"}}></i>
                </div>

                
                
                <div className="cabDtlBox">
                {cab?.self_driver === 1 &&    <p className="cabPrice-dtl"> <span>₹{cab?.self_driver_price}</span>/ day</p> }
                
                <div className="cabDesc-dtl">
                  {/* This is this cab's description. It has 5 seats. It is of white
                  color. */}
                  <h3>Description</h3>
                 <p> {(cab && cab.product_description) ? cab.product_description : "No description available."}</p>
                  {/* <p className="text-white" dangerouslySetInnerHTML={{ __html: description?.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p> */}
                </div>

                <div className="cabSpecs-dtl">
                  <h3>Specifications</h3>
                  <ul>
                  {cab?.product_seator && <li className="cabSpecBox"> <img src={cabSeat} /> <span> {cab?.product_seator} Seater</span></li>}
                  {fuelTypes.includes('petrol') && (<li className="cabSpecBox"><img src={cabPetrol} alt="Petrol" /><span>Petrol</span></li>)}
                  {fuelTypes.includes('el') && (<li className="cabSpecBox"><img src={cabElectric} alt="Electric" /><span>Electric</span></li>)}
                  {fuelTypes.includes('cng') && (<li className="cabSpecBox"><img src={cabCng} alt="Petrol" /><span>CNG</span></li>)}
                  {fuelTypes.includes('diesel') && (<li className="cabSpecBox"><img src={cabDiesel} alt="Petrol" /><span>Diesel</span></li>)}
                  {cab?.gear_type == "automatic" ? <li className="cabSpecBox"> <img src={cabAutomatic} /> <span>Automatic</span></li> : <li className="cabSpecBox"> <img src={cabManual} /> <span>Manual</span></li> }
                  {/* {cab?.product_seator && <li className="cabSpecBox"> <img src={cabSeat} /> <span> {cab?.product_seator} Seater</span></li>} */}
                  </ul>
                </div>
                </div>
                {/* <div className=" bg-black py-4 fixed bottom-0 spacing w-[450px] text-center ContactSupplier"> */}
                  {/* <button
                    className=" text-xl rounded text-white w-full font-normal font-Tajawal pt-3  pb-3 px-4"
                    style={{ width: "100%", background:"#edae10" }}
                  >
                    Enquire Now
                  </button> */}
                {/* </div> */}
                {/* <div >
                    <Drawer style={{ width: "520px" }}
                      duration={150}
                      hideScrollbars={true}
                      onClose={closeDrawer}
                      isVisible={isVisible}
                      className="drawer">
                      <BottomDrawer closeDrawer={closeDrawer} onSubmit={handleFormSubmit} selectedItem={productDetails} />
                    </Drawer>
                  </div> */}
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CabDetailPage;
