import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { environmentVariables } from "../../../config/env.config";
import { toast } from "react-toastify";
import plinkimg from "../../../Images/linkqr.png";
import ShareVector from "../../../Images/BlackShare.png";

import Theme1Template from "../../Templates/Resumethemes/theme1/Theme";
import Theme2Template from "../../Templates/Resumethemes/theme2/Theme2Template";
import Theme3Template from "../../Templates/Resumethemes/theme3/Theme3Template";
import Theme4Template from "../../Templates/Resumethemes/theme4/Theme4Template";
import Theme5Template from "../../Templates/Resumethemes/theme5/Theme5Template";
import Theme6Template from "../../Templates/Resumethemes/theme6/Theme6Template";
import Theme7Template from "../../Templates/Resumethemes/theme7/Theme7Template";
import CircularLoader from "../../Components/CircularLoader/CircularLoader";
import { useNavigate, useLocation } from "react-router";
import { userContext } from "../../../context/userContext";
import axios from "axios";
import "./MobileStyle.css";
import Theme8Template from "../../Templates/Resumethemes/theme8/Theme8Template";
import Theme9Template from "../../Templates/Resumethemes/theme9/Theme9Template";
import Theme10Template from "../../Templates/Resumethemes/theme10/Theme10Template";
import Resume1 from "./Resume1";
import Resume2 from "./Resume2";
import Resume3 from "./Resume3";
import Resume4 from "./Resume4";
import Resume5 from "./Resume5";
import Resume6 from "./Resume6";
// import Resume7 from "./Resume7";

import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  EmailShareButton,
} from "react-share";
import {
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  TelegramIcon,
  EmailIcon,
} from "react-share";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";


// import Resume1 from "Backend/BusinessPages/Resume1";

// import ResumeIcon from "./../../../../Images/ResumeLogo.png";
// import phoneIcon from "./../../../../Images/PhoneCallingRounded.png";
// import MessageIcon from "./../../../../Images/Letter.png";
// import MapIcon from "./../../../../Images/MapPoint.png";

const ShareIconWrapper = styled.div`
padding: 0 20px;
`;
const ShareIcon = styled.img`
cursor:pointer;
`;
const ShareContainer = styled.div`
background-color:#fff;
position:absolute;
padding: 10px 15px;
border-radius:10px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
top:-55px;
left:70px;
`;

const SharesingleIcon = styled.div`
padding: 0 5px;
`;

const PreviewParaGraph = styled.p`
position:relative;
display:flex;
justify-content: center;

`;

const ResumeViewThem = (props) => {
  // console.log("ResumeViewThem",props.formikData.values)
  const { userData } = useContext(userContext);
  const baseUrl = environmentVariables?.apiUrl;
  const frnturl = environmentVariables?.frntUrl;
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [bannerFile, setBannerFile] = useState(null);

  const ResumeIcon = "";
  const phoneIcon = "";
  const MessageIcon = "";
  const MapIcon = "";

  const ReduxObject = useSelector((state) => state.user?.userObject);
  // const [candidateName,setCandidateName]=useState(ReduxObject?.resumeObj?.candidate_name);

  const Rtheme = ReduxObject?.SelectResumeId;
  const [chooseResume, setChooseResume] = useState("");

  useEffect(() => {
    setChooseResume(ReduxObject?.SelectResumeId);
  }, []);

  // console.log(Rtheme,"RthemeRthemeRthemeRtheme")

  const [activeTab, setActiveTab] = useState(1);
  const [loadingSavePreview, setLoadingSavePreview] = useState(false);
  const [order, setOrder] = useState(null);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [businessId, setBusinessId] = useState();
  let location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    setBusinessId(id); // Output the value of 'id'
  }, [location.search]);

  const toggleShareOptions = () => {
    setShowShareOptions(!showShareOptions);
  };

  useEffect(() => {
    if (props?.logoImageUrl.includes("base64")) {
      const base64WithoutPrefix = props?.logoImageUrl.replace(
        /^data:image\/[a-z]+;base64,/,
        ""
      );
      const byteCharacters = atob(base64WithoutPrefix);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      const blob = new Blob([byteArray], { type: "image/png" });

      const convertedFile = new File([blob], "filename.png", {
        type: "image/png",
      });

      setFile(convertedFile);
    } else {
      setFile(props?.logoImageUrl);
    }
  }, [props]);

  useEffect(() => {
    if (props?.bannerImageUrl.includes("base64")) {
      const base64WithoutPrefix = props?.bannerImageUrl.replace(
        /^data:image\/[a-z]+;base64,/,
        ""
      );
      const byteCharacters = atob(base64WithoutPrefix);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      const blob = new Blob([byteArray], { type: "image/png" });

      const convertedFile = new File([blob], "filename.png", {
        type: "image/png",
      });

      setBannerFile(convertedFile);
    } else {
      setBannerFile(props?.bannerImageUrl);
    }
  }, [props]);

  const getTemplate = () => {
    const Rtheme1 = ReduxObject?.SelectResumeId;
    // console.log("asdjlaksjdlka",Rtheme1)
    switch (Rtheme) {
      case "Resume_1":
        return (
          <Resume1
            mobilePreviewLoading={props?.mobilePreviewLoading}
            isLoadingSubmit={props?.isLoadingSubmit}
            handleSubmit={props?.handleSubmit}
            activeTab={props?.activeTab}
            existingGalleryImages={props?.existingGalleryImages}
            galleryView={props?.galleryView}
            businessObj={props?.businessObj}
            title={props?.title}
            formikData={props?.formikData}
            themeColor={props?.themeColor}
            themeNumber={props?.themeNumber}
            designation={props?.designation}
            subTitle={props?.subTitle}
            description={props?.description}
            bannerImageUrl={props?.bannerImageUrl}
            logoImageUrl={props?.logoImageUrl}
            contactInfo={props?.contactInfo}
            businessHourObject={props?.businessHourObject}
            socialMediaData={props?.socialMediaData}
            skillArray={props?.skillArray}
            additionaldetailArray={props?.additinaldetailArray}
            achievementArray={props?.achievementArray}
            projectArray={props?.projectArray}
            certificationArray={props?.certificationArray}
            gallaryObject={props?.gallaryObject}
            galleryData={props?.galleryData}
            onState={props?.onState}
            servicesArray={props?.servicesArray}
            appointmentArray={props?.appointmentArray}
            toolsArray={props?.toolsArray}
            languageArray={props?.languageArray}
            workExpArray={props?.workExpArray}
          />
        );
      case "Resume_2":
        return (
          <Resume2
          mobilePreviewLoading={props?.mobilePreviewLoading}
          isLoadingSubmit={props?.isLoadingSubmit}
          handleSubmit={props?.handleSubmit}
          activeTab={props?.activeTab}
          existingGalleryImages={props?.existingGalleryImages}
          galleryView={props?.galleryView}
          businessObj={props?.businessObj}
          title={props?.title}
          formikData={props?.formikData}
          themeColor={props?.themeColor}
          themeNumber={props?.themeNumber}
          designation={props?.designation}
          subTitle={props?.subTitle}
          description={props?.description}
          bannerImageUrl={props?.bannerImageUrl}
          logoImageUrl={props?.logoImageUrl}
          contactInfo={props?.contactInfo}
          businessHourObject={props?.businessHourObject}
          socialMediaData={props?.socialMediaData}
          skillArray={props?.skillArray}
          additionaldetailArray={props?.additinaldetailArray}
          achievementArray={props?.achievementArray}
          projectArray={props?.projectArray}
          certificationArray={props?.certificationArray}
          gallaryObject={props?.gallaryObject}
          galleryData={props?.galleryData}
          onState={props?.onState}
          servicesArray={props?.servicesArray}
          appointmentArray={props?.appointmentArray}
          toolsArray={props?.toolsArray}
          languageArray={props?.languageArray}
          workExpArray={props?.workExpArray}
        />
        );
        case "Resume_3":
          return (
            <Resume3
              mobilePreviewLoading={props?.mobilePreviewLoading}
              isLoadingSubmit={props?.isLoadingSubmit}
              handleSubmit={props?.handleSubmit}
              activeTab={props?.activeTab}
              existingGalleryImages={props?.existingGalleryImages}
              galleryView={props?.galleryView}
              businessObj={props?.businessObj}
              title={props?.title}
              formikData={props?.formikData}
              themeColor={props?.themeColor}
              themeNumber={props?.themeNumber}
              designation={props?.designation}
              subTitle={props?.subTitle}
              description={props?.description}
              bannerImageUrl={props?.bannerImageUrl}
              logoImageUrl={props?.logoImageUrl}
              contactInfo={props?.contactInfo}
              businessHourObject={props?.businessHourObject}
              socialMediaData={props?.socialMediaData}
              skillArray={props?.skillArray}
              additionaldetailArray={props?.additinaldetailArray}
              achievementArray={props?.achievementArray}
              projectArray={props?.projectArray}
              certificationArray={props?.certificationArray}
              gallaryObject={props?.gallaryObject}
              galleryData={props?.galleryData}
              onState={props?.onState}
              servicesArray={props?.servicesArray}
              appointmentArray={props?.appointmentArray}
              toolsArray={props?.toolsArray}
              languageArray={props?.languageArray}
              workExpArray={props?.workExpArray}
            />
          );
           case "Resume_4":
          return (
            <Resume4
            mobilePreviewLoading={props?.mobilePreviewLoading}
            isLoadingSubmit={props?.isLoadingSubmit}
            handleSubmit={props?.handleSubmit}
            activeTab={props?.activeTab}
            existingGalleryImages={props?.existingGalleryImages}
            galleryView={props?.galleryView}
            businessObj={props?.businessObj}
            title={props?.title}
            formikData={props?.formikData}
            themeColor={props?.themeColor}
            themeNumber={props?.themeNumber}
            designation={props?.designation}
            subTitle={props?.subTitle}
            description={props?.description}
            bannerImageUrl={props?.bannerImageUrl}
            logoImageUrl={props?.logoImageUrl}
            contactInfo={props?.contactInfo}
            businessHourObject={props?.businessHourObject}
            socialMediaData={props?.socialMediaData}
            skillArray={props?.skillArray}
            additionaldetailArray={props?.additinaldetailArray}
            achievementArray={props?.achievementArray}
            projectArray={props?.projectArray}
            certificationArray={props?.certificationArray}
            gallaryObject={props?.gallaryObject}
            galleryData={props?.galleryData}
            onState={props?.onState}
            servicesArray={props?.servicesArray}
            appointmentArray={props?.appointmentArray}
            toolsArray={props?.toolsArray}
            languageArray={props?.languageArray}
            workExpArray={props?.workExpArray}
          />
          ); 
          case "Resume_5":
          return (
            <Resume5
            mobilePreviewLoading={props?.mobilePreviewLoading}
            isLoadingSubmit={props?.isLoadingSubmit}
            handleSubmit={props?.handleSubmit}
            activeTab={props?.activeTab}
            existingGalleryImages={props?.existingGalleryImages}
            galleryView={props?.galleryView}
            businessObj={props?.businessObj}
            title={props?.title}
            formikData={props?.formikData}
            themeColor={props?.themeColor}
            themeNumber={props?.themeNumber}
            designation={props?.designation}
            subTitle={props?.subTitle}
            description={props?.description}
            bannerImageUrl={props?.bannerImageUrl}
            logoImageUrl={props?.logoImageUrl}
            contactInfo={props?.contactInfo}
            businessHourObject={props?.businessHourObject}
            socialMediaData={props?.socialMediaData}
            skillArray={props?.skillArray}
            additionaldetailArray={props?.additinaldetailArray}
            achievementArray={props?.achievementArray}
            projectArray={props?.projectArray}
            certificationArray={props?.certificationArray}
            gallaryObject={props?.gallaryObject}
            galleryData={props?.galleryData}
            onState={props?.onState}
            servicesArray={props?.servicesArray}
            appointmentArray={props?.appointmentArray}
            toolsArray={props?.toolsArray}
            languageArray={props?.languageArray}
            workExpArray={props?.workExpArray}
          />
          );
          case "Resume_6":
          return (
            <Resume6
            mobilePreviewLoading={props?.mobilePreviewLoading}
            isLoadingSubmit={props?.isLoadingSubmit}
            handleSubmit={props?.handleSubmit}
            activeTab={props?.activeTab}
            existingGalleryImages={props?.existingGalleryImages}
            galleryView={props?.galleryView}
            businessObj={props?.businessObj}
            title={props?.title}
            formikData={props?.formikData}
            themeColor={props?.themeColor}
            themeNumber={props?.themeNumber}
            designation={props?.designation}
            subTitle={props?.subTitle}
            description={props?.description}
            bannerImageUrl={props?.bannerImageUrl}
            logoImageUrl={props?.logoImageUrl}
            contactInfo={props?.contactInfo}
            businessHourObject={props?.businessHourObject}
            socialMediaData={props?.socialMediaData}
            skillArray={props?.skillArray}
            additionaldetailArray={props?.additinaldetailArray}
            achievementArray={props?.achievementArray}
            projectArray={props?.projectArray}
            certificationArray={props?.certificationArray}
            gallaryObject={props?.gallaryObject}
            galleryData={props?.galleryData}
            onState={props?.onState}
            servicesArray={props?.servicesArray}
            appointmentArray={props?.appointmentArray}
            toolsArray={props?.toolsArray}
            languageArray={props?.languageArray}
            workExpArray={props?.workExpArray}
          />
          );
          // case "Resume_7":
          // return (
          //   <Resume7
          //   mobilePreviewLoading={props?.mobilePreviewLoading}
          //   isLoadingSubmit={props?.isLoadingSubmit}
          //   handleSubmit={props?.handleSubmit}
          //   activeTab={props?.activeTab}
          //   existingGalleryImages={props?.existingGalleryImages}
          //   galleryView={props?.galleryView}
          //   businessObj={props?.businessObj}
          //   title={props?.title}
          //   formikData={props?.formikData}
          //   themeColor={props?.themeColor}
          //   themeNumber={props?.themeNumber}
          //   designation={props?.designation}
          //   subTitle={props?.subTitle}
          //   description={props?.description}
          //   bannerImageUrl={props?.bannerImageUrl}
          //   logoImageUrl={props?.logoImageUrl}
          //   contactInfo={props?.contactInfo}
          //   businessHourObject={props?.businessHourObject}
          //   socialMediaData={props?.socialMediaData}
          //   skillArray={props?.skillArray}
          //   additionaldetailArray={props?.additinaldetailArray}
          //   achievementArray={props?.achievementArray}
          //   projectArray={props?.projectArray}
          //   certificationArray={props?.certificationArray}
          //   gallaryObject={props?.gallaryObject}
          //   galleryData={props?.galleryData}
          //   onState={props?.onState}
          //   servicesArray={props?.servicesArray}
          //   appointmentArray={props?.appointmentArray}
          //   toolsArray={props?.toolsArray}
          //   languageArray={props?.languageArray}
          //   workExpArray={props?.workExpArray}
          // />
          // );
      default:
        return <Resume1 />;
    }
  };

  const getStaticTemplate = (theme) => {
    switch (theme) {
      case "theme6":
        return <Theme1Template />;
      case "theme5":
        return <Theme2Template />;
      case "theme3":
        return <Theme3Template />;
      case "theme4":
        return <Theme4Template />;
      case "theme2":
        return <Theme5Template />;
      case "theme1":
        return <Theme6Template />;
      case "theme7":
        return <Theme7Template />;
      case "theme8":
        return <Theme8Template />;
      case "theme9":
        return <Theme9Template />;
      case "theme10":
        return <Theme10Template />;
    }
  };
  const handleTabChange = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  const changeTimeFormat = (time24) => {
    // Split the time string into hours and minutes
    const [hours, minutes] = time24.split(":").map(Number);

    // Create a Date object with today's date
    const date = new Date();

    // Set the hours and minutes to the Date object
    date.setHours(hours);
    date.setMinutes(minutes);

    // Format the time in AM/PM format
    const time12 = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return time12;
  };

  const handleShare = () => {
    setShowShareOptions(!showShareOptions);
  };

  const handleShareOnPlatform = (platform) => {
    // const resumePreviewLink = `http://localhost:3000/resume-preview/${businessId ?? ""}`;
    const resumePreviewLink = `${frnturl}resume-preview/${businessId ?? ""}`
    let message = "Check out my resume attached here:\n";
    const fullMessage = `${message} ${resumePreviewLink}`;
    let shareUrl = '';
    const shareMessage = message + resumePreviewLink;
    const shareMessage2 = `${message} ${resumePreviewLink}`;

    switch (platform) {
      case 'facebook':
        // shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(resumePreviewLink)}`;
        // shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(resumePreviewLink)}&quote=${encodeURIComponent(message)}`;
        FB.ui({
          method: 'share',
          href: resumePreviewLink,
          quote: shareMessage2
      });
        break;
      case 'linkedin':
        // shareUrl = `http://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(resumePreviewLink)}`;
        // shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(resumePreviewLink)}&summary=${encodeURIComponent(message)}`;
        // break;
        navigator.clipboard.writeText(fullMessage).then(() => {
          alert("Message copied to clipboard! Please paste it in LinkedIn messages.");
          window.open("https://www.linkedin.com/messaging", "_blank"); // Opens LinkedIn Messaging
        }).catch(err => {
          console.error('Could not copy text: ', err);
        });
        break;
      case 'whatsapp':
        // shareUrl = `http://api.whatsapp.com/send?text=${encodeURIComponent(resumePreviewLink)}`;
        shareUrl = `http://api.whatsapp.com/send?text=${encodeURIComponent(message + " " +resumePreviewLink)}`;
        break;
      case 'telegram':
        const shareMessage = encodeURIComponent(`${message}\n`);
        const telegramUrl = `tg://msg_url?url=${encodeURIComponent(resumePreviewLink)}&text=${shareMessage}`;
        window.open(telegramUrl);
        break;
      case 'email':
        shareUrl = `mailto:?subject=Check%20out%20my%20resume&body=${encodeURIComponent(resumePreviewLink)}`;
        // shareUrl = `mailto:?subject=Check%20out%20my%20resume&body=${encodeURIComponent(message + " " + resumePreviewLink)}`;
        break;
      default:
        break;
    }
  


  if (shareUrl) {
    window.open(shareUrl, "_blank"); // Open the selected platform in a new window
  }
};

  const getImage = (image) => {
    if (image.includes("base64")) {
      return image;
    } else {
      return `${environmentVariables?.apiUrl}uploads/${image}`;
    }
  };
  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(
        `${frnturl}${
          props?.businessObj?.slug ? props?.businessObj?.slug : ""
        }`
      )
      .then(() => {
        setCopied(true);
        toast.success("link copied", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      })
      .catch((error) => {
        console.error("Failed to copy link: ", error);
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
    toast.success("link copied", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  };

  const handleUpdateBusiness = (e,id,platform) => {
    setLoadingSavePreview(true);
    if (props.themeColor) {
      e.stopPropagation();
      let obj = {
      theme: props.themeColor,
        theme_color: props.themeColor,
        businessId: props?.businessObj?.id,
      };
      const formData = new FormData();
      // formData.append('resume_color', props.themeColor);
      formData.append("created_by", userData?.id);
      formData.append("business_id", businessId);
      // formData.append('business_id', 536);
      formData.append("logo", file);
      formData.append("banner", bannerFile);
      formData.append("city", props.formikData?.values?.address);
      formData.append("designation", props.formikData?.values?.designation);
      formData.append("fathername", props.formikData?.values?.fathername);
      formData.append("summary", props.formikData?.values?.description);
      formData.append("email", props.formikData?.values?.email);
      formData.append("fullName", props.formikData?.values?.fullName);
      formData.append("userdob", props.formikData?.values?.userdob);
      formData.append("phone", props.formikData?.values?.phone);
      formData.append("maritalstatus", props.formikData?.values?.maritalstatus);
      formData.append("linkedIn", props.formikData?.values?.linkedIn);
      formData.append("resume_color", Rtheme ? Rtheme.toLowerCase().replace('_', '') : "resume1");


      formData.append(
        "is_education_is_enabled",
        props?.onState?.isExperience ? 1 : 0
      );
      formData.append(
        "educationcontent",
        JSON.stringify(props?.socialMediaData)
      );
      formData.append("is_project_is_enabled", props?.onState?.project ? 1 : 0);
      formData.append("projectcontent", JSON.stringify(props?.projectArray));
      formData.append("is_skills_is_enabled", props?.onState?.services ? 1 : 0);
      formData.append("skillscontent", JSON.stringify(props?.skillArray));
      formData.append(
        "isEnabledAdddetails",
        props?.onState?.adddetails ? 1 : 0
      );
      formData.append(
        "is_achievement_is_enabled",
        props?.onState?.achievement ? 1 : 0
      );
      formData.append(
        "is_prsnldetail_is_enabled",
        props?.onState?.prsnldetail ? 1 : 0
      );
      formData.append(
        "additionaldetailscontent",
        JSON.stringify(props?.additinaldetailArray)
      );
      formData.append(
        "achievementcontent",
        JSON.stringify(props?.achievementArray)
      );
      formData.append("is_tools_is_enabled", props?.onState?.tools ? 1 : 0);
      formData.append("toolscontent", JSON.stringify(props?.toolsArray));
      formData.append(
        "is_language_is_enabled",
        props?.onState?.language ? 1 : 0
      );
      formData.append("languagecontent", JSON.stringify(props?.languageArray));
      formData.append("is_wrkExp_is_enabled", props?.onState?.workExp ? 1 : 0);
      formData.append("wrkExpcontent", JSON.stringify(props?.workExpArray));
      formData.append(
        "is_certification_is_enabled",
        props?.onState?.certification ? 1 : 0
      );
      formData.append(
        "certificationcontent",
        JSON.stringify(props?.certificationArray)
      );

      axios
        .post(`${baseUrl}api/v1/resume/resumeDetail`, formData, {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((res) => {
          if (res?.data?.success) {
            // console.log("111111")

            if(id==0){
              // console.log("22222")
              window.open(`/resume-preview/${businessId ?? ""}`, "_blank");
              setLoadingSavePreview(false);
              props.setThemeColor(selectedTheme);
            }else{
             
              // https://uat.businessbay.io/
              const resumePreviewLink = `${baseUrl}resume-preview/${businessId ?? ""}`
              let message = "Check out my resume attached here:\n";
              let shareUrl = '';
              const shareMessage = message + resumePreviewLink;
              const shareMessage2 = `${message} ${resumePreviewLink}`;
              switch (platform) {
                case 'facebook':
                  // shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(resumePreviewLink)}`;
                  // shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(resumePreviewLink)}&quote=${encodeURIComponent(message)}`;
                  FB.ui({
                    method: 'share',
                    href: resumePreviewLink,
                    quote: shareMessage2
                });
                  break;
                case 'linkedin':
                  // shareUrl = `http://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(resumePreviewLink)}`;
                  shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(resumePreviewLink)}&summary=${encodeURIComponent(message)}`;
                  break;
                case 'whatsapp':
                  // shareUrl = `http://api.whatsapp.com/send?text=${encodeURIComponent(resumePreviewLink)}`;
                  shareUrl = `http://api.whatsapp.com/send?text=${encodeURIComponent(message + " " +resumePreviewLink)}`;
                  break;
                case 'telegram':
                  const shareMessage = encodeURIComponent(`${message}\n`);
                  const telegramUrl = `tg://msg_url?url=${encodeURIComponent(resumePreviewLink)}&text=${shareMessage}`;
                  window.open(telegramUrl);
                  break;
                case 'email':
                  shareUrl = `mailto:?subject=Check%20out%20my%20resume&body=${encodeURIComponent(resumePreviewLink)}`;
                  // shareUrl = `mailto:?subject=Check%20out%20my%20resume&body=${encodeURIComponent(message + " " + resumePreviewLink)}`;
                  break;
                default:
                  break;
                  
              }
              setLoadingSavePreview(false);
              if (shareUrl) {
                window.open(shareUrl, "_blank");
                setLoadingSavePreview(false); // Open the selected platform in a new window
                handleShare()
              }
            }
            
            
          }
        })
        .catch((err) => {
          setLoadingSavePreview(false);
          toast.error(err?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        });
    } else if (props.activeTab == "tab2") {
      props.handleSubmit(e);

      setLoadingSavePreview(false);
    } else if (props.activeTab == "tab3") {
      props.handleSubmit(e);
      setLoadingSavePreview(false);
    } else {
      window.open(`/${businessId ?? ""}`, "_blank");
    }
  };

  return (
    <>
      <div className="col-12 col-lg-4">
        <div
          className="tile-aaa"
          // style={{ position: "absolute" }}
        >
          <div className="privewmobile">
            <div className="viewlink border-bottom d-none">
              <div className="linkl">
                <p>{`${frnturl}${
                  props?.businessObj?.slug ? props?.businessObj?.slug : ""
                }`}</p>
              </div>
              <div className="linkr">
                <ul className="linkandcopy">
                  <li onClick={handleCopyClick}>
                    <i class="fa-solid fa-copy"></i>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={`${frnturl}${
                        props?.businessObj?.slug ? props?.businessObj?.slug : ""
                      }`}
                    >
                      <i class="fa-solid fa-arrow-up-right-from-square"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="viewlink  preview_nav d-none">
              <div className="linkl">
                <ul className="btntabhere">
                  <li
                    onClick={() => handleTabChange(1)}
                    className={activeTab === 1 ? "active" : ""}
                  >
                    <i class="fa-solid fa-eye"></i>
                  </li>
                  <li
                    onClick={() => handleTabChange(2)}
                    className={activeTab === 2 ? "active" : ""}
                  >
                    <i class="fa-solid fa-qrcode"></i>
                  </li>
                </ul>
              </div>
              <div className="linkr">
                <button className="btn btn-save">Save</button>
              </div>
            </div>
            {activeTab === 1 &&
              (props.mobilePreviewLoading ? (
                <CircularLoader size={40} />
              ) : (
                <div className="movileview LL308 MobileSaveandpreviewHeight">
                  <>
                    <div class="device-header L590"></div>
                    {/* <Resume1></Resume1> */}
                    {getTemplate(chooseResume)}
                  </>
                </div>
              ))}

            {activeTab === 2 && (
              <div>
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/QR_Code_Example.svg/1200px-QR_Code_Example.svg.png"
                  className="mx-auto d-block img-fluid"
                />
              </div>
            )}
          </div>
          {props.activeTab == "tab1" &&
          (props.isChecking == null || props.isChecking == undefined) ? (
            <></>
          ) : (
            <>
              {loadingSavePreview || props.isLoadingSubmit ? (
                <CircularLoader />
              ) : (
                <>
                  {props.mobilePreviewLoading ? (
                    <></>
                  ) : (
                    <PreviewParaGraph className="linkcopunextwindiow" >
                      <a
                        target="_blank"
                        style={{ cursor: "pointer", display:"flex" , alignItems:"center"}}
                        onClick={(e) => {
                          handleUpdateBusiness(e,0,"preview");
                        }}
                      >
                         Resume Preview <img loading="lazy" style={{paddingLeft:"5px", width:"17px", height:"17px"}} src={plinkimg} /> 

                     
                      </a>
                      {/* <div style={{cursor:"pointer"}} onClick={handleShare}>
                        <i className="fa-solid fa-share"></i>
                      </div> */}
                         <ShareIconWrapper >
                          
                            <ShareIcon src={ShareVector}  onClick={handleShare}/>
                          </ShareIconWrapper>
                       
                      {showShareOptions && (
                        <div className="share-options-modal">
                        
                          {/* <div className="share-options-header">
                          
                            <button onClick={handleShare}>Close</button>
                          </div> */}
                          <ShareContainer className="share-options-list" style={{display:"flex"}}>
                            <SharesingleIcon  onClick={(e) => {
                                  handleUpdateBusiness(e,1,"linkedin");}}>
                              <LinkedinIcon size={32} round style={{cursor:"pointer"}} />
                            </SharesingleIcon>
                            <SharesingleIcon onClick={(e) => {
                                  handleUpdateBusiness(e,1,"whatsapp");}}>
                              <WhatsappIcon size={32} round style={{cursor:"pointer"}} />
                            </SharesingleIcon>
                            <SharesingleIcon  onClick={(e) => {
                                  handleUpdateBusiness(e,1,"telegram");}}>
                              <TelegramIcon size={32} round style={{cursor:"pointer"}} />
                            </SharesingleIcon>
                            <SharesingleIcon  onClick={(e) => {
                                  handleUpdateBusiness(e,1,"email");}}>
                              <EmailIcon size={32} round style={{cursor:"pointer"}} />
                            </SharesingleIcon>
                            {/* <SharesingleIcon  onClick={(e) => {
                                  handleUpdateBusiness(e,1,"facebook");}}>
                              <FacebookIcon size={32} round style={{cursor:"pointer"}} />
                            </SharesingleIcon> */}
                          </ShareContainer>
                        </div>
                      )}
                    </PreviewParaGraph>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default ResumeViewThem;
