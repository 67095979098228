import React, { useEffect, useCallback, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import { environmentVariables } from '../../../../config/env.config';
import cartImage from "../../../../Images/shopping-cart.png";
import Drawer from "react-bottom-drawer";
import BottomDrawer from './BottomDrawer';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ProductDetailpage = () => {
  const { t } = useTranslation();
  const [productDetails, setProductDetails] = useState(null);
  const [imagegallery, setGalleryImage] = useState([]);
  const [productImg, setProductImg] = useState('');
  const baseUrl = environmentVariables?.apiUrl;
  const [isVisible, setIsVisible] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ReduxObject = useSelector(state => state.productCartItem?.productCartItemData);
  const CartReduxObject = useSelector(state => state.showCart?.showCartPage);
  const backNavigationData = useSelector(state => state.navigation);
  // console.log("CartReduxObject===>>>", backNavigationData);
  const openDrawer = useCallback(() => {
    setIsVisible(true);
  }, []);
  const closeDrawer = useCallback(() => setIsVisible(false), []);

  let { id } = useParams();

  const fetchProductDetails = () => {
    let data = JSON.stringify({
      "pid": id
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}api/v1/business/getProductDetailsById`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        if (response?.data?.success) {
          setProductImg(response?.data?.data?.image)
          setProductDetails(response?.data?.data);
          setGalleryImage(JSON.parse(response?.data?.data.gallery))
          console.log(response?.data?.data)
        } else {

        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  // console.log("Image Gallery", imagegallery, typeof (imagegallery));
  useEffect(() => {
    fetchProductDetails();
  }, [id]);

  const handleFormSubmit = (values) => {
    const requestData = {
      countryCode: values.countryCode,
      phone: values.phone,
      business_id: values.item.business_id,
      product_id: values.item.id,
      name: values.item.product_name,
      message: values.description,
      enquiry_from: 0,
      captchaAns: values?.captchaAns
    };

    axios.post(`${baseUrl}api/v1/business/create_minimkt_enquiry`, requestData)
      .then((response) => {
        // handle success
        toast.success(t("Enquiry_submitted_successfully"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        let contactInfoData = !!productDetails?.businessDetails?.contactInfos ? JSON.parse(productDetails?.businessDetails?.contactInfos?.content) : "";
        const phoneNumber = !!contactInfoData ? contactInfoData.contactInfoPhone : "";
        const { product_name, product_price, product_service } = values.item;

        const message = encodeURIComponent(`Hi, I'd like to Enquire about this product\nProduct details:\nName: ${product_name}\nPrice: ${product_price}\nService: ${product_service}`);
        const apiUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
        window.open(apiUrl, '_blank');
      })
      .catch((error) => {
        // handle error
        console.log(error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  const handleBackArrowClick = () => {
    // console.log("backNavigationData?.navigationKey",backNavigationData?.navigationKey)
    if(backNavigationData){
      if(backNavigationData?.navigationKey == "List_Page"){
        navigate(-1);   
        setShowCart(false);
        dispatch({
          type: 'SET_SHOW_CART',
          payload: { status: false }
        });
      }else if(backNavigationData?.navigationKey == "Cart_Page"){
        setShowCart(true);
        dispatch({
          type: 'SET_SHOW_CART',
          payload: { status: true }
        });
        navigate(-1);
      }else{
        navigate(-1);
      }
    }   
  };

  const handleAddToCart = (menuItem) => {
          
          const newItem = {
            id: menuItem.id,
            name: menuItem.product_name,
            price: menuItem.product_price,
            image: `${environmentVariables.s3UrlBackendUrl}${menuItem.image}`,
            quantity: 1,
            currencySymbol: productDetails?.businessDetails?.currency_symbol,
          };
        
          dispatch({
            type: 'SET_PRODUCT_CART_ITEM',
            payload: { newItem }, 
          });
      };
      
      const handleRemoveToCart = (itemId) => {      
          dispatch({
            type: 'REMOVE_PRODUCT_CART_ITEM',
            payload: { id: itemId }
          });
      };

      const handleShowCart = () =>{
        setShowCart(true);
        dispatch({
          type: 'SET_SHOW_CART',
          payload: { status: true }
        });
        navigate(-1);
      }
  
  return (
    <div id="temp20">
      <div className="main-temaplt" id="temp20">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 wtemplate p-4" style={{ minHeight: "100vh" }}>
              {/* <p>Cart Count:{ReduxObject?.length}</p> */}
              <p className="theme20CartP" onClick={()=>handleShowCart()}><img loading="lazy" src={cartImage} style={{width:'25px', height:'25px'}}/><span>{ReduxObject?.length}</span></p>
              {!!productDetails &&
                <div>
                  <div className='relative'>
                    <Carousel>
                      {
                        (imagegallery && imagegallery.length > 0)
                          ? imagegallery.map((gallery, index) => {
                            return (
                              <div key={index}>
                                <img loading="lazy" className="rounded DetailProductImage object-fill" src={`${environmentVariables?.s3UrlBackendUrl + gallery}`} alt={`Gallery image ${index + 1}`} />
                              </div>
                            );
                          })
                          : (
                            <div>
                              <img loading="lazy" className="rounded DetailProductImage object-fill" src={`${environmentVariables?.s3UrlBackendUrl + productImg}`} alt="Default product image" />
                            </div>
                          )
                      }

                    </Carousel>
                  </div>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={handleBackArrowClick}
                  >
                    <i class="fa-solid fa-arrow-left"></i>
                  </div>

                  <div className='text-start font-medium text-2xl mt-2 pt-2  break-all text-detailheading'
                   style={{ wordBreak: "auto-phrase"}}>
                    {productDetails?.product_name}
                  </div>
                  {productDetails?.product_category == 'buynow' && <p>{productDetails?.businessDetails?.currency_symbol}
                  {productDetails?.product_price ? Math.trunc(Number(productDetails?.product_price) * 100) / 100 : null}</p>}

                  <div className="flex justify-between items-center py-2">
                  </div>
                  {!!productDetails?.product_description &&
                    <div
                      className=' text-base text-productDetail text-400 my-2 leading-relaxed text-justify'
                      style={{ paddingBottom: "70px", wordBreak: "break-word" }}
                      dangerouslySetInnerHTML={{
                        __html: productDetails?.product_description.replace(/(?:\r\n|\r|\n)/g, '<br>')
                      }}
                    >
                    </div>
                  }
                  <div className=' bg-white py-4 fixed bottom-0 spacing w-[450px] text-center ContactSupplier border border-white'>
                    {productDetails?.product_category === 'enquiry'?
                      <button className='enquire-now-btn bg-coffee text-xl rounded text-white w-full font-normal font-Tajawal pt-3  pb-3 px-4' onClick={openDrawer} style={{ width: "100%" }}>
                        {t("Enquire_now")}
                      </button>
                      :
                      ReduxObject.some(cartItem => cartItem.id === productDetails?.id) ?
                      <button className="enquire-now-btn bg-coffee text-xl rounded text-white w-full font-normal font-Tajawal pt-3  pb-3 px-4 " onClick={() => handleRemoveToCart(productDetails?.id)}>
                          {t("Added")}
                      </button>
                      :
                      <button className="enquire-now-btn bg-coffee text-xl rounded text-white w-full font-normal font-Tajawal pt-3  pb-3 px-4" onClick={() => handleAddToCart(productDetails)}>
                          {t("Add_to_cart")}
                      </button>
                    }
                  </div>
                  <div >
                    <Drawer style={{ width: "520px" }}
                      duration={150}
                      hideScrollbars={true}
                      onClose={closeDrawer}
                      isVisible={isVisible}
                      className="drawer">
                      <BottomDrawer closeDrawer={closeDrawer} onSubmit={handleFormSubmit} selectedItem={productDetails} />
                    </Drawer>
                  </div>
                  <ToastContainer />
                </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductDetailpage
