import React, { useState } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import locationicon from "../../../../Images/target.png";
import whtsappicon from "../../../../Images/WhatsappIcon2.png";
import {
  ConfirmOrder,
  ConfirmOrderWrapper,
  CustomerDetailText,
  CustomerDetailsWrapper,
  DrawerCloseButton,
  DrawerCloseButtonWrapper,
  InputTextField,
  InputTextFieldWrapper,
  InputTextFieldtextarea,
  TabCustomerDetailsWrapper,
} from "./theme53extstylecomponent";
import PhoneInput from "react-phone-input-2";
import { getcoutryfromlocation, getServicesArray, isValidPhoneNumber } from "../../../../utils/utility";
import CircularLoader from "../../../Components/CircularLoader/CircularLoader";
import { useEffect } from "react";
import {toast } from "react-toastify";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";


const Errormsg = styled.div`
  color: red;
  margin: 0 10px;
  font-size: 12px;
`;

const Overlay = styled.div`
  background-color: #00000042;
  bottom: 60px;
  height: 94vh;
  position: absolute;
  width: 100vw;
  left: 0;
  display: flex;
  justify-content: center;
      align-items: center;
  @media (min-width: 520px) {
    width: 520px;
  }
`;

const CustomerDetails = ({ closeDrawer, onSubmit, loader,customerData, colordata }) => {
  const { t } = useTranslation();
  const [fetchLocationLoader, setFetchLocationLoader] = useState(false);
 const validateDescription = (value) => {
    const maxLength = 255;
    const regex = /^[a-zA-Z0-9 ,.?/()@$'+\n&-]*$/;

    if (value.length > maxLength) {
        return {
            valid: false,
            error: `${t("Description cannot exceed")} ${maxLength} ${t("characters")}.`
        };
    } else if (!regex.test(value)) {
        return {
            valid: false,
            error: `${t("Description_contains_invalid_characters")}`
        };
    } else {
        return {
            valid: true,
            error: ''
        };
    }

}

  const [invalidPhoneErr, setInvalidPhoneErr] = useState("");
  // Define validation schema using Yup
  // const phoneRegExp = /^\d{14}$/;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("Name_required")),
    phone: Yup.string()
      // .matches(phoneRegExp, "Only 10 digit number is allowed")
      .min(10, t("Minimum_10_digits_are_required"))
      .required(t("Phone_number_required")),
    email: Yup.string().email(t("Invalid_email")).required(t("Email_required")),
    address: Yup.string()
    .test(
      "valid-address",
      function (value) {
        if (!value) {
          return this.createError({ message: `${t("Address_required")}` });
        }
        const { valid, error } = validateDescription(value);
        if (!valid) {
          return this.createError({ message: error });
        }
        return true;
      }
    )
    .required(t("Address_required")),
    // city: Yup.string().required('City is required'),
    // state: Yup.string().required('State is required'),
  });
  let cusData = getServicesArray(customerData);
  const initialValues = {
    name: cusData?.name || "",
    phone: cusData?.country_code && cusData?.contact ? `${cusData?.country_code}-${cusData?.contact}` : "",
    email: cusData?.email || "",
    address: "",
  };
  // Initialize Formik with validateOnChange set to true
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnChange: true, // Apply validation on each change
    onSubmit: async (values) => {
      let cloneSubmittedValue = JSON.parse(JSON.stringify(values));
      
      let seperateCountryCodeFromPhone = cloneSubmittedValue?.phone?.split("-");
      if (seperateCountryCodeFromPhone.length > 0) {
        cloneSubmittedValue.phone = seperateCountryCodeFromPhone[1];
        cloneSubmittedValue.countryCode = seperateCountryCodeFromPhone[0];
        await onSubmit(cloneSubmittedValue);
        closeDrawer();
      }
    },
  });

  const handleBlur = (value, phoneData) => {
    phoneData.iso2 = phoneData.countryCode;
    let contactNumber = formik.values.phone;
    if (!!contactNumber) {
      let val = contactNumber?.split("-");
      let valArray = val.length > 0 ? val[1] : "";
      if (valArray == "") {
        setInvalidPhoneErr(t("Please_enter_phone_number"));
      } else if (!isValidPhoneNumber(valArray, phoneData)) {
        setInvalidPhoneErr(` ${t("Invalid_phone_number_for")} ${phoneData.name}`);
      } else {
        setInvalidPhoneErr("");
      }
    }
  };

  const [countryCode, setCountryCode] = useState("in");
  
  const fetchCountryfromlocation = async () => {
    try {
      const countryData = await getcoutryfromlocation();
      // console.log("countryData-==>",countryData);
      setCountryCode(countryData.countryCode.toLowerCase());
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
  };

  useEffect(() => {
    fetchCountryfromlocation()
  }, []);

  //location 

  const fetchAddress = async (latitude, longitude) => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAP_LAT_LNG;
    
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const formattedAddress = data.results[0].formatted_address;
        formik.setFieldValue("address", formattedAddress);
      } else {
        alert(process.env.GOOGLE_MAP_LAT_LNG)
        toast.error(t("Unable_to_fetch_address"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (error) {
      toast.error(t("Failed_to_fetch_address"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleFetchLocation = () => {
    setFetchLocationLoader(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setFetchLocationLoader(false);
          const { latitude, longitude } = position.coords;
          fetchAddress(latitude, longitude);
        },
        (err) => {
          setFetchLocationLoader(false);
          toast.error(t("Unable_to_fetch_location"), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        },
        {
          enableHighAccuracy: true, // Request GPS-level accuracy
          timeout: 5000, // Wait for up to 5 seconds
          maximumAge: 0, // Ensure no cached location is used
        }
      );
    } else {
      setFetchLocationLoader(false);
      toast.error(t("Geolocation_is_not_supported"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  return (
    <Overlay>
      <TabCustomerDetailsWrapper>
        <form onSubmit={formik.handleSubmit}>
          <CustomerDetailsWrapper>
            <CustomerDetailText>{t("Customer_detail")}</CustomerDetailText>
          </CustomerDetailsWrapper>
          <InputTextFieldWrapper>
            <InputTextField
              type="text"
              placeholder={`${t("Full_name")}*`}
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            <Errormsg>
              {" "}
              {formik.errors.name && formik.touched.name && (
                <div>{formik.errors.name}</div>
              )}
            </Errormsg>
          </InputTextFieldWrapper>
          <InputTextFieldWrapper>
            <PhoneInput
              country={countryCode}
              enableSearch={true}
              countryCodeEditable={false}
              value={formik.values.phone}
              onChange={(value, country) => {
                if (country.dialCode) {
                  formik.setFieldValue(
                    "phone",
                    `+${country.dialCode}-${value.substring(
                      country.dialCode.length
                    )}`
                  );
                  setInvalidPhoneErr("");
                } else {
                  formik.setFieldValue("phone", value);
                  setInvalidPhoneErr("");
                }
              }}
              className="customer-info-phone"
              onBlur={(e, country) =>
                handleBlur(e, country) && formik.handleBlur("phone")
              }
              isValid={(inputNumber, country) =>
                isValidPhoneNumber(inputNumber, country)
              }
            />
            {/* <p
              style={{
                fontSize: "12px",
                paddingTop: "3px",
                paddingLeft: "5px",
              }}
            >
              Enter whatsapp no. only
            </p> */}
            <Errormsg>
              {" "}
              {formik.errors.phone && formik.touched.phone && (
                <div>{formik.errors.phone}</div>
              )}
            </Errormsg>
            {invalidPhoneErr && <Errormsg>{invalidPhoneErr}</Errormsg>}
          </InputTextFieldWrapper>
          {/* <InputTextFieldWrapper>
          <InputTextField
            type="number"
            name='phone'
            placeholder='Phone'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
          />
       <Errormsg>   {formik.errors.phone && formik.touched.phone && (
            <div>{formik.errors.phone}</div>
          )}</Errormsg>
        </InputTextFieldWrapper> */}
          <InputTextFieldWrapper>
            <InputTextField
              type="email"
              name="email"
              placeholder={`${t("Email")}*`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            <Errormsg>
              {formik.errors.email && formik.touched.email && (
                <div>{formik.errors.email}</div>
              )}
            </Errormsg>
          </InputTextFieldWrapper>
          <InputTextFieldWrapper style={{
            display: "flex",
            flexDirection: "column-reverse",
            alignItems: "flex-end",
          }}>
            <InputTextFieldtextarea
              rows="2"
              name="address"
              placeholder={`${t("Address")}*`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
            />

            <button
              type="button"
              onClick={handleFetchLocation}
              style={{
                // position: "absolute",
                // right: "36px",
                // top: "68%",
                // transform: "translateY(-50%)",
                // border: "none",
                // background: "transparent",
                // cursor: "pointer",
                background: "transparent",
                cursor: "pointer",
                display: "flex",
                gap: "5px",
                backgroundColor: "#ffffff",
                fontSize: "10px",
                padding: "7px 10px",
                borderRadius: "50px",
                color: "#008000",
                border: "1px solid #00800094",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row-reverse",
              }}
            >
              {!fetchLocationLoader?t("Use_current_location"): <Spinner size="sm"/>}
              <img
                src={locationicon}
                alt="Fetch Location"
                style={{ width: "15px",
                  height: "15px",
                  color: "green"}}
              />
            </button>
            {/* <img loading="lazy" src={locationicon} alt="Location Icon" /> */}
          
          </InputTextFieldWrapper>
          <Errormsg >
              {" "}
              {formik.errors.address && formik.touched.address && (
                <div style={{marginLeft:"10px"}}>{formik.errors.address}</div>
              )}
            </Errormsg>
          {/* <InputTextFieldWrapper>
          <InputTextField
            type='text'
            name='city'
            placeholder='City'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.city}
          />
          <Errormsg>{formik.errors.city && formik.touched.city && (
            <div>{formik.errors.city}</div>
          )}</Errormsg>
        </InputTextFieldWrapper>
        <InputTextFieldWrapper>
          <InputTextField
            name='state'
            placeholder='State'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.state}
          />
         <Errormsg> {formik.errors.state && formik.touched.state && (
            <div>{formik.errors.state}</div>
          )}</Errormsg>
        </InputTextFieldWrapper> */}
          <ConfirmOrderWrapper random={true} dynamicCtaColor={colordata?.dynamicCtaColor}>
            {loader ? (
              <CircularLoader />
            ) : (
              <button
                type="submit"
                disabled={!formik.isValid || !!invalidPhoneErr}
                style={{
                  background: "transparent",
                  border: "none",
                  fontWeight: "700",
                  fontSize: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color:colordata?.dynamicCtaTextColor || "#fff"
                }}
              >
                {/* <img
                  src={whtsappicon}
                  alt="WhatsApp Icon"
                  style={{ marginRight: "5px", width: "40px" }}
                /> */}
                {t("Place_order")}
              </button>
            )}
          </ConfirmOrderWrapper>
          <DrawerCloseButtonWrapper onClick={closeDrawer}>
            <DrawerCloseButton>+</DrawerCloseButton>
          </DrawerCloseButtonWrapper>
        </form>
      </TabCustomerDetailsWrapper>
    </Overlay>
  );
};

export default CustomerDetails;
