import React, { useContext, useEffect, useState, useRef } from "react";
import PageName from "../Utils/PageName";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { businessObjContext } from "../../context/businessObjContext";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import "../Pages/UpdateRequestcardstyle.css";
import { useCallback, useMemo } from "react";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import { useTranslation } from "react-i18next";


const LandingPage_enquiry = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);
  const { businessObjData, setBusinessObjData } =
    useContext(businessObjContext);
  const [isLoading, setIsLoading] = useState(true);
  const tableExtensions = { export: false, print: false };
  const [action] = useState({ fromUser: false });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paginationTotalRows, setPaginationTotalRows] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState([]);
  const [open, setOpen] = useState(false);



  const fetchEnquiries = async (page) => {
    try {
      const response = await axios.get(`${baseUrl}api/v1/visitor/getBusinessEnquiry`, {
        headers: {
          _token: userData?.token,
        },
        params: {
          page: page,
          pageSize: rowsPerPage
        }
      });
      setPaginationTotalRows(response?.data?.pagination?.totalRecords)
      setIsLoading(false);
      setData(response?.data?.result)
    } catch (error) {
      console.error('Error fetching Enquiries:', error);
      setIsLoading(false);
    }
  };

  const customStyles = {
    dataTableExtensions: {
      backgroundColor: "lightgray",
      padding: "10px",
    },
    headRow: {
      style: {
        backgroundColor: "#e9e9e9",
        borderRadius: "2px 2px 0 0",
        paddingLeft: "25px",
        border: "none",
        textTransform: "capitalize",
        position: "sticky",
        left: 0,
        top: 0,
        zIndex: 1,
        boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "52px",
      },
    },
    rows: {
      style: {
        padding: "6px 10px 6px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "48px !important",
      },
    },
    BusinessColumn: {
      position: "sticky",
      left: "0",
      zIndex: "1",
      backgroundColor: "#fff",
      overflow: "hidden",
      whiteSpace: "nowrap",
    }
  };

  const handleOnSelectedRowsChange = useCallback(
    ({ selectedRows }) => {
      if (!action.fromUser) return; //the component always trigger this with 0 selected rows when it renders a page, what would clear the selection

      selectedRowsPerPage[currentPage] = selectedRows; //there is no way to tell if a row was DEselected, so I had to control the selected rows per page,
      //the array would get an index to control each page
      // console.log(JSON.stringify(selectedRowsPerPage));
    },
    [currentPage, selectedRowsPerPage, action.fromUser]
  );

  const handlePageChange = (page) => {
    const nextPage = page;
    fetchEnquiries(nextPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
    setSelectedRowsPerPage([]);
    fetchEnquiries(1);
  };

  useEffect(() => {
    fetchEnquiries(1);
  }, [rowsPerPage]);

  useEffect(() => {
    fetchEnquiries(1);
  }, [businessObjData]);

  const handleViewMessage = (message) => {
    setOpen(true);
    setSelectedMessage(message);
    setIsModalOpen(true);
  };


  const handleWhatsAppRedirect = (phoneNumber) => {
    const formattedPhoneNumber = phoneNumber.replace(/[^0-9]/g, ""); 
    const message = "Hello, you have placed an inquiry, Please let me know how I can help?";
    const whatsappURL = `https://wa.me/${formattedPhoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, "_blank");
  };


  const columns = [
    {
      name: "Enquiry From",
      selector: row => row.enquiry_from,
      sortable: true,
    },
    {
      name: "Name",
      selector: row => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: row => row.email,
      sortable: true,
    },
    {
      name: "Contact",
      selector: row => row.contact,
      sortable: true,
    },{
      name: "Country",
      selector: row => row.countryName,
      sortable: true,
    },
    {
      name: t("Message"),
      selector: row => {
        return (<p style={{ fontSize: "14px" }}> {row.message} </p>)
      },
      sortable: true,
      cell: (row) => (
        <div
          style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", pointerEvents:"none" }}
        >
          {row.message}
        </div>
      ),
    },
    {
      name: t("Action"),
      sortable: false,
      cell: (row) => (
        <i
          className="first fas fa-eye"
          onClick={() => handleViewMessage(row)}
          style={{ cursor: "pointer", marginRight: "10px" }}
        ></i>
      ),
    },
  ];
  const tableData = {
    columns,
    data,
  };
 const paginationOptions = {
    rowsPerPageText: t("Rows_per_page"),
    rangeSeparatorText: t("Of"),
    selectAllRowsItem: true,
    selectAllRowsItemText: t("All"),
  };
  
  return (
    <>
      <PageName PageName= "LandingPage Enquiry" />
      <article>
        <div className="main">
          {isLoading ? (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "55%",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CircularLoader size={40} />
            </div>
          ) : (
            <>
              <div className="scrollable-columns">
                <div className="sticky-header-container">
                  <DataTableExtensions
                    {...tableData}
                    // {...tableExtensions}
                    customStyles={customStyles}
                    filterPlaceholder="Search Orders"
                  >
                    <DataTable
                      columns={columns}
                      data={data}
                      noHeader
                      defaultSortField="id"
                      sortIcon={<SortIcon />}
                      defaultSortAsc={true}
                      pagination
                      highlightOnHover
                      paginationServer
                      paginationTotalRows={paginationTotalRows}
                      paginationPerPage={rowsPerPage}
                      paginationRowsPerPageOptions={[10, 20, 50, 100, 300, 500, 1000, 10000]}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      onSelectedRowsChange={handleOnSelectedRowsChange}
                      dense
                       paginationComponentOptions={{
                        ...paginationOptions,
                      }}
                      customStyles={customStyles}
                      noDataComponent={<div style={{padding:"24px"}}>{t("No_records_found")}</div>}
                    />
                  </DataTableExtensions>
                </div>
              </div>
            </>
          )}
        </div>
       
           <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={open}
          onClose={() => setOpen(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          style={{outline: "none"}}
        >
          <Sheet
            variant="outlined"
            sx={{
              maxWidth: 500,
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
            }}
          >
            <ModalClose variant="plain" sx={{ m: 1 }} />
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              textColor="inherit"
              fontWeight="lg"
              mb={2}
              textAlign="center"
            >
              {t("Enquiry_details")}
            </Typography>
            <div className="modalparacontainer">
              <div className="modalheadingdiv">
             {t("Name")}:

              </div>

             <div className="modalcontentdiv"> 
             {selectedMessage?.name}
              </div>
            </div>

            <div className="modalparacontainer">
              <div className="modalheadingdiv">
              {t("Email")}:

              </div>

             <div className="modalcontentdiv"> 
             {selectedMessage?.email}
              </div>
            </div>

          <div className="modalparacontainer">
              <div className="modalheadingdiv">{t("Customer_phone")}:</div>
              <div className="modalcontentdiv">
                <div
                  style={{ cursor: "pointer", color: "green" }}
                  onClick={() => handleWhatsAppRedirect(
                    selectedMessage?.contact,
                    `Hi, I am ${userData?.name || 'Customer'}`,
                  )}
                >
                  <i className="fa-brands fa-whatsapp" style={{ marginRight: "5px" }}></i>
                  {selectedMessage?.contact}
                </div>
              </div>
            </div>

            <div className="modalparacontainer">
              <div className="modalheadingdiv">
              Enquiry From:
              </div>
             <div className="modalcontentdiv"> 
             {selectedMessage?.enquiry_from ? selectedMessage?.enquiry_from : " - "}
              </div>
            </div>

            <div className="modalparacontainer">
              <div className="modalheadingdiv">
              {t("Message")}:

              </div>

             <div className="modalcontentdiv"> 
             {selectedMessage?.message ? selectedMessage.message : "Not Available"}
              </div>
            </div>
          </Sheet>
        </Modal>
      </article>
    </>
  );
};
export default LandingPage_enquiry;
