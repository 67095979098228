import React from 'react'
import { useTranslation } from "react-i18next";
import CabAlternateUrl from './CabAlternateUrl';

const CabAbout = (props) => {
  const { t } = useTranslation();
  // console.log("CabAbout", props)
  return (
    <div className='about-container'>
    
    <div className='px-2'>
           <div className='capitalize font-Poppins text-xl font-medium pb-2' style={{color: props?.colorData?.dynamicTextHeadingColor || "#fff"}}>
           {props?.colorData?.descriptionHeading || props?.colorData?.rs?.headingsObj?.description_Heading || t("About")}
           </div>
        <div 
        style={{color: props?.colorData?.dynamicTextHeadingColor
          ? `${props?.colorData?.dynamicTextHeadingColor}B3`
          : "#B8B8B8",
        }} 
        className='py-2 font-DM text-lg' 
        dangerouslySetInnerHTML={{ 
                __html: props?.desc?.replace(/(?:\r\n|\r|\n)/g, '<br>') 
              }}
            >
          {/* {props?.abouttext?.description} */}
        </div>
    </div>
    
    </div>
  )
}

export default CabAbout