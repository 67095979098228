import React, { useEffect, useState } from "react";
import { environmentVariables } from "../../../config/env.config";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from "styled-components";
import axios from "axios";
import { useTranslation } from "react-i18next";

const FormikErrorStyle = styled.div`
font-size:11px;
color: #ff3333;
padding-bottom: 5px;
`;

const KeywordForm = ({
  formCount,
  servicesData,
  setIsAddServiceDisable,
  setServicesData,
  removeForm,
  index,
  businessObjData
}) => {
  const [data, setData] = useState({
    formCount: formCount,
    banner: null,
    id:null,
    name: "",
    description: "",
    buttonLink: "",
    buttonName: "",
    bannerImage: null,
    isImageUpload: true,
  });

  const [tempFormCount, setTempFormCount] = useState(0);
  const [addBtnDisable, setAddBtnDisable] = useState(false);
  const [image, setImage] = useState(null);
  const [isShowAdd, setIsShowAdd] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [dynamicMarketplaceCategories,setDynamicMarketplaceCategories]=useState([]);
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    name: Yup.string().matches(/^[\s\S]*$/, t('Alphabets_digits_special_characters_allowed'))
    .min(1, t('Minimum_1_characters'))
    .max(250, t('Maximum_250_characters'))
    .test('unique', t('Keyword_already_exists'), value => {
      return !servicesData.some(service => service.id === value);
    }),
    
  });

  const formik = useFormik({
    initialValues: {
      name: data.name || '',
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleAddService(formik.values.name);
    },
  });

  const getMarketplaceCategories=()=>{
    let config={
      method:"get",
      url:`${environmentVariables?.apiUrl}api/v1/business/allCategory`
    }
    axios.request(config).then(response=>{
        setDynamicMarketplaceCategories(response.data)
    }).catch(error=>{
      console.log(error)
    })
  }
  useEffect(()=>{
    getMarketplaceCategories();
  },[])
  useEffect(() => {
    let currentForm = servicesData[index];
    
    setTempFormCount(formCount);
    setData({
      // formCount: index,
      formCount: currentForm?.formCount,
      banner: currentForm?.image, 
      id:currentForm?.id,
      name: currentForm?.name,
      description: currentForm?.description,
      buttonLink: currentForm?.buttonLink,
      buttonName: currentForm?.buttonName,
      bannerImage: currentForm?.bannerImage,
      isImageUpload: currentForm?.isImageUpload,
    });

  if (currentForm?.name) {
    setAddBtnDisable(true);
  } else {
    setAddBtnDisable(false);
  }

    data["name"] ? setIsShowAdd(false) : setIsShowAdd(true);
    
  }, [servicesData]);

  useEffect(() => {
    setTempFormCount(data?.formCount);
  }, [data]);

  const handleAddService = (nameValue) => {
    setAddBtnDisable(true);
    setIsAddServiceDisable(false);
    
    const filteredCategories=dynamicMarketplaceCategories.filter(val=>val.id==nameValue);
    const newServicesData = [...servicesData];
    
    const isServiceAlreadyAdded = newServicesData.some(service => service.id === nameValue);
    
    if (isServiceAlreadyAdded) {
      return;
    }

    newServicesData.forEach((val) => {
      if (val.formCount === tempFormCount) {
        val.id=nameValue,
        val.banner = image;
        val.name = nameValue;
        val.description = data?.description;
        val.buttonLink = data?.buttonLink;
        val.buttonName = data?.buttonName;
        val.bannerImage = data?.bannerImage;
        val.isImageUpload = data?.isImageUpload;
      }
    });     
           
    setData(newServicesData);
    setServicesData(newServicesData);
    setIsEditing(false);
    
  };


  return (
    <div key={`form-${formCount}`} className="col-12" id="AddNewStyleFormfieldpad">
      <div className="serviceboxedit">
        <i
          class="fa-solid fa-circle-xmark crosseditservice crossIconAdddetails"
          onClick={() => removeForm(index, dynamicMarketplaceCategories)}
        ></i>
       <div>
          {isShowAdd ? (
            <div>
                <input
                  name="name"
                  disabled={addBtnDisable}
                  className="form-control"
                  style={{ padding: "14px 25px", marginBottom: "10px" }}
                  type="text"
                  placeholder={`${t("Name")}`}
                  value={data["name"] || ""}
                  onChange={(e) => {
                    formik.setFieldValue('name', e.target.value);
                    setData({ ...data, name: e.target.value });
                  }}
                  onBlur={formik.handleBlur}
                />
             

              {formik.touched.name && formik.errors.name && (
                <FormikErrorStyle>{formik.errors.name}</FormikErrorStyle>
              )}
            </div>
          ) : (
            <div className="servicenameedit" id="ServicesEditformstyle">
              {data["name"] || ""}
            </div>
          )}
        </div>

        {isShowAdd && !addBtnDisable ? (
          <button
            type="button"
            disabled={!formik.isValid || !formik.dirty}
            onClick={() => {
              formik.handleSubmit();
            }}
            className="btn btn-cu addbuttoneditform"
          >
            {t("Add")}
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default KeywordForm;
