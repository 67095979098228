import React from 'react';
import location from "./../../../../Images/minilocation.png";
import msg from "./../../../../Images/minimsg.png";
import web from "./../../../../Images/miniweb.png";
import phn from "./../../../../Images/miniphn.png";
import Twitter from "./../../../../Images/XCollectionIcon.png";
import Insta from "./../../../../Images/instaCollectionIcon.png";
import LinkedIn from "./../../../../Images/linkedincollectionicon.png";
import Facebook from "./../../../../Images/facbookCollectionicon.png";
import ExtAlternateUrls from './ExtAlternateUrls.js'
import { useTranslation } from "react-i18next";



 const ContactInfo = (props) => {
   console.log(props,"mmmmmm");
   const { t } = useTranslation();
  let usefulLinks = props?.contdata?.onState?.alternateurl;
  const getBrandNameForSocial = (brand) => {
    if (brand == "gmail") {
      return "google";
    } else return `${brand}`;
  };

  return (
    <div>
      {props?.contdata?.onState?.contactInfo && (
        <div className='ExtContactInfoMini  pt-3'>
        
            <div className='flex items-start'>
                <div className='z-0  rounded-2xl w-5'>
                <i class="fa-solid fa-phone" style={{color:`${props?.colordata?.dynamicCtaColor}` || '', background:`${props?.colordata?.dynamicCtaColor}52`}}></i>
                </div>
               <div>
               <div className='mx-2' style={{color: props?.colordata?.dynamicTextHeadingColor ? `${props?.colordata?.dynamicTextHeadingColor}`:''}}> {props?.contdata?.contactInfo?.contactInfoPhone}</div>
                <div className='mx-2' style={{color: props?.colordata?.dynamicTextHeadingColor ? `${props?.colordata?.dynamicTextHeadingColor}` : ''}}> {props?.contdata?.contactInfo?.optionalContactInfoPhone}</div>
                          </div>
                </div>
            {props?.contdata?.contactInfo?.contactInfoWebUrl && 
            <div className='flex items-center'>
                <div className='z-0  rounded-2xl w-5'>
                <i class="fa-solid fa-globe" style={{color:`${props?.colordata?.dynamicCtaColor}` || '', background:`${props?.colordata?.dynamicCtaColor}52`}}></i>
                </div>
                <div className='mx-2' style={{color: props?.colordata?.dynamicTextHeadingColor ? `${props?.colordata?.dynamicTextHeadingColor}` : ''}}>{props?.contdata?.contactInfo?.contactInfoWebUrl}</div>
            </div>
          }
            <div className='flex items-center'>
                <div className='z-0  rounded-2xl w-5'>
                 <i class="fa-solid fa-envelope" style={{color:`${props?.colordata?.dynamicCtaColor}` || '', background:`${props?.colordata?.dynamicCtaColor}52`}}></i>
                </div>
                <div className='mx-2' style={{color: props?.colordata?.dynamicTextHeadingColor ? `${props?.colordata?.dynamicTextHeadingColor}` : ''}}>  {props?.contdata?.contactInfo?.contactInfoEmail}</div>
            </div>
            {props?.contdata?.contactInfo?.contactInfoAddress && 
            <div className='flex items-center'>
                <div className='z-0  rounded-2xl w-5'>
                 <i class="fa-solid fa-location-dot" style={{color:`${props?.colordata?.dynamicCtaColor}` || '', background:`${props?.colordata?.dynamicCtaColor}52`}} ></i>
                </div>
                <div className='mx-2' style={{color: props?.colordata?.dynamicTextHeadingColor ? `${props?.colordata?.dynamicTextHeadingColor}` : ''}}>{props?.contdata?.contactInfo?.contactInfoAddress}</div>
            </div>
 }
            {props?.contdata?.contactInfo?.contactInfoOptAddress && 
            <div className='flex items-center'>
                <div className='mx-2' style={{paddingLeft:"26px", color:`${props?.colordata?.dynamicTextHeadingColor}B3` || '#936f5e'}}>
          <p style={{color:`${props?.colordata?.dynamicTextHeadingColor || 'gray'}70`, fontSize:"12px", fontWeight: "500", margin:"0"}}>{t("Alternative_address")}</p>
          <span style={{color:`${props?.colordata?.dynamicTextHeadingColor}` || 'black'}}>{props?.contdata?.contactInfo?.contactInfoOptAddress}</span> </div>
          </div>
            }
        </div>
        )}
      <div>
       
      <div style={{background:props?.colordata?.dynamicMainBackgroundColor }}   className={`px-1 py-4 pb-0 spacing -bottom-25 w-full text-center ${
        props?.colordata?.dynamicMainBackgroundColor ? "" : "bg-white"}`}>
      <div className='flex justify-start items-center'>
      <div className='Useful_Links' ><div className='ul_title' style={{color:props?.colordata?.dynamicTextHeadingColor || ''}}>{props?.contdata?.socialHeading || t("Social_links")}</div></div>
      </div>
      {props?.contdata?.onState?.socials && (
       <div className='flex items-center justify-evenly  py-2 w-hundred' style={{color:props?.colordata?.dynamicTextHeadingColor || '#1B776F'}} >
       <ul class="socil-link  w-hundred"  >
       {props?.contdata?.socialMediaData?.map(
                (val) =>
                  val.link &&
                  getBrandNameForSocial(val.nameOfSocial.toLowerCase()) && (
                    <div className="social-link-container">
                      <li className="" key={val.link}>
                      <a>
                       
                        <i
                        style={{color:props?.colordata?.dynamicCtaColor || ''}}
                          className={`fa-${
                            val?.nameOfSocial.toLowerCase() === "star"
                              ? "solid"
                              : "brands"
                          } fa-${getBrandNameForSocial(
                            val?.nameOfSocial.toLowerCase()
                          )}`}
                        ></i>
                      </a>
                    </li>
                    </div>
                  )
              )}
            </ul>
       </div>
       )}
               {/* <div>
               <button className='bg-coffee text-xl rounded text-white w-full font-bold font-Tajawal pt-3  p-2 '
                 >
                   Contact supplier
                 </button>
               </div> */}
        </div>

        {usefulLinks && (
             
             <div className='Useful_Links'>
                <div  className='ul_title' style={{marginBottom:"10px",color:props?.colordata?.dynamicTextHeadingColor || ''}}>{props?.contdata?.alternateUrlsHeading || t("Useful_links")}</div>
                <ExtAlternateUrls contdata={props?.contdata?.alternateUrlArray}  colordata={props?.colordata}/>
             </div>
            ) }

      </div>
    </div>
  )
}

export default ContactInfo;