import React, { useContext, useEffect, useState } from "react";
import PageName from "../Utils/PageName";
import { Link } from "react-router-dom";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";

import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import FileSaver from 'file-saver';
// import CircularLoader from "../../Backend/Components/CircularLoader/CircularLoader";
import {useTranslation} from "react-i18next";

const PaymentPlanList = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isCircularStatus, setIsCircularStatus] = useState(false);
  const [activeDownloadDocuemntId, setActiveDownloadDocuemntId] = useState("");

  const customStyles = {
    dataTableExtensions: {
      backgroundColor: "lightgray",
      padding: "10px",
    },
    headRow: {
      style: {
        backgroundColor: '#e9e9e9',
        borderRadius: "2px 2px 0 0",
        paddingLeft: "25px",
        border: "none",
      },
    },
    headCells: {
      style: {
        color: '#525252',
        fontSize: '16px',
        minHeight: "52px",
      },
    },
    rows: {
      style: {
        padding: "6px 0px 6px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight:"58px !important",
      },
    },
  };
  

  const OrderListBasedUser=()=>{
    try{
      let obj = {
        page: 1,
        limit: 1500,
        // "business_id": localStorage.getItem("business_id")
      };
      axios
        .get(`${baseUrl}api/v1/order/OrderListBasedUser`, {
          params: obj,
          headers: {
            _token: userData?.token,
          },
          // withCredentials:true,
        })
        .then((response) => {
          // console.log("OrderListBasedUser", response?.data?.result);
          setData(response?.data?.result);
          setIsLoading(false);
        })
        .catch((error) => {
          // console.log({ error: error, msg: "OrderListBasedUser isuuse" });
          setIsLoading(false);
        });
    }catch(err){
      console.log(err,"Erroro")
    }
  }
  useEffect(() => {
    OrderListBasedUser()
  }, []);

  const handleClick = (title) => {
    // console.log(`You clicked me! ${title}`);
  };

// const handleDownloadInvoice=(data)=>{
//   axios
//       .get(`${baseUrl}api/v1/order/downloadInvoice?invoiceId=${data.id}`, {
//         headers: {
//           _token: userData?.token,
//         },
//       }).then((res)=>{
//         console.log(res,"Resonsine")
//       }).catch((err)=>{
//         console.log(err,"errororoo")
//       })
//       // console.log(data,"dttatta")

// }


const handleDownloadInvoice = (data) => {
  setIsCircularStatus(true)
  setActiveDownloadDocuemntId(data.order_id)
  axios
    .get(`${baseUrl}api/v1/order/downloadInvoice?orderId=${data.order_id}`, {
      headers: {
        _token: userData?.token,
      },
      responseType: 'blob', // Important: Set the responseType to 'blob'
    })
    .then((response) => {
      const contentDispositionHeader = response.headers['content-disposition'];
      const fileName = contentDispositionHeader
        ? contentDispositionHeader.split('filename=')[1]
        : `invoice_${data.id}.pdf`;

      // Use FileSaver.saveAs to save the blob as a file
      FileSaver.saveAs(response.data, fileName);
      setIsCircularStatus(false)
      setActiveDownloadDocuemntId("")
    })
    .catch((error) => {
      setIsCircularStatus(false)
      setActiveDownloadDocuemntId("")
      console.log(error, 'Error');
    });
};
// useEffect(()=>{
// console.log(isCircularStatus,"isCircularStatus 1 @#")
// },[isCircularStatus])

  const columns = [
    {
      name: "Order Id",
      selector: (row) => row?.order_id,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => new Date(row?.created_at).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Name",
      selector: (row)=>row?.name,
      sortable: true,
      cell: (d) => {
        const capitalizedName = d.name.charAt(0).toUpperCase() + d.name.slice(1);
        return capitalizedName;
      },
    },
    {
      name: "Plan Name",
      selector: (row)=>row?.plan_name,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row)=>row?.price,
      sortable: true,
    },
    {
      name: "Payment Type",
      selector:(row)=>row?. payment_type,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row)=>row?. payment_status,
      sortable: true,
      cell: (d) => {
        const capitalizedName = d.payment_status.charAt(0).toUpperCase() + d.payment_status.slice(1);
        return capitalizedName;
      },
    },
    // {
    //   name: "COUPON",
    //   selector: (row)=>row?. coupon||"--",
    //   sortable: true,
    // },
    {
      name: "Invoice",
      sortable: false,
      cell: (d) => (
        < >
         {isCircularStatus&&activeDownloadDocuemntId!="" ? d.order_id==activeDownloadDocuemntId?
         
         <CircularLoader size={20} /> :  
         <i
         style={{ width: '130px', cursor:"pointer" }}
         key={d?.id}
         // data-bs-toggle="modal"
         // data-bs-target="#downloadButton"
         // data-bs-target="#downloadButton"
         onClick={() => handleDownloadInvoice(d)}
         className="first fas fa-download"
         > </i>:
          <i
          style={{ width: '130px', cursor:"pointer" }}
          key={d?.id}
          // data-bs-toggle="modal"
          // data-bs-target="#downloadButton"
          // data-bs-target="#downloadButton"
          onClick={() => handleDownloadInvoice(d)}
          className="first fas fa-download"
          > </i>
        }
        </>
      )
    },
    // {
    //   name: "Action",
    //   sortable: false,
    //   selector: "null",
    //   cell: (d) => [],
    // },
  ];

  const tableData = {
    columns,
    data,
  };
 const paginationOptions = {
    rowsPerPageText: t("Rows_per_page"),
    rangeSeparatorText: t("Of"),
    selectAllRowsItem: true,
    selectAllRowsItemText: t("All"),
  };
  
  return (
    <>
      <PageName PageName="Your Plan " />

      <article>
        <div className="row d-none">
          <div className="col-md-12">
            <div className="tile">
              <div className="tile-body">
                <form className="row">
                  <div className="mb-2 col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search....."
                    />
                  </div>
                  <div className="mb-2 col-md-3">
                    <select className="form-select">
                      <option value="">Select an option</option>
                      <option value="option1">xxx </option>
                      <option value="option2">xxxx</option>
                    </select>
                  </div>
                  <div className="mb-2 col-md-4 align-self-end">
                    <button className="btn btn-primary me-3" type="button">
                      <i className="bi bi-check-circle-fill ms-2 "></i>Search
                    </button>
                    <button className="btn btn-danger" type="button">
                      <i className="bi bi-check-circle-fill me-2"></i>Reset
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="main">
          {isLoading ? (
            <div style={{position:"absolute", top:"50%", left:"55%",display:"flex", alignItems:"center", textAlign:"center"}}>
            <CircularLoader size={40} />
        </div>
          ) : (
            <DataTableExtensions {...tableData} customStyles={customStyles} filterPlaceholder="Search">
              <DataTable
                columns={columns}
                data={data}
                noHeader
                defaultSortField="id"
                sortIcon={<SortIcon />}
                defaultSortAsc={true}
                pagination
                highlightOnHover
                dense
                 paginationComponentOptions={{
                        ...paginationOptions,
                      }}
                customStyles={customStyles}
                noDataComponent={<div style={{padding:"24px"}}>{t("No_records_found")}</div>}
              />
            </DataTableExtensions>
          )}
        </div>
      </article>
    </>
  );
};

export default PaymentPlanList;
