import React, { useState } from 'react';
import AllCollection from './AllCollection';
import { getServicesArray } from '../../../../utils/utility';
import { useTranslation } from "react-i18next";

const HomeComponent = (props) => {
  const { t } = useTranslation();
    const [activeTabb, setActiveTabb] = useState("All");
    const servicesArray = Array.isArray(props?.productdata?.servicesArray) ? props?.productdata?.servicesArray : [];
    const minimarketArray = Array.isArray(props?.productdata?.minimarketArray) ? props?.productdata?.minimarketArray : [];
    const renderContent = () => {
        let filteredProducts;

        if (activeTabb === "All") {
            filteredProducts = minimarketArray;
        } else {
            filteredProducts = minimarketArray.filter(
                product => product.product_service == activeTabb
            );
        }

        return <AllCollection products={filteredProducts} colordata ={props?.colordata} />;
    };

    const getButtonClass = (tabName) => {
        return tabName === activeTabb
            ? `bg-coffee border-none px-4 py-1 text-base rounded-full ${props?.colordata?.dynamicTextHeadingColor ? '': 'text-white'} `
            : 'px-4 py-1  rounded-full text-base  text-b45';
    };
const getButtonStyle = (tabName) => {
    if (tabName === activeTabb) {
        return {
            color: props?.colordata?.dynamicTextHeadingColor ? props?.colordata.dynamicTextHeadingColor:'',
            backgroundColor:props?.colordata?.dynamicTextHeadingColor ?  `${props.colordata.dynamicTextHeadingColor}40`:'',
             border:`1px solid  ${props?.colordata.dynamicTextHeadingColor || "#1B776F"}`,
        };
    }
    else{
        return {
            color:props?.colordata?.dynamicTextHeadingColor ? `${props?.colordata?.dynamicTextHeadingColor}40`:'',
            border:`1px solid  ${props?.colordata?.dynamicTextHeadingColor || "#000000"}40`        }
    } 
};

    return (
        props?.productdata?.onState?.miniproduct ? (
        <div>
            <div className='flex overflow-x-auto'>
            <div>
                    <button
                        className={getButtonClass("All")}
                        onClick={() => setActiveTabb("All")}
                        style={{ margin: '0 10px',  ...getButtonStyle("All") }}
                    >
                        {t("All")}
                    </button>
                </div>
               {getServicesArray(servicesArray).map((service, index) => (
    !!service.id && 
    <div key={index}>
        <button
            className={getButtonClass(service.id)}
            onClick={() => setActiveTabb(service.id)}
            style={{ 
                margin: '0 10px', 
                width: "max-content", 
                ...getButtonStyle(service.id) 
            }}
        >
 { t(
    service.id
        .replace(/&/g, " ")
        .toLowerCase()            
        .trim()       
        .replace(/\s+/g, "-")    
)
}

        </button>
    </div>
))}
            </div>
            <div className="px-2 py-2">{renderContent()}</div>
        </div>
        ) : null
    );
};

export default HomeComponent;
