import * as yup from "yup";
import { object, string, ref } from "yup";

export const registerValidationUser = (t)=> { 

const getCharacterValidationError = (str) => {
    return `${t("Password_atleast_one")} ${str} ${t("Character")}`;
  };

return yup.object().shape({

  name: yup
    .string()
    .required(t("Full_name_required"))
    .min(3, t("Name_greater_than_3"))
    .max(45),
  email: yup.string().required().email(t("Please_enter_valid_email")),
  contact: yup
    .string()
    .required(t("Phone_number_required")),
  password: string()
    .required(t("Please_enter_a_password"))
    .min(8, t("Password_at_least_8_characters"))
    .matches(/[0-9]/, getCharacterValidationError(t("Digit")))
    .matches(/[a-z]/, getCharacterValidationError(t("Lowercase")))
    .matches(/[A-Z]/, getCharacterValidationError(t("Uppercase"))),
  confirmPassword: string()
    .required(t("Retype_password"))
    .oneOf([ref("password")], t("Passwords_does_not_match")),
  type: yup.string().required(t("Please_select_type_of_registeration")),
})};
//212 15:59