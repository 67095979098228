import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import { environmentVariables } from "../../config/env.config";
import { userContext } from "../../context/userContext";
import { currencyContext } from "../../context/currencyContext.js";
import PageName from "../Utils/PageName";
import successright from "../../Images/check.png";
import { json, useNavigate } from "react-router";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const RazorPayment = () => {
   const { t } = useTranslation();
  const [paymentData, setPaymentData] = useState(null);
  const [amount, setAmount] = useState(null);
  const [planPrice,setPlanPrice]=useState()
  const [isVerifying, setIsVerifying] = useState(true);
  const [DeliveryDate, setDeliveryDate] = useState(null);
  const { userData } = useContext(userContext);
  const [paymentPopUp, setPaymentPopUp] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const navigate = useNavigate();
  const { search } = location;
  // const urlParams = new URLSearchParams(search);
  const urlParams = new URLSearchParams(window.location.search);
  const payStatus = urlParams.get("status");
  const [ttransID, setTtransID] = useState("NA");
  let planData = JSON.parse(localStorage.getItem("plan_pay"));

  const handleClosePopUp = () => {
    setPaymentPopUp(false);
    if (paymentSuccess) {
      navigate("/requestCard");
    } else {
      navigate("/requestCard");
    }
  };

  const handleRedirect = async () => {
    // Extract transaction token and company reference from URL params
    const urlParams = new URLSearchParams(window.location.search);
    const plan_id = urlParams.get("plan_id");
    setTtransID(urlParams.get("transId"))
    setPlanPrice(urlParams.get("amount"))
    if (payStatus=="success" ) {
          try {
            setPaymentPopUp(true);
            setPaymentSuccess(true);
          } catch (error) {
            console.error("Error processing payment order:", error);
          }   
    } else {
    toast.error("Payment failed");
    navigate('/requestCard')
      setIsVerifying(false);
    }
  };
  useEffect(() => {
    handleRedirect();
    
  }, []);

  return (
    <>
      <PageName PageName="Razor Payment" />
      {isVerifying ? (
        <div className="loader-container">
          <CircularLoader />
        </div>
      ) : (
        <form>
          <button
            type="submit"
            className="btn try_theme_btn mt-3 mx-auto d-block"
          >
            <CircularLoader size={20} />
          </button>
        </form>
      )}

      {paymentPopUp && (
        <div className="popup-outer">
          <div className="popup-overlay"></div>
          <div className="popup-inner">
            <div className="fs-1 payment_heaader">
              {/* {paymentSuccess ? "Payment Successful" : "Payment Failed"} */}
            </div>
            <img loading="lazy" src={successright} className="" />
            <div className="inner-box mt-4">
              <h1 className="name">{t("Payment_details")}</h1>
              <div className="orderbox">
                <ul className="list-group mb-3">
                  <li className="list-group-item d-flex justify-content-between">
                    <span>{t("Receiver")}</span>
                    <strong>{t("Businessbay")}</strong>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <span>{t("Payment_method_type")}</span>
                    <strong>{t("Razor_pay")}</strong>
                  </li>

                  <li className="list-group-item d-flex justify-content-between">
                    <span> {t("Amount_paid")}</span>
                    <strong>
                      {planPrice}
                    </strong>
                  </li>

                  <li className="list-group-item d-flex justify-content-between">
                    <span>{t("Transaction_id")}</span>
                    <strong>{ttransID}</strong>
                  </li>
                </ul>
                <button
                  type="submit"
                  className="btn try_theme_btn mx-auto d-block submitclose"
                  onClick={handleClosePopUp}
                >
                  {t("Close")}
                </button>
              </div>
              <div className="order-box">{/* Your payment details here */}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default RazorPayment;
