import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { environmentVariables } from "../config/env.config";
import { userContext } from "../context/userContext";
import Cookies from "js-cookie";
import ReCAPTCHA from "react-google-recaptcha";
import CircularLoader from "../Backend/Components/CircularLoader/CircularLoader";
import "./LoginStyle.css";
import Mainlogo from "../Images/logo.png";

function Customer_Login() {
  const { userData, setUserData } = useContext(userContext);
  const baseUrl = environmentVariables?.apiUrl;
  const homelocation = environmentVariables?.homeLoc;
  const [isLoginDisabled, setIsLoginDisabled] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();
  const [captchaReset, setCaptchaReset] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});
  const recaptchaRef = React.createRef();
  const [isCaptchaVerified, setCaptchaVerified] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);


  // get last login data 
  let lastLoginFormData = localStorage.getItem('login_form');
  
  const handleRememberMeChange = (event) => {
    const isChecked = event.target.checked;
    setRememberMe(isChecked);
  };
  useEffect(()=>{
    if (!!rememberMe) {
      localStorage.setItem('login_form', JSON.stringify({ email, password }));
    }
  },[rememberMe]);

  useEffect(()=>{
    let loginData = (lastLoginFormData != null)?JSON.parse(lastLoginFormData):"";
    if(!!loginData){
      setEmail(loginData?.email);
      setPassword(loginData?.password);
      setRememberMe(true);
    }
  },[])

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  function onChangeRecaptcha(value) {
    setCaptchaVerified(value !== null);
    setCaptchaValue(value);
    setIsSubmit(false);
    setCaptchaReset(false);
  }

  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem("user"));
    if (storedUserData) {
      navigate("/dashboard");
      return;
    }
    if (storedUserData) {
      let config = {
        method: "get",
        url: `${baseUrl}api/v1/user/getDashboardData`,
        headers: {
          _token: userData?.token,
        },
      };
      axios
        .request(config)
        .then((response) => {
          navigate("/dashboard");
          return;
        })
        .catch((error) => {
          if (error?.response?.status) {
            navigate("/login");
            return;
          }
        });
    }
  }, []);

  const onSubmit = () => {
    if (email && password && captchaValue) { // capcha code
      // if (email && password) {
      setIsSubmit(true);
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = emailPattern.test(email);
      if (isValid) {
        axios
          .post(`${baseUrl}api/v1/user/customer_login`, {
            email,
            password,
            captchaValue,
          })
          .then((res) => {
            setIsSubmit(true);
              localStorage.setItem("Customer", JSON.stringify(res?.data?.result));
              Cookies.set("access_token", res?.data?.result?.token, {
                expires: 7,
                domain: "localhost",
                path: "/",
                secure: true,
              });
              setUserData(res?.data?.result);
              toast.success("Logged in successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
              setIsSubmit(false);
              setIsLoginDisabled(true);
              setTimeout(() => {
                if(res.data.result.user_type == "super_admin"){
                  navigate("/super_dashboard");
                  return;
                }else{
                  if(res.data.result.user_type == "Customer"){
                    // let getCurrentInfluencerUrl = localStorage.getItem("currentBusinessUrl");
                    // window.location.href=getCurrentInfluencerUrl;
                    navigate('/customer_profile');
                  }
                }
              }, 1000);
            setIsSubmit(false);
          })
          .catch((err) => {
            toast.error(
              err?.response?.data?.message ||
              "Email and Password are not correct",
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              }
            );
            setIsSubmit(false);
            setCaptchaReset(true);
          });
          
      } else {
        toast.error("Email is not valid.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsSubmit(false);
      }
    } else {
      toast.error("Email, Password and Captcha are required", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmit(false);
    }
  
  };

  const handleChange = (e) => {
    if (e.target.name == "email") {
      setEmail(e.target.value);
    } else if (e.target.name == "password") {
      setPassword(e.target.value);
    }
    if (email && password) {
      setIsLoginDisabled(false);
    }
  };

  return (
    <>
      <div className="login-23">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-12 bg-color-23">
              <div className="form-section">
                {/* <div className="logo"></div> */}
                <h3 >
                  <Link to={`${homelocation}`} className="mainlogoalignloginreg">
                    <img loading="lazy" src={Mainlogo} style={{ width: "50%" }} />
                  </Link>
                </h3>
                <div className="login-inner-form">
                  <form>
                    <div className="form-group clearfix">
                      <div className="form-box">
                        <input
                          type="text"
                          className="form-control clear_string login_inputfield"
                          placeholder="Enter Your Email*"
                          name="email"
                          value={email}
                          required=""
                          key="email"
                          onChange={(e) => handleChange(e)}
                        />
                        <i
                          className="fa fa-envelope input_icons"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                    <div className="form-group clearfix">
                      <div className="form-box">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control clear_string login_inputfield"
                          placeholder="Enter Your Password*"
                          name="password"
                          key="password"
                          value={password}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <span
                          onClick={togglePasswordVisibility}
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                        >
                          {showPassword ? (
                            <i className="fas fa-eye Eeye"></i>
                          ) : (
                            <i className="fas fa-eye-slash Eeye"></i>
                          )}
                        </span>
                        <i
                          className="fa fa-key input_icons"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                      onChange={onChangeRecaptcha}
                      key={captchaReset ? "reset" : "normal"}
                    />

                    <div className="checkbox form-group clearfix mt-3 PhonealignforgotePass">
                      <div className="form-check float-start">
                        <input
                          type="checkbox"
                          checked={rememberMe}
                          onChange={handleRememberMeChange}
                          className="form-check-input"
                          id="rememberme"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="rememberme"
                        >
                          Remember me
                        </label>
                      </div>
                      <Link
                        to="/forgot-password"
                        className="link-light float-end forgot-password PhoneforgotePass"
                      >
                        Forgot your password?
                      </Link>
                    </div>
                    <div className="form-group clearfix mb-0">
                      <button
                        onClick={onSubmit}
                        type="button"
                        className="btn-theme login_button"
                        disabled={isSubmit}
                      >
                        {/* {isSubmit ? <CircularLoader size={25} /> : "Login"} */}
                        {!isSubmit ? "Login" : null}
                        {isSubmit && <CircularLoader size={20} />}
                        {/* Login */}
                      </button>
                    </div>

                    <ToastContainer />
                  </form>
                </div>
                <p className="text-center">
                  Don't have an account?
                  <Link className="registerhere" to="/customer_register" style={{ paddingLeft: "10px" }}>
                    Register here
                  </Link>
                </p>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-12 bg-img login-slides">
              <div className="info">
                <div className="waviy">
                  {/* Welcome To <span>Businessbay</span> */}
                </div>
                <p>
                  {/* Experience a 15-day FREE Trial that helps you connect with
                  people easily and build important relationships. */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Customer_Login;
//1112
