import React, { useEffect, useContext, useState } from "react";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useLocation } from "react-router";
import PageName from "../Utils/PageName";
import { environmentVariables } from "../../config/env.config";
import { userContext } from "../../context/userContext";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import successright from '../../Images/check.png'
import { json, useNavigate } from "react-router";
import axios from "axios";
import { useTranslation } from "react-i18next";


const Dpopayment = () => {
   const { t } = useTranslation();

  const [paymentData, setPaymentData] = useState(null);
  const [amount,setAmount]= useState(null)
  const [isVerifying, setIsVerifying] = useState(true);
  const [DeliveryDate, setDeliveryDate] = useState(null);
  const { userData } = useContext(userContext);
  const [paymentPopUp, setPaymentPopUp] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const navigate = useNavigate();
  const { search } = location;
  const urlParams = new URLSearchParams(window.location.search);
  const TransID = urlParams.get('TransID')

  const params = new URLSearchParams(search);
  const cardId = params.get('cardId');
  // const cardNumber = params.get('cardNumber');
  async function fetchConfig() {
    try {
      const response = await axios.get(
        `${environmentVariables?.apiUrl}api/v1/admin/setting/fetchConfig`,
        {
          params: {
            cnf_type: "env",
            name: ['physical_card_price', 'Delivery_Date']
          },
        }
      );
      // console.log("1111111~~~~~")
      if (response.status === 200) {
        // console.log(response?.data?.result?.Delivery_Date, "2222222222222");
        setDeliveryDate(response?.data?.result?.Delivery_Date);
      }
    } catch (error) {
      console.error({ "Error:": error, msg: "ErrorLanding" });
    }
  }

  const handleClosePopUp = () => {
    setPaymentPopUp(false);
    if (paymentSuccess) {
      navigate("/requestcard");
    } else {
      navigate("/create-card");
    }
  };

  const handleRedirect = async () => {
    // Extract transaction token and company reference from URL params
    const urlParams = new URLSearchParams(window.location.search);
    const transactionToken = urlParams.get('TransactionToken');
    const companyRef = urlParams.get('CompanyRef');
    const TransID = urlParams.get('TransID')
    const business_id = urlParams.get('business_id')
    const print_business_name = urlParams.get('print_business_name')
    const deliveryInstructions = urlParams.get('deliveryInstructions')
    const cardType = urlParams.get('cardType')
    const DeliveryDate = urlParams.get('DeliveryDate')
    const amounts = urlParams.get('amount')
    const currency = urlParams.get('currency')

    if (transactionToken && companyRef) {
      try {
        const verifyResponse = await fetch(`${environmentVariables?.apiUrl}api/v1/payment/VerifyToken`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            transaction_token: transactionToken
          }),
        })
        if (!verifyResponse.ok) {
          throw new Error('Failed to verify payment');
        }
        const verifyData = await verifyResponse.json();
        setPaymentData(verifyData.result.API3G);
        setAmount(verifyData?.result?.API3G?.TransactionFinalAmount?._text);

        setTimeout(async () => {
          try {
            const paymentOrderResponse = await fetch(`${environmentVariables?.apiUrl}api/v1/phy_card/phyPaymentOrder`, {
              method: 'POST',
              headers: {
                _token: userData?.token,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                order_id: Date.now(),
                card_number: "1234",
                card_exp_month: "12",
                card_name: "Physical Card",
                card_req_id: cardId,
                price: amounts,
                price_currency: currency,
                txn_id: TransID,
                payment_status: "success",
                receipt: "receipt_info",
                store_id: 1,
                payment_type: "DPO payment",
                qty_physical_card: 1,
                payment_data: "data.payment_data",
                CardType: cardType,
                DeliveryDate: DeliveryDate,
                business_id: business_id,
                deliveryInstructions: deliveryInstructions,
                print_business_name: print_business_name,
              }),
            });
            if (!paymentOrderResponse.ok) {
              throw new Error('Failed to process payment order');
            }
  
            const paymentOrderData = await paymentOrderResponse.json();
            console.log('Payment order processed successfully:', paymentOrderData);
  
            setPaymentPopUp(true);
            setPaymentSuccess(true);
          } catch (error) {
            console.error('Error processing payment order:', error);
          }
        }, 2000);
      } catch (error) {
        console.error('Error verifying/payment order:', error);
      } finally {
        setIsVerifying(false);
      }
    } else {
      setIsVerifying(false);
    }
  };
  useEffect(() => {
    handleRedirect();
  }, []);


  useEffect(()=>{
    fetchConfig()
      },[fetchConfig()])

  return (
    <>
    <PageName PageName="DPO Payment" />
    {isVerifying ? (
      <div className="loader-container">
        <CircularLoader />
      </div>
    ) : (
      <form>
        <button
          type="submit"
          className="btn try_theme_btn mt-3 mx-auto d-block"
        >
          <CircularLoader size={20} />
        </button>
      </form>
    )}

    {paymentPopUp && (
      <div className="popup-outer">
        <div className="popup-overlay"></div>
        <div className="popup-inner">
          <div className="fs-1 payment_heaader">
            {paymentSuccess ? "Payment Successful" : "Payment Failed"}
          </div>
          <img loading="lazy" src={successright} className="" />
          <div className="inner-box mt-4">
            <h1 className="name">{t("Payment_details")}</h1>
            <div className="orderbox">
              <ul className="list-group mb-3">
                <li className="list-group-item d-flex justify-content-between">
                  <span>{t("Receiver")}</span>
                  <strong>{t("Businessbay")}</strong>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <span>{t("Payment_method_type")}</span>
                  <strong>{t("Dpo_payment")}</strong>
                </li>

                <li className="list-group-item d-flex justify-content-between">
                  <span> {t("Amount_paid")}</span>
                  <strong>{paymentData?.TransactionCurrency?._text} {paymentData?.TransactionAmount?._text}</strong>
                </li>

                <li className="list-group-item d-flex justify-content-between">
                  <span>{t("Transaction_id")}</span>
                  <strong>{TransID}</strong>
                </li>
              </ul>
              <button
                type="submit"
                className="btn try_theme_btn mx-auto d-block submitclose"
                onClick={handleClosePopUp}
              >
                {t("Close")}
              </button>
            </div>
            <div className="order-box">
              {/* Your payment details here */}
            </div>
          </div>
        </div>
      </div>
    )}
  </>
  );
};

export default Dpopayment;

// paymentData ? (
//   <>
//     <p>Result: {paymentData.Result._text}</p>
//     <p>Result Explanation: {paymentData.ResultExplanation._text}</p>
//     <p>Customer Credit: {paymentData.CustomerCredit._text}</p>
//     {/* Add more fields as needed */}
//   </>
// )