import React from 'react'
import { OrderCardWrapperContainer, OrderCardWrapperContainerSM, SocialIcon, SocialmediaIconn, TabSocialContainer, TabSocialIConWrapper } from './Theme14ExtStyleComponent'
import FacebookimgIcon from "../../../../Images/Restaurant/FacebookIcon.png";
import YoutubeimgIcon from "../../../../Images/Restaurant/youtubeicon.png";
import XimgIcon from "../../../../Images/Restaurant/xIcon.png";


export const TabSocialMedia = (props) => {
  // let SocialMediaObj = JSON.parse(props?.rs?.SocialMediaObj?.content);
  // console.log(SocialMediaObj,"sclmedia#")
  let colordata = props?.colordata;
  const rs = props.rs;
  let socialMediaData = [];
  const getSocialIcon = (social) => {
    if (social == "gmail") {
      return "google";
    } else if (social == "facebook") {
      return "facebook-f";
    } else if (social == "twitter") {
      return "twitter";
    } else {
      return social;
    }
  };
  const getLink = (social, link) => {
    if (social == "whatsapp") {
      const cleanedNumber = link.replace(/-/g, "");
      return `https://wa.me/${cleanedNumber}`;
    } else if (social == "envelope") {
      return `mailto:${link}`;
    } else if (social == "telegram") {
      return `https://t.me/${link}`;
    } else if (social == "pinterest") {
      return `https://in.pinterest.com/${link}`;
    
    } else {
      if (!link.startsWith("http://") && !link.startsWith("https://")) {
        link = "https://" + link;
      }
      link = link.replace("www.", "");
      return link;
    }
  };
  try {
    socialMediaData = JSON.parse(rs.SocialMediaObj.content);
    if (socialMediaData.length) {
      socialMediaData = JSON.parse(socialMediaData);
    }
  } catch (error) {
    console.error("Error parsing socialMediaData:", error);
  }
  return (
    <OrderCardWrapperContainerSM>
      <TabSocialContainer>
       {/*  <TabSocialIConWrapper InternalTheme>
          <SocialmediaIconn src={FacebookimgIcon}/>    
          </TabSocialIConWrapper>
          <TabSocialIConWrapper InternalTheme>
          <SocialmediaIconn src={YoutubeimgIcon}/>    
        </TabSocialIConWrapper>
        
       */}
      <ul className="socil-link">
          {socialMediaData.map((val, index) => (
            <li key={index} style={{background : colordata?.dynamicTextHeadingColor ? `${colordata?.dynamicTextHeadingColor}40` : ""}}>
              <a
                href={getLink(val?.nameOfSocial.toLowerCase(), val?.link)}
                target="_blank"
              >
                <i style={{color : colordata?.dynamicTextHeadingColor || ""}}
                  className={`fa-${val?.nameOfSocial.toLowerCase() === 'envelope' ? 'solid' : 'brands'} fa-${getSocialIcon(
                    val?.nameOfSocial.toLowerCase()
                  )}`}
                ></i>
              </a>
            </li>
          ))}
        </ul>
        </TabSocialContainer>
    </OrderCardWrapperContainerSM>
  )
}
