import React, { useState } from 'react'
import { environmentVariables } from "../../../../config/env.config";
import {toast } from 'react-toastify';

export const AddItem = ({ qty, i, setQty, item, close, handleAddToOrderItem }) => {
    const [selectedOption, setSelectedOption] = useState(null);

    const handleSelectOption = (index) => {
        setSelectedOption(index);
    }


    const handleAddItem = () => {
        if (selectedOption === null) {
            toast.error(("Please select a quantity option before adding the item."), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                  });
            return;
        }

        const selectedQuantity = item.quantity[selectedOption];
        const selectedPrice = item.price[selectedOption];
        handleAddToOrderItem({ ...item, selectedQuantity, selectedPrice, qty: qty[i] }, i);
    }

    return (
        <div className='addItemPopup'>
            <div className='wrapperItem'>
                <div className='close' onClick={close}>
                    <i class="fa-solid fa-xmark"></i>
                </div>
                <div className='upper'>
                    <div className='image'><img src={item?.image === ""
                        ? `${environmentVariables.apiUrl}uploads/No image available.png`
                        : item?.image?.includes('/')
                            ? `https://businessbayuat.s3.ap-south-1.amazonaws.com/uploads/${item?.image}`
                            : `${environmentVariables.s3UrlBackendUrl}${item?.image}`} /></div>
                    <h6>{item?.name} </h6>
                </div>
                <div className='quantity'>
                    <div className='headerClass'>
                        <h4>
                           Select Quantity
                        </h4>
                        <p>Required • Select any 1 option</p>
                    </div>
                    <div className='bottom'>
                        {item?.quantity?.map((qty, index) => (
                            <div className='item' key={index}>
                                <p>{qty}</p>
                                <div>
                                    <span>₹{item.price[index]}</span>
                                    <input
                                        type='radio'
                                        name="itemGroup"
                                        checked={selectedOption === index}
                                        onChange={() => handleSelectOption(index)}
                                    />
                                </div>
                            </div>
                        ))}


                    </div>
                </div>

                <div className='lower'>
                    <div className='left'>
                        <button
                            disabled={qty[i] === 1}
                            onClick={() => setQty(prevQty =>
                                prevQty.map((q, index) => index === i ? Math.max(1, q - 1) : q)
                            )}
                        >-</button>

                        <button style={{ cursor: 'default' }}>{qty[i]}</button>

                        <button
                            onClick={() => setQty(prevQty =>
                                prevQty.map((q, index) => index === i ? (q < 50 ? q + 1 : q) : q)
                            )}
                        >+</button>
                    </div>
                    <div
                        className='right'
                        onClick={handleAddItem}
                    >
                        {selectedOption !== null
                            ? `Add item ₹${item.price[selectedOption] * qty[i]}`
                            : 'Add item'}
                    </div>
                </div>
            </div>

        </div>
    )
}
