import React, { useContext, useEffect, useState } from "react";
import "./Style.css";
import "./InfluencerDashboard.css";
import './BusinessPages/Additional.css'
import PageName from "../Utils/PageName";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { environmentVariables } from "../../config/env.config";
import QRCode from "qrcode.react";
import Charttb from "./Charttb";
import Charttc from "./Charttc";
import Chartte from "./Chartte";
import Charttf from "./Charttf";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./Updatedcss/UpdatedDashboardstyle.css";
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import { businessObjContext } from "../../context/businessObjContext";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTable from "react-data-table-component";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import moment from "moment/moment";
// import TooltipImg from "./../../Images/tooltip.svg";
import TooltipImg from "./../../Images/info.png";
import { formatSubscribers } from "../../utils/utility";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import { useTranslation } from "react-i18next";

function InfluencerDash() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [cardsData, setCardsData] = useState(null);
  const [data, setData] = useState([]);
  const [planExpireDays, setPlanExpireDays] = useState(0);
  const [showPlanExpireBanner, setShowPlanExpireBanner] = useState(false);
  const { userData } = useContext(userContext);
  const [open, setOpen] = useState(false);
  const [storedData, setStoredData] = useState(
    localStorage.getItem("businessObj")
  );
  const httpProtocol = environmentVariables?.httpProtocol;
  const fronturl = environmentVariables?.frntUrl;
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const { businessObjData, setBusinessObjData } =
    useContext(businessObjContext);
  let busiObj = localStorage.getItem("businessObj");
  const [appointments, setAppointments] = useState([]);
  const [appData, setAppData] = useState([]);
  const [contData, setContData] = useState([]);
  const [chartsRs, setChartsRs] = useState([]);
  const [infBusinessLink, setInfBusinessLink] = useState("");
  const [businessLink, setBusinessLink] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [sevdays, setSevdays] = useState({});
  const [busData, setBusData] = useState({});
  const [totalDeliveredOrders, setTotalDeliveredOrders] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalPendingOrders, setTotalPendingOrders] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [totalEarning, setTotalEarning] = useState(0);
  const [withdrawn, setWithdrawn] = useState(0);
  const [totalClaimable, setCotalClaimable] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [claimAmount, setClaimAmount] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [influencerData, setInfluencerData] = useState(null);
  const tableExtensions = { export: false, print: false };
  const [youtubeSubscribe, setYoutubeSubscribe] = useState("")
  const [youtubeName, setYoutubeName] = useState("")
  const [GreatingMsg, setGreatingMsg] = useState("");
  const [openRemarks, setOpenRemarks] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState("");
    

  const [graphData, setGraphData] = useState({
    transformedData: [],
    gData2: [],
  });
  async function getGreatingMsg() {
    try {
      const response = await axios.get(
        `${baseUrl}api/v1/admin/setting/fetchConfig`,
        {
          params: {
            cnf_type: "Web",
            name: ["Influencer_Greeting"],
          },
        }
      );
      if (response.status === 200) {
        setGreatingMsg(response?.data?.result?.Influencer_Greeting);
      }
    } catch (error) {
      console.error({ "Error:": error, msg: "ErrorLanding" });
    }
  }
  const baseUrl = environmentVariables?.apiUrl;
  const getGraphData = () => {
    axios
      .get(`${baseUrl}api/v1/business/fetchGraphData`, {
        params: { UserId: userData?.id },
        headers: {
          _token: userData?.token,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          setSevdays(res?.data);
          setBusData(res?.data?.totalBusiness?.rs)
          if (res?.data?.resultA?.sum_of_appointments) {
            setAppData(res.data.resultA.sum_of_appointments);
          }
          if (res?.data?.resultC?.sum_of_contacts) {
            setContData(res.data.resultC.sum_of_contacts);
          }
          if (res?.data?.resultViewr) {
            setChartsRs(res.data?.resultViewr);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.error("Resource not found:", error);
        } else {
          console.error("error:", error);
        }
      });
  };
  const getInfluenderDetails = () =>{
    axios.get(`${apiUrl}api/v1/influencer/getInfluencerById?id=${userData?.id}`)
    .then((response)=>{
      setInfluencerData(response?.data?.result);
      const businessData = response?.data?.result?.businesses;
      const slugb = businessData?.slug;
      const slug_subdomain = businessData?.slug_subdomain
      setYoutubeName(businessData?.yoptubeAccountName)
      let bsLink = (slug_subdomain != null)? httpProtocol +slug_subdomain:(slugb !=null)? fronturl+ slugb:"/";
      setInfBusinessLink(bsLink);
    }).catch((err)=>{
      console.log(err);
    })
  }

  const getYoutubeSubscribe = async () => {

    try {
      if (youtubeName) {
        let { data: get } = await axios.get(
          `${baseUrl}api/v1/influencer/getYoutubeSubscribers?youtubeHandle=${youtubeName}`,
          {
            headers: {
              _token: userData?.token,
            },
          }
        );
        setYoutubeSubscribe(get?.subscribers)
        // setOrder(get?.data?.order);
      }

    } catch (err) {
      console.log(err);
    }
  };
  
  useEffect(()=>{
    getInfluenderDetails();
  },[])
  useEffect(()=>{
    getYoutubeSubscribe();
  },[youtubeName])
  const getInfluencerDashboardData = () => {
    axios
      .get(`${baseUrl}api/v1/influencer/getInfluencerOrderSummaryByInfluencerId`, {
        params: { id: userData?.id },
        headers: {
          _token: userData?.token,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          setTotalDeliveredOrders(res?.data?.result?.totalDeliveredOrders);
          setTotalOrders(res?.data?.result?.totalOrders);
          setTotalPendingOrders(res?.data?.result?.totalPendingOrders);
          setTotalSales(res?.data?.result?.totalSales);
          setTotalEarning(res?.data?.result?.totalEarning);
          setCotalClaimable(res?.data?.result?.claimableAmount);
          setCurrencySymbol(res?.data?.result?.currencyDataObj?.symbol);
          setPercentage(res?.data?.result?.percentage);
          setWithdrawn(res?.data?.result?.totalPaidAmount);

        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.error("Resource not found:", error);
        } else {
          console.error("error:", error);
        }
      });
  };

  let data1 = graphData?.transformedData;
  let data2 = [["Language", "Speakers (in millions)"]];
  data2.push(...graphData.gData2);
  const setBusinessDataLink = (buzzData) => {
    const businessData = JSON.parse(buzzData);
    let redirectUrl = (businessData?.slug_subdomain != null) ? httpProtocol + businessData?.slug_subdomain : (businessData?.slug != null) ? fronturl + businessData?.slug : "/";
    setBusinessLink(`${redirectUrl}`);
    setBusinessName(businessData?.title);
  }
  useEffect(() => {
    // getGraphData();
    getInfluencerDashboardData();
    getGreatingMsg();
    if (storedData != "undefined" && storedData != null) {
      setBusinessDataLink(storedData);
    } else {
      setBusinessDataLink(busiObj);
    }
  }, [storedData, busiObj]);

  const handleCopyClick = (infBusinessLink) => {
    navigator.clipboard
      .writeText(infBusinessLink)
      .then((res) => {
        // setCopied(true);
      })
      .catch((error) => {
        console.error("Failed to copy link: ", error);
      });
    toast.info(t("Link_copy"), {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  };
  const apiUrl = environmentVariables?.apiUrl;

  useEffect(() => {
    let config = {
      method: "get",
      url: `${apiUrl}api/v1/user/getDashboardData`,
      headers: {
        _token: userData?.token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setCardsData(response?.data?.data);
      })
      .catch((error) => {
        if (error?.response?.status) {
          localStorage.clear();
          window.location.href = "/";
          window.location.reload();
        }
      });
  }, []);

  const fetchApppointent = (page = 1) => {
    try {
      let obj = {
        business_id: businessObjData?.id,
      };
      axios
        .post(`${baseUrl}api/v1/appointment/FetchAppointments_withpagination?page=${page}&limit=${rowsPerPage}&showcount=${userData?.appointments}`, { ...obj }, {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((response) => {
          let arr = response?.data?.data?.records;
          arr = arr.sort(
            (x, y) => new Date(y?.created_at) - new Date(x?.created_at)
          );
          setAppointments(arr);
        })
        .catch((error) => {
        });
    } catch (err) {
      console.log(err, "erroror");
    }
  };


  useEffect(() => {
    fetchApppointent();
  }, [rowsPerPage]);



  const fetchClaimrequest = async () => {
    try {
      axios
        .get(`${baseUrl}api/v1/influencer/getAllClaimRequestByInfluencerId?id=${userData?.id}`)
        .then((response) => {
          setData(response?.data?.result);
          setIsLoading(false)

        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false)
        });
    } catch (err) { }
  };

  const handleClickOpen = () => {
    ((totalClaimable - withdrawn) === 0 || !!data && data?.length>0 && data[0]?.payment_status == 'pending' || (totalClaimable - withdrawn) < 2000 )? setOpen(false) :
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchClaimrequest();
  }, [])

  // const CalendarComponent = ({ appointments }) => {
  // Transform appointment data into the format expected by FullCalendar
  const events = appointments.map(appointment => ({
    title: appointment.businessTitle, // Use businessTitle as the event title
    start: appointment.date, // Use date as the event start time
    // You can optionally include other properties such as 'end' here if needed
    extendedProps: {
      // Pass the entire appointment object as extendedProps
      appointment,
    },

  }));

  const eventContent = ({ event }) => {
    const { businessTitle, time, name, } = event.extendedProps.appointment;
    return (
      <div style={{ padding: "5px 0" }}>
        <p style={{ fontSize: "14px", fontWeight: "bold" }}>{businessTitle}</p>
        <p style={{ fontSize: "13px" }}> {time}</p>
        <p style={{ fontSize: "13px" }}>{name}</p>
      </div>
    );
  };

  const downloadQR = () => {
    const canvas = document.getElementById("businessLinkQr");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = businessName + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    getExpirationDetails();
  }, []);

  const getExpirationDetails = () => {
    let config = {
      method: "get",
      url: `${apiUrl}api/v1/user/getPlanExpirationDetails`,
      headers: {
        _token: userData?.token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response?.data?.success) {
          setPlanExpireDays(response?.data?.planExpiryDays);
          setShowPlanExpireBanner(response?.data?.allowToShowExpiryBanner);
        }
      })
      .catch((error) => {
        console.log("error====>>>", error.message);

      });
  }


  const ClaimHandler = () => {
    let data = {
      influencer_id: userData.id,
      requested_amount: String(totalClaimable - withdrawn),
      requested_currency:currencySymbol
    }
    axios.post(`${baseUrl}api/v1/influencer/createClaimRequest`, data)
      .then((res) => {
        setClaimAmount(res?.data?.result);
        toast.success(t("Claim_request_created"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        fetchClaimrequest();
        handleClose();
      }).catch((error) => {
        toast.error(error.response?.data?.message || t("An_error_occurred"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        handleClose();
      })
  }

  const hideModal = () => {
    // if (closeButtonRef.current) {
    //   $(closeButtonRef.current).trigger("click");
    // }
    setOpenRemarks(false);
    setSelectedMessage("");
    // setIsModalOpen(false);
  };

  
  const handleViewMessage = (d) => {
    setOpenRemarks(true);
    setSelectedMessage(d);
  };

  const columns = [
    {
      name: t("Payment_request"),
      selector: (row) => {        
        const date = new Date(row.created_at);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleString('en-US', options);
      },
      sortable: true,
    },
    {
      name: t("Requested_amount"),
      selector: (row) => currencySymbol + row.requested_amount || "--",
      sortable: true,
    },
    {
      name: t("Received_amount"),
      selector: (row) =>  row.payment_status == "failed" ? "--" : row.paid_amount ? currencySymbol + row.paid_amount : "--",
      sortable: true,
    },    
    {
      name: t("Receive_date"),
      selector: (row) => {
        const date = new Date(row.payment_date);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };

        // Check if the date is invalid or if it's 1970
        if (isNaN(date) || date.getFullYear() === 1970) {
          return '--';
        }

        return date.toLocaleString('en-US', options);
      },
      sortable: true,
    },
    {
      name: t("Payment_status"),
      selector: (row) => row.payment_status == "failed" ? "Cancel" : row.payment_status,
      sortable: true,
    },
    {
      name: t("Payment_id"),
      selector: (row) => row.payment_id || "--",
      sortable: true,
    },
    {
      name: t("Remarks"),
      // selector: (row) => row.payment_remarks || "--",
      sortable: true,
      cell: (row) => 
        row.payment_remarks ? (
          <>
            <i
              className="first fas fa-eye"
              onClick={() => handleViewMessage(row)}
              style={{ cursor: "pointer", marginRight: "10px" }}
            ></i>
          </>
        ) : (
          "--"
      ),
    },

  ];

  const customStyles = {

    headRow: {
      style: {
        display: "flex",
        alignItems: "stretch",
        width: "100%",
        backgroundColor: "#E9E9E9",
        minHeight: "52px",
        borderRadius: "2px 2px 0px 0px",
        padding: "8px 25px",
        border: "none",

      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "52px",
        fontSize: "15px",

      },
    },
    rows: {
      style: {
        padding: "20px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "48px ",
      },
    },
  };

  const tableData = {
    columns,
    data,
  };
   const paginationOptions = {
    rowsPerPageText: t("Rows_per_page"),
    rangeSeparatorText: t("Of"),
    selectAllRowsItem: true,
    selectAllRowsItemText: t("All"),
  };
  

  return (
    <>
      <PageName PageName="Influencer Dashboard" />
      <div className="row Greeting_total_wrapper">
        <div className="col" id="grettingWrapper">
          {!!influencerData &&
          <div className="welcom ">
            <div class="influencersdetails-topdetails">
              {/* <h3>{GreatingMsg}akjshdka jshdkajsdh kajsdhaksjdh aksjdh aksdjh</h3> */}
              <h3 style={{padding:"10px 0 20px 0", display:"flex"}}> {t("Welcome_aboard")} <div style={{color:"#5161de",textTransform: "uppercase", padding:"0 5px"}}> { influencerData?.name},</div>  {t("Welcome_aboard_line")} </h3>
              <div className="tile bbox Total_Titlebox_container">
              <div style={{display:"flex", margin:"10px 0px 0px 0px"}}>
                <h3 
                  style={{color:"black",fontWeight:"bold",fontSize:"28px",margin: "4px 0px 10px 20px", textTransform:"capitalize"}}>       
                  {influencerData?.name}
                </h3>
              </div>
              <div style={{display:'flex',flexWrap:'wrap',gap:'5px',justifyContent:'space-between',maxWidth:'80%'}}>
                <div style={{ display: 'flex', alignItems: 'center', flex: '1 1 calc(40% - 10px)' }}>
                  <i className="fa fa-briefcase" style={{fontSize: '20px',display:'flex',background:'none',color:'gray' }}></i>
                  {t("Business_name")}:<h3 style={{color:'black', padding: "0 0 0 10px"}}> {influencerData?.businesses?.title}</h3>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', flex: '1 1 calc(40% - 10px)' }}>
                  <i className="fa fa-link" style={{ fontSize: '20px',display:'flex',background:'none',color:'gray' }}></i>
                  {t("Business_link")}:<h3 style={{color:'black', padding: "0 0 0 10px"}}> {infBusinessLink}  &nbsp;&nbsp;<span style={{cursor:"pointer"}}  onClick={()=>handleCopyClick(infBusinessLink)}><i className="fa-solid fa-copy"></i></span></h3>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', flex: '1 1 calc(40% - 10px)' }}>
                <i className="fa-brands fa-youtube" style={{ fontSize: '20px',display:'flex',background:'none',color:'gray'  }}></i>
                {t("Youtube_subscriber")}:<h3 style={{color:'black',padding: '0px 0px 0px 10px'}}> {formatSubscribers(youtubeSubscribe)}</h3>
                </div>
              </div>
              </div>
            </div>
          </div>
          }
        </div>
        {/* <div className="col-md-7" id="total_wrapper_container"></div> */}
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-3">
              <div className="tile bbox Total_Titlebox_container">
                <i className="fa-solid fa-business-time"></i>
                <h3>{t("Total_orders")} </h3>
                
                <h4>{totalOrders || 0}</h4>
              </div>
            </div>
            <div className="col-md-3">
              <div className="tile bbox Total_Titlebox_container">
                <i className=" fa-regular fa-handshake"></i>
                <h3>{t("Pending_deliveries")}</h3>
               
                <h4>{totalPendingOrders || 0}</h4>
              </div>
            </div>
            <div className="col-md-3">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <h3>{t("Total_delivered_orders")}</h3>
                
                <h4>{totalDeliveredOrders || 0}</h4>
              </div>
            </div>

            <div className="col-md-3">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <h3>{t("Total_sales")}</h3>
                
                <h4>{currencySymbol}{totalSales || 0}</h4>
              </div>
            </div>

            <div className="col-md-3">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <div className="tooltip-wrapper">
  <h3 className="tooltipcontainer_infdash">
    {t("Total_earning")}
    <div className="tooltipDiv">
     <span className="tooltip-target"> <img loading="lazy" className="tooltipimg" src={TooltipImg}  style={{width:'18px'}}/>  </span>
    <span className="tooltip ">{t("Total_earning_tooltip")} </span></div>
  </h3>
                </div>
                <h4>{currencySymbol}{!!totalEarning ? Number(totalEarning)?.toFixed(2) :0 || 0}</h4>
                {/* <p>25% of Total Sales</p> */}
              </div>
            </div>

            {/* <div className="col-md-3">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <h3>Total Claimable Amount</h3>
                <h4>{currencySymbol}{totalClaimable || 0}</h4>
              </div>
            </div>       */}

              
            <div className="col-md-3">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <div className="tooltip-wrapper">
                    <h3 className="tooltipcontainer_infdash">
                      {t("Total_claimable_amount")} 
                      <div className="tooltipDiv"><span className="tooltip-target"> <img loading="lazy" className="tooltipimg" src={TooltipImg}  style={{width:'18px'}}/>  </span>
                      <span className="tooltip"
                      //  style={{top:'-100%',left:'90%'}}
                       >{t("Total_claim_amount_tooltip1")} <br/>
                  {t("Total_claim_amount_tooltip2")}</span></div>
                    </h3>
                  </div>
                {/* <h4>{currencySymbol}{influencerData?.totalClaimable || 0}</h4> */}
                <div className="Remainingclaimableamtdiv">
                  <h4 className="Remainingclaimableamt">
                    {currencySymbol}{(!!totalClaimable && totalClaimable>0)?(totalClaimable - withdrawn)?.toFixed(2):0} 
                    <span className={((totalClaimable - withdrawn) === 0 || !!data && data?.length>0 && data[0]?.payment_status == 'pending' ||  (totalClaimable - withdrawn) < 2000 )?"disableclaimbtnspan":"claimbtnspan"} 
                    
                    onClick={handleClickOpen}
                    >
                      <button className="claimbtn"> {t("Claim")} </button>
                    </span>
                  </h4>
                  {!!data && data?.length>0 && data[0]?.payment_status == 'pending'?<div className="lastrequest">{t("Last_request")} <span style={{fontWeight:"700"}}>{currencySymbol} {data[0]?.requested_amount}</span> {t("Under_process")}</div>:""}
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                {/* <h3>Withdrawn Amount</h3> */}
                <div className="tooltip-wrapper">
  <h3 className="tooltipcontainer_infdash">
    {t("Withdrawn_amount")}
    <div className="tooltipDiv"><span className="tooltip-target"> <img loading="lazy" className="tooltipimg" src={TooltipImg}  style={{width:'18px'}}/>  </span>
    <span className="tooltip" 
    // style={{left:'89%', top:'-100%'}}
    
    >{t("Total_withdrawn_amount_tooltip")} </span></div> 
  </h3>
                </div>
                <h4>{currencySymbol}{Number(withdrawn)?.toFixed(2) || 0}</h4>
              </div>
            </div>
          </div>



          <div className="main inf_dash">
            <h3 className="payin-payout-request-heading">{t("Claim_request_list")}</h3>
            {isLoading ? (
              <CircularLoader size={40} />
            ) : (
              <DataTableExtensions
                {...tableData}
                filterPlaceholder="Search Coupon"
                customStyles={customStyles}
                {...tableExtensions}
                className="extensionscls"
              >
                <DataTable
                  columns={columns}
                  data={data}
                  noHeader
                  defaultSortField="id"
                  sortIcon={<SortIcon />}
                  defaultSortAsc={true}
                  pagination
                  highlightOnHover
                  dense
                   paginationComponentOptions={{
                        ...paginationOptions,
                      }}
                  customStyles={customStyles}
                  noDataComponent={<div style={{padding:"24px"}}>{t("No_records_found")}</div>}
                />
              </DataTableExtensions>
            )}
          </div>


            
              
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  >
                  <div className="claimablepopbox">
                    <h3>{t("Confirm_payout_request")}</h3>
                    <div className="requestpopbox"> 
                      <h4>{t("Total_claimable_amount")} </h4>
                      <h5>{currencySymbol}{(!!totalClaimable && totalClaimable>0)?(totalClaimable - withdrawn).toFixed(2):0}</h5>
                    </div>
                    <div className="requestpopbox"> 
                      <h4>{t("Total_earning")}</h4>                      
                      <h5>{currencySymbol}{!!totalEarning ? Number(totalEarning)?.toFixed(2) : 0 || 0}</h5>
                    </div>
                    <div className="requestpayout" onClick={ClaimHandler} autoFocus>
                      {t("Request_for_payout")}
                    </div>
                    <div className="requestpayoutcancel" onClick={handleClose}>{t("Cancel")}
                    </div>
                  </div>
                </Dialog>
            



                <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={openRemarks}
        onClose={() => hideModal()}
        // onClose={() => setOpen(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        // style={{ outline: "none" }}
        // style={{overflow:"scroll", outline:"none"}}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            minWidth:390,
            borderRadius: "md",
            boxShadow: "lg",
            maxHeight: 300, // Limit the height
            overflow: "auto", // Enable scrolling if content exceeds height
            p: 2, // Reduce padding
          }}
          className="Infproductdetails"
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={2}
            textAlign="center"
          >
            {t("Remarks")}
          </Typography>
    
            <div>
              <div>{selectedMessage?.payment_remarks}</div>             
            </div>
        </Sheet>
      </Modal>


        </div>
      </div>
    </>
  );
}
export default InfluencerDash;
//1112