import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { userContext } from "../../context/userContext";
import axios from "axios";
import { environmentVariables } from "../../config/env.config";
import "./../Pages/Updatedcss/HeaderUpdatedStyle.css";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { businessContext } from "../../context/businessContext";
import { useTranslation } from "react-i18next";

const LogoutButtonContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    margin-left: 20px;
  }
`;

const LogoutButton = styled.div`
  border: none;
  border-radius: 6px;
  color: #fff;
  font-weight: 700;
  margin-top: 10px;
  padding: 10px 14px;
  background: linear-gradient(to right, #01c3ccdb, #2a76e8f2);
`;

function Sidebar({ isSidebarVisible, handleToggleSidebar }) {
  const { t } = useTranslation();
  const sidebarClass = `app-sidebar ${isSidebarVisible ? "visible" : ""}`;
  const { userData, setUserData } = useContext(userContext);
  const { businessData, setBusinessData } = useContext(businessContext);
  const storedData = localStorage.getItem("user");
  const userDataObj = JSON.parse(storedData);
  const [userPermission, setUserPermission] = useState([]);
  const user_type = userDataObj?.user_type;
  const [planName, setPlanName] = useState("");
  const baseUrl = environmentVariables?.apiUrl;
  const isMobileDevice = window.innerWidth <= 768;
  useEffect(() => {
    if (userData && userData.id) {
      setUserPermission(userData?.permission);
    }
  }, [userData]);
  const Dropmenu = (event) => {
    event?.currentTarget?.classList.toggle("is-expanded");
    removeActive();
  };

  const [expandedItems, setExpandedItems] = useState([]);
  const handleToggle = (event, item) => {
    event.preventDefault();
    if (expandedItems.includes(item)) {
      setExpandedItems(
        expandedItems.filter((expandedItem) => expandedItem !== item)
      );
    } else {
      setExpandedItems([item]);
      setTimeout(()=>{
        removeActive();
      },500);
    }
  };

  const removeActive = ()=>{
    // Fetch all elements with the class name 'target-class'
    const elements = document.querySelectorAll('.app-menu__item.active');
    // Loop through the elements and remove the 'active' class
    elements.forEach(element => {
      if(element.classList.contains('active')){
        element.classList.remove('active');
      }
    });
    return true;
  }

  const getPlanName = () => {
    axios
      .get(`${baseUrl}api/v1/plan/fetchPlanByIdForUser`, {
        params: { planId: userData?.plan },
        headers: {
          _token: userData?.token,
        },
      })
      .then((res) => {
        setPlanName(res?.data?.result?.name);
      })
      .catch((err) => {
        console.log({ err: err });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // console.log("one time only",userData?.plan)
    getPlanName();
  }, []);
  useEffect(()=>{
    // console.log("at time of userData",userData?.plan)
    getPlanName();
  },[userData])
  const handleLogout = () => {
    localStorage.clear();
    setUserData(null);
  };

  const addExpand = ()=>{
    const elements = document.querySelectorAll('.is-expanded');
  }

  const MenuAdmin=()=>{
    return ( <>
    <li>
      <NavLink
        to="/super_dashboard"
        className="app-menu__item"
        onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
      >
        <i className="app-menu__icon fa fa-home"></i>
        <span className="app-menu__label">Dashboard</span>
      </NavLink>
    </li>
    <li>
      <NavLink
        to="/Influencers"
        className="app-menu__item"
        onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
      >
        <i className="app-menu__icon fa fa-user-circle"></i>
        <span className="app-menu__label">Influencers</span>
      </NavLink>
    </li>
    <li>
      <NavLink
        to="/businesslist"
        className="app-menu__item"
        onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
      >
        <i className="app-menu__icon fa-solid fa-business-time"></i>
        <span className="app-menu__label">Business</span>
      </NavLink>
    </li>
    {/* <li 
      className={`treeview ${
        expandedItems.includes("item1") ? "is-expanded" : ""
      }`}
      onClick={Dropmenu}
    >
      <a className="app-menu__item" data-toggle="treeview" onClick={(e) => handleToggle(e, "item1")}>
        <i className="app-menu__icon fa-solid fa-users"></i>
        <span className="app-menu__label">Staff</span>
        <i className="treeview-indicator bi bi-chevron-right"></i>
      </a>
      <ul className="treeview-menu">
        <li>
          <NavLink
            to="/Roles"
            className="treeview-item"
            onClick={() => addExpand()}
          >
            <div className="pointcircal"></div> Roles
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/user"
            className="treeview-item"
            onClick={() => isMobileDevice && handleToggleSidebar()}
          >
            <div className="pointcircal"></div> Users
          </NavLink>
        </li>
      </ul>
    </li> */}

    <li 
      className={`treeview ${
        expandedItems.includes("item2") ? "is-expanded" : ""
      }`}
      onClick={Dropmenu}
    >
      <a className="app-menu__item" data-toggle="treeview" onClick={(e) => handleToggle(e, "item2")}>
        <i className="app-menu__icon fa-solid fa-users"></i>
        <span className="app-menu__label">User Management</span>
        <i className="treeview-indicator bi bi-chevron-right"></i>
      </a>
      <ul className="treeview-menu">
        <li>
          <NavLink  
            to="/businessowner"
            className="treeview-item"
            onClick={() => isMobileDevice && handleToggleSidebar()}
          >
            <div className="pointcircal"></div> Business Owners
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/staff"
            className="treeview-item"
            onClick={() => isMobileDevice && handleToggleSidebar()}
          >
            <div className="pointcircal"></div> Staffs
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/Roles"
            className="treeview-item"
            onClick={() => addExpand()}
          >
            <div className="pointcircal"></div> Roles
          </NavLink>
        </li>
      </ul>
    </li>

    <li 
      className={`treeview ${
        expandedItems.includes("item3") ? "is-expanded" : ""
      }`}
       onClick={Dropmenu}
    >
      <a className="app-menu__item" data-toggle="treeview" onClick={(e) => handleToggle(e, "item3")}>
        <i className="app-menu__icon fa-solid fa-credit-card"></i>
        <span className="app-menu__label">Physical Card</span>
        <i className="treeview-indicator bi bi-chevron-right"></i>
      </a>
      <ul className="treeview-menu">
        <li>
          <NavLink 
            className="treeview-item" 
            to="/requestCard_admin" 
            onClick={() =>
                  isMobileDevice && handleToggleSidebar()
                }
          >
            <div className="pointcircal"></div> Orders
          </NavLink>
        </li>
      </ul>
    </li>
    <li>
      <NavLink
        to="/PlansSuperAdmin"
        className="app-menu__item"
        href="docs.html"
        onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
      >
        <i className="app-menu__icon bi bi-ui-checks"></i>
        <span className="app-menu__label">Plans</span>
      </NavLink>
    </li>
    <li>
      <NavLink
        to="/AppointmentSuperAdmin"
        className="app-menu__item"
        href="docs.html"
        onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
      >
        <i className="app-menu__icon fa-regular fa-handshake"></i>
        <span className="app-menu__label">Appointments</span>
      </NavLink>
    </li>

    <li>
      <NavLink
        to="/superadminhiremedetails"
        className="app-menu__item"
        href="docs.html"
        onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
      >
        <i className="app-menu__icon bi bi-ui-checks"></i>
        <span className="app-menu__label">Influencer Hire Request</span>
      </NavLink>
    </li>
    <li>
      <NavLink
        to="/Influencerorder_admin"
        className="app-menu__item"
        href="docs.html"
        onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
      >
        <i className="app-menu__icon bi bi-ui-checks"></i>
        <span className="app-menu__label">Influencer Orders</span>
      </NavLink>
    </li>
    <li>
      <NavLink
        to="/influencerpayments"
        className="app-menu__item"
        href="docs.html"
        onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
      >
        <i className="app-menu__icon fa fa-credit-card"></i>
        <span className="app-menu__label">Influencer Transactions</span>
      </NavLink>
    </li>
    <li>
      <NavLink
        to="/productorders"
        className="app-menu__item"
        href="docs.html"
        onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
      >
        <i className="app-menu__icon bi bi-ui-checks"></i>
        <span className="app-menu__label">Product Orders </span>
      </NavLink>
    </li>
    <li>
      <NavLink
        to="/producttransaction"
        className="app-menu__item"
        href="docs.html"
        onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
      >
        <i className="app-menu__icon fa fa-credit-card"></i>
        <span className="app-menu__label">Product Transactions</span>
      </NavLink>
    </li>
    {/* <li>
      <NavLink
        to="/CustomizedPlansSuperAdmin"
        className="app-menu__item"
        href="docs.html"
      >
        <i className="app-menu__icon bi bi-ui-checks"></i>
        <span className="app-menu__label">Customized Plans</span>
      </NavLink>
    </li> */}


    <li>
      <NavLink
        to="/Coupons_admin"
        className="app-menu__item"
        href="docs.html"
        onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
      >
        <i className="app-menu__icon fa-solid fa-gift"></i>
        <span className="app-menu__label">Coupons</span>
      </NavLink>
    </li>
    <li>
      <NavLink
        to="/Events_admin"
        className="app-menu__item"
        href="docs.html"
        onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
      >
        <i className="app-menu__icon fa fa-calendar"></i>
        <span className="app-menu__label">Events</span>
      </NavLink>
    </li>
    <li>
      <NavLink
        to="/Plan_enquiry"
        className="app-menu__item"
        href="docs.html"
        onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
      >
        <i className="app-menu__icon fa fa-question-circle"></i>
        <span className="app-menu__label">Custom Plan Enquiries</span>
      </NavLink>
    </li>
    <li>
      <NavLink
        to="/Businessbay_enquiry"
        className="app-menu__item"
        href="docs.html"
        onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
      >
        <i className="app-menu__icon fa fa-question-circle"></i>
        <span className="app-menu__label">Businessbay Enquiries</span>
      </NavLink>
    </li>
    <li>
      <NavLink
        to="/LandingPage_enquiry"
        className="app-menu__item"
        href="docs.html"
        onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
      >
        <i className="app-menu__icon fa fa-question-circle"></i>
        <span className="app-menu__label">LandingPage Enquiries</span>
      </NavLink>
    </li>

      {user_type == "super_admin" && (
        <>
          <li
            className={`treeview ${expandedItems.includes("enquiry") ? "is-expanded" : ""
              }`}
            onClick={Dropmenu}
          >
            <a className="app-menu__item" data-toggle="treeview" onClick={(e) => handleToggle(e, "enquiry")}>
            <i className="app-menu__icon fa fa-question-circle"></i>
            <span className="app-menu__label">Enquiries</span>
              <i className="treeview-indicator bi bi-chevron-right"></i>
            </a>
            <ul className="treeview-menu">
              <li>
                <NavLink
                  to="/CabEnquiry"
                  className="treeview-item"
                  href="form-samples.html"
                  onClick={() => isMobileDevice && handleToggleSidebar()}
                >
                  <div className="pointcircal"></div> Cab Enquiries
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/MinimarketEnquiry"
                  className="treeview-item"
                  href="form-components.html"
                  onClick={() => isMobileDevice && handleToggleSidebar()}
                >
                  <div className="pointcircal"></div> Mini Market Enquiries
                </NavLink>
              </li>
            </ul>
          </li>
        </>
      )}

    <li>
      <NavLink
        to="/Currency_admin"
        className="app-menu__item"
        href="docs.html"
        onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
      >
        <i className="app-menu__icon fa-solid fa-indian-rupee-sign"></i>
        <span className="app-menu__label">Currency</span>
      </NavLink>
    </li>
    <li>
      <NavLink to="/addPhysicalCard" className="app-menu__item" onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}>
        <i className="app-menu__icon fa-regular fa-credit-card"></i>
        <span className="app-menu__label">Add Physical Card</span>
      </NavLink>
    </li>
    <li>
    <NavLink
          to="/addshipping"
          className="app-menu__item"
          onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
        >
          <i className="app-menu__icon fa-solid fa-plus"></i>
          <span
            className="app-menu__label"
            style={{ textAlign: "start" }}
          >
            Add shipping
          </span>
        </NavLink>
    </li>

    <li 
      className={`treeview ${
        expandedItems.includes("item4") ? "is-expanded" : ""
      }`}
      onClick={Dropmenu} 
    >
      <a className="app-menu__item" data-toggle="treeview" onClick={(e) => handleToggle(e, "item4")}>
        <i className="app-menu__icon fa-solid fa-users"></i>
        <span className="app-menu__label">Bulk</span>
        <i className="treeview-indicator bi bi-chevron-right"></i>
      </a>
      <ul className="treeview-menu">
        <li>
          <NavLink
            to="/bulk_upload"
            className="treeview-item"
            href="form-samples.html"
            onClick={() => isMobileDevice && handleToggleSidebar()}
          >
            <div className="pointcircal"></div> Bulk Upload
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/userUploadList"
            className="treeview-item"
            href="form-components.html"
            onClick={() => isMobileDevice && handleToggleSidebar()}
          >
            <div className="pointcircal"></div> Imported Users
          </NavLink>
        </li>
      </ul>
    </li>
    <li>
      <NavLink
        to="/Setting_admin"
        className="app-menu__item"
        href="docs.html"
        onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
      >
        <i className="app-menu__icon fa-solid fa-gear"></i>
        <span className="app-menu__label">Settings</span>
      </NavLink>
    </li>
    <li>
      <NavLink
        to="/Menubanner_admin"
        className="app-menu__item"
        href="docs.html"
        onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
      >
        <i className="app-menu__icon fa-solid fa-synagogue"></i>
        <span className="app-menu__label">Manage Ads</span>
      </NavLink>
    </li>

    <li>
      <NavLink
        to="/create_pay_link"
        className="app-menu__item"
        href="docs.html"
        onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
      >
        <i className="app-menu__icon fa-solid fa-synagogue"></i>
        <span className="app-menu__label">Create Payment Link</span>
      </NavLink>
    </li>

    <li style={{paddingBottom:'25px'}}>
      <NavLink
        to="/mailtemplate"
        className="app-menu__item"
        href="docs.html"
        onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
      >
        <i className="app-menu__icon fa-solid fa-synagogue"></i>
        <span className="app-menu__label">Mail Template</span>
      </NavLink>
    </li>


  </>)
  }

  const removeExpand = ()=>{
    setExpandedItems([]);
  }

  const checkRestuarentBusiness = () => {
    let themeColor = "theme14";
    const filteredData = businessData.filter(item => {
      const cardTheme = JSON.parse(item.card_theme);
      return cardTheme.theme === themeColor;
    });
    return filteredData.length > 0;
  };
  const checkMiniMarketOrders = () => {
    let themeColor = "theme20";
    const filteredData = businessData.filter(item => {
      const cardTheme = JSON.parse(item.card_theme);
      return cardTheme.theme === themeColor;
    });
    return filteredData.length > 0;
  };

  

  const checkCabBusiness = () => {
    let themeColor = "theme22";
    const filteredData = businessData.filter(item => {
      const cardTheme = JSON.parse(item.card_theme);
      return cardTheme.theme === themeColor;
    });
    return filteredData.length > 0;
  };

  const checkMinimarketBusiness = () => {
    let themeCabColor = "theme22";    
    let themerestaurantColor = "theme14"; 
           
    const filteredData = businessData.filter(item => {
      return (item.theme_color !== themeCabColor && item.theme_color !== themerestaurantColor);
    });
    
    return filteredData.length > 0;
  };
  

  const MenuUser=()=>{
    
    return ( <>
    {userDataObj?.created_by != 0 ? (
      userPermission &&
      userPermission.length &&
      userDataObj?.created_by != 0 &&
      userPermission.some((obj) =>
        obj.permission_id==1
      ) ? (
        <li>
          <NavLink
          to={user_type === 'Influencer' ? "/influencer_dashboard" : (user_type === 'Team Leader' || user_type === 'Sales Executive' ? "/staff_dashboard" : "/dashboard")}
            className="app-menu__item"
            // href="dashboard.html"
            onClick={() => isMobileDevice && handleToggleSidebar()}
          >
            <i className="app-menu__icon fa fa-home"></i>
            <span className="app-menu__label"> {t("Dashboard")} </span>
            {(user_type !== 'Influencer' && user_type !== 'Team Leader' && user_type !== 'Sales Executive') && <span className="plan_name">{planName}</span>}
          </NavLink>
        </li>
      ) : (
        ""
      )
    ) : (
      <li>
        <NavLink
          to="/dashboard"
          className="app-menu__item"
          // href="dashboard.html"
          onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
          // onClick={() => isMobileDevice && handleToggleSidebar()}
        >
          <i className="app-menu__icon fa fa-home"></i>
          <span
            className="app-menu__label"
            style={{ textAlign: "start" }}
          >
            {" "}
            {t("Dashboard")}{" "}
          </span>
          {(user_type !== 'Team Leader' || user_type !== 'Sales Executive') && <span className="plan_name">{planName}</span>}
        </NavLink>
      </li>
    )}
    {userDataObj?.created_by != 0 ? (
      userPermission &&
      userPermission.length &&
      userPermission.some(
        (obj) =>
          obj.permission_id==17 || obj.permission_id==18
      ) ? (
        <li>
          <NavLink
            to="/businesslist"
            className="app-menu__item"
            // href="dashboard.html"
            onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
          >
            <i className="app-menu__icon fa-solid fa-business-time"></i>
            <span className="app-menu__label">{t("Business")}</span>
          </NavLink>
        </li>
      ) : (
        ""
      )
    ) : (
      <li>
        <NavLink
          to="/businesslist"
          className="app-menu__item"
          // href="dashboard.html"
          onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
        >
          <i className="app-menu__icon fa-solid fa-business-time"></i>
          <span
            className="app-menu__label"
            style={{ textAlign: "start" }}
          >
            {t("Business")}
          </span>
        </NavLink>
      </li>
    )}
    {
      user_type == 'Influencer' &&
      <ul>
      <li>
        <NavLink
          to="/influencer_orders"
          className="app-menu__item"
          href="docs.html"
          onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
        >
          <i className="app-menu__icon fa-solid fa-tasks"></i>
          <span className="app-menu__label">{t("Product_orders")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/influencer_Hireme"
          className="app-menu__item"
          href="docs.html"
          onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
        >
          <i className="app-menu__icon fa-solid fa-tasks"></i>
          <span className="app-menu__label">{t("Influencer_hire_details")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/influencer_coupons"
          className="app-menu__item"
          href="docs.html"
          onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
        >
          <i className="app-menu__icon fa-solid fa-gift"></i>
          <span className="app-menu__label">{t("Influencer_coupons")}</span>
        </NavLink>
      </li>
      </ul>
    }
    {(user_type === 'Team Leader' || user_type === 'Sales Executive')? 
    <li 
      className={`treeview ${
        expandedItems.includes("item2") ? "is-expanded" : ""
      }`}
      onClick={Dropmenu}
    >
      <a className="app-menu__item" data-toggle="treeview" onClick={(e) => handleToggle(e, "item2")}>
        <i className="app-menu__icon fa-solid fa-users"></i>
        <span className="app-menu__label">{t("User_management")}</span>
        <i className="treeview-indicator bi bi-chevron-right"></i>
      </a>
      <ul className="treeview-menu">
        <li>
          <NavLink  
            to="/businessowner"
            className="treeview-item"
            onClick={() => isMobileDevice && handleToggleSidebar()}
          >
            <div className="pointcircal"></div> {t("Business_owners")}
          </NavLink>
        </li>
        {user_type === 'Team Leader'?
        <li>
          <NavLink
            to="/staff"
            className="treeview-item"
            onClick={() => isMobileDevice && handleToggleSidebar()}
          >
            <div className="pointcircal"></div> {t("Staffs")}
          </NavLink>
        </li>
        :""
        }
      </ul>
    </li>
    :""}


   { (userDataObj?.created_by != 0
      ? (userPermission &&
          userPermission.some((obj) => obj.permissionobj?.name?.includes("create role"))) ||
        (userPermission &&
          userPermission.some((obj) => obj.permissionobj?.name?.includes("create user")))
      : true) &&( <li
      className={`treeview ${
        expandedItems.includes("item1") ? "is-expanded" : ""
      }`}
    >
      {userDataObj?.created_by != 0 ? (
        userPermission &&
        userPermission.length &&
        userPermission.some(
          (obj) =>
            obj.permissionobj?.name?.includes("create user") ||
            obj.permissionobj?.name?.includes("create role")
        ) ? (
          <a
            className="app-menu__item"
            onClick={(e) => handleToggle(e, "item1")}
            data-toggle="treeview"
          >
            <i className="app-menu__icon fa-solid fa-users"></i>
            <span className="app-menu__label">{t("Staff")}</span>
            <i className="treeview-indicator bi bi-chevron-right"></i>
          </a>
        ) : 
          null
        
      ) : (
        <a
          className="app-menu__item"
          onClick={(e) => handleToggle(e, "item1")}
          data-toggle="treeview"
        >
          <i className="app-menu__icon fa-solid fa-users"></i>
          <span
            className="app-menu__label"
            style={{ textAlign: "start" }}
          >
            {t("Staff")}
          </span>
          <i className="treeview-indicator bi bi-chevron-right"></i>
        </a>
      )}
      {(userDataObj?.created_by != 0
      ? (userPermission &&
          userPermission.some((obj) => obj.permissionobj?.name?.includes("create role"))) ||
        (userPermission &&
          userPermission.some((obj) => obj.permissionobj?.name?.includes("create user")))
      : true) && (<ul className="treeview-menu">
        {userDataObj?.created_by != 0 ? (
          userPermission &&
          userPermission.length &&
          userPermission.some((obj) =>
            obj?.permissionobj?.name?.includes("create role")
          ) ? (
            <li>
              <NavLink
                to="/Roles"
                className="treeview-item"
                href="form-samples.html"
                onClick={() =>
                  isMobileDevice && handleToggleSidebar()
                }
              >
                <div className="pointcircal"></div> {t("Roles")}
              </NavLink>
            </li>
          ) : (
            ""
          )
        ) : (
          <li>
            <NavLink
              to="/Roles"
              className="treeview-item"
              href="form-samples.html"
              onClick={() => isMobileDevice && handleToggleSidebar()}
            >
              <div className="pointcircal"></div> {t("Roles")}
            </NavLink>
          </li>
        )}
        {userDataObj?.created_by != 0 ? (
          userPermission &&
          userPermission.length &&
          userPermission.some((obj) =>
            obj.permissionobj?.name?.includes("create user")
          ) ? (
            <li>
              <NavLink
                to="/user"
                className="treeview-item"
                href="form-components.html"
                onClick={() =>
                  isMobileDevice && handleToggleSidebar()
                }
              >
                <div className="pointcircal"></div> {t("Users")}
              </NavLink>
            </li>
          ) : (
            ""
          )
        ) : (
          <li>
            <NavLink
              to="/user"
              className="treeview-item"
              href="form-components.html"
              onClick={() => isMobileDevice && handleToggleSidebar()}
            >
              <div className="pointcircal"></div> {t("Users")}
            </NavLink>
          </li>
        )}
      </ul>)}
    </li>) } 
    {(userDataObj?.created_by != 0
      ? (userPermission &&
          userPermission.some((obj) => obj.permissionobj?.name?.includes("create role"))) ||
        (userPermission &&
          userPermission.some((obj) => obj.permissionobj?.name?.includes("create user")))
      : true)&& <li
      className={`treeview ${
        expandedItems.includes("item2") ? "is-expanded" : ""
      }`}
    >
      {userDataObj?.created_by != 0 ? (
        userPermission &&
        userPermission?.length &&
        userPermission?.some(
          (obj) =>
          obj.permission_id==97 ||
          obj.permission_id==96
        ) ? (
          <a
            className="app-menu__item"
            onClick={(e) => handleToggle(e, "item2")}
            data-toggle="treeview"
          >
            <i className="app-menu__icon fa-solid fa-credit-card"></i>
            <span className="app-menu__label">{t("Physical_Card")}</span>
            <i className="treeview-indicator bi bi-chevron-right"></i>
          </a>
        ) : (
          ""
        )
      ) : (
        <a
          className="app-menu__item"
          onClick={(e) => handleToggle(e, "item2")}
          data-toggle="treeview"
        >
          <i className="app-menu__icon fa-solid fa-credit-card"></i>
          <span
            className="app-menu__label"
            style={{ textAlign: "start" }}
          >
            {t("Physical_Card")}
          </span>
          <i className="treeview-indicator bi bi-chevron-right"></i>
        </a>
      )}
      <ul className="treeview-menu">
        {userDataObj?.created_by != 0 ? (
          userPermission &&
          userPermission.length &&
          userPermission.some((obj) =>
            obj.permission_id==96
          ) ? (
            <li>
              <NavLink
                className="treeview-item"
                to="/create-card"
                onClick={() =>
                  isMobileDevice && handleToggleSidebar()
                }
              >
                <div className="pointcircal"></div>{t("Create_card")}{" "}
              </NavLink>
            </li>
          ) : (
            ""
          )
        ) : (
          <>
            <li>
              <NavLink
                className="treeview-item"
                to="/create-card"
                onClick={() =>
                  isMobileDevice && handleToggleSidebar()
                }
              >
                <div className="pointcircal"></div>{t("Create_card")}{" "}
              </NavLink>
            </li>
          </>
        )}
        <li>
          <NavLink
            className="treeview-item"
            to="/requestCard"
            onClick={() => isMobileDevice && handleToggleSidebar()}
          >
            <div className="pointcircal"></div> {t("Orders")}
          </NavLink>
        </li>
      </ul>
    </li>}
    {userDataObj?.created_by != 0 ? (
      userPermission &&
      userPermission?.length &&
      userPermission?.some((obj) =>
        obj.permissionobj?.name?.includes("manage plan")
      ) ? (
        ""
      ) : (
        ""
      )
    ) : (
      <li>
        <NavLink
          to="/plans"
          className="app-menu__item"
          href="docs.html"
          onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
        >
          <i className="app-menu__icon fa-regular fa-handshake"></i>
          <span
            className="app-menu__label"
            style={{ textAlign: "start" }}
          >
            {t("Plans")}
          </span>
        </NavLink>
      </li>
    )}
    
    {userDataObj?.user_type != 'Sales Executive' && userDataObj?.user_type != 'Team Leader' ? 
      userDataObj?.created_by != 0 ? (
      userPermission &&
      userPermission.length &&
      userPermission.some(
        (obj) =>
          obj.permission_id==27 || obj.permission_id==25
      ) ? (
        <>
          <li>
          <NavLink
            to="/appoinments"
            className="app-menu__item"
            href="docs.html"
            onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
          >
            <i className="app-menu__icon fa-regular fa-handshake"></i>
            <span className="app-menu__label">{t("Appointments")}</span>
          </NavLink>
        </li>
        </>
      ) : (
        ""
      )
    ) : (
      <>
        <li>
          <NavLink
            to="/appoinments"
            className="app-menu__item"
            href="form-samples.html"
            onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
          >
            <i className="app-menu__icon fa-solid fa-handshake"></i>
            <span
              className="app-menu__label"
              style={{ textAlign: "start" }}
            >
              {t("Appointments")}
            </span>
          </NavLink>
        </li>
      </>
    ):''}

    

    {userDataObj?.user_type != 'Sales Executive' && userDataObj?.user_type != 'Team Leader' ? 
    userDataObj?.created_by != 0 ? (
      userPermission &&
      userPermission.length &&
      userPermission.some(
        (obj) =>
          obj.permission_id==77 || obj.permission_id==78
      ) ? (
        <li>
          <NavLink
            to="/contact"
            className="app-menu__item"
            href="docs.html"
            onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
          >
            <i className="app-menu__icon fa-solid fa-address-book"></i>
            <span className="app-menu__label">{t("Contacts")}</span>
          </NavLink>
        </li>
      ) : (
        ""
      )
    ) : (
      <li>
        <NavLink
          to="/contact"
          className="app-menu__item"
          href="docs.html"
          onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
        >
          <i className="app-menu__icon fa-solid fa-address-book"></i>
          <span
            className="app-menu__label"
            style={{ textAlign: "start" }}
          >
            {t("Contacts")}
          </span>
        </NavLink>
        <NavLink
          to="/profile"
          className="app-menu__item"
          onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
        >
          <i className="app-menu__icon fa-solid fa-user-circle"></i>
          <span
            className="app-menu__label"
            style={{ textAlign: "start" }}
          >
            {t("Profile")}
          </span>
        </NavLink>
        
        {businessData.length > 0 && checkRestuarentBusiness() ? (
        <NavLink
          to="/restaurantorders"
          className="app-menu__item"
          onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
        >
          <i className="app-menu__icon fa fa-shopping-cart"></i>
          <span
            className="app-menu__label"
            style={{ textAlign: "start" }}
          >
            {t("Orders")}
          </span>
        </NavLink>):null}

        {businessData.length > 0 && checkMinimarketBusiness() ? (
        <NavLink
          to="/MinimarketEnquiry"
          className="app-menu__item"
          onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
        >
          <i className="app-menu__icon fa fa-question-circle"></i>
          <span
            className="app-menu__label"
            style={{ textAlign: "start" }}
          >
            {t("Enquiry")}
          </span>
        </NavLink>):null}

        {businessData.length > 0 && checkMiniMarketOrders() ? (
        <NavLink
          to="/minimarketorders"
          className="app-menu__item"
          onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
        >
          <i className="app-menu__icon fa fa-shopping-cart"></i>
          <span
            className="app-menu__label"
            style={{ textAlign: "start" }}
          >
            {t("Product_orders")}
          </span>
        </NavLink>):null}

        {businessData.length > 0 && checkMiniMarketOrders() ? (
        <NavLink
          to="/minimarketstats"
          className="app-menu__item"
          onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
        >
          <i className="app-menu__icon fa fa-shopping-cart"></i>
          <span
            className="app-menu__label"
            style={{ textAlign: "start" }}
          >
            {t("Order_stats")}
          </span>
        </NavLink>):null}

        {businessData.length > 0 && checkCabBusiness() ? (
        <NavLink
          to="/CabEnquiry"
          className="app-menu__item"
          onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
        >
          <i className="app-menu__icon fa fa-question-circle"></i>
          <span
            className="app-menu__label"
            style={{ textAlign: "start" }}
          >
           {t("Cab_enquiry")}
          </span>
        </NavLink>):null}
        
        
        {/* <NavLink
          to="/send-notification"
          className="app-menu__item"
          onClick={() => removeExpand() && isMobileDevice && handleToggleSidebar()}
        >
          <i className="app-menu__icon fa fa-bell"></i>
          <span
            className="app-menu__label"
            style={{ textAlign: "start" }}
          >
            Notification
          </span>
        </NavLink> */}

        {/* <NavLink
          to="/restaurantorders"
          className="app-menu__item"
          onClick={() => isMobileDevice && handleToggleSidebar()}
        >
          <i className="app-menu__icon fa-solid fa-user-circle"></i>
          <span
            className="app-menu__label"
            style={{ textAlign: "start" }}
          >
            Orders
          </span>
        </NavLink>         */}
      </li>
    ):''}
  </>
    )
  }

  return (
    <>
      <div
        className="app-sidebar__overlay"
        data-toggle="sidebar"
        id="app_sideBarStyle_wrapper"
      ></div>
      <aside className={sidebarClass} id="app_sidebarStyle_maincontainer">
        <ul className="app-menu">
          <div
            className="MobilesidebarcloseButton"
            onClick={() => isMobileDevice && handleToggleSidebar()}
          >
            {/* <i className="fa-solid fa-square-xmark"></i> */}
            <button type="button" className="btn-close" aria-label="Close"></button>
          </div>
          {user_type === "super_admin" && (
           <MenuAdmin></MenuAdmin>
          )}

          {user_type != "super_admin" && (
            <MenuUser></MenuUser>
          )}

          <LogoutButtonContainer>
            <LogoutButton
              style={{
                display: "flex",
                width: "50%",
                justifyContent: "center",
              }}
            >
              <Link
                to="/"
                onClick={handleLogout}
                style={{ color: "#fff", fontSize: "16px" }}
              >
                Logout
              </Link>
            </LogoutButton>
          </LogoutButtonContainer>
        </ul>
      </aside>
    </>
  );
}
//112 16:28
export default Sidebar;
