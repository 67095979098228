import React, { useState, useEffect, useContext } from "react";
// import { useState } from 'react'
import { userContext } from "../../context/userContext";
import axios from "axios";
import { data } from "jquery";
import { environmentVariables } from "../../config/env.config";
// import { cp } from "fs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import Cookies from "js-cookie";
import "././Updatedcss/ProfileUpdateStyle.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getcoutryfromlocation, isValidPhoneNumber } from "../../utils/utility";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

// import { baseUrl } from "./config";

function Profile() {
  const { t } = useTranslation();
  const { userData, setUserData } = useContext(userContext);
  const navigate = useNavigate();
  const baseUrl = environmentVariables?.apiUrl;
  const [editedData, setEditedData] = useState({
    name: userData?.name || "",
    // lastname: userData?.lastname || '',
    email: userData?.email || "",
    contact: `${userData?.country_code || ''}${userData?.contact || ''}`,
  });
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [oPVisibility, setOPVisibility] = useState(false);
  const [pVisibility, setPVisibility] = useState(false);
  const [cpVisibility, setCPVisibility] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1");
  const [invalidPhoneErr, setInvalidPhoneErr] = useState("");
  const [countryCode, setCountryCode] = useState("in");

  const fetchCountryfromlocation = async () => {
    try {
      const countryData = await getcoutryfromlocation();
      setCountryCode(countryData.countryCode.toLowerCase());
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
  };

  useEffect(() => {
    fetchCountryfromlocation()
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const [countryName, setCountryName] = useState("");

  // frontend validation
  const validateSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .required(t("Please_enter_old_password"))
      .min(8, t("Password_at_least_8_characters"))
      .matches(/[0-9]/, t("Password_at_least_1_digit_character"))
      .matches(
        /[a-z]/,
        t("Password_at_least_1_lowercase_character")
      )
      .matches(
        /[A-Z]/,
        t("Password_at_least_1_uppercase_character")
      ),
    password: Yup.string()
      .required(t("Please_enter_new_password"))
      .min(8, t("Password_at_least_8_characters"))
      .matches(/[0-9]/, t("Password_at_least_1_digit_character"))
      .matches(
        /[a-z]/,
        t("Password_at_least_1_lowercase_character")
      )
      .matches(
        /[A-Z]/,
        t("Password_at_least_1_uppercase_character")
      ),
    confirmPassword: Yup.string()
      .required(t("Please_re-type_new_password"))
      .oneOf([Yup.ref("password")], t("Passwords_does_not_match")),
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validateSchema,
    onSubmit: (values, { resetForm }) => {
      setIsSubmit(true);
      handleChanePassword(values);
    },
  });

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const nameRegex = /^[A-Za-z\s]+$/;
      // setIsSubmit(true);
      // console.log(userData,"userDatauserDatauserData")
      if (editedData?.name == "" || editedData?.name?.trim() == "") {
        toast.error(t("Name_is_mandatory"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else if (!nameRegex.test(editedData?.name)) {
        toast.error(t("Name_only_alphabetic_characters"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else if (
        editedData?.name?.length > 40 ||
        editedData?.name?.trim()?.length > 40
      ) {
        toast.error(t("Name_maximum_40_character"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else if (
        editedData?.name?.length < 3 ||
        editedData?.name?.trim()?.length < 3
      ) {
        toast.error( t("Name_minimum_3_character"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      if(editedData?.contactNumber == "" || editedData?.contactNumber?.trim() == ""){
        toast.error(t("Contact_number_is_mandatory"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      if (editedData?.contact == "" || editedData?.contact?.trim() == "") {
        toast.error(t("Contact_number_is_mandatory"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      
      if (invalidPhoneErr) {
        toast.error(invalidPhoneErr, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsSubmit(false);
        return;
      }

      let countryCode = '';
      let contactNumber = '';
      if(!!editedData?.contact){
        let contactArr = editedData?.contact?.split("-");
        countryCode = contactArr?.length > 0? contactArr[0]:"";
        contactNumber = contactArr?.length > 0? contactArr[1]:"";
      }
      // // let contactNumber = editedData?.contact.replace(/-/g, '');
      // console.log("contactNumber====>>>", );
      
      // if (contactNumber.startsWith('+91')) {
      //   countryCode = '+91';
      //   contactNumber = contactNumber.slice(3);
      // } else if (contactNumber.startsWith('+971')) {
      //   countryCode = '+971';
      //   contactNumber = contactNumber.slice(4);
      // } else if (contactNumber.startsWith('+1')) {
      //   countryCode = '+1';
      //   contactNumber = contactNumber.slice(2);
      // } else if (contactNumber.startsWith('+964')) {
      //   countryCode = '+964';
      //   contactNumber = contactNumber.slice(4);
      // } else if (contactNumber.startsWith('+44')) {
      //   countryCode = '+44';
      //   contactNumber = contactNumber.slice(3);
      // } else if (contactNumber.startsWith('+960')) {
      //   countryCode = '+960';
      //   contactNumber = contactNumber.slice(4);
      // } else {
      //   toast.error(t("Unsupported_country_code"), {
      //     position: toast.POSITION.TOP_RIGHT,
      //     autoClose: 2000,
      //   });
      //   return;
      // }
      
      
      let config = {
        method: "put",
        url: `${baseUrl}api/v1/user/${userData?.user_type == "super_admin" ? "updateUser" : "updateNormalUser"
          }`,
        headers: {
          _token: userData?.token,
          "Content-Type": "application/json",
        },
        // withCredentials: true,
        data: {
          userId: userData?.id,
          name: editedData?.name,
          email: editedData?.email,
          contact: contactNumber,
          country_code: countryCode,
          profile:1
        },
      };

      axios
        .request(config)
        .then((response) => {
          setIsSubmit(false);
          let updatedUserData = {
            ...userData,
            name: editedData?.name || userData?.name,
            email: editedData?.email || userData?.email,
            contact: contactNumber || userData?.contact,
            country_code: countryCode || userData?.country_code,
          };



          setUserData(updatedUserData);
          localStorage.setItem("user", JSON.stringify(updatedUserData));

          // console.log(obj, "setUserDatasetUserDatasetUserData");
          toast.success(t("Your_profile_updated_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        })
        .catch((error) => {
          setIsSubmit(false);
          console.error(
            "Update User API Error:",
            error?.response?.data?.message
          );
          toast.error(
            error?.response?.data?.message ||
            t("Failed_to_update_your_profile"),
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
        });
    } catch (error) {
      setIsSubmit(false);
      console.error("API Error:", error);
      toast.error(t("An_unexpected_error_occurred"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleChanePassword = async (data) => {
    try {
      if (userData?.user_type === "super_admin" || userData?.user_type === "Super Admin") {
        let config = {
          method: "post",
          url: `${baseUrl}api/v1/user/createsuperAdminotp`,
          headers: {
            _token: userData?.token,
            "Content-Type": "application/json",
          },
          data: {
            oldpassword: data.oldPassword,
            password: data.password,
            cpassword: data.confirmPassword,
          },
        };
  
        axios
          .request(config)
          .then((response) => {
            console.log(response, "response ==>");
            setIsSubmit(false);

            Swal.fire({
              title: t("Otp"),
              text: t("Please_enter_otp"),
              input: "text",
              inputAttributes: {
                autocapitalize: "off",
                inputmode: "numeric",
                pattern: "[0-9]{6}",
                maxLength: 6,
              },
              inputValidator: (value) => {
                if (!value || !/^[0-9]{6}$/.test(value)) {
                  return t("Please_enter_valid_6-digit_otp");
                }
              },
              showCancelButton: true,
              cancelButtonColor: "#d33",
              confirmButtonColor: "#3085d6",
              confirmButtonText: t("Submit"),
              cancelButtonText: t("Cancel"),
              allowOutsideClick: false,
              allowEscapeKey: false,
              showLoaderOnConfirm: true,
              preConfirm: (otp) => {
                const otpValue = otp;
                let configOtp = {
                  method: "post",
                  maxBodyLength: Infinity,
                  withCredentials: true,
                  url: `${environmentVariables?.apiUrl}api/v1/user/changePassword`,
                  headers: {
                    _token: userData?.token,
                    "Content-Type": "application/json",
                  },
                  data: { 
                    oldpassword: data.oldPassword,
                    password: data.password,
                    cpassword: data.confirmPassword, 
                    otp_code: otpValue },
                };
                return axios
                  .request(configOtp)
                  .then((res) => {
                    toast.success(t("Password_updated_successfully"));
                    setTimeout(async () => {
                      await logout();
                      navigate("/");
                    }, 1500);
                    return;
                  })
                  .catch((err) => {
                    console.log("error", err);
                    Swal.showValidationMessage(
                      `Failed: ${err?.response?.data?.message}`
                    );
                  });
              },
            });

          })
          .catch((error) => {
            setIsSubmit(false);
            toast.error(
              error.response?.data?.message ||
                  t("Failed_to_send_otp"),
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              }
            );
          });
      } else {
        callChangePasswordAPI(data);
      }
    } catch (error) {
      setIsSubmit(false);
      toast.error(t("An_unexpected_error_occurred"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const callChangePasswordAPI = (data) => {
    let config = {
      method: "post",
      url: `${baseUrl}api/v1/user/changePassword`,
      headers: {
        _token: userData?.token,
        "Content-Type": "application/json",
      },
      data: {
        oldpassword: data.oldPassword,
        password: data.password,
        cpassword: data.confirmPassword,
      },
    };
  
    axios
      .request(config)
      .then((response) => {
        setIsSubmit(false);
        toast.success(t("Password_updated_successfully"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setTimeout(async () => {
          await logout();
          navigate("/");
        }, 1500);
      })
      .catch((error) => {
        setIsSubmit(false);
        toast.error(
          error.response?.data?.message ||
            t("Failed_to_update_password"),
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          }
        );
      });
  };

  const logout = () => {
    const loginFormData = localStorage.getItem("login_form");
    localStorage.clear();
    if (loginFormData !== null) {
      localStorage.setItem("login_form", loginFormData);
    }
    setUserData(null);
    return true;
  };
  const toggleOPasswordVisibility = () => {
    setOPVisibility(!oPVisibility);
  };
  const togglePasswordVisibility = () => {
    setPVisibility(!pVisibility);
  };
  const toggleCPasswordVisibility = () => {
    setCPVisibility(!cpVisibility);
  };

  const handleBlur = (value, phoneData) => {    
    phoneData.iso2 = phoneData.countryCode;
    let contactNumber = editedData.contact;
    if (!!contactNumber) {
      let val = contactNumber.split("-");
      let valArray = val.length > 0 ? val[1] : "";
      if (valArray == '') {
        setInvalidPhoneErr(t("Please_enter_number"));
      } else if (!isValidPhoneNumber(valArray, phoneData)) {
        setInvalidPhoneErr(`Invalid phone number for ${phoneData.name}`);
      } else {
        setInvalidPhoneErr("");
      }
    }
  };

  return (
    <>
      <div className="row ProfileContainer">
        <div className="col-md-4 ProfileWrapper  ">
          <div className="tile">
            <ul className="conatctprofile ProfileflexContainer">
              <li
                className={`tab-item ${activeTab === "tab1" ? "active" : ""}`}
                onClick={() => handleTabClick("tab1")}
                id="PersonalInput"
              >
                {t("Personal_info")}
              </li>
              <li
                className={`tab-item ${activeTab === "tab2" ? "active" : ""}`}
                onClick={() => handleTabClick("tab2")}
                id="PersonalInput"
              >
                {t("Change_password")}
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-8 ProfileWrapper">
          <div className="tile">
            {activeTab === "tab1" && (
              <form>
                <div className="row mb-4">
                  <div className="col-md-6 ProfileNameWid">
                    {/* <label>Name</label> */}
                    <input
                      className="form-control"
                      placeholder={t("Name")}
                      type="text"
                      value={editedData.name}
                      onChange={(e) =>
                        setEditedData({ ...editedData, name: e.target.value })
                      }
                    />
                  </div>
                  {/* <div className="col-md-6">
                    <label>Last Name</label>
                    <input className="form-control" type="text" value={editedData?.lastname || ''}
                    onChange={(e) => setEditedData({ ...editedData, name: e.target.value })}/>
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-md-12 mb-4">
                    {/* <label>Email</label> */}
                    <input
                      placeholder={t("Email")}
                      className="form-control"
                      type="text"
                      value={editedData?.email || ""}
                    />
                  </div>
                  <div className="clearfix"></div>
                  <div className="col-md-12 mb-4">                   
                    <PhoneInput
                      country={countryCode}
                      enableSearch={true}
                      countryCodeEditable={false}
                      value={editedData.contact || ""}
                      onChange={(value, country) => {
                        if (country.dialCode) {
                          setEditedData({
                            ...editedData,
                            countryCode: country.dialCode,
                            contactNumber: value.substring(
                              country.dialCode.length
                            ),
                            contact: `+${country.dialCode}-${value.substring(
                              country.dialCode.length
                            )}`,
                          });
                          setInvalidPhoneErr("");
                        } else {
                          setEditedData({
                            ...editedData,
                            contact: value,
                          });
                          setInvalidPhoneErr("");
                        }
                      }}
                      onBlur={(e, country) => handleBlur(e, country)}
                      isValid={(inputNumber, country) =>
                        isValidPhoneNumber(inputNumber, country)
                      }
                      className="DetailsInfoPhoneInput"
                    />
                    {invalidPhoneErr && (
                      <div id="error_msg" className="error">
                        {invalidPhoneErr}
                      </div>
                    )}
                  </div>
                  <div className="clearfix"></div>
                </div>
                <div className="row mb-10">
                  <div className="col-md-12 ProfileUpdatedButtonContainer">
                    <button
                      className="btn btn-primary ProfileUpdatedButton"
                      type="button"
                      onClick={(e) => handleSave(e)}
                    >
                      {/* <i className="bi bi-check-circle-fill me-2"></i>  */}
                      {/* {isSubmit ? <CircularLoader size={20} /> : "Update"} */}
                      {t("Update")}
                    </button>
                  </div>
                </div>
                <ToastContainer />
              </form>
            )}
            {activeTab === "tab2" && (
              <>
                <form onSubmit={formik.handleSubmit}>
                  <div className="row mb-4 UpdatePasswordWrapper">
                    <div className="col-md-6 ProfileChangepassWid" style={{position:"relative"}}>
                      {/* <label>Password</label> */}
                      <input
                        id="oldPassword"
                        name="oldPassword"
                        className="form-control "
                        placeholder= {t("Enter_old_password")}
                        type={oPVisibility ? "text" : "password"}
                        onChange={formik.handleChange}
                        value={formik.values.oldPassword}
                        
                      />
                      <span
                        onClick={toggleOPasswordVisibility}
                        style={{
                          position: "absolute",
                          right: "45px",
                          top: "32px",
                          // top:  formik.touched.password && formik.errors.password ? "11%" : "15%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      >
                        {formik.values.oldPassword.length >= 1 &&
                          (oPVisibility ? (
                            <i className="fas fa-eye Eeye"></i>
                          ) : (
                            <i className="fas fa-eye-slash Eeye"></i>
                          ))}
                      </span>
                      {formik.touched.oldPassword &&
                        formik.errors.oldPassword && (
                          <p className="text-danger passworderrmsg">
                            {formik.errors.oldPassword}
                          </p>
                        )}
                    </div>
                    <div className="col-md-6 ProfileChangepassWid"    style={{position:"relative"}}>
                      {/* <label>Password</label> */}
                      <input
                        id="password"
                        name="password"
                        className="form-control "
                        placeholder= {t("Enter_new_password")}
                        type={pVisibility ? "text" : "password"}
                        onChange={formik.handleChange}
                        value={formik.values.password}
                     
                      />
                      <span
                        onClick={togglePasswordVisibility}
                        style={{
                          position: "absolute",
                          right: "45px",
                          top: "32px",
                          // top: "38%",
                          // top:  formik.touched.password && formik.errors.password ? "37%" : "39%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      >
                        {/* {pVisibility ? (
                          <i className="fas fa-eye Eeye"></i>
                        ) : (
                          <i className="fas fa-eye-slash Eeye"></i>
                        )} */}
                        {formik.values.password.length >= 1 &&
                          (pVisibility ? (
                            <i className="fas fa-eye Eeye"></i>
                          ) : (
                            <i className="fas fa-eye-slash Eeye"></i>
                          ))}
                      </span>
                      {formik.touched.password && formik.errors.password && (
                        <p className="text-danger passworderrmsg">{formik.errors.password}</p>
                      )}
                    </div>
                    <div className="col-md-6 ProfileChangepassWid"  style={{position:"relative"}}>
                      {/* <label>Confirm Password</label> */}
                      <input
                        id="confirmPassword"
                        name="confirmPassword"
                        className="form-control"
                        placeholder= {t("Confirm_new_password")}
                        type={cpVisibility ? "text" : "password"}
                        onChange={formik.handleChange}
                        value={formik.values.confirmPassword}
                        style={{position:"relative"}}
                      />
                      <span
                        onClick={toggleCPasswordVisibility}
                        style={{
                          position: "absolute",
                          right: "45px",
                          top: "32px",
                          // top: "63%",
                          // top:  formik.touched.password && formik.errors.password ? "58%" : "63%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      >
                        {/* {cpVisibility ? (
                          <i className="fas fa-eye Eeye"></i>
                        ) : (
                          <i className="fas fa-eye-slash Eeye"></i>
                        )} */}
                        {formik.values.confirmPassword.length >= 1 &&
                          (cpVisibility ? (
                            <i className="fas fa-eye Eeye"></i>
                          ) : (
                            <i className="fas fa-eye-slash Eeye"></i>
                          ))}
                      </span>
                      {formik.touched.confirmPassword &&
                        formik.errors.confirmPassword && (
                          <p className="text-danger passworderrmsg">
                            {formik.errors.confirmPassword}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="row mb-10">
                    <div className="col-md-12 ProfileUpdatedButtonContainer">
                      <button
                        className="btn btn-primary ProfileUpdatedButton"
                        type="submit"
                      >
                        <i className="bi bi-check-circle-fill me-2"></i>
                        {isSubmit ? <CircularLoader size={20} /> : t("Update")}
                      </button>
                    </div>
                  </div>
                  <ToastContainer />
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
